import {
  transactionData
} from "../../aaone/shared/utils/transactionNumber";
import {
  PAYMENTS_KEY_NAME
} from "../constants/keyNamesSessionStorage";
import {
  getSystemParamsHelper
} from "./getSystemParamsHelper";
/**
 *
 * @returns {{methodName: string, details: {amountPaid: number, changeMoney: number}}[]}
 */
export const getLocalPayments = () => {
  try {
    const payments = localStorage.getItem(PAYMENTS_KEY_NAME);

    return JSON.parse(payments || "[]");
  } catch (error) {
    return [];
  }
};

export const clearLocalPayments = () => {
  localStorage.removeItem(PAYMENTS_KEY_NAME);
};

export const getTotalAlreadyPaid = () => {
  const payments = getLocalPayments();
  const amountPaid = payments.reduce(
    (previous, current) => previous + current.details.amountPaid,
    0
  );

  return amountPaid;
};

export const hasLocalPayments = () => {
  const payments = getLocalPayments();

  return payments.length > 0;
};

/**
 * Remove um pagamento da lista
 *
 * @param {string} pIndex paymentIndex
 */
export const removePayment = (pIndex = -1) => {
  let payments = getLocalPayments();

  if(pIndex >= 0) {
    payments.splice(pIndex, 1);

    localStorage.setItem(PAYMENTS_KEY_NAME, JSON.stringify(payments));

    return payments;
  }

  return payments;
}

/**
 *
 * @param {string} paymentMethodName Method name, for ex: DEBITO, CREDITO and others...
 * @param {{amountPaid: number, changeMoney: number}} paymentData
 * @returns {{methodName: string, details: {amountPaid: number, changeMoney: number}}[]}
 */
export const saveLocalPayment = (paymentMethodName, paymentData) => {
  const payments = getLocalPayments();

  const paymentIndex = payments.findIndex((payment) =>
    payment?.details.controlCode ?
    payment?.details.controlCode === paymentData.controlCode :
    false
  );

  if (paymentIndex >= 0) {
    payments[paymentIndex] = {
      methodName: paymentMethodName,
      details: {
        dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        ...paymentData,
      },
    };
  } else {
    const data = {
      methodName: paymentMethodName,
      details: {
        dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        ...paymentData,
      },
    };

    payments.push(data);
  }

  localStorage.setItem(PAYMENTS_KEY_NAME, JSON.stringify(payments));

  return payments;
};

/**
 * Remove o ultimo pagamento da lista de pagamentos
 *
 * @param {string} paymentMethodName Method name, for ex: DEBITO, CREDITO and others...
 * @param {{amountPaid: number, changeMoney: number}} paymentData
 * @returns {{methodName: string, details: {amountPaid: number, changeMoney: number}}[]}
 */
export const removeLastLocalPayment = (paymentMethodName, paymentData) => {
  let payments = getLocalPayments();


  payments.pop();

  localStorage.setItem(PAYMENTS_KEY_NAME, JSON.stringify(payments));

  return payments;
};

export const getPaymentsSerializedToOrder = () => {
  const payments = getLocalPayments();

  const CASH_METHODS_CONSTANT = ["DINHEIRO"];
  const FIDELITY_METHODS_CONSTANT = ["FIDELIDADE"];
  const PINPAD_METHODS_CONSTANT = [
    "CREDITO",
    "CRÉDITO",
    "DEBITO",
    "DÉBITO",
    "VOUCHER",
    "PIX",
    "QR LINX",
    "QRLINX",
    "OUTROS",
  ];

  return payments.map((payment) => {
    if (FIDELITY_METHODS_CONSTANT.includes(payment.methodName)) {
      const response = {
        dateTimeInsert: payment.dateTimeInsert, //transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        Change: payment.details.changeMoney,
        Amount: payment.details.amountPaid,
        sitefCode: 0,
        dtefCode: 0,
        value: parseFloat(payment.details.amountPaid.toFixed(2)),
        reshop: false,
        paymentCode: getSystemParamsHelper().defaultPaymentCodeReshop ?? 0,
        authorizationCodeOnline: null,
        qrLinxTransactionId: null,
        qrLinxTradingName: null,
        sticker: null,
        carPlate: null,
        semPararLane: null,
        transactionDatetime: payment.dateTimeInsert,
        paymentTransition: payment.methodName,
      };

      return response;
    } else if (CASH_METHODS_CONSTANT.includes(payment.methodName)) {
      const response = {
        dateTimeInsert: payment.dateTimeInsert, //transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        Change: payment.details.changeMoney,
        Amount: payment.details.amountPaid,
        sitefCode: 0,
        dtefCode: 0,
        value: parseFloat(payment.details.amountPaid.toFixed(2)),
        reshop: false,
        paymentCode: 1,
        authorizationCodeOnline: null,
        qrLinxTransactionId: null,
        qrLinxTradingName: null,
        sticker: null,
        carPlate: null,
        semPararLane: null,
        transactionDatetime: payment.dateTimeInsert,
        paymentTransition: payment.methodName,
      };

      return response;
    } else if (PINPAD_METHODS_CONSTANT.includes(payment.methodName)) {
      const response = {
        ...payment.details,
        value: !payment.details.value ?
          payment.details.amountPaid :
          payment.details.value,
        Change: payment.details.changeMoney,
        paymentTransition: payment.details?.paymentTransition ?? payment.methodName,
      };

      return response;
    }
  });
};
