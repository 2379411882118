import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { add } from "date-fns";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRetailerId } from "../../../../aaone/configuration";
import { getSaleByDayReportService } from "../../../../services/orderService";
import { PagesEnum } from "../../../constants/pagesEnum";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import { formatDateHelper } from "../../../helpers/formatDateHelper";
import { currencyString } from "../../../helpers/masks";
import { useWrapperRouters } from "../../../hook/wrapperRoutersHook";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import { Period } from "../../../components/period/period";
import {
  ContainerTable,
  DirectionalArrow,
  DirectionalArrowsContainer,
  PeriodWrapper,
} from "./style";
import { useLoading } from "../../../hook/loadingHook";
import { isSmartPos } from "../../../../aaone/configuration";
import classNames from "classnames";
import { CardContentSmartPos } from "../../../components/CardContentSmartPos";

const filters = {
  initialDate: new Date(),
  finalDate: new Date(),
};

const ROW_HEIGHT = 55;
const HEADER_HEIGHT = 40;

const ReportSales = () => {
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { setShowLoading } = useLoading();
  const navigate = useNavigate();

  const title = "Relatório de Vendas Diário";
  const columns = [
    //TODO - Comentado opções que não serão utilizadas de inicio

    { title: "Data", field: "data", resizable: false },
    { title: "Balcão", field: "balcao", resizable: false },
    //{ title: "Papa-Fila", field: "vendas", resizable: false },
    { title: "Delivery", field: "delivery", resizable: false },
    //{ title: "Mesa", field: "mesa", resizable: false },
    { title: "Desconto", field: "desconto", resizable: false },
    //{ title: "Cortesia", field: "cortesia", resizable: false },
    //{ title: "(-) 4All", field: "descontoFourAll", resizable: false },
    { title: "(-) Reshop", field: "descontoReshop", resizable: false },
    { title: "Total", field: "total", resizable: false },
  ];

  const [listReport, setListReport] = React.useState([]);
  const [pinnedRowData, setPinnedRowData] = React.useState([]);
  const periodRef = React.useRef(null);
  const agGridRef = React.useRef(null);

  const handleSearch = async () => {
    try {
      setShowLoading();
      setListReport([]);
      setPinnedRowData([]);

      const res = await getSaleByDayReportService({
        retailerId: getRetailerId(),
        startDate: formatDateHelper(filters.initialDate, "yyyy-MM-dd"),
        endDate: formatDateHelper(
          add(filters.finalDate, { days: 1 }),
          "yyyy-MM-dd"
        ),
      });

      let total = {
        data: "Total",
        balcao: 0,
        vendas: 0,
        delivery: 0,
        mesa: 0,
        desconto: 0,
        cortesia: 0,
        descontoFourAll: 0,
        descontoReshop: 0,
        total: 0,
      };

      let resFormatted = res.map((item) => {
        total.balcao += item.balcao;
        total.vendas += item.vendas;
        total.delivery += item.delivery;
        total.mesa += item.mesa;
        total.desconto += item.desconto;
        total.cortesia += item.cortesia;
        total.descontoFourAll += item.descontoFourAll;
        total.descontoReshop += item.descontoReshop;
        total.total +=
          item.balcao +
          item.delivery +
          item.mesa -
          item.desconto -
          item.cortesia -
          item.descontoFourAll -
          item.descontoReshop;

        return {
          ...item,
          balcao: item.balcao > 0 ? currencyString(item.balcao) : "",
          vendas: item.vendas > 0 ? currencyString(item.vendas) : "",
          delivery: item.delivery > 0 ? currencyString(item.delivery) : "",
          mesa: item.mesa > 0 ? currencyString(item.mesa) : "",
          desconto: item.desconto > 0 ? currencyString(item.desconto) : "",
          cortesia: item.cortesia > 0 ? currencyString(item.cortesia) : "",
          descontoFourAll:
            item.descontoFourAll > 0
              ? currencyString(item.descontoFourAll)
              : "",
          descontoReshop:
            item.descontoReshop > 0 ? currencyString(item.descontoReshop) : "",
          total: currencyString(
            item.balcao +
              item.delivery +
              item.mesa -
              item.desconto -
              item.cortesia -
              item.descontoFourAll -
              item.descontoReshop
          ),
        };
      });

      setListReport(resFormatted);
      setPinnedRowData([
        {
          ...total,
          balcao: currencyString(total.balcao),
          vendas: currencyString(total.vendas),
          delivery: currencyString(total.delivery),
          mesa: currencyString(total.mesa),
          desconto: currencyString(total.desconto),
          cortesia: currencyString(total.cortesia),
          descontoFourAll: currencyString(total.descontoFourAll),
          descontoReshop: currencyString(total.descontoReshop),
          total: currencyString(total.total),
        },
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  const scrollGrid = (direction) => {
    const displayedRowCount = agGridRef.current.api?.getDisplayedRowCount();
    if (displayedRowCount === 0) {
      return;
    }

    let nodeIndex = 0;
    const lastRowIndex = displayedRowCount - 1;
    const displayedRows =
      Math.floor(
        agGridRef.current.api?.["gridBodyCon"].eBodyViewport.clientHeight /
          ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = agGridRef.current.api?.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = agGridRef.current.api?.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    if (agGridRef.current.api) {
      agGridRef.current.api.ensureIndexVisible(nodeIndex);
    }
  };

  useEffect(() => {
    setVisibleSidebarCart(false);
  });

  useEffect(() => {
    return () => {
      filters.initialDate = new Date();
      filters.finalDate = new Date();
    };
  }, []);

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  const itemsSerializedSmartPos = listReport.map((item) => {
    const columnSerialized = columns.map((currentColumn) => ({
      label: currentColumn.headerName ?? null,
      key: currentColumn?.field ?? null,
    }));

    const returnSerialized = columnSerialized.map((currentItemList) => ({
      label: currentItemList.label,
      children: item[currentItemList.key],
    }));

    return {
      onClick: () => {
        this.props.onHandleSelectPdv(item);
      },
      list: returnSerialized,
    };
  });

  return (
    <Card
      title={title}
      handleClose={handleClose}
      closeEnable
      fullHeight
      className=""
      id="cardList"
    >
      <PeriodWrapper className={classNames({ isSmartPos })}>
        <Period
          ref={periodRef}
          object={filters}
          fieldStart="initialDate"
          fieldEnd="finalDate"
        />

        <Button className="btn-pronto" onClick={handleSearch}>
          <div>
            <i className="material-icons">search</i>
            Pesquisar
          </div>
        </Button>
      </PeriodWrapper>
      <ContainerTable
        id="containerTable"
        className={classNames({ isSmartPos })}
      >
        {!isSmartPos && (
          <DirectionalArrowsContainer>
            <DirectionalArrow className="top" onClick={() => scrollGrid("top")}>
              <span className="material-icons">keyboard_arrow_up</span>
            </DirectionalArrow>

            <DirectionalArrow
              className="bottom"
              onClick={() => scrollGrid("bottom")}
            >
              <span className="material-icons">keyboard_arrow_down</span>
            </DirectionalArrow>
          </DirectionalArrowsContainer>
        )}
        <div
          className="ag-theme-balham pdv"
          style={{ width: `calc(100% - 40px)` }}
        >
          {isSmartPos ? (
            <CardContentSmartPos items={itemsSerializedSmartPos} />
          ) : (
            <AgGridReact
              rowData={listReport}
              localeText={agGridLocalePtBr}
              ref={agGridRef}
              rowHeight={ROW_HEIGHT}
              headerHeight={HEADER_HEIGHT}
              rowBuffer={0}
              rowStyle={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: "15px",
                wordBreak: "break-word",
              }}
              onGridReady={({ api }) => {
                api.sizeColumnsToFit();
              }}
              pinnedBottomRowData={pinnedRowData}
            >
              {columns.map((column) => {
                const cellStyle = {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  lineHeight: "30px",
                  wordBreak: "break-word",
                };

                return (
                  <AgGridColumn
                    key={column.field}
                    cellStyle={cellStyle}
                    field={column.field}
                    headerName={column.headerName}
                  />
                );
              })}
            </AgGridReact>
          )}
        </div>
      </ContainerTable>
    </Card>
  );
};

export default ReportSales;
