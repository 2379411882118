import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SUM_ITEM,
  SUB_ITEM,
  CHECKOUT,
  TOGGLE_CART,
  CART_SEQ,
} from "../constants/cart";

const INITIAL_STATE = {
  items: [],
  toggle: false,
};

const items = (state = INITIAL_STATE.items, action) => {
  const findProductCondition = (item) =>
    item.id === action.item.id && item.valuePoints === action.item.valuePoints;

  switch (action.type) {
    case ADD_TO_CART:
      const index = state.findIndex(findProductCondition);
      const item = state.find(findProductCondition);

      if (
        index >= 0 &&
        !item.hasOwnProperty("vit_numlancto") &&
        !item.hasOwnProperty("vit_numlanctoprincipal") &&
        !action.item.hasOwnProperty("vit_numlancto")
      ) {
        const newState = [...state];
        newState[index].qtd += action.item.qtd;

        return [...newState];
      }

      return [...state, action.item];
    case REMOVE_FROM_CART:
      state.splice(action.index, 1);
      return [...state];
    case SUB_ITEM:
      state[action.index].qtd =
        state[action.index].qtd - (state[action.index].quantityDouble || 1);
      return [...state];
    case SUM_ITEM:
      state[action.index].qtd =
        state[action.index].qtd + (state[action.index].quantityDouble || 1);
      return [...state];

    case CART_SEQ:
      const tmpCartItems = [...state];

      tmpCartItems.forEach((combo, index) => {
        if (
          combo.hasOwnProperty("vit_numlanctoprincipal") ||
          combo.hasOwnProperty("vit_numlancto")
        ) {
          const oldNumLanctoPrincipal = tmpCartItems[index].vit_numlancto;
          const newNumLanctoPrincipal = index + 1;

          if (combo.vit_numlanctoprincipal === null) {
            tmpCartItems.forEach(
              (produto, index) => {
                if (produto.vit_numlanctoprincipal === oldNumLanctoPrincipal)
                  state[index].vit_numlanctoprincipal = newNumLanctoPrincipal;
              },
              { oldNumLanctoPrincipal, newNumLanctoPrincipal }
            );
          }

          state[index].vit_numlancto = newNumLanctoPrincipal;
        }
      });

      return [...state];
    default:
      return state;
  }
};

const toggleCart = (state = INITIAL_STATE.toggle, action) => {
  switch (action.type) {
    case TOGGLE_CART:
      state = action.toggle;
      return state;
    default:
      return state;
  }
};

const cart = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKOUT:
      return INITIAL_STATE;
    default:
      return {
        items: items(state.items, action),
        toggle: toggleCart(state.toggle, action),
      };
  }
};

export default cart;
