const endPoint = () => {
  return window.localStorage.getItem("endPoint");
};

export const configQrCode = () => {
  const url = `${endPoint()}/getobjectqrlinx`;

  const response = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      terminalid: window.localStorage.getItem("pdvCode"),
      logtoken: 1,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e) => console.log("configQrCode ", e));

  return response;
};
