import styled from "styled-components";

const FlavorSelectListWrapper = styled.ul`
  list-style: none;
  position: relative;

  li {
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 4fr auto;
    align-items: center;
  }
`;

export default FlavorSelectListWrapper;
