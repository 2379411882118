import React, { forwardRef } from "react";

import { Container } from "./styles";

export const SimpleItemTableSmartPos = forwardRef(
  ({ items, ...restProps }, ref) => {
    return (
      <Container role="button" {...restProps} ref={ref}>
        {items.map(({ label, children }, index) => (
          <div className="label" key={`simple-item-${index}-smart-pos`}>
            {label}
            <span className="value">{children}</span>
          </div>
        ))}
      </Container>
    );
  }
);
