import { isLayoutUtil } from "../aaone/shared/utils/isLayout";
import { PDV_COD_LOJA_KEY_NAME } from "../pdv-one/constants/keyNamesSessionStorage";
import { getSystemParametersService } from "./getSystemParametersService";
import { apiProducts } from "./api";

export const configsPdv = async () => {
  try {
    const data = await getSystemParametersService();

    window.sessionStorage.setItem(
      "reshopUser",
      data.objectSystemParameters.defaultUserUnico
    );

    if (
      data.objectSystemParameters.pedeProntoConfiguration &&
      data.objectSystemParameters.pedeProntoConfiguration.constructor === Object
    ) {
      window.localStorage.setItem(
        "categoriaPedePronto",
        data
          .objectSystemParameters
          .pedeProntoConfiguration
          .reshopCampaignCategory
          .replace(" ", "_")
          .toUpperCase()
      );
    } else {
      window.localStorage.setItem("categoriaPedePronto", "NO_HAS");
    }

    window.sessionStorage.setItem(
      "reshopPassword",
      data.objectSystemParameters.defaultPasswordUnico
    );

    if (isLayoutUtil("RAGAZZOEXPRESS")) {
      window.sessionStorage.setItem(
        PDV_COD_LOJA_KEY_NAME,
        data.objectSystemParameters.storeID + 8000
      );
    } else {
      window.sessionStorage.setItem(
        PDV_COD_LOJA_KEY_NAME,
        data.objectSystemParameters.storeID
      );
    }
    return data;
  } catch (error) {
    console.error(`Error in configsPdv :: `, error);
    return Promise.reject("Error in configsPdv ::");
  }
};

export const saveFiscalFlowConfig = async (params) => {
  try {
    const response = await apiProducts.post("/api/save-data-fiscal-flow", params);
    return response.data;
  } catch (error) {
    console.error("Error in saveFiscalFlowConfig:", error);
    throw error;
  }
};
