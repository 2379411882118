import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useLayoutAa } from "../../contexts/layout";

import { Button, Content, QrCode, ScrollContainer } from "../../shared/components/modules";

import { useViewDispatch } from "../../../context";

import { sendSaleOrder } from "../../../services/paymentService";

import { reshopCalculateValues } from "../../../services/paymentService";

import { consultDiscounts } from "../../../services/pointsService";

import leitora from "../../assets/leitora-rex.png";

import { useSelector } from "react-redux";

import { transactionData } from "../../shared/utils/transactionNumber";

import { ModalPayments } from "../../shared/components/modules";

import {
  cancelOperation,
  confirmsOperation,
  processesOperation,
} from "../../../services/verifyRedeemValueService";

import { v4 as uuidv4 } from "uuid";

import picpay from "../../assets/picpay.png";

import paymentPix from "../../assets/pagamento-pix.jpg";

import { currency } from "../../shared/utils/currency";

import { nextSequence } from "../../../services/sequentialService";

import { useCallback } from "react";

import { isDisabledReShop, isShowIconPaymentPix } from "../../configuration";

import {
  cancelPaymentOrder,
} from "../../../services/middlewareStoneService";
import { currentLayoutName } from "../../shared/utils/isLayout";
import { nsuValueHelper } from "../../../pdv-one/helpers/nsuValueHelper";
import { printerActionService } from "../../../services/actions/printerActionService";
import { LINXPAY_POSID } from "../../../pdv-one/constants/keyNamesSessionStorage";
import { cancelPaymentService, createPaymentService } from "../../../services/linxPayServices";
import { getSystemParamsHelper } from "../../../pdv-one/helpers/getSystemParamsHelper";

var msgRef = null;

const GetConfigDevice =
  JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {};



const timeoutFallback = null;
const TRY_AGAIN_MESSAGE = "Ops... Ocorreu um problema com seu pagamento! Por favor tente novamente ou chame um atendente";

const tefMethods = ["CREDITO", "DEBITO", "VOUCHER"];

const PaymentTransitionContainer = (props) => {
  const { t } = useTranslation();
  const { getTranslateName } = useLayoutAa();
  const [msg, setMsg] = useState(props?.msg || null);
  const [modal, setModalWarnings] = useState(false);
  const qrcodeText = getTranslateName(t, "qrcode") || "Aplicativos aceitos para pagamento QR code:";
  const generatingPassword = getTranslateName(t, "finishPayment.generating_password") || "Aguarde gerando uma senha...";
  const waitScreen = getTranslateName(t, "finishPayment.wait_screen") || "Aguarde retornando a tela de pagamentos";
  const waitInvoice = getTranslateName(t, "finishPayment.wait_invoice") || "Aguarde transmitindo a nota...";
  const waitText = getTranslateName(t, "finishPayment.wait") || "Aguade...";
  const totalPayable = getTranslateName(t, "payment.total_payable") || "Total a Pagar:";

  const paymentMethod = window.localStorage.getItem("paymentTransition");

  const viewDispatch = useViewDispatch();

  const [loadingWindow, setLoadingWindow] = useState(false);

  const [buttonCancel, setButtonCancel] = useState(false);

  const [messageTefWarningsLabell, setmessageTefWarningsLabell] = useState("");

  const [qrcode, setQrCode] = useState({});

  const [modalQrWarn, setModalQrWarn] = useState(false);

  const [semPararStarted, setSemPararStarted] = useState(false);

  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00:00");

  const cartItems = useSelector((state) => state.cart.items);

  const everyItensReshop = cartItems.every((i) => i.currencyPoints === true);

  const activeResetModal = useCallback(() => {
    props.modalTimer(true);
  }, [props]);

  useEffect(() => {
    console.log(localStorage.getItem('payments'))
    if (localStorage.getItem('payments')) {
      props.modalTimer(false);
    }
  }, [localStorage.getItem('payments')]);

  const cancelPendingTEF = () => {
    localStorage.setItem("returnPayment", 1);
    localStorage.removeItem("paymentTypeForReshop");
    localStorage.removeItem("descontoReshop");
    localStorage.removeItem("paymentResponseStone");

    if (qrcode.qrcode) {
      cancelPaymentService({ amount: qrcode.amount, payment_id: qrcode.payment_id });
    }

    if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.tef.undo", {});
    }
    setTimeout(() => {
      viewDispatch("step.payment");
    }, 3000);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 80);
    return deadline;
  };

  const backToCancel = () => {
    setLoadingWindow(true);
    window.localStorage.removeItem("paymentTransition");
    viewDispatch("step.payment");
  }

  useEffect(() => {
    clearTimer(getDeadTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.localStorage.setItem('inRouteToPayment', 'true');
  }, []);

  useEffect(() => {
    if (timer == "00:00:01" && localStorage.getItem("paymentTransition") == "QR LINX") {
      setModalQrWarn(true)
    }
    if (modalQrWarn) {
      setTimeout(() => {
        setModalQrWarn(false)
        window.localStorage.removeItem("paymentTransition")
        cancelPendingTEF();
      }, 30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);


  const startTransaction = (transactionType) => {
    const { clientValue, reshopValue } = reshopCalculateValues(cartItems);

    localStorage.setItem("reshopValue", JSON.stringify(reshopValue));

    contadorPayment = contadorPayment + 1;

    window.localStorage.removeItem("returnPayment");

    localStorage.setItem("contadorPayment", contadorPayment);

    const paymentMethods = {
      'CREDITO': creditCard,
      'TESTEQA': testCard,
      'DINHEIRO': dinheiro,
      'FIDELIDADE': dinheiro,
      'VOUCHER': voucherCard,
      'PIX': () => {
        localStorage.setItem("paymentTypeForReshop", "21");
        return pix();
      },
      'QR LINX': () => {
        localStorage.setItem("paymentTypeForReshop", "19");
        return qrCode();
      },
      'SEM PARAR': semParar,
      'PEDE PRONTO': pedePronto
    };

    if (paymentMethods[transactionType] !== undefined)
      return paymentMethods[transactionType]();
    else return debitCard();
  };

  const [discount, setDiscounts] = useState(0);

  let contador = 0;

  const clearContador = () => {
    contador = 0;
  };

  let contadorPayment = 0;

  const errorPaymenty = () => {
    if (!window.desktopApp) {
      viewDispatch("step.payment");
    }
    localStorage.setItem("contador", +localStorage.getItem("contador") + 1);
  };

  const checkTransaction = () => {
    startTransaction(paymentMethod);
  };

  useEffect(() => {
    if (localStorage.getItem("cpf")) {
      processesOperation(cartItems).then((res) => {
        localStorage.setItem("NsuReshop", JSON.stringify(res.Nsu));
        localStorage.setItem("IdTransacao", JSON.stringify(res.IdTransacao));
        localStorage.setItem(
          "PontosCreditados",
          JSON.stringify(res.PontosCreditados)
        );
        checkTransaction();
      });
    } else {
      checkTransaction();
    }

    if (
      tefMethods.includes(String(paymentMethod || "").toUpperCase()) &&
      window?.Android &&
      window.localStorage.getItem("AA_ConfigDevice")
    ) {
      console.log(`window.Android.initTransaction | {payment: ${String(paymentMethod).toUpperCase()}, value: ${Number(getValuesStorage().sendClientValue)}}`);
      window.Android.initTransaction(
        String(paymentMethod).toUpperCase(),
        Number(getValuesStorage().sendClientValue),
        "000"
      );
    }

    if (window?.desktopApp && !window.localStorage.getItem("AA_ConfigDevice")) {
      window.desktopApp.subscribe(
        "machine.devices.tef.warnings",
        (channel, payload) => {
          console.log("machine.devices.tef.warnings.", payload);
          setmessageTefWarningsLabell((payload?.message?.message?.message ?? payload?.message?.message ?? null) || "Error ao comunicar com o sitef");
          setModalWarnings(true);
          if (localStorage.getItem("contador") == 3) {
            window.desktopApp.publish("machine.devices.tef.undo", {});
            clearContador();
            cancelPendingTEF();
            activeResetModal();
          }
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.message",
        (channel, payload) => {
          const data = payload.qrcode ? payload.value : payload;
          console.log(data);

          if (
            data === "Cancelando transações de pagamento não confirmadas..."
          ) {
            return setMsg("");
          } else {
            setMsg(data);
          }

          console.log("machine.devices.message", payload);
          window.localStorage.setItem("messageTef", data);

          console.log("machine.devices.message payment trans.", payload);
          if (payload.qrcode) {
            setQrCode(payload.qrcode);
            setButtonCancel(true);
          }
          if (localStorage.getItem("messageTef") == "QRCode não está ativo!") {
            localStorage.removeItem("messageTef");
            setTimeout(() => {
              cancelPendingTEF();
              activeResetModal();
            }, 2000);
          }
          if (
            localStorage.getItem("messageTef") == "INSIRA OU PASSE O CARTAO"
          ) {
          }
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.desktop.error",
        (channel, data) => {
          setLoadingWindow(false);
          setButtonCancel(false);
          if (data.errors.indexOf("ERRO PINPAD") > -1) {
            alert(TRY_AGAIN_MESSAGE);

            if (!isDisabledReShop) {
              cancelOperation(JSON.parse(localStorage.getItem("NsuReshop")));
            }

            viewDispatch("step.payment");
          }
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.payment.error",
        (channel, data) => {
          localStorage.setItem("returnPayment", 1);
          window.desktopApp.publish("machine.devices.tef.undo", {});

          setLoadingWindow(false);
          setButtonCancel(false);
          if (!isDisabledReShop) {
            cancelOperation(JSON.parse(localStorage.getItem("NsuReshop")));
          }
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.undo.completed",
        (channel, data) => {
          console.log("machine.devices.tef.undo.completed", data);
          setButtonCancel(false);
          if (!isDisabledReShop) {
            cancelOperation(JSON.parse(localStorage.getItem("NsuReshop")));
          }
          setLoadingWindow(true);
          setTimeout(() => {
            window.localStorage.removeItem("paymentTransition");
            viewDispatch("step.payment");
          }, 3000);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.cancel.pending.operation.completed",
        (channel, data) => {
          console.log(
            "machine.devices.tef.cancel.pending.operation.completed",
            data
          );

          setLoadingWindow(false);
          setButtonCancel(false);
          if (!isDisabledReShop) {
            cancelOperation(JSON.parse(localStorage.getItem("NsuReshop")));
          }
          viewDispatch("step.payment");
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.debitcard.completed",
        (channel, data) => {
          console.log("machine.devices.tef.debitcard.completed", data);
          setMsg("")
          paymentCompleted(channel, data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.showQrCode",
        (channel, data) => {
          console.log("machine.devices.tef.showQrCode", data);
          setButtonCancel(true);
          setQrCode(data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.digitalWallet.completed",
        (channel, data) => {
          console.log("machine.devices.tef.digitalWallet.completed", data);

          paymentCompleted(channel, data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.creditcard.completed",
        (channel, data) => {
          console.log("machine.devices.tef.creditcard.completed", data);
          setMsg("")
          paymentCompleted(channel, data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.semparar.started",
        (channel, payload) => {
          console.log("machine.devices.semparar.started", payload);

          setLoadingWindow(true);
          setSemPararStarted(true);
          setButtonCancel(false);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.semparar.completed",
        (channel, payload) => {
          console.log("machine.devices.semparar.completed", payload);
          setMsg("")
          setSemPararStarted(false);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.semparar.failed",
        (channel, payload) => {
          console.log("machine.devices.semparar.failed", payload);
          setLoadingWindow(false);

          setSemPararStarted(false);
          setButtonCancel(true);
        }
      );

      return () => {
        window.desktopApp.unsubscribe(
          "machine.devices.tef.creditcard.completed"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.debitcard.completed"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.cancel.pending.operation.completed"
        );
        window.desktopApp.unsubscribe("machine.devices.message");
        window.desktopApp.unsubscribe("machine.devices.tef.undo.completed");
        window.desktopApp.unsubscribe("machine.devices.payment.error");
        window.desktopApp.unsubscribe("machine.devices.desktop.error");
        window.desktopApp.unsubscribe("machine.devices.qrcode.wallets");
        window.desktopApp.unsubscribe("machine.devices.tef.showQrCode ");
        // window.desktopApp.unsubscribe("machine.devices.tef.digitalWallet.completed");
        window.desktopApp.unsubscribe(
          "machine.devices.tef.debitcard.qrcode.getBrand"
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.returnPayments = (data) => {
      console.log(`window.returnPayments | ${JSON.stringify(data)}`);

      if (!data.success) {
        alert(TRY_AGAIN_MESSAGE);

        setMsg("Desfazendo operação...");

        // console.log(`window.Android.cancelOneTransaction | {NSU: ${data.nsu}}`);
        // window.Android.cancelOneTransaction(data.nsu);

        setTimeout(() => {
          console.log(`window.Android.finalizeTransaction | {}`);
          window.Android.finalizeTransaction();
        }, 500);

        //REVIEW - O correto é `nsuValue` ou `data.nsu` do pinpad?
        cancelOperation(nsuValueHelper()).then(() => {
          backToCancel();
        }).catch(() => {
          backToCancel();
        });
      } else {
        const payloadToSendPayment = {
          platform: "android",
          transactionType: paymentMethod,
          description: paymentMethod,
          amount: data.transactionAmount,
          controlCode: String(data.nsu), //REVIEW - Validar se é nsu ou authorizerNsu
          authorizationCode: String(data.authorizationCode), //REVIEW - Validar se é authorizationCode ou acquirerCode
          paymentTransition: paymentMethod,
          tefGateway: "D-TEF",
          dtefCode: data.brandCode,
          brandCode: data.brandCode
        };

        paymentCompleted("", payloadToSendPayment);
      }
    };

    return () => {
      window.returnPayments = undefined;
    };
  }, []);

  /* eslint-enable */

  useEffect(() => {
    if (semPararStarted) {
      setButtonCancel(false);
    } else if (paymentMethod === "SEM PARAR") {
      setButtonCancel(true);
    } else if (paymentMethod !== "QR LINX") {
      localStorage.getItem("payments")
        ? setButtonCancel(false)
        : setButtonCancel(true);
    }
    if (window.localStorage.getItem("AA_ConfigDevice")) {
      setButtonCancel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg]);


  const getValuesStorage = () => {
    const clientValue = JSON.parse(localStorage.getItem("clientValue")) ?? 0;
    const sendClientValue = parseFloat(clientValue.toFixed(2));

    const reshopValue = JSON.parse(localStorage.getItem("reshopValue")) ?? 0;
    const sendRehopValue = parseFloat(reshopValue?.toFixed(2));

    return { sendClientValue, sendRehopValue };
  }

  const paymentCompleted = (channel, data) => {
    console.log(`paymentCompleted ${JSON.stringify(data)}`);

    setLoadingWindow(true);
    setButtonCancel(false);

    let output = { channel, ...data, transactionType: paymentMethod };

    const payments = [output];

    sendSaleOrderService(payments, true);
  };

  const sendSaleOrderService = (payments, hasTef) => {
    console.log(`sendSaleOrderService - PAGAMENTO ${JSON.stringify(payments)}`);
    window.localStorage.setItem("payments", JSON.stringify(payments));
    cartItems[0].authorizationCodeRede = payments[0].authorizationCode;
    cartItems[0].authorizationCodeOnline = payments[0].controlCode;

    sendSaleOrder(cartItems)
      .then((res) => {
        window.localStorage.setItem(
          "saleControl",
          res.payload.orderSale.saleControl
        );

        const {
          errorWhenAuthorizing,
          pathDocFiscal,
          saleControl,
          danfeBase64,
          danfeUrl,
        } = res.payload.orderSale;

        if (
          (hasTef || window.localStorage.getItem("AA_ConfigDevice")) &&
          window.desktopApp
        ) {
          window.desktopApp.publish("machine.devices.tef.confirm", {}); // TO-DO - verificar o tipo de retorno para aprovar
          setTimeout(function () {
            window.desktopApp.publish(
              "machine.devices.tef.pbm.finishTefTransactions",
              {}
            );
          }, 500);
        } else if (window?.Android) {
          printerActionService({
            errorWhenAuthorizing,
            pathDocFiscal,
            saleControl,
            danfeBase64
          });

          window.localStorage.setItem("messageTef", "pagamento realizado com sucesso");

          if (tefMethods.includes(String(paymentMethod || "").toUpperCase())) {
            console.log(`window.Android.confirmTransaction | {NSU: ${payments[0].controlCode}}`);
            window.Android.confirmTransaction(payments[0].controlCode);

            setTimeout(() => {
              console.log(`window.Android.finalizeTransaction | {}`);
              window.Android.finalizeTransaction();
            }, 500);
          }
        }

        if (window.desktopApp) {
          printerActionService({
              errorWhenAuthorizing,
              saleControl,
              danfeUrl
            });
        };

        // ToDo: remover o trecho quando a integração com o pdv morrer
        if (!res.degustOneFinalization) {
          // Quando for fazer a impressão da danfe pelo electron
          if (window.desktopApp) {
            console.log(
              "PathFiscal: ",
              res.payload?.orderSale?.pathDocFiscal || "Não retornado"
            );

            if (
              res.payload.orderSale.prepayment &&
              localStorage.getItem("styleAA") === "DRIVEIN"
            ) {
              window.desktopApp.publish("machine.devices.printer", {
                body: `
                              <div style="text-align: center">
                                  <h3>SENHA: ${res.payload.orderSale.saleControl
                  }</h3>
                                  <h3>${currentLayoutName}</h3>
                                  <span>Cliente:</span>
                                  <h3>${res.payload.orderSale.client.name}</h3>
                                  <h5>Pagamento em dinheiro</h5>
                                  <div>SEM VALIDADE FISCAL</div>
                                  <h5>Não comprova pagamento.</h5>
                                  <div>Retire seu recibo e </div>
                                  <br></br>
                                  <div> aguarde na vaga</div>
                                  <br></br>
                                  <div> para realizar o pagamento e receber seu pedido.</div>
                              </div>
                              <br/>
                              <br/>
                              .
                              `,
              });
            }

            if (
              res.payload.orderSale.prepayment &&
              localStorage.getItem("styleAA") === "DRIVE"
            ) {
              window.desktopApp.publish("machine.devices.printer", {
                body: `
                              <div style="text-align: center">
                                  <h3>SENHA: ${res.payload.orderSale.saleControl
                  }</h3>
                                  <h3>${currentLayoutName}</h3>
                                  <span>Cliente:</span>
                                  <h3>${res.payload.orderSale.client.name}</h3>
                                  <h5>Pagamento em dinheiro</h5>
                                  <div>SEM VALIDADE FISCAL</div>
                                  <h5>Não comprova pagamento.</h5>
                                  <div>Retire seu recibo e siga </div>
                                  <br></br>
                                  <div> para próxima cabine</div>
                                  <br></br>
                                  <div> para realizar o pagamento.</div>
                              </div>
                              <br/>
                              <br/>
                              .
                              `,
              });
            }

            if (
              res.payload.orderSale.prepayment &&
              localStorage.getItem("styleAA") === "SHOP"
            ) {
              console.log(res.payload);
              console.log(res.payload.orderSale);
              window.desktopApp.publish("machine.devices.printer", {
                body: `
                              <div style="text-align: center">
                                  <h1>SENHA: ${res.payload.orderSale.saleControl
                  }</h1>
                                  <h3>${currentLayoutName}</h3>
                                  <span>Cliente:</span>
                                  <h3>${res.payload.orderSale.client.name}</h3>
                                  <h5>Pagamento em dinheiro</h5>
                                  <div>SEM VALIDADE FISCAL</div>
                                  <h5>Não comprova pagamento.</h5>
                                  <div>Retire seu recibo e dirija-se ao caixa </div>
                                  <br></br>
                                  <div> para realizar o pagamento</div>
                                  <br></br>
                                  <div> Informe sua senha ao atendente.</div>
                              </div>
                              <br/>
                              <br/>
                              .
                              `,
              });
            }
            if (
              window.desktopApp &&
              !res.payload.orderSale.errorWhenAuthorizing
            ) {
              window.desktopApp.publish("machine.devices.printerpdf", {
                fileName: `${res.payload.orderSale.pathDocFiscal}`,
              });
            } else if (window.desktopApp && res.payload.orderSale.saleControl) {
              window.desktopApp.publish("machine.devices.printer", {
                body: `
                              <div style="text-align: center">
                                  <h3>SENHA: ${res.payload.orderSale.saleControl}</h3>

                                  <div>A Venda foi registrada, mas ocorreu</div>
                                  <div> um problema para emitir a DANFE, </div>
                                  <div>guarde este comprovante e procure um caixa</div>
                              </div>
                              <br/>
                              <br/>
                              .
                              `,
              });
            }
          }
        }

        window.localStorage.removeItem("payments");

        setLoadingWindow(false);

        if (
          localStorage.getItem("cpf") &&
          localStorage.getItem("paymentTransition") !== "DINHEIRO"
        ) {
          confirmsOperation(JSON.parse(localStorage.getItem("NsuReshop"))).then(
            (res) => {
              if (!res.Result) {
                console.log("Error", res.Message);
              }

              return viewDispatch("step.cupom");
            }
          );
        }
        viewDispatch("step.cupom");
      })
      .catch((e) => {
        if (window.desktopApp && hasTef) {
          window.desktopApp.publish("machine.devices.tef.undo", {});
        }

        if (window.Android) {
          cancelOperation(nsuValueHelper())
            .then(() => {
              backToCancel();
            })
            .catch(() => {
              backToCancel();
            });
        }
      });
  };

  const dinheiro = () => {
    if (everyItensReshop) {
      let payments = [
        {
          creationDate: transactionData(new Date(), "dd/MM/yyyy hh:mm:ss"),
          description: "Resgate Fidelidade",
          controlCode: JSON.parse(localStorage.getItem("IdTransacao")),
          authorizationCode: JSON.parse(localStorage.getItem("NsuReshop")),
          amount: getValuesStorage().sendRehopValue,
          reshop: true,
          paymentCode: getSystemParamsHelper().defaultPaymentCodeReshop ?? 0,
        },
      ];

      setLoadingWindow(true);

      sendSaleOrderService(payments, false);

      return;
    }

    let payments = [
      {
        creationDate: transactionData(new Date(), "dd/MM/yyyy hh:mm:ss"),
        description: "Dinheiro",
        amount: getValuesStorage().sendClientValue,
        reshop: false,
        paymentCode: "1",
      },
    ];

    setLoadingWindow(true);

    sendSaleOrderService(payments, false);
    setButtonCancel(false);
  };

  const testCard = () => {
    if (everyItensReshop) {
      let payments = [
        {
          creationDate: transactionData(new Date(), "dd/MM/yyyy hh:mm:ss"),
          description: "Resgate Fidelidade",
          controlCode: JSON.parse(localStorage.getItem("IdTransacao")),
          authorizationCode: JSON.parse(localStorage.getItem("NsuReshop")),
          amount: getValuesStorage().sendRehopValue,
          reshop: true,
          paymentCode: getSystemParamsHelper().defaultPaymentCodeReshop ?? 0,
        },
      ];

      setLoadingWindow(true);

      sendSaleOrderService(payments, false);

      return;
    }

    let payments = [
      {
        creationDate: transactionData(new Date(), "dd/MM/yyyy hh:mm:ss"),
        description: "test",
        amount: getValuesStorage().sendClientValue,
        reshop: false,
        paymentCode: "1",
      },
    ];

    setLoadingWindow(true);

    sendSaleOrderService(payments, false);
  };

  const creditCard = (callback) => {
    if (window.desktopApp) {
      /* paymentForm = 0: INDEFINIDO 1: A VISTA 2: PARCELADO LOJA*/
      window.desktopApp.publish("machine.devices.tef.creditcard.getBrand", {
        amount: getValuesStorage().sendClientValue,
        installmentAmount: getValuesStorage().sendClientValue,
        type: 0,
        paymentForm: 1,
        installments: 1,
      });
    } else {
    }
  };

  const debitCard = (callback) => {
    if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.tef.debitcard.getBrand", {
        amount: getValuesStorage().sendClientValue,
        installmentAmount: getValuesStorage().sendClientValue,
        type: 0,
        paymentForm: 1,
        installments: 1,
      });
    }
  };

  const voucherCard = (callback) => {
    if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.tef.debitcard.vouchercard.getBrand", {
        amount: getValuesStorage().sendClientValue,
        installmentAmount: getValuesStorage().sendClientValue,
        type: 0,
        paymentForm: 1,
        installments: 1,
      });
    }
  };

  const pix = (callback) => {
    if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.tef.digitalWallet.getBrand", {
        amount: getValuesStorage().sendClientValue,
        installmentAmount: getValuesStorage().sendClientValue,
        type: 0,
        paymentForm: 1,
        installments: 1,
      });
    }
  };

  const qrCode = async (callback) => {
    if (cartItems?.length && !isDisabledReShop) {
      const discountsCart = await consultDiscounts(
        localStorage.getItem("cpf"),
        cartItems
      );
      setDiscounts(discountsCart);
    }
    const { clientValue, reshopValue } = reshopCalculateValues(cartItems);

    localStorage.setItem("reshopValue", JSON.stringify(reshopValue));

    if (localStorage.getItem("cpf")) {
      processesOperation(cartItems).then((res) => {
        localStorage.setItem("NsuReshop", JSON.stringify(res.Nsu));
        localStorage.setItem("IdTransacao", JSON.stringify(res.IdTransacao));
        localStorage.setItem(
          "PontosCreditados",
          JSON.stringify(res.PontosCreditados)
        );
      });
    }

    const sendClientValue = parseFloat(clientValue.toFixed(2));

    if (sessionStorage.getItem(LINXPAY_POSID)) {
      await createPaymentService({ amount: sendClientValue }, ({ dataCheck }) => {
        console.log("machine.devices.tef.debitcard.completed", dataCheck);
        setMsg("")
        paymentCompleted('channel', {
          dateTimeInsert: dataCheck.data.date_time,
          dtefCode: 0,
          sitefCode: 0,
          transactionType: paymentMethod,
          description: paymentMethod,
          amount: Number(dataCheck.data.value),
          controlCode: dataCheck.data.payment_id,
          authorizationCodeOnline: dataCheck.data.order_id,
          paymentTransition: paymentMethod,
          // paymentCode: 0,
          qrLinxTransactionId: dataCheck.data.transaction_id,
          qrLinxTradingName: dataCheck.data.client_payer_payments[0].account.bank.name,
          transactionDatetime: dataCheck.data.date_time,
        });
      }, () => {
        // TODO: Voltar para a tela de pagamentos
        // navigateToPaymentsMethod();
        cancelPendingTEF();
        setMsg('Ocorreu um erro ao criar um Pagamento através do QRLinx');
      })
        .then(
          ({ data }) => {
            setQrCode({ ...data?.data, amount: sendClientValue });
            setButtonCancel(true);
          }
        )
    } else {
      setMsg("QrLinx não foi ativado com sucesso, aguarde ou verifique suas configurações")
      if (timeoutFallback) {
        clearTimeout(timeoutFallback);
      }

      timeoutFallback = setTimeout(() => {
        cancelPendingTEF();
        // TODO: Voltar para a tela de pagamentos
      }, 3000);
    }

    // if (window.desktopApp) {
    //   window.desktopApp.publish(
    //     "machine.devices.tef.debitcard.qrcode.getBrand",
    //     {
    //       amount: sendClientValue,
    //       installmentAmount: sendClientValue,
    //       type: 0,
    //       paymentForm: 1,
    //       installments: 1,
    //       orderId: uuidv4(),
    //     }
    //   );
    // }
  };

  const pedePronto = async (callback) => {
    viewDispatch("step.pedePronto");
  };

  const semParar = async (callback) => {
    if (window.desktopApp) {
      const { objectSequential } = await nextSequence("SEMPARAR");

      window.desktopApp.publish(
        "machine.devices.tef.debitcard.semparar.getBrand",
        {
          amount: getValuesStorage().sendClientValue,
          installmentAmount: getValuesStorage().sendClientValue,
          type: 0,
          paymentForm: 1,
          installments: 1,
          orderId: uuidv4(),
          sequenceId: objectSequential.sequence,
        }
      );
    }
  };

  return (
    <Content
      title={
        paymentMethod === "FIDELIDADE" ||
          paymentMethod === "DINHEIRO" ||
          paymentMethod === "TESTEQA"
          ? ""
          : paymentMethod === "QR LINX" && qrcode?.qrcode !== ""
            ? "Escaneie o QR CODE usando seu aplicativo de pagamento"
            : msg
      }
      contentChildren={true}
      className="full"
      bgColor="background-primary"
    >
      {modalQrWarn && (
        <ScrollContainer>
          <div className="blackout" onClick={() => (setModalQrWarn(false), clearTimer(getDeadTime()))}>
            <ModalPayments
              onPrimaryClick={() => {
                window.localStorage.removeItem("paymentTransition")
                cancelPendingTEF();
              }}
              mTitle="Atenção"
              mSubtitle="O Pagamento via QRCode será cancelado!
              Caso o valor tenha sido debitado da conta, o mesmo será
              estornado em até 48h."
              mTextBtnPrimary="Cancelar a Cobrança"
              mTextBtnSecondary="Voltar"
              mBtnPrimarClassName="primary button-primary-qrwarn"
              mBtnSecondaryClassName="outline-primary button-primary-qrwarn button-primaryBobs"
            />
          </div>
        </ScrollContainer>
      )}
      {!qrcode.qrcode ? (
        !loadingWindow ? (
          <>
            <div className="leitora">
              {paymentMethod !== "QR LINX" ? (
                <img src={leitora} alt="logo" />
              ) : (
                <div id="loader"></div>
              )}
            </div>
          </>
        ) : (
          <div id="wrapper-loader">
            <div id="loader"></div>
            {paymentMethod === "DINHEIRO" ? (
              <p>{generatingPassword}</p>
            ) : localStorage.getItem("returnPayment") ? (
              <p>{waitScreen}</p>
            ) : semPararStarted ? (
              <p>{waitText}</p>
            ) : (
              <p>{waitInvoice}</p>
            )}
          </div>
        )
      ) : localStorage.getItem("messageTef") !==
        "pagamento realizado com sucesso" ? (
        <>
          <span className="totalQrcode">
            {totalPayable} {currency(localStorage.getItem("clientValue"))}
          </span>
          <h2 className="TimerCouldown">{timer}</h2>
          <QrCode value={qrcode?.qrcode}></QrCode>
          <div className="borderClassPayment">
            <h4 className="textQrCode">
              {qrcodeText}
            </h4>
            <div>
              <div>
                {
                  qrcode?.wallets?.map(({ logo_link }) => (
                    <img
                      className="logosQrlinx"
                      src={logo_link}
                      alt={logo_link}
                    />
                  ))
                }
                {isShowIconPaymentPix && (
                  <section>
                    <img
                      className="QrlinxPayment"
                      src={paymentPix}
                      alt="qr"
                      width="64px" />
                  </section>
                )}
              </div>
              {buttonCancel &&
                localStorage.getItem("nomeCampanha") === "picpay" && (
                  <h3 className="descriptionCampgning">
                    {localStorage.getItem("mensagemCampanha")}
                  </h3>
                )}
            </div>
          </div>
        </>
      ) : (
        <div>
          <span className="totalQrcode">
            Pagamento concluído
            <i class="fas fa-check check-color"></i>
          </span>
          <QrCode value={qrcode}></QrCode>
          <div className="borderClassPayment">
            <h4 className="textQrCode">
              {qrcodeText}
            </h4>
            <div>
              <img className="logosQrlinx" src={picpay} alt={picpay} />
            </div>
          </div>
        </div>
      )}

      {modal && (
        <div className="blackout" onClick={() => (errorPaymenty())}>
          <ModalPayments
            mClassName="tefError textWarningTablet"
            onPrimaryClick={() => {
              errorPaymenty();
              setModalWarnings(false);
            }}
            mTitle={`${messageTefWarningsLabell}`}
            mTextBtnPrimary="OK"
            mTextBtnSecondary={false}
            mBtnPrimarClassName="primary tefError"
          />
        </div>
      )}
      {buttonCancel &&
        paymentMethod !== "TESTEQA" &&
        paymentMethod !== "DINHEIRO" &&
        paymentMethod !== "FIDELIDADE" && (
          <section className={qrcode?.qrcode === "" ? "cancelTEF" : "cancelQRCode"}>
            <Button
              className="btnbackpayment"
              text="Escolha outra forma de pagamento"
              buttonUIType="outline-primary"
              buttonUI={"block"}
              handleClick={() => {
                cancelPendingTEF();
                activeResetModal();
              }}
            />
          </section>
        )}
      {buttonCancel && localStorage.getItem("descontoReshop") > 0 && (
        <section className="discountPaymentReshop">
          {/* <h1>Parabéns você Ganhou <strong className="discountPaymentReshopValue"> R${localStorage.getItem("descontoReshop")}</strong> de Desconto</h1> */}
        </section>
      )}
    </Content>
  );
};
export default PaymentTransitionContainer;
