import React, {memo, useState, useEffect} from "react";

import {Content, Section, SectionBody} from "./style";

import CompositionItemAdd from "../composition-item-add";
import CompositionItemWithdraw from "../composition-item-withdraw";

import { newUniqueId } from "../../utils/getUniqueId";

const Composition = (props) => {
  const {
    compositions,
    setRadio,
    onChangeValue,
    item
  } = props;

  const $ = (selector) => document.querySelector(selector);

  const handleCompositions = () =>
    compositions.map((item, index) =>
      compositions[index].selectedAmount = 0);

  const handleToggleArrowTag = (element) => {
    const classListToggle = (classes) => classes
      .map(classText => element.classList.toggle(classText))
      classListToggle(['fa-angle-down', 'fa-angle-right']);
  }

  useEffect(() => {
    handleCompositions();
  },[])

  const handleChangeSectionComposition = (event) => {
    handleToggleArrowTag($('.fa-angle-down'));

    const idCurrentTagI = event.target.getAttribute('idtagi');
    handleToggleArrowTag($(`#${idCurrentTagI}`));

    const compositionBodyActive = $(".composition-body.active");
    if (compositionBodyActive)
      compositionBodyActive.classList.remove("active");
  };

  const onChangeItem = (item) => {
    setRadio(item);
    onChangeValue(item);
  };

  const generateSections = () => {
    const itemsSections = [];

    const sections = [
      {
        label: "Retirar",
        type: 1,
        id: `composition-input-retirar-${Math.floor(Math.random() * 10)}`
      },
      {
        label: "Adicionar",
        type: 2,
        id: `composition-input-adicionar-${Math.floor(Math.random() * 10)}`
      }
    ];

    sections.forEach((sectionItem, index) => {
      const hash = newUniqueId();
      let classI = "fa fa-angle-right";
      let classSectionBody = "composition-body";

      if (index === 0) {
        classI = "fa fa-angle-down";
        classSectionBody = "composition-body active";
      }

      itemsSections.push(<Section key={index}>
        <input
          type="radio"
          name="composition"
          id={sectionItem.id}
          idtagi={`tag-i-${sectionItem.id}`}
          onChange={handleChangeSectionComposition}
        />
        <label htmlFor={sectionItem.id}>
          {sectionItem.label}
          <i id={`tag-i-${sectionItem.id}`} className={classI} aria-hidden="true" />
        </label>
        <SectionBody className={classSectionBody}>
          {sectionItem.type === 1 && <CompositionItemWithdraw
            selectedProduct={item}
            onChangeItem={onChangeItem}
            key={hash}
            compositions={compositions} />}
          {sectionItem.type === 2 && <CompositionItemAdd
            selectedProduct={item}
            onChangeItem={onChangeItem}
            key={hash}
            compositions={compositions} />}
        </SectionBody>
      </Section>);
    });

    return itemsSections;
  };

  return (
    <Content>
      {generateSections()}
    </Content>
  );
};

export default memo(Composition);
