import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useCategoriesNavigate } from "../../hook/categoriesHook";
import { useVertical } from "../../hook/verticalHook";
import { NextItemSlider, PreviousItemSlider } from "../ArrowsCarousel";
import { ItemProduct, SkeletonItemProduct } from "./Item";

import { Container, BackButton, ContentBackButton, Content } from "./styles";
import { useAuth } from "../../hook/authHook";
import classNames from "classnames";
import { isSmartPos } from "../../../aaone/configuration";
import { Slider } from "../Slider";

let currentProducts = [];
export const Products = () => {
  const sliderRef = useRef();

  const { isVerticalScreen } = useVertical();
  const { reshop } = useAuth();

  const {
    currentCategoryId,
    currentCategoryIsFidelity,
    categoryProducts,
    setCategoryProducts,
  } = useCategoriesNavigate();

  const [showArrowBackButton, setShowArrowBackButton] = useState(false);

  const totalProducts = categoryProducts.length;

  const responsiveSerialize = (options) => {
    const { breakpoint, quantity, rows = 2 } = options;

    return {
      breakpoint,
      settings: {
        rows: isSmartPos
          ? totalProducts <= 2
            ? 1
            : rows
          : totalProducts <= 5
          ? 1
          : rows,
        slidesToShow: quantity,
        slidesToScroll: quantity,
      },
    };
  };

  const settingsCarousel = useMemo(() => {
    setShowArrowBackButton(false);
    return {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      rows: totalProducts <= 5 ? 1 : 2,
      slidesToShow: 5,
      slidesToScroll: 5,
      slidesPerRow: 1,
      prevArrow: (
        <PreviousItemSlider
          disabled={currentCategoryId === null}
          hasArrowBackButton={setShowArrowBackButton}
        />
      ),
      nextArrow: <NextItemSlider disabled={currentCategoryId === null} />,
      responsive: [
        responsiveSerialize({
          breakpoint: 1920,
          quantity: 5,
          rows: isVerticalScreen ? 3 : 2,
        }),
        responsiveSerialize({ breakpoint: 1366, quantity: 5 }),
        responsiveSerialize({ breakpoint: 1080, quantity: 4 }),
        responsiveSerialize({ breakpoint: 480, quantity: 2 }),
        responsiveSerialize({ breakpoint: 360, quantity: 2, rows: 2 }),
      ],
    };
  }, [currentCategoryId, totalProducts]);

  const isAuthReshop = !!reshop.cpfFidelity;

  const isCategoryFidelity = useMemo(
    () =>
      currentCategoryIsFidelity &&
      !isAuthReshop &&
      (categoryProducts.length === 0 || categoryProducts.length > 0),
    [isAuthReshop, categoryProducts, currentCategoryIsFidelity]
  );

  const handleBackProduct = useCallback(() => {
    setCategoryProducts(categoryProducts[0]?.backProducts);
  }, [categoryProducts]);

  useEffect(() => {
    if (isAuthReshop) {
      currentProducts = undefined;
    }

    if (categoryProducts !== currentProducts && sliderRef?.current) {
      currentProducts = categoryProducts;
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 200);
    }
  }, [isAuthReshop, categoryProducts, sliderRef?.current]);

  return (
    <>
      <Container
        className={classNames(
          { isCategoryFidelity, main__in_smart_pos: isSmartPos },
          "main__products"
        )}
      >
        <div
          className={classNames(
            { isCategoryFidelity, is__smart__pos__container: isSmartPos },
            "carousel__products"
          )}
        >
          {isCategoryFidelity ? (
            <>
              <div className="warn-info">
                <div className="title">
                  Reshop - Resgate de Produtos por Pontos
                </div>
                <div>
                  Para acessar esta categoria é necessário identificar o cliente
                  no Reshop na venda
                </div>
              </div>
            </>
          ) : (
            <Content className={classNames({ container__mobile: isSmartPos })}>
              <ContentBackButton
                hasArrowButton={showArrowBackButton}
                className={classNames({ isSmartPos })}
              >
                <BackButton
                  showButtonBack={!!categoryProducts[0]?.isLevel}
                  onClick={() => handleBackProduct()}
                  className={classNames({ isSmartPos })}
                >
                  <i className="material-icons">arrow_back</i>
                  <span className="text">VOLTAR</span>
                </BackButton>
              </ContentBackButton>
              <Slider
                {...settingsCarousel}
                className={classNames({
                  no_arrow__has__button_back:
                    !!categoryProducts[0]?.isLevel && !showArrowBackButton,
                })}
                ref={sliderRef}
              >
                {categoryProducts.map((currentProduct, index) => {
                  const product = currentProduct?.product ?? currentProduct;
                  if (currentCategoryId === null) {
                    return <SkeletonItemProduct key={index} />;
                  } else if (currentProduct) {
                    return (
                      <ItemProduct
                        data={{
                          ...currentProduct,
                          pointPrice: currentProduct?.pointPrice,
                          categoryId: currentCategoryId,
                          categoryIsFidelity: currentCategoryIsFidelity,
                          code: String(product.productCode),
                        }}
                        key={String(`${currentProduct._id}-${index}`)}
                        allProducts={categoryProducts}
                        setProductList={(products) =>
                          setCategoryProducts(products)
                        }
                      >
                        {
                          product.allDescriptions ?
                            product.allDescriptions.descriptionPDV :
                            product.description
                        }
                      </ItemProduct>
                    );
                  }

                  return <></>;
                })}
              </Slider>
            </Content>
          )}
        </div>
      </Container>
    </>
  );
};
