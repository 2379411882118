import classNames from "classnames";
import React, { memo } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import { Container } from "./styles";
import { isSmartPos } from "../../../aaone/configuration";

export const PreviousItemSlider = memo(({ disabled, className, onClick }) => {
  const button__disabled =
    disabled ||
    String(className || "")
      .split(" ")
      .includes("slick-disabled");

  return (
    <Container
      role="button"
      className={classNames({
        button__navigation: true,
        button__left: true,
        button__disabled,
        skeleton: disabled,
        isSmartPos: isSmartPos,
      })}
      onClick={disabled ? () => {} : onClick}
    >
      <MdKeyboardArrowLeft size={20} />
    </Container>
  );
});

export const NextItemSlider = memo(({ disabled, className, onClick }) => {
  const button__disabled =
    disabled ||
    String(className || "")
      .split(" ")
      .includes("slick-disabled");

  return (
    <Container
      role="button"
      className={classNames({
        button__navigation: true,
        button__right: true,
        button__disabled,
        skeleton: disabled,
        isSmartPos: isSmartPos,
      })}
      onClick={disabled ? () => {} : onClick}
    >
      <MdKeyboardArrowRight size={20} />
    </Container>
  );
});
