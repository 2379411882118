import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  flex: 1;

  .quantity__wrapper {
    display: grid;
    grid-template-columns: repeat(3, auto);

    grid-gap: 0.5rem 0.5rem;
    gap: 0.5rem 0.5rem;

    flex: 1;

    .button {
      font-size: 0px !important;
      text-align: center;

      background-color: #ccc;
      border-radius: 50%;

      color: #797979;

      height: 2rem;
      width: 2rem;
      padding: 0;

      &.active {
        background-color: ${({ theme }) => theme.colors.purple};
        color: #fff;
      }
    }

    input {
      border-radius: 8px;
      border: 2px solid #ccc;

      text-align: center;
      font-weight: 700;

      max-width: 89px;
      width: 100%;
    }
  }

  .button__code {
    border: 2px solid ${({ theme }) => theme.colors.purple} !important;
    border-radius: 4px;

    color: ${({ theme }) => theme.colors.purple};
    background: #fff;

    padding: 0.45rem 1.25rem !important;

    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;

    font-weight: 700;

    margin-left: 1rem;

    span {
      font-size: 0.75rem;
    }
  }
`;
