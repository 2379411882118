import React, {memo, useEffect, useState} from "react";
import {
  Content,
  Items,
  Item,
  Image,
  SelectionFlag,
  TitleItem
} from "./style.js";

const CompositionItemWithdraw = (props) => {
  const {
    onChangeItem,
    selectedProduct,
    compositions
  } = props;
  const [items, setItems] = useState(compositions);

  const handleClick = (event, index) => {
    let image = null;
    let span = null;
    const toggleImage = () => {
      if (image !== null && image.constructor === HTMLImageElement)
        image.classList.toggle('no-active');

      if (span !== null && span.constructor === HTMLSpanElement) {
        span.classList.toggle('active');
        const active = !span.classList.contains('active');
        handlingItemShipment(index, active);
      }
    }

    const allElementsChild = Array.from(event.target.closest('li').children);
    allElementsChild.map((childElement) => {
      if (childElement.constructor === HTMLImageElement)
        image = childElement;

      if (childElement.constructor === HTMLSpanElement)
        span = childElement;
    });

    toggleImage();
  };

  const handlingItemShipment = (index, active) => {
    const newItems = [...items];
    const newProductSelected = {...selectedProduct};

    if (active) newItems[index].selectedAmount++;
    else newItems[index].selectedAmount--;

    newProductSelected.compositions[index] = newItems[index];

    onChangeItem(newProductSelected);
    setItems(newItems);
  };

  const generateItem = () => {
    const allCompositions = [];

    items.map((item, indexComposition) => {
      if(item.operationType === "R"){
        let index = item.product.productCode;
        let classActive = !!item.selectedAmount ? '' : 'active';
        let classNoActive = !!item.selectedAmount ? 'no-active' : '';

        allCompositions.push(
          <Item key={`item-${index}`}
                onClick={(event) => handleClick(event, indexComposition)}>
          <SelectionFlag key={`selection-flag-${index}`} className={classActive} />
          <input key={`input-${index}`} className={classActive} type="checkbox"/>
          <Image key={`image-${index}`} className={classNoActive} src={item.product.image} alt={item.product.image}/>
          <TitleItem key={`p-${index}`} className={`${classActive} composition-tittle`}>{item.product.description}</TitleItem>
        </Item>)
      }
    })
    return allCompositions;
  };

  return (
    <>
      <Content>
        <Items key={0}>
          {generateItem()}
        </Items>
      </Content>
    </>
  );
}
export default memo(CompositionItemWithdraw);
