import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  cashdeskOpenService,
  getLastCashdeskService,
  cashdeskCloseService,
  cashWithdrawService,
  cashSupplyService,
  cashdeskCloseDayService,
  cashdeskReduceService,
  chargebackService,
  armoredCarService,
  listCashdeskByPeriodService,
  armoredCarWithdrawListService,
  armoredCarWithdrawCancelService,
  getCashdeskOpenDate,
  getCanSaleStatus,
} from "../../../services/cashdeskService";
import { getRetailerId, getTerminalID } from "../../../aaone/configuration";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { add, compareAsc } from "date-fns";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { currencyMask } from "../../helpers/masks";

export const CashdeskContext = createContext({});

export const CashDeskProvider = ({ children }) => {
  const [cashdesk, setCashdesk] = useState(null);
  const [cashdeskOpenDate, setCashdeskOpenDate] = useState(null);
  const [listReducedCashdesk, setListReducedCashdesk] = useState([]);
  const [showCashierClosingDay, setShowCashierClosingDay] = useState(false);
  const [notifyWithdrawCanSale, setNotifyWithdrawCanSale] = useState(false);
  const [blockWithdrawSales, setBlockWithdrawSales] = useState({});

  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();

  const isCashdeskOpen = !(
    !cashdesk ||
    cashdesk?.closingDate ||
    cashdesk?.reducingDate
  );
  const isCashdeskOnCurrentDay = cashdeskOpenDate
    ? compareAsc(
        new Date(cashdeskOpenDate?.mandatoryClosingDate),
        new Date()
      ) !== -1
      ? true && cashdeskOpenDate?.isActive
      : !cashdeskOpenDate?.isActive
    : true;

  const getLastCashdesk = useCallback(async () => {
    try {
      const dataCashdesk = await getLastCashdeskService();
      const dataOpenDate = await getCashdeskOpenDate();

      setCashdeskOpenDate(dataOpenDate);

      if (dataCashdesk.errors) {
        console.log("getLastCashdesk não encontrou nenhum caixa");
        setCashdesk(null);
      } else {
        setCashdesk(dataCashdesk);
      }
    } catch (err) {
      setShowLoading(false);
      console.error(err);
    }
  }, []);

  const getListCashDesk = useCallback(async () => {
    await listCashdeskByPeriodService({
      initialDate: formatDateHelper(
        new Date(cashdeskOpenDate.openingDate),
        "yyyy-MM-dd"
      ),
      finalDate: formatDateHelper(
        add(new Date(cashdeskOpenDate?.openingDate), { days: 1 }),
        "yyyy-MM-dd"
      ),
    }).then((res) => {
      const filtered = res.filter(({ closingDate = null }) => !closingDate);

      setListReducedCashdesk(filtered);
    });
  }, [cashdesk]);

  // Função para realizar a abertura de caixa
  const cashdeskOpen = useCallback(
    async ({
      openingAmount,
      userId,
      user,
      userReferenceId,
      responsableId,
      responsable,
      responsableReferenceId,
      paymentType,
    }) => {
      try {
        if (cashdesk && !isCashdeskOnCurrentDay) {
          showAlert({
            message: `Não pode executar uma abertura de caixa com o dia encerrado.`,
          });

          throw false;
        }

        const data = await cashdeskOpenService({
          posId: getTerminalID().toString(),
          retailerId: getRetailerId(),
          userId,
          user,
          userReferenceId,
          responsableId,
          responsable,
          responsableReferenceId,
          openingAmount,
          paymentType,
        });
        // setCashdesk(data.cashDesk);
        getLastCashdesk();
        if (data?.code === 400) {
          setShowLoading(false);
          showAlert({
            message: data.errors[0].message,
          });
        }
        return { ...data.cashDesk, isNewDay: data?.isNewDay };
      } catch (err) {
        console.error(err);

        if (err) {
          setShowLoading(false);
          showAlert({
            message: `Ocorreu um erro ao executar a abertura de caixa`,
          });
        }

        throw err;
      }
    },
    [cashdesk, showAlert, isCashdeskOnCurrentDay]
  );

  // Função para realizar o fechamento de caixa
  const cashdeskClose = useCallback(
    async ({
      closingValues,
      description,
      userId,
      responsableId,
      amount,
      paymentType,
      cashdeskId,
      posId,
      operationalValues,
      isCloseCashDeskLeftOverValueZero,
    }) => {
      try {
        if (!cashdeskOpenDate.isActive) {
          showAlert({
            message:
              "Não pode executar um fechamento de caixa com o dia encerrado",
          });
          setShowLoading(false);

          return;
        } else if (cashdeskOpenDate.closingDate) {
          showAlert({
            message:
              "Não pode executar um fechamento de caixa sem um caixa aberto",
          });
          setShowLoading(false);

          return;
        }

        const body = {
          // posId: String(getTerminalID()),
          posId: String(posId),
          retailerId: getRetailerId(),
          cashdeskId: cashdeskId ?? cashdesk.id,
          userId,
          responsableId,
          amount,
          description,
          closingUserId: userId,
          closingResponsableId: responsableId,
          closingValues,
          paymentType,
          operationalValues,
          isCloseCashDeskLeftOverValueZero,
        };

        const data = await cashdeskCloseService(body);

        if (data.id === cashdesk.id) {
          setCashdesk(data);
        } else {
          getListCashDesk();
        }
        return data;
      } catch (err) {
        console.error(err);
        showAlert({
          message: `Ocorreu um erro ao executar o fechamento de caixa`,
        });
        setShowLoading(false);

        throw err;
      }
    },
    [cashdesk, showAlert, getListCashDesk]
  );

  // Função para realizar o encerramento de dia
  const cashdeskCloseDay = useCallback(async () => {
    try {
      setShowLoading();
      if (!cashdesk) {
        showAlert({
          message: "Não foi encontrado um dia aberto.",
        });
        setShowCashierClosingDay(false);
        setShowLoading(false);

        return;
      } else if (!cashdeskOpenDate?.isActive) {
        showAlert({
          message:
            "Não pode executar o encerramento de dia com o dia já encerrado.",
        });
        setShowLoading(false);

        return;
      } else if (!cashdesk.closingDate) {
        showAlert({
          message:
            "Não pode executar o encerramento de dia com o caixa aberto.",
        });
        setShowLoading(false);

        return;
      }

      const data = await cashdeskCloseDayService({
        posId: String(getTerminalID()).toString(),
        retailerId: getRetailerId(),
      });

      if (data.errors) {
        showAlert({ message: data.errors[0].message });
        throw data.errors;
      }

      setCashdesk(data);
      setShowCashierClosingDay(false);
      setShowLoading(false);

      showAlert({
        message: `Encerramento do dia ${formatDateHelper(
          new Date(data.openingDate),
          "dd/MM/yyyy"
        )} realizado com sucesso.`,
      });
    } catch (err) {
      console.error(err);

      if (!err[0]) {
        showAlert({
          message: `Ocorreu um erro ao executar o encerramento de dia`,
        });
      }

      setShowLoading(false);

      throw err;
    }
  });

  // Função para realizar a redução de caixa
  const cashdeskReduce = useCallback(
    async ({
      amount,
      description,
      numberEnvelop,
      userId,
      user,
      responsableId,
      responsable,
    }) => {
      try {
        if (!cashdesk && !cashdesk.closingDate) {
          showAlert({
            message: "Não pode reduzir um caixa fechado",
          });
          setShowLoading(false);
        } else if (cashdesk.reducingDate) {
          showAlert({
            message: "O caixa já está reduzido",
          });
          setShowLoading(false);
        }

        const data = await cashdeskReduceService({
          posId: String(getTerminalID()).toString(),
          retailerId: getRetailerId(),
          cashdeskId: cashdesk.id,
          user,
          responsable,
          userId,
          responsableId,
          amount,
          description,
          numberEnvelop,
          isBlindCashDesk: true,
        });

        setCashdesk(data.cashDesk);
        return data.cashDesk;
      } catch (err) {
        console.error(err);
        showAlert({
          message: `Ocorreu um erro ao executar a redução de caixa`,
        });
        setShowLoading(false);

        throw err;
      }
    },
    [cashdesk, showAlert]
  );

  // Função para realizar suprimento
  const cashSupply = useCallback(
    async ({
      amount,
      description,
      paymentType,
      userId,
      responsableId,
      operatorName,
      adminName,
      responsableReferenceId,
      userReferenceId,
    }) => {
      try {
        const data = await cashSupplyService({
          posId: getTerminalID().toString(),
          retailerId: getRetailerId(),
          cashdeskId: cashdesk.id,
          amount,
          description,
          userId,
          responsableId,
          paymentType,
          operatorName: cashdesk.user,
          userReferenceId: cashdesk.userReferenceId,
          adminName,
          responsableReferenceId,
        });

        setCashdesk(data.cashDesk);
        return data.cashDesk;
      } catch (err) {
        console.error(err);
        setShowLoading(false);
      }
    },
    [cashdesk]
  );

  // Função de estorno de Sangria/Suprimento
  const chargeback = useCallback(async ({ movementId }) => {
    try {
      const data = await chargebackService({
        posId: getTerminalID().toString(),
        retailerId: getRetailerId(),
        movementId,
      });

      setCashdesk(data.cashDesk);
      return data;
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  }, []);

  // Função para realizar um carro forte
  const armoredCar = useCallback(
    async ({ description, numberEnvelop, adminName, responsableId }) => {
      try {
        const data = await armoredCarService({
          posId: getTerminalID().toString(),
          retailerId: getRetailerId(),
          cashdeskId: cashdesk.id,
          userId: cashdesk.userId,
          description: description,
          numberEnvelop: numberEnvelop,
          adminName,
          responsableId,
        });

        if (data.errors) {
          showAlert({
            message: data.errors[0].message,
          });

          throw data.errors[0].message;
        }

        setCashdesk(data);

        return data;
      } catch (err) {
        console.error(err);
        setShowLoading(false);

        return null;
      }
    },
    [cashdesk]
  );

  // Função para listar carro forte
  const armoredCarList = useCallback(
    async ({}) => {
      try {
        const data = await armoredCarWithdrawListService({
          posId: getTerminalID().toString(),
          retailerId: getRetailerId(),
          armoredCarID: cashdesk.armoredCarId,
        });

        setCashdesk(data);
      } catch (err) {
        console.error(err);
        setShowLoading(false);
      }
    },
    [cashdesk]
  );

  // Função para cancelar um carro forte
  const armoredCarCancel = useCallback(
    async ({}) => {
      try {
        const data = await armoredCarWithdrawCancelService({
          retailerId: getRetailerId(),
          armoredCarId: cashdesk.armoredCarId,
        });

        setCashdesk(data);
      } catch (err) {
        console.error(err);
        setShowLoading(false);
      }
    },
    [cashdesk]
  );

  // Função para realizar sangria
  const cashWithdraw = useCallback(
    async ({
      amount,
      description,
      numberEnvelop,
      paymentType,
      responsableId,
      userId,
      operatorName,
      userReferenceId,
      adminName,
      responsableReferenceId,
      cashdeskId,
      posId,
    }) => {
      try {
        const systemParams = getSystemParamsHelper();
        const data = await cashWithdrawService({
          withdrawingAllowValueGreaterThanCountAmount:
            systemParams?.withdrawingAllowValueGreaterThanCountAmount || false,
          posId: posId ?? getTerminalID().toString(),
          retailerId: getRetailerId(),
          cashdeskId: cashdeskId ?? cashdesk.id,
          amount,
          description,
          numberEnvelop,
          userId,
          responsableId,
          paymentType,
          operatorName: cashdesk.user,
          userReferenceId: cashdesk.userReferenceId,
          adminName,
          responsableReferenceId,
        });

        if (data.errors) {
          showAlert({ message: data.errors[0].message });
          throw data.errors[0].message;
        }

        if (data.id === cashdesk.id) {
          setCashdesk(data);
        }

        return data;
      } catch (err) {
        console.error(err);
        setShowLoading(false);
      }
    },
    [cashdesk]
  );

  /**
   * Verifica se o operador pode ou não continuar vendendo e exibe alertas
   */
  const verifyWithdrawStatus = useCallback(async () => {
    try {
      return await getCanSaleStatus(cashdesk?.id).then((data) => {
        setBlockWithdrawSales({
          blockAmount: data.blockAmount,
          block: data.block,
        });

        setNotifyWithdrawCanSale({
          notify: data.notify,
          notifyAmount: data.notifyAmount,
          notified: false,
        });

        return data;
      });
    } catch (err) {
      if (window.Android) {
        console.log(`error on verifyWithdrawStatus -> ${JSON.stringify(err)}`);
      } else {
        console.error("error on verifyWithdrawStatus ->", err);
      }
    }
  }, [cashdesk]);

  /**
   * Exibe o alerta para o operador realizar uma sangria
   *
   * @param {*} cb - callback para transferir operador.
   */
  const showAlertWithdrawCanSale = useCallback(
    async (cb = () => {}) => {
      try {
        if (!notifyWithdrawCanSale?.notified) {
          const data = await verifyWithdrawStatus();

          if (data.notify && !notifyWithdrawCanSale.notified) {
            showAlert({
              message: `O valor de dinheiro no caixa ultrapassou o valor de ${currencyMask(
                notifyWithdrawCanSale.notifyAmount
              )}.\nDeseja realizar a sangria?`,
              onConfirmText: "Sim",
              handleConfirm: () => {
                cb();
                setNotifyWithdrawCanSale({
                  ...notifyWithdrawCanSale,
                  notified: true,
                });
              },
              handleCancel: () => {
                setNotifyWithdrawCanSale({
                  ...notifyWithdrawCanSale,
                  notified: true,
                });
              },
            });
          }

          if (data.block) {
            showAlert({
              message: `O valor de dinheiro no caixa ultrapassou o limite permitido.\nPara realizar novas vendas será necessário realizar a sangria.`,
              onConfirmText: "Sim",
              handleConfirm: () => {
                cb();
              },
              handleCancel: () => {},
            });
          }

          if (!data.block && !data.notify) {
            setNotifyWithdrawCanSale({
              ...notifyWithdrawCanSale,
              notified: true,
            });
          }

          return (
            ((!notifyWithdrawCanSale?.notified && data.notify) ||
              data?.block) ??
            false
          );
        } else {
          return false;
        }
      } catch (err) {
        if (window.Android) {
          console.log(
            `error on showAlertWithdrawCanSale -> ${JSON.stringify(err)}`
          );
        } else {
          console.error("error on showAlertWithdrawCanSale ->", err);
        }

        return false;
      }
    },
    [notifyWithdrawCanSale, showAlert, verifyWithdrawStatus]
  );

  useEffect(() => {
    getLastCashdesk();
  }, [getLastCashdesk]);

  /**
   * Toda a vez que ocorre uma atualização no cashdesk busca uma nova lista dos caixas
   */
  useEffect(() => {
    if (cashdesk) {
      getListCashDesk();

      verifyWithdrawStatus();
    }
  }, [cashdesk, getListCashDesk]);

  const value = useMemo(
    () => ({
      isCashdeskOpen,
      isCashdeskOnCurrentDay,
      cashdesk,
      cashdeskOpenDate,
      getLastCashdesk,
      cashdeskOpen,
      cashSupply,
      cashWithdraw,
      cashdeskClose,
      cashdeskCloseDay,
      cashdeskReduce,
      showCashierClosingDay,
      setShowCashierClosingDay,
      chargeback,
      armoredCar,
      armoredCarList,
      armoredCarCancel,
      listReducedCashdesk,
      getListCashDesk,
      verifyWithdrawStatus,
      blockSales:
        ((!notifyWithdrawCanSale?.notified && notifyWithdrawCanSale.notify) ||
          blockWithdrawSales?.block) ??
        false,
      showAlertWithdrawCanSale,
    }),
    [
      isCashdeskOpen,
      isCashdeskOnCurrentDay,
      cashdesk,
      cashdeskOpenDate,
      getLastCashdesk,
      cashdeskOpen,
      cashdeskClose,
      setShowCashierClosingDay,
      cashdeskReduce,
      cashSupply,
      cashWithdraw,
      showCashierClosingDay,
      cashdeskCloseDay,
      chargeback,
      armoredCar,
      armoredCarList,
      armoredCarCancel,
      listReducedCashdesk,
      getListCashDesk,
      verifyWithdrawStatus,
      blockWithdrawSales,
      notifyWithdrawCanSale,
      showAlertWithdrawCanSale,
    ]
  );

  return (
    <CashdeskContext.Provider value={value}>
      {children}
    </CashdeskContext.Provider>
  );
};
