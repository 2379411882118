import { apiProducts } from "./api";
import configuration from "../aaone/configuration";
import {transactionData} from "../aaone/shared/utils/transactionNumber";

/**
 * @param {*[]} cartItems
 * @param {String} document
 * @param {Object} itemsCampaigns
 * @param {Object} activeCampaigns
 * @returns {Promise<*>}
 */
export const createOrder = async (
  cartItems = [],
  document = '',
  itemsCampaigns,
  activeCampaigns
) => {
  try {
    const itemsDiscountPedePronto = handleItemsCampaign(itemsCampaigns);
    const itemsDiscountActiveCampaign = handleItemsCampaign(activeCampaigns);
    const order = generateOrder(
      cartItems,
      document,
      itemsDiscountPedePronto,
      itemsDiscountActiveCampaign
    );

    return handleResponse(await apiProducts.post("/api/pedePronto/create-order", order));
  } catch (error) {
    return error;
  }
};

export const statusOrder = async (orderId) => {
  try {
    return handleResponse(await apiProducts.get(`/api/pedePronto/status-order/${orderId}`, {}));
  } catch (error) {
    return error;
  }
};

export const cancelOrder = async (orderId) => {
  try {
    return handleResponse(await apiProducts.put(`/api/pedePronto/cancel-order/${orderId}`, {}));
  } catch (error) {
    return error;
  }
};

export const confirmOrder = async (orderId) => {
  try {
    return handleResponse(await apiProducts.put(`/api/pedePronto/confirm-order/${orderId}`, {}));
  } catch (error) {
    return error;
  }
};

/**
 * @param {Array} cartItems
 * @param {String} document
 * @param {[{productCode: string, discount: number}]} itemsDiscount
 * @param {[{productCode: string, discount: number}]} itemsActiveCampaign
 * @returns {{createdAt: Date, total: number, totalCustomerPrice: number, saleId: string, cpf, totalDiscount: number, items: *[]}}
 */
const generateOrder = (cartItems, document, itemsDiscount, itemsActiveCampaign) => {
  let totalPrice = 0;
  let totalDiscountPedePronto = 0;
  let totalDiscount = 0;
  const itemsFormatted = [];

  cartItems.forEach((item) => {
    if (parseFloat(item.price) > 0 && item.valuePoints <= 0) {
      const price = (item.qtd * item.price).toFixed(2);
      totalPrice += parseFloat(price);

      const discountedItemPedePronto = itemsDiscount
        .find((itemDiscount) => itemDiscount.productCode === item.code);

      const discountedItemActive = itemsActiveCampaign
        .find((itemDiscount) => itemDiscount.productCode === item.code);

      const priceDiscountPedePronto = discountedItemPedePronto?.discount || 0;
      totalDiscountPedePronto += priceDiscountPedePronto;

      const discountActiveCampaign = discountedItemActive?.discount || 0;
      totalDiscount += discountActiveCampaign;

      itemsFormatted.push({
        "id": item.code,
        "name": item.name,
        "unitPrice": (item.price).toFixed(2),
        "quantity": item.qtd,
        "totalPrice": price,
        "discount": discountActiveCampaign + priceDiscountPedePronto,
        "percentageDiscount": 0,
        "subsidized": !!priceDiscountPedePronto
      });
    }
  });

  const newDate = new Date().toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"});
  const [date, hour] = newDate.split(' ');

  return {
    "cpf": document,
    "saleId": `${Math.random()}`.slice(1).slice(-6),
    "createdAt": `${date.split('/').reverse().join('-')}T${hour}`,
    "total": (totalPrice - (totalDiscount + totalDiscountPedePronto)).toFixed(2),
    "totalDiscount": (totalDiscount + totalDiscountPedePronto).toFixed(2),
    "totalCustomerPrice": (totalPrice - (totalDiscount + totalDiscountPedePronto)).toFixed(2),
    "totalSubsidized": totalDiscountPedePronto.toFixed(2),
    "items": itemsFormatted
  };
};

/**
 *
 * @param {Object[]} itemsCampaigns (This object is return of Reshop API. So, all position object is portuguese language)
 * @returns {[{productCode: string, discount: number}]}
 */
const handleItemsCampaign = (itemsCampaigns) => {
  const responseItems = [];
  itemsCampaigns.forEach((item) => {
    responseItems.push({
      productCode: item.CodigoProduto,
      discount: item.ValorTotalDescontoItem
    });
  });
  return responseItems;
};

export const setItemsReshopStorage = (rawData) => {
  const numeroOperacao = `${configuration.terminalid}${transactionData(
    new Date(),
    "MM/dd/yyyy hh:mm:ss"
  )}`
    .replace(" ", "")
    .replace(/\//g, "")
    .replace(/:/g, "");

  localStorage.setItem("IdTransacao", JSON.stringify(rawData.IdTransacao));
  localStorage.setItem("PontosCreditados",JSON.stringify(rawData.PontosCreditados));
  localStorage.setItem("numeroOperacao", numeroOperacao);
  localStorage.setItem("ValorPonto", 1);
};

const handleResponse = (response) => response.data;
