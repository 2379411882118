import { combineReducers } from "redux";

import cart from "./cart";
import loyaltyPoints from './loyaltyPoints'

const reducers = combineReducers({
  cart,
  loyaltyPoints
});

export default reducers;