import styled from "styled-components";

const SelectorWrapper = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #c8cbd0;
  border-radius: 4px;
  position: relative;

  & + .keyboard-fixed {
    bottom: -115px;
  }

  .selected-item {
    height: 100%;
    line-height: 50px;
  }

  &.angle-down:before {
    content: "˅";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    right: 20px;
    color: #c8cbd0;
  }

  .btn_scroll {
    position: absolute;
    z-index: 99;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    outline: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &--top {
      top: 33px;
    }

    &--bottom {
      bottom: -35vh;
    }

    &--open_top {
      top: -120px;

      @media only screen and (${(props) =>
          props.theme.minimumResolution.media}) {
        top: -150px;
      }
    }

    &--open_bottom {
      bottom: -12vh;

      @media only screen and (${(props) =>
          props.theme.minimumResolution.media}) {
        bottom: -3vh;
      }
    }
  }

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    height: 35px;
    padding: 10px;

    .selected-item {
      height: 100%;
      line-height: 15px;
    }
  }
`;
SelectorWrapper.displayName = "SelectorWrapper";

const SelectorList = styled.ul`
  position: absolute;
  width: calc(100% + 10px);
  top: -30px;
  left: -5px;
  background-color: #fff;
  margin: 0;
  padding: 10px;
  list-style-type: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  overflow: hidden;
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 2;
  max-height: 50vh;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
  }

  &.selector_scroll--top {
    top: -184px;

    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      top: -200px;
    }
  }

  &:before {
    content: "${(props) => props.text}";
    color: #ccc;

    position: relative;
    top: 1rem;
  }

  button {
    float: right;
  }

  .backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: ${(props) => (props.visible ? "block" : "none")};
    z-index: -1;
  }
`;

SelectorList.displayName = "SelectorList";

const SelectorListItem = styled.li`
  height: 50px;
  box-shadow: 0px 1px 2px #0000001a;
  border: 1px solid #ebebeb;
  padding: 0 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;

  &.selector-scroll {
    width: calc(100% - 50px);
  }

  .input-filter {
    border: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
    outline: none;
  }

  &.hasScroll {
    width: calc(100% - 50px);
  }

  &.smartPos {
    width: calc(120% - 50px);
  }

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    height: 45px;
  }

  span {
    margin-right: 15px;
  }

  .code {
    width: 50px;
    text-align: end;
  }

  .status {
    margin-left: auto;
    color: #91aa5f;
    text-transform: capitalize;

    &:before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 12px;
      border-radius: 50%;
      background-color: #91aa5f;
      vertical-align: middle;
    }
  }
`;
SelectorListItem.displayName = "SelectorListItem";

export { SelectorWrapper, SelectorList, SelectorListItem };
