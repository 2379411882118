export const agGridLocalePtBr = {
  // Set Filter
  selectAll: "(Selecionar tudo)",
  selectAllSearchResults: "(Selecione todos os resultados da pesquisa)",
  searchOoo: "Pesquisar...",
  blanks: "(Vazio)",
  noMatches: "Nenhum registro encontrado.",

  // Number Filter & Text Filter
  filterOoo: "Filtrar...",
  equals: "Igual",
  notEqual: "Diferente",
  empty: "Escolha um",

  // Number Filter
  lessThan: "Less than",
  greaterThan: "Greater than",
  lessThanOrEqual: "Less than or equal",
  greaterThanOrEqual: "Greater than or equal",
  inRange: "In range",
  inRangeStart: "to",
  inRangeEnd: "from",

  // Text Filter
  contains: "Contém",
  notContains: "Não contém",
  startsWith: "Começa com",
  endsWith: "Termina com",

  // Date Filter
  dateFormatOoo: "aaaa-mm-dd",

  // Filter Conditions
  andCondition: "E",
  orCondition: "OU",

  // Filter Buttons
  applyFilter: "Aplicar",
  resetFilter: "Redefinir",
  clearFilter: "Limpar",
  cancelFilter: "Cancelar",

  // Filter Titles
  textFilter: "Text Filter",
  numberFilter: "Number Filter",
  dateFilter: "Date Filter",
  setFilter: "Set Filter",

  // Side Bar
  columns: "Colunas",
  filters: "Filtros",

  // columns tool panel
  pivotMode: "Modo dinâmico",
  groups: "Grupos de linhas",
  rowGroupColumnsEmptyMessage: "Arraste aqui para agrupar linhas",
  values: "Valores",
  valueColumnsEmptyMessage: "Arraste aqui para agregar valores",
  pivots: "Rótulos de coluna",
  pivotColumnsEmptyMessage: "Arraste aqui para definir rótulos de coluna",

  // Header of the Default Group Column
  group: "Grupo",

  // Other
  loadingOoo: "Carregando...",
  noRowsToShow: "Não há registros para mostrar.",
  enabled: "Habilitado",

  // Menu
  pinColumn: "Fixar coluna",
  pinLeft: "Fixar à esquerda",
  pinRight: "Fixar à direita",
  noPin: "Não fixar",
  valueAggregation: "Agregação de Valor",
  autosizeThiscolumn: "Dimensionar automaticamente esta coluna",
  autosizeAllColumns: "Dimensionar automaticamente todas as colunas",
  groupBy: "Agrupar por",
  ungroupBy: "Desagrupar por",
  resetColumns: "Redefinir colunas",
  expandAll: "Expandir todos",
  collapseAll: "Fechar todos",
  copy: "Copiar",
  ctrlC: "Ctrl+C",
  copyWithHeaders: "Copiar com cabeçalhos",
  paste: "Colar",
  ctrlV: "Ctrl+V",
  export: "Exportar",
  csvExport: "Exportar como CSV",
  excelExport: "Exportar como arquivo Excel",

  // Enterprise Menu Aggregation and Status Bar
  sum: "Sum",
  min: "Min",
  max: "Max",
  none: "None",
  count: "Count",
  avg: "Average",
  filteredRows: "Filtrado",
  selectedRows: "Selecionado",
  totalRows: "Total de registros",
  totalAndFilteredRows: "Registros",
  more: "Mais",
  to: "para",
  of: "de",
  page: "Página",
  nextPage: "Próxima página",
  lastPage: "Última página",
  firstPage: "Primeira página",
  previousPage: "Página anterior",

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
  pivotChart: "Pivot Chart",
  chartRange: "Chart Range",

  columnChart: "Column",
  groupedColumn: "Grouped",
  stackedColumn: "Stacked",
  normalizedColumn: "100% Stacked",

  barChart: "Bar",
  groupedBar: "Grouped",
  stackedBar: "Stacked",
  normalizedBar: "100% Stacked",

  pieChart: "Pie",
  pie: "Pie",
  doughnut: "Doughnut",

  line: "Line",

  xyChart: "X Y (Scatter)",
  scatter: "Scatter",
  bubble: "Bubble",

  areaChart: "Area",
  area: "Area",
  stackedArea: "Stacked",
  normalizedArea: "100% Stacked",

  histogramChart: "Histogram",

  // Charts
  pivotChartTitle: "Pivot Chart",
  rangeChartTitle: "Range Chart",
  settings: "Definições",
  data: "Dados",
  format: "Formato",
  categories: "Categorias",
  defaultCategory: "(None)",
  series: "Series",
  xyValues: "X Y Values",
  paired: "Paired Mode",
  axis: "Axis",
  navigator: "Navigator",
  color: "Color",
  thickness: "Thickness",
  xType: "X Type",
  automatic: "Automatic",
  category: "Category",
  number: "Number",
  time: "Time",
  xRotation: "X Rotation",
  yRotation: "Y Rotation",
  ticks: "Ticks",
  width: "Width",
  height: "Height",
  length: "Length",
  padding: "Padding",
  spacing: "Spacing",
  chart: "Chart",
  title: "Title",
  titlePlaceholder: "Chart title - double click to edit",
  background: "Background",
  font: "Font",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  size: "Size",
  minSize: "Minimum Size",
  maxSize: "Maximum Size",
  legend: "Legend",
  position: "Position",
  markerSize: "Marker Size",
  markerStroke: "Marker Stroke",
  markerPadding: "Marker Padding",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "Stroke Width",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "Bold Italic",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeOpacity: "Line Opacity",
  histogramBinCount: "Bin count",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  histogramGroup: "Histogram",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  doughnutTooltip: "Doughnut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",

  // ARIA
  ariaHidden: "hidden",
  ariaVisible: "visible",
  ariaChecked: "checked",
  ariaUnchecked: "unchecked",
  ariaIndeterminate: "indeterminate",
  ariaColumnSelectAll: "Toggle Select All Columns",
  ariaInputEditor: "Input Editor",
  ariaDateFilterInput: "Date Filter Input",
  ariaFilterInput: "Filter Input",
  ariaFilterColumnsInput: "Filter Columns Input",
  ariaFilterValue: "Filter Value",
  ariaFilterFromValue: "Filter from value",
  ariaFilterToValue: "Filter to value",
  ariaFilteringOperator: "Filtering Operator",
  ariaColumnToggleVisibility: "column toggle visibility",
  ariaColumnGroupToggleVisibility: "column group toggle visibility",
  ariaRowSelect: "Press SPACE to select this row",
  ariaRowDeselect: "Press SPACE to deselect this row",
  ariaRowToggleSelection: "Press Space to toggle row selection",
  ariaRowSelectAll: "Press Space to toggle all rows selection",
  ariaSearch: "Search",
  ariaSearchFilterValues: "Search filter values",
};
