import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ImSpinner3 } from "react-icons/im";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { HiOutlineBackspace, HiOutlineCheck } from "react-icons/hi";

import { Container } from "./styles";
import { maskCPFHelper } from "../../../helpers/maskCPFHelper";
import { KeyboardNumeric } from "../../KeyboardNumeric";
import { MdClose } from "react-icons/md";
import { escOnlyNumbersHelper } from "../../../helpers/escOnlyNumbersHelper";
import { isCPFValidHelper } from "../../../helpers/isCPFValidHelper";
import { CPF_RESHOP_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import { useAuth } from "../../../hook/authHook";
import { useDialog } from "../../../hook/dialogHook";
import { useCategoriesNavigate } from "../../../hook/categoriesHook";
import Button from "../../../../pdv-one/components/Button";
import { isSmartPos } from "../../../../aaone/configuration";

export const ModalAuthFidelity = ({ handleClose }) => {
  const auth = useAuth();
  const { currentCategoryId, handleChangeCategory, currentCategoryIsFidelity } =
    useCategoriesNavigate();
  const { showAlert } = useDialog();

  const inputRef = useRef(null);
  const [cpfFidelity] = useState(sessionStorage.getItem(CPF_RESHOP_KEY_NAME));
  const [keyboardActions, setKeyboardActions] = useState();
  const [isInputValid, setIsInputValid] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const data = new FormData(e.currentTarget);
      setIsSendingForm(true);

      try {
        const cpfOrCnpj = escOnlyNumbersHelper(data.get("cpfOrCnpj") ?? "");

        await auth.reshop.signIn({ cpf: cpfOrCnpj }).then(async (res) => {
          try {
            if (currentCategoryIsFidelity && res.ClienteCadastrado) {
              await handleChangeCategory({
                categoryId: currentCategoryId,
                isFidelity: currentCategoryIsFidelity,
                cpfFidelity: res.Documento,
              });
            }
          } catch (error) {
            console.log("Erro ao pesquisar categorias de fidelidade", error);
            handleClose();
          } finally {
            handleClose();
          }
        });
      } catch (error) {
        if (error?.message === "Request failed with status code 401") {
          showAlert({
            message:
              "Ocorreu um erro ao conectar com Reshop. Verifique suas configurações.",
          });
        } else {
          showAlert({
            message: error?.message ?? "Erro desconhecido",
          });
        }
      }
      setIsSendingForm(false);
    },
    [handleClose]
  );

  const onEventKeyUp = useCallback(
    (e) => {
      const shortcuts = ["Escape"];

      if (shortcuts.includes(e.key)) {
        handleClose();
      }
    },
    [handleClose]
  );

  const handleCostumerConsult = useCallback(async (e) => {
    setIsSendingForm(true);
    try {
      const cpfOrCnpj = escOnlyNumbersHelper(e);
      await auth.reshop.signIn({ cpf: cpfOrCnpj, isConsult: true });
    } catch (error) {
      if (error?.message === "Request failed with status code 401") {
        showAlert({
          message:
            "Ocorreu um erro ao conectar com Reshop. Verifique suas configurações.",
        });
      } else {
        showAlert({
          message: error?.message ?? "Erro desconhecido",
        });
      }
    }
    setIsSendingForm(false);
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", onEventKeyUp);

    return () => {
      window.removeEventListener("keyup", onEventKeyUp);
    };
  }, [onEventKeyUp]);

  useEffect(() => {
    if (keyboardActions?.value.length >= 0 && inputRef?.current) {
      const value = maskCPFHelper(escOnlyNumbersHelper(keyboardActions.value));

      let serializedValue = value;

      const isCPFValid = isCPFValidHelper(value);

      if (!isCPFValid) {
        setIsInputValid(false);
        serializedValue = maskCPFHelper(escOnlyNumbersHelper(value));

        //TODO - Adicionar flag de error;
      }

      if (isCPFValid) {
        setIsInputValid(true);
        serializedValue = maskCPFHelper(serializedValue);
      }

      if (serializedValue.length >= 14 && !isCPFValid) {
        serializedValue = maskCPFHelper(serializedValue);
      }

      const setCpfValue = (value) => {
        inputRef.current.value = value;
        keyboardActions.set(value);
      };

      if (serializedValue) {
        setCpfValue(serializedValue);
      } else {
        if (cpfFidelity) {
          setCpfValue(maskCPFHelper(cpfFidelity));
        } else {
          setCpfValue("");
          setIsInputValid(false);
        }
      }
    }

    if (keyboardActions?.value.length < 14 && inputRef?.current) {
      auth.reshop.setCostumerConsultant(null);
    }

    if (isCPFValidHelper(keyboardActions?.value ?? "") && inputRef?.current) {
      handleCostumerConsult(keyboardActions?.value);
    }
  }, [keyboardActions?.value, inputRef]);

  return (
    <Container className={classNames({ isSmartPos })}>
      <div className="content">
        <div className="header-modal">
          <button className="btn-close" onClick={handleClose}>
            <MdClose size="1.5rem" />
          </button>
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="form__container">
          <div className="details-content">
            <div className="details">
              <div className="row">
                <label>Informe o CPF do cliente fidelidade.</label>
              </div>
              <div className="row">
                <label>CPF</label>
                <div className="input-adornment">
                  <input
                    type="text"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    role="presentation"
                    readOnly={!isSmartPos}
                    autoFocus
                    inputMode="numeric"
                    onFocus={(e) => {
                      e.currentTarget.focus();
                      e.currentTarget.removeAttribute("readOnly");
                    }}
                    onBlur={(e) => e.currentTarget.focus()}
                    onChange={(e) => {
                      e.target.value = e.target.value;

                      if (keyboardActions?.set) {
                        keyboardActions.set(e.target.value);
                      }

                      return e;
                    }}
                    ref={inputRef}
                    name="cpfOrCnpj"
                    id="cpfOrCnpj"
                    placeholder="Identificação do Cliente"
                  />
                  <i className="icon">
                    {isSendingForm && (
                      <ImSpinner3 size="25px" className="loading" />
                    )}
                    {!isSendingForm &&
                      keyboardActions?.value.length > 0 &&
                      isInputValid && (
                        <RiCheckboxCircleFill size="25px" className="checked" />
                      )}
                  </i>
                </div>
                {keyboardActions?.value.length === 14 &&
                  !isCPFValidHelper(keyboardActions?.value) && (
                    <label className="invalid-cpf">CPF inválido</label>
                  )}
              </div>
              {!!auth.reshop?.costumerConsultant?.Id && (
                <>
                  <div className="row">
                    <label>Cliente:</label>
                    <span>
                      {auth.reshop?.costumerConsultant?.Nome
                        ? auth.reshop?.costumerConsultant.Nome.split(
                            " ",
                            2
                          ).join(" ")
                        : "Cliente Reshop"}
                    </span>
                  </div>
                  <div className="row">
                    <label>Pontos:</label>
                    <span className="points">
                      {auth.reshop?.costumerConsultant?.SaldoMonetario
                        ? `${auth.reshop?.costumerConsultant?.SaldoMonetario} pontos`
                        : `-`}{" "}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex buttons__container">
              <Button
                onClick={handleClose}
                className="button__default btn-purple-outline mx-10 flex-grow-1"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className="button__default btn-purple mx-10 flex-grow-1"
                disabled={
                  !auth.reshop?.costumerConsultant?.Id ||
                  isSendingForm ||
                  !isInputValid
                }
              >
                Pronto
              </Button>
            </div>
          </div>
          <div className="keyboard-numeric-content">
            <KeyboardNumeric
              newButtons={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                {
                  className: "backspace",
                  value: <HiOutlineBackspace />,
                },
                "0",
                {
                  type: "submit",
                  disabled:
                    !auth.reshop?.costumerConsultant?.Id ||
                    isSendingForm ||
                    !isInputValid,
                  className: "send",
                  value: <HiOutlineCheck />,
                },
              ]}
              setActions={setKeyboardActions}
            />
          </div>
        </form>
      </div>
    </Container>
  );
};
