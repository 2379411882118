import styled from "styled-components";

const ModalWrapper = styled.section`
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -60%);

  .modal {
    text-align: center;
    padding: 1em;
    border-radius: 1.25em;
    box-sizing: border-box;
    background-color: var(--color-background-modal);
    
    .no-image,
    img {
      line-height: 245px;
      height: 100%;
      width: 535px;
      margin: 0 auto;
      border-radius: 20px;
    }

    .title {
      color: var(--color-title-modal);
      font-size: 30px;
      font-weight: 100;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .subtitle {
      color: var(--color-title-category);
      font-size: 30px;
    }

    .container {
      width: 730px;
      height: 294px;
      border-radius: 20px;
      opacity: 1;
    }

    .container-combo {
      background: #ffcb05 0% 0% no-repeat padding-box;
    }

    .container-individual {
      border: 2px solid #fecb2e;
      margin-top: 2em;
    }
  }

  .modalcombo {
    padding: 1em;
    border-radius: 1.25em;
    box-sizing: border-box;
    background-color: var(--color-background-modal);

    
    .no-image,
    img {
      line-height: 245px;
      height: 200px;
      width: 400px;
      border-radius: 20px;
      margin-left: -36px;
      margin-top: 6%;
    }

    .title {
      color: red;
      font-size: 23px;
      font-weight: 100;
      text-transform: uppercase;
    }
    
    .descriptioncombo {
      font-family: monospace;
      font-weight: 100;
      color: var(--unnamed-color-5f5f5f);
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.03px;
      color: #5F5F5F;
      opacity: 1;
      margin-top: -16px;
    }

    .descriptionvalue {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/40px var(--unnamed-font-family-bib’sans);
      color: var(--unnamed-color-5f5f5f);
      text-align: center;
      letter-spacing: 0.03px;
      color: #5F5F5F;
      text-transform: uppercase;
      opacity: 1;
      margin-top: -18px;
      text-decoration: line-through;
    }

    .subtitlevalor {
      color: red;
      font-size: 30px;
      text-transform: uppercase;
      margin-top: -12px;
    }

    .subtitle {
      color: red;
      font-size: 30px;
      text-transform: uppercase;
      margin-top: -2px;
    }

    .container {
      display: flex;
      margin-top: 85px;
      height: 294px;
      border-radius: 20px;
      opacity: 1;
    }
  
    .divdescription {
      flex-direction: column;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 2%;
    }

    .container-combo {
      background: #ffcb05 0% 0% no-repeat padding-box;
    }

    .container-individual {
      border: 2px solid #fecb2e;
      margin-top: 2em;
    }
  }
`;

export default ModalWrapper;
