import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Content, Button, Summary } from "../../shared/components/modules";
import Configuration, { getThemeSettings, isDesktopApp, isShowYourNameTabletConfig } from "../../configuration";
import { verifyRedeemValue } from "../../../services/verifyRedeemValueService";
import { consultDiscounts } from "../../../services/pointsService";
import { isDisabledReShop } from "../../configuration";
import { showPrismaPage } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { useViewDispatch } from "../../../context";

const OrderSummaryContainer = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const {
    getTranslateName,
    getScreenTitleBySlug,
    getScreenIsActiveBySlug,
    isLayout,
    isLayoutAAOne,
  } = useLayoutAa();
  const orderSummaryText =
    getTranslateName(t, "order_summary") ||
    getScreenTitleBySlug("RESUMO") ||
    DEFAULT_LOCAL_TEXTS.order_summary;
  const isShowPrismaPage =
    getScreenIsActiveBySlug("RECEBA_NA_MESA") ?? showPrismaPage;
  const isShowCPFPage = getScreenIsActiveBySlug("CPF_NA_NOTA") ?? true;
  const isShowYourName = isDesktopApp ? getScreenIsActiveBySlug("INFORME_SEU_NOME") ?? true : isShowYourNameTabletConfig;
  const isShowCarIdentification =
    getScreenIsActiveBySlug("PLACA_VEICULO") ?? true;

  const continueShoppingOptionText = getTranslateName(t, "keep_shopping") || getThemeSettings?.text?.keep_shopping || "Continuar Comprando";
  const paymentIntentText = getTranslateName(t, "payment_intent") || getThemeSettings?.text?.nice_to_pay || "Perfeito! Quero Pagar";

  const cartItems = useSelector((state) => state.cart.items);
  const [discount, setDiscounts] = useState(0);
  const [verifyDiscountReshop, setVerifyDiscountReshop] = useState(false);

  useEffect(() => {
    if (cartItems?.length) {
      (async () => {
        if (!isDisabledReShop) {
          setVerifyDiscountReshop(true);
          const discountsCart = await consultDiscounts(
            localStorage.getItem("cpf"),
            cartItems
          );
          setDiscounts(discountsCart);
        }
        setVerifyDiscountReshop(false);
      })();
    }
  }, [cartItems]);
  const clearInfosInMemory = (param) => {
    param.forEach((item) => {
      window.localStorage.removeItem(item);
      window.sessionStorage.removeItem(item);
    });
  };

  const nextStep = () => {
    //Foi removido temporariamente a tela de selecionar onde vai comer, estamos passando viagem fixo
    //Quando voltar tela só apagar o dispatch cpf e localstorage e voltar chamar o dispatch step.withdrawn
    //window.localStorage.setItem('typeCode', 'V');
    //viewDispatch('step.cpf');

    if (isLayoutAAOne) {
      if (!isShowPrismaPage) {
        if (isShowCarIdentification) {
          viewDispatch("step.carIdentification");
          if (isShowCPFPage) {
            viewDispatch("step.cpf");
          }
        } else {
          if (isShowCPFPage) {
            viewDispatch("step.cpf");
          } else {
            if (isShowYourName) {
              viewDispatch("step.orderIdentification");
            } else {
              viewDispatch("step.payment");
            }
          }
        }
      } else {
        viewDispatch("step.prisma");
      }

      return false;
    }

    if (isLayout("cocobambu")  || !isShowPrismaPage || isLayout("RAGAZZOEXPRESS") ) {
      viewDispatch("step.cpf");
      return false;
    }

    if (localStorage.getItem("typeCode") === "L") {
      viewDispatch("step.prisma");
    }
    // if (localStorage.getItem("styleAA") === "DRIVE")
    //   {
    //     viewDispatch('step.carIdentification')
    //   }
    else {
      viewDispatch("step.cpf");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("cpf")) {
      verifyRedeemValue(cartItems);
    }

    clearInfosInMemory(["messageTef"]);
  });

  return (
    <>
      <Content
        bgColor="background-primary"
        title={orderSummaryText}
        subtitle={false}
        contentChildren={true}
        className="full fullbobsresume"
      >
        <Summary
          list={cartItems}
          discounts={discount}
          updateTotal={() => console.log()}
          updateItensInCart={() => console.log()}
          hasFooter={true}
          hasImg={true}
          showTotal={false}
        />

        <footer className="footer">
          <Button
            className="btnRagazzo"
            text={continueShoppingOptionText}
            buttonUIType="outline-primary"
            buttonUI={"normal button-withdrawn"}
            handleClick={() => viewDispatch("step.home")}
          />

          <Button
            className="buttonAddCar buttonAddCarRagazzo"
            text={paymentIntentText}
            buttonUIType="primary"
            buttonUI={"normal button-withdrawn"}
            handleClick={() => nextStep()}
            disabled={
              !cartItems.length ||
              verifyDiscountReshop ||
              localStorage.getItem("NotEnoughPoints")
            }
          />
        </footer>
      </Content>
    </>
  );
};

export default OrderSummaryContainer;
