import React, { useState } from "react";
import {
  BodyWrapper,
  BoxWrapper,
  Container,
  DataWrapper,
  DetailButtom,
  HeaderWrapper,
  HiddenRadioButton,
  InfoWrapper,
  RadioButtonWrapper,
  StatusWrapper,
  StyledRadioButton,
} from "./style";
import { currencyMask } from "../../helpers/masks";
import Button from "../Button";

export const CardStatus = ({ status }) => {
  return <StatusWrapper status={status}>{status}</StatusWrapper>;
};

const RadioButton = ({ checked, onChange, name }) => {
  return (
    <RadioButtonWrapper>
      <HiddenRadioButton
        type="radio"
        checked={checked}
        onChange={onChange}
        name={name}
      />
      <StyledRadioButton checked={checked} />
    </RadioButtonWrapper>
  );
};

export const CardMonitorNotes = ({ items, onSelectItem, onItemNoteDetail }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleRadioChange = (index) => {
    setSelectedItem(index);
    onSelectItem(index);
  };

  const handleDetailsNote = (item) => {
    console.log("item-detail-note: ", item);
  };

  return (
    <Container>
      {items.map((item, index) => (
        <BoxWrapper key={index}>
          <HeaderWrapper>
            <DataWrapper>
              <label>Nº</label>
              <label>
                <strong>{item?.receiptNumber}</strong>
              </label>
            </DataWrapper>
            <DataWrapper>
              <label>Série</label>
              <label>
                <strong>{item?.receiptSeries}</strong>
              </label>
            </DataWrapper>
            <DataWrapper>
              <label>PDV</label>
              <label>
                <strong>{item.posId}</strong>
              </label>
            </DataWrapper>
            <DataWrapper>
              <label>Valor</label>
              <h3>
                <strong>{item?.total}</strong>
              </h3>
            </DataWrapper>
            <div>
              <RadioButton
                name="selectedItem"
                checked={selectedItem === index}
                onChange={() => handleRadioChange(index)}
              />
            </div>
          </HeaderWrapper>
          <BodyWrapper>
            <InfoWrapper>
              <DataWrapper style={{ marginBottom: "5px" }}>
                <label>Dt. Atendimento</label>
                <label>
                  <strong>{item?.dtAtendimento}</strong>
                </label>
              </DataWrapper>
              <DataWrapper>
                <label>Dt. Autorização</label>
                <label>
                  <strong>{item?.dtAutorizacao}</strong>
                </label>
              </DataWrapper>
            </InfoWrapper>
            <div>
              <CardStatus status={item?.status} />
              <DetailButtom onClick={() => onItemNoteDetail(item)}>
                <i className="material-icons">search</i>
              </DetailButtom>
            </div>
          </BodyWrapper>
          <DataWrapper>
            <label>Motivo:</label>
            <label>
              <strong>{item?.motivo}</strong>
            </label>
          </DataWrapper>
        </BoxWrapper>
      ))}
    </Container>
  );
};
