import styled from "styled-components";

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 35px 15px 0;
  position: relative;

  .title__text {
    flex: 1;
  }

  .button__remove {
    position: absolute;
    right: 0;
    top: 16px;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  ul {
    padding: 0 0.5rem;
    list-style-type: none;
  }

  &.select-product {
    padding: 5px 35px 5px 0;
    &.is-current {
      padding: 5px 35px 5px 8px;
      box-shadow: rgb(65, 30, 90) 0px -2px 6px;
      border-radius: 4px;
      background: rgb(65, 30, 90);
      color: rgb(255, 255, 255);
      margin: 0;

      .product-requirement {
        color: #fff;
      }
    }

    .product-requirement {
      position: absolute;
      right: 12px;
      top: 2px;
      font-size: 30px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.error};

      &.required::before {
        content: "!";
      }
      &.optional::before {
        content: "?";
      }
    }
  }

  .product-description {
    .product-info {
      font-weight: 700;
      font-size: 0.875rem;
      display: flex;
      justify-content: space-between;
      margin-right: 16px;
    }
  }

  &.children-product > .product-description {
    padding-left: 8px;
  }
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  position: relative;
  padding-left: 0.5rem;

  .isCurrent {
    box-shadow: rgb(65, 30, 90) 0px -2px 6px;
    border-radius: 4px;
    background: rgb(65, 30, 90);
    color: rgb(255, 255, 255);
  }

  :nth-child(2n) {
    background-color: rgb(247, 247, 247);
    border-top: 1px solid rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
  }
`;

export const Actions = styled.div`
  display: flex;
  /* width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; */

  .action-wrapper {
    position: absolute;
    width: 225px;
    background: #fff;

    right: 0.625rem;
    z-index: 3;
    box-shadow: 0 3px 6px #033;
    border-radius: 4px;

    &.bottom {
      bottom: 0;
    }

    .action {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed;
      text-transform: uppercase;
      height: 60px;

      svg {
        margin-left: 16px;
        width: 2rem;
        height: 2rem;
      }

      .isPurple {
        color: rgb(110, 75, 135);
        width: 2rem;
        height: 2rem;
      }

      .isRed {
        color: #da482c;
        width: 2rem;
        height: 2rem;
      }

      span {
        padding-left: 8px;
      }
    }
  }

  .overlay {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    z-index: 2;
  }
`;

export const Observation = styled.div`
  display: block;

  .observation-wrapper {
    position: absolute;
    width: 100%;
    background: #fff;
    box-shadow: 0 3px 6px #033;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    top: 0;

    &.bottom {
      bottom: 0;
      top: auto;
    }

    textarea {
      font-size: 16px;
      resize: none;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    height: 100%;

    .button {
      margin-left: 8px;
      border-radius: 4px !important;
      height: auto;
    }
  }
`;
export const BoxProduct = styled.div`

  padding: 10px;
`;
