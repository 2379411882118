import { apiProducts } from "./api";

export const checkTokenIsValidService = async () => {
  try {
    const { data } = await apiProducts.get("/api/token-verify");

    return !!(data?.result ?? false);
  } catch (error) {
    return false;
  }
};
