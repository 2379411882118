import React from "react";
import { SimpleItemTableSmartPos } from "../../SimpleItemTableSmartPos";

export const ItemsProductsUnavailableSmartPos = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <SimpleItemTableSmartPos
          key={`simple-item-${index}-table-smart-pos`}
          items={item.list}
        />
      ))}
    </>
  );
};
