import {
	UPDATE_POINTS
} from "../constants/loyaltyPoints";
  
const INITIAL_STATE = {
	points: 0,
};

const loyaltyPoints = (state = INITIAL_STATE.points, action) => {
	switch (action.type) {
    case UPDATE_POINTS:
      console.log(`Points ${state}`)
      console.log(`Points ${action.points}`)

      return 
    default: 
      return state;
  }
}

export default loyaltyPoints