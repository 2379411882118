import React, { useState } from "react";
import { FidelityContent, FidelityHeader, FidelityWrapper } from "./styles";
import { FidelityCouponPage } from "./coupon";
import FidelityTabs from "./tabs";
import OptionalCampaing from "./optionalCampaing";

const ReshopFidelity = () => {
  const [selectedTab, selectTab] = useState("Promocode");

  return (
    <FidelityWrapper>
      <FidelityHeader>
        <FidelityTabs selectedTab={selectedTab} selectTab={selectTab} />
      </FidelityHeader>
      <FidelityContent>
        {selectedTab === "Promocode" && <FidelityCouponPage />}
        {selectedTab === "Campanhas Opcionais" && <OptionalCampaing />}
      </FidelityContent>
    </FidelityWrapper>
  );
};

export default ReshopFidelity;
