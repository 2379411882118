import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Filter = styled.div`
  display: flex;
  width: 100%;
  height: 4.25rem;
  background: #ffffff;
  border: 1px solid rgba(189, 189, 189, 0.5);
  font-weight: bold;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 0.5rem solid #bdbdbd;
  cursor: pointer;

  &:last-child {
    border-right: 0;
  }

  div:last-child {
    position: relative;

    &:before {
      content: "";
      border: 0.063rem solid #bdbdbd;
      height: 1.563rem;
      position: absolute;
      left: -1.313rem;
    }
  }

  &.all {
    color: #411e5a;

    &.active {
      border-bottom: 0.5rem solid #411e5a;
    }
  }

  &.open {
    color: #005bbe;

    &.active {
      border-bottom: 0.5rem solid #005bbe;
    }
  }

  &.free {
    color: #09843a;

    &.active {
      border-bottom: 0.5rem solid #09843a;
    }
  }

  &.closed {
    color: #ff554a;

    &.active {
      border-bottom: 0.5rem solid #ff554a;
    }
  }

  &.isSmartPos {
    justify-content: flex-start;
    flex-direction: row;
    text-align: left;
    font-size: 0.75rem;
    height: 2rem;

    padding: 0 0.5rem;

    grid-gap: 0.25rem;
    gap: 0.25rem;

    margin-bottom: 6px;

    div:last-child {
      padding-left: 0.5rem;
      font-size: 0.75rem;

      &::before {
        display: none;
      }
    }
  }
`;
