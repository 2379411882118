import React, { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import CashWithdraw from "../../components/CashWithdraw";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useCashdesk } from "../../hook/cashdeskHook";
import { getRetailerId } from "../../../aaone/configuration";
import { listMovementsService } from "../../../services/cashdeskService";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { MessageBox } from "../../components/modules";
import { getPaymentsMethodService } from "../../../services/getPaymentsMethodService";
import { useAuth } from "../../hook/authHook";
import { movementsEnum } from "../../constants/movementsEnum";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import {
  handlePrintChargeback,
  handlePrintCoupon,
} from "../../helpers/printer/withdraw";
import { getAPIPayments } from "../../../services/paymentService";
import { getListMonitorNotes } from "../../../services/orderService";
import { getCloseCashDeskValues } from "../../../services/cashdeskService";
import PanelStatusPdvContainer from "../../components/panel-status-pdv";
import { printCloseReport } from "../../helpers/printer/closeReport";
import { openDrawer } from "../../helpers/openDrawer";
import { printCloseDiscountOperator } from "../../helpers/printer/closeDiscountOperator";
import { getCashDeskClosingPrinter } from "../../../services/printerService";
import { printerActionService } from "../../../services/actions/printerActionService";
import { printDefault } from "../../helpers/printer/printDefault";

const withHooks = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();
    const navigate = useNavigate();
    const cashdesk = useCashdesk();
    const location = useLocation();
    const dialog = useDialog();
    const loading = useLoading();
    const auth = useAuth();

    return (
      <Component
        {...props}
        setVisibleSidebarCart={setVisibleSidebarCart}
        navigate={navigate}
        cashdesk={cashdesk}
        location={location}
        dialog={dialog}
        loading={loading}
        auth={auth}
      />
    );
  };
};

export class CashCloseWithdrawContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controlNumber: 0,
      openCard: true,
      openCardList: false,
      listWithdraw: [],
      movements: {},
      message: false,
      paymentType: null,
      manager: {},
      operator: {},
      cashdesk: null,
    };
  }

  componentDidMount() {
    this.props.setVisibleSidebarCart(false);
    try {
      this.props.loading.setShowLoading(true);
      const { state } = this.props.location;

      const manager = state?.auth
        ? state.auth.user
        : this.props.auth.userAuthData;
      const operator = this.props.auth.userAuthData;

      const promises = [];

      promises.push(
        getPaymentsMethodService().then((res) => {
          this.setState({
            paymentType: res.find(
              (payment) => payment.paymentOneDetails.reference === 1
            ),
          });
        })
      );

      this.setState({
        manager,
        operator,
      });
      Promise.all(promises).then(() => {
        this.props.loading.setShowLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.props.loading.setShowLoading(false);
    }
  }

  handleClick(amount, description, numberEnvelop) {
    try {
      this.props.loading.setShowLoading(true);
      const req = {
        description: description,
        amount: -amount,
        numberEnvelop: numberEnvelop,
        operatorName: this.state.operator.login,
        adminName: this.state.manager.login,
        paymentType: {
          id: this.state.paymentType?.nuuidOne,
          name: this.state.paymentType?.transactionType,
          referenceId: this.state.paymentType?.paymentOneBaseCode,
        },
        responsableId: this.state.manager.nuuidOne,
        userId: this.state.operator.nuuidOne,
        responsableReferenceId: this.state.manager.employeeCode,
        userReferenceId: this.state.operator.employeeCode,
        cashdeskId: this.state.cashdesk.id,
        posId: this.state.cashdesk.posId,
      };
      if (amount > 0) {
        this.props.cashdesk
          .cashWithdraw(req)
          .then((res) => {
            this.handlePrinter({
              cashdesk: res,
            });
            this.handleCloseCashdesk(res);
            this.setState({
              disableConfirm: false,
            });
            this.props.loading.setShowLoading(false);
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              disableConfirm: false,
            });
            this.props.loading.setShowLoading(false);
          });
      } else {
        this.props.loading.setShowLoading(false);
        this.props.dialog.showAlert({
          message: (
            <>
              O valor informado para sangria foi R$0,00. Deseja realizar o
              fechamento sem informar o valor para sangria?
            </>
          ),
          onConfirmText: "Sim",
          cancelText: "Não",
          handleCancel: () => {},
          handleConfirm: () => {
            this.props.loading.setShowLoading(true);

            this.handleCloseCashdesk(this.state.cashdesk);
            this.setState({
              disableConfirm: false,
            });
          },
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        disableConfirm: false,
      });
      this.props.loading.setShowLoading(false);
    }
  }

  async handleCloseCashdesk(cashdesk) {
    try {
      this.props.loading.setShowLoading();
      const openingAmount = cashdesk.movements[0].amount;
      const userName = cashdesk?.user;

      const promises = [
        getAPIPayments({ getall: true }),
        getCloseCashDeskValues(cashdesk?.id),
      ];

      await Promise.all(promises)
        .then((res) => {
          const [{ payments = {} }, { payments: cashdeskValues }] = res;

          const closingValues = payments.reduce((acc = [], curr) => {
            if (
              !acc.find(
                (payment) =>
                  payment.paymentType.reference ===
                  curr.paymentOneDetails.reference
              )
            ) {
              const brands = [];

              payments.forEach((paymentType) => {
                if (curr.paymentCode === paymentType?.paymentCode) {
                  const brand = cashdeskValues?.find(
                    (brand) =>
                      paymentType.paymentOneDetails.reference ===
                      brand.referenceId
                  );

                  brands.push({
                    amount:
                      paymentType.paymentOneDetails.reference !== 1
                        ? brand?.amount || 0
                        : 0, // Não preenche o campo em dinheiro
                    id: paymentType.paymentOneDetails.nuuidOne,
                    name: paymentType.description,
                    paymentTypeId: curr.paymentOneDetails.nuuidOne,
                    systemAmount: brand?.amount || 0,
                    referenceId: paymentType.paymentCode,
                    isReadOnly:
                      paymentType.paymentOneDetails.reference !== 1
                        ? true
                        : false,
                  });
                }
              });

              acc.push({
                amount: 0,
                systemAmount: brands.reduce(
                  (acc, curr) => curr.systemAmount + acc,
                  0
                ),
                paymentType: {
                  id: curr.paymentOneDetails.nuuidOne,
                  name: curr.paymentOneDetails.paymentBaseOne.description,
                  paymentOneBaseCode: curr.paymentOneBaseCode,
                },
                brands,
              });
            }

            return acc;
          }, []);

          const body = {
            userName,
            openingAmount,
            closingValues,
            userId: this.state.operator.nuuidOne,
            responsableId: this.state.manager.nuuidOne,
            paymentType: {
              name: this.state.paymentType?.description || "",
            },
            cashdeskId: cashdesk.id,
            posId: cashdesk.posId,
            operationalValues: {
              ...res?.[1].operationalResult,
              totalOperational: res?.[1].operationalResult?.total,
            },
            isCloseCashDeskLeftOverValueZero:
              res?.[1].isCloseCashDeskLeftOverValueZero,
          };

          this.props.cashdesk
            .cashdeskClose(body)
            .then((cashdesk) => {
              const objFilter = {
                retailerId: getRetailerId(),
                startDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
                endDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
                status: 12,
                type: 1,
              };

              getListMonitorNotes(objFilter).then((response) => {
                const orders = response.sort((a, b) => {
                  if (a.receiptNumber > b.receiptNumber) {
                    return 1;
                  }

                  if (
                    Date(a.fiscalDocument?.returnDate) >
                    new Date(b.fiscalDocument?.returnDate)
                  ) {
                    return 1;
                  }

                  return -1;
                });
                getCloseCashDeskValues(cashdesk.id, true).then((res) => {
                  setTimeout(() => {
                    getCashDeskClosingPrinter({
                      closeCashdeskValues: res,
                      cashdesk,
                    }).then((data) => {
                      if (window.desktopApp) {
                        printerActionService({
                          body: data,
                        });
                      } else if (window.Android) {
                        console.log(`printDefault | {}`);
                        printDefault(data);
                      }
                    });

                    if (res.printDiscountVoucherOperator) {
                      printCloseDiscountOperator({
                        cashdesk,
                        printDiscountVoucherOperatorValue:
                          res.printDiscountVoucherOperatorValue,
                      });
                    }
                  }, 1000);
                });
              });

              //Abre a gaveta
              openDrawer();

              this.handleClose();
              this.props.loading.setShowLoading(false);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .then(() => {
          this.props.loading.setShowLoading(false);
        });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  handleChargeback(itemMovement) {
    try {
      this.props.loading.setShowLoading(true);
      this.props.cashdesk
        .chargeback({
          movementId: itemMovement.id,
        })
        .then((res) => {
          this.props.dialog.showAlert({
            message: "Estorno realizado com sucesso!",
          });
          this.handlePrinter({
            cashdesk: res.cashDesk,
            movementId: itemMovement.id,
          });
          this.handleClose();
          this.props.loading.setShowLoading(false);
        });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  onCashOutFinish() {
    this.props.cashWithdrawBoxShow(false);
    this.props.onCartShow();
  }

  handleClose() {
    this.props.navigate(PagesEnum.HOME);
    this.props.setVisibleSidebarCart();
  }

  /**
   * Função que seleciona uma data de fechamento
   * e retorna ela para o fechamento de caixa
   *
   */
  onHandleSelectClosingDate = (selectedCashdesk) => {
    if (selectedCashdesk.reducingDate) {
      this.props.dialog.showAlert({
        message: "Deseja fechar o caixa?",
        onConfirmText: "Sim",
        handleCancel: () => {},
        handleConfirm: () => {
          this.handleCloseCashdesk(selectedCashdesk);
        },
      });
    } else if (!selectedCashdesk.closingDate) {
      this.setState({
        cashdesk: selectedCashdesk,
      });

      listMovementsService({
        retailerId: getRetailerId(),
        movementType: movementsEnum.WITHDRAW,
        initialDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
        finalDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
      }).then((res) => {
        const withdraws = res.filter(
          (movement) => movement.openingCount === selectedCashdesk.openingCount
        );

        this.setState({
          controlNumber: res.length
            ? res[res.length - 1].referenceSequence + 1
            : 1,
          listWithdraw: withdraws,
        });
      });
    }
  };

  handleHidden(expandCardList) {
    const objState = {
      openCardList: expandCardList,
      openCard: !expandCardList,
    };
    this.setState(objState);
  }

  confirmChargeback = (itemMovement) => {
    this.setState({
      ...this.state,
      message: true,
      itemMovement: itemMovement,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      message: false,
    });
  };

  handlePrinter({ cashdesk, movementId }) {
    const currWithdraw = movementId
      ? cashdesk.movements.filter((movement) => movement.id === movementId)[0]
      : cashdesk.movements[cashdesk.movements.length - 1];

    if (!currWithdraw.isReversed) {
      handlePrintCoupon(cashdesk, currWithdraw);
      handlePrintCoupon(cashdesk, currWithdraw);
    } else {
      handlePrintChargeback(cashdesk, currWithdraw);
    }
  }

  render() {
    const { openCard, openCardList, cashdesk } = this.state;
    const { listReducedCashdesk } = this.props.cashdesk;

    const messageBox = this.state.message && (
      <MessageBox
        message="Deseja realmente estornar sangria?"
        handleCancel={() => this.closeModal()}
        handleConfirm={() => this.handleChargeback(this.state.itemMovement)}
        okText="Sim"
      />
    );

    return (
      <KeyboardProvider>
        <>
          {cashdesk ? (
            <>
              <CashWithdraw
                title="SANGRIA/FECHAMENTO DE CAIXA"
                bypassValidation={true}
                manager={this.state.manager}
                handleClick={(amount, description, numberEnvelop) =>
                  this.handleClick(amount, description, numberEnvelop)
                }
                handleClose={() => this.handleClose()}
                handleHidden={(isCardList) => this.handleHidden(isCardList)}
                handlePrinter={(movementId) =>
                  this.handlePrinter({
                    cashdesk,
                    movementId,
                  })
                }
                handleChargeback={(itemMovement) =>
                  this.confirmChargeback(itemMovement)
                }
                expandCard={openCard}
                expandCardList={openCardList}
                listWithdraw={this.state.listWithdraw}
                controlNumber={this.state.controlNumber}
                setVisibleSideKeyboardContainer={(visible) =>
                  this.props.setVisibleSideKeyboardContainer(visible)
                }
                setVisibleBottomKeyboardContainer={(visible) =>
                  this.props.setVisibleBottomKeyboardContainer(visible)
                }
                showRequired={false}
              />
              {messageBox}
            </>
          ) : (
            <PanelStatusPdvContainer
              onHandleSelectClosingDate={this.onHandleSelectClosingDate}
              listCashdesks={listReducedCashdesk}
              handleClose={() => this.handleClose()}
            />
          )}
        </>
      </KeyboardProvider>
    );
  }
}

export default withHooks(CashCloseWithdrawContainer);
