import React, { useMemo } from "react";

import ContentWrapper from "./style";

const Content = ({
  bgColor,
  className,
  title,
  subtitle,
  contentChildren,
  hasImg,
  srcImg,
  imgPosition = "top",
  imageClass,
  children,
}) => {
  const Image = useMemo(
    () =>
      ({ srcImg, imageClass }) =>
        (
          <img
            src={srcImg}
            alt={srcImg}
            className={`content-wrapper ${imageClass}`}
          />
        ),
    []
  );

  return (
    <ContentWrapper className={`content-wrapper ${bgColor} ${className}`}>
      {imgPosition === "top" && hasImg && <Image srcImg={srcImg} />}

      {title && <h1 className="title">{title}</h1>}

      {subtitle && <h2 className="subtitle">{subtitle}</h2>}

      {imgPosition === "bottom" && hasImg && (
        <Image srcImg={srcImg} imageClass={imageClass} />
      )}

      {contentChildren && <>{children}</>}
    </ContentWrapper>
  );
};

export default Content;
