import React, { useState, useEffect } from "react";
import { FormWrapper, InputGroup } from "./style";
import Modal from "react-modal";
import { Button } from "../../modules";
import { getSerieNFCe } from "../../../../aaone/configuration";
import { KeyboardProvider } from "../../../contexts/KeyboardContext";
import classNames from "classnames";
import { isSmartPos } from "../../../../aaone/configuration";

const customStyle = {
  content: {
    maxWidth: "572px",
    height: "fit-content",
    background: "#ffffff",
    margin: "auto",
    border: "none",
    top: isSmartPos ? "40px" : "-95px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.60)",
    display: "block",
  },
  iconCloseModal: {
    float: "right",
    cursor: "pointer",
  },
};

function InutilizaNotes(props) {
  const formInit = {
    numAno: new Date().getFullYear(),
    numserie: getSerieNFCe(),
    numInicial: "",
    numFinal: "",
    justificativa: "",
  };

  const configInit = [
    {
      name: "numAno",
      minLenght: 4,
      maxLength: 4,
      msgErroAno: "Informar o ano com 4 dígitos.",
      isOk: true,
    },
    {
      name: "numserie",
      minLenght: 1,
      maxLength: 3,
      msgErroSerie: "Informar a série de 1 a 3 digitos.",
      isOk: true,
    },
    {
      name: "numInicial",
      minLenght: 1,
      maxLength: 9,
      msgErroIni: "Valor de 1 a 9 digitos e menor que final.",
      isOk: true,
    },
    {
      name: "numFinal",
      minLenght: 1,
      maxLength: 9,
      msgErroFim: "Valor de 1 a 9 digitos e maior que inicial",
      isOk: true,
    },
    {
      name: "justificativa",
      minLenght: 15,
      maxLength: 256,
      msgErroJust: "Informar o valor de 15 a 256 digitos.",
      isOk: true,
    },
  ];

  const [form, setValues] = useState(formInit);
  const [inputName, setInputName] = useState();
  const [configFields, setConfigFields] = useState(configInit);

  useEffect(() => {
    return () => {
      setValues(formInit);
      setConfigFields(configInit);
    };
  }, [props.showModal]);

  const updateField = (input) => {
    const testNumber = /^[0-9\b]*$/;
    if (
      input.target.name.indexOf("num") >= 0 &&
      !testNumber.test(input.target.value)
    ) {
      return null;
    }

    setValues({
      ...form,
      [input.target.name]: input.target.value,
    });
  };

  const onChange = (input) => {
    setValues({
      ...form,
      [inputName]: input,
    });
  };

  const sendDataUnused = (e) => {
    e.preventDefault();
    const list = configFields;
    let newItem = [];
    let formIsValid = true;
    list.forEach(function (item, i) {
      if (!validForm(form, configFields[i], configFields)) {
        newItem.push({ ...configFields[i], isOk: false });
        setConfigFields(newItem);
        formIsValid = false;
      } else {
        newItem.push({ ...configFields[i], isOk: true });
        setConfigFields(newItem);
      }
    });

    if (formIsValid) props.onHandleUnsed(form);
  };

  const validForm = (field, objVerify, objVerifyComplete) => {
    const name = objVerify.name;
    if (field[name] === "") return false;

    if (
      field[name].toString().length < objVerify.minLenght ||
      field[name].toString().length > objVerify.maxLength
    )
      return false;

    // eslint-disable-next-line eqeqeq
    if (
      name == "numFinal" &&
      // eslint-disable-next-line radix
      parseInt(field[name]) < parseInt(field["numInicial"])
    )
      return false;

    return true;
  };

  const cancel = () => {
    setValues(formInit);
    props.setOpenInutilizacao(false);
  };

  return (
    <div>
      <KeyboardProvider>
        <Modal
          isOpen={props.showModal}
          closeOnOverlayClick={true}
          center
          style={customStyle}
          ariaHideApp={false}
        >
          <span>INUTILIZAÇÃO NFC-E</span>
          <FormWrapper className={classNames({ isSmartPos })}>
            <div className="row">
              <InputGroup className="field-default">
                <label>Ano</label>
                <input
                  type="text"
                  id="numAno"
                  name="numAno"
                  minLength="4"
                  maxLength="4"
                  value={form.numAno}
                  disabled
                ></input>
                <span className={classNames({ 'visible': !configFields[0].isOk })}>
                  {configFields[0].msgErroAno}
                </span>
              </InputGroup>
              <InputGroup className="field-default">
                <label>Série</label>
                <input
                  type="text"
                  id="numserie"
                  name="numserie"
                  minLength="1"
                  maxLength="3"
                  value={form.numserie}
                  disabled
                ></input>
                <span className={classNames({ 'visible': !configFields[1].isOk })}>
                  {configFields[1].msgErroSerie}
                </span>
              </InputGroup>
            </div>
            <div className="row">
              <InputGroup className="field-default">
                <label>Número Inicial</label>
                <input
                  data-use-touch-keyboard
                  type="text"
                  id="numInicial"
                  name="numInicial"
                  minLength="1"
                  maxLength="9"
                  value={form.numInicial}
                  onChange={updateField}
                ></input>
                <span className={classNames({ 'visible': !configFields[2].isOk })}>
                  {configFields[2].msgErroIni}
                </span>
              </InputGroup>
              <InputGroup className="field-default">
                <label>Número Final</label>
                <input
                  data-use-touch-keyboard
                  type="text"
                  id="numFinal"
                  name="numFinal"
                  minLength="1"
                  maxLength="9"
                  value={form.numFinal}
                  onChange={updateField}
                ></input>
                <span className={classNames({ 'visible': !configFields[3].isOk })}>
                  {configFields[3].msgErroFim}
                </span>
              </InputGroup>
            </div>
            <div className="row">
              <InputGroup className="field-text-area">
                <label>Justificativa</label>
                <textarea
                  data-use-touch-keyboard
                  id="justificativa"
                  name="justificativa"
                  cols="40"
                  rows="5"
                  minLength="1"
                  maxLength="256"
                  value={form.justificativa}
                  onChange={updateField}
                ></textarea>
                <span className={classNames({ 'visible': !configFields[4].isOk })}>
                  {configFields[4].msgErroJust}
                </span>
              </InputGroup>
            </div>
            <div className="row">
              <div className="container-buttons">
                <Button
                  tabIndex="0"
                  id="btn-conf-cancel"
                  type="button"
                  className="btn-purple btn-inutilizar"
                  handleClick={(e) => cancel(e)}
                >
                  Fechar
                </Button>
                <Button
                  tabIndex="0"
                  id="btn-conf-cancel"
                  type="button"
                  className="btn-purple btn-inutilizar"
                  handleClick={(e) => sendDataUnused(e)}
                >
                  Inutilizar
                </Button>
              </div>
            </div>
          </FormWrapper>
        </Modal>
      </KeyboardProvider>
    </div>
  );
}

export default InutilizaNotes;
