import Configuration from "../aaone/configuration";
import qs from "qs";
import { transactionData } from "../aaone/shared/utils/transactionNumber";
import axios from "axios";
import { apiReshop } from "./api";
import { isLayoutUtil } from "../aaone/shared/utils/isLayout";
import { PDV_COD_LOJA_KEY_NAME, TOKEN_RESHOP_AUTH_KEY_NAME } from "../pdv-one/constants/keyNamesSessionStorage";

// Se houver uma config de client reshop, a baseURL deve ser a API, caso contrário o endpoint do reshop especificado
const reshopBaseURL = Configuration.endPointReshopClient ? Configuration.endPointProductsAll : Configuration.endPointReshop;

// Adicionar estes headers somente se for utilizar a API para acessar o client
const addReshopClientProxyHeaders = headers => {
  headers.reshopClientBaseURL = Configuration.endPointReshopClient;
  headers.reshopBaseURL = Configuration.endPointReshop;
}

export const loginfidelidade = async () => {
  const headers = { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" };
  if (Configuration.endPointReshopClient) addReshopClientProxyHeaders(headers);

  // Se for client reshop a rota deve ser api/fidelidade/token, caso contrário reshop....linx.com.br/token
  const loginRoute = Configuration.endPointReshopClient ? `${reshopBaseURL}/api/fidelidade/token` : `${reshopBaseURL}/token`;

  try {
    const { data } = await axios.post(
      loginRoute,
      qs.stringify({
        username: sessionStorage.getItem("reshopUser"),
        password: sessionStorage.getItem("reshopPassword"),
        grant_type: "password",
      }),
      {
        headers: headers
      }
    );

    window.sessionStorage.setItem(
      TOKEN_RESHOP_AUTH_KEY_NAME,
      `Bearer ${data.access_token}`
    );

    return data;
  } catch (error) {
    console.error(`Error in loginfidelidade :: `, error);
    return Promise.reject("Error in loginfidelidade ::");
  }
};

export const consultPoints = (cpf, codLoja) => {
  const url = `${reshopBaseURL}/api/fidelidade/ConsultaCliente?documentoFidelidade=${cpf}&codigoLoja=${codLoja}&considerarTransacoesPendentesSaldo=true`;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    terminalid: Configuration.terminalid,
    Authorization: window.sessionStorage.getItem(TOKEN_RESHOP_AUTH_KEY_NAME),
  };

  if (Configuration.endPointReshopClient) addReshopClientProxyHeaders(headers);

  const response = fetch(url, {
    method: "GET",
    headers: headers,
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      return res.json();
    })
    .then((data) => {
      localStorage.setItem("ValorPonto", data.ValorPonto);
      const name = data.Nome.split(" ").slice(0, 1).join(" ");
      window.localStorage.setItem("nome", name);

      return data;
    })
    .catch((e) => console.log("/consultPoints ", e));

  return response;
};

export const consultCampaign = (cpf) => {
  const url = `${reshopBaseURL}/api/MobileApp/AvailableCampaignToCustomer?document=${cpf}`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    terminalid: Configuration.terminalid,
    Authorization: window.sessionStorage.getItem(TOKEN_RESHOP_AUTH_KEY_NAME),
  };

  if (Configuration.endPointReshopClient) addReshopClientProxyHeaders(headers);

  const response = fetch(url, {
    method: "GET",
    headers,
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e) => console.log("/consultPoints ", e));

  return response;
};

export const consultDiscounts = async (
  cpf,
  items,
  codLoja = sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME),
  payment
) => {
  const total = !!items.length
    ? items.reduce((acc, cur) => {
        return (
          acc +
          (!cur.vit_numlanctoprincipal && !cur.currencyPoints
            ? cur.price * cur.qtd
            : 0)
        );
      }, 0)
    : 0;

  const itemsAdjustments = handleItems(items);

  let paymentsMethod;

  if (payment) {
    paymentsMethod = [
      {
        Tipo: payment.type,
        Valor: total,
        QtdeParcelas: 1,
        Vencimento: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
      },
    ];
    console.log("paymentsMethod prop", paymentsMethod);
  }

  let discounts = 0;
  let mensagemCampanha = {};
  let nomeCampanha = {};
  let Promocodes = undefined;

  if (JSON.parse(localStorage.getItem("promoCode")))
    Promocodes = JSON.parse(localStorage.getItem("promoCode"));

  try {
    const { data } = await apiReshop.post("/api/Fidelidade/ConsultaCampanha", {
      DocumentoFidelidade: cpf || "",
      Itens: itemsAdjustments,
      CodigoLoja: codLoja,
      CodigoTerminal: Configuration.terminalid,
      DataHora: new Date(),
      TransacaoAssociada: localStorage.getItem("NsuForConsult") || "",
      Promocodes,
      CategoriaVenda: "2",
      QtdeTotal: items.length,
      PontosResgate: "",
      Nsu: "",
      ValorBruto: total,
      ValorLiquido: total,
      Pagamentos: paymentsMethod || undefined,
      ValidaCampanha: true
    });
    discounts = data.CampanhasAtivadas.reduce((c,i) => c+i.ValorDescontoSubtotal, 0);
    mensagemCampanha = data.CampanhasAtivadas.map(
      (item) => item.DescricaoCampanha
    );
    nomeCampanha = data.CampanhasAtivadas.map((item) => item.NomeCampanha);
    localStorage.setItem("mensagemCampanha", mensagemCampanha);
    localStorage.setItem("nomeCampanha", nomeCampanha);
    localStorage.setItem("descontoReshop", data.CampanhasAtivadas.reduce((c,i) => c+i.ValorDescontoSubtotal, 0));
    localStorage.setItem("NsuForConsult", data.Nsu);
    const itensReshopForDiscount = data.CampanhasAtivadas.map((item) => item);
    localStorage.setItem(
      "itensReshopForDiscount",
      JSON.stringify(itensReshopForDiscount)
    );
  } catch (error) {
    console.error(
      `Error in consultDiscounts(params: ${cpf}, ${items}, ${codLoja}, ${payment}) :: `,
      error
    );
  }

  return discounts;
};

/**
 * @param {*[]} items
 * @returns {*[]}
 */
const handleItems = (items) => {
  return items.map((currentItem) => {
    const { code, qtd, price, vit_numlancto, promocode, value } = currentItem;
    const currentPrice = Number(price);
    const currentValue = Number(value);
    const currentQtd = Number(qtd);

    const isBobsLayout = isLayoutUtil("BOBS");

    return {
      Item: vit_numlancto,
      CodigoProduto: code,
      Qtde: currentQtd,
      PrecoUnitario: Number(isBobsLayout ? !value ? currentPrice : currentValue : currentPrice),
      Desconto: 0,
      ValorLiquido: Number(isBobsLayout ? !value ? currentPrice : currentValue : currentPrice * currentQtd).toFixed(2),
      ValorDescontoUnico: 0,
      ProdutoResgate: false,
      Promocode: promocode ? promocode : ''
    };
  })
}

export const consultPromoCode = async (cpf, inputValue) => {
  const codLoja = sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME);
  const url = `${reshopBaseURL}/api/Fidelidade/ConsultaPromocode`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    terminalid: Configuration.terminalid,
    Authorization: window.sessionStorage.getItem(TOKEN_RESHOP_AUTH_KEY_NAME),
  };

  if (Configuration.endPointReshopClient) addReshopClientProxyHeaders(headers);

  const body = {
    DocumentoFidelidade: cpf || "",
    Promocode: inputValue,
    CodigoLoja: codLoja,
    CodigoTerminal: Configuration.terminalid,
    DataHora: new Date(),
  };
  return await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  })
  .then((res) => {
    if (!res.ok) {
      throw Error(res.statusText);
    }

    return res.json();
  })
  .then((data) => {
      return data;
    })
    .catch((e) => console.log("/consultPromoCode ", e));
};

/**
 * @param {{document: string, payments: [{tipo: string, valor: string, NumeroPagamento: null, BinCartao: string}], items, storeCode: string}} requisitionData
 */
export const consultAllCampaignByPaymentType = async (requisitionData) => {
  let totalPrices = 0;

  requisitionData.items.forEach((item) => {
    totalPrices += item.qtd * item.price;
  });

  const request = {
    DocumentoFidelidade: requisitionData.document || "",
    Itens: handleItems(requisitionData.items),
    CodigoLoja: requisitionData.storeCode,
    CodigoTerminal: Configuration.terminalid,
    DataHora: new Date(),
    TransacaoAssociada: "",
    Promocode: "",
    CategoriaVenda: "2",
    QtdeTotal: requisitionData.items.length,
    PontosResgate: "",
    Nsu: "",
    ValorBruto: totalPrices.toFixed(2),
    ValorLiquido: totalPrices.toFixed(2),
    Pagamentos: requisitionData.payments
  };

  const { data } = await apiReshop.post("/api/Fidelidade/ConsultaCampanha", request);
  localStorage.setItem("NsuForConsult", data.Nsu);
  localStorage.setItem("NsuReshop", JSON.stringify(data.Nsu));
  return data;
}
