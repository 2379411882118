import React, { forwardRef } from "react";
import classNames from "classnames";

import { Container } from "../styles";
import noImage from "../../../../../linx/no_image.jpg";
import { getImageURl } from "../../../../helpers/getImageURl";
import { currency } from "../../../../../aaone/shared/utils/currency";

export const LayoutItemDefault = forwardRef(
  (
    {
      handleOnClick,
      classes,
      noHasImage,
      data,
      code,
      thumbnailContent,
      productFractional,
      children,
      ...rest
    },
    ref
  ) => {
    const showValue = rest.showValue ?? true;

    return (
      <Container
        ref={ref}
        onClick={handleOnClick}
        role="button"
        className={classes}
      >
        <div
          className={classNames({
            picture: true,
            no__image: noHasImage,
            skeleton: noHasImage,
          })}
          style={{
            backgroundImage: `url("${
              data?.product?.image
                ? getImageURl(data.product.image)
                : getImageURl(data?.image) ?? noImage
            }")`,
          }}
        >
          {typeof code === "string" && <div className="code">{code}</div>}
          {thumbnailContent && <>{thumbnailContent}</>}
        </div>
        <div className="title">{children}</div>
        {showValue && (
          <div className="price">
            {data?.pointPrice
              ? `${data.pointPrice}pts`
              : currency(
                  data?.product?.displayValue ??
                    data?.displayValue ??
                    data?.value
                )}
            {productFractional && <div className="product-fractional">kg</div>}
          </div>
        )}
      </Container>
    );
  }
);
