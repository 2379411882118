import { apiBff, apiProducts } from "./api";

const getTypeCode = () => window.localStorage.getItem("typeCode");

export const loadCategoryItems = async (pdvCode) => {
  try {
    const { data } = await apiProducts.get("/api/categories", {
      params: { pdvCode },
    });

    return data;
  } catch (error) {
    console.error("Error in loadCategoryItems :: ", error);
    return Promise.reject("Error in loadCategoryItems :: ");
  }
};

/**
 *
 * @param {*} category
 * @param {*} withLevelsFilter
 * @param {Number} filterRestrition - Restrição de produtos 1 não exibe 0 exibe
 * @returns
 */
export const loadCategoryProducts = async (
  category,
  withLevelsFilter = true,
  filterRestrition
) => {
  try {
    const { data } = await apiProducts.get(`/api/products`, {
      params: {
        category,
        consumptionPlace: getTypeCode() ?? null,
        withLevel: withLevelsFilter,
        filterRestrition,
      },
    });

    return data;
  } catch (error) {
    console.error("Error in loadCategoryProducts :: ", error);
    return Promise.reject("Error in loadCategoryProducts :: ");
  }
};
export const loadProductsFidelity = async (categoryCode, reshopItems) => {
  try {
    const consumptionPlace = getTypeCode() || "L";
    const { data } = await apiProducts.post("/api/products/fidelity", {
      categoryCode,
      consumptionPlace,
      reshopItems: reshopItems,
    });

    return data;
  } catch (error) {
    console.error("Error in loadProductsFidelity :: ", error.isAxiosError);
  }
};

export const loadSubProducts = async (productCode) => {
  try {
    const { data } = await apiProducts.get("/api/products/complete", {
      params: {
        productCode,
        consumptionPlace: getTypeCode() ?? null,
      },
    });

    return data;
  } catch (error) {
    console.error("Error in loadSubProducts :: ", error);
    return Promise.reject("Error in loadSubProducts :: ");
  }
};

export const getProductWithAllPossibilities = async (productCode) => {
  try {
    const consumptionPlace = getTypeCode();
    const { data } = await apiProducts.get(
      "/api/products/with-all-possibilities",
      {
        params: {
          consumptionPlace,
          productCode,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error in loadSubProducts :: ", error);
    return Promise.reject("Error in loadSubProducts :: ");
  }
};
