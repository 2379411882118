import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.main`
  --sidebar-width: 412px;

  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: var(--sidebar-width) calc(
      100% - var(--sidebar-width) - 1.5rem
    );
  grid-template-rows: 100%;

  grid-gap: 1.5rem 1.5rem;
  gap: 1.5rem 1.5rem;

  flex: 1;

  .sidebar {
    border-right: 1px solid #eee;
    grid-area: sidebar;
    position: relative;
  }

  .content {
    display: flex;
    grid-area: content;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 0px;
    grid-gap: 1rem;
    gap: 1rem;

    position: relative;

    &.isHide {
      display: none;
      grid-area: none;
    }
  }

  &.isSidebarRight {
    grid-template-areas: "content sidebar";
    grid-template-columns: calc(100% - var(--sidebar-width) - 1.5rem) var(
        --sidebar-width
      );

    .content {
      padding: 1rem 0 1rem 1rem;
    }

    .sidebar {
      border-right: 0;
      border-left: 1px solid #eee;
    }
  }

  &.isSidebarCartHide {
    grid-template-areas: "content content";
    grid-template-columns: 1fr 1fr;

    .sidebar {
      display: none;
    }

    .content {
      padding: 1rem;
    }
  }

  ${mediaQueryHelper("vertical").lessThan(css`
    grid-template-areas:
      "content content"
      "sidebar sidebar";
    grid-template-columns: 1fr;

    grid-gap: 1.5rem 0;
    gap: 1.5rem 0;

    &.isSmartPos{
      grid-gap: 0;
      gap: 0;
    }

    .content {
      padding: 1rem;
    }

    &.isSidebarRight {
      grid-template-areas:
        "sidebar sidebar"
        "content content";
    }

    &.isSidebarCartHide {
      grid-template-areas: "content content" "content content";
      grid-template-columns: 1fr;

      .sidebar {
        display: none;
      }
    }
  `)}

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .content {
      padding: 1rem 1rem 0;
    }
  `)}
`;
