import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content__wrapper {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;

    .form__container {
      max-width: 750px;
      margin: 1rem auto;
      width: 100%;

      &.isSmartPos {
        margin: 0 auto;
      }

      .input__group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;

        .flex-row {
          display: flex;
          flex-direction: row;

          > .input__group {
            width: 50%;
          }

          .input__group + .input__group {
            margin-left: 1rem;
          }

          &.isSmartPos {
            flex-direction: column;

            > .input__group {
              width: 100%;
            }

            .input__group + .input__group {
              margin-left: 0;
            }
          }
        }

        label.title {
          padding-left: 5px;
          font-weight: 700;
        }

        select {
          border: transparent;
          border-radius: 4px;
          margin-top: 1.5rem;
          font-weight: 700;
        }

        input {
          padding: 0.5rem;
          font-size: 1rem;
          border: 0;
          border-radius: 4px;

          margin-right: 1rem;
          flex: 1;

          outline: none;

          &:last-child {
            margin-right: 0;
          }

          &:not(.control) {
            margin-top: 1.5rem;
          }
        }
      }

      .buttons__container {
        display: flex;
        margin-top: 1rem;

        button.button__default {
          text-transform: uppercase;
          text-align: center;
          width: 100%;

          & + .button__default {
            margin-left: 1rem;
          }
        }

        button.button__submit {
          background-color: rgba(46, 0, 55, 1);
          color: #fff;
          width: 100%;
        }
      }

      .react-datepicker-wrapper {
        &,
        .react-datepicker__input-container {
          &,
          input {
            width: 100%;
          }
        }
      }
    }
  }
`;
