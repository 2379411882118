import React from "react";
import { useSelector, useDispatch } from "react-redux";

import SummaryWrapper from "./style";

import { currency } from "../../utils/currency";

import { removeFromCart, sum, sub, cartSequence } from "../../../actions/cart";
import summaryEmpty from "../../../assets/undraw_no_data_qbuo.svg";
import { LayoutCustomizationItem } from "../../utils/layoutCustomizationItem";

const Summary = ({ tag, hasFooter, showTotal, hasImg, discounts }) => {
  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cart.items);

  const total = !!cartItems.length
    ? cartItems.reduce((acc, cur) => {
        return (
          acc +
          (!cur.vit_numlanctoprincipal && !cur.currencyPoints ? cur.price : 0) *
            cur.qtd
        );
      }, 0)
    : 0;

  const totalPoints = !!cartItems.length
    ? cartItems.reduce((acc, cur) => {
        return acc + cur.valuePoints * cur.qtd;
      }, 0)
    : 0;

  if (hasFooter && totalPoints < 20 && totalPoints !== 0) {
    localStorage.setItem("NotEnoughPoints", true);
  } else {
    localStorage.removeItem("NotEnoughPoints");
  }

  const updateCustomizations = (index, item) => {
    cartItems[index].customizations = cartItems
      .filter(
        (cart) =>
          item.vit_numlancto &&
          item.vit_numlancto === cart.vit_numlanctoprincipal
      );
    cartItems[index].customizations = LayoutCustomizationItem(cartItems[index].customizations);
  };

  const sumItem = (index, item) => {
    if (item.currencyPoints) {
      return;
    }

    dispatch(sum(index));

    for (let i = 0; i < cartItems.length; i++) {
      if (
        item.vit_numlancto &&
        item.vit_numlancto === cartItems[i].vit_numlanctoprincipal
      )
        dispatch(sum(i));
    }

    if (cartItems[index].customizations.length > 0)
      updateCustomizations(index, item);
  };

  const subItem = (index, item) => {
    dispatch(sub(index));

    for (let i = 0; i < cartItems.length; i++) {
      if (
        item.vit_numlancto &&
        item.vit_numlancto === cartItems[i].vit_numlanctoprincipal
      )
        dispatch(sub(i));
    }

    if (cartItems[index].customizations.length > 0)
      updateCustomizations(index, item);
  };

  const remove = (index, item) => {
    if (item.currencyPoints) {
      let getPoints = localStorage.getItem("saldoAtual");

      localStorage.setItem(
        "saldoAtual",
        parseInt(getPoints) + item.qtd * item.valuePoints
      );
    }

    dispatch(removeFromCart(index));

    let count = 0;

    cartItems.forEach((cartItem, i) => {
      if (
        item.vit_numlancto &&
        item.vit_numlancto === cartItem.vit_numlanctoprincipal
      ) {
        dispatch(removeFromCart(count));
        count--;
      }

      count = count + 1;
    });

    dispatch(cartSequence(index));
  };

  return (
    <>
      <SummaryWrapper className="background-summary summary">
        {!cartItems.length && <img src={summaryEmpty} alt={summaryEmpty} />}

        {cartItems.map((item, indice) => {
          // eslint-disable-next-line array-callback-return
          if (item.vit_numlanctoprincipal) return;

          return (
            <li key={`${item.id}-${indice}`}>
              {hasImg &&
                (item.image !== null ? (
                  <img
                    className="summary-image"
                    src={item.image}
                    alt={item.name}
                  />
                ) : (
                  <div className="no-image-summary"></div>
                ))}

              <div className="summary-item">
                <p className="summary-item-name">{item.name}</p>

                {item.customizations.map((i, indice) => {
                  return (
                    <span
                      key={`${i[indice]}-${item.id}-${indice}`}
                      className={`summary-item-details true ${tag}`}
                    >
                      {i}
                    </span>
                  );
                })}

                {item.currencyPoints && (
                  <span className="tag-fidelity">
                    RESGATE FIDELIDADE - {item.valuePoints} PTS
                  </span>
                )}

                {!showTotal && (
                  <p className="summary-item-price">
                    <strong className={item.currencyPoints ? "hide" : ""}>
                      {currency(item.price)}
                    </strong>
                  </p>
                )}
              </div>

              <div className="summary-action">
                {item.qtd === 1 ||
                item.qtd === item.quantityDouble ||
                item.currencyPoints ? (
                  <button
                    type="button"
                    className="trash"
                    onClick={() => remove(indice, item)}
                  >
                    <i className="far fa-trash-alt"></i>
                    &nbsp;
                  </button>
                ) : (
                  <button type="button" onClick={() => subItem(indice, item)}>
                    -
                  </button>
                )}

                <span className="quantity">{item.qtd}</span>

                <button
                  type="button"
                  disabled={item.currencyPoints}
                  onClick={() => sumItem(indice, item)}
                >
                  +
                </button>
              </div>

              {showTotal && (
                <div className="total">
                  {`${
                    item.currencyPoints
                      ? item.qtd * item.valuePoints + " PTS"
                      : currency(item.qtd * item.price)
                  }`}
                </div>
              )}
            </li>
          );
        })}
      </SummaryWrapper>

      {hasFooter && totalPoints < 20 && totalPoints !== 0 && (
        <section
          className={`summary-footer notPoints display-flex ${
            discounts ? "flex-column" : ""
          }`}
        >
          {cartItems.some((i) => i.currencyPoints) && (
            <span
              className={`flex-item flex-item-points ${
                discounts ? "discount-displayed-item" : ""
              }`}
            >
              O valor mínimo para resgate é R$2,00!
            </span>
          )}
        </section>
      )}

      {hasFooter && total > 0 && (
        <section
          className={`summary-footer display-flex ${
            discounts ? "flex-column" : ""
          }`}
        >
          {cartItems.some((i) => i.currencyPoints) && (
            <span
              className={`flex-item flex-item-points flex-item-pointsRagazzo  ${
                discounts ? "discount-displayed-item" : ""
              }`}
            >
              Total de pontos fidelidade <strong>{totalPoints}</strong>
            </span>
          )}

          {discounts > 0 && total > 0 && (
            <span className="flex-item discount-displayed-item flex-item-discounts">
              <div className="subTotalClassStyle">Subtotal</div>
              <strong className="subTotalClassStyle">{`${currency(
                total
              )}`}</strong>
            </span>
          )}
          {discounts > 0 && total > 0 && (
            <span className="flex-item discount-displayed-item flex-item-discounts">
              <div className="cupomClassStyle">Cupom de desconto</div>
              <strong className="cupomClassStyle">{`-${currency(
                discounts
              )}`}</strong>
            </span>
          )}
          {total > 0 && (
            <span
              className={`flex-item ${
                discounts ? "discount-displayed-item" : ""
              }`}
            >
              <div className="ragazzoSummary">Total</div>
              <strong className="totalClassStyle">
                {currency(total - discounts)}
              </strong>
            </span>
          )}
        </section>
      )}
    </>
  );
};

export default React.memo(Summary);
