import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content__wrapper {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;

    .form__container {
      max-width: 750px;
      margin: 1rem auto;
      width: 100%;

      &.isSmartPos {
        margin: 0 auto;
      }

      .input__group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;

        .flex-row {
          display: flex;
          flex-direction: row;

          > .input__group {
            width: 50%;
          }
        }

        label.title {
          padding-left: 5px;
          font-weight: 700;
        }

        select {
          border: transparent;
          border-radius: 4px;
          margin-top: 1.5rem;
          font-weight: 700;
        }

        input {
          padding: 0.5rem;
          font-size: 1rem;
          border: 0;
          border-radius: 4px;

          margin-right: 1rem;
          margin-top: 1.5rem;
          flex: 1;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .buttons__container {
        display: flex;
      }

      button.button__submit {
        background-color: rgba(46, 0, 55, 1);
        text-transform: uppercase;
        text-align: center;
        margin: 2rem;
        color: #fff;
        width: 100%;
      }
    }
  }

  &.isSmartPos .content__wrapper .form__container .buttons__container {
    margin: 1rem 0;

    .button__submit {
      margin: 0;
      font-size: 0.75rem !important;
      border-radius: 0.5rem !important;

      & + .button__submit {
        margin-left: 1rem;
      }
    }
  }
`;

export const DetailsVersion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;

  &.modal {
    font-size: 0.9375rem;
    align-items: flex-start;

    > span {
      text-align: left;
    }
  }

  span {
    text-transform: capitalize;
  }

  .more__info {
    color: ${({ theme }) => theme.colors.purple};
    font-weight: bold;
    cursor: pointer;
    margin-top: 0.5rem;
  }
`;

export const WarnMessageText = styled.span`
  small {
    color: ${({ theme }) => theme.colors.purple};
    font-size: 0.85rem;
  }
`;
