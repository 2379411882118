import {
  PDV_ORDER_AUTHORIZATION_STATUS,
} from "../pdv-one/constants/keyNamesSessionStorage";
import { apiDegustOneOrder, apiProducts } from "./api";
import { parseAASaleToDegustOneOrderService } from "./parseAASaleToDegustOneOrderService";
import { isPDVOne } from "../aaone/configuration";
import { newDate } from "../aaone/shared/utils/getDate";
import axios from "axios";
import { sendToProductionPrinter } from "./productionPrinterService";

export const callDegustOneAuthorizeService = async (retailerId, bodySale) => {
  let callDegustOneData = null;
  let orderId = null;
  let mideSuccess = false;
  let mideError = false;
  let notaFiscalNumero = 0;
  let saleControl = 0;
  let isSuccess = false;

  const eventError = window?.desktopApp
    ? window.desktopApp.subscribe(
        "machine.devices.coupon.authorization.error",
        (_, res) => {
          callDegustOneData = res;
          mideError = true;
        }
      )
    : () => {};

  const eventSuccess = window?.desktopApp
    ? window.desktopApp.subscribe(
        "machine.devices.coupon.authorization.completed",
        (_, res) => {
          callDegustOneData = res;
          mideSuccess = true;
        }
      )
    : () => {};

  const releaseEvents = () => {
    if (window?.desktopApp) {
      window.desktopApp.unsubscribe(eventSuccess);
      window.desktopApp.unsubscribe(eventError);
    }
  };

  let bodyOrder
  try {
    bodyOrder = await parseAASaleToDegustOneOrderService(
      retailerId,
      bodySale
    );

    try {
      console.log(`api/order/createOrder | start`);

      const tableSelectedInHome = JSON.parse(
          sessionStorage.getItem("tableSelected")
      );

      let enviarParaProducao = true;
      if(tableSelectedInHome && Object.keys(tableSelectedInHome)) {
        enviarParaProducao = false;
      }

      Object.assign(bodyOrder, {
        IsAAOne: !isPDVOne,
        EnviarParaProducao: enviarParaProducao,
      });

      const { data: fiscalData } = await apiProducts.get("/api/pdv/params");

      // Configura o tipo de emissão | 0 e 2 -> NFCe / 1 -> SAT
      // más o 2 é contingência SAT, em caso de erro para transmitir com NFCe, tentará como SAT
      if (fiscalData.pdvFound && fiscalData.typeFiscalTransmission == 1) {
        bodyOrder = {
          ...bodyOrder,
          fiscalType: "SAT",
          fiscalTypeCode: 2,
          autorizacaoPeloFront: true, // usado para autorizar o sat
          typeFiscalTransmission: fiscalData.typeFiscalTransmission
          };
      } else {
        bodyOrder = {
          ...bodyOrder,
          fiscalType: "NFCe",
          fiscalTypeCode: 1,
          autorizacaoPeloFront: false,
          typeFiscalTransmission: fiscalData.typeFiscalTransmission
          };
      }

      let resp = await apiDegustOneOrder.post("/api/order/createOrder", {
        ...bodyOrder,
        //fiscalFlowUrlSat: getFiscalFlowEndpoint(),
        dateAndTime: newDate(),
      });

      // Se vier informação para transmitir pelo front, altero alguns dados e envio ao MID pelo front
      // depois devolvo ao Order para retormar o processamento e salvar no banco
      if (resp.data.autorizacaoPeloFront && resp.data.fiscalFlowBody != "")
      {

        const baseURL = `http://${fiscalData.localIPForFiscalFlow}`;
        var apiFiscalFlow = axios.create({
          baseURL,
          headers: {
            "Content-Type": 'application/json;charset=utf-8',
            "Access-Control-Allow-Origin": '*'
          },
        });

        const fiscalFlowBody = JSON.parse(resp.data.fiscalFlowBody);
        let respFiscalFlow;
        try {
          const { data } = await apiFiscalFlow.post("/NFCe/Autoriza", fiscalFlowBody);
          respFiscalFlow = data;
        }
        catch (e) {
          console.log(`/NFCe/Autoriza | resp ${JSON.stringify(e)}`);
          respFiscalFlow = {
            cStat: "0",
            xMotivo: "Erro de rede"
          };
        }

        bodyOrder = {
          ...bodyOrder,
          fiscalType: resp.data.fiscalType == 2 ? "SAT" : "NFCe",
          fiscalTypeCode: resp.data.fiscalType,
          autorizacaoPeloFront: true,
          fiscalFlowResponse: JSON.stringify(respFiscalFlow)
        };

        resp = await apiDegustOneOrder.post("/api/order/createOrder", {
          ...bodyOrder
        });
      }

      notaFiscalNumero = resp.data.receiptNumber;
      saleControl = bodyOrder.sequenceNumber;
      orderId = resp.data.orderId;
      isSuccess = resp.data?.fiscalResult?.isSuccess ?? false;
      callDegustOneData = {
        orderId: resp.data.orderId,
        cStat: resp.data.status,
        isSuccess: resp.data.fiscalResult.isSuccess,
        danfeUrl: resp.data.danfeUrlFiscalFlow,
        danfeBase64: resp.data.danfeBase64,
        reason: resp.data?.fiscalResult?.reason,
      };

      if (resp?.data?.danfeBase64) delete resp.data.danfeBase64;
      console.log(`api/order/createOrder | resp ${JSON.stringify(resp)}`);
    } catch (e) {
      console.log(`api/order/createOrder | error ${JSON.stringify(e)}`);
    }

    //callDegustOneData = await autorizarNotaFiscal(data.content.couponAuthorization);
  } catch (err) {
    releaseEvents();
    console.error(err);
    throw err;
  }

  releaseEvents();

  console.log("Indo para impressão");

  // Verifica se a NF foi emitida
  if (isSuccess) {
    // Função que envia para a impressora de produção
    setTimeout(() => {
      sendToProductionPrinter(orderId);
    }, 5000);
  }

  sessionStorage.setItem(
    PDV_ORDER_AUTHORIZATION_STATUS,
    JSON.stringify({
      isSuccess: callDegustOneData?.isSuccess,
      orderId: callDegustOneData?.orderId,
      status: callDegustOneData?.cStat,
      reason: callDegustOneData?.reason,
      bodyOrder,
    })
  );

  var response = {
    degustOneFinalization: true,
    isSuccess,
    status: callDegustOneData?.cStat,
    payload: {
      orderSale: {
        saleControl: saleControl,
        nfNumber: notaFiscalNumero,
        ...(callDegustOneData?.danfeUrl && {
          danfeUrl: callDegustOneData.danfeUrl,
        }),
        ...(callDegustOneData?.danfeBase64 && {
          danfeBase64: callDegustOneData.danfeBase64,
        }),
      },
    },
  };

  return response;
};
