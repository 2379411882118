import styled from "styled-components";

export const MessageDetail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px;
  background-color: #fff;

  i {
    display: block;
    font-size: 50px;
    flex: 0 0 40px;
    margin-right: 8px;
  }

  i[icon="help"] {
    color: #6a5a8c;
  }

  i[icon="warning"] {
    color: #ffb200;
  }
  i[icon="done"] {
    color: green;
  }
  i[icon="error"] {
    color: #bd362f;
  }
  i[icon="cached"] {
    color: #6a5a8c;
    animation: spin 1s linear infinite;
  }

  p {
    flex: 1 1 80%;
    margin: 0px;
    padding: 0px;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
  }
  div.moreinfo {
    flex: 1 1 500px;
    font-size: 14px;
    font-family: NeoSansProRegular;
    padding: 20px;
    background: #f8f8f8;
    font-weight: bold;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: calc(100vh - 350px);
    max-height: calc(100vh - 350px);
    color: rgba(223, 223, 223, 0);
  }
  div.moreinfo p {
    color: #333;
    font-size: 14px;
    font-weight: normal;
  }

  div.moreinfo p span {
    display: inline-block;
  }

  div.moreinfo p span.material-icons {
    top: 5px;
    position: relative;
  }

  div.moreinfo p span:last-child:not(.rotate) {
    color: #66bb6a;
  }

  div.moreinfo p span.material-icons.done {
    color: #66bb6a;
  }

  div.moreinfo p span.material-icons.close {
    color: #da482c;
  }

  div.moreinfo p span:first-child {
    min-width: 100px;
  }

  div.moreinfo p span.status {
    margin: 0 10px 0 20px;
  }

  div.close {
    text-align: center;
    width: 100%;
    padding: 15px;
  }

  .btn-purple {
    display: block;
    text-align: center;
    padding: 10px 20px;
    margin: 0 auto;
  }

  .message-title {
    color: #777;
    font-size: 120%;
    padding: 20px;
  }

  .submessage-title {
    color: #999;
    font-size: 100%;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
    padding: 10px 20px;
    border-top: 1px solid;
    border-bottom: 1px solid;
  }

  .rotate {
    animation: spin 1s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
`;

export const ArrowTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  right: 2%;
  top: 24%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;

export const ArrowBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  right: 2%;
  bottom: 12%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;

export const Modal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  align-items: start;
  justify-content: center;
`;

export const ModalContent = styled.div`
  max-width: 1024px;
  max-height: 500px;
  background: #fff;
`;
