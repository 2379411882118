import React, { createContext, useCallback, useMemo, useState } from "react";
import { useAuth } from "../../hook/authHook";
import {
  loadCategoryProducts,
  loadProductsFidelity,
} from "../../../services/catalogService";
import { getReshopReedemableProducts } from "../../helpers/getReshopReedemableProducts";

export const CategoriesNavigateContext = createContext({});

export const CategoriesNavigateProvider = ({ children }) => {
  const { reshop } = useAuth();

  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [currentCategoryIsFidelity, setCurrentCategoryIsFidelity] =
    useState(false);

  const handleChangeCategory = useCallback(
    async ({
      categoryId,
      isFidelity = false,
      cpfFidelity = reshop.cpfFidelity,
    }) => {
      const isAuthReshop = !!cpfFidelity;
      const productsFidelity = getReshopReedemableProducts();

      if (categoryId) {
        setCurrentCategoryId(categoryId);

        const reshopItems = productsFidelity;

        try {
          const result = await loadCategoryProducts(categoryId, false, 0);
          const resultReshop =
            isFidelity && isAuthReshop
              ? await loadProductsFidelity(categoryId, reshopItems)
              : { products: {} };
          let productsList = isFidelity
            ? resultReshop?.products
            : result?.products ?? [];

          if (isFidelity && isAuthReshop) {
            productsList = productsList.map((currentProduct) => {
              const productCampaign = productsFidelity.find(
                (productFidelity) =>
                  productFidelity.ProductCode ==
                  currentProduct.product.productCode
              );
              return {
                ...currentProduct,
                pointPrice: productCampaign.Points,
              };
            });
          }

          setCategoryProducts(isFidelity && !isAuthReshop ? [] : productsList);
        } catch (error) {
          console.log(
            "Ocorreu um erro ao puxar os produtos da categoria.\n",
            error
          );
          setCategoryProducts([]);
        }
      } else {
        setCategoryProducts([...new Array(30)]);
      }

      setCurrentCategoryIsFidelity(isFidelity);
    },
    [reshop.cpfFidelity]
  );

  const value = useMemo(
    () => ({
      currentCategoryId,
      currentCategoryIsFidelity,
      handleChangeCategory,
      categoryProducts,
      setCategoryProducts,
      setCurrentCategoryIsFidelity,
    }),
    [
      currentCategoryId,
      currentCategoryIsFidelity,
      handleChangeCategory,
      categoryProducts,
      setCategoryProducts,
      setCurrentCategoryIsFidelity,
    ]
  );

  return (
    <CategoriesNavigateContext.Provider value={value}>
      {children}
    </CategoriesNavigateContext.Provider>
  );
};
