import { apiProducts } from "./api";

export const getSuggestion = async (cartItems) => {
  const items = [];
  cartItems.forEach((item) =>
    items.push({
      productCode: item.code,
      classification: item.classification,
    })
  );

  try {
    const consumptionPlace = window.localStorage.getItem("typeCode");

    const { data } = await apiProducts.post("/api/suggestions", {
      items: items,
      consumptionPlace,
    });

    return data;
  } catch (error) {
    console.error(`Error in getSuggestion(params: ${cartItems}) ::`, error);
    return Promise.reject(`Error in getSuggestion(params: ${cartItems}) ::`);
  }
};

/**
 *
 * @param {Object} params
 * @param {Boolean} params.complete = Retorna todos os produtos e niveis
 * @returns {Object}
 */
export const getProductsAll = async (params) => {
  try {
    const { data } = await apiProducts.get("/api/productsall?complete=true", params);

    const productsAll = data.products.map((item) => ({
      description: item.description,
      image: item.image,
      catalogImage: item.catalogImage,
      featuredImage: item.featuredImage,
      productCode: item.productCode,
    }));

    window.sessionStorage.setItem("productsAll", JSON.stringify(productsAll));

    return data;
  } catch (error) {
    console.error(`Error in getProductsAll :: `, error);
    return Promise.reject("Error in getProductsAll ::");
  }
};

/**
 * @param productCode {String}
 * @returns {Object}
 */
export const getProductByCode = async (productCode) => {
  try {
    const { data } = await apiProducts.get(
      `/api/products/by-code?productCode=${productCode}`
    );

    return data;
  } catch (error) {
    console.error(`Error in getProductByCode :: `, error);
    return Promise.reject("Error in getProductByCode ::");
  }
};

/**
 * @param {Array} [params]
 * @param {String} params.productCode = Código do Produto
 * @param {Array} params.in = Onde ele está indisponibilizado
 * @returns {Object}
 */
export const setUnavailable = async (req) => {
  try {
    const { data } = await apiProducts.patch(
      `/api/products/make-product-unavailable`,
      req
    );

    return data;
  } catch (error) {
    console.error(`Error in getProductByCode :: `, error);
    return Promise.reject("Error in getProductByCode ::");
  }
};
