import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Container } from "./styles";
import { ToggleInput } from "../../components/ToggleInput";
import { HeaderPaymentMethod } from "../../components/Header/paymentMethod";
import { PagesEnum } from "../../constants/pagesEnum";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import {
  MACHINE_PRINTER_INFO_KEY_NAME,
  PRODUCTION_PRINTER_INFO_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { useDialog } from "../../hook/dialogHook";
import { KeyboardProvider } from "../../contexts/KeyboardContext";

export const PrinterSettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useDialog();
  const { setVisibleSidebarCart } = useWrapperRouters();

  const hasProductionPrinter =
    !!localStorage.getItem(PRODUCTION_PRINTER_INFO_KEY_NAME) || false;
  const hasMachinePrinter =
    !!localStorage.getItem(MACHINE_PRINTER_INFO_KEY_NAME) || false;

  const [isLocalProductionPrinter, setIsLocalProductionPrinter] = useState(
    !hasProductionPrinter
  );
  const [isLocalMachinePrinter, setIsLocalMachinePrinter] = useState(
    !hasMachinePrinter
  );

  const handleChangeLocal = (setState) => (e) => {
    setState(Boolean(e?.currentTarget?.checked ?? e?.target?.checked ?? false));
  };

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, []);

  const onSubmitProductionPrinter = (e) => {
    try {
      const data = new FormData(e.target);

      const ip = data.get("ip") || "";
      const port = data.get("port") || null;

      const payload = JSON.stringify({ ip, port });

      console.log(`Dados da impressão de produção: ${payload}`);

      localStorage.setItem(PRODUCTION_PRINTER_INFO_KEY_NAME, payload);

      showAlert({
        message: "Dados da impressora de produção foi salvo!",
      });
    } catch (error) {
      showAlert({
        message: "Dados da impressora de produção não foi salvo!",
      });
    }
  };

  const onSubmitMachinePrinter = (e) => {
    try {
      const data = new FormData(e.target);

      const ip = data.get("ip") || "";
      const port = data.get("port") || null;

      const payload = JSON.stringify({ ip, port: Number(port) });

      console.log(`Dados da impressão padrão: ${payload}`);

      localStorage.setItem(MACHINE_PRINTER_INFO_KEY_NAME, payload);

      showAlert({
        message: "Dados da impressora padrão foi salvo!",
      });
    } catch (error) {
      showAlert({
        message: "Dados da impressora padrão não foi salvo!",
      });
    }
  };

  const FormIpContainer = ({ printer, onSubmit }) => {
    const getProductionPrinterInfo = JSON.parse(
      localStorage.getItem(PRODUCTION_PRINTER_INFO_KEY_NAME) || "{}"
    );

    const getMachinePrinterInfo = JSON.parse(
      localStorage.getItem(MACHINE_PRINTER_INFO_KEY_NAME) || "{}"
    );

    const defaultValueStart = { ip: "", port: "" };
    let defaultValue = defaultValueStart;

    switch (printer) {
      case "production":
        defaultValue = getProductionPrinterInfo;
        break;
      case "machine":
        defaultValue = getMachinePrinterInfo;
        break;
      default:
        defaultValue = defaultValueStart;
    }

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div className="input__group">
          <input
            name="ip"
            type="text"
            placeholder="IP"
            data-use-touch-keyboard
            defaultValue={defaultValue.ip}
          />
          <input
            min="0"
            name="port"
            type="number"
            placeholder="Porta"
            layout-name="numeric"
            inputMode="numeric"
            data-use-touch-keyboard
            defaultValue={defaultValue.port}
          />
        </div>
        <button type="submit" className="button button__submit">
          Salvar
        </button>
      </form>
    );
  };

  useEffect(() => {
    const getPrinterInfo = localStorage.getItem(
      PRODUCTION_PRINTER_INFO_KEY_NAME
    );

    if (getPrinterInfo && hasProductionPrinter === isLocalProductionPrinter) {
      localStorage.removeItem(PRODUCTION_PRINTER_INFO_KEY_NAME);
    } else if (!getPrinterInfo) {
      localStorage.removeItem(PRODUCTION_PRINTER_INFO_KEY_NAME);
    }
  }, [isLocalProductionPrinter]);

  useEffect(() => {
    const getPrinterInfo = localStorage.getItem(MACHINE_PRINTER_INFO_KEY_NAME);

    if (getPrinterInfo && hasMachinePrinter === isLocalMachinePrinter) {
      localStorage.removeItem(MACHINE_PRINTER_INFO_KEY_NAME);
    } else if (!getPrinterInfo) {
      localStorage.removeItem(MACHINE_PRINTER_INFO_KEY_NAME);
    }
  }, [isLocalMachinePrinter]);

  useEffect(() => {
    setVisibleSidebarCart(false);
  }, [location]);

  return (
    <KeyboardProvider>
      <Container>
        <HeaderPaymentMethod onClickBackButton={handleBackToHome}>
          Configurar Impressoras
        </HeaderPaymentMethod>
        <div className="content__wrapper">
          <div className="widget__wrapper">
            <div className="widget__content">
              <div className="widget__title">Impressora Produção</div>
              <div className="input__widget-group">
                <label htmlFor="productionPrinter">Uso Local:</label>
                <ToggleInput
                  name="productionPrinter"
                  defaultChecked={isLocalProductionPrinter}
                  onChange={handleChangeLocal(setIsLocalProductionPrinter)}
                />
              </div>
              {!isLocalProductionPrinter && (
                <FormIpContainer
                  printer="production"
                  onSubmit={onSubmitProductionPrinter}
                />
              )}
            </div>
            <div className="widget__content">
              <div className="widget__title">Impressora Padrão</div>
              <div className="input__widget-group">
                <label htmlFor="localPrinter">Uso Local:</label>
                <ToggleInput
                  name="localPrinter"
                  defaultChecked={isLocalMachinePrinter}
                  onChange={handleChangeLocal(setIsLocalMachinePrinter)}
                />
              </div>
              {!isLocalMachinePrinter && (
                <FormIpContainer
                  printer="machine"
                  onSubmit={onSubmitMachinePrinter}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </KeyboardProvider>
  );
};
