import PropTypes from "prop-types";
import React, { Component, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPaymentsMethodService } from "../../../services/getPaymentsMethodService";
import CashClose from "../../components/CashClose";
import { PagesEnum } from "../../constants/pagesEnum";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { useAuth } from "../../hook/authHook";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useLoading } from "../../hook/loadingHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import PanelStatusPdv from "../../components/panel-status-pdv";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { getListMonitorNotes } from "../../../services/orderService";
import { getCloseCashDeskValues } from "../../../services/cashdeskService";
import { getAPIPayments } from "../../../services/paymentService";
import { useVersionAa } from "../../../aaone/contexts/versionContext";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { getRetailerId } from "../../../aaone/configuration";
import { openDrawer } from "../../helpers/openDrawer";
import { printCloseDiscountOperator } from "../../helpers/printer/closeDiscountOperator";
import { getCashDeskClosingPrinter } from "../../../services/printerService";
import { printerActionService } from "../../../services/actions/printerActionService";
import { printDefault } from "../../helpers/printer/printDefault";

const withHooks = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();
    const navigate = useNavigate();
    const cashdesk = useCashdesk();
    const location = useLocation();
    const loading = useLoading();
    const auth = useAuth();
    const { systemParameters } = useVersionAa();
    return (
      <Component
        {...props}
        setVisibleSidebarCart={setVisibleSidebarCart}
        systemParameters={systemParameters}
        navigate={navigate}
        location={location}
        cashdesk={cashdesk}
        loading={loading}
        auth={auth}
      />
    );
  };
};

class CashCloseContainer extends Component {
  static propTypes = {
    cashCloseBoxShow: PropTypes.func,
    administrativeMenu: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    cashCloseBoxShow: null,
    administrativeMenu: {},
  };

  constructor(props) {
    super(props);
    const systemParams = getSystemParamsHelper();
    const blindCashDesk = systemParams?.isBlindCashDeskClosing;
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      cashdesk: null,
      paymentTypesLoad: [],
      paymentType: null,
      openingAmount: 0,
      controlNumber: 0,
      manager: {},
      operator: {},
      systemAmountSummarized: 0,
      openCard: true,
      openCardList: false,
      cashkId: 0,
      isBlindCashDeskClosing: blindCashDesk ?? true,
      cashDateOpening: "",
      listCashDesks: [],

      // Flags temporarias
      operationalValues: {
        openingAmount: 0,
        supply: 0,
        withdraw: 0,
        total: 0,
        totalOperational: 0,
      },
      isCloseCashDeskLeftOverValueZero: false,
    };
    // variable to control whether to show the Cash Closed message or not, after the pos.close.success message is received
    this.onHandleSelectClosingDate = this.onHandleSelectClosingDate.bind(this);
  }

  componentDidMount() {
    try {
      this.props.setVisibleSidebarCart(false);

      const authManager = this.props.location?.state?.auth ?? null;

      if (
        this.props.cashdesk.cashdesk &&
        !this.props.cashdesk.cashdesk.closingDate &&
        this.props.cashdesk.cashdesk.user !==
          this.props.auth.userAuthData.login &&
        !this.props.auth.userAuthData.isManager &&
        !authManager
      ) {
        setTimeout(() => {
          this.props.navigate(PagesEnum.NEED_AUTH, {
            state: {
              redirectUrl: PagesEnum.CASH_CLOSE,
            },
          });
        }, 300);

        return;
      }

      this.props.loading.setShowLoading();
      const { listReducedCashdesk } = this.props.cashdesk;

      const manager = authManager?.user || this.props.auth.userAuthData;
      const operator = this.props.auth.userAuthData;

      if (listReducedCashdesk.length === 0) {
        this.setCashdesk(this.props.cashdesk.cashdesk);
      }

      getPaymentsMethodService().then((res) => {
        this.setState({
          paymentType: {
            id: res[0].nuuidOne,
            name: res[0].transactionType,
          },
        });
      });

      this.setState({
        manager,
        operator,
      });

      this.props.loading.setShowLoading(false);
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cashdesk !== this.state.cashdesk) {
      this.getCloseCashDeskValues();
    }
  }

  async getCloseCashDeskValues() {
    try {
      this.props.loading.setShowLoading(true);

      const promises = [
        getAPIPayments({ getall: true }),
        getCloseCashDeskValues(this.state.cashdesk?.id),
      ];

      await Promise.all(promises)
        .then((res) => {
          const [{ payments = {} }, { payments: cashdeskValues }] = res;

          this.setState({
            operationalValues: {
              ...res?.[1].operationalResult,
              totalOperational: res?.[1].operationalResult?.total,
            },
            isCloseCashDeskLeftOverValueZero:
              res?.[1].isCloseCashDeskLeftOverValueZero,
          });

          const closingValues = payments.reduce((acc = [], curr) => {
            if (
              !acc.find(
                (payment) => payment.paymentType.reference === curr.paymentCode
              )
            ) {
              const brands = [];

              payments.forEach((paymentType) => {
                if (curr.paymentCode === paymentType?.paymentCode) {
                  const brand = cashdeskValues?.find(
                    (brand) =>
                      paymentType.paymentOneDetails.reference ===
                      brand.referenceId
                  );

                  brands.push({
                    amount:
                      paymentType.paymentOneDetails.reference !== 1
                        ? brand?.amount || 0
                        : 0, // Não preenche o campo em dinheiro
                    id: paymentType.paymentOneDetails.nuuidOne,
                    name: paymentType.description,
                    paymentTypeId: curr.paymentOneDetails.nuuidOne,
                    systemAmount: brand?.amount || 0,
                    referenceId: paymentType.paymentCode,
                    isReadOnly:
                      paymentType.paymentOneDetails.reference !== 1
                        ? true
                        : false,
                  });
                }
              });

              acc.push({
                amount: 0,
                systemAmount: brands.reduce(
                  (acc, curr) => curr.systemAmount + acc,
                  0
                ),
                paymentType: {
                  id: curr.paymentOneDetails.nuuidOne,
                  name: curr.paymentOneDetails.paymentBaseOne.description,
                  paymentOneBaseCode: curr.paymentOneBaseCode,
                  referenceId: curr.paymentCode,
                },
                brands,
              });
            }

            return acc;
          }, []);

          this.setState({
            paymentTypesLoad: closingValues,
          });
        })
        .then(() => {
          this.props.loading.setShowLoading(false);
        });
    } catch (err) {
      this.props.loading.setShowLoading(false);
      console.error(err);
    }
  }

  /**
   * Função que seleciona uma data de fechamento
   * e retorna ela para o fechamento de caixa
   *
   */
  onHandleSelectClosingDate = (selectedCashdesk) => {
    if (!selectedCashdesk.closingDate) {
      this.setCashdesk(selectedCashdesk);
    }
  };

  setCashdesk(cashdesk) {
    const openingAmount = cashdesk.movements[0].amount;

    this.setState({
      controlNumber: cashdesk.openingCount,
      cashDateOpening: formatDateHelper(
        new Date(cashdesk.openingDate),
        "dd/MM/yyyy HH:mm:ss"
      ),
      openingAmount,
      cashdesk,
    });
  }

  onCashierOpening = (_, { openingCount }) => {
    this.setState({
      ...this.state,
      openingCount,
    });
  };

  handleClose() {
    this.props.navigate(PagesEnum.HOME);
    this.props.setVisibleSidebarCart();
    this.props.loading.setShowLoading(false);
  }

  TryParseInt(str, defaultValue) {
    let retValue = defaultValue;

    if (str !== null) {
      if (str.length > 0) {
        if (!Number.isNaN(str)) {
          retValue = parseInt(str, 10);
        }
      }
    }

    return retValue;
  }

  handleClick(formValues) {
    try {
      this.props.loading.setShowLoading(true);

      const body = {
        ...formValues,
        userId: this.state.operator.nuuidOne,
        responsableId: this.state.manager.nuuidOne,
        paymentType: this.state.paymentType,
        cashdeskId: this.state.cashdesk.id,
        posId: this.state.cashdesk.posId,
        operationalValues: this.state.operationalValues,
        isCloseCashDeskLeftOverValueZero:
          this.state.isCloseCashDeskLeftOverValueZero,
      };

      this.props.cashdesk.cashdeskClose(body).then((cashdesk) => {
        const objFilter = {
          retailerId: getRetailerId(),
          startDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
          endDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
          status: 12,
          type: 1,
        };

        getListMonitorNotes(objFilter).then((response) => {
          const orders = response.sort((a, b) => {
            if (a.receiptNumber > b.receiptNumber) {
              return 1;
            }

            if (
              Date(a.fiscalDocument?.returnDate) >
              new Date(b.fiscalDocument?.returnDate)
            ) {
              return 1;
            }

            return -1;
          });
          getCloseCashDeskValues(cashdesk.id, true).then((res) => {
            getCashDeskClosingPrinter({
              closeCashdeskValues: res,
              cashdesk,
            }).then((data) => {
              if (window.desktopApp) {
                printerActionService({
                  body: data,
                });
              } else if (window.Android) {
                console.log(`printDefault | {}`);
                printDefault(data);
              }
            });

            if (res.printDiscountVoucherOperator) {
              printCloseDiscountOperator({
                cashdesk,
                printDiscountVoucherOperatorValue:
                  res.printDiscountVoucherOperatorValue,
              });
            }

            //Abre a gaveta
            openDrawer();
          });
        });

        this.handleClose();
      });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  handleHidden(expandCardList) {
    const objState = {
      openCardList: expandCardList,
      openCard: !expandCardList,
    };
    this.setState(objState);
  }

  render() {
    const {
      paymentTypesLoad,
      systemAmountSummarized,
      openCard,
      openCardList,
      cashDateOpening,
      cashdesk,
    } = this.state;

    const { listReducedCashdesk } = this.props.cashdesk;

    return (
      <>
        {cashdesk ? (
          <KeyboardProvider>
            <CashClose
              disableConfirm={this.state.disableConfirm}
              title="FECHAMENTO DE CAIXA"
              posId={
                this.state.cashdesk.formattedPosId ??
                this.state.cashdesk.posId ??
                ""
              }
              controlNumber={this.state.controlNumber}
              openingAmount={this.state.openingAmount}
              paymentTypesLoad={paymentTypesLoad}
              closingResponsableName={this.state.manager.login}
              closingResponsableId={this.state.manager.nuuidOne}
              userName={this.state?.cashdesk?.user || ""}
              operatorId={this.state?.cashdesk?.userId || ""}
              isBlindCashDeskClosing={this.state.isBlindCashDeskClosing}
              systemAmountSummarized={systemAmountSummarized}
              handleClick={(values) => this.handleClick(values)}
              handleClose={() => this.handleClose()}
              expandCard={openCard}
              expandCardList={openCardList}
              handleHidden={(isCardList) => this.handleHidden(isCardList)}
              // TODO: Fix
              // setVisibleSideKeyboardContainer={visible => this.props.setVisibleSideKeyboardContainer(visible)}
              setVisibleSideKeyboardContainer={() => {}}
              // TODO: Fix
              // setVisibleBottomKeyboardContainer={visible => this.props.setVisibleBottomKeyboardContainer(visible)}
              setVisibleBottomKeyboardContainer={() => {}}
              cashDateOpening={cashDateOpening}
            />
          </KeyboardProvider>
        ) : (
          <>
            <PanelStatusPdv
              onHandleSelectClosingDate={this.onHandleSelectClosingDate}
              listCashdesks={listReducedCashdesk}
              handleClose={() => this.handleClose()}
            />
          </>
        )}
      </>
    );
  }
}

export default withHooks(CashCloseContainer);
