import styled from "styled-components";

const ContentWrapper = styled.section`
  .window-options {
    display: flex;
    position: absolute;
    flex-direction: row;
    right: 3rem;
    top: 2rem;

    background: rgba(0, 0, 0, .25);
    padding: 1rem;
    border-radius: 15px;

    .logo img {
      margin-right: 2rem;
      width: 70px;
    }

    .close {
      background: rgba(255, 0, 0, 0.5);
      padding: 1rem 1.5rem;
      border-radius: 14px;
      font-size: 2rem;
      font-family: Arial, sans-serif;
      font-weight: 700;
    }
  }

  .footer {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    padding: 2em 0 4em 0;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);

    button {
      width: 385px;
      outline: none;
    }
  }

  .template-columns-1 {
    grid-template-columns: 1fr;

    button {
      outline: none;
      width: 100%;
    }
  }

  .leitora {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
`;

export default ContentWrapper;
