export const hasCheckInternetConnectedHelper = () => {
  const status = navigator?.onLine
  ? fetch("https://linx.com.br", {
      method: "HEAD",
      mode: "no-cors",
    })
      .then(() => true)
      .catch((e) => false)
  : false;

return status;
}
