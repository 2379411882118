import React from 'react';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { Container } from './styles';

export const Loading3Quarters = () => {
  return (
    <Container className="loading__container">
      <AiOutlineLoading3Quarters className="icon" />
    </Container>
  );
}
