import { getTerminalID, isSmartPos } from "../aaone/configuration";
import { transactionData } from "../aaone/shared/utils/transactionNumber";
import { PDV_STORE_PARAMS } from "../pdv-one/constants/keyNamesSessionStorage";
import { apiRouting, apiProducts } from "./api";
import { getCurrentSystemDataInStorage } from "./getCurrentSystemVersion";

/**
 * Pesquisa por items para a impressão na impressora de produção
 *
 * @param {Object} body
 * @param {String} body.posId
 * @param {String} body.retailerId
 * @param {String} body.orderId
 */
export const getProductionPrinterData = async (body) => {
  try {
    const config = window.mobileConfig
      ? JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {}
      : JSON.parse(window.sessionStorage.getItem("AA_Config"));

    const systemParams = getCurrentSystemDataInStorage();

    const { data } = await apiRouting.post(
      "/api/printer/ProductionOrder/PrintRoutes",
      {
        isText: true,
        printerDimension: config.printSize ?? "80mm",
        retailerId: systemParams?.retailerId,
        posId: String(getTerminalID()).toString(),
        orderId: body.orderId,
        dateTimeStart: transactionData(new Date(),"dd/MM/yyyy hh:mm:ss"),
      }
    );

    return data;
  } catch (error) {
    console.log(
      `Error in getProductionPrinterData :: ${JSON.stringify(error)}`
    );
    return Promise.reject(`Error in getProductionPrinterData :: ${error}`);
  }
};

/**
 * Retorna a página para impressão do fechamento de caixa
 *
 * @param {Object} body
 * @param {String} body.closeCashdeskValues
 * @param {String} body.cashdesk
 * @param {String} body.getHTML
 *
 */
export const getCashDeskClosingPrinter = async ({
  closeCashdeskValues,
  cashdesk,
  getHTML = false,
}) => {
  try {
    cashdesk.movements = cashdesk.movements.filter(
      (x) => x.isReversed === false
    );

    const config = window.mobileConfig
      ? JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {}
      : JSON.parse(window.sessionStorage.getItem("AA_Config"));

    const { data } = await apiProducts.post("/api/printers/cashdesk/closing", {
      isText: !!window.Android && !getHTML,
      printerDimension: isSmartPos ? "56mm" : config.printSize ?? "80mm",
      object: {
        closeCashdeskValues,
        cashdesk,
      },
    });

    return data;
  } catch (error) {
    console.log(
      `Error in getCashDeskClosingPrinter :: ${JSON.stringify(error)}`
    );
    return Promise.reject(`Error in getCashDeskClosingPrinter :: ${error}`);
  }
};

/**
 * Retorna a página para impressão do fechamento de caixa
 *
 * @param {Object} body
 * @param {String} body.via
 * @param {Array} body.withdraw
 *
 */
export const getCashDeskArmoredCarPrinter = async ({ via, withdraw }) => {
  try {
    const config = window.mobileConfig
      ? JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {}
      : JSON.parse(window.sessionStorage.getItem("AA_Config"));

    const systemParams = JSON.parse(
      window.sessionStorage.getItem(PDV_STORE_PARAMS)
    );

    const { data } = await apiProducts.post(
      "/api/printers/cashdesk/amored-car",
      {
        isText: !!window.Android,
        printerDimension: config.printSize ?? "80mm",
        object: {
          storeName: systemParams.storeName,
          via,
          withdraw,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(
      `Error in getCashDeskArmoredCarPrinter :: ${JSON.stringify(error)}`
    );
    return Promise.reject(`Error in getCashDeskArmoredCarPrinter :: ${error}`);
  }
};
