import React, { Component } from "react";
import classNames from "classnames";

import { isSmartPos } from "../../../aaone/configuration";

import {
  CashWithdrawWrapper,
  InputGroup,
  ConfirmFormActions,
  GroupButton,
} from "./style";
import { currencyMask } from "../../helpers/masks";
import CurrencyInput from "react-currency-input";
import Card from "../../components/Card";
import KeyboardPlugin from "../../components/keyboard-plugin";
import Button from "../../components/Button";
import TableList from "./table-list";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";

const withHook = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();

    return (
      <Component {...props} setVisibleSidebarCart={setVisibleSidebarCart} />
    );
  };
};

class CashWithdraw extends Component {
  static defaultProps = {
    showRequired: true,
  };

  constructor(props) {
    super(props);
    this.amountInput = null;
    this.motivoInput = null;
    this.numberEnvelopInput = null;
    this.keyboardNumber = null;

    this.state = {
      hasErrorInputAmountInput: false,
      hasErrorInput: false,
      amountValue: 0,
      amountInputValue: 0,
      descriptionValue: "",
      numberEnvelopValue: "",
      keyboardVisible: false,
      disabledKeyboard: false,
      layoutName: "default",
      inputName: "input_amount",
    };
  }

  submitHandler = (event, action) => {
    let validate = true;

    event.preventDefault();

    const amountInput = this.amountInput.state.value;

    if (amountInput === 0) {
      validate = false;

      this.setState({
        hasErrorInputAmountInput: true,
        amountInputValue: amountInput,
      });
    } else {
      this.setState({
        hasErrorInputAmountInput: false,
        amountInputValue: amountInput,
      });
    }

    if (this.motivoInput.value.trim() === "") {
      validate = false;

      this.setState({
        hasErrorInput: true,
      });
    } else {
      this.setState({
        hasErrorInput: false,
      });
    }

    if (this.numberEnvelopInput.value.trim() === "") {
      validate = false;

      this.setState({
        hasErrorInput: true,
      });
    } else {
      this.setState({
        hasErrorInput: false,
      });
    }

    if (this.props.bypassValidation) {
      validate = true;

      if (amountInput > 0) {
        if (this.numberEnvelopInput.value.trim() === "") {
          validate = false;
          this.setState({
            hasErrorInput: true,
          });
        } else if (this.motivoInput.value.trim() === "") {
          validate = false;
          this.setState({
            hasErrorInput: true,
          });
        }
      }

      if (
        this.numberEnvelopInput.value.trim() !== "" ||
        this.motivoInput.value.trim() !== ""
      ) {
        if (this.numberEnvelopInput.value.trim() === "") {
          validate = false;
          this.setState({
            hasErrorInput: true,
          });

          if (amountInput === 0) {
            validate = false;
            this.setState({
              hasErrorInputAmountInput: true,
            });
          }
        } else if (this.motivoInput.value.trim() === "") {
          validate = false;
          this.setState({
            hasErrorInput: true,
          });

          if (amountInput === 0) {
            validate = false;
            this.setState({
              hasErrorInputAmountInput: true,
            });
          }
        }
      }
    }

    if (validate) {
      this.props.handleClick(
        this.amountInput.state.value,
        this.motivoInput.value.trim(),
        this.numberEnvelopInput.value.trim()
      );

      this.setState({
        hasErrorInputAmountInput: false,
        hasErrorInput: false,
      });
    }
  };

  toggleCard = (expandCardList) => {
    if (expandCardList && this.props.listWithdraw.length === 0) return;

    this.props.handleHidden(expandCardList);
  };

  componentDidMount() {
    this.props.setVisibleSidebarCart(false);
  }

  onChangeAmountInput = (value) => {
    this.setState({
      amountValue: value,
    });
  };

  onChangeDescriptionInput = (event) => {
    this.setState({
      descriptionValue: event.target.value,
    });
  };

  onChangeNumberEnvelopInput = (event) => {
    this.setState({
      numberEnvelopValue: event.target.value,
    });
  };

  keyboardOnChangeAll = (value) => {
    if (this.state.inputName === "input_amount") {
      this.setState({ amountValue: value });
    }

    if (this.state.inputName === "input_description") {
      this.setState({ descriptionValue: value });
    }

    if (this.state.inputName === "input_numberEnvelop") {
      this.setState({ numberEnvelopValue: value });
    }
  };

  keyboardOnKeyPress = (button) => {
    if (button === "{enter}" || button === "{close}") {
      this.setState({
        keyboardVisible: false,
      });
    }

    if (button === "{shift}" || button === "{lock}") {
      let activeLayoutName = this.state.layoutName;

      this.setState({
        layoutName: activeLayoutName === "default" ? "shift" : "default",
      });
    }

    if (button === "{clear}") {
      if (this.state.inputName === "input_amount") {
        this.setState({ ...this.state, amountValue: 0 });
        this.keyboardNumber.clearInput(`${this.state.inputName}`);
      }

      if (this.state.inputName === "input_description") {
        this.setState({ ...this.state, descriptionValue: "" });
        this.keyboard.clearInput(`${this.state.inputName}`);
      } else if (this.state.inputName === "input_numberEnvelop") {
        this.setState({ numberEnvelopValue: "" });
        this.keyboard.clearInput(`${this.state.inputName}`);
      }
    }
  };

  keyboardSetActiveInput = (inputName) => {
    switch (inputName) {
      case "input_amount":
        this.setState({
          keyboardVisible: false,
          disabledKeyboard: false,
        });
        break;
      case "input_description":
        this.setState({
          layoutName: "default",
          keyboardVisible: true,
          disabledKeyboard: true,
        });
        break;
      case "input_numberEnvelop":
        this.setState({
          layoutName: "default",
          keyboardVisible: true,
          disabledKeyboard: true,
        });
        break;
      default:
        break;
    }

    this.setState({
      inputName: inputName,
    });
  };

  closeKeyboard() {
    this.setState({ keyboardVisible: false });
  }

  render(action) {
    const {
      handleClose,
      title,
      manager,
      controlNumber,
      closeEnable = true,
      listWithdraw,
      handlePrinter,
      handleChargeback,
    } = this.props;

    return (
      <CashWithdrawWrapper className={classNames({ isSmartPos })}>
        <KeyboardPlugin
          visible={true}
          customClassName="keyboard-cashier-withdraw"
          baseClass={"id-keyboard-cash-withdraw-numeric"}
          titleDescription="Valor"
          layoutName={"numeric"}
          numericType={"currency"}
          disabledKeyboard={this.state.disabledKeyboard}
          onKeyPress={(button) => this.keyboardOnKeyPress(button)}
          onChangeAll={(value) => this.keyboardOnChangeAll(value)}
        />
        <div className="cash-withdraw">
          <Card
            id="card"
            title={`${title}`}
            className={!this.props.expandCard ? "expand-card" : ""}
            closeEnable={closeEnable}
            handleClose={handleClose}
            handleHidden={() => this.toggleCard(false)}
          >
            <form onSubmit={this.submitHandler}>
              <InputGroup>
                <label>Nº Controle</label>
                <div>
                  <input value={controlNumber} readOnly disabled />
                </div>
              </InputGroup>
              <InputGroup>
                <label>Responsável</label>
                <div>
                  <input value={manager.login} readOnly disabled />
                </div>
              </InputGroup>
              <InputGroup>
                <label htmlFor="amount">
                  Valor
                  {!this.props.showRequired &&
                  Number(this.state.amountValue) === 0
                    ? ""
                    : "*"}
                </label>
                <div>
                  <CurrencyInput
                    id="amount"
                    ref={(input) => (this.amountInput = input)}
                    decimalSeparator=","
                    thousandSeparator="."
                    inputMode="numeric"
                    onChange={(value) => this.onChangeAmountInput(value)}
                    onFocus={() => this.keyboardSetActiveInput("input_amount")}
                    value={this.state.amountValue}
                  />
                  {this.state.hasErrorInputAmountInput && (
                    <span>Informe um valor maior que zero!</span>
                  )}
                </div>
              </InputGroup>
              <InputGroup>
                <label htmlFor="description">
                  Informe o motivo
                  {!this.props.showRequired &&
                  Number(this.state.amountValue) === 0
                    ? ""
                    : "*"}
                </label>
                <div>
                  <input
                    data-use-touch-keyboard
                    id="description"
                    type="text"
                    className="medium-size input-custom"
                    ref={(input) => {
                      this.motivoInput = input;
                    }}
                    onChange={(e) => this.onChangeDescriptionInput(e)}
                    onFocus={() =>
                      this.keyboardSetActiveInput("input_description")
                    }
                    onBlur={() => this.closeKeyboard()}
                    value={this.state.descriptionValue}
                  />
                  {this.state.hasErrorInput && this.motivoInput.value === "" ? (
                    <span>Informe o motivo!</span>
                  ) : (
                    <></>
                  )}
                </div>
              </InputGroup>
              <InputGroup>
                <label htmlFor="numberEnvelop">
                  Ident. Sangria (Envelope)
                  {!this.props.showRequired &&
                  Number(this.state.amountValue) === 0
                    ? ""
                    : "*"}
                </label>
                <div>
                  <input
                    data-use-touch-keyboard
                    id="numberEnvelop"
                    type="text"
                    className="medium-size input-custom"
                    ref={(input) => {
                      this.numberEnvelopInput = input;
                    }}
                    value={this.state.numberEnvelopValue}
                    onChange={(e) => this.onChangeNumberEnvelopInput(e)}
                    onFocus={() =>
                      this.keyboardSetActiveInput("input_numberEnvelop")
                    }
                    onBlur={() => this.closeKeyboard()}
                  />
                  {this.state.hasErrorInput &&
                  this.numberEnvelopInput.value === "" ? (
                    <span>Informe o número do envelope!</span>
                  ) : (
                    <></>
                  )}
                </div>
              </InputGroup>
              <ConfirmFormActions className={classNames({ isSmartPos })}>
                <Button
                  id="confirmar"
                  className="btn-large btn-purple btn-custom"
                  type="submit"
                  handleClick={this.validate}
                >
                  Confirmar
                </Button>
              </ConfirmFormActions>
            </form>
          </Card>
          <Card
            id="cardList"
            title={`CONSULTA ${title}`}
            className={!this.props.expandCardList ? "expand-card" : ""}
            handleHidden={() => this.toggleCard(true)}
            disabledBtnArrow={listWithdraw.length === 0}
            controlScroll={this.props.tableCardList ? true : false}
          >
            <div className="amount-list">
              <span>
                {listWithdraw.length}{" "}
                {listWithdraw.length > 1 ? "sangrias" : "sangria"} realizado
                {listWithdraw.length > 1 ? "s" : ""}.
              </span>
            </div>
            <GroupButton
              className="group-button"
              tableCardList={this.props.tableCardList}
            >
              <span
                className="btn-actions"
                onClick={() => this.updateFormatList(!this.props.tableCardList)}
              >
                <i className="material-icons">
                  {this.props.tableCardList ? "list" : "apps"}
                </i>
              </span>
            </GroupButton>

            {this.props.tableCardList && (
              <div className="grid-list-card">
                <>
                  {listWithdraw.map((item, i) => {
                    return (
                      <Card id={`card${i}`} key={i}>
                        <section className="card-list">
                          <h1>{item.description}</h1>
                          <div>
                            <span>
                              <strong>Nº controle:</strong>{" "}
                              {item.referenceSequence}
                            </span>
                            <span>
                              <strong>Nº Envelope:</strong> {item.numberEnvelop}
                            </span>
                            <span>
                              <strong>Cancelado: </strong>{" "}
                              {item.isReversed ? " Sim" : " Não"}
                            </span>
                            <span>
                              <strong>Valor:</strong>{" "}
                              {currencyMask(item.amount)}
                            </span>
                          </div>
                          <section>
                            <button
                              className="btn-card-list"
                              disabled={item.isReversed}
                              onClick={() => handleChargeback(item)}
                            >
                              <i className="material-icons">money_off</i>
                              <span>Estornar</span>
                            </button>
                            <button
                              className="btn-card-list"
                              onClick={() => handlePrinter(item.id)}
                            >
                              <i className="material-icons">print</i>
                              <span>Imprimir</span>
                            </button>
                          </section>
                        </section>
                      </Card>
                    );
                  })}
                </>
              </div>
            )}
            <TableList
              listWithdraw={listWithdraw}
              handlePrinter={handlePrinter}
              handleChargeback={handleChargeback}
            />
          </Card>
        </div>
      </CashWithdrawWrapper>
    );
  }
}

export default withHook(CashWithdraw);
