import React, {
  useEffect,
  useRef,
  useState
} from "react";

import { TimerH2 } from "./style.js";

const Timer = ({
  chosenSeconds,
  action,
  start,
  paramAction = null
}) => {
  let [timer, setTimer] = useState(0);
  const [chosenTimer, setChosenTimer] = useState('00:01:00');
  const Ref = useRef(null);

  let interval;
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 * 60 * 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }
  const startTimer = (e) => {
    const { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      );
    } else if(!isNaN(total)) {
      clearInterval(Ref.current);
      action(paramAction);
    }
  }

  const clearTimer = (e) => {
    setTimer(chosenTimer);
    if (Ref.current) clearInterval(Ref.current);
    interval = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = interval;
  }

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + chosenSeconds)
    return deadline;
  }

  const stop = () => {
    if (Ref !== null) {
      clearInterval(Ref.current);
      clearInterval(interval);
      setTimer("");
    }
  };
  useEffect(() => {
    if (start) clearTimer(getDeadTime());
    else stop();
  }, [start, chosenTimer]);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + chosenSeconds)
    const {hours, minutes, seconds} = getTimeRemaining(currentDate);
    setChosenTimer(
      (hours > 9 ? hours : '0' + hours) + ':' +
      (minutes > 9 ? minutes : '0' + minutes) + ':'
      + (seconds > 9 ? seconds : '0' + seconds)
    )
    clearTimer(getDeadTime());
  }, []);

  return (
    <div>
      <TimerH2>
        &nbsp;{timer}
      </TimerH2>
    </div>
  );
}
export default React.memo(Timer);
