import React from "react";

import { Container } from "./styles";

export const LoadingDots = () => (
  <Container className="loading-dots-container">
    <div className="dots">
      <div className="one" />
    </div>
    <div className="dots">
      <div className="two" />
    </div>
    <div className="dots">
      <div className="three" />
    </div>
  </Container>
);
