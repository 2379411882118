import React, { useReducer, useContext, useState, useMemo } from "react";

const ViewScrollingContext = React.createContext();
const ViewContext = React.createContext();
const ViewDispatchContext = React.createContext();

function reducer(state, action) {
  return { view: action };
}

export const ProviderContext = React.memo((props) => {
  const [state, dispatch] = useReducer(reducer, { view: "step.serviceStatusProxy" });
  const [pinnedViewAtBottom, setPinnedViewAtBottom] = useState(false);

  const values = useMemo(
    () => ({ pinnedViewAtBottom, setPinnedViewAtBottom }),
    [pinnedViewAtBottom, setPinnedViewAtBottom]
  );

  return (
    <>
      <ViewContext.Provider value={state}>
        <ViewDispatchContext.Provider value={dispatch}>
          <ViewScrollingContext.Provider value={values}>
            {props.children}
          </ViewScrollingContext.Provider>
        </ViewDispatchContext.Provider>
      </ViewContext.Provider>
    </>
  );
});

export const useViewState = () => useContext(ViewContext);
export const useViewDispatch = () => useContext(ViewDispatchContext);
export const useViewScrolling = () => useContext(ViewScrollingContext);
