/**
 * @param array {[]}
 */
export const removeFirstPositionArray = (array) => {
  const newArray = [];

  array.forEach((object, index) => {
    if (index > 0)
      newArray.push(object);
  })

  return newArray;
};
