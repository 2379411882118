import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useCart } from "../../hook/cartHook";
import { useCashdesk } from "../../hook/cashdeskHook";

import { Categories } from "../../components/Categories";
import { Products } from "../../components/Products";
import { ProductsInCart } from "../../components/ProductsInCart";

import Dialog from "../../components/dialog";
import { Container } from "./styles";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { PagesEnum } from "../../constants/pagesEnum";
import { isSmartPos } from "../../../aaone/configuration";

export const HomePage = () => {
  const location = useLocation();
  const { itemsInCart, currentProductStep } = useCart();
  const { setVisibleSidebarCart } = useWrapperRouters();
  const {
    cashdesk,
    showCashierClosingDay,
    setShowCashierClosingDay,
    cashdeskCloseDay,
  } = useCashdesk();

  useEffect(() => {
    if (location?.state?.props?.showCashierClosingDay) {
      setShowCashierClosingDay(true);
    }

    if (location.pathname === PagesEnum.HOME) {
      setVisibleSidebarCart(true);
    }
  }, [location, setShowCashierClosingDay]);

  return (
    <Container>
      {showCashierClosingDay && (
        <Dialog
          dialogTitle={`ENCERRAMENTO DO DIA ${formatDateHelper(
            cashdesk?.openingDate ? new Date(cashdesk.openingDate) : new Date(),
            "dd/MM/yyyy"
          )}`}
          // messageTitle="O caixa não está aberto"
          message={`Se o encerramento do dia for realizado, NÃO será possível abrir outro caixa com esta data.
          \n\nTem certeza que deseja encerrar o dia ${formatDateHelper(
            cashdesk?.openingDate ? new Date(cashdesk.openingDate) : new Date(),
            "dd/MM/yyyy"
          )}?`}
          // messageIcon="pdv-one/aberturadecaixa.png"
          okButtonText="Continuar"
          onOkButtonClick={() => cashdeskCloseDay()}
          onCloseButtonClick={() => setShowCashierClosingDay(false)}
        />
      )}
      <Categories />
      {currentProductStep?.length > 0 ? <ProductsInCart /> : <Products />}
    </Container>
  );
};
