import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useTranslation } from "react-i18next";

import { Content, Button } from "../../shared/components/modules";
import { reshopCalculateValues } from "../../../services/paymentService";
import { processesOperation } from "../../../services/verifyRedeemValueService";
import logoBobs from "../../assets/logobobs.png";
import {
  getThemeSettings,
  showPrismaPage,
  isShowTellInfo,
} from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { useViewDispatch } from "../../../context";

const OrderIdentificationContainer = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const {
    getTranslateName,
    isLayout,
    getScreenTitleBySlug,
    getScreenIsActiveBySlug,
  } = useLayoutAa();

  const yourNameBeCalledOrder =
    getTranslateName(t, "your_name_be_called_order") ||
    getScreenTitleBySlug("INFORME_SEU_NOME") ||
    DEFAULT_LOCAL_TEXTS.your_name_be_called_order;

  const beCalledOrderText =
    getTranslateName(t, "be_called_order") ||
    getThemeSettings?.text?.be_called_order ||
    "Como gostaria de ser chamado?";

  const isShowCPFPage = getScreenIsActiveBySlug("CPF_NA_NOTA") ?? true;

  const isShowCarIdentification =
    getScreenIsActiveBySlug("PLACA_VEICULO") ?? true;

  const isShowPrismaPage =
    getScreenIsActiveBySlug("RECEBA_NA_MESA") ?? showPrismaPage;

  const buttonToContinueText =
    getTranslateName(t, "buttons.toContinue") ||
    getThemeSettings?.text?.button_continue_be_called_order ||
    "Continuar";

  const buttonBackText =
    getTranslateName(t, "buttons.back") ||
    getThemeSettings?.text?.button_cancel_be_called_order ||
    "Voltar";

  const cartItems = useSelector((state) => state.cart.items);

  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const [statusWindow, setStatusWindow] = useState(false);
  const keyboard = useRef();

  const onChange = (input) => {
    if (input.length < 16) {
      setInput(input.trim());
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    /**
         * If you want to handle the shift and caps lock buttons

        if (button === "{shift}" || button === "{lock}") handleShift();
        */
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  const sendProcessesOperation = async () => {
    const response = await processesOperation(cartItems);

    if (!response.Result) {
      throw "Error in operation processesOperation...";
    }

    return response;
  };

  const byPass = async () => {
    try {
      const response = await sendProcessesOperation();

      localStorage.setItem("NsuReshop", JSON.stringify(response.Nsu));
      localStorage.setItem("IdTransacao", JSON.stringify(response.IdTransacao));

      setStatusWindow(true);

      window.localStorage.setItem("paymentTransition", "FIDELIDADE");

      viewDispatch("step.paymentTransition");

      return;
    } catch (err) {
      console.error(err);
    }
  };

  const backStep = () => {
    if (isShowTellInfo) {
      return viewDispatch("step.tellIdentification");
    }
    if (isShowCPFPage) {
      return localStorage.getItem("styleAA") === "DRIVE"
        ? viewDispatch("step.carIdentification")
        : viewDispatch("step.cpf");
    } else {
      if (isShowCarIdentification) {
        return viewDispatch("step.carIdentification");
      } else {
        if (isShowPrismaPage) {
          return viewDispatch("step.prisma");
        } else {
          return viewDispatch("step.orderSummary");
        }
      }
    }
  };

  const next = () => {
    window.localStorage.setItem("name", input);

    if (everyItensReshop) {
      return byPass();
    }

    return viewDispatch("step.payment");
  };

  const everyItensReshop = cartItems.every((i) => i.currencyPoints === true);

  useEffect(() => {
    if (localStorage.getItem("nome")) {
      keyboard.current.setInput(localStorage.getItem("nome"));
      setInput(localStorage.getItem("nome"));
    }
    window.localStorage.setItem("inRouteToPayment", "true");
  }, []);

  useEffect(() => {
    const { clientValue, reshopValue } = reshopCalculateValues(cartItems);

    localStorage.setItem("clientValue", JSON.stringify(clientValue));
    localStorage.setItem("reshopValue", JSON.stringify(reshopValue));
  }, [cartItems]);

  return (
    <>
      <Content
        bgColor="background-primary hasIMG"
        hasImg={isLayout("BOBS")}
        srcImg={logoBobs}
        title={yourNameBeCalledOrder}
        subtitle={false}
        contentChildren={true}
        className="full"
      >
        {statusWindow ? (
          <div id="loader"></div>
        ) : (
          <>
            <div className="App keyboard bobsIdentification">
              <input
                value={input}
                maxLength={15}
                placeholder={beCalledOrderText}
                onChange={onChangeInput}
                className="input"
              />

              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                layoutName={layout}
                onChange={onChange}
                theme={"hg-theme-default hg-layout-default habibs"}
                onKeyPress={onKeyPress}
                disableRowButtonContainers={true}
                layout={{
                  default: [
                    "Q W E R T Y U I O P",
                    "A S D F G H J K L",
                    "Z X C V B N M {bksp}",
                    "{space}",
                  ],
                }}
              />
            </div>

            <footer className="footer">
              <Button
                text={buttonBackText}
                buttonUIType="outline-primary"
                buttonUI="normal"
                handleClick={() => backStep()}
              />

              <Button
                className="buttonAddCar"
                disabled={!input.length}
                text={buttonToContinueText}
                buttonUIType="primary"
                buttonUI="normal"
                handleClick={() => next()}
              />
            </footer>
          </>
        )}
      </Content>
    </>
  );
};

export default OrderIdentificationContainer;
