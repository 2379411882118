import React, { useEffect, useState } from "react";
import ArmouredCar from "../../components/armoured-car";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useLocation, useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import {
  armoredCarWithdrawListService,
  armoredCarWithdrawCancelService,
} from "../../../services/cashdeskService";
import { getTerminalID, getRetailerId } from "../../../aaone/configuration";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import { useAuth } from "../../hook/authHook";
import { getCashDeskArmoredCarPrinter } from "../../../services/printerService";
import { printerActionService } from "../../../services/actions/printerActionService";
import { printDefault } from "../../helpers/printer/printDefault";

const ArmouredCarContainer = (props) => {
  const cashdesk = useCashdesk();
  const { setVisibleSidebarCart } = useWrapperRouters();
  const navigate = useNavigate();
  const location = useLocation();
  const [controlNumber, setControlNumber] = useState(1);
  const [listArmouredCars, setLlistArmouredCars] = useState([]);
  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();
  const auth = useAuth();

  useEffect(() => {
    if (cashdesk && !cashdesk.isCashdeskOnCurrentDay) {
      showAlert({
        message:
          "Para realizar um novo carro forte por favor realize o encerramento do dia",
      });

      // Ele não transferia a página, tive que colocar um timeout
      setTimeout(() => {
        navigate(PagesEnum.HOME);
      }, 300);

      return;
    }

    setVisibleSidebarCart(false);
  }, []);

  useEffect(() => {
    handleStart();

    return () => {
      setLlistArmouredCars([]);
      setControlNumber(0);
    };
  }, []);

  const handleStart = (armoredCarId) => {
    return armoredCarWithdrawListService({
      posId: getTerminalID().toString(),
      retailerId: getRetailerId(),
      armoredCarID: armoredCarId,
    }).then((res) => {
      if (!armoredCarId) {
        const lastControlNumber = res?.[res.length - 1]?.referenceSequence ?? 0;
        setControlNumber(lastControlNumber + 1);
        setLlistArmouredCars(res);
      }
      return res;
    });
  };

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  const handleSubmit = (data) => {
    try {
      const authManager = location?.state?.auth ?? null;

      let manager = authManager ? authManager.user : auth.userAuthData;

      cashdesk
        .armoredCar({
          ...data,
          adminName: manager.login,
          responsableId: manager.nuuidOne,
        })
        .then((res) => {
          if (res) {
            showAlert({
              message: "Carro-Forte realizado com sucesso!",
            });

            handleReprint(res.movements[res.movements.length - 1].id);

            handleClose();
          }
        })
        .catch((err) => {
          console.error(err);
          setShowLoading(false);
        });
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  };

  const handleCancel = (armoredCarId) => {
    armoredCarWithdrawCancelService({
      retailerId: getRetailerId(),
      armoredCarId: armoredCarId,
    })
      .then((res) => {
        handleStart();
        showAlert({
          message: "Cancelamento realizado com sucesso!",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleReprint = (armoredCarId) => {
    handleStart(armoredCarId).then((res) =>
      ["1. Via Loja", "2. Via Coletor"].forEach((via) =>
        getCashDeskArmoredCarPrinter({
          via,
          withdraw: res,
        }).then((data) => {
          if (window.desktopApp) {
            printerActionService({
              body: data,
            });
          } else if (window.Android) {
            console.log(`printDefault | {}`);
            printDefault(data);
          }
        })
      )
    );
  };

  // Se o caixa estiver no dia anterior
  if (cashdesk && !cashdesk.isCashdeskOnCurrentDay) {
    return <></>;
  }

  return (
    <KeyboardProvider>
      <ArmouredCar
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        listArmouredCars={listArmouredCars}
        controlNumber={controlNumber}
        handleCancel={handleCancel}
        handleReprint={handleReprint}
      />
    </KeyboardProvider>
  );
};

export default ArmouredCarContainer;
