import { RESHOP_AUTH_DATA_KEY_NAME } from "../constants/keyNamesSessionStorage";

export const reshopAuthDefaultEmpty = { username: "", password: "" };

export const getReshopAuthDataUnico = () => {
  try {
    const reshopAuthData = JSON.parse(
      sessionStorage.getItem(RESHOP_AUTH_DATA_KEY_NAME) ??
        JSON.stringify(reshopAuthDefaultEmpty)
    );

    return reshopAuthData;
  } catch (error) {
    return reshopAuthDefaultEmpty;
  }
};
