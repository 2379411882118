import React from "react";

export const AAMenu = ({ size = "1rem", ...props }) => {
  const serializeSize = typeof size === "number" ? `${size}px` : size;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
      width={serializeSize}
      height={serializeSize}
    >
      <path
        className="cls-1"
        d="M17.5 4h-9a.5.5 0 000 1H17v1H3V5h1.5a.5.5 0 000-1h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM17.5 9h-15a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-.5 2H3v-1h14zM17.5 14h-15a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h9a.5.5 0 000-1H3v-1h14v1h-2.5a.5.5 0 000 1h3a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"
      />
    </svg>
  );
};
