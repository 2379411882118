import OrientedSaleUseCase from "./OrientedSaleUseCase";

export default class CompositionSaleUseCase {
  #rawStepComposition;
  #stepComposition;
  #nameStepComposition;
  #CompositionSale;

  constructor(rawStepComposition) {
    this.#rawStepComposition = rawStepComposition;
    this.#stepComposition = {};
    this.#nameStepComposition = `Personalize seu ${rawStepComposition.classification}`;
    this.#CompositionSale = null;

    this.initialization();
  }

  initialization() {
    this.adjustStepsComposition();
  }

  getStepComposition() {
    return {...this.#stepComposition};
  }

  adjustStepsComposition() {
    if (!!this.#rawStepComposition?.compositions?.length) {
      const OrientedSale = new OrientedSaleUseCase(this.#rawStepComposition.compositions, true);

      this.#stepComposition = {
        description: this.#nameStepComposition,
        quantity: 0,
        sequence: 1,
        current:  true,
        selected: null,
        required: false,
        composition: true,
        products: OrientedSale.getCurrentProducts(),
        duplicated: {
          status: false,
          originalQuantity: 0,
        },
      };
    }
  }
}
