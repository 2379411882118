import { MACHINE_PRINTER_INFO_KEY_NAME } from "../../constants/keyNamesSessionStorage";
import { escUndefinedInStringHelper } from "../escUndefinedInStringHelper";
import { htmlToTextHelper } from "../htmlToTextHelper";

export const printDefault = (content) => {
  if (window.desktopApp) {
    console.error("Não suporta no desktopApp");
    return;
  }

  try {
    const getPrinterInfo = JSON.parse(
      localStorage.getItem(MACHINE_PRINTER_INFO_KEY_NAME) || "{}"
    );

    const contentToPrint = htmlToTextHelper(content);

    console.log("Conteúdo enviado para impressão abaixo");
    console.log(JSON.stringify(contentToPrint));
    console.log("Executando função de impressão mobile");

    if (getPrinterInfo?.ip?.length > 0) {
      window.Android.printerComandas(
        contentToPrint,
        escUndefinedInStringHelper(getPrinterInfo.ip),
        escUndefinedInStringHelper(getPrinterInfo.port)
      );
    } else {
      window.Android.printerComandasTotem(contentToPrint);
    }
  } catch (error) {
    console.log(
      `Ocorreu um erro ao executar impressão: ${JSON.stringify(
        error,
        Object.getOwnPropertyNames(error)
      )}`
    );
  }
};
