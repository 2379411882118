import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
  } from "react";
  import { cpfMask } from "../../utils/masks/cpf";
  import { cpfClearMask } from "../../utils/cpfClearMask";
  import { validaCPF } from "../../utils/checkCPF";
  import {
    consultCampaign,
    consultPromoCode,
    consultPoints,
  } from "../../../../services/pointsService";
import { PDV_COD_LOJA_KEY_NAME } from "../../../../pdv-one/constants/keyNamesSessionStorage";

  const alfaNumericKeypad = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 0,
    "Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P",
  ]
  const alfaNumericKeypadpt2 = [
    "A", "S", "D", "F", "G", "H", "J", "K", "L",
  ]

  const alfaNumericKeypadpt3 = [
    "Z", "X", "C", "V", "B", "N", "M",
  ]

  const alfaNumericKeypadpt4 = [
    "⌫",
  ]

  const KeyboardAlfa = forwardRef(
    (
      {
        handleClick,
        textPlaceholder,
        textLabel,
        isFidelity,
        classN,
        typeInput,
      },
      ref
    ) => {
      let [stateCPF, setStateCPF] = useState("hidden");
      let [textStateCPF, textSetStateCPF] = useState("");
      const [promoCodeReshop,setPromoCodeReshop] = useState(0);
      let [inputValue, setInputValue] = useState("");

      useImperativeHandle(ref, () => ({
        handleDoneOnClickCupom: handleFunction,
      }));

      const handleChange = (event) => {
        if (typeInput !== "numeric") {
          setInputValue((inputValue = cpfMask(inputValue + event.target.value)));
        }
        if (event.target.value.length > 1) {
          setInputValue("");
          textSetStateCPF("");
          setStateCPF("hidden");
          return;
        }

        if (event.target.value === "⌫") {
          return setInputValue(inputValue.slice(0, -1));
        }

        if (typeInput === "numeric" && localStorage.getItem("prismakey")) {
          if (inputValue.length < 3) {
            return setInputValue((inputValue = inputValue + event.target.value));
          }
        }

        if (typeInput === "numeric" && !localStorage.getItem("prismakey")) {
          return setInputValue((inputValue = inputValue + event.target.value));
        }
      };

      const handleFunction = async () => {
        const promoCodeReshop = await consultPromoCode(localStorage.getItem("cpf"), inputValue);
        setPromoCodeReshop(promoCodeReshop);
        if (inputValue.length < 11 && typeInput !== "numeric") {
          textSetStateCPF("");
          return setStateCPF("visible");
        }

        if (typeInput !== "numeric") {
          let valid = validaCPF(cpfClearMask(inputValue));

          if (!valid) {
            textSetStateCPF("");
            return setStateCPF("visible");
          }

          window.localStorage.setItem("cpfDocument", cpfClearMask(inputValue));
        }

        if (isFidelity) {
          window.localStorage.setItem("cpf", cpfClearMask(inputValue));
          return checkUser();
        }

        if (typeInput === "numeric" && inputValue.length > 0) {
          setInputValue("");
          return handleClick(inputValue,promoCodeReshop);
        }
        return handleClick();
      };

      const clearClientData = () => {
        window.localStorage.removeItem("cpf");
        // eslint-disable-next-line no-throw-literal
        throw "Cliente não cadastrado...";
      };

      const getCampaigns = async () => {
        const response = await consultCampaign(cpfClearMask(inputValue));

        if (!response) {
          clearClientData();
        }

        return response;
      };

      const getPoints = async () => {
        const response = await consultPoints(
          cpfClearMask(inputValue),
          sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME)
        );

        if (!response) {
          clearClientData();
        }

        return response;
      };

      function showInputCpf() {
        let senha = document.getElementById("senha");
        let icon = document.getElementById("icon");
        if (senha.type === "password") {
          icon.className = "fas fa-eye lnr-eye";
          senha.type = "text";
        } else {
          senha.type = "password";
          icon.className = "fas fa-eye-slash lnr-eyeclose";
        }
      }

      const checkUser = async () => {
        let valid = true;

        try {
          const response = await getPoints();
          const responseCampaign = await getCampaigns();
          window.localStorage.setItem("cpf", cpfClearMask(inputValue));
          window.localStorage.setItem("saldoAtual", response.SaldoAtual);
          console.log(
            "responseCampaign.AvailableCampaigns",
            responseCampaign.AvailableCampaigns
          );
          window.localStorage.setItem(
            "reshopCampanhas",
            JSON.stringify(responseCampaign.AvailableCampaigns)
          );
          handleClick();
        } catch (err) {
          console.error(err);
          valid = false;
          textSetStateCPF(err);
          setStateCPF("visible");
        }

        return valid;
      };

      const keys = alfaNumericKeypad.map((key) => {
        return (
          <button
            className={`key-${key} keyAlfabutton`}
            key={key}
            value={key}
            onClick={handleChange}
          >
            {key === "⌫" ? "⌫" : key}
          </button>
        );
      });

      const keys2 = alfaNumericKeypadpt2.map((key2) => {
        return (
          <button
            className={`key-${key2} keyAlfabutton`}
            key={key2}
            value={key2}
            onClick={handleChange}
          >
            {key2 === "⌫" ? "⌫" : key2}
          </button>
        );
      });

      const keys3 = alfaNumericKeypadpt3.map((key3) => {
        return (
          <button
            className={`key-${key3} keyAlfabutton`}
            key={key3}
            value={key3}
            onClick={handleChange}
          >
            {key3 === "⌫" ? "⌫" : key3}
          </button>
        );
      });

      const keys4 = alfaNumericKeypadpt4.map((key4) => {
        return (
          <button
            className={`key-${key4} keyAlfabutton deleteKeys`}
            key={key4}
            value={key4}
            onClick={handleChange}
          >
            {key4 === "⌫" ? "⌫" : key4}
          </button>
        );
      });

      useEffect(() => {
        if (localStorage.getItem("cpf") && typeInput !== "numeric") {
          setInputValue(cpfMask(localStorage.getItem("cpf")));
        }
      }, [typeInput, promoCodeReshop]);

      return (
        <section className={`keyboard keyboardAlfaNumeric ${classN}`}>
          {textLabel && <p className="label">{textLabel}</p>}
          <div className="divInput">
            <input
              className="input inputAlfaNumeric"
              value={inputValue}
              readOnly
              pattern="[0-9]{11}"
              id="senha"
              type={textPlaceholder || "password"}
            />
            {!textPlaceholder ? (
              <i
                className="fas fa-eye-slash lnr-eyeclose"
                id="icon"
                onClick={showInputCpf}
              ></i>
            ) : (
              <div />
            )}
          </div>
          <section className="numericKeypad numericKeypadAlfa">
            <div>
              {keys}
            </div>
            <div className="keys2">
              {keys2}
            </div>
            <div className="keys3">
              {keys3}
              {keys4}
            </div>
          </section>
        </section>
      );
    }
  );

  export default React.memo(KeyboardAlfa);
