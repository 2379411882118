import React, {useState, useEffect} from "react";
import { sendSaleOrder } from "../../../services/paymentService";
import {Content, ModalPayments} from "../../shared/components/modules";
import { useViewDispatch } from "../../../context";

import {
cancelOrder,
createOrder,
statusOrder,
confirmOrder,
setItemsReshopStorage
} from "../../../services/pedeProntoService";
import { useSelector } from "react-redux";
import PedePronto from "../../shared/components/pede-pronto";
import { transactionData } from "../../shared/utils/transactionNumber";
import { consultAllCampaignByPaymentType } from "../../../services/pointsService";
import {cancelOperation, confirmsOperation, processesOperation} from "../../../services/verifyRedeemValueService";
import {ScrollContainer} from "../modules";
import { PDV_COD_LOJA_KEY_NAME, TOKEN_RESHOP_AUTH_KEY_NAME } from "../../../pdv-one/constants/keyNamesSessionStorage";

const PedeProntoContainer = ({
  modalTimer
}) => {
let timerGetOrder = undefined;
const importantStatuses = {
  paidStatus: "PAYMENT_CONFIRMED",
  cancelledStatus: "CANCELLED",
  confirmedStatus: "CONFIRMED",
  typeCpfSubsidy: "CPF_USED_SUBSIDY",
};
const slugCategoryCampaignPedePronto = localStorage.getItem("categoriaPedePronto");
const cpfDocument = "";
const cartItems = useSelector((state) => state.cart.items);
const viewDispatch = useViewDispatch();
const [discounts, setDiscounts] = useState([]);
const [treatedItems, setTreatedItems] = useState(cartItems);
const [cpf, setCpf] = useState(cpfDocument);
const [hasCpf, setHasCpf] = useState(false);
const [qrCode, setQrCode] = useState("");
const [orderResponse, setOrderResponse] = useState({});
const [subtitle, setSubtitle] = useState("");
const [paymentStatus, setPaymentStatus] = useState("RECEIVED");
const [showModal, setShowModal] = useState(false);
const [messageModal, setMessageModal] = useState("");
const [startTimer, setStartTimer] = useState(false);
const [showButtonBack, setShowButtonBack] = useState(true);
const [canBackToPaymentScreen, setCanBackToPaymentScreen] = useState(true);

/**
 * @param {String} statusPayment
 * @example The functions is for handle when client pay order
 * @returns {boolean}
 */
const handlePaidOrder = async (statusPayment) => {
  if (statusPayment !== importantStatuses.paidStatus) return false;
  setStartTimer(false);

  const clientValue = JSON.parse(localStorage.getItem("clientValue"));
  const sendClientValue = parseFloat(clientValue.toFixed(2));
  let discount = 0;
  treatedItems.forEach((item) => {
    discount += item.promotion.discount * item.qtd;
  });

  localStorage.setItem("descontoReshop", discount);

  const firstTransactionsIds = getFirstIdsPayments();

  const payments = [
    {
      authorizationCodeRede: firstTransactionsIds.paymentId,
      authorizationCodeOnline: orderResponse.id,
      authorizationCode: '0',
      controlCode: firstTransactionsIds.tid,
      transactionType: "PEDE PRONTO",
      creationDate: transactionData(new Date(), "dd/MM/yyyy hh:mm:ss"),
      description: "test",
      amount: sendClientValue - discount,
      reshop: false,
      paymentCode: 20,
    },
  ];
  window.localStorage.setItem("payments", JSON.stringify(payments));

  try {
    setPaymentStatus("ISSUING_INVOTE_FISCAL");
    sendSaleOrder(cartItems)
      .then(async (response) => {
        if (response.step !== undefined) {
          setPaymentStatus("CONFIRMING_OPERATION_PEDE_PROTO");
          const confirmOperactionReshop = await confirmsOperation(JSON.parse(localStorage.getItem("NsuReshop")));
          if (confirmOperactionReshop.Result) {
            const confirmPedePronto = await confirmOrder(orderResponse.id);
            if (confirmPedePronto?.eventType === importantStatuses.confirmedStatus)
              finalizingTransaction(response);
            else
              backStep();
          }
        } else backStep()
      })
      .catch((e) => backStep())
  } catch(e) {}
}

const getFirstIdsPayments = () => {
  const allTids = new Set();
  allTids.add(0);

  const allPaymentsId = new Set();
  allPaymentsId.add(0);


  if (orderResponse.transaction !== undefined) {
    orderResponse.transaction.transactionReturns
      .forEach((transaction) => {
        if (allTids.has(0)) allTids.delete(0);
        allTids.add(transaction.transactionInfos.tid);

        if (allPaymentsId.has(0)) allPaymentsId.delete(0);
        allPaymentsId.add(transaction.transactionInfos.paymentId);
      });
  }

  return {
    paymentId: allPaymentsId.values().next().value,
    tid: allTids.values().next().value,
  };
};
/**
 * @params {String} document
 * @example If the client change your CPF, the function is seeing this
 * @example The function change the texts that show for the user referent for the use QR Code
 * @returns {any}
 */
const handleCpf = async (document = '') => {
  setSubtitle("");
  let clientDocument = localStorage.getItem("cpf") || document;

  setCpf(clientDocument);
  let totalPriceSale = 0;
  const cartItemsWithPrice = cartItems.filter((item) => item.price > 0);
  cartItemsWithPrice.forEach(item => totalPriceSale += item.price * item.qtd);

  if (clientDocument) {
    setHasCpf(true);
    const requestCampaign = {
      document: clientDocument,
      items: cartItemsWithPrice,
      storeCode: sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME),
      payments: [{
        BinCartao: "",
        NumeroPagamento: null,
        tipo: "20",
        valor: totalPriceSale
      }]
    };

    setSubtitle("Buscando promoção no Reshop....");
    consultAllCampaignByPaymentType(requestCampaign)
      .catch(() => backStep())
      .then(async (rawCampaign) => {
        try {
          localStorage.setItem("itensReshopForDiscount", JSON.stringify(rawCampaign.CampanhasAtivadas));
          processesOperation(cartItemsWithPrice)
            .then((response) => setItemsReshopStorage(response));

          const itemsCampaigns = [];
          const activeCampaigns = [];
          rawCampaign.CampanhasAtivadas.forEach((campaign) => {
            if (campaign.CategoriaCampanha !== undefined) {
              const receivedSlug = campaign.CategoriaCampanha
                .replace(" ", "_")
                .toUpperCase();

              if (slugCategoryCampaignPedePronto === receivedSlug) {
                campaign.Itens.forEach((item) => {
                  itemsCampaigns.push(item);
                });
              } else {
                campaign.Itens.forEach((item) => {
                  activeCampaigns.push(item);
                });
              }
            }
          });

          setPaymentStatus("CREATING_ORDER");
          setSubtitle("Criando QR Code do pede pronto...");
          const responseCreateOrder = await createOrder(
            treatedItems,
            clientDocument,
            itemsCampaigns,
            activeCampaigns
          );

          setDiscounts(itemsCampaigns);
          if (responseCreateOrder.status === 200 && responseCreateOrder?.id !== undefined) {
            setOrderResponse({...responseCreateOrder});
            setQrCode(`data:image/png;base64,${responseCreateOrder.qrcode}`);
            setSubtitle("Aponte a câmera do seu aplicativo do Pede Pronto para o Qr Code e realize seu pagamento.");
          } else {
            let messageModalBackStep = "Houve um problema ao criar o QR Code. Você será redirecionado para as formas de pagamento.";
            if (
              responseCreateOrder &&
              responseCreateOrder.type !== undefined &&
              responseCreateOrder.type === importantStatuses.typeCpfSubsidy
            ) {
              messageModalBackStep = "O desconto do pedido não foi concedido pela Pede Pronto pois o CPF já utilizou subsídio no dia de hoje.";
            }
            backStep(messageModalBackStep);
          }
        } catch (e) {
          console.error(e.message);
        }
      });
  } else {
    setHasCpf(false);
  }
}

useEffect(() => {
  const tokenAccessReshop = window.sessionStorage.getItem(TOKEN_RESHOP_AUTH_KEY_NAME);
  if (!!tokenAccessReshop) {
    if (localStorage.getItem("cpf") || localStorage.getItem("cpfDocument")){
      setCpf(localStorage.getItem("cpf") || localStorage.getItem("cpfDocument"));

      if(localStorage.getItem("cpf"))
        handleCpf(localStorage.getItem("cpf"));
    } else {
      setSubtitle("Você ainda não digitou seu CPF. Por favor digite seu CPF para consutarmos seus dados.");
    }
  } else {
    backStep("Tivemos problemas ao conectar com Reshop. Por gentileza, Escolha outra forma de pagamento.");
  }
}, []);

const backStep = async (message = "Houve um problema no seu pagamento. Você será redirecionado.") => {
  setStartTimer(false);
  localStorage.setItem("returnPayment", 1);
  localStorage.removeItem("paymentTypeForReshop");
  localStorage.removeItem("descontoReshop");
  localStorage.removeItem("paymentResponseStone");

  if (orderResponse.id)
    await cancelOrder(orderResponse.id);

  if (JSON.parse(localStorage.getItem("NsuReshop")))
    await cancelOperation(JSON.parse(localStorage.getItem("NsuReshop")));

  clearTimeout(timerGetOrder);

  setMessageModal(message);
  setShowModal(true);

  removeCampaignsPedeProntoReshop();

  if (canBackToPaymentScreen) {
    const timer = setTimeout(() => {
      setShowModal(false);
      modalTimer(true);
      localStorage.removeItem("paymentTransition");
      viewDispatch("step.payment");
      clearTimeout(timer);
    }, 3000);
  }
  setCanBackToPaymentScreen(false);
};

const removeCampaignsPedeProntoReshop = () => {
  const campaigns = localStorage.getItem("itensReshopForDiscount");
  const campaignsWithoutPedePronto = [];

  if (campaigns) {
    JSON.parse(campaigns).forEach((campaign) => {
      const receivedSlug = campaign.CategoriaCampanha
        .replace(" ", "_")
        .toUpperCase();

      if (slugCategoryCampaignPedePronto !== receivedSlug)
        campaignsWithoutPedePronto.push(campaign);
    })
  }

  localStorage.setItem("itensReshopForDiscount", JSON.stringify(campaignsWithoutPedePronto));
};

useEffect(() => {
  const treatedDiscountedItems = [];

  if (!!discounts) {
    cartItems.forEach((item) => {
      let discountPrice = 0;
      const foundItem =  discounts.find((itemDiscount) => itemDiscount.CodigoProduto === item.code);
      if (foundItem)
        discountPrice = foundItem.ValorDescontoItem;

      treatedDiscountedItems.push({
        ...item,
        promotion: {
          discount: discountPrice
        }
      })
    });

    setTreatedItems(treatedDiscountedItems);
  } else {
    setTreatedItems(cartItems);
  }
}, [discounts]);

useEffect( () => {
  if (timerGetOrder === undefined) {
    timerGetOrder = setTimeout(async () => {
      if (orderResponse.id) {
        const consultOrder = await statusOrder(orderResponse.id);

        if (consultOrder.status === 200)
          setOrderResponse({...consultOrder});
        else {
          clearTimeout(timerGetOrder);
          backStep("Não conseguimos consultar o status do seu pedido. Você será redirecionado para tela de formas de pagamento.");
        }

        modalTimer(false);
        modalTimer(true);
      }
    }, 5000);
  }

  const statusContinuePolling = [
    "RECEIVE",
    "RECEIVED",
    "PAYMENT_REQUESTED",
    "PAYMENT_FAILURE"
  ];

  if (orderResponse.eventType && !statusContinuePolling.includes(orderResponse.eventType)) {
    clearTimeout(timerGetOrder);
    if (orderResponse.eventType === importantStatuses.paidStatus) {
      setShowButtonBack(false);
      handlePaidOrder(orderResponse.eventType);
    }
    else if(orderResponse.eventType === importantStatuses.cancelledStatus)
      backStep("Seu pagamento foi cancelado pelo Pede Pronto. Você será redirecionado para as formas de pagamento.");
  }
}, [orderResponse]);

  useEffect(() => {
    setStartTimer(true);
  }, [qrCode]);

  const finalizingTransaction = (response) => {
    window.desktopApp.publish("machine.devices.tef.confirm", {});
    setTimeout(function () {
      window.desktopApp.publish("machine.devices.tef.pbm.finishTefTransactions", {});
    }, 500);

    window.localStorage.setItem("saleControl", response.payload.orderSale.saleControl);
    window.localStorage.removeItem("payments");

    if (window.desktopApp && !response.payload.orderSale.errorWhenAuthorizing) {
      window.desktopApp.publish("machine.devices.printerpdf", {
        fileName: `${response.payload.orderSale.pathDocFiscal}`,
      });
    } else if (window.desktopApp && response.payload.orderSale.saleControl) {
      const body = `<div style="text-align: center">
                  <h3>SENHA: ${response.payload.orderSale.saleControl}</h3>
                  <div>A Venda foi registrada, mas ocorreu</div>
                  <div> um problema para emitir a DANFE, </div>
                  <div>guarde este comprovante e procure um caixa</div>
                </div><br/><br/>`;

      window.desktopApp.publish("machine.devices.printer", {body: body});
    }

    setStartTimer(false);
    viewDispatch("step.cupom");
  }

return (
  <>
    <Content
      bgColor="background-primary"
      title="PEDE PRONTO"
      subtitle={'DIGITE SEU CPF E ABRA SEU APLICATIVO DO PEDE PRONTO.'}
      contentChildren={true}
      className="full"
    >
      {showModal && (
        <ScrollContainer>
          <div className="blackout" onClick={() => setShowModal(false)}>
            <ModalPayments
              mTitle="Atenção"
              mSubtitle={messageModal}
              mTextBtnSecondary={false}
              mTextBtnPrimary={false}
              mBtnPrimarClassName="primary"
              mBtnSecondaryClassName="outline-primary"
            />
          </div>
        </ScrollContainer>
      )}
      <PedePronto
        cpf={cpf}
        hasCpf={hasCpf}
        handleCpf={handleCpf}
        backStep={backStep}
        qrCode={qrCode}
        orderResponse={orderResponse}
        paymentStatus={paymentStatus}
        setPaymentStatus={setPaymentStatus}
        subtitle={subtitle}
        chosenSeconds={160}
        action={{
          action: backStep,
          message: "Você está voltando para tela de pagamento por inatividade."
        }}
        start={startTimer}
        showButtonBack={showButtonBack}
      />
    </Content>
  </>
);
};

export default React.memo(PedeProntoContainer);
