import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Modal = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.25);

  .font__warn {
    font-size: 1.5rem !important;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    padding: 1rem;
  `)}
`;

const CardWrapper = styled.div`
  box-sizing: border-box;
  height: 420px;
  width: 495px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  position: relative;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;

  .spacing {
    padding-right: 40px;
    padding-left: 40px;
  }

  &.loading:before {
    content: "Validando...";
    font-weight: 100;
    letter-spacing: 1px;
    display: flex;
    position: absolute;
    left: 0;
    top: 20%;
    bottom: 0;
    background: #fff;
    width: 100%;
    z-index: 1;
    align-items: center;
    justify-content: center;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    transform: translate(-50%, -60%);
    width: 100%;

    .spacing {
      font-size: 1.5rem;
    }
  `)}
`;

const CardHeader = styled.h1`
  font-size: 28px;
  font-family: Dosis;
  font-weight: 600;
  color: #414141;
  opacity: 0.8;
  border-bottom: 1px solid hsla(0, 0%, 25%, 0.46);
  padding-top: 25px;
  padding-bottom: 25px;
`;

const CardBody = styled.div`
  min-height: 250px;
  padding: 25px 0;

  input[type="password"] {
    font-size: 2em;
    line-height: 46px;
    padding: 0 15px;
  }

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    min-height: auto;
    padding: 10px;
  }

  .form-itens + .form-itens {
    margin-top: 15px;
    position: relative;
  }
`;

const CardFormRow = styled.div`
  line-height: 2;

  .input__wrapper {
    position: relative;
  }

  input {
    width: 100%;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
  }

  .button__reset {
    position: absolute;
    right: 10px;
    margin-top: 5px;
    border: none;
    color: #949393;
    padding: 8px !important;
    outline: none;
    top: 6px;
    border-radius: 4px !important;
    font-weight: bold !important;
  }

  .error {
    display: none;
    color: #fe2e2e;
    position: absolute;

    &.displayError {
      display: block;
    }
  }
`;

const CardFooter = styled.div`
  .btn {
    height: 50px;
    width: 100%;

    // @media only screen and (${(props) =>
      props.theme.minimumResolution.media}) {
    //   height: 40px;
    //   margin-bottom: 10px;
    // }

    &.btn-link {
      color: var(--highlight-color);
      line-height: 40px;
      background-color: transparent;
    }

    &.btn-primary {
      border-radius: 4px;
      box-shadow: 0px 3px 6px #00000033;
      background: #411e5a linear-gradient(90deg, #411e5a 0%, #411e5a 100%);
    }

    &.btn-link,
    &.has-btn-link {
      width: 50%;
    }
  }
`;

export { CardWrapper, CardHeader, CardBody, CardFormRow, CardFooter };
