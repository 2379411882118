import styled from "styled-components";

export const FidelityProductsContent = styled.div`
  .breadcrumb {
    font-size: 1.3rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-product {
    position: relative;
    .product-actions {
      &.hide {
        display: none !important;
      }

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 1rem;

      .remove-product {
        padding: 16px;
        color: ${(props) => props.theme.colors.error};
      }
      .quantity {
        padding: 16px;
        color: ${(props) => props.theme.colors.primary};
        font-weight: bold;
        font-size: 1.2rem !important;
      }
    }
  }

  .carousel__optionalCampaign {
    position: relative;
    width: 100%;

    .slick-slider {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0;
      width: 100%;
      height: 100%;

      grid-gap: 16px;
      gap: 16px;

      .slick-list {
        width: 100%;
        height: 100%;
      }

      .slick-slide > div {
        margin-top: 1rem;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;

    button {
      margin: 8px;
    }
  }
`;
