import React, { useState, useEffect } from "react";
import {
  MdAdd,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import { AccordionContainer, Header, TabNavContainer } from "./styles";
import { ContentCart } from "../styles";
import classNames from "classnames";
import { currency } from "../../../../aaone/shared/utils/currency";
import Accordion from "../../accordion";
import { ItemInCart } from "../ItemCart";
import { useCart } from "../../../hook/cartHook";
import { PagesEnum } from "../../../constants/pagesEnum";
import { saleInProgressService } from "../../../../services/saleInProgress";
import { getStoreId, getTerminalID } from "../../../../aaone/configuration";
import { useCashdesk } from "../../../hook/cashdeskHook";
import { useLocation, useNavigate } from "react-router-dom";
import { useDialog } from "../../../hook/dialogHook";
import { DATA_USER_AUTH_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import { getSystemParamsHelper } from "../../../helpers/getSystemParamsHelper";
import ModalWaiterCommission from "../../modals/ModalWaiterCommission";
import { useLoading } from "../../../hook/loadingHook";

export const TableCart = () => {
  const [currentTabNav, _setCurrentTabNav] = useState("cart");
  const [selectedWaiter, setSelectedWaiter] = useState(null);
  const [isOpenModalWaiter, setIsOpenModalWaiter] = useState(false);

  const cashdesk = useCashdesk();
  const navigate = useNavigate();
  const location = useLocation();
  const { setOpenDialog, showAlert } = useDialog();

  const tableSelectedData = JSON.parse(sessionStorage.getItem("tableSelected"));
  const tableNumber = tableSelectedData?.tableNumber || tableSelectedData;
  const operator = JSON.parse(sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME));
  const systemParams = getSystemParamsHelper();

  const onCashierReductionOpenWithdrawingScreen =
    systemParams?.onCashierReductionOpenWithdrawingScreen || false;

  const positionDataJSON = sessionStorage.getItem("positionData") ?? "{}";
  const positionData = JSON.parse(positionDataJSON);
  const positions = positionData?.positions ?? [];

  const isCurrentTabNyName = (tabName) => currentTabNav === tabName;

  const {
    clearCartTable,
    itemsInCart,
    isReadyToPay,
    Sale,
    selectedPosition,
    setSelectedPosition,
    setIsOpenModalQuantity,
    totalPriceWDiscount,
  } = useCart();
  const { setShowLoading } = useLoading();

  const goToStep = (product, sequence) => Sale.goToStep(product, sequence);

  useEffect(() => {
    const quantityCustomersSelectedInHome =
      sessionStorage.getItem("customersSelected");

    const [currentPosition = null] = positions.filter((position) => position.status !== "CLOSING");
    const initialSelectedPosition =
      currentPosition ? currentPosition?.position : parseInt(quantityCustomersSelectedInHome) > 0 ? 1 : -1;
    setSelectedPosition(initialSelectedPosition);
  }, []);

  const getCurrentStep = () => Sale.getCurrentSaleStep();

  const handleWaiterSelection = (waiter) => {
    setSelectedWaiter(waiter);
  };

  const handleModalWaiterClose = () => {
    setIsOpenModalWaiter(false);
  };

  const handlePositionChange = (newPosition) => {
    const customersNumber =
      JSON.parse(sessionStorage.getItem("customersSelected")) ?? 1;

    if (newPosition > 0 && newPosition <= Number(customersNumber)) {
      const newPositionStatus = positions[newPosition - 1]?.status;

      if (newPositionStatus !== "CLOSING") {
        setSelectedPosition(newPosition);
      } else {
        // Se a nova posição é "CLOSING", encontre a próxima posição válida
        let nextValidPosition = newPosition;
        const positionsFiltered =
          positions.filter((position) => position.status !== "CLOSING") ?? [];

        // Ajusta nextValidPosition com base na direção
        if (newPosition > selectedPosition) {
          while (
            nextValidPosition <= positionsFiltered?.length &&
            positions[nextValidPosition - 1]?.status === "CLOSING"
          ) {
            nextValidPosition++;
          }
        } else if (newPosition < selectedPosition) {
          while (
            nextValidPosition <= positionsFiltered?.length &&
            positions[nextValidPosition - 1]?.status === "CLOSING"
          ) {
            nextValidPosition--;
          }
        }

        if (
          nextValidPosition > 0 &&
          nextValidPosition <= Number(customersNumber)
        ) {
          setSelectedPosition(nextValidPosition);
        }
      }
    }
  };

  const calculateTotalValueForPosition = (position) => {
    const itemsForPosition = itemsInCart.filter(
      (item) => item.ClassSaleStep.getSteps().tablePosition === position
    );

    const totalValue = itemsForPosition.reduce((total, item) => {
      const principalItem = item.ClassSaleStep.getSteps();
      let itemTotalValue = 0;

      if (!principalItem.pointPrice) {
        itemTotalValue += item.quantity * principalItem.value;

        if (principalItem.steps && principalItem.steps.length) {
          principalItem.steps.forEach((step) => {
            if (step.selected !== null) {
              itemTotalValue += Array.from(step.selected).reduce(
                (amount, product) => {
                  let totalChildrenValue = product.amount * product.value;

                  if (product.hasOrientedSale) {
                    totalChildrenValue = calculateTotalValueForPosition(
                      position // Passa o mesmo número de posição para lidar com os itens filhos
                    );
                  }

                  return totalChildrenValue + amount;
                },
                0
              );
            } else if (step.composition) {
              step.products.forEach((composition) => {
                if (composition.operationType === "A") {
                  itemTotalValue += composition.value * composition.amount;
                }
              });
            }
          });
        }
      }

      return total + itemTotalValue;
    }, 0);

    return currency(totalValue);
  };

  let preventDoubleSubmit = false;

  const handleSubmitTable = async () => {
    if (preventDoubleSubmit) {
      return;
    }

    preventDoubleSubmit = true;

    try {
      const customersNumber =
        JSON.parse(sessionStorage.getItem("customersSelected")) ?? 1;

      let data = {
        storeId: getStoreId(),
        pdvCode: getTerminalID(),
        cashDeskOpenDateId: cashdesk.cashdesk.cashDeskOpenDate.id,
        tableNumber: tableNumber,
        cashDeskId: cashdesk.cashdesk.id,
        dateTimeStart: new Date(),
        totalValue: Sale.getTotalValue(),
        sellerReferenceId: operator.employeeCode,
        sellerName: operator.login,
        numberOfCustomers: customersNumber,
        positions: [],
        items: [],
      };

      for (let i = 0; i < positions.length; i++) {
        const itemObj = itemsInCart
          .filter(
            (item) =>
              item.ClassSaleStep.getSteps().tablePosition ===
              positions[i].position
          )
          .map((item) => ({
            ...item.ClassSaleStep.getSteps(),
            categoryId: item.ClassSaleStep.getSteps().categoryId ?? 0,
            tablePosition: positions[i].position ?? "",
            clientName: positions[i].name ?? "",
            isNewItem: true,
            quantity: item.quantity,
            sentToProductionAt: new Date(),
            pdvCode: getTerminalID(),
            sellerReferenceId: operator.employeeCode,
            dateTimeInsert: new Date(),
            waiterReferenceId: selectedWaiter.employeeCode,
            waiterName: selectedWaiter.loginName,
          }));

        data.items = data.items.concat(itemObj);
      }

      const currentPositions = data.items.map((item) => item.tablePosition);
      data.positions = positions
        .filter(
          (position) => currentPositions.includes(position.position) === false
        )
        .map((position) => ({ ...position, items: [] }));

      const result = await saleInProgressService(data);

      setIsOpenModalWaiter(false);

      if (result.data?.error) {
        showAlert({ message: result.data.error });
      } else {
        clearCartTable();
        showAlert({
          message: "Lançamento Enviado para produção!",
          onConfirmText: "Mapa de Mesa",
          cancelButtonText: "Continuar",
          handleCancel: () => {},
          handleConfirm: () => {
            navigate(PagesEnum.SALLER_TABLE);
            sessionStorage.removeItem("tableSelected");
            sessionStorage.removeItem("customersSelected");
          },
        });
      }
    } catch (error) {
      console.error(error);
      showAlert({ message: "Ocorreu um erro desconhecido!" });
    }
    preventDoubleSubmit = false;
  };

  const dialogCashdeskClosedSettings = {
    title: "Abertura do Caixa",
    subTitle: "O caixa não está aberto",
    message: "Deseja realizar a abertura do caixa neste momento?",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Abrir o caixa",
    onConfirmClick: async () => {
      setShowLoading();
      await cashdesk.getLastCashdesk();
      setShowLoading(false);
      navigate(PagesEnum.CASH_OPEN);
    },
  };

  const dialogCashdeskCurrentDaySettings = {
    title: "Fechamento de Caixa",
    subTitle: "O caixa está aberto",
    message:
      "Para continuar vendendo, realize o fechamento do caixa e encerramento do dia anterior.",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Fechar o caixa",
    onConfirmClick: () => {
      navigate(
        onCashierReductionOpenWithdrawingScreen
          ? PagesEnum.CASH_CLOSE_WITHDRAW
          : PagesEnum.CASH_CLOSE
      );
    },
  };

  const handleAddPosition = () => {
    if (!cashdesk.isCashdeskOpen) {
      setOpenDialog(dialogCashdeskClosedSettings);
      return;
    } else if (!cashdesk.isCashdeskOnCurrentDay) {
      setOpenDialog(dialogCashdeskCurrentDaySettings);
      return;
    }

    setIsOpenModalQuantity(true);
  };

  return (
    <>
      <Header>
        <div className="total">
          <div className="label">Mesa</div>
          <div className="value">{tableNumber}</div>
        </div>
        <div className="names__container">
          <div className="label">Posição</div>
          <select
            onChange={(e) => handlePositionChange(parseInt(e.target.value))}
            value={selectedPosition}
          >
            {positions
              .filter((position) => position.status !== "CLOSING")
              .map((position) => (
                <option key={position.position} value={position.position}>
                  {position.position} - {position.name || "POSIÇÃO"}
                </option>
              ))}
          </select>
        </div>
        <div className="buttons__container_table">
          <div>
            <button
              className="button__action"
              onClick={() => handlePositionChange(selectedPosition - 1)}
            >
              <MdOutlineArrowBackIos />
            </button>
            <button
              className="button__action"
              onClick={() => handlePositionChange(selectedPosition + 1)}
            >
              <MdOutlineArrowForwardIos />
            </button>
            <button
              className="button__action button__success"
              onClick={() => handleAddPosition()}
            >
              <MdAdd />
            </button>
            {/* Botão para uso futuro
            <button className="button__action">
              <MdOutlineMoreVert />
            </button> */}
          </div>
        </div>
      </Header>
      <AccordionContainer>
        {positions.map(
          (position, index) =>
            position.status !== "CLOSING" && (
              <Accordion
                key={index}
                title={`POSIÇÃO ${index + 1}`}
                value={
                  <span>
                    {calculateTotalValueForPosition(position.position)}
                  </span>
                }
                activeAccordion={
                  selectedPosition === index + 1 ? "POSIÇÃO " + (index + 1) : ""
                }
                setActiveAccordion={() => handlePositionChange(index + 1)}
                content={
                  <div style={{ height: "420px" }}>
                    <ContentCart className="content__space">
                      <div
                        className={classNames(
                          "content__space no-space cart-content",
                          {
                            hide: !isCurrentTabNyName("cart"),
                          }
                        )}
                      >
                        <div className="pdvcart-summary">
                          <span>PRODUTO</span>
                          <span>VALOR</span>
                        </div>
                        <div className="container-sidebar">
                          {itemsInCart
                            .filter((item) => {
                              console.log("filter => ", { item });

                              const {
                                tablePosition: itemTablePosition = null,
                              } = item.ClassSaleStep.getSteps();

                              console.log("filter => ", {
                                itemTablePosition,
                                position,
                              });

                              return itemTablePosition >= 0
                                ? itemTablePosition === position.position
                                : true;
                            })
                            .map((item, itemIndex) => {
                              console.log({ item, itemIndex });

                              return (
                                <ItemInCart
                                  item={item}
                                  index={itemIndex}
                                  totalItemsInCart={itemsInCart.length}
                                  key={String(`${item.id}${itemIndex}`)}
                                  handleGoToStep={(item, sequence) =>
                                    goToStep(item, sequence)
                                  }
                                  currentStep={() => getCurrentStep()}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </ContentCart>
                  </div>
                }
                expanded={index === selectedPosition}
              />
            )
        )}
      </AccordionContainer>
      <ul className="payments">
        {/* <li>
          <span>QTD</span>
          <span className="price-with-button">{totalQuantity}</span>
        </li> */}

        <li>
          <strong>TOTAL</strong>{" "}
          <strong className="price-with-button">
            {currency(totalPriceWDiscount)}
          </strong>
        </li>
      </ul>
      <TabNavContainer>
        <button
          onClick={() => {
            if (Sale?.getTotalValue() === 0) {
              setIsOpenModalWaiter(false);
            } else if (tableNumber && !tableNumber?.isPayment) {
              setIsOpenModalWaiter(true);
            }
          }}
          disabled={!isReadyToPay && location.pathname === PagesEnum.HOME}
          type="button"
          className={classNames("button__tab-submit", {
            isActive: isCurrentTabNyName("cart"),
          })}
        >
          ENVIAR PEDIDO
        </button>
      </TabNavContainer>
      {/* Fim do Carrinho */}

      <ModalWaiterCommission
        isOpen={isOpenModalWaiter}
        onClose={handleModalWaiterClose}
        onSubmit={handleSubmitTable}
        onWaiterSelect={handleWaiterSelection}
      />
    </>
  );
};
