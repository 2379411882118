export const currency = (value) => {
  const newValue = value ? Number(value) : 0;

  return newValue
    .toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    })
    .replace(/[\s\uFEFF\xA0]/g, "") // Remover espaços especiais (bug na impressão do mobile)
    .trim();
};
