import Configuration from "../aaone/configuration";

export const nextSequence = (type) => {
  const url = `${Configuration.endPoint}/getobjectsequential?type=${type}&pdvCode=${Configuration.terminalid}`;

  const response = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      terminalid: Configuration.terminalid,
      logtoken: 1,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e) => console.log("configSemParar ", e));

  return response;
};
