import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { apiProducts } from "../../../services/api";
import {
  DEFAULT_LOCAL_COLOR_OPTIONS,
  DEFAULT_LOCAL_TEXTS,
} from "../../constants/layout";
import { AccessibilityWrapper } from "./styles";
import { AccessibilityStyle } from "../../styles/themes/accessibility";
import { getSystemParametersService } from "../../../services/getSystemParametersService";
import { currentLayoutName, isLayoutUtil } from "../../shared/utils/isLayout";
import axios from "axios";

export const LayoutContext = createContext({});

export const LayoutProvider = ({ children }) => {
  const [colorOptions, setOptions] = useState(DEFAULT_LOCAL_COLOR_OPTIONS);
  const [screensTexts, setScreensTexts] = useState([]);
  const [isLayoutAAOne, setIsLayoutAAOne] = useState(isLayoutUtil("AAONE"));
  const [isActiveAccessibility, setIsActiveAccessibility] = useState(false);
  const [isVisibleAccessibilityContainer, setIsVisibleAccessibilityContainer] =
    useState(false);
  const [isActiveAccessibilityColor, setIsActiveAccessibilityColor] =
    useState(false);
  const [isActiveAccessibilityLabel, setIsActiveAccessibilityLabel] =
    useState(false);
  const [fontMultiplierAccessibility, setFontMultiplierAccessibility] =
    useState(0);
  const [isTranslationActive, setIsTranslationActive] = useState(false);
  const [systemParameters, setSystemParameters] = useState(null);

  const getTranslateName = useCallback((t, translateName) => {
    const result = t(translateName);

    if (result !== translateName) {
      return result;
    }

    return null;
  }, []);

  const changeLanguage = useCallback((lang) => {
    i18n.changeLanguage(lang);
  }, []);

  const getIndexScreenBySlug = useCallback(
    (slugName) => {
      if (screensTexts.length > 0) {
        const screen = screensTexts.findIndex(
          (currentScreen) => currentScreen.slug === slugName
        );

        if (screen > -1) {
          return screen;
        }
      }

      return null;
    },
    [screensTexts]
  );

  const getScreenBySlug = useCallback(
    (slugName) => {
      const screen = getIndexScreenBySlug(slugName);

      if (screen > -1) {
        return screensTexts[screen];
      }

      return undefined;
    },
    [screensTexts, getIndexScreenBySlug]
  );

  const getScreenTitleBySlug = useCallback(
    (slugName) => {
      const screen = getIndexScreenBySlug(slugName);

      if (screen > -1) {
        return screensTexts[screen]?.title ?? null;
      }

      return null;
    },
    [screensTexts, getIndexScreenBySlug]
  );

  const getScreenImageBySlug = useCallback(
    (slugScreenName, slugImageName) => {
      const screen = screensTexts.find(
        (currentScreen) =>
          currentScreen.slug === slugScreenName &&
          currentScreen?.image?.slug === slugImageName
      );

      if (screen) {
        return screen.image.relativePath;
      }

      return null;
    },
    [screensTexts]
  );

  const getScreenIsActiveBySlug = useCallback(
    (slugName) => {
      const screen = getIndexScreenBySlug(slugName);

      if (screen > -1) {
        return screensTexts[screen]?.active ?? undefined;
      }

      return undefined;
    },
    [screensTexts, getIndexScreenBySlug]
  );

  const getFieldScreenBySlug = useCallback(
    (fieldIndex, screenSlugName) => {
      const screen = getIndexScreenBySlug(screenSlugName);

      if (screen > -1) {
        const field = screensTexts[screen]?.fields[Number(fieldIndex)] ?? null;

        if (field) {
          return field;
        }
      }

      return null;
    },
    [getIndexScreenBySlug, screensTexts]
  );

  const setVisibleAccessibility = useCallback((isVisible = true) => {
    setIsVisibleAccessibilityContainer(isVisible);
  }, []);

  const handleToggleAccessibilityColor = useCallback(() => {
    setIsActiveAccessibilityColor((isActive) => !isActive);
  }, []);

  const disableAccessibilityColor = useCallback(() => {
    setIsActiveAccessibilityColor(false);
  }, []);

  const isLocalFont = useMemo(
    () =>
      colorOptions?.fontSource &&
      colorOptions?.fontSource === "FONT_SOURCE_SYSTEM",
    [colorOptions]
  );

  const setStatusActiveAccessibilityLabel = useCallback((isActive) => {
    setIsActiveAccessibilityLabel(isActive);
  }, []);

  const isLayout = useCallback(isLayoutUtil, []);

  const increaseText = () => {
    setFontMultiplierAccessibility((fontSize) => {
      const newSize = fontSize + 0.8;

      if (newSize > 3.2) {
        return 3.2;
      }

      return newSize;
    });
  };

  const decreaseText = () => {
    setFontMultiplierAccessibility((fontSize) => {
      const newSize = fontSize - 0.8;

      if (newSize < -0.8) {
        return -0.8;
      }

      return newSize;
    });
  };

  useEffect(() => {
    const isAAOne = currentLayoutName === "AAONE";

    setIsLayoutAAOne(isAAOne);

    if (isAAOne) {
      (async () => {
        try {
          const { data } = await apiProducts.get("/api/layout");

          if (data?.layout) {
            setOptions((oldOptions) => ({
              ...oldOptions,
              ...data.layout,
            }));
          }

          if (data?.screens) {
            setScreensTexts(data.screens);
          }

          const systemParametersData = await getSystemParametersService();

          const isAccessibilityActive =
            !!systemParametersData?.objectSystemParameters
              ?.franchisorConfiguration?.accessibility;

          setSystemParameters(
            systemParametersData?.objectSystemParameters ?? null
          );

          setIsActiveAccessibility(isAccessibilityActive);
        } catch (error) {
          setOptions(DEFAULT_LOCAL_COLOR_OPTIONS);
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      let resources = {
        pt: { translation: DEFAULT_LOCAL_TEXTS },
      };

      try {
        const { data } = await axios.get("/static/lang/en.json");

        resources = {
          ...resources,
          en: { translation: data },
        };

        setIsTranslationActive(data?.start_button?.length >= 0);
      } catch (error) {
        // !todo - (futuro) Adicionar log de erro junto aos log geral
        setIsTranslationActive(false);
      }

      i18n.use(initReactI18next).init({
        compatibilityJSON: "v3",
        resources,
        fallbackLng: false,
        interpolation: {
          escapeValue: false,
        },
      });
    })();
  }, []);

  const values = useMemo(
    () => ({
      isTranslationActive,
      isLayoutAAOne,
      isLayout,
      colorOptions,
      getScreenTitleBySlug,
      getScreenImageBySlug,
      getFieldScreenBySlug,
      getScreenBySlug,
      getScreenIsActiveBySlug,
      fontMultiplierAccessibility,
      setVisibleAccessibility,
      disableAccessibilityColor,
      setStatusActiveAccessibilityLabel,
      getTranslateName,
      changeLanguage,
      systemParameters,
    }),
    [
      isTranslationActive,
      isLayoutAAOne,
      isLayout,
      colorOptions,
      getScreenTitleBySlug,
      getScreenImageBySlug,
      getFieldScreenBySlug,
      getScreenBySlug,
      getScreenIsActiveBySlug,
      fontMultiplierAccessibility,
      setVisibleAccessibility,
      disableAccessibilityColor,
      setStatusActiveAccessibilityLabel,
      getTranslateName,
      changeLanguage,
      systemParameters,
    ]
  );

  const format = String(colorOptions?.fontPath ?? "").substring(
    String(colorOptions?.fontPath ?? "").lastIndexOf(".") + 1
  );
  let formatFont = "truetype";

  if (!isLocalFont && colorOptions?.fontPath) {
    const formats = {
      ttf: "truetype",
      woff2: "woff2",
      woff: "woff",
    };

    formatFont = formats[format] ?? formats["ttf"];
  }

  return (
    <LayoutContext.Provider value={values}>
      {isLayoutAAOne && !isActiveAccessibilityColor && (
        <style jsx="true">
          {`
            @font-face {
              font-family: "${isLocalFont
                ? colorOptions.fontPath
                : "AAOne Font"}";
              src: local(
                  "${isLocalFont ? colorOptions.fontPath : "AAOne Font"}"
                )
                ${!isLocalFont
                  ? `, url("${colorOptions.fontPath}") format("${formatFont}")`
                  : ""};
              font-weight: normal;
            }

            :root {
              --font-family-main: "${isLocalFont
                ? colorOptions.fontPath
                : "AAOne Font"}";

              ${colorOptions?.primaryColor &&
              `
            --color-select-text: ${colorOptions.primaryColor} !important;
            --color-background-primary: ${colorOptions.primaryColor} !important;
            --color-background-cart: ${colorOptions.primaryColor} !important;
            --color-progress: ${colorOptions.primaryColor} !important;
            --color-background-card-secondary: ${colorOptions.primaryColor} !important;
            --color-additem: ${colorOptions.primaryColor} !important;
            --color-trash: ${colorOptions.primaryColor} !important;
            --color-background-keyboard: ${colorOptions.primaryColor} !important;
            `}
              ${colorOptions?.menuBackgroundColor &&
              `
            --color-bg-menu: ${colorOptions.menuBackgroundColor} !important;
            `}

            ${colorOptions?.menuFontColor &&
              `
            --color-text-menu: ${colorOptions.menuFontColor} !important;
            `}

            ${colorOptions?.secondaryColor &&
              `
            --color-title-secondary: ${colorOptions.secondaryColor} !important;
            --color-keyboard-background: ${colorOptions.secondaryColor} !important;
            --color-fidelity: ${colorOptions.secondaryColor} !important;
            --color--background-banner: ${colorOptions.secondaryColor} !important;
            --color-button-secondary: ${colorOptions.secondaryColor} !important;
            --color-bg-button: ${colorOptions.secondaryColor} !important;
            `}

            // white font is like secondary color font
            ${colorOptions?.whiteFontColor &&
              `
            --color-background-light: ${colorOptions.whiteFontColor} !important;
            --color-button-resgate: ${colorOptions.whiteFontColor} !important;
            --color-button-banner: ${colorOptions.whiteFontColor} !important;
            --color-title-points: ${colorOptions.whiteFontColor} !important;
            --color-box-points: ${colorOptions.whiteFontColor} !important;
            --color-total-price-secondary: ${colorOptions.whiteFontColor} !important;
            --text-light: ${colorOptions.whiteFontColor} !important;
            --color-text-button-outline: ${colorOptions.whiteFontColor} !important;
            `}

            // white font is like primary color font
            ${colorOptions?.darkFontColor &&
              `
            --text-color-primary: ${colorOptions.darkFontColor} !important;
            --color-background-card-primary: ${colorOptions.darkFontColor} !important;
            --color-title-category: ${colorOptions.darkFontColor} !important;
            --color-subtitle: ${colorOptions.darkFontColor} !important;
            --total-color-cart: ${colorOptions.darkFontColor} !important;
            --color-text-category: ${colorOptions.darkFontColor} !important;
            --color-text-flavor-list: ${colorOptions.darkFontColor} !important;
            --color-text-dark-font: ${colorOptions.darkFontColor} !important;
            --color-text-button: ${colorOptions.darkFontColor} !important;
            `}

            ${colorOptions?.buttonBorderColor &&
              `
            --button-bg-color: ${colorOptions.buttonBorderColor} !important;
            --button-border-color: ${colorOptions.buttonBorderColor} !important;
            --color-border-button-outline:  ${colorOptions.buttonBorderColor} !important;
            `}

            ${colorOptions.productBackgroundColor &&
              `
            --product-background-color: ${colorOptions.productBackgroundColor} !important;
            `}

            ${colorOptions.productModalBackgroundColor &&
              `
              --color-background-modal: ${colorOptions.productModalBackgroundColor} !important;
            `}
            }
          `}
        </style>
      )}
      {isActiveAccessibility && isVisibleAccessibilityContainer && (
        <>
          <style jsx="true">
            {`
              html {
                font-size: ${16 + fontMultiplierAccessibility}px;
              }
            `}
          </style>
          <AccessibilityWrapper>
            <div className="container">
              {isActiveAccessibilityLabel && (
                <div className="label">Acessibilidade:</div>
              )}
              <div
                className="button-font-size"
                role="button"
                onClick={increaseText}
              >
                A+
              </div>
              <div
                className="button-font-size"
                role="button"
                onClick={decreaseText}
              >
                A-
              </div>
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="adjust"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="2.5rem"
                onClick={handleToggleAccessibilityColor}
              >
                <path
                  fill="currentColor"
                  d="M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z"
                />
              </svg>
            </div>
          </AccessibilityWrapper>
          {isActiveAccessibilityColor && <AccessibilityStyle />}
        </>
      )}
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutAa = () => {
  const context = useContext(LayoutContext);

  return context;
};
