import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.div`
  display: ${(props) => (props.visible ? "" : "none")};

  ${(props) =>
    props.layout === "numeric"
      ? // Teclado Numérico
        css`
          background-color: ${(props) => props.theme.background.primary};

          .header-content {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;

            background-color: #fff;
            height: 46px;
            margin-bottom: 2px;
            padding-left: 15px;

            span {
              font-family: Roboto, sans-serif;
              font-size: 18px;
              font-weight: 500;
              color: #414141;
              text-transform: capitalize;
              opacity: 0.8;
            }

            button {
              min-width: 50px;
              height: 44px;
              border: none;
              outline: none;
              background-color: #fff;

              font-family: Roboto, sans-serif;
              font-size: 16px;
              font-weight: 500;
              color: #414141;
              text-transform: capitalize;
              opacity: 0.8;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .overlay-keyboard {
            display: block;
            position: absolute;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            width: 270px;
            top: 0px;
          }

          .simple-keyboard.hg-theme-default.myThemeNumeric {
            min-width: 270px;

            border: none;
            padding: 0px;
            background-color: ${(props) => props.theme.background.primary};
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          .simple-keyboard.hg-layout-default.myThemeNumeric .hg-button {
            font-family: Roboto, sans-serif;
            font-size: 22px;
            font-weight: 500;
            color: #414141;
            height: 70px;
            width: 90px;
            border-radius: 0px;
            border-bottom: none;
            margin-left: -5px;
            margin-bottom: -5px;
          }

          .simple-keyboard.hg-layout-default.myThemeNumeric
            .hg-button.hg-primary {
            background: var(--highlight-color);
            color: white;

            &:active {
              background: #666;
            }
          }

          .simple-keyboard.hg-layout-default.myThemeNumeric
            .hg-button.hg-close {
            background: #ff4c4c;
            color: white;

            &:active {
              background: red;
            }
          }

          /* Tela de Login */
          &.keyboard-login-password {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 10;
          }

          /* Fixo na Lateral */
          &.keyboard-fixed {
            border: none !important;
            position: fixed;
            top: 60%;
            ${(props) =>
              props.cartIsVisible && props.cartPosition === "left"
                ? css`
                    right: 12px;
                  `
                : css``}
            ${(props) =>
              props.cartIsVisible && props.cartPosition === "right"
                ? css`
                    left: 12px;
                  `
                : css``}
        ${(props) =>
              !props.cartIsVisible && props.cartPosition === "left"
                ? css`
                    left: 12px;
                  `
                : css``}
        ${(props) =>
              !props.cartIsVisible && props.cartPosition === "right"
                ? css`
                    right: 12px;
                  `
                : css``}
        +.keyboard-disabled {
              hg-button {
                background: #ccc;
              }
            }
          }
        `
      : // Teclado Alphanumérico
        css`
          .simple-keyboard.hg-theme-default.myTheme {
            min-width: 480px;
            max-width: 960px;

            border: 1px solid #c8cbd0;
            background-color: ${(props) => props.theme.background.primary};
            width: 80vw;
            margin-bottom: 20px;
            box-shadow: 0 0 55px 10px #666, 0 0 2px 1px #999;
          }

          .simple-keyboard.hg-layout-default .hg-button {
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #414141;
            height: 50px;
          }

          .simple-keyboard.hg-layout-default .hg-button.hg-primary {
            background: var(--highlight-color);
            color: white;
            width: 100px;

            &:active {
              background: #666;
            }
          }

          .simple-keyboard.hg-layout-default .hg-button.hg-close {
            background: #ff4c4c;
            color: white;
            width: 50px;

            &:active {
              background: red;
            }
          }

          /* Fixo em baixo */
          &.keyboard-fixed {
            position: fixed;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 14;
          }

          &.keyboard-combo {
            bottom: -120px;
          }
        `}
`;
export const InputGroup = styled.div`
  font-family: Roboto, sans-serif;
  color: #414141;
  line-height: 2;
  padding: 5px 10px 0 5px;
  width: 270px;

  span {
    color: #fe2e2e;
    height: 25px;
  }

  small {
    color: #888484;
  }

  input {
    width: 100%;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
    font: 400 13.3333px Arial;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    width: 100%;
  `)}
`;

export const InputTotal = styled.input`
  ${(props) => css`
    text-align: right;
    height: 50px;
    background: rgba(128, 128, 128, 0.13);
    font-weight: bold;
    color: ${(props) =>
      props.colorBackground ? props.colorBackground : "none"};
  `}
`;

export const CardCheck = styled.div`
  width: 270px;
  height: 280px;
  background: ${(props) => props.theme.palette.grayscale.white};
  margin-top: 5px;
  padding: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    width: 100%;
  `)}
`;
