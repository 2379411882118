import styled from "styled-components";

export const ContentGridScroll = styled.div`
  position: static;
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 1em 0 2em;
  margin-top: 20px !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
`
export const ContentFigure = styled.div`
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
  margin-top: 6px;
  margin-bottom: 10px;
  width: 323px;
`
export const FigureItem = styled.figure`
  color: var(--color-text-category);
  display: grid;
  font-size: 23px;
  font-weight: 400;
  margin: 0;
  margin-top: 6px;
  .name-combo-card-item {
    width: 80%;
    margin: 1em auto 5px;
    text-align: center;
  }
`
export const ImageItem = styled.img`
  width: 270px;
  height: 180px;
  padding: 2em 1em;
  border-radius: 1.5em;
  box-shadow: -6px -6px 8px var(--color-background-light), 6px 6px 20px #d8d2d2;
  background: var(--color-box-shadow-primary);
  &.no-image {
    line-height: 190px;
    width: 270px;
    height: 180px;
    margin-left: 0px !important;
    background: var(--no-image) no-repeat 50%;
    background-size: contain;
  }
`
export const ItemPrice = styled.p`
  color: var(--color-title-category);
  font-weight: 700;
  margin: 0;
  text-align: center;
`
export const SectionGrid = styled.section`
  max-height: 1000px;
  overflow-y: scroll;
  .radio-button-group--list {
    max-height: ${({displayRequired}) => !displayRequired ? '600px' : '700px'};
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.8);
  }
`;
