export const openDrawer = () => {
  try {
    //Abre a gaveta
    if (window.Android) {
      window.Android.openDrawerTectoy();
      window.sessionStorage.removeItem("draweropen")
    } else if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.drawer.open");
      window.sessionStorage.removeItem("draweropen")
    }
  } catch (err) {
    console.log(`error on openDrawer: ${JSON.stringify(err)}`);
  }
};
