import React, { useEffect, useRef, useState } from "react";
import ProductsUnavailable from "../../components/ProductsUnavailable";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { useLoading } from "../../hook/loadingHook";
import { getProductsAll } from "../../../services/productService";

const ProductsUnavailableContainer = (props) => {
  const [rowsData, setRowsData] = useState([]);
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { setShowLoading } = useLoading();
  const navigate = useNavigate();
  const agGridRef = useRef();

  useEffect(() => {
    setVisibleSidebarCart(false);

    return () => {
      setVisibleSidebarCart();
    };
  }, []);

  useEffect(() => {
    try {
      setShowLoading();

      if (agGridRef.current) {
        agGridRef.current.api.hideOverlay();
      }

      const returnsLevelOrientedSale = (item) => {
        return Object.keys(item).find((position) => {
          if (position.includes("nivel")) return position;
          else return undefined;
        });
      };

      const getNivelInItem = (item) => {
        const level = returnsLevelOrientedSale(item);

        if (typeof level !== "undefined") {
          return item[level]?.length > 0 ? item[level] : null;
        }

        return null;
      };

      const filterItems = (acc, curr, prodHierarchy = []) => {
        curr.prodHierarchy = [...prodHierarchy, curr.productCode];

        acc = [...acc, curr];

        const level = getNivelInItem(curr);

        if (level) {
          let subItems = level.reduce(
            (_acc, _curr) => filterItems(_acc, _curr, curr.prodHierarchy),
            []
          );

          acc = [...acc, ...subItems];
        }

        return acc;
      };

      getProductsAll({ complete: true })
        .then((res) => {
          const products = res.products.reduce(
            (acc, curr) => filterItems(acc, curr),
            []
          );

          setRowsData(products);
        })
        .then(() => {
          setShowLoading(false);
        });
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  }, []);

  const handleClose = () => {
    navigate(PagesEnum.HOME);
  };

  return (
    <ProductsUnavailable
      handleClose={() => handleClose()}
      agGridRef={agGridRef}
      rowsData={rowsData}
    />
  );
};

export default ProductsUnavailableContainer;
