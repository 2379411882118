import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Slider from "react-slick";

import { useVersionAa } from "../../../aaone/contexts/versionContext";
import { loadCategoryItems } from "../../../services/catalogService";
import { useCart } from "../../hook/cartHook";
import { useCategoriesNavigate } from "../../hook/categoriesHook";
import { useMediaQuery } from "../../hook/mediaQueryHook";
import { useVertical } from "../../hook/verticalHook";
import { TotalContainer } from "../../pages/home/styles";
import { NextItemSlider, PreviousItemSlider } from "../ArrowsCarousel";

import { ItemCategory, SkeletonItemCategory } from "./Item";
import { Container } from "./styles";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useDialog } from "../../hook/dialogHook";
import { useNavigate } from "react-router-dom";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { PagesEnum } from "../../constants/pagesEnum";
import { useAuth } from "../../hook/authHook";
import { useMigration } from "../../hook/migrationHook";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { useLoading } from "../../hook/loadingHook";
import { getTerminalID, isSmartPos } from "../../../aaone/configuration";
import { QuantityDefault } from "./quantity/default";
import { QuantitySmall } from "./quantity/small";

let lestQuantityUpdate;

export const Categories = () => {
  const systemParams = getSystemParamsHelper();
  const onCashierReductionOpenWithdrawingScreen =
    systemParams?.onCashierReductionOpenWithdrawingScreen || false;

  const versionAa = useVersionAa();
  const navigate = useNavigate();
  const { handleChangeCategory } = useCategoriesNavigate();
  const { isVerticalScreen } = useVertical();
  const { setOpenDialog } = useDialog();
  const {
    quantityToCart,
    setQuantityToCart,
    handleOpenModalAddProductByCode,
    isOpenModalTotalProduct,
    handleOpenModalTotalQuantity,
    itemsInCart,
  } = useCart();
  const isLikeIMCSizeMedia = useMediaQuery("(min-width: 1366px)");
  const {
    cashdesk,
    isCashdeskOpen,
    isCashdeskOnCurrentDay,
    getLastCashdesk,
    showAlertWithdrawCanSale,
  } = useCashdesk();
  const { setShowLoading } = useLoading();
  const { setIsOpenMenu } = useWrapperRouters();
  const { userAuthData } = useAuth();
  const { lastUpdateMigrationByUser } = useMigration();

  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [categories, setCategories] = useState([]);
  const totalCategories = categories.length;

  const [totalQuantity, setTotalQuantity] = useState({
    format: "number",
    quantity: 1,
  });

  const quantity = useMemo(() => {
    if (isLikeIMCSizeMedia && !isVerticalScreen) {
      return 9;
    } else if (!isLikeIMCSizeMedia && isVerticalScreen) {
      return 5;
    }

    return 9;
  }, [isVerticalScreen]);

  const dialogCashdeskClosedSettings = {
    title: "Abertura do Caixa",
    subTitle: "O caixa não está aberto",
    message: "Deseja realizar a abertura do caixa neste momento?",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Abrir o caixa",
    onConfirmClick: async () => {
      setShowLoading();
      await getLastCashdesk();
      setShowLoading(false);
      navigate(PagesEnum.CASH_OPEN);
    },
  };

  const dialogCashdeskCurrentDaySettings = {
    title: "Fechamento de Caixa",
    subTitle: "O caixa está aberto",
    message:
      "Para continuar vendendo, realize o fechamento do caixa e encerramento do dia anterior.",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Fechar o caixa",
    onConfirmClick: () => {
      navigate(
        onCashierReductionOpenWithdrawingScreen
          ? PagesEnum.CASH_CLOSE_WITHDRAW
          : PagesEnum.CASH_CLOSE
      );
    },
  };

  const dialogNotOpenedUser = {
    title: "Operador diferente da abertura de caixa",
    subTitle:
      "Foi identificado que o operador logado não é o responsável pela abertura.",
    message:
      "Para continuar vendendo, realize o fechamento ou redução do caixa.",
    onConfirmText: "Fechar o caixa",
    onConfirmClick: () => {
      navigate(
        onCashierReductionOpenWithdrawingScreen
          ? PagesEnum.CASH_CLOSE_WITHDRAW
          : PagesEnum.CASH_CLOSE
      );
    },
    customActions: [
      {
        text: "Reduzir o caixa",
        onClick: () =>
          navigate(
            onCashierReductionOpenWithdrawingScreen
              ? PagesEnum.CASH_CLOSE_REDUCE
              : PagesEnum.CASH_REDUCE
          ),
      },
    ],
  };

  const handleChangeQuantity = useCallback(
    async (quantity) => {
      try {
        let blockSales = false;
        const tableSelectedInHome = JSON.parse(
          sessionStorage.getItem("tableSelected")
        );
        if (isCashdeskOpen) {
          blockSales = await showAlertWithdrawCanSale(() => {
            navigate(PagesEnum.CASH_WITHDRAW);
          });
        }

        let validateSale = true;

        /**
         *  Se a loja for 24h e estiver com uma venda em andamento,
         *  não trava o carrinho.
         */
        try {
          if (
            itemsInCart.length > 0 &&
            systemParams.store24Hours &&
            !isCashdeskOnCurrentDay &&
            !tableSelectedInHome
          ) {
            validateSale = false;
          }
        } catch (e) {
          if (window.Android) {
            console.log(
              "Error on Categories handleChangeQuantity validateSale => " +
                JSON.stringify(e)
            );
          } else {
            console.error(
              "Error on Categories handleChangeQuantity validateSale => " + e
            );
          }
        }

        if (validateSale) {
          if (!isCashdeskOpen && !tableSelectedInHome) {
            setOpenDialog(dialogCashdeskClosedSettings);

            return;
          } else if (!isCashdeskOnCurrentDay && !tableSelectedInHome) {
            setOpenDialog(dialogCashdeskCurrentDaySettings);

            return;
          } else if (
            cashdesk &&
            cashdesk.user !== userAuthData.login &&
            !userAuthData.isManager
          ) {
            setOpenDialog(dialogNotOpenedUser);

            return;
          } else if (blockSales) {
            console.log("Venda bloqueada por Limite/Notificação de Sangria");

            return;
          }
        }

        const isOther = "qtd" === quantity;

        if (isOther) {
          handleOpenModalTotalQuantity();
        }

        const quantityObj = {
          format: isOther ? "other" : "number",
          quantity,
        };

        if (!isOther) {
          setQuantityToCart(quantity);
          setTotalQuantity(quantityObj);
        }
      } catch (err) {
        if (window.Android) {
          console.log(
            `Error on handleChangeQuantity => ${JSON.stringify(err)}`
          );
        } else {
          console.error(`Error on handleChangeQuantity => ${err}`);
        }
      }
    },
    [isCashdeskOpen, isCashdeskOnCurrentDay, cashdesk, userAuthData]
  );

  const openModalAddProductByCode = useCallback(async () => {
    try {
      let blockSales = false;
      let validateSale = true;
      const tableSelectedInHome = JSON.parse(
        sessionStorage.getItem("tableSelected")
      );
      if (isCashdeskOpen) {
        blockSales = await showAlertWithdrawCanSale(() => {
          navigate(PagesEnum.CASH_WITHDRAW);
        });
      }

      /**
       *  Se a loja for 24h e estiver com uma venda em andamento,
       *  não trava o carrinho.
       */
      try {
        if (
          itemsInCart.length > 0 &&
          systemParams.store24Hours &&
          !isCashdeskOnCurrentDay &&
          !tableSelectedInHome
        ) {
          validateSale = false;
        }
      } catch (e) {
        if (window.Android) {
          console.log(
            "Error on Categories openModalAddProductByCode => " +
              JSON.stringify(e)
          );
        } else {
          console.error(
            "Error on Categories openModalAddProductByCode => " + e
          );
        }
      }

      if (validateSale) {
        if (!isCashdeskOpen) {
          setOpenDialog(dialogCashdeskClosedSettings);

          return;
        } else if (!isCashdeskOnCurrentDay) {
          setOpenDialog(dialogCashdeskCurrentDaySettings);

          return;
        } else if (
          cashdesk &&
          cashdesk.user !== userAuthData.login &&
          !userAuthData.isManager
        ) {
          setOpenDialog(dialogNotOpenedUser);

          return;
        } else if (blockSales) {
          console.log("Venda bloqueada por Limite/Notificação de Sangria");

          return;
        }
      }

      handleOpenModalAddProductByCode();
    } catch (err) {
      if (window.Android) {
        console.log(
          `Error on openModalAddProductByCode => ${JSON.stringify(err)}`
        );
      } else {
        console.error(`Error on openModalAddProductByCode => ${err}`);
      }
    }
  }, [
    setOpenDialog,
    setIsOpenMenu,
    isCashdeskOpen,
    isCashdeskOnCurrentDay,
    handleOpenModalAddProductByCode,
    showAlertWithdrawCanSale,
  ]);

  const responsiveSerialize = (breakpoint, quantity, options) => {
    return {
      breakpoint: breakpoint,
      settings: {
        rows:
          totalCategories > 9 ? Number(options?.rows ?? 2) : isSmartPos ? 2 : 1,
        slidesToShow: quantity,
        slidesToScroll: quantity,
      },
    };
  };

  const settingsCarousel = useMemo(
    () => ({
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      rows: totalCategories > 9 ? 2 : 1,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: <PreviousItemSlider disabled={isLoadingCategories} />,
      nextArrow: <NextItemSlider disabled={isLoadingCategories} />,
      responsive: [
        responsiveSerialize(1920, 6),
        responsiveSerialize(1366, 5),
        responsiveSerialize(1080, 3),
        responsiveSerialize(480, isSmartPos ? 3 : 2, { rows: 2 }),
        responsiveSerialize(360, isSmartPos ? 3 : 2, { rows: 2 }),
      ],
    }),
    [isLoadingCategories, totalCategories]
  );

  useEffect(() => {
    if (isLoadingCategories) {
      setCategories([...new Array(30)]);
    }
  }, [isLoadingCategories]);

  useEffect(() => {
    //NOTE - REMOVER ISSO Após conclusão
    versionAa.hide();

    setIsLoadingCategories(true);

    (async () => {
      try {
        const result = await loadCategoryItems(String(getTerminalID()));

        const categories = result?.categories ?? [];

        if (categories.length > 0) {
          const { categoryCode, isFidelity = false } = categories[0];

          handleChangeCategory({ categoryId: categoryCode, isFidelity });
        }

        setCategories(categories);
      } catch (error) {
        // todo - Tratar erro quando não tem categorias
        setCategories([]);
      }

      setIsLoadingCategories(false);
    })();
  }, [lastUpdateMigrationByUser]);

  useEffect(() => {
    if ("reload" === quantityToCart) {
      handleChangeQuantity(1);

      if (isSmartPos) {
        const inputEle = document.querySelector(
          ".isSmartPos section div input.input__quantity"
        );

        if (inputEle) {
          inputEle.value = "1";
        }
      }
    } else if (quantityToCart < quantity && "other" === totalQuantity.format) {
      if (!isSmartPos) {
        const qtdEle = document.querySelector(
          `.item__button.item__num.item__quantity__${quantityToCart}`
        );

        if (qtdEle) {
          qtdEle.click();
        }
      }
    } else if (
      quantityToCart > quantity &&
      quantityToCart != lestQuantityUpdate
    ) {
      lestQuantityUpdate = quantityToCart;

      setTotalQuantity({
        format: "other",
        quantity: quantityToCart,
      });
    }
  }, [isOpenModalTotalProduct, quantityToCart, quantity, totalQuantity]);

  const quantityProps = useMemo(
    () => ({
      quantity,
      totalQuantity,
      isLoadingCategories,
      handleChangeQuantity,
      openModalAddProductByCode,
    }),
    [
      quantity,
      totalQuantity,
      isLoadingCategories,
      handleChangeQuantity,
      openModalAddProductByCode,
    ]
  );

  return (
    <>
      <Container>
        <div className={classNames({ isSmartPos }, "carousel__categories")}>
          <Slider {...settingsCarousel}>
            {categories.map((category, index) => {
              if (isLoadingCategories) {
                return <SkeletonItemCategory key={String(index)} />;
              } else {
                return (
                  <ItemCategory
                    data={{ ...category, id: category.categoryCode }}
                    key={category.categoryCode}
                  >
                    {category.description}
                  </ItemCategory>
                );
              }
            })}
          </Slider>
        </div>
      </Container>
      <TotalContainer
        className={classNames({ skeleton: isLoadingCategories, isSmartPos })}
      >
        {isSmartPos ? (
          <QuantitySmall {...quantityProps} />
        ) : (
          <QuantityDefault {...quantityProps} />
        )}
      </TotalContainer>
    </>
  );
};
