import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";

import { getProductWithAllPossibilities } from "../../../../services/catalogService";

import { ItemProduct } from "../../Products/Item";
import { KeyboardNumeric } from "../../KeyboardNumeric";

import { Container } from "./styles";
import { LoadingDots } from "../../LoadingDots";
import { useCategoriesNavigate } from "../../../hook/categoriesHook";
import SaleStepUseCase from "../../../domain/useCases/sale/SaleStepUseCase";
import { useCart } from "../../../hook/cartHook";

let timerDebounceId;
export const ModalAddProductByCode = ({ handleClose }) => {
  const { setCategoryProducts, setCurrentCategoryIsFidelity } =
    useCategoriesNavigate();
  const { clearCurrentProduct } = useCart();
  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const [code, setCode] = useState("");
  const [codeToRequest, setCodeToRequest] = useState("");
  const [keyboardActions, setKeyboardActions] = useState();
  const [productResult, setProductResult] = useState(null);

  const itemRef = useRef(null);

  useEffect(() => {
    if (code?.length > 0) {
      setIsLoadingResult(true);

      if (timerDebounceId) {
        clearTimeout(timerDebounceId);
      }

      timerDebounceId = setTimeout(() => {
        setCodeToRequest(code);
      }, 1500);
    }

    return () => {
      if (timerDebounceId) {
        clearTimeout(timerDebounceId);
      }
    };
  }, [code]);

  useEffect(() => {
    if (codeToRequest?.length > 0) {
      (async () => {
        try {
          const { product } = await getProductWithAllPossibilities(
            codeToRequest
          );

          if (Object.keys(product).length === 0) {
            setProductResult("empty");
          } else {
            setProductResult(product);
          }
        } catch (error) {
          setProductResult("empty");
        }

        setIsLoadingResult(false);
      })();
    } else {
      setProductResult(null);
    }
  }, [codeToRequest]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!isLoadingResult && itemRef?.current && productResult) {
        itemRef.current.click();
      }
    },
    [isLoadingResult, itemRef, productResult]
  );

  const onEventKeyUp = useCallback((e) => {
    const shortcuts = ["Escape"];

    if (shortcuts.includes(e.key)) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", onEventKeyUp);

    return () => {
      window.removeEventListener("keyup", onEventKeyUp);
    };
  }, [onEventKeyUp]);

  const handleSelectedItemWithLevel = (product) => {
    if (product) {
      clearCurrentProduct();

      const saleProduct = new SaleStepUseCase(productResult, 1, 1);
      const steps = saleProduct.getSteps();
      if (steps?.steps.length) {
        setCategoryProducts(steps?.steps[0].products);
        setCurrentCategoryIsFidelity(false);
      }
    }
  };

  useEffect(() => {
    if (keyboardActions?.value && keyboardActions?.value.length > 0) {
      setCode(keyboardActions?.value ?? "");
    } else {
      setCode("");
    }

    return () => {
      if (timerDebounceId) {
        clearTimeout(timerDebounceId);
      }
    };
  }, [keyboardActions?.value]);

  return (
    <Container>
      <div className="content">
        <button onClick={handleClose} className="button__close">
          <MdClose size="2rem" />
        </button>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="inputs-group">
            <div className="input-container">
              <input
                type="text"
                value={code}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                role="presentation"
                readOnly="readonly"
                autoFocus
                onFocus={(e) => {
                  e.currentTarget.focus();
                  e.currentTarget.removeAttribute("readOnly");
                }}
                onBlur={(e) => e.currentTarget.focus()}
                onChange={(e) => {
                  if (keyboardActions?.set) {
                    keyboardActions.set(e.target.value);
                  }

                  return e;
                }}
                name="code"
                id="code"
                inputMode="none"
                placeholder="Digite o código"
              />
            </div>
            <KeyboardNumeric
              newButtons={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                {
                  className: "doubleZero",
                  value: "00",
                },
                "0",
                {
                  className: "backspace",
                  value: "←",
                },
              ]}
              setActions={setKeyboardActions}
            />
          </div>
          <div className="product__result">
            {isLoadingResult ? (
              <div className="empty__product">
                <LoadingDots />
              </div>
            ) : (
              <>
                {productResult?.productCode && (
                  <ItemProduct
                    ref={itemRef}
                    className="no-vertical"
                    data={{ ...productResult, code: productResult.productCode }}
                    setProductList={(product) =>
                      handleSelectedItemWithLevel(product)
                    }
                  >
                    {
                      productResult.allDescriptions.length ?
                        productResult.allDescriptions.descriptionPDV :
                        productResult.description
                    }
                  </ItemProduct>
                )}
                {productResult === "empty" && (
                  <div className="empty__product">Produto não encontrado</div>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </Container>
  );
};
