import React, { useState, useEffect } from "react";
import { ImageProduct, RadioContainer } from "./style";
import {
  StepComposition,
  FlavorSelect,
  RadioButtonList,
  StepList,
  Step,
} from "../modules";
import { useCallback } from "react";
import { showFullValue } from "../../../configuration";
import { getQuantityCombo } from "../../utils/getQuantityCombo";

const ComboIndividual = (props) => {
  const { product, onSubmit, listSteps, newStep, setValue } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsComposition, setSelectedItemsComposition] = useState([]);
  const [controlBackStep, setBackstep] = useState(false);
  const [steps, setSteps] = useState([]);
  const [listCombo, setListCombo] = useState([]);

  const selectedTotalCombo = listCombo.length;

  useEffect(() => {
    localStorage.setItem("selectedTotalCombo", String(selectedTotalCombo));
  }, [selectedTotalCombo]);

  useEffect(() => {
    setSteps(listSteps);
  }, [listSteps, steps]);

  const handleRemoveItemComposition = item => {
    const otherItems = selectedItemsComposition
      .filter(productItem => productItem.productCode !== item.productCode);

    setSelectedItemsComposition([...otherItems]);
  };

  const addItemSelectedComposition = product => {
    const newSelectedItems = selectedItemsComposition.filter((item) => {
      if (
        (item.productCode !== product.productCode &&
          item.classification !== product.classification) ||
        (item.isProductComposition !== undefined &&
          item.productCode !== product.productCode)
      ) {
        return item;
      }
    });

    setSelectedItemsComposition([...newSelectedItems, product]);
  };

  const onChange = useCallback(
    (item, index) => {
      if (controlBackStep) {
        setBackstep(false);
        return;
      }

      const stepList = steps;
      stepList[index]["item"] = item;

      setValue(stepList);
      newStep(item, index + 1);
    },
    [controlBackStep, newStep, setValue, steps]
  );

  const handleSubmit = useCallback(
    (data) => {
      const listItems = [...selectedItemsComposition];

      steps.forEach((step) => {
        if (
          !step.productWithoutGroup &&
          step.item &&
          (!step.item.nivel1 || step.item.nivel1.length === 0)
        ) {
          if (step.item) {
            listItems.push(step.item);
          }
        } else if(step.quantity > 1) {
          const flavorFound = step.listRadioButton.filter((flavor) => flavor.qtd && flavor.qtd > 0);

          if (flavorFound) {
            [].push.apply(listItems, flavorFound);
          }
        }
      });

      const quantityCombo  = (product?.orientedSale[0].orientedSaleSteps[0].orientedSaleGroup?.orientedSaleGroupProduct.length > 1 || product?.orientedSale[0].orientedSaleSteps.length > 1) ? Number(getQuantityCombo()) : 1;

      const sendRequest = (list) => {
        list.forEach(async (currentListCombo, indexNumlancto) => {
          onSubmit(currentListCombo, indexNumlancto);
        });
      };

      if (selectedTotalCombo === quantityCombo) {
        const allListCombo = selectedTotalCombo === 0 ? [listItems] : listCombo;
        sendRequest(allListCombo);
      } else {
        setListCombo((restList) => {
          const allList =
            restList.length > 0 ? [...restList, listItems] : [listItems];

          if (allList.length ===  quantityCombo) {
            sendRequest(allList);

            return restList;
          } else {
            const stepsFiltered = steps.map((currentOldStep) => ({
              ...currentOldStep,
              listRadioButton: currentOldStep.listRadioButton.map(
                (currentListRadio) => ({
                  ...currentListRadio,
                  qtd: 0,
                })
              ),
            }));

            setValue(stepsFiltered);

            setTimeout(() => {
              window.preventMultipleSubmit = false;
              data.goToStep(0);
            }, 200);

            return allList;
          }
        });
      }
    },
    [listCombo, onSubmit, setValue, steps, selectedItemsComposition]
  );

  const handleJump = useCallback(
    (index, submit, data) => {
      const stepList = steps;
      stepList[index]["item"] = undefined;
      if (stepList[index + 1] !== undefined && stepList[index + 1].subProduct) {
        if (
          index >= 2 &&
          index + 1 === steps.length - 1 &&
          stepList[index + 1].required
        ) {
          handleSubmit(data);
        }

        stepList.splice(index + 1, 1);
      }

      setValue(stepList);

      if (submit) {
        handleSubmit(data);
      } else {
        newStep(null, index + 1);
      }
    },
    [handleSubmit, newStep, setValue, steps]
  );

  const handlePrevious = useCallback(
    (index) => {
      if (index === 0) {
        props.backStepCategory();
        return;
      } else {
        setBackstep(true);
      }

      // etapa opcional sem item selecionado
      if (
        !steps[index - 1].required &&
        steps[index - 1].item === undefined &&
        steps[index - 1].listRadioButton.length > 0
      ) {
        // foi necessário forçar o onChange para carregar o subProduct da próxima etapa
        onChange(steps[index - 1].listRadioButton[0], index - 1);
      }
    },
    [onChange, props, steps]
  );

  const onChangeQuantity = useCallback(
    (items, index) => {
      if (items.length === 0) return;

      const stepList = steps;
      stepList[index].listRadioButton = items;

      setValue(stepList);
      setSelectedItems(items);
    },
    [setValue, steps]
  );

  const disabledNextProduct = useCallback((step) => {
    const quantity = step?.listRadioButton
      .filter((product) => product.qtd && product.qtd > 0)
      .reduce((acc, cur) => (acc = acc + cur.qtd), 0);
    return step?.required && step?.listRadioButton.length > 1  && (quantity === 0 || quantity < step?.quantity);
  }, []);

  const layoutRadioButtonList = (step, index) => {

    if (step.quantity) {
      return (
        <FlavorSelect
          title={product.description}
          listProducts={step.listRadioButton}
          maxQuantity={product?.orientedSale[0].orientedSaleSteps.length > 1 || step?.listRadioButton.length > 1 ? step.quantity : step.quantity * getQuantityCombo()}
          onChange={(selectedItems) => onChangeQuantity(selectedItems, index)}
          onSubmit={() => props.onSubmit(selectedItems)}
          required={step.required}
        />
      );
    }

    return (
      <RadioButtonList
        groupName={`combo_${index}`}
        description={"description"}
        displayValue={showFullValue ? "mixValue" : "lowerValue"}
        value={"_id"}
        key={`radio-list-${index}`}
        listRadioButton={step.listRadioButton}
        onChangeValue={(item) => onChange(item, index)}
        styleClass="list"
        defaultValue={step.item ? step.item : undefined}
      />
    );
  };
  const layoutCombo = (step, index) => {
    const image =
      step.quantity && step.productWithoutGroup
        ? step.listRadioButton[0].featuredImage
        : product.featuredImage;

    const imageQuantityEmpty =
      step.item && step.item.featuredImage
        ? step.item.featuredImage
        : step?.listRadioButton?.length > 0
        ? step.listRadioButton[0].featuredImage
        : null;

    if (step.composition) {
      return (
        <>
          <StepComposition
            steps={steps}
            listProducts={step.listRadioButton}
            onSubmit={() => props.onSubmit(selectedItemsComposition)}
            listProductsSelected={selectedItemsComposition}
            onAddItem={addItemSelectedComposition}
            handleRemoveItem={handleRemoveItemComposition}
          />
        </>
      );
    }

    return (
      <>
        {step.quantity && (
          <ImageProduct className="combo-individual-featured-image imgComboTablet">
            {product.featuredImage === null && image === null ? (
              <div className="no-image"></div>
            ) : (
              <img className="imgComboIndTablet" src={image} alt={image} />
            )}
          </ImageProduct>
        )}
        {!step.quantity && (
          <ImageProduct className="combo-individual-featured-image imgComboTablet">
            {imageQuantityEmpty ? (
              <img
                className="imgComboIndTablet"
                src={imageQuantityEmpty}
                alt={imageQuantityEmpty}
              />
            ) : (
              <div className="no-image"></div>
            )}
          </ImageProduct>
        )}
        {(!step.productWithoutGroup ||
          (step.index === 0 && step.quantity === null) ||
          (step.productWithoutGroup)) && (
          <RadioContainer className="combo-individual-products">{layoutRadioButtonList(step, index)}</RadioContainer>
        )}
      </>
    );
  };

  const disabledSubmit = () => {
    const items = steps
      .filter(
        (step) =>
          step.item && (!step.item.nivel1 || step.item.nivel1.length === 0)
      )
      .map((step) => step.item);

    const listRequired = steps
      .map((step) => step)
      .filter((step) => step.required)
      .map((step) => step.required);
    let step = steps[steps.length - 1];
    const flavorList = steps.filter((step) => {
      const quantity = step.listRadioButton
        .filter((product) => product.qtd && product.qtd > 0)
        .reduce((acc, cur) => (acc = acc + cur.qtd), 0);
        return (
          step?.listRadioButton.length > 1 ? step.quantity * getQuantityCombo()  : step.index > 0 ? step.quantity : step.quantity * getQuantityCombo() === quantity || (step.quantity === null && !step.required)
          );
        });
    if (!step.item && step.item !== null) {
      return flavorList.length === 0 && listRequired[0] !== undefined;
    }
    if (step.item !== null) {
      return (
        flavorList.length === 0 &&
        listRequired[0] !== undefined &&
        items.length === 0
      );
    }
  };

  const layoutStep = (step, index, onSubmit) => {
    const displayRequired = step.required || step.quantity > 1;
    const isSubmit = !!onSubmit || (!displayRequired && index >= 2 && index + 1 === steps.length - 1);

    const isRequiredInSingleProduct = step.required;
    const singleProductInVO = step?.listRadioButton?.length === 1;
    const quantityInSingleProductInVO = singleProductInVO ? step?.listRadioButton[0]?.qtd ?? 0 : null;
    const isDisabledButtonToAdd = isRequiredInSingleProduct && quantityInSingleProductInVO !== null && quantityInSingleProductInVO < step.quantity;

    return (
      <Step
        key={index}
        index={index + 1}
        title={step.description}
        onPrevious={() => handlePrevious(index)}
        isSubmit={isSubmit}
        onSubmit={
          isSubmit
          ? (data) => {
            handleSubmit(data);
          }
          : undefined
        }
        isDisabledButtonToAdd={isDisabledButtonToAdd}
        disabledSubmit={disabledSubmit()}
        displaySubmit={onSubmit}
        disableButton={step.quantity > 0 && disabledNextProduct(step)}
        disabledNext={step.quantity > 0 && disabledNextProduct(step)}
        onJump={(data) => handleJump(index, onSubmit, data)}
        displayRequired={displayRequired}
      >
        {layoutCombo(step, index)}
      </Step>
    );
  };

  return (
    <>
      <StepList>
        {steps.map((step, index) => {
          return layoutStep(step, index, index === steps.length - 1);
        })}
      </StepList>
    </>
  );
};

export default React.memo(ComboIndividual);
