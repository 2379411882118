import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background: radial-gradient(circle, #411e5a 0%, #2e0037 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  height: 100%;
  flex: 1;

  img {
    filter: drop-shadow(2px 4px 6px #000);
    max-width: 320px;
    width: auto;
  }

  .message {
    margin: 3rem auto;
    color: #ccc;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .spinner {
      margin-bottom: 2rem;
    }
  }
`;
