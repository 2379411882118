import React from "react";
import { FidelityTab, FidelityTabsContent } from "./styles";
import classNames from "classnames";

const FidelityTabs = ({ selectedTab, selectTab }) => {
  const Categories = [
    {
      name: "Promocode",
      icon: "pdv-one/reshopPromocode.svg",
    },
    // {
    //   name: "Resgate",
    //   icon: "pdv-one/reshopRescue.svg",
    // },
    {
      name: "Campanhas Opcionais",
      icon: "pdv-one/reshopOptionalCampaign.svg",
    },
    // {
    //   name: "Outros",
    //   icon: "pdv-one/reshopOthers.svg",
    // },
  ];

  return (
    <FidelityTabsContent>
      {Categories.map((category) => (
        <FidelityTab
          className={classNames({ active: selectedTab === category.name })}
          onClick={() => selectTab(category.name)}
        >
          <img
            style={{ width: "1.3rem", marginRight: "16px" }}
            src={category.icon}
            alt={category.name}
          />
          {category.name}
        </FidelityTab>
      ))}
    </FidelityTabsContent>
  );
};

export default FidelityTabs;
