import { v4 as uuidv4 } from "uuid";

export const printerActionService = (options) => {
  if (window.desktopApp) {
    if (options?.danfeBase64){
      window.desktopApp.publish("machine.devices.printerbase64", {
        fileName: "danfe",
        fileType: "pdf",
        base64: decodeURIComponent(options.danfeBase64.replace(/\+/g,  " ")),
        page: {width: 400, height: 800}
      });
    }
    else if (options?.printOverTCPSocket){
      window.desktopApp.publish("machine.devices.printOverTCPSocket", options);
    }
    else if (options?.danfeUrl){
      window.desktopApp.publish("machine.devices.downloadPDFAndPrintWithDotNet", {
        url: options.danfeUrl,
        identity: `danfe-${uuidv4()}`
      });
    }
    else if (options?.body) {
      window.desktopApp.publish("machine.devices.printer", {
        body: options.body,
        // printerName: options.printerName,
      });
    } else if (options?.saleControl && options?.errorWhenAuthorizing) {
      window.desktopApp.publish("machine.devices.printer", {
        body: `
      <div style="text-align: center">
          <h3>SENHA: ${options.saleControl}</h3>

          <div>A Venda foi registrada, mas ocorreu</div>
          <div> um problema para emitir a DANFE, </div>
          <div>guarde este comprovante e procure um caixa</div>
          </div>
      <br/>
      <br/>
      .
      `,
      });
    } else if (!options?.errorWhenAuthorizing) {
      window.desktopApp.publish("machine.devices.printerpdf", {
        fileName: `${options.pathDocFiscal}`,
      });
    }
  } else if (window.Android && options.danfeBase64) {
    console.log(`window.Android.printerNfceBase64 | {}`);
    window.Android.printerNfceBase64(decodeURIComponent(options.danfeBase64.replace(/\+/g,  " ")));

  } else if (window.Android && options.danfeUrl) {
    console.log(`window.Android.printerNfce | {danfeUrl: ${options.danfeUrl}}`);
    window.Android.printerNfce(options.danfeUrl);
  }
};
