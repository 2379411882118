import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
// import { getSetting } from '../services/userSettings';
import KeyboardSingle from "../../components/keyboard-single";
import { isSmartPos } from "../../../aaone/configuration";

const KeyboardContext = React.createContext();

var inputsToGetFocus = ["input", "textarea"]; // ...'select', 'button', 'textarea'

export const KeyboardProvider = ({ children }) => {
  const [input, setInput] = useState(null);
  const [keyboardIsVisible, setKeyboardIsVisible] = useState(false);
  const [onScreenKeyboardPosition, setOnScreenKeyboardPosition] = useState({
    y: "bottom",
    x: "right",
  }); // y: top | bottom; x: left | right;
  const [layoutName, setLayoutName] = useState("default");

  const HandleFocus = () => {
    const element = document?.activeElement;
    const IsElementValid =
      inputsToGetFocus.indexOf(element.tagName.toLowerCase()) !== -1 &&
      element.getAttribute("data-use-touch-keyboard");

    if (element && IsElementValid) {
      setInput(element);

      setKeyboardIsVisible(isSmartPos ? false : true);
    }

    const y = element.getAttribute("data-y-pos");

    if (y && (y === "top" || y === "bottom")) {
      setOnScreenKeyboardPosition((prevPosition) => ({
        ...prevPosition,
        y,
      }));
    }

    const x = element.getAttribute("data-x-pos");

    if (x && (x === "left" || x === "right")) {
      setOnScreenKeyboardPosition((prevPosition) => ({
        ...prevPosition,
        x,
      }));
    }

    const layoutNameEl = element.getAttribute("layout-name");

    if (layoutNameEl !== null && layoutName !== layoutNameEl) {
      setLayoutName(layoutNameEl);
    } else {
      setLayoutName("default");
    }
  };

  const HandleBlur = (event) => {
    setInput((prevInput) => {
      if (event.target === prevInput) {
        prevInput.blur();
        return null;
      }
      return prevInput;
    });
  };

  useEffect(() => {
    document.addEventListener("focus", HandleFocus, true);
    document.addEventListener("click", HandleFocus, true);
    document.addEventListener("blur", HandleBlur, true);

    return () => {
      document.removeEventListener("focus", HandleFocus);
      document.addEventListener("click", HandleFocus);
      document.removeEventListener("blur", HandleBlur);
    };
    // }
  }, []);

  useEffect(() => {
    if (keyboardIsVisible && !input) {
      setKeyboardIsVisible(false);
    }
  }, [keyboardIsVisible, input]);

  const DefocusOnInputElement = useCallback(() => {
    setInput((prevInput) => {
      if (prevInput) {
        prevInput.blur();
      }
      return null;
    });
  }, []);

  const HandleOnChangeInputElement = useCallback((value) => {
    let inputElement = null;

    setInput((prevInput) => {
      inputElement = prevInput;
      return prevInput;
    });

    if (inputElement) {
      let newValue = value;

      // Se a quantidade de caracteres do novo valor for maior que
      // a quantidade permitida (maxLength definido no input), então preserve o valor atual do input.

      if (
        typeof inputElement?.maxLength === "number" &&
        inputElement.maxLength > 0 &&
        value.length > inputElement.maxLength
      ) {
        newValue = inputElement.value;
      }

      const valueSetter = Object.getOwnPropertyDescriptor(
        window[inputElement.constructor.name].prototype,
        "value"
      ).set;
      valueSetter.call(inputElement, newValue);

      var event = new Event("input", { bubbles: true });
      inputElement.dispatchEvent(event);
    }
  }, []);

  const value = useMemo(
    () => ({
      input,
      keyboardIsVisible,
      onScreenKeyboardPosition,
      DefocusOnInputElement,
      HandleOnChangeInputElement,
      layoutName,
    }),
    [
      input,
      keyboardIsVisible,
      onScreenKeyboardPosition,
      DefocusOnInputElement,
      HandleOnChangeInputElement,
      layoutName,
    ]
  );

  return (
    <KeyboardContext.Provider value={value}>
      <KeyboardSingle />
      {children}
    </KeyboardContext.Provider>
  );
};

export const useKeyboard = () => useContext(KeyboardContext);
