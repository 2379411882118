import styled from "styled-components";
import { isSmartPos } from "../../../aaone/configuration";

export const CashSupplyWrapper = styled.div`
  display: flex;
  width: 100%;

  .keyboard-cashier-supply {
    border: none;
    position: relative;
  }

  .simple-keyboard.hg-theme-default.myThemeNumeric {
    border: none;
    width: 270px;
  }

  .card-cashier-supply {
    flex: 1;
    margin-left: 15px;
  }

  .cash-supply {
    flex: 1;
  }

  input {
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    border: 1px solid rgb(200, 203, 208);
    border-radius: 4px;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 25px;
  }

  #card {
    position: relative;
    min-height: 55px;

    #btn-close {
      right: 50px;
    }
  }

  .btn-supply {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    max-width: inherit;
    font-size: 0.9rem;
    outline: none;
    border: none;
    margin-top: 10px;
    padding: 7px 15px;
    background: var(--highlight-color);
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    &[disabled] {
      opacity: 0.35;
    }
  }

  #cardList {
    position: relative;

    .amount-list {
      position: absolute;
      &.smart-pos {
        left: 20px;
        top: 65px;
      }

      &.pdv-windows {
        top: 24px;
      }
    }
  }

  .content-cell {
    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      margin-right: 4px;
    }
  }

  &.isSmartPos {
    flex-direction: column;

    .keyboard-cashier-supply {
      display: none;
    }

    .cash-supply .card-body form {
      display: flex;
      flex-direction: column;

      .btn-custom {
        margin: 1rem 0.625rem 0;
      }
    }
  }
`;

export const InputGroup = styled.div`
  padding: 10px 40px 0 10px;
  margin: 11px 0 3px 0;

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    padding: 10px 10px 0;
    margin: 1px;
  }

  span {
    color: #fe2e2e;
    height: 25px;
  }

  small {
    color: #888484;
  }
`;

export const ConfirmFormActions = styled.div`
  .btn-custom {
    width: 130px;
    height: 50px;
    margin: 45px 40px 0 10px;
  }

  grid-column-start: 2;
  text-align: right;

  &.isSmartPos {
    text-align: center;
  }
`;

export const AgGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .ag-theme-balham {
    margin-top: 10px;
    height: 465px;
    min-height: 465px;

    padding-right: 15px;
    width: 98%;

    .ag-header {
      background: #e7eaf1;
    }
  }
`;

export const AgGridActions = styled.section`
  display: flex;

  .btn-card-list {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    max-width: inherit;
    font-size: 0.9rem !important;
    outline: none;
    border: none;
    padding: 7px 15px !important;
    background: #411e5a;
    color: #fff;
    margin-right: 3px !important;
    border-radius: 4px !important;

    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      flex-direction: column;
      font-size: smaller;
    }

    &[disabled] {
      opacity: 0.35;
    }
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 130px;
  right: 10px;
  bottom: 40px;
  flex-direction: column;
  position: absolute;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;

export const GroupButton = styled.div`
  margin-bottom: ${(props) => (props.tableCardList ? "40px" : "0")};
  margin-right: ${(props) => props.tableCardList && "30px"} !important;

  .btn-actions {
    min-width: 39px;
    min-height: 34px;

    i {
      width: 24px;
      heigth: 24px;
    }
  }
`;
