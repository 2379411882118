import OrientedSaleUseCase from "./OrientedSaleUseCase";
import CompositionSaleUseCase from "./CompositionSaleUseCase";
import UtilsUseCase from "../UtilsUseCase";

export default class OrientedSaleStepUseCase {
  #rawSteps;
  #steps;
  #CompositionSale;
  #isOnlyLevel;
  #chosenQuantity;
  #classSale;
  #selectedEnvironment;

  constructor(
    rawSteps,
    chosenQuantity,
    ClassSale,
    selectedEnvironment,
  ) {
    this.#rawSteps = rawSteps;
    this.#steps = [];
    this.#CompositionSale = null;
    this.#isOnlyLevel = false;
    this.#chosenQuantity = chosenQuantity;
    this.#classSale = ClassSale;
    this.#selectedEnvironment = selectedEnvironment;

    this.initialization();
  }

  initialization() {
    this.initializationCompositionSale();
    this.adjustSteps();
  }

  next() {
    let activeStep = 0;

    this.#steps.forEach((step, index) => {
      if (activeStep === 1 && step.required) {
        step.current = true;
        activeStep = 2;
      }

      if (
        step.current &&
        activeStep === 0 &&
        this.#steps[index + 1] !== undefined
      ) {
        step.current = false;
        activeStep = 1;
      }
    });

    if (activeStep === 0) {
      if (this.#steps[0] && this.#steps[0].required) {
        this.#steps[0].current = true;
      }
    }
  }

  back() {
    let activeStep = false;

    this.#steps.forEach((step, index) => {
      if (step.current && activeStep === false) {
        step.current = false;
        activeStep = true;
      }

      if (activeStep) {
        if (this.#steps[index - 1] !== undefined) {
          this.#steps[index - 1].current = true;
          activeStep = false;
        }
      }
    });
  }

  goTo(currentSequence = null) {
    this.#steps.forEach((step) => {
      step.current = step.sequence === currentSequence;

      if (!step.current && step.selected && step.selected.length) {
        step.selected.forEach(stepSelected => {
          if (stepSelected?.ClassSaleStep) {
            stepSelected.ClassSaleStep.goToStep(currentSequence);
          }
        });
      }
    });
  }

  removeSelectedStep() {
    this.#steps.forEach(step => step.current = false);

    this.#steps.forEach(step => {
      if (step.selected && step.selected.length) {
        step.selected.forEach(stepSelected => {
          if (stepSelected?.ClassSaleStep) {
            stepSelected.ClassSaleStep.removeSelectedStep();
          }
        });
      }
    });
  }

  exitSteps() {
    this.#steps.forEach((step) => {
      step.current = false;
    });
  }

  getIsOnlyLevel() {
    return this.#isOnlyLevel;
  }

  checkDoneSteps() {
    let stepsCompleted = true;
    this.#steps.forEach((step) => {
      if (step.quantity !== step.quantitySelected && step.required) {
        stepsCompleted = false;
      }
    });

    return stepsCompleted;
  }

  getSteps() {
    return this.#steps ?? [];
  }

  getCurrentStep() {
    let currentStep = this.#steps.find(step => step.current);

    if (!currentStep) {
      this.#steps.forEach(step => {
        if (!step.current && step.selected && step.selected.length) {
          step.selected.forEach(stepSelected => {
            if (stepSelected?.ClassSaleStep) {
              currentStep = stepSelected.ClassSaleStep.getCurrentStep();
            }
          });
        }
      });
    }

    return currentStep;
  }

  selectedProductCurrentStep(product, amount = 1) {
    this.#steps.forEach((step) => {
      if (step.current && step.composition === false) {
        if (step.quantitySelected + amount > step.quantity) {
          amount = (step.quantitySelected - step.quantity) * -1;
        }

        if (step.quantitySelected + amount <= step.quantity && amount !== 0) {
          const findProductSelected = step.selected.find(
            (selectedItem) => selectedItem === product
          );
          if (!findProductSelected) {
            step.selected.push(product);
          }

          step.quantitySelected += amount;
          product.amount += amount;
          product.orientedSaleClass.selectProduct(product);
        }
      } else if(!step.current && step.selected && step.selected.length) {
        step.selected.forEach(stepSelected => {
          if (stepSelected?.ClassSaleStep) {
            stepSelected.ClassSaleStep.selectedProductCurrentStep(product, amount);
          }
        });
      }
    });
  }

  deselectProductStep(sequence = null, deselectProduct) {
    this.#steps.forEach((step) => {
      if (step.sequence === sequence) {
        step.products.forEach((productUp) => {
          let product = null;

          if (!!productUp.nextLevel.length) {
            productUp.nextLevel.forEach((productDown) => {
              if (productDown.productCode === deselectProduct.productCode) {
                product = productDown;
              }
            });
          } else {
            if (productUp.productCode === deselectProduct.productCode) {
              product = productUp;
            }
          }

          if (product) {
            deselectProduct.orientedSaleClass.deselectProduct(deselectProduct);
            if (step.quantitySelected > 0) {
              step.quantitySelected -= 1;
            }

            if (deselectProduct?.ClassSaleStep) {
              const stepsChildrens = deselectProduct?.ClassSaleStep.getSteps().steps;
              stepsChildrens.forEach((stepChildren) => {
                stepChildren.current = false;
                stepChildren.selected.forEach((productSelectedChildren) => {
                  stepChildren.classSale.deselectProductStep(stepChildren.sequence, productSelectedChildren, true);
                });
              });
            }

            if (product.amount === 0) {
              step.current = false;
              step.selected = step.selected.filter(
                (itemSelected) => itemSelected !== deselectProduct
              );
            }
          }
        });
      } else {
        step.selected.forEach(stepSelected => {
          if (stepSelected?.ClassSaleStep) {
            stepSelected.ClassSaleStep.deselectProductStep(sequence, deselectProduct);
          }
        });
      }
    });
  }

  doubleProductQuantities() {
    this.#steps.forEach((step) => {
      if (!step.duplicated.status) {
        step.duplicated.status = true;
      }

      step.quantity += step.duplicated.originalQuantity;
    });
  }

  adjustSteps() {
    const Utils = new UtilsUseCase();
    let nextLevelPositionName = Utils.getLevelNameInProduct(this.#rawSteps);

    if (this.#rawSteps[nextLevelPositionName] === undefined) {
      nextLevelPositionName = 'level';
    }

    if (this.#rawSteps[nextLevelPositionName] || this.#rawSteps.level) {
      if (!!this.#rawSteps[nextLevelPositionName]?.length) {
        this.#isOnlyLevel = true;
      }
    }

    if (!!this.#rawSteps.orientedSale && !!this.#rawSteps.orientedSale.length) {
      this.#rawSteps = this.#rawSteps.orientedSale[0];
    } else if (!this.#rawSteps.orientedSaleSteps || !this.#rawSteps.orientedSaleSteps.length) {
      this.#rawSteps.orientedSaleSteps = [];
    }

    let steps = [];
    const creatingStepsProducts = (infoStep, step, products) => {
      const sequence = `${window.performance.now()}${steps.length + 1}`.replace(".", "");
      const OrientedSale = new OrientedSaleUseCase(
        products,
        sequence,
        false,
        this,
        this.#selectedEnvironment
      );

      const isFractionalQuantity = !!this.#rawSteps?.fractionalQuantity ?? false;

      return {
        description: infoStep.description,
        quantity: !isFractionalQuantity ? step.quantity * this.#chosenQuantity : step.quantity,
        sequence,
        current: step.required,
        selected: [],
        quantitySelected: 0,
        required: step.required,
        composition: false,
        products: OrientedSale.getCurrentProducts(),
        duplicated: {
          status: false,
          originalQuantity: step.quantity,
        },
        classOrientedSaleStep: this,
        classSale: this.#classSale,
      };
    };

    if (!!this.#rawSteps?.orientedSaleSteps?.length) {
      /**
       * TODO Creation of composition step. It's not 100%.
       */
      // const compositionStep = this.#CompositionSale.getStepComposition();

      // if (!!Object.keys(compositionStep).length) {
      //   steps.push(compositionStep);
      // }

      this.#rawSteps.orientedSaleSteps.forEach((step) => {
        const infoStep =
          step.orientedSaleGroup === undefined
            ? step.productWithoutGroup
            : step.orientedSaleGroup;
        let products;
        if (step.orientedSaleGroup) {
          products = step.orientedSaleGroup.orientedSaleGroupProduct;
        } else {
          products = step.productWithoutGroup ?? [];
        }

        if (infoStep && step && products) {
          steps.push(creatingStepsProducts(infoStep, step, products));
        }
      });
    } else if (!!this.#rawSteps[nextLevelPositionName]?.length) {
      const infoStep = { description: "Escolha seu complemento" };
      const step = { quantity: 1, required: true };

      steps.push(
        creatingStepsProducts(
          infoStep,
          step,
          this.#rawSteps[nextLevelPositionName]
        )
      );
    } else if (this.#rawSteps?.steps?.length) {
      steps = this.#rawSteps.steps;
    }

    this.#steps = steps;
  }

  getChosenQuantity() {
    return this.#chosenQuantity;
  }

  getClassSale() {
    return this.#classSale;
  }

  initializationCompositionSale() {
    this.#CompositionSale = new CompositionSaleUseCase(this.#rawSteps);
  }
}
