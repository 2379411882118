import React, {memo, useState, useEffect} from "react";
import { currency } from  "../../utils/currency";
import {
  Content,
  Items,
  Item,
  Image,
  ContentCount,
  SelectionFlag,
  TitleItem
} from "./style.js";

const CompositionItemAdd= (props) => {
  const {
    compositions,
    onChangeItem,
    selectedProduct
  } = props;
  const [items, setItems] = useState(compositions);

  const handleClickCount = (event, index) => {
    const conditionIncrease = event.target.classList.contains("increase");
    const newItems = [...items];
    const newProductSelected = {...selectedProduct};

    if (conditionIncrease) newItems[index].selectedAmount++;
    else newItems[index].selectedAmount--;

    newProductSelected.compositions[index] = newItems[index];
    onChangeItem(newProductSelected);
    setItems(newItems);
  }

  const generateItem = () => {
    const listsElements = [];

    items.map((item, index) => {
      if (item.operationType === "A") {
        let classImage = item.selectedAmount ? "" : "no-active";
        let classActive = item.selectedAmount ? "active" : "";

        let disabledDecreaseButton = item.selectedAmount >= 1 ? false : true;

        listsElements.push(<Item key={index} className={classActive}>
          <SelectionFlag key={`selection-flag-${index}`} className={classActive} />
          <input key={`input-hidden-${index}`} type="checkbox"/>
          <Image key={`image-${index}`} className={`image-product ${classImage}`} src={item.product.image} alt={item.product.image}/>
          <div key={`div-${index}`}>
            <TitleItem key={`p-title-${index}`} className={`${classActive} composition-tittl`}>{item.product.description}</TitleItem>
            <p key={`p-price-${index}`} className="composition-increase-price">+ {currency(item.product.value)}</p>
          </div>
          <ContentCount key={`content-count-${index}`} className="composition-content-count">
            <button
              key={`button-decrease-${index}`}
              className="composition-button-count decrease"
              onClick={(event) => handleClickCount(event, index)}
              disabled={disabledDecreaseButton}
            >
              &#8722;
            </button>
            <span key={`quantity-${index}`} className="composition-quantity"> {item.selectedAmount} </span>
            <button
              key={`button-increase-${index}`}
              className="composition-button-count increase"
              onClick={(event) => handleClickCount(event, index)}
            >
              &#43;
            </button>
          </ContentCount>
        </Item>)
      }
    })
    return listsElements;
  };

  return (
    <>
      <Content>
        <Items key={1}>
        {generateItem()}
        </Items>
      </Content>
    </>
  );
}
export default memo(CompositionItemAdd);
