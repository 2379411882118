import styled from "styled-components";

export const ContentInfoNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 13px;
  margin-top: 5px;
`;

export const ContentKeyAccess = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 13px;

  margin-top: 5px;
  margin-bottom: 5px;

  word-break: break-all;
`;

export const ItemsContentNote = styled.div`
  border: 1px solid #000000;
  border-radius: 6px;

  padding: 5px;

  display: flex;
  flex-direction: column;

  margin-top: 5px;

  font-size: 12px;
`;

export const ResumeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 50px;
`;

export const Sumary = styled.div`
  width: 40%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  hr {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;
