import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  &.isSmartPos {
    > div {
      &,
      > #card {
        height: 100%;
      }
    }
  }
`;

export const ProductsUnavailableWrapper = styled.div`
  display: flex;
  height: 100%;

  &.isSmartPos {
    display: block;
    flex-direction: column;
    margin: 1.25rem 0px;
  }
`;

export const ProductLevelWrapper = styled.div`
  background: #e8204d;
  color: #fff;
  text-align: center;
  width: calc(100% - 16px);
  border-radius: 4px;
  width: 100%;

  &.primário {
    background: #4594f2;
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 190px;
  bottom: 60px;
  flex-direction: column;
  position: absolute;
  right: 37px;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;

export const SwitchWrapper = styled.div`
  padding: 0 8px;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #411e5a;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #411e5a;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
