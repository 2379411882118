import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Outlet, useLocation } from "react-router-dom";

import { useWrapperRouters } from "../../hook/wrapperRoutersHook";

import { SidebarCart } from "../SidebarCart";

import { Container } from "./styles";
import { useCart } from "../../hook/cartHook";
import { isSmartPos } from "../../../aaone/configuration";
import { PagesEnum } from "../../constants/pagesEnum";

export const ContainerContentWrapper = () => {
  const contentRef = useRef(null);
  const { isSidebarCartHide, isSidebarRight } = useWrapperRouters();
  const location = useLocation();

  const {
    itemsInCart,
    isCartVisible,
    setIsCartVisible,
    currentProductStep,
    oldCurrentProductStepContainer,
    setOldCurrentProductStepContainer,
  } = useCart();

  useEffect(() => {
    if (
      isSmartPos &&
      currentProductStep?.length > 0 &&
      currentProductStep !== oldCurrentProductStepContainer &&
      isCartVisible
    ) {
      setOldCurrentProductStepContainer(currentProductStep);
      setIsCartVisible(false);
    }
  }, [currentProductStep, oldCurrentProductStepContainer, isCartVisible]);

  useEffect(() => {
    if (contentRef?.current) {
      const menuHeight = `${
        document.querySelector(".header-main-top")?.clientHeight ?? 0
      }px`;

      contentRef.current.setAttribute(
        "style",
        `height: calc( 100vh - ${menuHeight} ) !important;`
      );

      document
        .querySelector("body")
        .style.setProperty("--menu-height", menuHeight);
    }
  }, [contentRef, isCartVisible]);

  return (
    <>
      <Container
        className={classNames({
          isSidebarCartHide,
          isSidebarRight,
          isSmartPos,
        })}
      >
        {isSmartPos ? <div className="display__hide" /> : <SidebarCart />}
        <div
          ref={contentRef}
          className={classNames(
            {
              isHide:
                isSmartPos &&
                isCartVisible &&
                itemsInCart.length > 0 &&
                location.pathname !== PagesEnum.NEED_AUTH,
            },
            "content"
          )}
        >
          <Outlet />
        </div>
      </Container>
    </>
  );
};
