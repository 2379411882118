import { RESHOP_DISCOUNTS } from "../../pdv-one/constants/keyNamesSessionStorage";

export const getDiscountsReshopService = () => {
  const discountsReshop = sessionStorage.getItem(RESHOP_DISCOUNTS);

  if (discountsReshop) {
    try {
      const data = JSON.parse(discountsReshop);

      return data ? data : null;
    } catch (error) {
      return null;
    }
  }

  return null;
};
