/**
 * @param item {Object}
 * @returns {string|undefined}
 */
export const returnsLevelOrientedSale = (item) => {
  return Object.keys(item).find((position) => {
    if (position.includes('nivel'))
      return position;
    else
      return undefined;
  });
}

/**
 * @param item {Object}
 * @returns {Object|null}
 */
export const getNivelInItem = (item) => {
  const level = returnsLevelOrientedSale(item);

  if(typeof level !== "undefined"){
    return item[level]?.length > 0 ? item[level] : null;
  }

  return null;
}
