import styled from "styled-components";

export const FidelityWrapper = styled.div`
  background-color: #f2f4f7;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const FidelityContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;

export const FidelityHeader = styled.div``;
