import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  height: 100vh;
  overflow-y: hidden;
`;

const NavWrapper = styled.nav`
  background-color: var(--color-background-light);

  overflow-y: auto;
  padding: 0em 0 10em;
  width: 220px;
  flex-shrink: 0;

  .listCategory {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    list-style: none;
    height: 100%;
    padding: 0;
    margin: 0;
  }
`;

const ContentWrapper = styled.main`
  overflow-y: hidden;
  padding: 0 25px 0;
  position: relative;
  height: 88vh;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .gridscroll {
    position: static;
    flex-grow: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 1em 0 2em;
    margin-top 20px !important;
  }

  .blackout {
    height: 100%;
    width: 80%;
    position: fixed;
    left: 20%;
    z-index: 3;
  }

  .filters + .groupSales,
  .wrapper-button + .groupSales {
    max-height: calc(100vh - 700px);
  }

  .groupSales {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    padding: 0 20px;

    &.group-sales-rows {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;

      .groupSales-item img,
      .groupSales-item .no-image {
        padding: 0em 2em;
        width: 525px;
        margin: auto;
        height: 260px;
      }
    }
  }

  .gridscroll::-webkit-scrollbar-track {
    background-color: var(--color-background-light);
  }

  .gridscroll::-webkit-scrollbar {
    width: 20px;
  }

  .gridscroll::-webkit-scrollbar-thumb {
    border-radius: 20px 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.8);
  }
`;

export { SectionWrapper, NavWrapper, ContentWrapper };
