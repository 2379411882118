import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { CardWrapper, CardTitle, CardContent, CardMain } from "./style";
import classNames from "classnames";
import { isSmartPos } from "../../../aaone/configuration";

const actions = [
  {
    label: "ATUALIZAR",
    icon: "autorenew",
    propToShow: "refreshEnable",
    action: "handleRefreshClick",
  },
  {
    label: "VOLTAR",
    icon: "arrow_back",
    propToShow: "closeEnable",
    rootKey: "",
    action: "handleClose",
  },
  {
    label: "FECHAR",
    icon: "close",
    propToShow: "alternativeCloseEnable",
    rootKey: "",
    action: "handleClose",
  },
  {
    label: "CANCELAR",
    icon: "close",
    propToShow: "cancelEnable",
    rootKey: "",
    action: "handleClose",
  },
];

export class Card extends React.Component {
  state = {
    hasScroll: false,
    disabledTopScroll: false,
    disabledBottomScroll: false,
  };

  constructor(props) {
    super(props);

    this.handleHidden = this.handleHidden.bind(this);
    this.top = this.top.bind(this);
    this.bottom = this.bottom.bind(this);
  }

  hasScroll() {
    this.hasScrollElement(
      document.querySelector(`#${this.props.id} .card-body`)
    );

    if (!this.state.hasScroll)
      this.hasScrollElement(document.querySelector(`#${this.props.id} .table`));
  }

  hasScrollElement = (scrollElement) => {
    if (scrollElement)
      this.setState({
        ...this.state,
        hasScroll: scrollElement.scrollHeight > scrollElement.clientHeight,
      });
  };

  bottom() {
    const scrollElement = document.querySelector(
      `#${this.props.id} .card-body`
    );
    scrollElement.scrollTo(0, scrollElement.scrollTop + 30);

    const tableElement = document.querySelector(`#${this.props.id} .table`);
    if (tableElement) tableElement.scrollTo(0, tableElement.scrollTop + 30);
  }

  top() {
    const scrollElement = document.querySelector(
      `#${this.props.id} .card-body`
    );
    scrollElement.scrollTo(0, scrollElement.scrollTop - 30);

    const tableElement = document.querySelector(`#${this.props.id} .table`);
    if (tableElement) tableElement.scrollTo(0, tableElement.scrollTop - 30);
  }

  handleHidden() {
    this.props.handleHidden();
    this.hasScroll();
  }

  render() {
    const selectedActions = actions.filter(
      (action) => this.props[action.propToShow]
    );
    const { controlScroll, fullHeight } = this.props;

    const className = classNames(
      {
        "control-card-scroll": controlScroll ?? "",
        "full-height": fullHeight,
      },
      this.props.classNameMain
    );

    return (
      <CardMain className={className}>
        <CardWrapper id={this.props.id} className={this.props.className}>
          {this.state.hasScroll &&
            this.props.className !== "expand-card" &&
            controlScroll && (
              <button
                type="button"
                className="btn-pagination btn-pagination--top"
                onClick={this.top}
              >
                <span className="material-icons">expand_less</span>
              </button>
            )}
          {this.props.handleHidden && (
            <button
              type="button"
              className="btn-arrow"
              disabled={
                this.props.disabledBtnArrow ||
                this.props.className !== "expand-card"
              }
              onClick={this.handleHidden}
            >
              <i className="material-icons">
                {this.props.className === "expand-card"
                  ? "expand_more"
                  : "expand_less"}
              </i>
            </button>
          )}
          {this.props.title && (
            <CardTitle
              className={classNames({ close__container__card: isSmartPos })}
            >
              {this.props.urlIcone && <img src={this.props.urlIcone} alt="" />}

              <h1 className="title" align={this.props.align}>
                <div>
                  {this.props.title}
                  <span className="sub-title">{this.props.subTitle}</span>
                </div>
              </h1>

              {selectedActions.map((action) => {
                const onClick = () => this.props[action.action]();

                return (
                  <Fragment key={action.label}>
                    {/* {action.rootKey && (
                      <KeyboardEventHandler
                        handleKeys={[action.rootKey]}
                        handleFocusableElements
                        onKeyEvent={onClick}
                      />
                    )} */}
                    <button type="button" id="btn-close" onClick={onClick}>
                      {isSmartPos ? (
                        <strong>
                          <i className="material-icons">{action.icon}</i>
                        </strong>
                      ) : (
                        <>
                          <strong>
                            <i className="material-icons">{action.icon}</i>
                          </strong>
                          <p>{action.label}</p>
                        </>
                      )}
                    </button>
                  </Fragment>
                );
              })}
            </CardTitle>
          )}
          <CardContent className="card-body">{this.props.children}</CardContent>

          {this.state.hasScroll &&
            this.props.className !== "expand-card" &&
            controlScroll && (
              <button
                type="button"
                className="btn-pagination btn-pagination--bottom"
                onClick={this.bottom}
              >
                <span className="material-icons">expand_more</span>
              </button>
            )}
        </CardWrapper>
      </CardMain>
    );
  }
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  align: PropTypes.string,
  id: PropTypes.string,
  urlIcone: PropTypes.string,
  handleClick: PropTypes.func, // user by reflection
  closeEnable: PropTypes.bool, // user by reflection
  refreshEnable: PropTypes.bool, // user by reflection
  handleRefreshClick: PropTypes.func, // user by reflection
  className: PropTypes.string,
  classNameMain: PropTypes.string,
  handleHidden: PropTypes.func,
  controlScroll: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  urlIcone: null,
  title: null,
  subTitle: null,
  align: null,
  id: null,
  handleClick: null,
  closeEnable: false,
  refreshEnable: null,
  handleRefreshClick: null,
  className: null,
  handleHidden: null,
  controlScroll: "",
};

export default Card;
