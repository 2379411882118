import React, { useCallback, useEffect, useState } from "react";
import { InutilizaNotes, MonitorNotes } from "../../components/modules";
import { NoteDetails } from "../../components/monitor-notes/detalhes";
import { currencyString } from "../../helpers/masks";
import {
  cancelOrderService,
  getListMonitorNotes,
  updatePaymentCancel,
  inutilizarNumeracaoService,
  reauthorizeOrder,
} from "../../../services/orderService";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import {
  getFiscalFlowEndpoint,
  getFiscalType,
  getRetailerId,
} from "../../../aaone/configuration";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hook/authHook";
import { useDialog } from "../../hook/dialogHook";
import InfoModalContainer from "../../components/monitor-notes/info-modal";
import { useLoading } from "../../hook/loadingHook";
import { TransactionCode } from "../../constants/transactionCode";
import { cancelPaymentService } from "../../../services/linxPayServices";
import { transactionData } from "../../../aaone/shared/utils/transactionNumber";
import { useCashdesk } from "../../hook/cashdeskHook";
import { handlePrintCoupon } from "../../helpers/printer/salesReturn";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { apiProducts } from "../../../services/api";
import { getCashdeskById } from "../../../services/cashdeskService";

export const MonitorNotesContainer = (props) => {
  const systemParams = getSystemParamsHelper();

  const [documentNumber] = useState(systemParams?.storeDetails?.cnpj);
  const fiscalType = getFiscalType();

  const [openInutilizacao, setOpenInutilizacao] = React.useState(false);
  const [openNoteDetails, setOpenNoteDetails] = React.useState(null);
  const [itemsOrders, setItemsOrders] = useState([]);
  const [currentList, setCurrentList] = React.useState([]); // Listagem dos pedidos em processamento
  const [, setListOrderProcessing] = React.useState([]);
  const [filters, setFilters] = useState({});
  const [infoModal, setinfoModal] = useState({});
  const [operator, setOperator] = useState({});
  const [manager, setManagar] = useState();

  const cashdesk = useCashdesk();
  const location = useLocation();
  const auth = useAuth();
  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();

  useEffect(() => {
    const propsLocation = location?.state;

    let manager = propsLocation?.auth
      ? propsLocation.auth.user
      : auth.userAuthData;

    let operator = {
      user: cashdesk.user,
      referenceId: cashdesk.userReferenceId,
    };

    setOperator(operator);
    setManagar(manager);
  }, []);

  useEffect(() => {
    if (window.desktopApp) {
      window.desktopApp.subscribe(
        "machine.devices.coupon.reprint.completed",
        handleReprintCouponCompleted
      );
      window.desktopApp.subscribe(
        "machine.devices.coupon.reprint.error",
        handleReprintCouponError
      );
    }

    return () => {
      if (window.desktopApp) {
        window.desktopApp.unsubscribe(
          "machine.devices.coupon.reprint.completed"
        );
        window.desktopApp.unsubscribe("machine.devices.coupon.reprint.error");
      }
    };
  }, []);

  // Modal de Informações de autorização de notas
  const showInfoModal = (payload) => {
    setinfoModal(payload);
  };

  const getStatusGrid = (fiscalStatus, orderStatus) => {
    const itemsStatus = {
      1: "Autorizada",
      2: "Contingência",
      3: "Contingência Rejeitada",
      4: "Cancelada",
      7: "Inutilizada",
      8: "Erro na emissão",
      10: "Pendente",
      11: "Devolvida",
    };

    if (fiscalStatus === 1 && orderStatus === 10) {
      return itemsStatus[11];
    }

    return itemsStatus[fiscalStatus];
  };

  const listItems = useCallback(() => {
    return itemsOrders.map((item) => ({
      ...item,
      protocolo: item.fiscalCanceledDocument?.protocol
        ? item.fiscalCanceledDocument.protocol
        : item.fiscalDocument.protocol,
      dtAtendimento: formatDateHelper(
        new Date(item.createdAt),
        "dd/MM/yyyy HH:mm:ss"
      ),
      dtAutorizacao: formatDateHelper(
        new Date(
          item.fiscalCanceledDocument?.returnDate
            ? item.fiscalCanceledDocument.returnDate
            : item.fiscalDocument.returnDate
        ),
        "dd/MM/yyyy HH:mm:ss"
      ),
      status: getStatusGrid(item.fiscalStatus, item.status),
      motivo: item.fiscalCanceledDocument?.reason
        ? item?.fiscalCanceledDocument?.reason
        : item?.fiscalDocument?.reason?.replace(/^\[.*]: /g, ""),
      motivoDetalhes: item.fiscalCanceledDocument?.reason
        ? `[${item?.fiscalCanceledDocument.status}] ${item?.fiscalCanceledDocument?.reason}`
        : `[${
            item?.fiscalDocument.status
          }] ${item?.fiscalDocument?.reason?.replace(/^\[.*]: /g, "")}`,
      keyaccess: item.fiscalCanceledDocument?.accessKey
        ? item.fiscalCanceledDocument?.accessKey
        : item.fiscalDocument.accessKey ?? "----",
      urlDanfe: item.fiscalDocument.danfeUrl ?? "----",
      isChecked: false,
      total: item.total && currencyString(item.total),
      totalDiscount: item.totalDiscount && currencyString(item.totalDiscount),
      statusNumber: item.status,
      cnpj: documentNumber,
      receiptNumber:
        item.fiscalDocument.fiscalType == 2
          ? item.fiscalDocument.satReceiptNumber
          : item.receiptNumber,
      receiptSeries:
        item.fiscalDocument.fiscalType == 2
          ? item.fiscalDocument.satReceiptSeries
          : item.receiptSeries,
    }));
  }, [documentNumber, itemsOrders]);

  const onHandleFilters = (
    data = {
      initialDate: new Date(),
      finalDate: new Date(),
      status: { id: 0 },
      type: { id: fiscalType },
    }
  ) => {
    const objFilter = {
      retailerId: getRetailerId(),
      startDate: formatDateHelper(new Date(data.initialDate), "yyyy-MM-dd"),
      endDate: formatDateHelper(new Date(data.finalDate), "yyyy-MM-dd"),
      status: data.status["id"] === undefined ? data.status : data.status.id,
      type: data.type["id"] === undefined ? data.type : data.type.id,
    };

    getListMonitorNotes(objFilter)
      .then((response) => {
        const orders = response.sort((a, b) => {
          if (a.receiptNumber > b.receiptNumber) {
            return 1;
          }

          if (
            Date(a.fiscalDocument?.returnDate) >
            new Date(b.fiscalDocument?.returnDate)
          ) {
            return 1;
          }

          return -1;
        });

        setItemsOrders(orders);
      })
      .catch((error) => {
        console.error(error);
        //context.showMessage(stringErrors(error));
      });
  };

  const handleReprintCouponCompleted = (channel, payload) => {
    if (!payload.Danfe && !payload.XmlCompactado) {
      showAlert({
        message:
          "Ocorreu um erro ao reimprimir a nota, tente novamente mais tarde!",
      });

      return;
    }

    showAlert({ message: "Reimpresso com sucesso!" });
  };

  const handleReprintCouponError = (channel, payload) => {
    showAlert({ message: "Não foi possível reimprimir!" });
  };

  const handleCancelNote = async (selectedOrders) => {
    try {
      setShowLoading();
      if (selectedOrders.length === 0) {
        showAlert({ message: "Nenhuma nota selecionada para envio!" });

        setShowLoading(false);
        return;
      }

      const orderCashdesk = await getCashdeskById(selectedOrders[0].cashDeskId);

      if (orderCashdesk.closingDate) {
        showAlert({
          message: "Não é possível cancelar uma nota com o caixa fechado.",
        });

        setShowLoading(false);

        return;
      } else if (selectedOrders[0].fiscalStatus === 4) {
        showAlert({ message: "A nota já está cancelada!" });
        setShowLoading(false);
      } else if (
        selectedOrders[0].fiscalStatus !== 1 ||
        selectedOrders[0].statusNumber === 10
      ) {
        showAlert({
          message: `Não é possível cancelar notas com status: ${selectedOrders[0].status}`,
        });
        setShowLoading(false);
      } else {
        const order = selectedOrders[0];

        let reqBody = {
          RetailerId: getRetailerId(),
          FiscalFlowUrl: getFiscalFlowEndpoint(),
          OrderId: order.id,
          CodigoUsuario: manager.employeeCode,
          PosId: order.posId,
          Justificativa: "Cancelamento solicitado pelo Operador do PDV",
          FiscalType: order.fiscalDocument.fiscalType,
        };

        let resp = await cancelOrderService(reqBody);

        // Se vier informação para transmitir pelo front, altero alguns dados e envio ao MID pelo front
        // depois devolvo ao Order para retormar o processamento e salvar no banco
        if (
          resp.order.autorizacaoPeloFront &&
          resp.order.fiscalFlowBody != ""
        ) {
          const { data: fiscalData } = await apiProducts.get("/api/pdv/params");

          const baseURL = `http://${fiscalData.localIPForFiscalFlow}`;
          var apiFiscalFlow = axios.create({
            baseURL,
            headers: {},
          });

          const fiscalFlowBody = JSON.parse(resp.order.fiscalFlowBody);
          let respFiscalFlow;
          try {
            const { data } = await apiFiscalFlow.post(
              "/NFCe/Cancela",
              fiscalFlowBody
            );
            respFiscalFlow = data;
          } catch (e) {
            console.log(`/NFCe/Cancelar | resp ${JSON.stringify(e)}`);
            respFiscalFlow = {
              cStat: "0",
              xMotivo: "Erro de rede",
            };
          }

          reqBody = {
            ...reqBody,
            autorizacaoPeloFront: true,
            fiscalFlowResponse: JSON.stringify(respFiscalFlow),
          };

          resp = await cancelOrderService(reqBody);
        }

        if (resp.order.fiscalCanceledDocument?.accessKey != null) {
          let tefPayments = [];

          resp.order.payments != null &&
            resp.order.payments.forEach(async (payment) => {
              if (
                payment.paymentType.enumValue === TransactionCode.CreditTef ||
                payment.paymentType.enumValue === TransactionCode.DebitTef ||
                payment.paymentType.enumValue === TransactionCode.VoucherTef
              ) {
                tefPayments.push(payment); // Armazena o pagamento TEF para cancelamento
              } else if (
                payment.paymentType.enumValue === TransactionCode.QRCode
              ) {
                if (window.desktopApp) {
                  window.desktopApp.publish(
                    "machine.devices.desktop.log",
                    `Cancelamento QRCode: ${JSON.stringify({
                      payment_id: payment.controlCode,
                      amount: payment.amount,
                    })}`
                  );
                } else if (window.Android) {
                  console.log(
                    `Cancelamento QRCode | ${JSON.stringify({
                      payment_id: payment.controlCode,
                      amount: payment.amount,
                    })}`
                  );
                }

                cancelPaymentService({
                  payment_id: payment.controlCode,
                  amount: payment.amount,
                });
              }
            });

          if (tefPayments.length) {
            showAlert({
              onConfirmText: "Sim",
              cancelText: "Não",
              message: (
                <>
                  Deseja cancelar os pagamentos agora? <br />
                  Eles poderão ser cancelados manualmente no menu em
                  "Reimpressão e Cancelamento TEF"?
                </>
              ),
              handleConfirm: () => {
                handleCancelTef(resp, tefPayments);
                setShowLoading(false);
              },
              handleCancel: () => {
                showAlert({
                  message: `A nota de número ${resp.order.fiscalNumber} foi cancelada com sucesso.`,
                });
                setShowLoading(false);
              },
            });
          } else {
            showAlert({
              message: `A nota de número ${resp.order.fiscalNumber} foi cancelada com sucesso.`,
            });
          }

          handlePrintCoupon(resp.order, manager);

          onHandleFilters();
        }

        setShowLoading(false);
      }
    } catch (err) {
      showAlert({
        message: `Não foi possivel cancelar a nota.\n\nErro: ${err}`,
      });
      window.returnPayments = undefined;
      setShowLoading(false);
      console.error(err);
    }
  };

  const handleCancelTef = async (order, tefPayments = []) => {
    try {
      if (window.Android) {
        window.returnPayments = (data) => {
          console.log(`cancel tefPayments || ${JSON.stringify(tefPayments)}`);
          console.log(
            `window.returnPayments monitor-notes | ${JSON.stringify(data)}`
          );

          window.Android.confirmTransaction(data.nsu);

          setTimeout(async () => {
            try {
              if (data?.success || data?.errorMessage.split("-")[0] === "T89") {
                // T89 significa que já está cancelado
                await updatePaymentCancel({
                  orderId: order.order.id,
                  paymentId: tefPayments[0].id,
                  authorizationCodeCancel: data.authorizationCode,
                  controlCodeCancel: data.nsu,
                  cancellerSellerName: operator.user,
                  cancellerSellerReferenceId: operator.referenceId,
                  cancellerManagerName: manager.login,
                  cancellerManagerReferenceId: manager.employeeCode,
                });
              }

              tefPayments.shift();
              callCancelation();

              if (!tefPayments.length) {
                window.returnPayments = undefined;
                showAlert({
                  message: `A nota de número ${order.order.fiscalNumber} foi cancelada com sucesso.`,
                });
                setShowLoading(false);
              }
            } catch (error) {
              window.returnPayments = undefined;
            }
          }, 5000);
        };
      }

      const callCancelation = async () => {
        setShowLoading();
        const payment = tefPayments[0];

        if (window.desktopApp) {
          window.desktopApp.publish(
            "machine.devices.desktop.log",
            `Cancelamento de cartão: ${order.order.id} `
          );

          window.desktopApp.subscribe(
            "machine.devices.tef.cancelcoupon.completed",
            (ch, payload) => {
              if (payload.error) {
                showAlert({
                  title: "Operação abortada",
                  message: (
                    <>
                      {payload.error}
                      <br /> Os demais cancelamentos devem ser realizados
                      manualmente no menu em "Reimpressão e Cancelamento TEF".
                    </>
                  ),
                });
              }

              const paymentsToUpdate = payload.result;

              const updatePayments = async (index = 0) => {
                if (paymentsToUpdate[index]) {
                  await updatePaymentCancel({
                    orderId: order.order.id,
                    paymentId: paymentsToUpdate[index].id,
                    authorizationCodeCancel:
                      paymentsToUpdate[index].authorizationCode,
                    controlCodeCancel: paymentsToUpdate[index].controlCode,
                    cancellerSellerName: operator.user,
                    cancellerSellerReferenceId: operator.referenceId,
                    cancellerManagerName: manager.login,
                    cancellerManagerReferenceId: manager.employeeCode,
                  }).then(() => {
                    setTimeout(() => {
                      updatePayments(index + 1);
                    }, 300);
                  });
                }
              };

              updatePayments();

              window.desktopApp.unsubscribe(
                "machine.devices.tef.cancelcoupon.completed"
              );
            }
          );

          console.log({
            payments: order.order.payments,
            idOrder: order.order.id,
          });

          window.desktopApp.publish("machine.devices.tef.cancelcoupon", {
            payments: order.order.payments,
            idOrder: order.order.id,
          });
        } else if (window.Android) {
          try {
            const data = {
              controlCode: payment.controlCode,
              dataTransaction: transactionData(
                new Date(order.order.createdAt),
                "yyyyMMdd"
              ),
              totalDouble: payment.amount,
            };

            console.log(
              `window.Android.cancelOrderTransaction | ${JSON.stringify(data)}`
            );
            window.Android.cancelOrderTransaction(
              data.controlCode, // NSU
              data.dataTransaction, // Data
              data.totalDouble
            ); // Total (Double)
            console.log(`cancelOrderTransaction | end`);
          } catch (e) {
            console.error(`cancelOrderTransaction | error | ${e}`);
            window.returnPayments = undefined;
            setShowLoading();
          }
        }
      };

      callCancelation();
    } catch (err) {
      console.error(err);
    }
  };

  const handleReprintNote = (selectedOrders) => {
    selectedOrders.forEach((order) => {
      if (order.statusNumber === 4 || order.statusNumber === 10) {
        showAlert({
          message: `Não é possível reimprimir notas com status: ${order.status}`,
        });

        return;
      }

      if (window.desktopApp) {
        window.desktopApp.publish(
          "machine.devices.downloadPDFAndPrintWithDotNet",
          {
            url: order.danfeUrlFiscalFlow,
            identity: `danfe-${uuidv4()}`,
          }
        );
      }

      if (window.Android) {
        try {
          // ToDo: se parar de salvar no storage, precisa revisar
          // ToDo: todo mais sem vergonha que eu ja vi..... tinha que ser o corno master
          let danfeUrl = order.danfeUrlFiscalFlow; //`https://linxfood.blob.core.windows.net/danfe/${order.keyaccess}.png`;
          console.log(`window.Android.printerNfce | {danfeUrl: ${danfeUrl}}`);
          window.Android.printerNfce(danfeUrl);
        } catch (e) {
          console.error(`window.Android.printerNfce | ${JSON.stringify(e)}`);
          showAlert({
            message: `Não foi possível reimprimir a nota.`,
          });
        }
      }
    });
  };

  const handleReauthorizeNote = async (selectedOrders) => {
    try {
      const orders = [];
      let listOP = [];

      if (
        selectedOrders.find(
          (order) =>
            order.fiscalStatus !== 3 &&
            order.fiscalStatus !== 8 &&
            order.fiscalStatus !== 2 &&
            order.fiscalStatus !== 10
        )
      ) {
        showAlert({
          message: `Somente é possivel reautorizar notas com o status Contingência e Rejeitada`,
        });

        return;
      }

      selectedOrders
        .filter((order) => order.fiscalStatus === 3)
        .forEach((order) => {
          if (
            order.fiscalStatus !== 3 &&
            order.fiscalStatus !== 8 &&
            // order?.fiscalDocument?.status === "MC015" &&
            // order?.fiscalDocument?.status === "M0015" &&
            order?.fiscalStatus !== 2
          ) {
            showAlert({
              message: `Não é possível autorizar notas com status: ${order.status}`,
            });

            return;
          }

          listOP.push(order.id);
          orders.push(order);
        });

      setListOrderProcessing(listOP);
      setCurrentList(selectedOrders);

      showInfoModal({
        message: "REAUTORIZAÇÃO DE NOTAS - PROCESSANDO...",
        subMessage:
          "As notas processadas com sucesso, são enviadas para contingência novamente. Aguarde o processamento da nota no SEFAZ novamente para validar se será autorizada.",
        moreInfo: null,
        type: 7,
      });

      for (let i = 0; i < selectedOrders.length; i++) {
        const order = selectedOrders[i];

        // const res = await calculateTaxOrderService({ orderId: order.id });

        // const document = orderToAuthorizationData(
        //   res.content.couponAuthorization
        // );

        // const { data: result } = await autorizarNFCe(document);

        // if (result.cStat === "MC015" || result.cStat === "M0015") {
        //   result.xMotivo =
        //     "Emissão NFCe em Contingencia por ausência de internet ou Serviço SEFAZ indisponível/instável";
        // }

        // var payload = nfceResultToReAuthorize(result);
        // // payload.correlationId = result.correlationId;
        // payload.retailerId = order.retailerId;
        // payload.posId = order.posId;
        // payload.id = order.id;
        // payload.receiptNumber = res.content.couponAuthorization.nNF;
        // payload.receiptSeries = res.content.couponAuthorization.serie;
        // payload.resend = order.resend;

        // const req = nfceResultToFiscalDocument(payload);
        // await setFiscalDocumentOrderService(req);

        let config = {};
        try {
          config = window.mobileConfig
            ? JSON.parse(window.localStorage.getItem("AA_ConfigDevice") || {})
            : JSON.parse(window.sessionStorage.getItem("AA_Config") || {});
        } catch (error) {
          console.log("Ocorreu um erro ao adquirir config");
          console.log(JSON.stringify(error));
        }

        const res = await reauthorizeOrder({
          orderId: order.id,
          danfeJpg: window?.Android != undefined ?? false,
          danfe56mm: config?.printSize == "56mm" ?? true,
          //dateAndTime
        });

        const payload = {
          status: res.status,
          reason: res.reason,
        };

        reauthDone(payload);
      }

      onHandleFilters();
    } catch (err) {
      showAlert({
        title: "Erro ao reautorizar nota",
        message: `Ocorreu um erro ao reautorizar sua nota, tente novamente em 30 minutos.`,
      });
      console.error(err);
    }
  };

  const reauthDone = (payload) => {
    try {
      const fiscalDocumentClassStatus =
        payload.status === ("100" || "150" || "M0015" || "MC015")
          ? "success"
          : "error";
      const text = fiscalDocumentClassStatus === "success" ? "SUCESSO" : "ERRO";
      const msg =
        fiscalDocumentClassStatus !== "success"
          ? `(${payload.status} - ${payload.reason})`
          : "";
      const nameIcon =
        fiscalDocumentClassStatus !== "success" ? "close" : "done";
      let listOP = [];
      setListOrderProcessing((prev) => {
        listOP = prev;
        return prev;
      });

      listOP.shift();
      setListOrderProcessing(listOP);

      let noteList = [];

      setCurrentList((notes) => {
        noteList = notes;
        return noteList;
      });

      const content = noteList?.map((item) => {
        if (listOP.find((i) => i === item.id)) {
          return (
            <p>
              <span>Nota número: {item.receiptNumber}</span>
              <span className="status">PROCESSANDO</span>
              <span className="material-icons rotate">cached</span>
            </p>
          );
        } else {
          return (
            <p>
              <span>Nota número: {item.receiptNumber}</span>
              <span className={`status ${fiscalDocumentClassStatus}`}>
                {text}
              </span>
              <span className={`material-icons ${nameIcon}`}>{nameIcon}</span>
              {msg}
            </p>
          );
        }
      });

      showInfoModal({
        message: "REAUTORIZAÇÃO DE NOTAS - PROCESSANDO...",
        subMessage:
          "As notas processadas com sucesso, são enviadas para contingência novamente. Aguarde o processamento da nota no SEFAZ novamente para validar se será autorizada.",
        moreInfo: content,
        type: 7,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleUnuseNotes = async (data) => {
    try {
      setShowLoading();

      let reqPayload = {
        FiscalFlowUrl: getFiscalFlowEndpoint(),
        Ano: data.numAno,
        //CNPJ: documentNumber,
        RetailerId: getRetailerId(),
        NotaFiscalInicial: data.numInicial,
        NotaFiscalFinal: data.numFinal,
        Serie: data.numserie,
        Justificativa: data.justificativa,
        DevolveXml: true,
      };

      var data = await inutilizarNumeracaoService(reqPayload);

      if (data.status != "102") {
        showAlert({
          message: `Não é possível inutilizar a numeração. Motivo: ${data.reason}`,
        });
      } else {
        showAlert({
          message: `O intervalo de numeração de ${reqPayload.NotaFiscalInicial} a ${reqPayload.NotaFiscalFinal} foi inutilizado com sucesso`,
        });
      }
      setShowLoading(false);
    } catch (err) {
      setShowLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      <MonitorNotes
        listItens={listItems()}
        handleClick={(data) => onHandleFilters(data)}
        title="Monitor Notas Fiscais"
        setOpenInutilizacao={setOpenInutilizacao}
        handleCancelNote={handleCancelNote}
        handleReprintNote={handleReprintNote}
        handleReauthorizeNote={handleReauthorizeNote}
        fiscalType={fiscalType}
        setOpenNoteDetails={setOpenNoteDetails}
        filters={filters}
        setMonitorNotesFilters={setFilters}
      />

      <InfoModalContainer
        {...infoModal}
        handleClose={() => showInfoModal({})}
      />

      <InutilizaNotes
        showModal={openInutilizacao}
        setOpenInutilizacao={setOpenInutilizacao}
        onHandleUnsed={handleUnuseNotes}
      />

      <NoteDetails
        noteDetails={openNoteDetails}
        showModal={!!openNoteDetails}
        setOpenNoteDetails={setOpenNoteDetails}
      />
    </>
  );
};

export default MonitorNotesContainer;
