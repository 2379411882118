import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../../helpers/mediaQueryHelper";

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .content__wrapper {
    background-color: #fff;
    max-width: 320px;
    width: 100%;

    position: relative;
    overflow: hidden;

    border-radius: 0.5rem;
    margin: 0 auto;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0 1rem;
      }

      .button__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        cursor: pointer;
      }
    }

    .content__container {
      display: flex;
      flex-direction: column;

      .input__container {
        input {
          border: 0;
          width: 100%;

          padding: 0.5rem 1rem;
          font-size: 1.5rem;
          color: #000;

          border-bottom: 1px solid #eee;
        }
      }

      .keyboard-numeric .button {
        padding: 0.5rem;
      }

      .buttons__container {
        flex: 1;

        .button {
          background-color: ${({ theme }) => theme.colors.purple};
          border-radius: 0;
          width: 100%;
          color: #fff;

          cursor: pointer;

          &.button__submit:disabled {
            background-color: ${({ theme }) => theme.colors.purpleDisabled};
          }
        }
      }
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .keyboard-numeric .button {
      font-size: 1rem;
    }
  `)}
`;
