import React, { useEffect, useRef, useState } from "react";
import { Button } from "../Button";
import { Card } from "../Card";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import KeyboardPlugin from "../keyboard-plugin";
import CurrencySelector from "./CurrencySelector";

import Selector from "../selector";
import {
  CashierOpeningBody,
  CashierOpeningFooter,
  CashierOpeningFormRow,
  CashierOpeningWrapper,
} from "./style";
import { useAuth } from "../../hook/authHook";
import LoginModal from "../loginModal";

export const CashierOpening = ({
  handleClose,
  onOkButtonClick,
  operatorList,
  manager,
  pdv,
  openingDate,
  openingCount,
  onCartShow,
  setVisibleSideKeyboardContainer,
  disableButton,
}) => {
  const numericKeyboardRef = useRef();
  const { setVisibleSidebarCart } = useWrapperRouters();
  const auth = useAuth();

  const [user, selectUser] = useState(null);
  const [openingAmount, setOpeningAmount] = useState(null);
  const [hasErrorInputOperator, setError] = useState(false);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);

  useEffect(() => {
    setVisibleSidebarCart(false);
    setVisibleSideKeyboardContainer(false);
    setTimeout(() => setVisibleSideKeyboardContainer(true), 10);

    var element = document.getElementById("currency-input-opening-amount");
    if (element) {
      element.focus();
    }

    return () => {
      setVisibleSidebarCart();

      // if (numericKeyboardRef.current) {
      //   numericKeyboardRef.current.destroy();
      // }
      setVisibleSideKeyboardContainer(false);
    };
  }, [onCartShow, setVisibleSideKeyboardContainer, setVisibleSidebarCart]);

  const submitCashierOpening = () => {
    if (!user && auth.userAuthData.isManager) {
      setError(true);
      return;
    }

    if (auth.userAuthData.isManager && user.isOperator) {
      setShowAuthorizationModal(true);
      return;
    }

    setError(false);

    let req = {
      openingAmount: openingAmount ? openingAmount : 0,
    };

    if (auth.userAuthData.isManager) {
      req = {
        ...req,
        userId: user.nuuidOne,
        user: user.login,
        userReferenceId: user.employeeCode,
      };
    }

    onOkButtonClick(req);
  };

  const onChangeKeyboard = (input) => {
    setOpeningAmount(input);
  };

  const onKeyPressKeyboard = (button) => {
    if (button === "{clear}") {
      setOpeningAmount(0);
      numericKeyboardRef.current.setInput(0);
    }
  };

  const handleCurrencySelector = (value) => {
    setOpeningAmount(value);

    let keyboardValue = String(value).replace(".", "").replace(",", "");
    numericKeyboardRef.current.setInput(keyboardValue);
  };

  const handleOperatorCashierOpening = () => {

    if (!user && auth.userAuthData.isManager) {
      setError(true);
      return;
    }

    setError(false);

    let req = {
      openingAmount,
    };

    if (auth.userAuthData.isManager) {
      req = {
        ...req,
        userId: user.nuuidOne,
        user: user.login,
        userReferenceId: user.employeeCode,
      };
    }

    onOkButtonClick(req);
    setShowAuthorizationModal(false);
  };

  return (
    <>
      <CashierOpeningWrapper>
        <KeyboardPlugin
          customClassName="keyboard-cashier-opening"
          visible={true}
          baseClass={"id-keyboard-cashier-opening"}
          titleDescription="Valor"
          layoutName="numeric"
          numericType={"currency"}
          keyboardRef={(r) => (numericKeyboardRef.current = r)}
          onChange={(input) => onChangeKeyboard(input)}
          onKeyPress={(button) => onKeyPressKeyboard(button)}
        />

        {showAuthorizationModal && (
          <LoginModal
            handleCancel={() => setShowAuthorizationModal(false)}
            selectedOperator={user}
            handleSubmit={handleOperatorCashierOpening}
          />
        )}
        <Card
          classNameMain="card-cashier-opening"
          title="Abertura de Caixa"
          handleClose={handleClose}
          closeEnable={true}
        >
          <CashierOpeningBody>
            <div className="grid">
              <CashierOpeningFormRow>
                PDV
                <input value={`PDV ${pdv}`} readOnly disabled />
              </CashierOpeningFormRow>

              <CashierOpeningFormRow>
                Data Movimento
                <input value={openingDate} readOnly disabled />
              </CashierOpeningFormRow>

              <CashierOpeningFormRow>
                Número Abertura
                <input value={openingCount} readOnly disabled />
              </CashierOpeningFormRow>

              <CashierOpeningFormRow>
                Responsável
                <input
                  value={`${manager.referenceId} ${manager.name}`}
                  readOnly
                  disabled
                />
              </CashierOpeningFormRow>
            </div>

            <CashierOpeningFormRow>
              Fundo inicial de troco
              <CurrencySelector
                inputValue={openingAmount}
                setValue={handleCurrencySelector}
              />
            </CashierOpeningFormRow>

            {auth.userAuthData.isManager ? (
              <CashierOpeningFormRow>
                Operador *
                <Selector
                  openTop
                  items={operatorList}
                  onSelect={selectUser}
                  selectText="Selecione o Operador"
                  isLogin
                />
                {hasErrorInputOperator && (
                  <span className="error">
                    Obrigatório selecionar um operador!
                  </span>
                )}
              </CashierOpeningFormRow>
            ) : (
              <CashierOpeningFormRow>
                Operador
                <input
                  value={`${auth.userAuthData.personCode} ${auth.userAuthData.login}`}
                  readOnly
                  disabled
                />
              </CashierOpeningFormRow>
            )}
          </CashierOpeningBody>

          <CashierOpeningFooter>
            <Button
              onClick={() => submitCashierOpening()}
              className="btn btn-primary"
              disabled={disableButton}
            >
              Confirmar
            </Button>
          </CashierOpeningFooter>
        </Card>
      </CashierOpeningWrapper>
    </>
  );
};
