import React from "react";
import { PrintCSS } from "./styled";

const PrintTemplate = (props) => {
  const { children } = props;

  return (
    <>
      <head>{window.desktopApp && <style>{PrintCSS}</style>}</head>

      <body>
        <div className="content-printer">{children}</div>
      </body>
    </>
  );
};

export default PrintTemplate;
