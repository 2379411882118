import React, { useState } from "react";
import Card from "../Card";
import { ArmouredCarWrapper, InputGroup } from "./styled";
import TableList from "./table-list";
import Button from "../Button";
import { isSmartPos } from "../../../aaone/configuration";
import classNames from "classnames";
import { InfoArmouredCard } from "./info-armoured-car";

const ArmouredCar = (props) => {
  const {
    controlNumber,
    handleClose,
    handleSubmit,
    handleCancel,
    handleReprint,
    listArmouredCars,
  } = props;

  const [description, setDescription] = useState("");
  const [numberEnvelop, setnumberEnvelop] = useState("");
  const [errors, setErrors] = useState({});
  const [expandCard, setExpandCard] = useState(true);
  const [expandCardList, setExpandCardList] = useState(false);

  const handleForm = (e) => {
    e.preventDefault();
    let errors = {};
    setErrors({});

    /**
     * Validação de erros
     */
    if (numberEnvelop === "") {
      errors.numberEnvelop = true;
    }

    if (Object.values(errors).length) {
      setErrors(errors);
      return;
    }

    handleSubmit({ description, numberEnvelop });
  };

  return (
    <ArmouredCarWrapper>
      <Card
        title="Carro Forte"
        id="card"
        className={!expandCard ? "expand-card" : ""}
        handleHidden={() => {
          setExpandCard(true);
          setExpandCardList(false);
        }}
        fullHeight={expandCard}
        closeEnable
        handleClose={handleClose}
      >
        <form
          className={classNames("form-control", {
            "form-smartpos": isSmartPos,
            "form-pdv": !isSmartPos,
          })}
          onSubmit={(e) => handleForm(e)}
        >
          <InputGroup>
            <label>Nº Controle</label>
            <input value={controlNumber} readOnly disabled />
          </InputGroup>
          <div></div>
          <InputGroup>
            <label>Observação</label>
            <input
              className={`${errors.description ? "error" : ""}`}
              data-use-touch-keyboard
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <label>Identificação do Carro-Forte</label>
            <input
              className={`${errors.numberEnvelop ? "error" : ""}`}
              data-use-touch-keyboard
              value={numberEnvelop}
              onChange={(e) => setnumberEnvelop(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <Button
              id="btn-confirm"
              className="btn-large btn-purple btn-custom"
              type="submit"
            >
              Confirmar
            </Button>
          </InputGroup>
        </form>
      </Card>
      <Card
        title="Consulta Carro Forte"
        id="cardList"
        className={!expandCardList ? "expand-card" : ""}
        handleHidden={() => {
          setExpandCard(false);
          setExpandCardList(true);
        }}
        fullHeight={expandCardList}
      >
        <div
          className={classNames("amount-list", {
            "smart-pos": isSmartPos,
            "pdv-windows": !isSmartPos,
          })}
        >
          <span>
            {listArmouredCars.length}{" "}
            {listArmouredCars.length > 1 ? "carros-fortes" : "carro-forte"}{" "}
            realizado
            {listArmouredCars.length > 1 ? "s" : ""}.
          </span>
        </div>
        {isSmartPos ? (
          <InfoArmouredCard
            items={listArmouredCars}
            handleReprint={handleReprint}
            handleCancel={handleCancel}
          />
        ) : (
          <TableList
            listWithdraw={listArmouredCars}
            handleReprint={handleReprint}
            handleCancel={handleCancel}
          />
        )}
      </Card>
    </ArmouredCarWrapper>
  );
};

export default ArmouredCar;
