import styled, { css } from "styled-components";

export const BoxWrapper = styled.div`
  display: block;

  padding: 10px;

  border: 1px solid #e6e6e6;
  border-radius: 8px;

  height: 255px !important;

  margin-top: 16px;
  margin-bottom: 16px;

  font-size: 14px;
`;

export const InfoSuppleData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 5px;

  button {
    width: 100%;
    align-items: center;
    align-content: center;
  }

  .btn-print {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #411e5a;
    border-radius: 4px !important;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: 13px !important;
    padding: 7px 20px;

    margin-top: 10px;
    outline: none;
    .material-icons {
      color: #fff;
      font-size: 18px;
      margin-right: 3px;
    }
  }

  .btn-cancel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #411e5a;
    border-radius: 4px !important;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: 13px !important;
    padding: 7px 20px;
    margin-top: 10px;
    outline: none;

    .material-icons {
      color: #fff;
      font-size: 18px;
      margin-right: 3px;
    }
  }
`;

export const TextData = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid #e6e6e6;
  text-transform: uppercase;

  label {
    padding: 8px;
  }
`;
