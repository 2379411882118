import styled from "styled-components";

export const ImageProduct = styled.figcaption`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 6px 6px 8px #d8d2d282;
  margin-bottom: 0px;
  border-radius: 20px;

  .no-image,
  img {
    width: 500px;
    height: 300px;
    margin: 0 auto;
    border-radius: 20px;
    text-align: center;
    align-items: center;
  }
`;

export const Amount = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 3rem;
    font-weight: 700;
    color: var(--color-background-cart);
  }

  strong {
    color: var(--color-title-category);
    font-size: 3.4rem;
    margin-left: 4px;
  }
`;

export const RadioContainer = styled.section`
  max-height: 700px;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background-color: var(--color-background-progress-bar);
    border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.8);
  }
`;
