import React, { useEffect } from "react";
import {
  HashRouter,
  Routes as RoutesContainer,
  Route,
  Navigate,
} from "react-router-dom";
import { WrapperRouters } from "../components/WrapperRouters";

import { PagesEnum } from "../constants/pagesEnum";
import { useAuth } from "../hook/authHook";

import { HomePage } from "../pages/home";
import { LoginPage } from "../pages/login";
import { PaymentMethodsPage } from "../pages/paymentMethods";
import { PaymentCashPage } from "../pages/payments/Cash";
import { PaymentQrLinxPage } from "../pages/payments/QrLinx";
import { PaymentTestQAPage } from "../pages/payments/TestQA";
import { PaymentWithCardPage } from "../pages/payments/WithCard";
import { PaymentSuccessfulPage } from "../pages/paymentSuccessful";
import { PaymentHowMuchWillYouPayPage } from "../pages/payments/HowMuchWillYouPay";
import CashOpen from "../pages/cashOpen";
import SallerTable from "../pages/sallerTable";
import { SettingsPage } from "../pages/settings";
import CashSupply from "../pages/CashSupply";
import CashClose from "../pages/cashClose";
import CashWithdraw from "../pages/cashWithdraw";
import ArmouredCarContainer from "../pages/armoured-car";
import CashReduce from "../pages/cashReduce";
import ReportSalesHourly from "../pages/reports/sales_hourly";
import ReportSales from "../pages/reports/sales-daily";
import NeedAuth from "../pages/needAuth";
import MonitorTefList from "../components/monitor-tef";
import ProductsUnavailable from "../pages/ProductsUnavailable";
import ReportCashierClosing from "../pages/reports/cashier-closing";
import MonitorNotesContainer from "../pages/monitor-notes";
import { NFCESeriesSettings } from "../pages/nfce-series-config";
import SalesReturnContainer from "../pages/sales-return";
import { PrinterSettingsPage } from "../pages/PrinterSettings";
import { MideSettingsPage } from "../pages/MideSettings";
import CanceledItems from "../pages/reports/canceled-items";
import CashCloseWithdraw from "../pages/cashCloseWithdraw";
import CashCloseReduce from "../pages/cashCloseReduce";
import { PaymentPosPage } from "../pages/payments/Pos";
import { TefSettingsPage } from "../pages/tefSettings";
import ReshopFidelity from "../pages/fidelity";
import { SaveLogsPage } from "../pages/save-logs";

export const Routes = () => {
  const user = useAuth();
  const { signOut, isAuthenticated } = user;

  useEffect(() => {
    if (!isAuthenticated) {
      try {
        signOut();
      } catch (error) {
        window.location.reload();
      }
    }
  }, [isAuthenticated, signOut]);

  return (
    <HashRouter>
      <RoutesContainer>
        <Route path={PagesEnum.LOGIN}>
          <Route index element={<LoginPage />} />
          {isAuthenticated && (
            <Route element={<WrapperRouters />}>
              <Route path={PagesEnum.HOME} element={<HomePage />} />
              <Route path={PagesEnum.SETTINGS} element={<SettingsPage />} />
              <Route
                path={PagesEnum.PAYMENT_METHODS}
                element={<PaymentMethodsPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_HOW_MUCH_WILL_YOU_PAY}
                element={<PaymentHowMuchWillYouPayPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_TESTQA_METHOD}
                element={<PaymentTestQAPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_QR_LINX_METHOD}
                element={<PaymentQrLinxPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_SUCCESSFUL}
                element={<PaymentSuccessfulPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_CASH_METHOD}
                element={<PaymentCashPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_WITH_CARD_METHOD}
                element={<PaymentWithCardPage />}
              />
              <Route
                path={PagesEnum.PAYMENT_POS_METHOD}
                element={<PaymentPosPage />}
              />

              {/** Menu */}
              <Route path={PagesEnum.NEED_AUTH} element={<NeedAuth />} />
              <Route path={PagesEnum.CASH_OPEN} element={<CashOpen />} />
              <Route path={PagesEnum.SALLER_TABLE} element={<SallerTable />} />
              <Route path={PagesEnum.CASH_CLOSE} element={<CashClose />} />
              <Route
                path={PagesEnum.CASH_CLOSE_WITHDRAW}
                element={<CashCloseWithdraw />}
              />
              <Route
                path={PagesEnum.CASH_CLOSE_REDUCE}
                element={<CashCloseReduce />}
              />
              <Route path={PagesEnum.CASH_SUPPLY} element={<CashSupply />} />
              <Route
                path={PagesEnum.CASH_WITHDRAW}
                element={<CashWithdraw />}
              />
              <Route
                path={PagesEnum.CASH_ARMOURED_CAR}
                element={<ArmouredCarContainer />}
              />
              <Route path={PagesEnum.CASH_REDUCE} element={<CashReduce />} />
              <Route
                path={PagesEnum.SALES_HOURLY}
                element={<ReportSalesHourly />}
              />
              <Route path={PagesEnum.SALES_DAILY} element={<ReportSales />} />

              <Route
                path={PagesEnum.PRODUCTS_UNAVAILABLE}
                element={<ProductsUnavailable />}
              />
              <Route
                path={PagesEnum.MONITOR_TEF}
                element={<MonitorTefList />}
              />
              <Route
                path={PagesEnum.REPORT_CASHIER_CLOSING}
                element={<ReportCashierClosing />}
              />
              <Route
                path={PagesEnum.MONITOR_NOTES}
                element={<MonitorNotesContainer />}
              />
              <Route
                path={PagesEnum.FIDELITY_COUPON}
                element={<ReshopFidelity />}
              />
              <Route
                path={PagesEnum.SERIES_NFCE_CONFIG}
                element={<NFCESeriesSettings />}
              />
              <Route
                path={PagesEnum.PRINTER_SETTINGS}
                element={<PrinterSettingsPage />}
              />
              <Route
                path={PagesEnum.SALES_RETURN}
                element={<SalesReturnContainer />}
              />
              <Route
                path={PagesEnum.MIDE_SETTINGS}
                element={<MideSettingsPage />}
              />
              <Route
                path={PagesEnum.CANCELED_ITEMS}
                element={<CanceledItems />}
              />
              <Route
                path={PagesEnum.TEF_SETTINGS}
                element={<TefSettingsPage />}
              />
              <Route
                path={PagesEnum.SAVE_LOGS}
                element={<SaveLogsPage />}
              />
            </Route>
          )}
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Route>
      </RoutesContainer>
    </HashRouter>
  );
};
