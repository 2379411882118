import styled, { createGlobalStyle } from "styled-components";

export const CreateGlobal = createGlobalStyle`
  .btn-outline {
    background-color: #411e5a !important;
    color: #fff !important;
  }
`;

export const DivWallets = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 15px;
  align-items: center;
  flex-wrap: wrap;
`;

export const TefMessagesWrapper = styled.div`
  .list-itens {
    height: calc(100vh - 50%); // Viewport height mínimo
    width: 100%;
    display: grid;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .list-itens p {
    margin-bottom: 20px;
  }

  .qrcode {
    margin: auto;
    margin-top: 360px;
    margin-bottom: 130px;
    height: 400px !important;
    width: 400px !important;
    position: relative;
    z-index: 1;
  }
`;
