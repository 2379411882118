import { currency } from "../../aaone/shared/utils/currency";

export const escMaskMoneyHelper = (value) => {
  let escMask = String(value)
    .toUpperCase()
    .replace("R$", "")
    .replace(",", ".")
    .trim()
    .replace(/\D/g, "")
    .replace(/^0+/, "");

  if (escMask.length > 1) {
    escMask = escMask.replace(/(\d{2})$/, ".$1");
  } else {
    escMask = "0." + escMask.padStart(2, "0");
  }

  if (escMask.startsWith(".")) {
    escMask = `0${escMask}`;
  }

  return escMask;
};

export const formatDecimal = (input) => {
  const cleanedInput = input.replace(/[^0-9]/g, '');

  const integerPart = cleanedInput.slice(0, -3) || '0';
  const decimalPart = cleanedInput.slice(-3);

  const formattedDecimal = decimalPart.padStart(3, '0');

  const formattedNumber = `${integerPart},${formattedDecimal}`;

  return formattedNumber;
};

export const maskMoneyHelper = (value) => {
  const moneyMasked = currency(
    escMaskMoneyHelper(String(value).replace(".", "").replace(",", "."))
  );

  return moneyMasked;
};
