import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
`;

export const Items = styled.ul`
  width: 100%;
  padding: 0;
`;

export const SelectionFlag = styled.span`
  &::before {
    content: '|';
    color: #ababab;
    font-size: 2.1rem;
    transition: color 0.3s;
  }
  &.active::before {
    color: var(--color-background-primary) !important;
  }
`;

export const Item = styled.li`
  font-size: 1.7rem;
  list-style-type: none;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: 0.2fr 0.2fr 2fr 0.2fr;
  padding: 10px;
  height: 46px;
  text-align: center;
  align-items: center;
  margin: 4px 20px 0px 15px;
  transition: box-shadow 0.3s;

  .counter {
    color: var(--color-text-flavor-list);
    border-bottom: 3px solid #f3f1f1;
    .quantity {
      display: inline-block;
      font-size: 125px;
      font-weight: 700;
      min-width: 180px;
    }
  }
  .composition-increase-price {
    font-size: 1.0rem;
    white-space: nowrap;
    margin: 0;
    color: var(--color-background-primary);
    text-align: left;
  }
  .composition-tittle{
    margin :0;
    font-size: 1.3rem;
    text-align: left;
  }
  &.active {
    border-radius: 10px;
  }
`;

export const Image = styled.img`
  width: 80px;
  &.no-active {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
`;

export const ContentCount = styled.div`
  color: var(--color-text-flavor-list);
  border-bottom: 3px solid #f3f1f1;
  float: right;
  width: 140px;
  text-align: right;
  .composition-quantity {
    font-size: 1.4rem;
  }

  .composition-button-count {
    padding: 0.2rem !important;
    border-radius: 17% !important;
    background: var(--color-background-primary);
    color: white;
    height: 37px;
    width: 42px;
    font-size: 1.2rem;
    span {
      top: 0% 1important;
    }
  }
`;
export const TitleItem = styled.p`
  color: #ababab;
  margin :0;
  font-size: 1.3rem;
  text-align: left;
  &.active {
    color: black;
  }
`;
