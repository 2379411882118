import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;

  font-weight: 700;
  font-size: 1.5rem;

  border-bottom: 1px solid #eee;
  padding-bottom: 0.75rem;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .button__back {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    outline: none;
    cursor: pointer;
    padding: 0;

    svg {
      margin-right: 0.5rem;
    }

    font-size: 1rem;
  }
`;
