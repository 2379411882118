import React from "react";
import { StepCompositionList } from "../modules";

const StepComposition = ({
  steps,
  listProducts,
  onAddItem,
  handleRemoveItem,
  listProductsSelected
}) => (
  <>
    <StepCompositionList
      steps={steps}
      listProducts={listProducts}
      listProductsSelected={listProductsSelected}
      onAddItem={onAddItem}
      handleRemoveItem={handleRemoveItem}
    />
  </>
);

export default React.memo(StepComposition);
