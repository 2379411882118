import SaleStepUseCase from "./SaleStepUseCase";
import UtilsUseCase from "../UtilsUseCase";

class OrientedSaleUseCase {
  #rawProducts;
  #products;
  #beforeProducts;
  #selectedProduct;
  #composition;
  #orientedSaleStepClass;
  #sequenceStep;
  #selectedEnvironment;

  constructor(
    rawProducts,
    sequenceStep,
    composition = false,
    orientedSaleStepClass,
    selectedEnvironment,
  ) {
    this.#rawProducts = Array.isArray(rawProducts)
      ? rawProducts
      : [rawProducts];
    this.#products = { before: [], current: [] };
    this.#beforeProducts = new Set();
    this.#composition = composition;
    this.#orientedSaleStepClass = orientedSaleStepClass;
    this.#sequenceStep = sequenceStep;
    this.#selectedEnvironment = selectedEnvironment;

    this.initialization();
  }

  next(productCode) {
    const chosenProduct = this.#products.current.find(
      (product) => product.productCode === productCode
    );

    if (chosenProduct === undefined) {
      this.returnToFirstLevelProductAfterNextLevel(productCode);
    } else {
      this.#beforeProducts.add({ ...this.#products });
      this.#products.before = [...this.#products.current];
      const positionProduct = chosenProduct.product
        ? chosenProduct.product
        : chosenProduct;
      const nextLevel = this.handleNextLevel(positionProduct);

      this.#products = this.handleProducts(nextLevel);
    }
  }

  back() {
    if (this.#beforeProducts.size) {
      const lastPosition = Array.from(this.#beforeProducts).pop();
      this.#products = lastPosition;
      this.#beforeProducts.delete(lastPosition);
    }
  }

  getSequenceStep() {
    return this.#sequenceStep;
  }

  getBeforeProduct() {
    return this.#beforeProducts;
  }

  returnToFirstLevelProductAfterNextLevel(productCode) {
    if (this.#beforeProducts.size) {
      const lastPosition = Array.from(this.#beforeProducts).pop();
      this.#products = lastPosition;
      this.#beforeProducts.delete(lastPosition);

      this.next(productCode);
    }
  }

  initialization() {
    this.adjustProducts();
  }

  getCurrentProducts() {
    return [...this.#products.current];
  }

  selectProduct(product) {
    if (product.composition === false) {
      this.#selectedProduct = product;
    } else {
      if (product.operationType === "R") product.amount = 1;
      else product.amount += 1;
    }

    this.#products = Array.from(this.#beforeProducts)[0];
    this.#beforeProducts = new Set();
  }

  deselectProduct(product) {
    if (product.amount > 0) {
      product.amount -= 1;
    }

    if (!product.composition || product.composition === false) {
      this.#selectedProduct = null;
    }
  }

  getSelectedProduct() {
    return { ...this.#selectedProduct };
  }

  getProducts() {
    return { ...this.#products };
  }

  adjustProducts() {
    this.#products = this.handleProducts(this.#rawProducts);
  }

  setObservation(product, observation) {
    product.observation = observation;
  }

  mountObjectHandleProduct (product, nextLevel = []) {
    const Utils = new UtilsUseCase();

    return {
      productCode: product.productCode,
      nuuidOne: product.nuuidOne,
      promoCode: product.promoCode ? product.promoCode : "",
      description: Utils.getDescription(
        product.allDescriptions,
        this.#selectedEnvironment,
      ),
      allDescriptions: product.allDescriptions,
      restriction: product.restriction,
      observation: "",
      value: product.value,
      promotion: product.promotion,
      mainProductCode: product.mainProductCode,
      catalogImage: product.catalogImage,
      fractionalQuantity: product.fractionalQuantity ?? false,
      image: product.catalogImage,
      composition: this.#composition,
      amount: this.#composition ? (product.operationType === "A" ? 0 : 1) : 0,
      operationType: product.operationType,
      noSalesEffect: product.noSalesEffect,
      nextLevel,
      orientedSaleClass: this,
      orientedSaleStepClass: this.#orientedSaleStepClass,
      hasOrientedSale: product.hasOrientedSale,
      ClassSaleStep: product.hasOrientedSale ? new SaleStepUseCase(product, 1, this.#selectedEnvironment) : null,
      orientedSaleSteps: product.orientedSaleSteps ? product.orientedSaleSteps : [],
    }
  }
  handleProducts(untreatedProducts) {
    const products = [];
    untreatedProducts = untreatedProducts?.current ?? untreatedProducts;

    untreatedProducts.forEach((product) => {
      let unavailableProduct = false;
      if(product?.unavailableIn) {
        if (product?.unavailableIn[0].type.includes(this.#selectedEnvironment)) {
          unavailableProduct = true;
        }
      }

      if (!unavailableProduct) {
        const positionProduct = product.product ? product.product : product;
        let hasNextLevel = !!positionProduct?.nextLevel;

        let nextLevel = this.handleNextLevel(positionProduct);

        let productToInsert = positionProduct;
        if (!hasNextLevel) {
          if (nextLevel?.length) {
            nextLevel.forEach(itemNextLevel => {
              itemNextLevel.orientedSaleClass = this;
            });
          }

          productToInsert = this.mountObjectHandleProduct(positionProduct, nextLevel);
        } else {
          productToInsert.orientedSaleStepClass = this.#orientedSaleStepClass;
        }

        products.push(productToInsert);
      }
    });

    return { current: products, before: this.#products.before };
  }

  handleNextLevel(product) {
    if (product?.orientedSale && product?.orientedSale.length) {
      const orientedSaleMounted = [];
      product.orientedSale.forEach(orientedSale => {
        orientedSaleMounted.push(this.mountObjectHandleProduct(orientedSale));
      });
      return orientedSaleMounted;
    } else if (product.level) {
      product.level = product.level.map((productLevel) => {
        return this.mountObjectHandleProduct(productLevel);
      });

      return product.level;
    } else {
      return product.nextLevel ?? [];
    }
  }
}
export default OrientedSaleUseCase;
