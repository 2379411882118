import styled from "styled-components";

const select = styled.div`
  background-color: #fff;
  border-radius: 0;
  cursor: pointer;

  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  display: block;
  &:focus {
    outline: none;
  }

  .icon-align {
    float: right;
    margin-top: 8px;
  }

  .menu-area {
    cursor: pointer;
    position: absolute;
    top: 46px;
    width: 200px;
    display: grid;
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 15px;
    z-index: 500;

    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      height: 38px;
      padding: 0 10px;
      color: ${(props) => props.theme.palette.grayscale.ultraDark};
      &:focus,
      &:hover {
        background-color: #ffb200;
      }
    }
  }
`;
export default select;
