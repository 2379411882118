import { getCodLoja, getTerminalID } from "../aaone/configuration";
import { transactionData } from "../aaone/shared/utils/transactionNumber";
import { escOnlyNumbersHelper } from "../pdv-one/helpers/escOnlyNumbersHelper";
import { getCpfInvoiceHelper } from "../pdv-one/helpers/getCpfInvoiceHelper";
import { nsuValueHelper } from "../pdv-one/helpers/nsuValueHelper";
import { apiProducts } from "./api";
import { confirmsOperation } from "./verifyRedeemValueService";
import { callDegustOneAuthorizeService } from "./callDegustOneAuthorizeService";
import { getCurrentSystemDataInStorage } from "./getCurrentSystemVersion";
import {
  CPF_RESHOP_KEY_NAME,
  OBSERVATION_ORDER_KEY_NAME,
  RESHOP_TRANSACTION_ID_KEY_NAME,
} from "../pdv-one/constants/keyNamesSessionStorage";
import { getSystemParamsHelper } from "../pdv-one/helpers/getSystemParamsHelper";
import {
  confirmTEFPayments,
  undoTEFPayments,
} from "../pdv-one/helpers/paymentHelper";
import { FISCAL_STATUS_CONTINGENCY } from "../pdv-one/constants/fiscalStatus";

export const sendSaleOrderService = async (settings) => {
  try {
    // Finalização de venda do AA

    console.log(`Ident: [sendSaleOrderService] "Finalização de venda do AA"`);

    const terminalId = getTerminalID();

    const dataItemsAndValues =
      await settings?.Sale?.processItemsFinalizationSale(
        settings.prices.reshopTotalPrice
      );

    const hasItensReshop = dataItemsAndValues.items?.some(
      (item) => !!item?.pointPrice
    );
    const everyItensReshop = dataItemsAndValues.items?.every(
      (item) => !!item?.pointPrice
    );

    const payments = Array.isArray(settings.payments)
      ? settings.payments
      : [settings.payments];

    const reshopValue = JSON.parse(localStorage.getItem("reshopValue")) ?? 0;
    const sendRehopValue = parseFloat(reshopValue?.toFixed(2));

    if (hasItensReshop !== everyItensReshop) {
      payments.push({
        dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        description: "Fidelidade",
        controlCode: JSON.parse(
          localStorage.getItem(RESHOP_TRANSACTION_ID_KEY_NAME)
        ),
        authorizationCode: nsuValueHelper(),
        value: sendRehopValue,
        reshop: true,
        paymentCode: getSystemParamsHelper().defaultPaymentCodeReshop ?? 0,
      });
    }

    const cpfToDocument = getCpfInvoiceHelper();
    const cpfReshop = sessionStorage.getItem(CPF_RESHOP_KEY_NAME);
    const transactionId = sessionStorage.getItem(
      RESHOP_TRANSACTION_ID_KEY_NAME
    );
    const NsuReshop = nsuValueHelper();

    const body = {
      ...dataItemsAndValues,
      pdvCode: terminalId,
      pistaDrive: "1",
      carSpace: "1",
      layoutSale: "SHOP",
      carIdentification: localStorage.getItem("carIdentification"),
      prismaNumber: localStorage.getItem("prismaNumber"),
      pagerNumber: localStorage.getItem("pagerNumber"),
      operatorCode: localStorage.getItem("UserCode"),
      dateTimeStart: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
      consumptionPlace: window.localStorage.getItem("typeCode") || "",
      client: {
        name:
          localStorage.getItem("name") ||
          localStorage.getItem(OBSERVATION_ORDER_KEY_NAME),
        document: cpfToDocument,
      },
      AAMobile: null,
      payments: payments.map((payment) => ({
        ...payment,
        paymentResponseStone: null,
      })),
      paymentTransition: settings.options.paymentTransition,
      reshop: {
        cpfReshop: cpfReshop ? escOnlyNumbersHelper(cpfReshop) : null,
        numeroOperacao: transactionId,
        totalPontosVenda: null,
        clienteValorPonto: null,
        contingenciaReshop: null,
        NsuReshop,
      },
      totalPrice: settings.prices.totalPrice,
      manualDiscount: settings.prices.totalManualDiscount,
      totalReshopDiscount: settings.prices.reshopTotalPrice,
      typeSiteSaleId: getTypeSiteSaleId(),
      cashDeskOpenDateId: settings.cashDeskOpenDateId,
    };

    let data = null;
    const systemParams = getCurrentSystemDataInStorage();

    if (systemParams?.isOneIntegration) {
      data = await callDegustOneAuthorizeService(
        systemParams?.retailerId,
        body
      );
    } else {
      const result = await apiProducts.post(
        "/api/sales-order/save-sale-order",
        body
      );
      data = result.data;
    }

    const nsuValue = nsuValueHelper();
    const codigoLoja = getCodLoja();

    if (data.isSuccess || FISCAL_STATUS_CONTINGENCY.includes(data.status)) {
      try {
        // Confirma as operações em TEF
        confirmTEFPayments();

        // Reshop Confirm
        confirmsOperation(nsuValue, codigoLoja);
      } catch (error) {
        if (window.Android) {
          console.log(
            "error on sendSaleOrderService ConfirmPayments" +
              JSON.stringify({ error })
          );
        }

        console.log("error on sendSaleOrderService ConfirmPayments", error);
      }
    }

    return data;
  } catch (error) {
    // Desfaz os pagamentos em caso de erro
    undoTEFPayments();

    console.error(`sendSaleOrderService | ${JSON.stringfy(error)}`);
    throw new Error("Não foi possível finalizar a compra!");
  }
};

// Retorna o tipo de venda
const getTypeSiteSaleId = () => {
  try {
    const systemParams = getCurrentSystemDataInStorage();

    if (!systemParams.isAutoatendimentoOne) {
      return systemParams.vendaSiteBalcao;
    } else {
      return systemParams.vendaSiteAutoatendimento;
    }
  } catch (err) {
    console.error("getTypeSiteSaleId: " + err);
    return 0;
  }
};
