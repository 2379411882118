import styled, { keyframes } from "styled-components";

const cssToMoveTop = `
  0% {
    top: 0;
  }

  50% {
    top: -100%;
  }

  70% {
    top: 0;
  }
`;

const dotsOneMove = keyframes`${cssToMoveTop}`;
const dotsTwoMove = keyframes`${cssToMoveTop}`;
const dotsThreeMove = keyframes`${cssToMoveTop}`;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
  height: 1.5rem;

  .dots {
    position: relative;
    grid-gap: 1rem 1rem;
    gap: 1rem;

    width: 2.5rem;
    height: 1.75rem;

    div {
      background-color: #2e0037;
      border-radius: 50%;

      position: absolute;
      height: 1.5rem;
      width: 1.5rem;

      &.one {
        animation: ${dotsOneMove} 1s ease-in-out infinite;
      }

      &.two {
        animation-delay: 0.5s !important;
        animation: ${dotsTwoMove} 1s ease-in-out infinite;
      }

      &.three {
        animation-delay: 0.75s !important;
        animation: ${dotsThreeMove} 1s ease-in-out infinite;
      }
    }
  }
`;
