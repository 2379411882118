import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useErrorBoundaries } from "../../shared/components/error-boundary";

import errorImg from "../../assets/error404.svg";

import { Container } from "./styles";
import { checkout } from "../../actions/cart";
import { getTerminalID } from "../../configuration";
import { apiProducts } from "../../../services/api";
import { useViewDispatch } from "../../../context";

let goToBannerTimeout;
export const ErrorContainer = () => {
  const dispatch = useDispatch();
  const viewDispatch = useViewDispatch();
  const { disableError, errors } = useErrorBoundaries();

  const goToBanner = () => {
    dispatch(checkout());
    disableError();
    viewDispatch("step.banner");
  };

  useEffect(() => {
    const FIVE_SECONDS_IN_MILLISECONDS = 5000;

    (async () => {
      try {
        const terminal = Number(getTerminalID());

        await apiProducts.post("/api/log-code/save-error-front", {
          terminal,
          error: JSON.stringify(errors),
        });
      } catch (error) {
        const serializeErros = JSON.stringify(
          error,
          Object.getOwnPropertyNames(error)
        );

        window.desktopApp.log(
          `ErrorBoundaries (Front):: ${JSON.stringify(errors)}`
        );
        window.desktopApp.log(
          `ErrorBoundaries (Front) (no work):: ${serializeErros}`
        );

        console.error(`ErrorBoundaries (Front)::`, errors);
        console.error(`ErrorBoundaries (Front) (no work)::`, serializeErros);
      }

      goToBannerTimeout = setTimeout(goToBanner, FIVE_SECONDS_IN_MILLISECONDS);
    })();

    return () => {
      if (goToBannerTimeout) {
        clearTimeout(goToBannerTimeout);
      }
    };
  }, [errors]);

  return (
    <Container onClick={goToBanner} role="button">
      <div className="message">
        <div className="title">Opss... desculpe algo deu errado</div>
        <img src={errorImg} className="w90" alt=":(" />
        <p className="desc">você será redirecionado em breve.</p>
        <div className="ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Container>
  );
};
