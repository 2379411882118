import styled from "styled-components";

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .content {
    background-color: #fff;
    max-width: 350px;
    width: 100%;

    position: relative;
    overflow: hidden;

    border-radius: 0.5rem;
    margin: 0 auto;

    input[name="total"] {
      order: 0;
      width: 100%;

      outline: none;

      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      color: #000;

      border: 0;
      border-bottom: 1px solid #eee;
    }

    .keyboard-numeric {
      button {
        font-size: 1rem;
      }
    }

    .button__submit {
      border-radius: 0;
      width: 100%;
    }
  }
`;
