import React, { createContext, useCallback, useMemo, useState } from "react";
import Dialog from "../../components/dialog";
import MessageBox from "../../components/message-box";
import LoginModal from "../../components/loginModal";

export const DialogContext = createContext({});

const generateId = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

const removeDuplicatesById = (array) => {
  const uniqueObject = {};

  array.forEach((element) => {
    uniqueObject[element.id] = element;
  });

  return Object.values(uniqueObject);
};

export const DialogProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [loginModals, setLoginModals] = useState([]);

  const setCloseDialog = useCallback((id) => {
    setDialogs((oldDialogs) => oldDialogs.filter((dialog) => dialog.id !== id));
  }, []);

  const setCloseAlert = useCallback((id) => {
    setAlerts((oldDialogs) => oldDialogs.filter((dialog) => dialog.id !== id));
  }, []);

  const setCloseLoginModal = useCallback((id) => {
    setLoginModals((oldModals) =>
      oldModals.filter((dialog) => dialog.id !== id)
    );
  }, []);

  const defaultOptions = {
    id: generateId(),
    title: null,
    subTitle: null,
    message: null,
    icon: undefined,
    onConfirmClick: function () {
      setCloseDialog(this.id);
    },
    onConfirmText: undefined,
    onCloseClick: function () {
      setCloseDialog(this.id);
    },
  };

  const showAlert = useCallback((options) => {
    const newObj = {
      ...options,
      id: generateId(),
    };

    setAlerts((oldDialogs) => removeDuplicatesById([...oldDialogs, newObj]));

    return newObj.id;
  }, []);

  const setOpenDialog = useCallback((options) => {
    if (!options?.title) {
      throw new Error("Set title in object to open dialog");
    }

    const newObj = {
      ...defaultOptions,
      ...options,
      id: options?.id ? options.id : generateId(),
    };

    setDialogs((oldDialogs) => removeDuplicatesById([...oldDialogs, newObj]));

    return newObj.id;
  }, []);

  const setOpenLoginModal = useCallback((options) => {
    if (!options?.handleSubmit) {
      throw new Error("Set handleSubmit in object to open login modal");
    }

    const newObj = {
      ...options,
      id: options?.id ? options.id : generateId(),
    };

    setLoginModals((oldDialogs) => removeDuplicatesById([...oldDialogs, newObj]));

    return newObj.id;
  }, []);

  const value = useMemo(
    () => ({ setOpenDialog, setCloseDialog, showAlert, setOpenLoginModal }),
    [setOpenDialog, setCloseDialog, showAlert, setOpenLoginModal]
  );

  return (
    <DialogContext.Provider value={value}>
      {dialogs.length > 0 &&
        dialogs.map((dialog) => (
          <Dialog
            key={dialog.id}
            dialogTitle={dialog.title}
            messageTitle={dialog.subTitle}
            message={dialog.message}
            messageIcon={dialog.icon}
            okButtonText={dialog.onConfirmText}
            cancelButtonText={dialog.onCloseText}
            customActions={dialog.customActions}
            onOkButtonClick={() => {
              setCloseDialog(dialog.id);
              dialog.onConfirmClick();
            }}
            onCloseButtonClick={() => {
              setCloseDialog(dialog.id);
              dialog.onCloseClick();
            }}
          />
        ))}

      {alerts.map((alert) => (
        <MessageBox
          key={alert.id}
          {...alert}
          handleClose={() => {
            setCloseAlert(alert.id);

            if (alert.handleClose) {
              alert.handleClose();
            }
          }}
          handleCancel={
            alert.handleCancel
              ? () => {
                  setCloseAlert(alert.id);

                  alert.handleCancel();
                }
              : undefined
          }
          handleConfirm={
            alert.handleConfirm
              ? () => {
                  setCloseAlert(alert.id);

                  alert.handleConfirm();
                }
              : undefined
          }
          okText={alert.onConfirmText}
          cancelText={alert.cancelButtonText}
        />
      ))}

      {loginModals.map((props) => (
        <LoginModal
          handleCancel={() => {
            setCloseLoginModal(props.id);
            if (props.handleCancel) {
              props.handleCancel();
            }
          }}
          handleSubmit={() => {
            setCloseLoginModal(props.id);

            if (props.handleSubmit) {
              props.handleSubmit();
            }
          }}
        />
      ))}

      {children}
    </DialogContext.Provider>
  );
};
