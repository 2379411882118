import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getTerminalID } from "../../../aaone/configuration";
import CashReduce from "../../components/CashReduce";
import { PagesEnum } from "../../constants/pagesEnum";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { useAuth } from "../../hook/authHook";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useDialog } from "../../hook/dialogHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useLoading } from "../../hook/loadingHook";
import { getCloseCashDeskValues } from "../../../services/cashdeskService";
import { printReduceCashDesk } from "../../helpers/printer/printReduceCashDesk";
import { openDrawer } from "../../helpers/openDrawer";

// TODO: Add Config
const withHooks = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();
    const cashdesk = useCashdesk();
    const location = useLocation();
    const navigate = useNavigate();
    const dialog = useDialog();
    const loading = useLoading();
    const auth = useAuth();

    return (
      <Component
        {...props}
        setVisibleSidebarCart={setVisibleSidebarCart}
        navigate={navigate}
        location={location}
        cashdesk={cashdesk}
        dialog={dialog}
        loading={loading}
        auth={auth}
      />
    );
  };
};

class CashReduceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "REDUÇÃO DE CAIXA",
      openingAmount: 0,
      amount: 0,
      responsable: "",
      user: "",
      reduceDate: formatDateHelper(new Date(), "dd/MM/yyyy"),
      reduceTime: formatDateHelper(new Date(), "HH:mm:ss"),
      openingCount: 0,
      shouldWarnCahsierIsClosed: false,
      shouldShowCashierOpeningForm: false,
      isBlindCashReduce: false,
      manager: {},
      operator: {},
    };
  }

  componentDidMount() {
    const authManager = this.props.location?.state?.auth ?? null;

    if (
      this.props.cashdesk.cashdesk &&
      this.props.cashdesk.cashdesk.user !==
        this.props.auth.userAuthData.login &&
      !this.props.auth.userAuthData.isManager &&
      !authManager
    ) {
      setTimeout(() => {
        this.props.navigate(PagesEnum.NEED_AUTH, {
          state: {
            redirectUrl: PagesEnum.CASH_REDUCE,
          },
        });
      }, 300);

      return;
    } else if (!this.props.cashdesk.isCashdeskOnCurrentDay) {
      // TODO: Customizar alerta
      this.props.dialog.showAlert({
        message:
          "Para realizar essa operação, realize o fechamento do caixa e encerramento do dia anterior.",
      });

      // Ele não transferia a página, tive que colocar um timeout
      setTimeout(() => {
        this.props.navigate(PagesEnum.HOME);
      }, 300);

      return;
    }
    try {
      this.props.loading.setShowLoading(true);

      const manager = {
        nuuidOne: authManager
          ? authManager.user.nuuidOne
          : this.props.cashdesk.cashdesk.responsableId,
        referenceId: authManager
          ? authManager.user.employeeCode
          : this.props.cashdesk.cashdesk.userReferenceId,
        name: authManager
          ? authManager.user.login
          : this.props.cashdesk.cashdesk.responsable,
      };

      const operator = {
        nuuidOne: this.props.cashdesk.cashdesk.userId,
        referenceId: this.props.cashdesk.cashdesk.userReferenceId,
        name: this.props.cashdesk.cashdesk.user,
      };
      const openingAmount = this.props?.cashdesk?.cashdesk?.movements[0].amount;

      getCloseCashDeskValues(this.props.cashdesk.cashdesk.id).then((res) => {
        this.setState({
          amount: res.total,
        });

        this.props.loading.setShowLoading(false);
      });

      this.setState({
        manager,
        operator,
        openingAmount,
      });
    } catch (error) {
      console.error(error);
      this.props.loading.setShowLoading(false);
    }
  }

  handleClose() {
    this.props.setVisibleSidebarCart();
    this.props.navigate(PagesEnum.HOME);
  }

  handleClick() {
    try {
      this.props.loading.setShowLoading(true);
      const body = {
        amout: this.state.amount,
        userId: this.state.operator.nuuidOne,
        user: this.state.operator.name,
        responsableId: this.state.manager.nuuidOne,
        responsable: this.state.manager.name,
      };

      this.props.cashdesk.cashdeskReduce(body).then((cashdesk) => {
        printReduceCashDesk(cashdesk);

        //Abre a gaveta
        openDrawer();

        this.handleClose();
        this.props.loading.setShowLoading(false);
      });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  render() {
    // Se o caixa estiver no dia anterior
    if (!this.props.cashdesk.isCashdeskOnCurrentDay) {
      return <></>;
    }

    return (
      <>
        <CashReduce
          title={this.state.title}
          openingAmount={this.state.openingAmount}
          amount={this.state.amount}
          // TODO: Add Manager
          responsable={this.state.manager.name}
          user={this.state.operator.name}
          reduceDate={this.state.reduceDate}
          reduceTime={this.state.reduceTime}
          openingCount={this.props.cashdesk.cashdesk.openingCount}
          posId={getTerminalID()}
          handleClick={(values) => this.handleClick(values)}
          handleClose={() => this.handleClose()}
          onCartHide={() => this.props.setVisibleSidebarCart(false)}
          isBlindCashReduce={this.state.isBlindCashReduce}
        />
      </>
    );
  }
}

export default withHooks(CashReduceContainer);
