import React from "react";

import CardWrapper from "./style";

const Card = ({ cardDisplay, cardTitle, imgURL, eventClick }) => {
  return (
    <CardWrapper className={"card " + cardDisplay} onClick={eventClick}>
      <div className="">
        <img src={imgURL} className="card-img" alt="..." />
      </div>

      <div className="">
        <div className="card-body">
          <p className="card-title">{cardTitle}</p>
        </div>
      </div>
    </CardWrapper>
  );
};

export default React.memo(Card);
