import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  ButtonClose,
  ButtonDiscount,
  ButtonFooter,
  DiscountCartFooter,
  DiscountCartHeader,
  DiscountCartKeyboard,
  DiscountCartMain,
  DiscountCartModalContainer,
  ErrorMessage,
  InputDiscount,
} from "./styles";
import { KeyboardNumeric } from "../../../KeyboardNumeric";
import { currencyMask } from "../../../../helpers/masks";
import { useCart } from "../../../../hook/cartHook";
import Decimal from "decimal.js";
import { MANUAL_DISCOUNTS } from "../../../../constants/keyNamesSessionStorage";
import { useLoading } from "../../../../hook/loadingHook";
import { useDialog } from "../../../../hook/dialogHook";
import { currency } from "../../../../../aaone/shared/utils/currency";

const customStyle = {
  content: {
    maxWidth: "330px",
    maxHeight: "550px",
    background: "#ffffff",
    margin: "auto",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.60)",
    display: "block",
  },
  iconCloseModal: {
    float: "right",
    cursor: "pointer",
  },
};

const ModalDiscount = ({
  showModal,
  handleCancel,
  toggleModal,
  calcReshopDiscount,
}) => {
  const [inputValue, setInputValue] = useState(0);
  const [discountCalc, setDiscountCalc] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [keyboardActions, setKeyboardActions] = useState({});
  const [discountItems, setDiscountItems] = useState({});
  const [isPercentage, setIsPercentage] = useState();
  const { setShowLoading } = useLoading();
  const { showAlert } = useDialog();
  const { reshopTotalPrice, totalPrice, handleSetManualDiscount, Sale } =
    useCart();

  /**
   * Calculo do desconto
   */
  const handleInputChange = async () => {
    setErrorMessage("");
    let discount, formatedValue;

    if (isPercentage) {
      discount = new Decimal(
        new Decimal(
          (new Decimal(totalPrice) / 100) *
            new Decimal(new Decimal(keyboardActions.value || 0).toFixed(2))
        ).toFixed(2)
      );
      formatedValue = `${parseInt(keyboardActions.value || 0, 10)}%`;
    } else {
      discount = parseFloat(Number(keyboardActions.value) / 100);
      formatedValue = currencyMask(Number(keyboardActions.value) / 100);
    }

    let dataItemsAndValues = await Sale?.processItemsFinalizationSale(
      reshopTotalPrice
    );

    setDiscountOnItems(discount, dataItemsAndValues.items);

    if (totalPrice - discount <= 0) {
      setErrorMessage("O valor do desconto não é permitido.");
    }

    setDiscountItems(dataItemsAndValues.items);
    setDiscountCalc(discount);
    setInputValue(formatedValue);
  };

  function setDiscountOnItems(discount, dataItemsAndValues) {
    try {
      let sumTotalDiscount = 0;
      dataItemsAndValues = dataItemsAndValues.map((item) => {
        if (item.discountManual === undefined) {
          item.discountManual = 0;
        }

        let itemValue = Number(item.value) - item.discountManual;
        let itemSubTotalValue = Number(item.subtotal) - item.discountManual;

        // Porcentagem do Item em cima do valor total do Cupom
        let porcentagemItemTotal = new Decimal(
          new Decimal(
            (((item.vit_numlanctoprincipal ? 0 : itemValue * item.quantity) ||
            !item.vit_numlanctoprincipal
              ? itemValue * item.quantity
              : Number(itemSubTotalValue)) *
              100) /
              totalPrice
          ).toFixed(2)
        );

        // Calcula o desconto manual por item
        let itemManualDiscount = new Decimal(
          new Decimal(discount)
            .mul(porcentagemItemTotal <= 100 ? porcentagemItemTotal : 100)
            .div(100)
            .toFixed(2)
        );

        let valorItem = new Decimal(item.quantity * itemValue);

        if (
          valorItem.minus(itemManualDiscount).comparedTo(new Decimal(0.01)) ===
            -1 &&
          valorItem.comparedTo(new Decimal(0.01)) === 1
        ) {
          itemManualDiscount = new Decimal(
            new Decimal(valorItem).minus(0.01).toFixed(2)
          );
        }

        if (item.categoryIsFidelity) itemManualDiscount = new Decimal(0);
        sumTotalDiscount += Number(itemManualDiscount);

        item.discountManual = itemManualDiscount;
        item.discountedValue = new Decimal(
          valorItem.minus(itemManualDiscount).toFixed(2)
        );

        return item;
      });

      let appliedDiscount = dataItemsAndValues.reduce(
        (acc, curr) => acc.plus(curr.discountManual ?? 0),
        new Decimal(0)
      );

      const differenceDiscount = new Decimal(
        new Decimal(sumTotalDiscount).minus(discount).toFixed(2)
      );

      let changedDiscount = false;

      if (differenceDiscount.comparedTo(new Decimal(0)) === 1) {
        dataItemsAndValues.forEach((item) => {
          const valueWithDiscount = Number(item.discountedValue);

          if (
            new Decimal(item.discountManual).comparedTo(differenceDiscount) ===
              1 &&
            !changedDiscount &&
            differenceDiscount
              .minus(valueWithDiscount)
              .comparedTo(new Decimal(0)) === 1
          ) {
            item.discountManual = new Decimal(
              item.discountManual + differenceDiscount * -1
            );
            item.discountedValue = item.discountedValue.minus(
              item.discountManual
            );
            changedDiscount = true;
          }
        });
      }

      //Diferença entre discontos manuais aplicados e desconto manual que deveria ser aplicado
      let unusedDiscount = new Decimal(discount).minus(appliedDiscount);

      if (unusedDiscount.comparedTo(new Decimal(0)) === 0) {
        return; // Sem diferença, retorne
      }

      //Sort por maior para que tenha maior chance de resolver nas primeiras interações
      dataItemsAndValues.sort((a, b) => b.discountedValue - a.discountedValue);
      for (let i = 0; i < dataItemsAndValues.length; i++) {
        let item = dataItemsAndValues[i];
        if (item.discountedValue <= 0.01) {
          // Item já está com o valor maximo de desconto aplicado, então passa par ao próximo
          continue;
        }

        //Valor maximo que pode ser aplicado é o discountedValue - 0,01
        let maxAvaibleDiscount = new Decimal(item.discountedValue).minus(
          new Decimal(0.01)
        );

        if (maxAvaibleDiscount >= unusedDiscount) {
          //valor de disconto que sobrou é menor que o valor de disconto maximo que o item suporta
          item.discountManual = new Decimal(item.discountManual).plus(
            unusedDiscount
          );

          // portanto joga o valor de disconto que sobrou no item e acaba o loop
          unusedDiscount = new Decimal(0);
          break;
        } else {
          // Item não suporta todo o disconto restante então se adiciona o maximo possivel nele e passa para o proximo (que vai cair no if de cima)
          item.discountManual = Number(
            new Decimal(item.discountManual).plus(maxAvaibleDiscount).toFixed(2)
          );

          unusedDiscount = unusedDiscount.minus(maxAvaibleDiscount);
        }

        item.discountedValue = new Decimal(item.quantity * item.value).minus(
          item.discountManual
        );
      }

      if (unusedDiscount?.comparedTo(new Decimal(0)) === 1) {
        showAlert({
          message: `O valor da venda não pode ser inferior a R$0,01 por item.\nConfirma desconto sugerido de ${
            isPercentage ? "99%" : currency(discount - unusedDiscount)
          }?`,
          handleConfirm: () => {
            if (isPercentage) {
              keyboardActions.set(99);
            } else {
              keyboardActions.set(
                Number(
                  String(new Decimal(discount - unusedDiscount).toFixed(2))
                    .toString()
                    .replace(/\D/g, "")
                )
              );
            }
          },
          onConfirmText: "Sim",
          handleCancel: () => {},
        });
        setErrorMessage("");
      }
    } catch (err) {
      if (window.Android) {
        console.log("error on modalDiscount -> ", JSON.stringify(err));
      } else {
        console.log("error on modalDiscount -> ", err);
      }
    }
  }

  const handleChangeType = (isPercentage = false) => {
    setIsPercentage(isPercentage);
    keyboardActions.set(0);
  };

  const handleAddDiscount = async () => {
    try {
      setShowLoading();
      sessionStorage.setItem(MANUAL_DISCOUNTS, JSON.stringify(discountItems));
      await calcReshopDiscount(discountCalc);
      handleSetManualDiscount(discountCalc);
      toggleModal();
      setShowLoading(false);
    } catch (err) {
      console.log("err on ModalDiscount -> ", JSON.stringify(err));
      setShowLoading(false);
    }
  };

  const keyboardButtons = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    {
      className: "clear",
      value: "Limpar",
      onClick: () => {
        keyboardActions.set(0);
      },
    },
    "0",
    {
      className: "backspace",
      value: "←",
      onClick: () => {
        if (inputValue.toString().length > 0) {
          keyboardActions.set(
            inputValue.toString().replace(/\D/g, "").slice(0, -1)
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (keyboardActions?.value >= 0) {
      handleInputChange();
    }
  }, [keyboardActions, isPercentage]);

  return (
    <>
      {/* Modal desconto Manual */}
      <Modal
        isOpen={showModal}
        closeOnOverlayClick={true}
        center
        style={customStyle}
        ariaHideApp={false}
      >
        <DiscountCartModalContainer>
          <div className="content__container">
            <DiscountCartHeader>
              <div>
                <h2>Desconto</h2>
                <ButtonClose onClick={handleCancel}>x</ButtonClose>
              </div>

              <div>
                <ButtonDiscount
                  disabled={!isPercentage}
                  onClick={() => handleChangeType(false)}
                >
                  $
                </ButtonDiscount>
                <ButtonDiscount
                  disabled={isPercentage}
                  onClick={() => handleChangeType(true)}
                >
                  %
                </ButtonDiscount>
              </div>
            </DiscountCartHeader>

            <DiscountCartMain>
              <span>Valor Base: {currencyMask(totalPrice)}</span>
              <InputDiscount
                name="discount"
                value={inputValue}
                onFocus={(el) =>
                  el.currentTarget.setSelectionRange(
                    el.currentTarget.value.length,
                    el.currentTarget.value.length
                  )
                }
                onChange={(el) => {
                  keyboardActions.set(
                    Number(el.target.value.toString().replace(/\D/g, ""))
                  );
                }}
                valid={errorMessage}
              />
              <ErrorMessage className="error-message">
                {errorMessage}
              </ErrorMessage>
              <DiscountCartKeyboard>
                <KeyboardNumeric
                  setActions={setKeyboardActions}
                  newButtons={keyboardButtons}
                />
              </DiscountCartKeyboard>
            </DiscountCartMain>
          </div>
          <DiscountCartFooter>
            <ButtonFooter className="cancel" onClick={handleCancel}>
              Voltar
            </ButtonFooter>
            <ButtonFooter
              className="confirm"
              disabled={errorMessage}
              onClick={handleAddDiscount}
            >
              Confirmar
            </ButtonFooter>
          </DiscountCartFooter>
        </DiscountCartModalContainer>
      </Modal>
    </>
  );
};

export default ModalDiscount;
