import React from "react";

import ModalWrapper from "./style";
import Button from "../button";

const ModalDrinks = ({
  mTitle,
  mSubtitle,
  mTextBtnPrimary,
  mTextBtnSecondary,
  mBtnPrimarClassName,
  mBtnSecondaryClassName,
  mClassName,
  onPrimaryClick,
  onSecondaryClick,
  mTitleSub,
}) => {
  return (
    <ModalWrapper>
      <div className={`modal ${mClassName}`}>
        <h1 className="title">{mTitle}</h1>

        <h2 className="subtitle">{mSubtitle}</h2>

        <span className="titlesub">
          {mTitleSub}
          <span className="titlesubIdade"> 18 anos?</span>
        </span>

        {mTextBtnSecondary !== false && (
          <Button
            text={mTextBtnSecondary}
            buttonUIType={mBtnSecondaryClassName}
            buttonUI="highlight"
            handleClick={() => {
              onSecondaryClick();
            }}
          />
        )}

        <Button
          text={mTextBtnPrimary}
          buttonUIType={mBtnPrimarClassName}
          buttonUI="highlight"
          handleClick={() => {
            onPrimaryClick();
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default React.memo(ModalDrinks);
