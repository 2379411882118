import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Content, Button, Keyboard } from "../../shared/components/modules";

import {
  showPrismaPage,
  showOptionName,
  getThemeSettings,
  isShowTellInfo,
  isShowYourNameTabletConfig,
  isDesktopApp,
} from "../../configuration";
import { useSelector } from "react-redux";
import logoBobs from "../../assets/logobobs.png";
import { reshopCalculateValues } from "../../../services/paymentService";
import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { useViewDispatch } from "../../../context";

const CPFContainer = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const {
    getTranslateName,
    isLayout,
    isLayoutAAOne,
    getScreenTitleBySlug,
    getScreenIsActiveBySlug,
  } = useLayoutAa();

  const askCpfInvoiceText =
    getTranslateName(t, "ask_cpf_invoice") ||
    getScreenTitleBySlug("CPF_NA_NOTA") ||
    DEFAULT_LOCAL_TEXTS.ask_cpf_invoice;

  const receiptIdentificationText =
    getTranslateName(t, "receipt_identification") ||
    "Digite seu CPF para identificação no cupom fiscal";
  const buttonBackText =
    getTranslateName(t, "buttons.back") ||
    getThemeSettings?.text?.button_back_cpf_invoice ||
    "Voltar";

  const isShowPrismaPage =
    getScreenIsActiveBySlug("RECEBA_NA_MESA") ?? showPrismaPage;
  const isShowCarIdentification =
    getScreenIsActiveBySlug("PLACA_VEICULO") ?? true;
  const isShowYourName = isDesktopApp ? getScreenIsActiveBySlug("INFORME_SEU_NOME") ?? true : isShowYourNameTabletConfig;
  const buttonNoThanksText =
    getTranslateName(t, "buttons.noThanks") ||
    getThemeSettings?.text?.button_no_thanks_cpf_invoice ||
    "Não, obrigado!";

  const backStep = useCallback(() => {
    //Foi removido temporariamente a tela de selecionar onde vai comer, estamos passando viagem fixo
    //Quando voltar tela só apagar o dispatch orderSummary e voltar o dispatch step.withdrawn
    //viewDispatch("step.orderSummary");

    if (!isShowPrismaPage || isLayout("RAGAZZOEXPRESS")) {
      viewDispatch("step.orderSummary");
    } else {
      if (localStorage.getItem("typeCode") === "L" || !showPrismaPage) {
        viewDispatch("step.prisma");
      } else {
        viewDispatch("step.orderSummary");
      }
    }
  }, [viewDispatch, isShowPrismaPage]);

  const nextStep = useCallback(() => {
    if (isLayoutAAOne) {
      if (isShowCarIdentification) {
        viewDispatch("step.carIdentification");
      } else {
        if (isShowYourName) {
          if (isShowTellInfo) {
            viewDispatch("step.tellIdentification");
          } else {
            viewDispatch("step.orderIdentification");
          }
        } else {
          viewDispatch("step.payment");
        }
      }
    } else {
      if (!showOptionName) {
        viewDispatch("step.payment");
      } else {
        if (localStorage.getItem("styleAA") === "DRIVE") {
          viewDispatch("step.carIdentification");
        } else {
          if (isShowTellInfo) {
            viewDispatch("step.tellIdentification");
          } else {
            viewDispatch("step.orderIdentification");
          }
        }
      }
    }
  }, [isLayoutAAOne, isShowCarIdentification, viewDispatch, isShowYourName]);

  const isBobsLayout = isLayout("BOBS");

  useEffect(() => {
    const { clientValue, reshopValue } = reshopCalculateValues(cartItems);

    localStorage.setItem("clientValue", JSON.stringify(clientValue));
    localStorage.setItem("reshopValue", JSON.stringify(reshopValue));
    localStorage.removeItem("tellkey", true);
    console.log(localStorage.getItem("clientValue"));
  }, [cartItems]);

  return (
    <>
      <Content
        bgColor="background-primary hasIMG"
        hasImg={isBobsLayout}
        srcImg={logoBobs}
        title={askCpfInvoiceText}
        subtitle={
          getThemeSettings?.text?.describe_ask_cpf_invoice
            ? getThemeSettings?.text?.describe_ask_cpf_invoice
            : isBobsLayout
            ? ""
            : receiptIdentificationText
        }
        contentChildren={true}
        className="full"
      >
        <Keyboard handleClick={() => nextStep()} typeKeyboard={"dasdasdsa"} />

        <footer className="footer">
          <Button
            text={buttonBackText}
            buttonUIType="outline-primary"
            buttonUI={"normal"}
            handleClick={() => backStep()}
          />

          <Button
            text={buttonNoThanksText}
            buttonUIType="outline-primary"
            buttonUI={"normal"}
            handleClick={() => {
              nextStep();
              window.localStorage.setItem("cpfDocument", "");
            }}
          />
        </footer>
      </Content>
    </>
  );
};

export default React.memo(CPFContainer);
