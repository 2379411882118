import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import classNames from "classnames";
import { useLoading } from "../../hook/loadingHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import CardsTable from "../../components/SallerTable/CardsTable";
import Filters from "../../components/SallerTable/Filters";
import {
  Container,
  Content,
  ContainerSlider,
  Sidebar,
  CustomButtom,
  InputContent,
  ContainerActionsButton,
} from "./styles";
import {
  NextItemSlider,
  PreviousItemSlider,
} from "../../components/ArrowsCarousel";
import Slider from "react-slick";
import { KeyboardNumeric } from "../../components/KeyboardNumeric";
import {
  MdOutlineRefresh,
  MdOutlineReceiptLong,
  MdLogin,
  MdLoop,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { useCashdesk } from "../../hook/cashdeskHook";
import { tableInProgressService } from "../../../services/tableInProgress";
import { ModalSeller } from "../../components/modals/ModalMesa";
import { ModalQuantityClient } from "../../components/modals/ModalQuantityClient";
import { useDialog } from "../../hook/dialogHook";
import { useCart } from "../../hook/cartHook";
import { escOnlyNumbersHelper } from "../../helpers/escOnlyNumbersHelper";
import { isSmartPos } from "../../../aaone/configuration";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { useSallerTable } from "../../hook/sallerTableHook";


let intervalSearchTable;
const SallerTableContainer = () => {
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { setShowLoading } = useLoading();
  const cashdesk = useCashdesk();
  const { showAlert, setOpenDialog } = useDialog();
  const navigate = useNavigate();
  const [filter, setFilter] = useState("ALL");
  const [isOpenModalQuantity, setIsOpenModalQuantity] = useState(false);
  const { clearCartTable, setSaleStartHour } = useCart();
  const {
    selectedSaleInProgressId,
    setSelectedSaleInProgressId,
    handleSearchTable,
    keyboardActions,
    handleSelectSaller,
    setIsOpenModalSaller,
    tableMap,
    setKeyboardActions,
    isOpenModalSaller
  } = useSallerTable();
  const location = useLocation();
  const inputRef = useRef(null);

  const systemParams = getSystemParamsHelper();
  const onCashierReductionOpenWithdrawingScreen =
    systemParams?.onCashierReductionOpenWithdrawingScreen || false;

  const dialogCashdeskClosedSettings = {
    title: "Abertura do Caixa",
    subTitle: "O caixa não está aberto",
    message: "Deseja realizar a abertura do caixa neste momento?",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Abrir o caixa",
    onConfirmClick: async () => {
      setShowLoading();
      await cashdesk.getLastCashdesk();
      setShowLoading(false);
      navigate(PagesEnum.CASH_OPEN);
    },
  };

  const dialogCashdeskCurrentDaySettings = {
    title: "Fechamento de Caixa",
    subTitle: "O caixa está aberto",
    message:
      "Para continuar vendendo, realize o fechamento do caixa e encerramento do dia anterior.",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Fechar o caixa",
    onConfirmClick: () => {
      navigate(
        onCashierReductionOpenWithdrawingScreen
          ? PagesEnum.CASH_CLOSE_WITHDRAW
          : PagesEnum.CASH_CLOSE
      );
    },
  };

  useEffect(() => {
    if (location.pathname === PagesEnum.SALLER_TABLE) {
      if (intervalSearchTable) {
        clearInterval(intervalSearchTable);
      }

      intervalSearchTable = setInterval(() => {
        handleSearchTable({cashdesk: cashdesk.cashdesk});
      }, 30000);

      return () => {
        if (intervalSearchTable) {
          clearInterval(intervalSearchTable);
        }
      };
    }
  }, [location]);

  useEffect(() => {
    if (keyboardActions?.value && inputRef?.current) {
      const value = escOnlyNumbersHelper(keyboardActions.value);

      inputRef.current.value = value;
      keyboardActions.set(value);
    }
  }, [keyboardActions?.value, inputRef]);

  useEffect(() => {
    clearCartTable();
    handleSearchTable({cashdesk: cashdesk.cashdesk});
    try {
      setVisibleSidebarCart(false);
      setShowLoading(false);
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  }, [handleSearchTable, location]);

  const responsiveSerialize = useCallback((options) => {
    const { breakpoint, quantity, quantityH } = options;
    return {
      breakpoint: breakpoint,
      settings: {
        rows: quantityH,
        slidesPerRow: quantity,
      },
    };
  }, []);

  const settingsCarousel = useMemo(
    () => ({
      infinite: false,
      speed: 500,
      rows: 5,
      slidesPerRow: 9,
      prevArrow: <PreviousItemSlider />,
      nextArrow: <NextItemSlider />,
      responsive: [
        responsiveSerialize({ breakpoint: 3000, quantity: 9, quantityH: 5 }),
        responsiveSerialize({ breakpoint: 1920, quantity: 9, quantityH: 5 }),
        responsiveSerialize({ breakpoint: 1900, quantity: 8, quantityH: 5 }),
        responsiveSerialize({ breakpoint: 1682, quantity: 7, quantityH: 4 }),
        responsiveSerialize({ breakpoint: 1495, quantity: 6, quantityH: 4 }),
        responsiveSerialize({ breakpoint: 1366, quantity: 5, quantityH: 4 }),
        responsiveSerialize({ breakpoint: 1250, quantity: 4, quantityH: 3 }),
        responsiveSerialize({ breakpoint: 1080, quantity: 4, quantityH: 3 }),
        responsiveSerialize({ breakpoint: 990, quantity: 3, quantityH: 3 }),
        responsiveSerialize({ breakpoint: 845, quantity: 2, quantityH: 2 }),
        responsiveSerialize({ breakpoint: 640, quantity: 2, quantityH: 2 }),
        responsiveSerialize({ breakpoint: 610, quantity: 1, quantityH: 1 }),
        responsiveSerialize({ breakpoint: 360, quantity: 3, quantityH: 4 }),
      ],
    }),
    [responsiveSerialize]
  );

  const handleSelectTable = (number, numberOfCustomers, table) => {
    if (!number) {
      return;
    }

    const positionsTable = table?.data?.positions || [];
    const positionData = {
      positions: positionsTable.map((positionData, index) => ({
        position: index + 1,
        name: String(positionData.name),
        status: String(positionData.status)
      })),
    };
    setSaleStartHour(new Date());

    sessionStorage.setItem("tableSelected", number);
    sessionStorage.setItem("customersSelected", numberOfCustomers);
    sessionStorage.setItem("positionData", JSON.stringify(positionData));

    navigate(PagesEnum.HOME);
  };

  const handleTableClosing = (number) => {
    showAlert({
      message: `A Mesa ${number} Está em Processo de Fechamento`,
    });
  };

  const handleConsultTable = async (number) => {
    if (!number) {
      return;
    }
    try {
      const res = await tableInProgressService({
        CashDeskOpenDateId: cashdesk.cashdesk.cashDeskOpenDate.id,
        tableNumber: number,
      });

      if (res && res.tableMap && res.tableMap.length > 0) {
        const selectedTable = res.tableMap[number - 1];
        const saleInProgressId = selectedTable.saleInProgressId;

        if (saleInProgressId !== null) {
          await handleSelectSaller(saleInProgressId);
        } else {
          showAlert({
            message: `Mesa ${number} ainda não possui nenhum produto lançado!`,
          });
        }
      } else {
        console.error("Mesa não encontrada");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setIsOpenModalSaller(false);
    setSelectedSaleInProgressId(null);
  };

  const handleOpenModalQuantity = useCallback((number) => {
    if (!cashdesk.isCashdeskOpen) {
      setOpenDialog(dialogCashdeskClosedSettings);
      return;
    } else if (!cashdesk.isCashdeskOnCurrentDay) {
      setOpenDialog(dialogCashdeskCurrentDaySettings);
      return;
    }

    setIsOpenModalSaller(false);
    setIsOpenModalQuantity(true);
    setSelectedSaleInProgressId();
    sessionStorage.setItem("tableSelected", number);
  }, []);

  const handleModalQuantityClose = useCallback(() => {
    setIsOpenModalQuantity(false);
    sessionStorage.removeItem("positionData");
  }, []);

  const handleAccessButtonClick = (tableNumber) => {
    const selectedTable = tableMap.find(
      (table) => String(table.tableNumber) === tableNumber
    );

    if (selectedTable) {
      if (selectedTable.visualStatus === "FREE") {
        handleOpenModalQuantity(selectedTable.tableNumber);
      } else if (selectedTable.visualStatus === "CLOSING") {
        handleTableClosing(selectedTable.tableNumber);
      } else {
        handleSelectTable(
          selectedTable.tableNumber,
          selectedTable?.data?.numberOfCustomers,
          selectedTable.visualStatus
        );
      }
    }
  };

  const keyboardButtons = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    {
      className: "backspace",
      value: "←",
    },
    "0",
    {
      className: "send",
      onClick: () => {},
    },
  ];

  const SlickSlider = useCallback(() => {
    const filteredByNumber = tableMap.filter((table) => {
      const tableNumber = String(table.tableNumber);
      return (
        !keyboardActions?.value ||
        tableNumber.includes(keyboardActions?.value ?? "")
      );
    });

    const filteredTables =
      filter === "ALL"
        ? filteredByNumber
        : filteredByNumber.filter((table) => {
            const visualStatus = table.visualStatus
              ? table.visualStatus.toUpperCase()
              : null;
            return visualStatus === filter;
          });

    if (filteredTables.length === 0) {
      return <div className="emptyList">NENHUMA MESA ENCONTRADA</div>;
    }

    const getDifferenceInMinutes = (dateString) => {
      const currentDate = new Date();
      const targetDate = new Date(dateString);
      const differenceInMilliseconds = currentDate - targetDate;
      const differenceInMinutes = Math.floor(
        differenceInMilliseconds / (1000 * 60)
      );
      const hours = Math.floor(differenceInMinutes / 60);
      const minutes = differenceInMinutes % 60;
      return hours > 0
        ? `${hours.toString().padStart(2)}h: ${minutes
            .toString()
            .padStart(2, "0")}m`
        : `${minutes.toString().padStart(1, "0")}m`;
    };

    return (
      <div className={classNames("carousel__tables", { isSmartPos })}>
        {isSmartPos && (
          <>
            <div className="container__actions">
              <label>Buscar mesa</label>
              <InputContent className={classNames({ isSmartPos })}>
                <input
                  type="text"
                  pattern="[0-9]*"
                  value={keyboardActions?.value}
                  onChange={(e) => {
                    e.target.value = escOnlyNumbersHelper(e.target.value);

                    if (keyboardActions?.set) {
                      keyboardActions.set(e.target.value);
                    }

                    return e;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && keyboardActions?.value) {
                      handleConsultTable(keyboardActions.value);
                    }
                  }}
                  ref={inputRef}
                  placeholder="Número da mesa"
                />
                <CustomButtom
                  className="btn-outline btn-clean"
                  onClick={keyboardActions?.resetInput}
                >
                  Limpar
                </CustomButtom>
              </InputContent>
            </div>
            <ContainerActionsButton className="container__actions_button">
              <CustomButtom
                onClick={() => keyboardActions?.resetInput()}
                className="btn-outline btn-transfer"
              >
                <MdLoop size="1.2rem" />
                Transferir
              </CustomButtom>
              <CustomButtom
                className="btn-outline btn-consult"
                onClick={() => handleConsultTable(keyboardActions?.value ?? "")}
              >
                <MdOutlineReceiptLong size="1.3rem" />
                Consultar
              </CustomButtom>
              <CustomButtom
                className="btn-outline btn-access"
                onClick={() => {
                  if (keyboardActions?.value.trim() !== "") {
                    handleAccessButtonClick(keyboardActions.value);
                  }
                }}
              >
                <MdLogin size="1.3rem" />
                Acessar
              </CustomButtom>
            </ContainerActionsButton>
          </>
        )}

        <Slider {...settingsCarousel}>
          {filteredTables.map((table) => {
            const tableNumber = String(table.tableNumber);
            const numberOfCustomers = String(table?.data?.numberOfCustomers);

            return (
              <CardsTable
                key={table.saleInProgressId}
                handleSelectTable={() => {
                  if (table.visualStatus === "FREE") {
                    handleOpenModalQuantity(tableNumber);
                  } else if (table.visualStatus === "CLOSING") {
                    handleTableClosing(tableNumber);
                  } else {
                    handleSelectTable(
                      tableNumber,
                      numberOfCustomers,
                      table,
                      table.visualStatus
                    );
                  }
                }}
                handleSelectSaller={() =>
                  handleSelectSaller(table.saleInProgressId)
                }
                number={tableNumber}
                value={table.total}
                elapsedTime={getDifferenceInMinutes(table.dateTimeStart)}
                info={table.visualStatus}
                delayTime={
                  getDifferenceInMinutes(table.dateTimeStart).length > 3 ?? true
                }
              />
            );
          })}
        </Slider>
      </div>
    );
  }, [tableMap, keyboardActions?.value, filter, settingsCarousel]);

  return (
    <Container className={classNames({ isSmartPos })}>
      <Content className={classNames({ isSmartPos })}>
        <ContainerSlider className={classNames({ isSmartPos })}>
          <Filters
            data={tableMap}
            activeFilter={filter}
            setFilter={setFilter}
          />
          <SlickSlider />
        </ContainerSlider>
        <Sidebar className={classNames({ display__hide: isSmartPos })}>
          <div className="btn-table">
            <CustomButtom
              onClick={() => keyboardActions?.resetInput()}
              className="btn-outline btn-transfer"
            >
              <MdLoop size="1.2rem" />
              Transferir
            </CustomButtom>
            <CustomButtom
              onClick={() => handleSearchTable({cashdesk: cashdesk.cashdesk})}
              className="btn-outline btn-refresh"
            >
              <MdOutlineRefresh size="1.2rem" />
              Atualizar
            </CustomButtom>
          </div>
          <label>Buscar mesa</label>
          <InputContent>
            <input
              type="text"
              pattern="[0-9]*"
              value={keyboardActions?.value}
              onChange={(e) => {
                e.target.value = escOnlyNumbersHelper(e.target.value);

                if (keyboardActions?.set) {
                  keyboardActions.set(e.target.value);
                }

                return e;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && keyboardActions?.value) {
                  handleConsultTable(keyboardActions.value);
                }
              }}
              ref={inputRef}
              placeholder="Número da mesa"
            />
            <CustomButtom
              className="btn-outline btn-clean"
              onClick={keyboardActions?.resetInput}
            >
              Limpar
            </CustomButtom>
          </InputContent>
          <KeyboardNumeric
            setActions={setKeyboardActions}
            newButtons={keyboardButtons}
          />
          <div className="btn-table">
            <CustomButtom
              className="btn-outline btn-consult"
              onClick={() => handleConsultTable(keyboardActions?.value ?? "")}
            >
              <MdOutlineReceiptLong size="1.3rem" />
              Consultar
            </CustomButtom>
            <CustomButtom
              className="btn-outline btn-access"
              onClick={() => {
                if (keyboardActions?.value.trim() !== "") {
                  handleAccessButtonClick(keyboardActions.value);
                }
              }}
            >
              <MdLogin size="1.3rem" />
              Acessar
            </CustomButtom>
          </div>
        </Sidebar>
      </Content>
      <ModalQuantityClient
        isOpen={isOpenModalQuantity}
        onClose={handleModalQuantityClose}
        saleInProgressId={selectedSaleInProgressId}
      />
      <ModalSeller
        isOpen={isOpenModalSaller}
        onClose={handleClose}
        saleInProgressId={selectedSaleInProgressId}
      />
    </Container>
  );
};

export default SallerTableContainer;
