import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import {
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_CART,
  CLEAR_TABLE,
  cartReducer,
  ADD_DISCOUNTS_DATA_RESHOP,
  ADD_COUPON,
  REMOVE_COUPON,
  DUPLICATE_PRODUCT,
} from "./reducer";

import { ModalAddProductByCode } from "../../components/modals/ModalAddProductByCode";
import { nsuValueHelper } from "../../helpers/nsuValueHelper";
import { useCategoriesNavigate } from "../../hook/categoriesHook";
import { ModalToEat } from "../../components/modals/ModalToEat";
import { ModalCPFInInvoice } from "../../components/modals/ModalCPFInInvoice";
import { ModalAuthFidelity } from "../../components/modals/ModalAuthFidelity";
import { ModalFidelityData } from "../../components/modals/ModalFidelityData";
import { useAuth } from "../../hook/authHook";
import { useLoading } from "../../hook/loadingHook";
import { ModalQuantityProduct } from "../../components/modals/ModalQuantityProduct";
import { getLocalPayments } from "../../helpers/multiPaymentsHelper";
import { undoTEFPayment } from "../../helpers/paymentHelper.js";
import {
  MANUAL_DISCOUNTS,
  PAYMENTS_KEY_NAME,
  PDV_ORDER_AUTHORIZATION_STATUS,
  PDV_ORDER_IS_TAKE_OUT,
  RESHOP_ACTIVE_OPTIONAL_CAMPAIGN,
  RESHOP_DISCOUNTS,
  RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { ModalProductFractional } from "../../components/modals/ModalProductFractional";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useDialog } from "../../hook/dialogHook";
import { currencyMask } from "../../helpers/masks";
import ModalQuantityClient from "../../components/modals/ModalQuantityClient";
import Decimal from "decimal.js";
import { isSmartPos } from "../../../aaone/configuration";

export const CartContext = createContext({});

let isFirstLoad = false;
export const CartProvider = ({ children }) => {
  const { handleChangeCategory } = useCategoriesNavigate();
  const location = useLocation();
  const { setShowLoading } = useLoading();
  const { showAlert } = useDialog();
  const { reshop } = useAuth();

  const [isOpenModalFidelity, setIsOpenModalFidelity] = useState(false);
  const [isOpenModalFidelityData, setIsOpenModalFidelityData] = useState(false);
  const [isOpenModalToEat, setIsOpenModalToEat] = useState(false);
  const [isOpenModalCPFInInvoice, setIsOpenModalCPFInInvoice] = useState(false);
  const [isOpenModalProductByCode, setIsOpenModalProductByCode] =
    useState(false);
  const [isOpenModalTotalProduct, setIsOpenModalTotalProduct] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState([]);
  const [manualDiscount, setManualDiscount] = useState(0);
  const [activeOptionalCampaign, setActiveOptionalCampaign] = useState([]);
  const [isOpenModalProductFractional, setIsOpenModalProductFractional] =
    useState(false);
  const [isOpenModalQuantity, setIsOpenModalQuantity] = useState(false);
  // Controla a hora que foi iniciada a venda
  const [, setSaleStartHour] = useState(null);

  const [quantityToCart, setQuantityToCart] = useState(1);
  const [productFractionalData, setProductFractionalData] = useState(null);

  const { verifyWithdrawStatus } = useCashdesk();
  const [state, dispatch] = useReducer(cartReducer, {
    coupons: [],
    cart: [],
    discountsReshop: null,
  });

  const reshopTotalPrice = reshop.totalPrice;
  const setReshopTotalPrice = reshop.setTotalPrice;

  const [isCartVisible, setIsCartVisible] = useState(() => {
    const isLoaded = isFirstLoad;
    isFirstLoad = true;

    if (isSmartPos && !isLoaded) {
      return false;
    }

    return !isSmartPos;
  });

  const [oldCurrentProductStepContainer, setOldCurrentProductStepContainer] =
    useState(null);
  const [currentProductStep, setCurrentProductStep] = useState([]);
  const [action, setActions] = useState({});

  // Estados da Venda Mesa
  const [selectedTablePosition, setSelectedTablePosition] = useState(null);

  const addProductToCart = useCallback((product) => {
    dispatch({
      type: ADD_PRODUCT,
      product,
    });
  }, []);

  const duplicateProductInCart = useCallback((product) => {
    dispatch({
      type: DUPLICATE_PRODUCT,
      product,
    });
  }, []);

  const addDiscountsReshop = useCallback((data) => {
    dispatch({
      type: ADD_DISCOUNTS_DATA_RESHOP,
      discountsData: data,
    });

    setReshopTotalPrice(Number(data?.TotalDesconto ?? 0));
  }, []);

  const addCouponToCart = useCallback((couponName, data) => {
    dispatch({
      type: ADD_COUPON,
      coupon: {
        value: couponName,
        details: data,
      },
    });
  }, []);

  const removeCouponInCart = useCallback((couponName) => {
    dispatch({
      type: REMOVE_COUPON,
      couponName,
    });
  }, []);

  const removeProductFromCart = useCallback(
    (product, isFidelity = false, manager) => {
      dispatch({
        type: REMOVE_PRODUCT,
        product,
        manager,
      });

      if (state?.Sale?.getItems()?.length === 0) {
        setSaleStartHour(null);
      }

      redirectCategoryProduct(product, isFidelity);
    }
  );

  const redirectCategoryProduct = useCallback((product, isFidelity) => {
    const productObj = product.ClassSaleStep.getSteps();

    if (productObj.categoryId) {
      setCurrentProductStep([]);
    }

    if (productObj.categoryId && productObj.categoryIsFidelity !== true) {
      handleChangeCategory({
        categoryId: productObj.categoryId,
        isFidelity: isFidelity,
      });
    }
  }, []);

  const clearCart = useCallback(
    (manager) => {
      // Atualiza a sangria do caixa
      verifyWithdrawStatus();

      setSaleStartHour(null);

      localStorage.removeItem(PAYMENTS_KEY_NAME);
      sessionStorage.removeItem(PDV_ORDER_AUTHORIZATION_STATUS);
      sessionStorage.removeItem(PDV_ORDER_IS_TAKE_OUT);
      sessionStorage.removeItem(MANUAL_DISCOUNTS);
      sessionStorage.removeItem(RESHOP_DISCOUNTS);
      sessionStorage.removeItem(RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME);
      sessionStorage.removeItem(RESHOP_ACTIVE_OPTIONAL_CAMPAIGN);
      sessionStorage.removeItem("positionData");

      setPaymentSuccess([]);
      setActiveOptionalCampaign([]);
      setCurrentProductStep([]);
      setReshopTotalPrice(0);
      handleSetManualDiscount(0);

      dispatch({
        type: CLEAR_CART,
        pathname: location.pathname,
        manager,
      });

      reshop.signOut();
    },
    [reshop, location]
  );

  // Reseta a mesa selecionada
  const clearCartTable = useCallback(
    (manager) => {
      setSaleStartHour(null);

      sessionStorage.removeItem(MANUAL_DISCOUNTS);
      sessionStorage.removeItem(RESHOP_DISCOUNTS);
      sessionStorage.removeItem("positionData");

      setSelectedTablePosition(null);
      setCurrentProductStep([]);
      setReshopTotalPrice(0);
      handleSetManualDiscount(0);

      dispatch({
        type: CLEAR_TABLE,
        pathname: location.pathname,
        manager,
      });

      reshop.signOut();
    },
    [reshop, location]
  );

  const addPaymentSuccess = useCallback(() => {
    const payments = getLocalPayments();

    setPaymentSuccess(payments);
  }, []);

  /**
   * Desfaz um pagamento
   */
  const undoPayment = useCallback(
    (payment) => {
      if (payment.details.controlCode) {
        setShowLoading(true, "Desfazendo pagamento, aguarde...");
      }

      undoTEFPayment(
        payment,
        (payments) => {
          setPaymentSuccess(payments);

          if (payment.details.controlCode) {
            setShowLoading(true, "Pagamento desfeito com sucesso...");

            setTimeout(() => {
              setShowLoading(false);
            }, 4000);
          }

          showAlert({
            message: `Os pagamentos abaixo foram cancelados.\n
        ${
          payment.details.controlCode
            ? "NSU: " + payment.details.controlCode + " | "
            : ""
        }Valor: ${currencyMask(payment.details.amountPaid)}`,
          });
        },
        () => {
          setShowLoading(false);

          showAlert({ message: "Ocorreu um erro ao desfazer pagamento" });
        }
      );
    },
    [setShowLoading, showAlert]
  );

  const removePaymentByIndex = useCallback((index) => {
    const payments = getLocalPayments();
    const newListPayments = payments.filter(
      (_, paymentIndex) => paymentIndex !== index
    );

    localStorage.setItem(PAYMENTS_KEY_NAME, JSON.stringify(newListPayments));

    setPaymentSuccess(newListPayments);
  }, []);

  const clearCurrentProduct = useCallback(() => {
    setCurrentProductStep([]);
  }, []);

  const setCurrentActions = useCallback((actions) => {
    setActions(actions);
  }, []);

  const handleOpenModalAddProductByCode = useCallback(() => {
    setIsOpenModalProductByCode(true);
  }, []);

  const handleCloseModalAddProductByCode = useCallback(() => {
    setIsOpenModalProductByCode(false);
  }, []);

  const handleOpenModalTotalQuantity = useCallback(() => {
    setIsOpenModalTotalProduct(true);
  }, []);

  const handleCloseModalTotalQuantity = useCallback(() => {
    setIsOpenModalTotalProduct(false);
  }, []);

  const handleOpenModalToEat = useCallback(() => {
    if (sessionStorage.getItem("tableSelected")) {
      setIsOpenModalCPFInInvoice(true);
    } else {
      setIsOpenModalToEat(true);
    }
  }, []);

  const handleCloseModalToEat = useCallback(() => {
    setIsOpenModalToEat(false);
  }, []);

  const handleOpenModalCPFInInvoice = useCallback(() => {
    setIsOpenModalCPFInInvoice(true);
  }, []);

  const handleCloseModalCPFInInvoice = useCallback(() => {
    setIsOpenModalCPFInInvoice(false);
  }, []);

  const handleOpenModalFidelity = useCallback(() => {
    setIsOpenModalFidelity(true);
  }, []);

  const handleCloseModalFidelity = useCallback(() => {
    setIsOpenModalFidelity(false);
  }, []);

  const handleOpenModalFidelityData = useCallback(() => {
    setIsOpenModalFidelityData(true);
  }, []);

  const handleCloseModalFidelityData = useCallback(() => {
    setIsOpenModalFidelityData(false);
  }, []);

  const handleSetActiveOptionalCampaign = useCallback(
    (campaign) => {
      try {
        const found = activeOptionalCampaign.findIndex((curr) => {
          return curr.IdCampanha === campaign.IdCampanha;
        });

        if (found < 0) {
          const newActiveOptionalCampaign = [
            ...activeOptionalCampaign,
            campaign,
          ];

          setActiveOptionalCampaign(newActiveOptionalCampaign);
          sessionStorage.setItem(
            RESHOP_ACTIVE_OPTIONAL_CAMPAIGN,
            JSON.stringify(newActiveOptionalCampaign)
          );

          return newActiveOptionalCampaign;
        }

        return activeOptionalCampaign;
      } catch (err) {
        if (window.Android) {
          console.log(
            `Error on handleSetActiveOptionalCampaign -> ${JSON.stringify(err)}`
          );
        } else {
          console.error("Error on handleSetActiveOptionalCampaign -> ", err);
        }

        return activeOptionalCampaign;
      }
    },
    [activeOptionalCampaign]
  );

  const handleSetManualDiscount = useCallback((value = 0) => {
    setManualDiscount(value);
  }, []);

  const handleOpenModalProductFractional = useCallback(
    ({ product, data, cb }) => {
      setProductFractionalData({
        productResult: product,
        dataProductResult: data,
        cb,
      });
      setIsOpenModalProductFractional(true);
    },
    []
  );

  const handleCloseModalProductFractional = useCallback(() => {
    setIsOpenModalProductFractional(false);
    setProductFractionalData(null);
  }, []);

  const isReadyToPay = !!(state?.Sale?.readySale() ?? false);

  const totalPrice = Number(state?.Sale?.getTotalValue() ?? 0);
  const totalPriceWDiscount = Number(
    new Decimal(totalPrice)
      .minus(new Decimal(reshopTotalPrice ? reshopTotalPrice : 0))
      .minus(new Decimal(manualDiscount ? manualDiscount : 0))
      .toFixed(2)
  );
  const totalCartInPoints = state?.Sale?.getTotalInPointsValue() ?? 0;

  const itemsInCart = state?.Sale?.getItems() ?? [];

  const nsuValue = nsuValueHelper;

  const hasDiscount = useMemo(() => reshopTotalPrice > 0 || manualDiscount > 0);

  useEffect(() => {
    if (!currentProductStep) {
      setCurrentProductStep([]);
    }
  }, [currentProductStep]);

  const value = useMemo(
    () => ({
      isCartVisible,
      setIsCartVisible,
      cart: state.cart,
      coupons: state.coupons,
      itemsInCart,
      Sale: state.Sale,
      isReadyToPay,
      totalPrice,
      totalPriceWDiscount,
      totalCartInPoints,
      reshopTotalPrice,
      setReshopTotalPrice,
      nsuValue,
      paymentSuccess,
      addPaymentSuccess,
      quantityToCart,
      setQuantityToCart,
      addProductToCart,
      duplicateProductInCart,
      addCouponToCart,
      removeCouponInCart,
      addDiscountsReshop,
      removeProductFromCart,
      clearCart,
      clearCartTable,
      currentProductStep,
      setCurrentProductStep,
      oldCurrentProductStepContainer,
      setOldCurrentProductStepContainer,
      clearCurrentProduct,
      action,
      setCurrentActions,
      isOpenModalProductByCode,
      handleOpenModalAddProductByCode,
      handleCloseModalAddProductByCode,
      handleOpenModalToEat,
      handleCloseModalToEat,
      handleOpenModalCPFInInvoice,
      handleCloseModalCPFInInvoice,
      handleOpenModalFidelity,
      handleCloseModalFidelity,
      handleOpenModalFidelityData,
      handleCloseModalFidelityData,
      isOpenModalTotalProduct,
      handleOpenModalTotalQuantity,
      setIsOpenModalTotalProduct,
      manualDiscount,
      handleSetManualDiscount,
      hasDiscount,
      activeOptionalCampaign,
      handleSetActiveOptionalCampaign,
      handleOpenModalProductFractional,
      setIsOpenModalProductFractional,
      removePaymentByIndex,
      undoPayment,
      selectedPosition: selectedTablePosition,
      setSelectedPosition: setSelectedTablePosition,
      isOpenModalQuantity,
      setIsOpenModalQuantity,
      redirectCategoryProduct,
      setSaleStartHour,
    }),
    [
      isCartVisible,
      setIsCartVisible,
      state.cart,
      state.coupons,
      itemsInCart,
      state.Sale,
      isReadyToPay,
      totalPrice,
      totalPriceWDiscount,
      totalCartInPoints,
      reshopTotalPrice,
      setReshopTotalPrice,
      nsuValue,
      paymentSuccess,
      addPaymentSuccess,
      quantityToCart,
      addCouponToCart,
      addProductToCart,
      duplicateProductInCart,
      addDiscountsReshop,
      removeProductFromCart,
      clearCart,
      clearCartTable,
      currentProductStep,
      oldCurrentProductStepContainer,
      clearCurrentProduct,
      action,
      setCurrentActions,
      isOpenModalProductByCode,
      handleOpenModalAddProductByCode,
      handleCloseModalAddProductByCode,
      handleOpenModalToEat,
      handleCloseModalToEat,
      handleOpenModalCPFInInvoice,
      handleCloseModalCPFInInvoice,
      handleOpenModalFidelity,
      handleCloseModalFidelity,
      handleOpenModalFidelityData,
      handleCloseModalFidelityData,
      isOpenModalTotalProduct,
      handleOpenModalTotalQuantity,
      setIsOpenModalTotalProduct,
      manualDiscount,
      handleSetManualDiscount,
      hasDiscount,
      activeOptionalCampaign,
      handleSetActiveOptionalCampaign,
      isOpenModalProductFractional,
      handleOpenModalProductFractional,
      setIsOpenModalProductFractional,
      removePaymentByIndex,
      undoPayment,
      selectedTablePosition,
      setSelectedTablePosition,
      isOpenModalQuantity,
      setIsOpenModalQuantity,
      redirectCategoryProduct,
      setSaleStartHour,
    ]
  );

  return (
    <CartContext.Provider value={value}>
      {isOpenModalProductByCode && (
        <ModalAddProductByCode handleClose={handleCloseModalAddProductByCode} />
      )}
      {isOpenModalTotalProduct && (
        <ModalQuantityProduct handleClose={handleCloseModalTotalQuantity} />
      )}
      {isOpenModalToEat && <ModalToEat handleClose={handleCloseModalToEat} />}
      {isOpenModalCPFInInvoice && (
        <ModalCPFInInvoice handleClose={handleCloseModalCPFInInvoice} />
      )}
      {isOpenModalFidelity && (
        <ModalAuthFidelity handleClose={handleCloseModalFidelity} />
      )}
      {reshop?.data && isOpenModalFidelityData && (
        <ModalFidelityData handleClose={handleCloseModalFidelityData} />
      )}
      {isOpenModalProductFractional && (
        <ModalProductFractional
          productFractionalData={productFractionalData}
          handleClose={handleCloseModalProductFractional}
        />
      )}
      {isOpenModalQuantity && (
        <ModalQuantityClient
          isOpen={isOpenModalQuantity}
          onClose={() => setIsOpenModalQuantity(false)}
          // onConfirm={handleModalQuantityConfirm}
        />
      )}
      {children}
    </CartContext.Provider>
  );
};
