import styled from "styled-components";

export const InputGroup = styled.div`
  padding: 10px 10px;
  margin: 11px 0 3px 0;
  display: flex;
  flex-direction: column;

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    padding: 10px 10px 0;
    margin: 1px;
  }

  label {
    display: inline-block;
    margin-bottom: 5px;
  }

  input {
    height: 30px;
    padding-left: 5px;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
  }

  span {
    color: #fe2e2e;
    height: 15px;
    font-size: 14px;
    margin-top: 5px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 15px;

  .row {
    display: flex;

    & > * {
      flex: 1 0 auto;
    }
  }

  .field-default,
  .field-text-area {
    margin-top: 0;
  }

  textarea {
    width: 515px;
    height: 95px;
    resize: none;
    padding: 10px 15px;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
  }

  label::after {
    content: " *";
    color: red;
  }

  .btn-inutilizar {
    width: 48%;
    margin-top: 10px;
    margin-left: 10px;
  }

  .container-buttons {
    margin-top: 10px;
  }

  select {
    height: 35px;
    width: 100%;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
  }

  &.isSmartPos {
    .row {
      display: flex;
      flex-direction: column;

      .container-buttons {
        display: flex;
        flex-direction: row;

        button {
          font-size: 12px;
          width: 43%;
          height: 30px;
          margin-top: 0px;
          margin-left: 11px;
          font-size: 12px !important;
        }
      }
    }

    textarea {
      width: 100%;
      height: 95px;
      resize: none;
      padding: 10px 15px;
      border: 1px solid #c8cbd0;
      border-radius: 4px;
    }

    .field-default,
    .field-text-area {
      margin-top: -10px;
    }
  }
`;
