import { apiReshop } from "../api";
import { getCodLoja, getTerminalID } from "../../aaone/configuration";
import { escOnlyNumbersHelper } from "../../pdv-one/helpers/escOnlyNumbersHelper";

export const getCouponService = async (promoCode, cpf) => {
  try {
    const codigoLoja = getCodLoja();
    const terminalid = getTerminalID();

    const { data } = await apiReshop.post("/api/Fidelidade/ConsultaPromocode", {
      DocumentoFidelidade: escOnlyNumbersHelper(cpf || ""),
      Promocode: promoCode,
      CodigoLoja: codigoLoja,
      CodigoTerminal: terminalid,
      DataHora: new Date().toISOString(),
    });

    if (data?.QtdeCampanhasPromocode === 0 || data?.Campanhas.length === 0) {
      if (data.Message) {
        throw new Error(data.Message ?? "Erro desconhecido");
      }

      return null;
    }

    return data;
  } catch (error) {
    throw new Error(error?.message ?? "Erro desconhecido");
  }
};
