import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { getSetting } from "../../helpers/userSettings";
import { Container } from "./style";

const keyboardNumericTypes = {
  CURRENCY: "currency"
};

const KeyboardPlugin = props => {
  const { inputName, keyboardRef } = props;
  const [usarKeyboardTouch, setUsarKeyboardTouch] = useState();
  const [cartPosition, setCartPosition] = useState("");
  const keyboard = useRef(null);
  const ref = useRef(null);

  /** Usado para limpeza do keyboard
   * Utilizar
   * window.dispatchEvent(new CustomEvent('cellEditorInputValueClear'));
   */
  useEffect(() => {
    const cellEditorInputValueClear = event => {
      Object.entries(ref.current.input).forEach(([key]) => {
        ref.current.setInput("", key);
        ref.current.clearInput(key);
      });
    }

    window.addEventListener('cellEditorInputValueClear', cellEditorInputValueClear);
    return () =>
      window.removeEventListener('cellEditorInputValueClear', cellEditorInputValueClear);
  }, []);

  useEffect(() => {
    setUsarKeyboardTouch(getSetting("usarKeyboardTouch"));
    setCartPosition(getSetting("cartPosition"));

    const HandleInputValueChanged = event =>
      ref.current.setInput(event.detail?.inputValue, event.detail?.inputName);

    window.addEventListener('cellEditorInputValueChanged', HandleInputValueChanged)
    return () => window.removeEventListener('cellEditorInputValueChanged', HandleInputValueChanged);

  }, [inputName]);

  function customHandleClose() {
    keyboard.current.focus();
    props.onKeyPress("{close}");
  }

  function currencyMaskValidator(value) {
    let newValue = value;

    if (value.length === 0) {
      newValue = 0;
    } else if (value.length === 1) {
      newValue = +`0.0${value}`;
    } else if (value.length === 2) {
      newValue = +`0.${value}`;
    } else if (value.length === 3) {
      newValue = +`${value[0]}.${value[1]}${value[2]}`;
    } else {
      let splitValue = value.toString().slice(0, value.length - 2);
      splitValue = `${splitValue}.${value.slice(
        value.length - 2,
        value.length
      )}`;
      newValue = + splitValue;
    }

    return newValue;
  }

  function onChange(value) {
    let newValue = value;

    if (props.onChange) {
      if (props.numericType === keyboardNumericTypes.CURRENCY) {
        newValue = currencyMaskValidator(value);
      }

      // Verifica se o número de dígitos é menor ou igual a 9
      if (newValue.toString().length <= 9) {
        props.onChange(newValue);
      }
    }
  }

  function onKeyPress(button) {
    if (button === "{close}") {
      keyboard.current.focus();
    }
    props.onKeyPress(button);
  }

  function onChangeAll(inputObj) {
    let value = inputObj[inputName || "default"];
    let maskedValue = value;

    if (props.onChangeAll) {
      if (props.numericType === keyboardNumericTypes.CURRENCY) {
        maskedValue = currencyMaskValidator(value);
      }

      props.onChangeAll(maskedValue, value);
    }
  }

  return (
    <Container
      visible={usarKeyboardTouch && props.visible}
      layout={props.layoutName}
      className={props.customClassName}
      cartIsVisible={props.cartIsVisible}
      cartPosition={cartPosition}
    >
      <Container
        visible={usarKeyboardTouch && props.visible}
        layout={props.layoutName}
        className={props.customClassName}
        cartIsVisible={props.cartIsVisible}
        cartPosition={cartPosition}
      >
        <input style={{ maxHeight: 0, padding: 0, border: 0 }} ref={keyboard} />
        {props.layoutName === "numeric" ? (
          <div className="header-content">
            <span>{props.titleDescription}</span>
            <button type="button" onClick={() => customHandleClose()}>
              x
            </button>
          </div>
        ) : (
          <></>
        )}
        {props.disabledKeyboard && <div className={"overlay-keyboard"}></div>}

        <Keyboard
          {...props}
          keyboardRef={r => {
            ref.current = r;
            if (typeof keyboardRef === 'function') {
              keyboardRef(r);
            }
          }}
          layoutName={props.layoutName}
          layout={{
            default: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp} {close}",
              "{tab} q w e r t y u i o p [ ] \\",
              "{lock} a s d f g h j k l ; ' {enter}",
              "{shift} z x c v b n m , . / {clear}",
              ".com @ {space}"
            ],
            shift: [
              "~ ! @ # $ % ^ & * ( ) _ + {bksp} {close}",
              "{tab} Q W E R T Y U I O P { } |",
              '{lock} A S D F G H J K L : " {enter}',
              "{shift} Z X C V B N M < > ? {clear}",
              ".com @ {space}"
            ],
            numeric: ["1 2 3", "4 5 6", "7 8 9", "{clear} 0 {bksp}", "{enter}"]
          }}
          display={{
            "{bksp}": props.layoutName === "numeric" ? "⌫" : "back ⌫",
            "{close}": "X",
            "{tab}": "tab ⇥",
            "{lock}": "capsss lock ⇪",
            "{enter}": props.layoutName === "numeric" ? "PRONTO" : "Enter ↵",
            "{shift}": "shift ⇧",
            "{space}": " ",
            "{clear}": "C"
          }}
          theme={
            props.layoutName === "numeric"
              ? "hg-theme-default hg-layout-default myThemeNumeric"
              : "hg-theme-default hg-layout-default myTheme"
          }
          buttonTheme={[
            {
              class: "hg-primary",
              buttons: "{enter}"
            },
            {
              class: "hg-close",
              buttons: "{close}"
            }
          ]}
          preventMouseDownDefault={true}
          disableCaretPositioning={true}
          onChange={value => onChange(value)}
          onKeyPress={value => onKeyPress(value)}
          onChangeAll={inputObj => onChangeAll(inputObj)}
        />
      </Container>

      <Container visible={usarKeyboardTouch && props.visible}>
      </Container>
    </Container>
  );
};

KeyboardPlugin.propTypes = {
  visible: PropTypes.bool.isRequired,
  titleDescription: PropTypes.string,
  customClassName: PropTypes.string.isRequired,
  layoutName: PropTypes.string.isRequired,
  numericType: PropTypes.string
};

// const mapStateToProps = state => ({
//   cartIsVisible: state.cart.isVisible
// });

export default KeyboardPlugin;
