import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0;

  grid-gap: 16px;
  gap: 16px;

  .carousel__categories {
    position: relative;
    width: 100%;

    &.isSmartPos {
      margin-top: -3px;
    }

    .slick-slider {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0px;
      width: 100%;

      grid-gap: 16px;
      gap: 16px;

      .slick-list {
        width: 100%;
      }

      .slick-slide > div {
        margin-bottom: 1rem;
      }
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .carousel__categories {
      .slick-slider {
        .slick-list {
          margin-bottom: 0.5rem;
          margin-top: -1px;
        }

        .slick-slide > div {
          margin-bottom: -0.25rem;
        }
      }
    }
  `)}
`;
