import styled from "styled-components";

export const RadioButtonGroup = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;

  &.radio-button-group--inline {
    display: inline-flex;
    justify-content: space-between;
    height: 45px;
    flex-wrap: wrap;
  }

  &.radio-button-group--column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-top: 2em;
    margin-bottom: 3em;
  }

  &.radio-button-group--list {
    display: flex;
    flex-direction: column;
    max-height: 700px;

    label {
      margin-top: 1.4em;
    }
  }
`;

export const RadioButtonComponent = styled.label`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 150px;
  position: relative;
  padding: 0 15px 0 5px;
  font-size: 1rem;
  letter-spacing: 1px;
  border: 1px solid var(--color-background-primary);
  border-radius: 100px;
  min-height: 50px;

  &.checked {
    background: var(--color-title-secondary);
  }

  &:hover input:not(:checked) ~ .radio-check {
    opacity: 0.8;
  }

  .radio-button-input {
    display: none;

    &:checked {
      ~ .radio-check {
        background-color: var(--color-background-light);
        transition: background 0.3s;
        &::after {
          opacity: 1;
          background: var(--color-background-cart);
        }
      }

      ~ .radio-button-label {
      }
    }
  }

  .radio-check {
    position: relative;
    height: 2em;
    width: 2em;
    border: 1px solid var(--color-background-cart);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px !important;

    &::after {
      content: "";
      position: absolute;
      opacity: 0;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background: #0ac07d;
    }
  }

  .radio-button-label {
    margin-left: 3px;
    padding-right: 1.5rem;
    font-size: 1.5em;
    span {
      line-height: 0em;
    }
  }
`;

export const RadioButtonLabel = styled.label`
  margin-left: 3px;
  padding-right: 1.5rem;
  color: var(--color-text-category);
  span {
    line-height: 0em;
  }
`;
