import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import emptyCart from "../../../assets/undraw_no_data_qbuo.svg";

import { Button, Summary } from "../modules";

import FooterWrapper from "./style";

import { currency } from "../../utils/currency";

import { getSuggestion } from "../../../../services/productService";
import { ModalProduct } from "../modules";
import { getThemeSettings } from "../../../configuration";
import { useLayoutAa } from "../../../contexts/layout";
import { useViewDispatch } from "../../../../context";

const Cart = (props) => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const { getTranslateName, isLayout } = useLayoutAa();

  const { expand, showCart, modal } = props;

  const isRagazzoLayout = isLayout("RAGAZZO");

  const cartItems = useSelector((state) => state.cart.items);

  const qtdItens =
    cartItems.filter((item) => !item.vit_numlanctoprincipal).length || 0;
  const total = !!cartItems.length
    ? cartItems.reduce((acc, cur) => {
        return (
          acc +
          (!cur.vit_numlanctoprincipal && !cur.currencyPoints ? cur.price : 0) *
            cur.qtd
        );
      }, 0)
    : 0;

  const [modalProduct, setModalProduct] = useState(false);
  const [suggestion, setSuggestion] = useState({});

  const statusLimpar = useCallback(() => {
    if (localStorage.getItem("cpf")) {
      return false;
    }

    if (!cartItems.length) {
      return true;
    }
  }, [cartItems.length]);

  useEffect(() => {
    if (cartItems.length === 0) {
      showCart(false);
    }

    statusLimpar();
  }, [cartItems, showCart, statusLimpar]);

  const getSuggestions = () => {
    getSuggestion(cartItems).then((res) => {
      const suggestion =
        res.suggestions && res.suggestions.length
          ? res.suggestions[0].products[0]
          : null;

      if (suggestion) {
        setSuggestion(suggestion);
        setModalProduct(true);
      } else showNotification();
    });
  };

  const closeModal = () => {
    showNotification();
    setModalProduct(false);
  };

  const showNotification = () => {
    viewDispatch("step.orderSummary");
  };

  // Texts translate
  const clearCartText =
    getTranslateName(t, "buttons.clear_cart") ||
    getThemeSettings?.text?.clear_cart ||
    "LIMPAR CARRINHO";

  const clearCartAndExitText =
    getTranslateName(t, "buttons.clear_cart_and_exit") ||
    getThemeSettings?.text?.clear_cart_and_exit ||
    "LIMPAR CARRINHO E SAIR";

  const myOrderCartText =
    getTranslateName(t, "buttons.my_order_cart") ||
    getThemeSettings?.text?.my_order_cart ||
    "Meu Pedido";

  const finishCartText =
    getTranslateName(t, "buttons.finish_cart") || "Finalizar";

  const finishCartTextBobs =
    getTranslateName(t, "buttons.finish_cart") || "PAGAR";
  const cartEmptyText =
    getTranslateName(t, "cart.empty") ||
    getThemeSettings?.text?.cart_empty ||
    "Carrinho Vazio";

  const totalItemText = {
    plural: getTranslateName(t, "cart.totalItems.plural") || "##total## itens",
    singular:
      getTranslateName(t, "cart.totalItems.singular") || "##total## item",
  };

  return (
    <FooterWrapper id="cart">
      {modalProduct && (
        <div className="blackout">
          <ModalProduct
            title={suggestion.description}
            value={suggestion.value}
            image={suggestion.image}
            id={suggestion._id}
            modalClose={closeModal}
            code={suggestion.productCode}
            notification={showNotification}
            suggestion={true}
            removeCount={false}
            classification={suggestion.classification}
            quantity={1}
            promotion={suggestion.promotion}
            nuuidOne={suggestion.nuuidOne}
          />
        </div>
      )}
      <section className="cart-details background-cart">
        <Button
          text={
            !localStorage.getItem("cpf") ? clearCartText : clearCartAndExitText
          }
          buttonUI="clear"
          disabled={statusLimpar()}
          handleClick={() => modal(true)}
        />

        <section
          className="order"
          onClick={() =>
            cartItems.length >= 1 ? showCart(true) : showCart(false)
          }
        >
          <h1 className="order-resume">
            {isRagazzoLayout ? (
              <i className="far fa-shopping-cart"></i>
            ) : `${myOrderCartText} `}
            <strong>
              {totalItemText[qtdItens > 1 ? "plural" : "singular"]?.replace(
                "##total##",
                qtdItens
              )}
            </strong>
          </h1>

          <h2 className="order-total">{currency(total)}</h2>
        </section>

        <Button
          className="buttonAddCar finalizarClass"
          text={isLayout("BOBS") ? finishCartTextBobs : finishCartText}
          buttonUIType={isRagazzoLayout ? "primaryExpress" : "primary"}
          buttonUI="normal finalizar"
          disabled={!cartItems.length}
          handleClick={() => getSuggestions()}
        />
      </section>

      <section
        className={`cart-summary ${expand ? " cart-summary--active " : ""}`}
      >
        <section
          className="cart-summary-title"
          onClick={() => {
            showCart(cartItems.length >= 1);
          }}
        >
          <i className="cart-icon fas fa-shopping-cart"></i>
          {expand ? (
            <i className="fad fa-arrow-alt-up"></i>
          ) : (
            <i className="fad fa-arrow-alt-down"></i>
          )}
        </section>

        <section className="cart-summary-items">
          {!cartItems.length ? (
            <div className="empty-cart">
              <p>{cartEmptyText}</p>
              <img src={emptyCart} alt={cartEmptyText} />
            </div>
          ) : (
            <Summary
              hasFooter={false}
              hasImg={false}
              tag={true}
              showTotal={true}
            />
          )}
        </section>
      </section>
    </FooterWrapper>
  );
};

export default Cart;
