import React, { useRef, useState, useEffect } from "react";
import { Content, Button, Keyboard } from "../../shared/components/modules";
import { useViewDispatch } from "../../../context";
import logoTell from "../../assets/telefone.svg";
import { useLayoutAa } from "../../contexts/layout";
import { getThemeSettings } from "../../configuration";


const PrismaContainer = () => {
  const {isLayoutAAOne, getScreenIsActiveBySlug } = useLayoutAa();
  const isShowCarIdentification = getScreenIsActiveBySlug("PLACA_VEICULO") ?? true;
  const viewDispatch = useViewDispatch();

  const [inputValue, setInputValue] = useState("");
  const keyboard = useRef();

  const onChange = (inputValue) => {
    keyboard.current.setInputValue(inputValue);
    setInputValue(inputValue);
  };

  const imagePrisma = () => {
    return logoTell
  };

  const next = (inputValue) => {
    inputValue ? localStorage.setItem("phoneNumber", inputValue)
      : localStorage.setItem("phoneNumber", "");
        viewDispatch("step.orderIdentification");
    window.localStorage.removeItem("prismakey");
    localStorage.removeItem("tellkey", true)
  };

  useEffect(() => {
    localStorage.setItem("tellkey", true);
  }, []);
  return (
    <>
      <Content
        bgColor="background-primary"
        title={"TELEFONE"}
        subtitle={ "Favor insira seu telefone com DDD"}
        contentChildren={true}
        className={"full prisma"}
        hasImg={true}
        srcImg={imagePrisma()}
        imgPosition="bottom"
      >
        <Keyboard
          classN="prisma"
          handleClick={next}
          onChange={onChange}
          value={inputValue}
          typeInput="tel"
          textPlaceholder={"Insira o número"}
        />

        <footer className="footer">
          <Button
            text="Voltar"
            className={""}
            buttonUIType="outline-primary"
            buttonUI={"normal"}
            handleClick={() => isShowCarIdentification && isLayoutAAOne ? viewDispatch("step.carIdentification") : viewDispatch("step.cpf")}
          />
            <Button
              className="btnragazzobalcao"
              text={
                getThemeSettings?.text?.withdraw_at_the_counter
                  ? getThemeSettings.text.withdraw_at_the_counter
                  : "Não,Obrigado!"
              }
              buttonUIType="outline-primary"
              buttonUI={"normal"}
              handleClick={() => next()}
            />
        </footer>
      </Content>
    </>
  );
};

export default PrismaContainer;
