import React, { useCallback, useEffect, useRef, useState } from "react";

import { Container } from "./styles";
import { HeaderPaymentMethod } from "../../components/Header/paymentMethod";
import { PagesEnum } from "../../constants/pagesEnum";
import { useLocation, useNavigate } from "react-router-dom";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { getFiscalFlowEndpointConfig } from "../../../aaone/configuration";
import {
  FISCAL_FLOW_ENDPOINT_KEY_NAME,
  FISCAL_FLOW_TYPE_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { useDialog } from "../../hook/dialogHook";
import { saveFiscalFlowConfig } from "../../../services/pdvConfigs";

const FISCAL_ENVS_LOCAL = { value: "Local" };
const FISCAL_ENVS_ONLINE = { value: "Online" };

const FISCAL_TYPES_VALUE = {
  nfce: 0,
  sat: 1,
  nfceAndSat: 2,
};

export const MideSettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputUrlRef = useRef();

  const { showAlert } = useDialog();
  const { setVisibleSidebarCart } = useWrapperRouters();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isActiveWarningMessage, setIsActiveWarningMessage] = useState(false);
  const [typeFiscal, setTypeFiscal] = useState(() => {
    const initialValue = Number(
      localStorage.getItem(FISCAL_FLOW_TYPE_KEY_NAME) || FISCAL_TYPES_VALUE.nfce
    );

    return initialValue;
  });
  const [fiscalEnvironment, setFiscalEnvironment] = useState(
    FISCAL_ENVS_LOCAL.value
  );
  const [fiscalEnvironments, setFiscalEnvironments] = useState([
    FISCAL_ENVS_LOCAL,
  ]);

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, []);

  const taxTypeOptions = {
    "NFC-e": FISCAL_TYPES_VALUE.nfce,
    SAT: FISCAL_TYPES_VALUE.sat,
    "NFC-e contingência SAT": FISCAL_TYPES_VALUE.nfceAndSat,
  };

  const getLocalFiscalUrl = localStorage.getItem(FISCAL_FLOW_ENDPOINT_KEY_NAME);
  const urlFiscalFlowOnline = "http://74.249.18.248:30001";

  const onSubmitForm = async (e) => {
    e.preventDefault();

    setIsSubmitLoading(true);

    try {
      const data = new FormData(e.target);

      let url = String(data.get("urlFiscalflow") || "");
      let typeFiscalValue = String(data.get("typeFiscal") || "0");
      let fiscalEnvironmentValue = String(
        data.get("fiscalEnvironment") || "local"
      );

      console.log(`Url do MIDe: ${url}`);

      if (url.trim().length === 0) {
        console.log(`Não foi detectado url do MIDe, será usado a padrão`);
        url = getFiscalFlowEndpointConfig();

        if (inputUrlRef?.current) {
          inputUrlRef.current.value = url;
        }
      }

      const isOnlineValue =
        String(fiscalEnvironmentValue).toLowerCase() === "online";

      const urlSerialized = isOnlineValue
        ? urlFiscalFlowOnline
        : String(url)
            .replace(/[\s\uFEFF\xA0]/g, "") // Remover espaços especiais
            .trim();

      await saveFiscalFlowConfig({
        typeFiscalTransmission: Number(typeFiscalValue),
        localIPForFiscalFlow: urlSerialized,
      });

      localStorage.setItem(FISCAL_FLOW_ENDPOINT_KEY_NAME, urlSerialized);

      localStorage.setItem(FISCAL_FLOW_TYPE_KEY_NAME, typeFiscalValue);

      showAlert({
        message: "Dados do MIDe foi salvo!",
      });
    } catch (error) {
      showAlert({
        message: "Dados do MIDe não foi salvo!",
      });
    }

    setIsSubmitLoading(false);
  };

  useEffect(() => {
    const taxTypeValues = Object.values(taxTypeOptions);
    const typeFiscalValue = typeFiscal;

    let newUrlFiscal = urlFiscalFlowOnline;
    let newFiscalEnvironments = FISCAL_ENVS_ONLINE;

    switch (typeFiscalValue) {
      case FISCAL_TYPES_VALUE.nfce:
        newUrlFiscal = urlFiscalFlowOnline;
        newFiscalEnvironments = FISCAL_ENVS_ONLINE;
        break;
      case FISCAL_TYPES_VALUE.sat:
      case FISCAL_TYPES_VALUE.nfceAndSat:
        newUrlFiscal =
          localStorage.getItem(FISCAL_FLOW_ENDPOINT_KEY_NAME) || "";
        newFiscalEnvironments = FISCAL_ENVS_LOCAL;
        break;
      default:
        newUrlFiscal = urlFiscalFlowOnline;
        newFiscalEnvironments = FISCAL_ENVS_ONLINE;
        break;
    }

    const isLocal =
      String(newFiscalEnvironments.value).toLowerCase() === "local";

    setFiscalEnvironment(newFiscalEnvironments.value);
    setFiscalEnvironments([newFiscalEnvironments]);

    if (inputUrlRef?.current) {
      inputUrlRef.current.value = isLocal ? "" : urlFiscalFlowOnline;
    }
  }, [inputUrlRef, typeFiscal]);

  useEffect(() => {
    if (inputUrlRef?.current) {
      const typesLocal = [
        FISCAL_TYPES_VALUE.sat,
        FISCAL_TYPES_VALUE.nfceAndSat,
      ];

      const isLocal = typesLocal.includes(
        Number(String(localStorage.getItem(FISCAL_FLOW_TYPE_KEY_NAME) || "").trim())
      );

      if (isLocal) {
        inputUrlRef.current.value = localStorage.getItem(
          FISCAL_FLOW_ENDPOINT_KEY_NAME
        );
      }
    }
  }, [location, inputUrlRef]);

  useEffect(() => {
    setVisibleSidebarCart(false);
  }, [location]);

  return (
    <KeyboardProvider>
      <Container>
        <HeaderPaymentMethod onClickBackButton={handleBackToHome}>
          Configurar MIDe
        </HeaderPaymentMethod>
        <div className="content__wrapper">
          <form onSubmit={onSubmitForm} className="form__container">
            <div className="input-container">
              <div className="input__group">
                <label>Tipo</label>
                <select
                  name="typeFiscal"
                  value={typeFiscal}
                  onChange={(e) => {
                    setTypeFiscal(Number(e.target.value));

                    return e;
                  }}
                >
                  {Object.keys(taxTypeOptions).map((option, index) => (
                    <option key={index} value={taxTypeOptions[option]}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input__group">
                <label>Ambiente</label>
                <select
                  name="fiscalEnvironment"
                  onChange={(e) => {
                    setFiscalEnvironment(e.target.value);
                    return e;
                  }}
                >
                  {fiscalEnvironments.map((currentFiscalEnv) => (
                    <option value={currentFiscalEnv.value}>
                      {currentFiscalEnv.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {fiscalEnvironment === "Local" && (
              <div className="input__group">
                <input
                  data-use-touch-keyboard
                  name="urlFiscalflow"
                  type="text"
                  placeholder="http://localhost:8089"
                  ref={inputUrlRef}
                  onChange={(e) => {
                    if (e.currentTarget.value.trim().length === 0) {
                      setIsActiveWarningMessage(true);
                    }

                    return e;
                  }}
                />
              </div>
            )}
            {isActiveWarningMessage && (
              <p>
                <strong>
                  Ao salvar o campo vazio será usado a URL "
                  {getFiscalFlowEndpointConfig()}" como padrão.
                </strong>
              </p>
            )}
            <button
              type="submit"
              className="button button__submit"
              disabled={isSubmitLoading}
            >
              Salvar
            </button>
          </form>
        </div>
      </Container>
    </KeyboardProvider>
  );
};
