import styled from "styled-components";
import Button from "../../components/Button";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  label {
    padding-top: 1.5rem;
    padding-left: 5px;
    font-weight: 700;
    padding-bottom: 3px;
  }

  .carousel__tables {
    flex-grow: 2;
    position: relative;
    width: 100%;
    padding: 0 22px;

    [tabindex] {
      outline: none;
    }

    .slick-slider {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0px;
      width: 100%;

      grid-gap: 16px;
      gap: 16px;

      .slick-list {
        width: 100%;
        margin-left: 1rem;
      }

      .slick-slide > div {
        margin-bottom: 0.625rem;
      }
    }

    &.isSmartPos {
      margin-top: 0.5rem;
      padding: 0;
    }
  }

  .btn-table {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
  }

  .emptyList {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 100%;
    flex: 1;

    font-weight: 700;
    font-size: 32px;

    color: #bdbdbd;
  }
`;

export const ContainerActionsButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0 0;

  button.btn-outline {
    font-size: 0.65rem !important;
    padding: 0.375rem 0.35rem !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
  }
`;

export const TotalContainer = styled.section`
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  align-items: center;

  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.25em auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px;

  width: 100%;

  .item__button {
    background-color: #ffffff;
    border: 1px solid #efefef;

    padding: 0.3rem;

    display: flex;
    justify-content: center;
    align-self: stretch;
    align-items: center;

    font-weight: 500;
    font-size: 2rem;

    flex: 1;

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &.item__code,
    &.item__qtd {
      text-transform: uppercase;
      font-size: 1.5rem;
    }

    &.item__qtd {
      background-color: #ccc;
    }

    &.item__active {
      background-color: #411e5a;
      color: #f2f2f2;
      outline: none;
      border: 1px solid rgb(216, 216, 216);
      border-radius: 4px;
      box-shadow: none;
      font-weight: 600;
    }

    &.skeleton {
      color: transparent;

      border-color: rgba(0, 0, 0, 0.05);

      .skeleton__text {
        height: 1.813rem;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  &.isSmartPos {
    flex-direction: column;
    flex: 1;

    .keyboard-numeric {
      display: none;
    }
  }
`;

export const CustomButtom = styled(Button)`
  &.btn-outline {
    color: #411e5a !important;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 12px !important;

    &.btn-transfer {
      width: 140px;
      margin-right: 10px;
      border: 1px solid #411e5a !important;
    }
    &.btn-refresh {
      width: 140px;
      border: 1px solid #411e5a !important;
    }
    &.btn-access {
      height: 70px;
      width: 140px;
      background: #09843a;
      color: #fff !important;
    }
    &.btn-consult {
      background: #411e5a;
      color: #fff !important;
      height: 70px;
      width: 140px;
      margin-right: 10px;
    }
    &.btn-clean {
      background: #fff;
      border: 1px solid #411e5a !important;
      color: #411e5a;
      margin-bottom: 0px;
    }
  }

  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  padding: 0 16px;
`;

export const ContainerSlider = styled.div`
  display: flex;
  width: 75%;
  flex-direction: column;
  flex-grow: 2;

  &.isSmartPos {
    width: 100%;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  border-left: 2px solid #bdbdbd;
  max-height: 700px;
  min-height: 700px;

  .keyboard-numeric {
    padding: 20px 0;

    button {
      font-size: 1.3rem !important;
      border-radius: 5px !important;
      color: #411e5a;
    }
  }

  &.isSmartPos {
    min-height: auto;
    max-height: auto;
    border-left: 0;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  input {
    height: 45px;
    border: 1px solid #cecece;
    border-radius: 4px;
    width: 100%;
  }

  button {
    margin-left: 0.5rem;
  }

  &.isSmartPos {
    margin-top: 0.25rem;
  }
`;
