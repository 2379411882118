import {
  cancelPaymentService,
  confirmPayment,
} from "../../services/linxPayServices";
import {
  clearLocalPayments,
  getLocalPayments,
  removePayment,
} from "./multiPaymentsHelper";
import { getUserAuthDataHelper } from "./getUserAuthDataHelper";
import { TransactionCode } from "../constants/transactionCode";
import { updatePaymentCancel } from "../../services/orderService";
import { apiDegustOneOrder } from "../../services/api";
import { getRetailerId } from "../../aaone/configuration";
import { transactionData } from "../../aaone/shared/utils/transactionNumber";

export const confirmTEFPayments = () => {
  const allPayments = getLocalPayments();

  // Confirma as operações em TEF
  if (window?.desktopApp) {
    const nsus = allPayments
      .filter(
        (payment) =>
          (payment.details.transactionType === "CREDITO" ||
            payment.details.transactionType === "DEBITO" ||
            payment.details.transactionType === "VOUCHER") &&
          payment.details?.controlCode
      )
      .reduce((acc, curr) => {
        acc.push(curr.details?.controlCode);

        return acc;
      }, []);

    window.desktopApp.publish("machine.devices.tef.confirm", nsus);

    setTimeout(() => {
      window.desktopApp.publish(
        "machine.devices.tef.pbm.finishTefTransactions",
        {}
      );
    }, 5000);
  } else if (window?.Android) {
    allPayments
      .filter(
        (payment) =>
          (payment.details.transactionType === "CREDITO" ||
            payment.details.transactionType === "DEBITO" ||
            payment.details.transactionType === "VOUCHER") &&
          payment.details?.controlCode
      )
      .forEach((payment) => {
        if (payment.details?.controlCode) {
          console.log(
            `window.Android.confirmTransaction | {NSU:${payment.details.controlCode}}`
          );
          window.Android.confirmTransaction(payment.details.controlCode);
        }
      });

    setTimeout(() => {
      console.log(`window.Android.finalizeTransaction | {}`);
      window.Android.finalizeTransaction();
    }, 300);
  }

  //Confirma pagamento QRLinx
  allPayments
    .filter(
      (payment) =>
        (payment.details.transactionType === "QRLINX" ||
          payment.details.transactionType === "QR LINX") &&
        payment.details?.controlCode
    )
    .forEach((payment) => {
      confirmPayment({
        amount: payment.details.amountPaid,
        payment_id: payment.details.controlCode,
      });
    });
};

/**
 * Desfaz uma operação TEF
 *
 * @param {*} payment - objeto do pagamento realizado
 * @param {*} cb - Função que será executada no sucesso
 * @param {*} cbError - Função que será executada em caso de erro
 */
export const undoTEFPayment = async (
  payment,
  cb = () => {},
  cbError = () => {}
) => {
  try {
    let allPayments = getLocalPayments();

    const paymentIndex = allPayments.findIndex(
      (currPayment) =>
        currPayment.details.controlCode === payment.details.controlCode
    );

    if (
      (payment.details.transactionType === "CREDITO" ||
        payment.details.transactionType === "DEBITO" ||
        payment.details.transactionType === "VOUCHER") &&
      payment.details.controlCode
    ) {
      if (paymentIndex >= 0) {
        if (window.desktopApp) {
          allPayments = removePayment(paymentIndex);
          cb(allPayments);
        } else if (window.Android) {
          console.log(
            `window.Android.cancelOneTransaction | {NSU: ${payment.details.controlCode}}`
          );

          window.Android.cancelOneTransaction(payment.details.controlCode);

          allPayments = removePayment(paymentIndex);
          cb(allPayments);
        }
      }
    } else if (
      (payment.details.transactionType === "QRLINX" ||
        payment.details.transactionType === "QR LINX") &&
      payment.details.controlCode
    ) {
      if (paymentIndex >= 0) {
        cancelPaymentService({
          amount: payment.details.amountPaid,
          payment_id: payment.details.controlCode,
        }).then(() => {
          allPayments = removePayment(paymentIndex);
          cb(allPayments);
        });
      }
    } else {
      if (paymentIndex >= 0) {
        allPayments = removePayment(paymentIndex);
        cb(allPayments);
      }
    }
  } catch (err) {
    if (window.Android) {
      console.log("Error on undoTEFPayment ->" + JSON.stringify(err));

      console.error("Error on undoTEFPayment ->", err);
    } else {
      console.error(`Error on undoTEFPayment -> ${err}`);
    }

    if (cbError) {
      cbError();
    }
  }
};

/**
 * Desfaz todas as operações TEF
 *
 * @param {*} cb - Função que será executada no sucesso
 * @param {*} cbError - Função que será executada em caso de erro
 */
export const undoTEFPayments = (cb = () => {}, cbError = () => {}) => {
  try {
    const allPayments = getLocalPayments();

    if (window?.desktopApp) {
      window.desktopApp.publish("machine.devices.tef.undo", {});
    }

    if (allPayments.length) {
      if (window?.Android) {
        allPayments
          .filter(
            (payment) =>
              (payment.details.transactionType === "CREDITO" ||
                payment.details.transactionType === "DEBITO" ||
                payment.details.transactionType === "VOUCHER") &&
              payment.details?.controlCode
          )
          .forEach((payment) => {
            console.log(
              `window.Android.cancelOneTransaction | {NSU: ${payment.details.controlCode}}`
            );
            window.Android.cancelOneTransaction(payment.details.controlCode);
          });

        setTimeout(() => {
          console.log(`window.Android.finalizeTransaction | {}`);
          window.Android.finalizeTransaction();
        }, 300);
      }
    }

    // Desfaz pagamentos QRLinx
    allPayments
      .filter(
        (payment) =>
          (payment.details.transactionType === "QRLINX" ||
            payment.details.transactionType === "QR LINX") &&
          payment.details?.controlCode
      )
      .forEach((payment) => {
        cancelPaymentService({
          amount: payment.details.amountPaid,
          payment_id: payment.details.controlCode,
        });
      });

    if (cb) {
      setTimeout(() => {
        cb();
        // Limpa os pagamentos TEF
        clearLocalPayments();
      }, 2000);
    }
  } catch (error) {
    console.error(error);
    setTimeout(() => {
      cbError();
    }, 2000);
  }
};

export const cancelPayment = (tefOrderSelected, onSuccess, onError) => {
  // Cancelamento QRLinx
  if (
    tefOrderSelected?.payments[0]?.paymentType?.enumValue ===
    TransactionCode.QRCode
  ) {
    cancelPaymentService({
      payment_id: tefOrderSelected?.payments[0]?.controlCode,
      amount: tefOrderSelected.totalDouble,
    });

    return;
  }

  // Cancelamento TEF Windows
  if (window.desktopApp) {
    const payment = tefOrderSelected.payments.find(
      (payment) => payment.controlCode === tefOrderSelected.controlCode
    );

    window.desktopApp.subscribe(
      "machine.devices.tef.cardtransactioncancelling.completed",
      (ch, payload) => {
        const userInfo = getUserAuthDataHelper();

        apiDegustOneOrder
          .post("api/Order/updatePaymentCancel", {
            retailerId: getRetailerId(),
            orderId: tefOrderSelected.idOrder,
            paymentId: tefOrderSelected.idPayment,
            controlCode: tefOrderSelected.controlCode,
            amount: tefOrderSelected.totalDouble,
            authorizationCodeCancel: payload.authorizationCode || 0,
            controlCodeCancel: payload.controlCode || 0,
            cancellerSellerReferenceId: userInfo.employeeCode,
            cancellerSellerName: userInfo.login,
          })
          .then((res) => {
            if (onSuccess) onSuccess();
          })
          .catch((err) => {
            console.error(err);
            onError({
              error:
                "O pagamento foi cancelado, mas ocorreu um erro ao atualizar seu status.",
            });
          });

        window.desktopApp.unsubscribe(
          "machine.devices.tef.cardtransactioncancelling.error"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.cardtransactioncancelling.completed"
        );
      }
    );

    window.desktopApp.subscribe(
      "machine.devices.tef.cardtransactioncancelling.error",
      (ch, payload) => {
        if (onError) onError(payload);

        if (payload.error.search("T89") >= 0) {
          // T89 significa que já está cancelado
          const userInfo = getUserAuthDataHelper();

          updatePaymentCancel({
            orderId: tefOrderSelected.idOrder,
            paymentId: tefOrderSelected.idPayment,
            authorizationCodeCancel: payload.authorizationCode || 0,
            controlCodeCancel: payload.controlCode || 0,
            cancellerSellerReferenceId: userInfo.employeeCode,
            cancellerSellerName: userInfo.login,
          });
        }

        window.desktopApp.unsubscribe(
          "machine.devices.tef.cardtransactioncancelling.error"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.cardtransactioncancelling.completed"
        );
      }
    );

    window.desktopApp.publish(
      "machine.devices.tef.cardtransactioncancelling",
      payment
    );
  } /* Cancelamento TEF Android */ else if (window.Android) {
    // Função utilizada para retorno do cancelamento na APK
    window.returnPayments = (data) => {
      console.log(`window.returnPayments | ${JSON.stringify(data)}`);

      if (window.Android) {
        if (!data.success) {
          setTimeout(() => {
            console.log(`window.Android.finalizeTransaction | {}`);
            window.Android.finalizeTransaction();
          }, 500);

          if (data?.errorMessage.split("-")[0] === "T89") {
            // T89 significa que já está cancelado
            const userInfo = getUserAuthDataHelper();

            updatePaymentCancel({
              orderId: tefOrderSelected.idOrder,
              paymentId: tefOrderSelected.idPayment,
              authorizationCodeCancel: data.authorizerNsu,
              controlCodeCancel: data.nsu,
              cancellerSellerReferenceId: userInfo.employeeCode,
              cancellerSellerName: userInfo.login,
            });
          }
        } else {
          console.log(`window.Android.confirmTransaction | {NSU: ${data.nsu}}`);
          window.Android.confirmTransaction(data.nsu);

          setTimeout(() => {
            console.log(`window.Android.finalizeTransaction | {}`);
            window.Android.finalizeTransaction();
          }, 500);

          // ToDo: corrigir para pegar o usuario que autorizou entrar na tela?
          const userInfo = getUserAuthDataHelper();

          apiDegustOneOrder
            .post("api/Order/updatePaymentCancel", {
              retailerId: getRetailerId(),
              orderId: tefOrderSelected.idOrder,
              paymentId: tefOrderSelected.idPayment,
              controlCode: tefOrderSelected.controlCode,
              amount: tefOrderSelected.totalDouble,
              authorizationCodeCancel: data.authorizerNsu,
              controlCodeCancel: data.nsu,
              cancellerSellerReferenceId: userInfo.employeeCode,
              cancellerSellerName: userInfo.login,

              // cancellerManagerReferenceId:
              // cancellerManagerName:
            })
            .then((res) => {
              if (onSuccess) {
                onSuccess();
              }
            })
            .catch((err) => {
              console.error('Error on returnPayments updatePaymentCancel' + JSON.stringify(err));
              onError();
            });
        }
      }
    };

    const data = {
      controlCode: tefOrderSelected.controlCode,
      dataTransaction: transactionData(
        new Date(tefOrderSelected.dataTransaction),
        "yyyyMMdd"
      ),
      totalDouble: tefOrderSelected.totalDouble,
    };
    try {
      console.log(
        `window.Android.cancelOrderTransaction | ${JSON.stringify(data)}`
      );
      window.Android.cancelOrderTransaction(
        data.controlCode, // NSU
        data.dataTransaction, // Data
        data.totalDouble
      ); // Total (Double)
      console.log(`cancelOrderTransaction | end`);
    } catch (e) {
      console.error(`cancelOrderTransaction | error | ${e}`);
    }
  }
};
