import styled from 'styled-components';

const FooterWrapper = styled.footer`
    position: relative;
    z-index: 10;

    .cart-details {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 2fr 4fr 2fr;
        min-height: 9vh;
        width: 100vw;
        padding: 2em;
        position: fixed;
        bottom: 0;
        z-index: 1;
        left: 0;
        border-radius: 1.5em 1.5em 0 0;
    }
`;

export default FooterWrapper;