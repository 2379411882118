import React, { Component } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { CashierOpening } from "../../components/CashOpen";
import { PagesEnum } from "../../constants/pagesEnum";
import { useAuth } from "../../hook/authHook";
import { useCashdesk } from "../../hook/cashdeskHook";

import { getTerminalID } from "../../../aaone/configuration";
import { getUsers } from "../../../services/usersServices";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { getPaymentsMethodService } from "../../../services/getPaymentsMethodService";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { handlePrintCoupon } from "../../helpers/printer/openCashdesk";
import { compareAsc } from "date-fns";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useMigration } from "../../hook/migrationHook";
import { openDrawer } from "../../helpers/openDrawer";

const withHooks = (Component) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const cashdesk = useCashdesk();
    const loading = useLoading();
    const auth = useAuth();
    const dialog = useDialog();
    const wrapperRouters = useWrapperRouters();
    const migration = useMigration();

    return (
      <Component
        {...props}
        navigate={navigate}
        cashdesk={cashdesk}
        location={location}
        dialog={dialog}
        loading={loading}
        auth={auth}
        migration={migration}
        wrapperRouters={wrapperRouters}
      />
    );
  };
};

class CashOpenContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldShowCashierOpeningForm: false,
      operatorList: [],
      openingDate: formatDateHelper(new Date(), "dd/MM/yyyy HH:mm:ss"),
      openingCount: this.props.cashdesk?.cashdesk
        ? (compareAsc(
            new Date(
              this.props.cashdesk?.cashdeskOpenDate?.mandatoryClosingDate
            ),
            new Date()
          ) === 1 &&
            this.props.cashdesk.cashdesk?.openingCount + 1) ||
          1
        : 1,
      manager: {},
      operator: {},
      disableConfirm: false,
      paymentType: {},
      hideScreen: false,
    };

    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    if (
      this.props.cashdesk?.cashdeskOpenDate &&
      !this.props.cashdesk?.cashdeskOpenDate?.isActive &&
      !this.props.cashdesk?.isCashdeskOnCurrentDay
    ) {
      this.props.dialog.showAlert({
        message: "Não pode abrir o caixa com o dia já encerrado",
      });

      setTimeout(() => {
        this.props.navigate(PagesEnum.HOME);
      }, 300);

      return;
    }

    const authManager = this.props.location?.state?.auth ?? null;

    if (
      this.props.auth.userAuthData.login &&
      !this.props.auth.userAuthData.isManager &&
      !authManager
    ) {
      this.setState({ hideScreen: true });

      setTimeout(() => {
        this.props.navigate(PagesEnum.NEED_AUTH, {
          state: {
            redirectUrl: PagesEnum.CASH_OPEN,
          },
        });
      }, 300);

      return;
    }

    if (!this.props.cashdesk?.isCashdeskOnCurrentDay) {
      this.props.dialog.setOpenDialog({
        title: "Abertura de caixa",
        message:
          "Para realizar uma nova abertura por favor realize o encerramento do dia",
        icon: "pdv-one/aberturadecaixa.png",
        onConfirmText: "Encerrar o dia",
        onConfirmClick: () => {
          this.props.cashdesk.setShowCashierClosingDay(true);
          this.props.wrapperRouters.setIsOpenMenu(false);
        },
      });

      // Ele não transferia a página, tive que colocar um timeout
      setTimeout(() => {
        this.props.navigate(PagesEnum.HOME);
      }, 300);
      return;
    }

    try {
      this.props.loading.setShowLoading();

      // const propsLocation = this.props.location?.state;

      let manager = authManager
        ? authManager.user
        : this.props.auth.userAuthData;
      manager.referenceId = manager?.employeeCode;
      manager.name = manager?.login;

      let operator = {};

      if (!this.props.auth.userAuthData.isManager) {
        operator = this.props.auth.userAuthData;
      }

      this.setState({
        manager,
        operator,
        hideScreen: false,
      });

      const promises = [
        getPaymentsMethodService()
          .then((res) => {
            const paymentType = res?.find(
              (payment) => payment.paymentOneBaseCode === 1
            );

            if (!paymentType) {
              this.props.dialog.showAlert({
                message: "Não foi encontrado o finalizador Dinheiro",
              });

              throw "Não foi encontrado o finalizador Dinheiro";
            }

            this.setState({
              paymentType: {
                id: paymentType.nuuidOne,
                name: paymentType.transactionType,
                referenceId: paymentType.paymentOneBaseCode,
              },
            });
          })
          .catch((err) => {
            this.props.navigate(PagesEnum.HOME);

            console.error(err);
          }),
      ];

      if (!this.state.operatorList.length) {
        promises.push(
          getUsers()
            .then((res) => {
              this.setState({
                operatorList: res.users,
              });
            })
            .catch((err) => {
              this.props.navigate(PagesEnum.HOME);

              console.error(err);
            })
        );
      }

      Promise.all(promises).then(() => {
        this.props.loading.setShowLoading(false);
      });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  handleSuccess(isDisableLoading = false) {
    this.props.dialog.showAlert({
      message: "Abertura de caixa realizada com sucesso!",
    });

    //Abre a gaveta
    openDrawer();

    this.handleClose(isDisableLoading);
  }

  handleClose = (isDisableLoading = false) => {
    if (!isDisableLoading) {
      this.props.loading.setShowLoading(false);
    }

    this.props.navigate(PagesEnum.HOME);
  };

  onSubmitCashierOpening = (cashierData) => {
    try {
      if (cashierData.openingAmount >= 999999.99) {
        this.props.dialog.showAlert({
          title: "Não é possivel abrir o caixa",
          message: "O limite de fundo inicial é de 999.999,99",
        });

        return;
      }

      this.props.loading.setShowLoading(true);

      this.setState({
        disableConfirm: true,
      });

      if (!this.props.auth.userAuthData.isManager) {
        cashierData = {
          ...cashierData,
          userId: this.state.operator.nuuidOne,
          user: this.state.operator.login,
          userReferenceId: this.state.operator.employeeCode,
        };
      }

      let cashierOpeningData = {
        ...cashierData,
        responsableId: this.state.manager.nuuidOne,
        responsable: this.state.manager.login,
        responsableReferenceId: this.state.manager.employeeCode,
        paymentType: this.state.paymentType,
      };

      this.props.cashdesk
        .cashdeskOpen(cashierOpeningData)
        .then((res) => {
          handlePrintCoupon(res);

          if (res?.isNewDay) {
            this.props.loading.setShowLoading(true);
            this.props.migration.generateMigration();
          }

          this.handleSuccess(res?.isNewDay ? true : false);
          this.setState({
            disableConfirm: false,
          });
        })
        .catch((err) => {
          console.error(err);

          this.setState({
            disableConfirm: false,
          });
          this.props.loading.setShowLoading(false);
        });
    } catch (err) {
      this.props.loading.setShowLoading(false);
      console.error(err);
      this.setState({
        disableConfirm: false,
      });
    }
  };

  render() {
    if (!this.props.cashdesk?.isCashdeskOnCurrentDay || this.state.hideScreen) {
      return <></>;
    }

    return (
      <KeyboardProvider>
        <CashierOpening
          onOkButtonClick={(cashierData) =>
            this.onSubmitCashierOpening(cashierData)
          }
          handleClose={this.handleClose}
          manager={this.state.manager}
          operatorList={this.state.operatorList}
          pdv={getTerminalID()}
          openingDate={this.state.openingDate}
          openingCount={this.state.openingCount}
          setVisibleSideKeyboardContainer={(visible) => {}}
          disableButton={this.state.disableConfirm}
        />
      </KeyboardProvider>
    );
  }
}

export default withHooks(CashOpenContainer);
