import { getThemeSettings } from "../configuration";
import { isLayoutUtil } from "../shared/utils/isLayout";

export const DEFAULT_LOCAL_COLOR_OPTIONS = {
  primaryColor: getThemeSettings?.colors?.primary || "#47195a",
  secondaryColor: getThemeSettings?.colors?.secondary || "#ffd848",
  whiteFontColor: getThemeSettings?.colors?.light || "#707070",
  darkFontColor: getThemeSettings?.colors?.title || "#FFD2B4",
  buttonBorderColor: getThemeSettings?.colors?.title || "#ffd848",
};

export const DEFAULT_LOCAL_TEXTS = {
  start_button:
    getThemeSettings?.text?.start_button || "Toque na tela para iniciar!",
  describe_cpf_loyalty_text:
    getThemeSettings?.text?.describe_cpf_loyalty_text || isLayoutUtil("BOBS")
      ? "É cliente Bob’s Fã?"
      : "Digite seu CPF para utilizar os benefícios",
  order_summary:
    getThemeSettings?.text?.order_summary || isLayoutUtil("BOBS")
      ? "SEU PEDIDO"
      : "Resumo do seu Pedido",
  on_table: getThemeSettings?.text?.on_table || "Receba na Mesa",
  describe_ask_vehicle_license:
    getThemeSettings?.text?.describe_ask_vehicle_license ||
    "Qual a placa do veículo?",
  ask_cpf_invoice:
    getThemeSettings?.text?.ask_cpf_invoice || isLayoutUtil("BOBS")
      ? "DESEJA INSERIR O CPF NA NOTA?"
      : "CPF NA NOTA?",
  your_name_be_called_order:
    getThemeSettings?.text?.your_name_be_called_order || "Informe seu nome",
};
