import styled from "styled-components";

export const DatepickerWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__input-container {
    input {
      border: 1px solid rgb(211, 209, 209);
      border-radius: 3px;
      font-size: 16px;
      box-sizing: border-box;
      background: rgb(255, 255, 255);
      margin-left: 1px;
    }
  }

  .react-datepicker-popper {
    position: fixed !important;
  }

  i {
    position: absolute;
    padding-right: 5px;
    padding-top: 4px;
    font-size: 22px;
    color: #411e5a;

    &:hover {
      color: #ffb201;
      cursor: pointer;
    }
  }
`;

export const MainWrapper = styled.div`
  .invalidDate {
    position: absolute;
    color: red;
    margin-left: 5px;
  }
`;
