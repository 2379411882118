import React from "react";
import { FlavorSelectList } from "../modules";
import { ContainerTitle, Title } from "./style";

const FlavorSelect = ({
  title,
  listProducts,
  maxQuantity,
  onChange,
  required,
}) => (
  <>
    {title && (
      <ContainerTitle className="containerTitleTablet">
        <Title>{title}</Title>
      </ContainerTitle>
    )}
    <FlavorSelectList
      listProducts={listProducts}
      maxQuantity={maxQuantity}
      onChangeValue={(selectedItems) => onChange(selectedItems)}
      required={required}
    />
  </>
);

export default React.memo(FlavorSelect);
