import { getProductsAll } from "../../aaone/configuration";

export const getImageURl = (imageURl) => {
  if (
    !new RegExp(
      "[a-zA-Zd]+://(w+:w+@)?([a-zA-Zd.-]+.[A-Za-z]{2,4})(:d+)?(/.*)?"
    ).test(imageURl)
  ) {
    return getProductsAll() + imageURl;
  }

  return imageURl;
};
