import React from 'react';
import { CloseButtonWrapper } from './style';


const CloseButton = ({ onClick }) => (
  <CloseButtonWrapper onClick={onClick}>
    <i className="icon lx-close"></i>
  </CloseButtonWrapper>
)

export default CloseButton