import { apiRouting } from './api';
import { getRetailerId } from '../aaone/configuration';
import { v4 as uuidV4 } from 'uuid';

const handleRequestDataInsertProductsKds = async (rawItems = {}, Sale) => {
  const items = [];

  const handleItemType = item => {
    const parentSequentialId = item.vit_numlanctoprincipal ?? 0;
    const itemType = [];
    const principalCombo = item.vit_numlancto !== null &&
      rawItems.some(product => product.vit_numlanctoprincipal === item.vit_numlancto);

    const itemCombo = item.vit_numlanctoprincipal !== null || principalCombo;

    if (item.isCombination) {
      if (item.vit_numlanctoprincipal != null) {
        itemType.push(3);
      }

      if (parentSequentialId > 0) {
        itemType.push(2);
      }

      itemType.push(7);
    } else if (itemCombo) {
      if (principalCombo && item.vit_numlanctoprincipal == null){
        itemType.push(1);
      }

      if (principalCombo){
        itemType.push(5);
      }

      if (item.vit_numlanctoprincipal !== null) {
        itemType.push(6);
      }
    }

    if (parentSequentialId === 0) {
      itemType.push(8);
    }

    return itemType;
  }


  const itemsFinalizados = await Sale.processItemsFinalizationSale();
  itemsFinalizados.items.forEach((item, index) => {
    items.push({
      parentSequentialId: item.vit_numlanctoprincipal ?? 0,
      sequentialId: index + 1,
      quantity: item.quantity,
      productReferenceId: item.productCode,
      typedNote: "",
      itemType: handleItemType(item),
      operation: 0,
      product: {
        id: item.nuuidOne,
        name: item.description,
        referenceId: item.productCode,
        sequentialId: index,
        isCombination: item.isCombination
      },
    })
  });

  return items;
};

export const insertProductsInKds = async (requestData = {}, Sale) => {
  try {
    const endpoint = '/api/StartRouting/start';
    const tableSelected = sessionStorage.getItem("tableSelected");
    let orderCode = `${requestData.sellerReferenceId}`;

    if (tableSelected) {
      orderCode = tableSelected;
    }

    const dataRequest = {
      typeRoute: 1,
      retailerId: getRetailerId(),
      posId: requestData.pdvCode,
      currentSellerName: requestData.sellerName,
      items: await handleRequestDataInsertProductsKds(requestData.items, Sale),
      recoveryId: null,
      isTakeOut: false,
      deliveryInfo: null,
      orderCode,
      observation: "",
      orderId: uuidV4(),
    };

    const { data } = await apiRouting.post(endpoint, dataRequest);
    return data;
  } catch(error) {
    const message = `ERROR insertProductsInKds ${error}`;
    console.log(message);
    return Promise.reject(message);
  }
}
