import React, { Component } from "react";
import classNames from "classnames";
import CurrencyInput from "react-currency-input";

import { isSmartPos } from "../../../aaone/configuration";

import {
  CashSupplyWrapper,
  InputGroup,
  ConfirmFormActions,
  GroupButton,
} from "./style";
import { currencyMask } from "../../helpers/masks";
import Card from "../../components/Card";
import TableList from "./table-list";
import KeyboardPlugin from "../../components/keyboard-plugin";
import Button from "../../components/Button";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { InfoSupply } from "./InfoSupply";

const withHook = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();

    return (
      <Component {...props} setVisibleSidebarCart={setVisibleSidebarCart} />
    );
  };
};
class CashSupply extends Component {
  constructor(props) {
    super(props);
    this.amountInput = null;
    this.motivoInput = null;

    this.state = {
      hasErrorInputAmountInput: false,
      hasErrorInput: false,
      amountValue: "",
      descriptionValue: "",

      keyboardVisible: false,
      layoutName: "default",
      inputName: "input_amount",
    };
  }

  submitHandler = (event) => {
    event.preventDefault();

    this.setState({
      hasErrorInputAmountInput: false,
      hasErrorInput: false,
    });

    if (this.amountInput.theInput.value === "") return;

    let validate = true;

    if (
      parseFloat(
        this.amountInput.theInput.value.replace(".", "").replace(",", ".")
      ) <= 0
    ) {
      validate = false;
      this.setState({ hasErrorInputAmountInput: true });
    }

    if (this.motivoInput.value === "") {
      validate = false;
      this.setState({ hasErrorInput: true });
    }

    if (validate) {
      this.props.handleClick(
        parseFloat(
          this.amountInput.theInput.value.replace(".", "").replace(",", ".")
        ),
        this.motivoInput.value
      );
    }
  };

  componentDidMount() {
    this.props.onCartHide();
    this.props.setVisibleSidebarCart(false);
  }

  /*componentWillUnmount = () => {
    this.keyboard.destroy();
    this.keyboardNumber.destroy();
    this.props.setVisibleSideKeyboardContainer(false);
    this.props.setVisibleBottomKeyboardContainer(false);
  };*/

  toggleCard = (expandCardList) => {
    if (expandCardList && this.props.listSupply.length === 0) return;

    this.props.handleHidden(expandCardList);
  };

  //updateFormatList = (isCardList) => {
  //isCardList
  //? store.dispatch({ type: "SHOW_TABLE_CARD", tableCard: true })
  //: store.dispatch({ type: "SHOW_TABLE_LIST", tableList: true });
  //};

  onChangeAmountInput = (value) => {
    this.setState({
      amountValue: value,
    });
    //this.keyboardNumber.setInput(String(value).replace(/[^0-9-]+/g, ""));
  };

  onChangeDescriptionInput = (event) => {
    this.setState({
      descriptionValue: event.target.value,
    });
    //this.keyboard.setInput(event.target.value);
  };

  keyboardOnChangeAll = (value) => {
    if (this.state.inputName === "input_amount") {
      this.setState({ amountValue: value });
    }

    if (this.state.inputName === "input_description") {
      this.setState({ descriptionValue: value });
    }
  };

  keyboardOnKeyPress = (button) => {
    if (button === "{enter}" || button === "{close}") {
      this.setState({
        keyboardVisible: false,
      });
      //this.props.setVisibleBottomKeyboardContainer(false);
    }

    if (button === "{shift}" || button === "{lock}") {
      let activeLayoutName = this.state.layoutName;

      this.setState({
        layoutName: activeLayoutName === "default" ? "shift" : "default",
      });
    }

    if (button === "{clear}") {
      if (this.state.inputName === "input_amount") {
        this.setState({ ...this.state, amountValue: 0 });
        this.keyboardNumber.clearInput(`${this.state.inputName}`);
      }

      if (this.state.inputName === "input_description") {
        this.setState({ ...this.state, descriptionValue: "" });
        this.keyboard.clearInput(`${this.state.inputName}`);
      }
    }
  };

  keyboardSetActiveInput = (inputName) => {
    switch (inputName) {
      case "input_amount":
        this.setState({
          keyboardVisible: false,
          disabledKeyboard: false,
        });
        //this.props.setVisibleBottomKeyboardContainer(false);
        break;
      case "input_description":
        this.setState({
          layoutName: "default",
          keyboardVisible: true,
          disabledKeyboard: true,
        });
        //this.props.setVisibleBottomKeyboardContainer(true);
        break;
      default:
        break;
    }

    this.setState({
      inputName: inputName,
    });
  };

  closeKeyboard() {
    this.setState({ keyboardVisible: false });
  }

  render() {
    const {
      handleClose,
      title,
      manager,
      responsable,
      controlNumber,
      closeEnable = true,
      listSupply,
      handlePrinter,
      handleChargeback,
    } = this.props;

    return (
      <CashSupplyWrapper className={classNames({ isSmartPos })}>
        <KeyboardPlugin
          visible={true}
          customClassName="keyboard-cashier-supply"
          baseClass={"id-keyboard-cash-suply-numeric"}
          titleDescription="Valor"
          layoutName={"numeric"}
          numericType={"currency"}
          disabledKeyboard={this.state.disabledKeyboard}
          onKeyPress={(button) => this.keyboardOnKeyPress(button)}
          onChangeAll={(value) => this.keyboardOnChangeAll(value)}
        />
        <div className="cash-supply">
          <Card
            id="card"
            title={`${title}`}
            className={!this.props.expandCard ? "expand-card" : ""}
            closeEnable={closeEnable}
            handleClose={handleClose}
            handleHidden={() => this.toggleCard(false)}
          >
            <form onSubmit={this.submitHandler}>
              <InputGroup>
                <label>Nº Controle</label>
                <div>
                  <input value={controlNumber} readOnly disabled />
                </div>
              </InputGroup>
              <InputGroup>
                <label>Responsável</label>
                <div>
                  <input value={manager.login} readOnly disabled />
                </div>
              </InputGroup>
              <InputGroup>
                <label htmlFor="amount">Valor*</label>
                <div>
                  <CurrencyInput
                    id="amount"
                    ref={(input) => (this.amountInput = input)}
                    decimalSeparator=","
                    thousandSeparator="."
                    inputMode="numeric"
                    onChange={(value) => this.onChangeAmountInput(value)}
                    onFocus={() => this.keyboardSetActiveInput("input_amount")}
                    value={this.state.amountValue}
                  />
                  {this.state.hasErrorInputAmountInput && (
                    <span>Informe um valor maior que zero!</span>
                  )}
                </div>
              </InputGroup>
              <InputGroup>
                <label htmlFor="description">Informe o motivo*</label>
                <div>
                  <input
                    data-use-touch-keyboard
                    id="description"
                    type="text"
                    className="medium-size input-custom"
                    ref={(input) => {
                      this.motivoInput = input;
                    }}
                    onChange={(e) => this.onChangeDescriptionInput(e)}
                    onFocus={() =>
                      this.keyboardSetActiveInput("input_description")
                    }
                    onBlur={() => this.closeKeyboard()}
                    value={this.state.descriptionValue}
                  />
                  {this.state.hasErrorInput && <span>Informe o motivo!</span>}
                </div>
              </InputGroup>
              <ConfirmFormActions className={classNames({ isSmartPos })}>
                <Button
                  id="confirmar"
                  className="btn-large btn-purple btn-custom"
                  type="submit"
                  handleClick={this.validate}
                >
                  Confirmar
                </Button>
              </ConfirmFormActions>
            </form>
          </Card>
          <Card
            id="cardList"
            title={`CONSULTA ${title}`}
            className={!this.props.expandCardList ? "expand-card" : ""}
            handleHidden={() => this.toggleCard(true)}
            disabledBtnArrow={listSupply.length === 0}
            controlScroll={this.props.tableCardList ? true : false}
          >
            <div
              className={`amount-list ${
                isSmartPos ? "smart-pos" : "pdv-windows"
              }`}
            >
              <span>
                {listSupply.length} suprimento
                {listSupply.length > 1 || listSupply.length === 0
                  ? "s"
                  : ""}{" "}
                realizado
                {listSupply.length > 1 || listSupply.length === 0 ? "s" : ""}.
              </span>
            </div>

            <GroupButton
              className="group-button"
              tableCardList={this.props.tableCardList}
            >
              <span
                className="btn-actions"
                onClick={() => this.updateFormatList(!this.props.tableCardList)}
              >
                <i className="material-icons">
                  {this.props.tableCardList ? "list" : "apps"}
                </i>
              </span>
            </GroupButton>

            {this.props.tableCardList && (
              <div className="grid-list-card">
                <>
                  {listSupply.map((item, i) => {
                    return (
                      <Card id={`card${i}`} key={i}>
                        <section className="card-list">
                          <h1>{item.description}</h1>
                          <div>
                            <span>
                              <strong>Nº controle:</strong>{" "}
                              {item.referenceSequence}
                            </span>
                            <span>
                              <strong>Cancelado: </strong>{" "}
                              {item.isReversed ? " Sim" : " Não"}
                            </span>
                            <span>
                              <strong>Valor:</strong>{" "}
                              {currencyMask(item.amount)}
                            </span>
                          </div>
                          <section>
                            <button
                              className="btn-card-list"
                              disabled={item.isReversed}
                              onClick={() => handleChargeback(item)}
                            >
                              <i className="material-icons">money_off</i>
                              <span>Estornar</span>
                            </button>
                            <button
                              className="btn-card-list"
                              onClick={() => handlePrinter(item.id)}
                            >
                              <i className="material-icons">print</i>
                              <span>Imprimir</span>
                            </button>
                          </section>
                        </section>
                      </Card>
                    );
                  })}
                </>
              </div>
            )}
            {isSmartPos ? (
              <InfoSupply
                items={listSupply}
                handlePrinter={handlePrinter}
                handleChargeback={handleChargeback}
              />
            ) : (
              <TableList
                listSupply={listSupply}
                handlePrinter={handlePrinter}
                handleChargeback={handleChargeback}
              />
            )}
          </Card>
        </div>
      </CashSupplyWrapper>
    );
  }
}
export default withHook(CashSupply);
