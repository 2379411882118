import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  border-bottom: 2px solid #ccc;
  width: 100%;

  padding: 1rem;

  div + div {
    margin-left: 1rem;
  }

  .total {
    display: flex;
    flex-direction: column;

    .label {
      font-weight: normal;
      font-size: 1rem;
    }

    .value {
      color: ${({ theme }) => theme.colors.purple};
      font-weight: bold;
      font-size: 2rem;
    }
  }

  .names__container {
    display: flex;
    flex-direction: column;
    width: 150px;

    .label {
      font-weight: normal;
      font-size: 1rem;
    }

    select {
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      flex: 1;
    }
  }

  .buttons__container_table {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > div {
      display: flex;
      flex-direction: row;
    }

    .button__action {
      border: 2px solid ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.purple};
      border-radius: 0.5rem;
      padding: 0.375rem;

      display: flex;
      align-items: center;
      justify-content: center;

      & + .button__action {
        margin-left: 0.5rem;
      }

      &.button__success {
        background-color: #2e0037;
        border-color: #2e0037;
        color: #fff;
      }

      &:disabled {
        border-color: #9a9a9a;
        color: #9a9a9a;
      }
    }
  }
`;

export const TabNavContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  .button__tab-submit {
    background: #2e0037;
    text-align: center;
    color: #fff;

    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0;

    outline: none;

    padding: 1rem;
    flex: 1;
  }

  .button__tab-action {
    border-bottom: 6px solid #ccc;
    text-align: center;
    color: #999;

    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0;

    outline: none;

    padding: 1rem;
    flex: 1;

    & + .button__tab-action {
      border-left: 1px solid #ccc;
    }

    &.isActive {
      border-bottom-color: ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: -webkit-fill-available;
  flex: 1;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(46, 0, 55, 0.4);
    border-radius: 20px;
    border: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
