import React from "react";
import classNames from "classnames";

import { isSmartPos } from "../../../../aaone/configuration";
import { Container, Filter } from "./style";

const Filters = ({ data, activeFilter = "ALL", setFilter }) => {
  const countTable = (filter) => {
    if (!data) {
      return 0;
    }

    return (
      data?.filter((table) => {
        if (filter === "ALL") {
          return true;
        }

        return table.visualStatus?.toUpperCase() === filter;
      })?.length || 0
    );
  };

  return (
    <Container>
      <Filter
        onClick={() => {
          setFilter("ALL");
        }}
        className={classNames(
          {
            active: activeFilter === "ALL",
            isSmartPos,
          },
          "all"
        )}
      >
        <div>{countTable("ALL")}</div>
        <div>
          TODAS
          <span className={classNames({ display__hide: isSmartPos })}>
            {` AS MESAS`}
          </span>
        </div>
      </Filter>
      <Filter
        onClick={() => {
          setFilter("ACTIVE");
        }}
        className={classNames(
          {
            active: activeFilter === "ACTIVE",
            isSmartPos,
          },
          "open"
        )}
      >
        <div>{countTable("ACTIVE")}</div>
        <div>ABERTAS</div>
      </Filter>
      <Filter
        onClick={() => {
          setFilter("FREE");
        }}
        className={classNames(
          {
            active: activeFilter === "FREE",
            isSmartPos,
          },
          "free"
        )}
      >
        <div>{countTable("FREE")}</div>
        <div>DISPONÍVEIS</div>
      </Filter>
      <Filter
        onClick={() => {
          setFilter("CLOSING");
        }}
        className={classNames(
          {
            active: activeFilter === "CLOSING",
            isSmartPos,
          },
          "closed"
        )}
      >
        <div>{countTable("CLOSING")}</div>
        <div>
          AG
          <span className={classNames({ display__hide: !isSmartPos })}>.</span>
          <span className={classNames({ display__hide: isSmartPos })}>
            UARDANDO
          </span>{" "}
          PAG
          <span className={classNames({ display__hide: !isSmartPos })}>.</span>
          <span className={classNames({ display__hide: isSmartPos })}>
            AMENTO
          </span>
        </div>
      </Filter>
    </Container>
  );
};

export default Filters;
