import buildInfo from "../buildinfo.json";
import { getTerminalID } from "../aaone/configuration";
import { shortcutIconVersionUtils } from "../aaone/shared/utils/shortcutIconVersionUtils";
import { getSystemParametersService } from "./getSystemParametersService";
import { isLayoutUtil } from "../aaone/shared/utils/isLayout";
import {
  PDV_COD_LOJA_KEY_NAME,
  PDV_STORE_PARAMS,
  SYSTEM_DATA_KEY_NAME,
} from "../pdv-one/constants/keyNamesSessionStorage";

export const getCurrentSystemDataInStorage = () => {
  try {
    const data = sessionStorage.getItem(SYSTEM_DATA_KEY_NAME);
    if (data) {
      return JSON.parse(data);
    }
  } catch (error) {
    return null;
  }
};

export const getCurrentSystemVersionService = async () => {
  try {
    const terminalId = getTerminalID();
    const shortcutIcon = shortcutIconVersionUtils() || "Desconhecido";

    const configsPdv = await getSystemParametersService();

    const storeId = String(configsPdv?.objectSystemParameters?.storeID || "");

    sessionStorage.setItem(
      PDV_COD_LOJA_KEY_NAME,
      !isLayoutUtil("RAGAZZOEXPRESS") ? storeId : Number(storeId) + 8000
    );
    sessionStorage.setItem(
      PDV_STORE_PARAMS,
      JSON.stringify(configsPdv?.objectSystemParameters)
    );

    window.sessionStorage.setItem(
      "printQRCode",
      JSON.stringify(configsPdv?.objectPdv?.printQRCode)
    );

    const result = {
      front: buildInfo,
      terminalId,
      shortcutIcon: { version: shortcutIcon },
      isOneIntegration:
        configsPdv?.objectSystemParameters?.isOneIntegration ?? false,
      retailerId:
        configsPdv?.objectSystemParameters?.storeDetails?.retailerNuuid ?? "",
      receiptSeries: configsPdv?.objectPdv.receiptSeries ?? 0,
      isAutoatendimentoOne: configsPdv?.objectSystemParameters.isAutoatendimentoOne ?? false,
      vendaSiteBalcao: configsPdv?.objectPdv.pdvVendaSiteBalcao ?? 0,
      vendaSiteBalcaoDescricao: configsPdv?.objectPdv.pdvVendaSiteBalcaoDescricao ?? 0,
      vendaSiteMesa: configsPdv?.objectPdv.pdvVendaSiteMesa ?? 0,
      vendaSiteAutoatendimento: configsPdv?.objectPdv.pdvVendaSiteAutoatendimento ?? 0,
    };

    sessionStorage.setItem(SYSTEM_DATA_KEY_NAME, JSON.stringify(result));

    return result;
  } catch (error) {
    console.error("Error when loading system version", error);
    sessionStorage.setItem(SYSTEM_DATA_KEY_NAME, JSON.stringify({}));

    return null;
  }
};
