import { RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME } from "../constants/keyNamesSessionStorage";

export const getReshopReedemableProductsEmpty = [];

export const getReshopReedemableProducts = () => {
  try {
    const reshopReedemableProducts = JSON.parse(
      sessionStorage.getItem(RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME) ??
        JSON.stringify(getReshopReedemableProductsEmpty)
    );

    return reshopReedemableProducts;
  } catch (error) {
    return getReshopReedemableProductsEmpty;
  }
};
