import styled from "styled-components";

export const CashReduceWrapper = styled.section`
  width: 100%;

  & section {
    margin: 0.3em 0;

    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      margin: 3px;
    }
  }
`;

export const CashReduceHead = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const CashReduceBody = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const CashReduceFooter = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const InputGroup = styled.div`
  padding: 10px 40px 0 10px;
  margin: 11px 0 3px 0;

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    padding: 10px 10px 0;
    margin: 1px;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 0 8px;
  }
`;

export const ConfirmFormActions = styled.div`
  .btn-custom {
    width: 130px;
    height: 50px;
    margin: 20px 15px 0 10px;
  }

  grid-column-start: 2;
  text-align: right;
`;
