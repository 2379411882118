import React, { useCallback, useEffect, useState } from "react";
import { MdClose, MdCheck, MdAdd, MdHorizontalRule } from "react-icons/md";
import { Container, InputContent, ToggleSwitch } from "./styles";
import { KeyboardProvider } from "../../../contexts/KeyboardContext";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../../constants/pagesEnum";
import { KeyboardNumeric } from "../../../components/KeyboardNumeric";
import { getSystemParamsHelper } from "../../../helpers/getSystemParamsHelper";
import { useCart } from "../../../hook/cartHook";
import { useDialog } from "../../../hook/dialogHook";
import classNames from "classnames";
import { isSmartPos } from "../../../../aaone/configuration";

export const ModalQuantityClient = ({
  onClose,
  isOpen,
  onConfirm,
  isClosing,
}) => {
  const DEFAULT_POSITION_DATA = {
    positions: [{ position: 1, status: "", name: "" }],
  };

  const navigate = useNavigate();
  const { showAlert } = useDialog();
  const systemParams = getSystemParamsHelper();

  const [keyboardActions, setKeyboardActions] = useState({ value: 1 });
  const [nomearPosicoesAtivo, setNomearPosicoesAtivo] = useState(false);
  const [positionData, setPositionData] = useState(DEFAULT_POSITION_DATA);
  const [disableToggle, setDisableToggle] = useState(false);

  const { setIsOpenModalQuantity } = useCart();

  const saleInProgressConfiguration =
    systemParams?.saleInProgressConfiguration?.workWithTableByPosition ?? false;

  useEffect(() => {
    try {
      const positionData = JSON.parse(sessionStorage.getItem("positionData"));

      if (positionData) {
        setPositionData(positionData);

        if (positionData.positions[0].name) {
          setNomearPosicoesAtivo(true);
          setDisableToggle(true);
        }
      }
    } catch (error) {
      if (window.Android) {
        console.log(
          "Error on ModalQuantityClient useEffect => " + JSON.stringify(error)
        );
      } else {
        console.error("Error on ModalQuantityClient useEffect => " + error);
      }
    }
  }, []);

  useEffect(() => {
    const initialValue =
      parseInt(sessionStorage.getItem("customersSelected")) || 1;
    setKeyboardActions({ value: initialValue });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("customersSelected", keyboardActions.value || 1);
  }, [keyboardActions.value]);

  useEffect(() => {
    if (!isOpen) {
      // Redefina positionData quando o modal for fechado
      setPositionData(DEFAULT_POSITION_DATA);
      setNomearPosicoesAtivo(false);
      setDisableToggle(false);
    }
  }, [isOpen]);

  const onHandleCLose = useCallback(() => {
    sessionStorage.removeItem("tableSelected");
    onClose();
  }, []);

  const handleTableSelected = () => {
    if (nomearPosicoesAtivo) {
      sessionStorage.setItem("positionData", JSON.stringify(positionData));

      sessionStorage.setItem(
        "customersSelected",
        positionData.positions.length
      );
    } else {
      const createdPositionData = positionData;

      for (let i = positionData.positions.length; i < Number(keyboardActions.value); i++) {
        createdPositionData.positions.push({ position: i + 1, name: "" });
      }

      sessionStorage.setItem(
        "positionData",
        JSON.stringify(createdPositionData)
      );

      sessionStorage.setItem(
        "customersSelected",
        createdPositionData.positions.length
      );
    }

    navigate(PagesEnum.HOME);
    setIsOpenModalQuantity(false);
  };

  const handleDecreaseQuantity = () => {
    if (nomearPosicoesAtivo) {
      const newPositions = [...positionData.positions];

      if (newPositions.length > 1) {
        newPositions.pop();
      }

      setPositionData({ positions: newPositions });
    } else {
      let currentValue = keyboardActions.value;

      if (currentValue < 1) {
        currentValue = 1;
      }

      const newQuantity = parseInt(currentValue) - 1;
      setKeyboardActions({ value: newQuantity });
    }
  };

  const handleIncreaseQuantity = () => {
    if (nomearPosicoesAtivo) {
      const newPositions = [...positionData.positions];
      const existingPositionIndex = newPositions.length;

      newPositions.push({
        position: existingPositionIndex + 1,
        name: "",
      });

      setPositionData({ positions: newPositions });
    } else {
      let currentValue = keyboardActions.value;

      if (currentValue < 1) {
        currentValue = 1;
      }

      const newQuantity = parseInt(currentValue) + 1;
      setKeyboardActions({ value: newQuantity });
    }
  };

  const handleCancel = () => {
    showAlert({
      message:
        "Deseja realmente cancelar? Os dados preenchidos serão apagados.",
      onConfirmText: "Sim",
      cancelButtonText: "Não",
      handleCancel: () => {},
      handleConfirm: () => {
        onHandleCLose();
      },
    });
  };

  const handleSetNomearPosicoesAtivo = () => {
    const newValue = !nomearPosicoesAtivo;

    setKeyboardActions({ value: 1 });
    setPositionData(DEFAULT_POSITION_DATA);

    setNomearPosicoesAtivo(!nomearPosicoesAtivo);
  };

  const keyboardButtons = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    {
      className: "backspace",
      value: "←",
    },
    "0",
    {
      className: "send",
      value: <MdCheck />,
      onClick: isClosing ? onConfirm : handleTableSelected,
    },
  ];

  if (!isOpen) {
    return <></>;
  }

  return (
    <KeyboardProvider>
      <Container>
        <div
          className={classNames("content__wrapper", {
            isSmartPos,
          })}
        >
          <div className="header__content">
            <header className="header">
              {saleInProgressConfiguration ? (
                <div className="title">Quantidade de Posições</div>
              ) : (
                <div className="title">Quantidade de Pessoas</div>
              )}
              <button onClick={onHandleCLose} className="button__close">
                <MdClose size="1.5rem" />
              </button>
            </header>
          </div>
          <div className="content__container_quantity">
            <div className="quantity__container">
              <div className="header__content title">
                <div className="header status">
                  {saleInProgressConfiguration ? (
                    <div className="table-number">
                      Quantas posições você quer incluir nessa mesa?
                    </div>
                  ) : (
                    <div className="table-number">
                      Quantas pessoas você quer incluir nessa mesa?
                    </div>
                  )}
                </div>
              </div>
              <div className="input__wrapper">
                <InputContent className={classNames({ isSmartPos })}>
                  <MdHorizontalRule
                    className="btn-quantity"
                    size="1rem"
                    onClick={handleDecreaseQuantity}
                  />
                  <input
                    value={
                      nomearPosicoesAtivo
                        ? positionData?.positions.length
                        : keyboardActions.value || 1
                    }
                    readOnly
                  />
                  <MdAdd
                    className="btn-quantity"
                    size="1rem"
                    onClick={handleIncreaseQuantity}
                  />
                </InputContent>
              </div>
              {saleInProgressConfiguration && (
                <div className="toggle__button">
                  <label>Nomear Posições</label>
                  <ToggleSwitch>
                    <input
                      type="checkbox"
                      disabled={disableToggle}
                      onChange={() => handleSetNomearPosicoesAtivo()}
                    />
                    <span className="slider round"></span>
                  </ToggleSwitch>
                </div>
              )}
              <div className="button-content">
                <button
                  className="button button__submit button__cancel"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
                <button
                  className="button button__submit"
                  onClick={isClosing ? onConfirm : handleTableSelected}
                  type="submit"
                >
                  OK
                </button>
              </div>
            </div>
            <div className="info__text__container">
              {!nomearPosicoesAtivo ? (
                <KeyboardNumeric
                  setActions={setKeyboardActions}
                  newButtons={keyboardButtons}
                  className={classNames({ display__hide: isSmartPos })}
                />
              ) : (
                <div className="identification-client-container">
                  <div className="title-client">Identificação</div>
                  <div className="identification-client">
                    {positionData.positions.map((_, index) => (
                      <div className="client-position-name" key={index}>
                        <div>
                          <span className="number">{index + 1}</span>
                        </div>
                        <div>
                          <input
                            className="input-client"
                            data-use-touch-keyboard
                            value={positionData?.positions?.[index]?.name ?? ""}
                            placeholder={`Identificação ${index + 1}`}
                            onChange={(e) => {
                              const positions = [...positionData.positions];
                              positions[index].name = e.target.value;
                              setPositionData({ positions });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </KeyboardProvider>
  );
};

export default ModalQuantityClient;
