import PubSub from "pubsub-js";

import {getHubEndpoint, getRetailerId, getTerminalID} from "../aaone/configuration";
import WSConnection from "./WSConnection";

class MessageBusService {
  connection = null;

  static _instance = new MessageBusService();

  _onConnected = () => {

  };

  subscriptions = [];

  constructor() {
    this.Subscribe("contigencychange", this.ConnectionChange.bind(this));
  }

  // eslint-disable-next-line class-methods-use-this
  Handle(message, pubSub = PubSub) {
    const parse = JSON.parse(message);

    if (parse) {
      console.log(`Event-Back: ${parse.routingKey}`, parse);
      // console.log(`Event-Back: ${parse.routingKey} / payload: ${JSON.stringify(parse.payload)}`);
    }

    pubSub.publish(parse.routingKey, parse.payload);
  }

  // eslint-disable-next-line class-methods-use-this
  Subscribe(channel, callbackPayload) {
    const token = PubSub.subscribe(channel, callbackPayload);

    this.subscriptions.push({
      channel,
      token,
    });

    return token;
  }

  Unsubscribe(subscription) {
    PubSub.unsubscribe(subscription);

    this.subscriptions = this.subscriptions.filter((item) => item.channel !== subscription);
  }

  FindSubscription(subscription) {
    return this.subscriptions.filter((item) => item.channel === subscription);
  }

  HasSubscription(subscription) {
    return this.FindSubscription(subscription).length > 0;
  }

  SubscribeIfNotExists(channel, callbackPayload) {
    const found = this.FindSubscription(channel);

    if (found.length === 0) return this.Subscribe(channel, callbackPayload);

    return found.token;
  }

  // eslint-disable-next-line class-methods-use-this
  Publish(channel, payload) {
    PubSub.publish(channel, payload);
  }

  ConnectionChange(topic, message) {
    this.CreateConnection(getHubEndpoint());
  }

  async CreateConnection(connectionString) {
    if (this.connection) {
      this.connection.Stop();
    }
    const connection = new WSConnection(
      `${connectionString}?PosId=${getTerminalID()}&RetailerId=${getRetailerId()}`
    );
    await connection.Start(this.Handle);
    this.connection = connection;
    this._onConnected();
  }

  OnConnected(handler) {
    this._onConnected = handler;
  }

  static GetInstance() {
    return MessageBusService._instance || (MessageBusService._instance = new MessageBusService());
  }
}

export default MessageBusService;
