import React from "react";
import { BoxWrapper, ButtonContainer, InfoSuppleData, TextData } from "./style";
import Button from "../../../Button";
import { formatDateHelper } from "../../../../helpers/formatDateHelper";

export const ReportCashierCloseSmartPos = ({ item, print }) => {
  return (
    <BoxWrapper>
      <InfoSuppleData>
        <TextData>
          <label>PDV:</label>
          <label>{item?.posId}</label>
        </TextData>
        <TextData>
          <label>Nº Abertura:</label>
          <label>{item?.openingCount}</label>
        </TextData>
        <TextData>
          <label>Operador:</label>
          <label>{item?.responsable}</label>
        </TextData>
        <TextData>
          <label>Data Abertura:</label>
          <label>
            {formatDateHelper(new Date(item?.openingDate), "dd/MM/yyyy HH:mm")}
          </label>
        </TextData>
        <TextData>
          <label>Data Fechamento:</label>
          <label>
            {formatDateHelper(new Date(item?.closingDate), "dd/MM/yyyy HH:mm")}
          </label>
        </TextData>
      </InfoSuppleData>
      <ButtonContainer>
        <Button className="btn-print" onClick={print}>
          <div>
            <i className="material-icons">print</i>
            IMPRIMIR
          </div>
        </Button>
      </ButtonContainer>
    </BoxWrapper>
  );
};
