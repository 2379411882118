import React from "react";
import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { PagesEnum } from "../../../constants/pagesEnum";
import buildInfo from "../../../../buildinfo.json";

import classNames from "classnames";
import { useWrapperRouters } from "../../../hook/wrapperRoutersHook";
import { LogoPDV } from "../../assets/Logo";
import MenuSections from "./MenuSections";
import { Container, Overlay, Wrapper } from "./styles";

export const MenuBar = () => {
  const { isOpenMenu, setIsOpenMenu } = useWrapperRouters();

  return (
    <>
      <Wrapper
        className={classNames({
          isOpenMenu,
        })}
      >
        <Container>
          <div className="header">
            <NavLink className="logo__container" to={PagesEnum.HOME}>
              <LogoPDV isLight />
              {buildInfo?.releaseVersion && (
                <div className="version">
                  <span>{buildInfo.releaseVersion}</span>
                </div>
              )}
            </NavLink>
            <div
              role="button"
              onClick={() => {
                setIsOpenMenu(false);
              }}
              className="close"
            >
              <MdClose size={32} />
            </div>
          </div>
          <div className="content">
            <MenuSections />
          </div>
        </Container>
      </Wrapper>
      <Overlay
        className={classNames(
          {
            isOpenMenu,
          },
          "overlay"
        )}
        onClick={() => setIsOpenMenu(false)}
      />
    </>
  );
};
