import React, { useState, useEffect } from "react";

import { cupomService } from "../../../services/cupomService";

import { useDispatch } from "react-redux";

import { checkout } from "../../actions/cart";

import pgtocaixa from "../../assets/pgtocaixa.svg";

import { useTranslation } from "react-i18next";

import setacarrinho from "../../assets/setacarrinho.svg";
import cabine from "../../assets/cabine-pgto.svg";
import cabinevaga from "../../assets/cabine-vaga.svg";
import logoBobs from "../../assets/logobobs.png";
import iconbobscomprovante from "../../assets/iconbobscomprovante.png";
import { getThemeSettings, isDisableLoyaltPoints } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { useViewDispatch } from "../../../context";

const CupomContainer = (props) => {
  const { t } = useTranslation();
  const { getTranslateName } = useLayoutAa();
  const finishPayment = getTranslateName(t, "finishPayment.thanks") || "OBRIGADO!";
  const receiptBelow = getTranslateName(t, "finishPayment.thanks") || "RETIRE SEU RECIBO ABAIXO";
  const waitNumber = getTranslateName(t, "finishPayment.wait_number") || "Retire seu recibo e aguarde pelo número:";
  const goToTheCashier = getTranslateName(t, "finishPayment.go_to_the_cashier") || "Retire seu recibo e dirija-se ao caixa para realizar o pagamento.";
  const nextBooth = getTranslateName(t, "finishPayment.next_booth") || "Retire seu recibo e dirija-se ao caixa para realizar o pagamento.";
  const waitInTheParking = getTranslateName(t, "finishPayment.wait_in_the_parking") || "Retire seu recibo e dirija-se ao caixa para realizar o pagamento.";
  const pointsTotal = getTranslateName(t, "fidelityPage.points_total") || "pontos";
  const pointsWon = getTranslateName(t, "fidelityPage.your_points_won") || "Você ganhou";


  const viewDispatch = useViewDispatch();
  const { isLayout, getScreenTitleBySlug } = useLayoutAa();
  const waitYourNumberText =
    getScreenTitleBySlug("AGUARDE_NUMERO") ||
    waitNumber;
  const getYourNoteAndPayText =
    getScreenTitleBySlug("DIRIJA_AO_CAIXA") ||
    goToTheCashier;
  const goToPaymentText =
    getScreenTitleBySlug("PROXIMA_CABINE") ||
    nextBooth;
  const waitInDriveText =
    getScreenTitleBySlug("H6") ||
    waitInTheParking;

  const recoveredName = window.localStorage.getItem("name");
  const controlOrder = window.localStorage.getItem("saleControl");
  const [promotion, setPromotion] = useState(false);
  const PontosCreditados = JSON.parse(localStorage.getItem("PontosCreditados"));

  const isBobsLayout = isLayout("BOBS");

  const dispatch = useDispatch();
  const cupomBobs = () => {
    if (isBobsLayout) {
      return (
        <footer className="footerBobsCupom">
          <img className="imgLogoBobsCupom" src={logoBobs} alt={logoBobs} />
          <h1 className="titleBobsCupom">{finishPayment}</h1>
          <h1 className="subtitleBobsCupom">{receiptBelow}</h1>
          <img
            className="iconbobscomprovante"
            src={iconbobscomprovante}
            alt={iconbobscomprovante}
          />
        </footer>
      );
    }
  };

  const imageCupom = () => {
    if (
      localStorage.getItem("paymentTransition") === "DINHEIRO" &&
      localStorage.getItem("styleAA") === "SHOP"
    ) {
      return (
        <footer className="pgtocaixa">
          <img className="setadinheiro" src={setacarrinho} alt={setacarrinho} />
          <img src={pgtocaixa} alt={pgtocaixa} />
        </footer>
      );
    }

    if (
      localStorage.getItem("paymentTransition") === "DINHEIRO" &&
      localStorage.getItem("styleAA") === "DRIVE"
    ) {
      return (
        <footer className="pgtocaixa">
          <img className="setadinheiro" src={setacarrinho} alt={setacarrinho} />
          <img src={cabine} alt={cabine} />
        </footer>
      );
    }

    if (
      localStorage.getItem("paymentTransition") === "DINHEIRO" &&
      localStorage.getItem("styleAA") === "DRIVEIN"
    ) {
      return (
        <footer className="pgtocaixa">
          <img className="setadinheiro" src={setacarrinho} alt={setacarrinho} />
          <img src={cabinevaga} alt={cabinevaga} />
        </footer>
      );
    }
  };

  const messageCupom = () => {
    if (isBobsLayout) {
      return <h1 className="subtitleBobsCupom">SEU PEDIDO É O NUMERO</h1>;
    }
    if (
      localStorage.getItem("paymentTransition") === "DINHEIRO" &&
      localStorage.getItem("styleAA") === "SHOP"
    ) {
      return <p className="warn-cupom">{getYourNoteAndPayText}</p>;
    }

    if (
      localStorage.getItem("paymentTransition") === "DINHEIRO" &&
      localStorage.getItem("styleAA") === "DRIVE"
    ) {
      return <p className="warn-cupom">{goToPaymentText}</p>;
    }

    if (
      localStorage.getItem("paymentTransition") === "DINHEIRO" &&
      localStorage.getItem("styleAA") === "DRIVEIN"
    ) {
      return <p className="warn-cupom">{waitInDriveText}</p>;
    }

    if (localStorage.getItem("paymentTransition") !== "DINHEIRO") {
      // localStorage.getItem("paymentTransition") !== "TESTEQA" ) {
      return <p className="warn-cupom">{waitYourNumberText}</p>;
    }
  };

  useEffect(() => {
    if (props.modalTimer) {
      props.modalTimer(false);
    }

    const timer = setTimeout(() => {
      dispatch(checkout());
      viewDispatch("step.banner");
    }, 9000);

    cupomService().then((res) => {
      if (!!res.payload.length) {
        setPromotion(res.payload[0].promotion);
      }
    });

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      className="cupom-template"
      style={{ backgroundImage: `url(${promotion.background})` }}
    >
      {cupomBobs()}
      <h1 className="name">{recoveredName}</h1>
      {messageCupom()}
      <strong className="code">{controlOrder}</strong>
      {!isDisableLoyaltPoints && PontosCreditados > 0 && (
        <p className="points">
          {isLayout("HABIBS") ? (
            <p>
              {pointsWon} <strong>{PontosCreditados} {pointsTotal}</strong> No HABIBERS
            </p>
          ) : (
            <p>
              {pointsWon}<strong>{PontosCreditados} {pointsTotal}</strong>{" "}
              {getThemeSettings?.text?.type_points_text
                ? getThemeSettings?.text?.type_points_text
                : "de fidelidade"}
            </p>
          )}
        </p>
      )}

      {imageCupom()}

      {promotion && (
        <footer className="promotion">
          <img src={promotion.image} alt={promotion.description} />
        </footer>
      )}
    </section>
  );
};

export default CupomContainer;
