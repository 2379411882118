import React from 'react';
import PropTypes from 'prop-types';
import SelectWrapper from './styles';

const Select = React.forwardRef(({
  handleClickToogleMenu, isToggleOnOption, listFormPayment, clickFormPayment, textToggle, icon, children
}, ref) => (
  <SelectWrapper innerRef={ref} className="title-select" onClick={handleClickToogleMenu} tabIndex="0">
    {textToggle}
    <div className="icon-align">
      <i className="material-icons">{icon ? 'expand_less' : 'expand_more'}</i>
    </div>
    {children}
    {isToggleOnOption
      ? (
        <div className="menu-area">
          { listFormPayment && listFormPayment.map(payment => (
            <span
              className="payment-option"
              key={payment.idForm}
              id={payment.idForm}
              onClick={clickFormPayment}
              tabIndex="0"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  clickFormPayment(e);
                }
              }}
            >
              {payment.name}
            </span>
          ))
          }
        </div>
      ) : ''}
  </SelectWrapper>
));

Select.propTypes = {
  handleClickToogleMenu: PropTypes.func.isRequired,
  isToggleOnOption: PropTypes.bool,
};

Select.defaultProps = {
  isToggleOnOption: undefined,
};
export default Select;
