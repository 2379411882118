import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import CellEditorCustom from "./cellEditorCustom";
import { isSmartPos } from "../../../aaone/configuration";

class CashCheck extends Component {
  render() {
    const {
      payments,
      handleEditingStartedCellvalue,
      handleEditingStoppedCellvalue,
      isBlindCashDeskClosing,
      gridRef,
    } = this.props;

    const rowHeight = 55;

    const columnsSmarPos = [
      {
        field: "paymentTypeName",
        headerName: "Tipo de Pagamento",
        filter: "agTextColumnFilter",
        fontWeight: "bold",
        rowGroup: true,
        hide: true,
      },
      {
        field: "manualCounting",
        headerName: "Ct. Manual",
        editable: (params) => !params.data.isReadOnly,
        cellClassRules: {
          "edit-disabled": (params) => params?.data?.isReadOnly,
        },
        resizable: true,
        cellEditor: "cellEditorCustom",
        valueFormatter: ({ value }) => {
          if (typeof value !== "undefined") {
            let valorFormatado = Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value);

            return valorFormatado;
          }
        },
      },
    ];

    const columnsWindows = [
      {
        field: "paymentTypeName",
        headerName: "Tipo de Pagamento",
        filter: "agTextColumnFilter",
        fontWeight: "bold",
        rowGroup: true,
        hide: true,
      },
      {
        field: "systemCounting",
        headerName: "Contagem Sistema",
        filter: "agTextColumnFilter",
        fontWeight: "bold",
        cellClassRules: {
          "hide-system-amount": (params) =>
            isBlindCashDeskClosing && !params.node.group,
          "edit-disabled": (params) => !params.node.group,
        },
        valueFormatter: ({ value }) => {
          if (typeof value !== "undefined") {
            let valorFormatado = Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value);

            return valorFormatado;
          }
        },
      },
      {
        field: "manualCounting",
        headerName: "Contagem Manual",
        editable: (params) => !params.data.isReadOnly,
        cellClassRules: {
          "edit-disabled": (params) => params?.data?.isReadOnly,
        },
        resizable: true,
        cellEditor: "cellEditorCustom",
        valueFormatter: ({ value }) => {
          if (typeof value !== "undefined") {
            let valorFormatado = Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value);

            return valorFormatado;
          }
        },
      },
    ];

    const columnsDefs = isSmartPos ? columnsSmarPos : columnsWindows;

    const defaultColDef = {
      flex: 1,
    };

    const autoGroupColumnDef = {
      headerName: isSmartPos ? "Tp. Pgto." : "Tipo de Pagamento",
      field: "paymentName",
      minWidth: isSmartPos ? 150 : 250,
    };

    const sideBar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
      ],
    };

    return (
      <div
        className="ag-theme-balham pdv"
        style={{ width: "100%", height: "400px" }}
      >
        <AgGridReact
          ref={gridRef}
          getRowNodeId={(data) => data.id}
          columnDefs={columnsDefs}
          defaultColDef={defaultColDef}
          groupDisplayType={"groupRows"}
          rowData={payments}
          rowHeight={rowHeight}
          localeText={agGridLocalePtBr}
          headerHeight={40}
          animateRows={true}
          autoGroupColumnDef={autoGroupColumnDef}
          // sideBar={sideBar}
          singleClickEdit
          frameworkComponents={{ cellEditorCustom: CellEditorCustom }}
          onCellEditingStarted={(event) => {
            handleEditingStartedCellvalue(event.value, event.data.id);
          }}
          onCellEditingStopped={(event) => {
            handleEditingStoppedCellvalue(
              event.newValue || event.oldValue,
              event.data.id
            );
          }}
        />
      </div>
    );
  }
}

export default CashCheck;
