import React, { useCallback, useEffect, useState } from "react";

import buildInfo from "../../../buildinfo.json";

import { LogoPDV } from "../assets/Logo";
import { MenuBar } from "./MenuBar";
import { Container } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { AAMenu } from "../icons/Menu";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { getTerminalID, isSmartPos } from "../../../aaone/configuration";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { useDialog } from "../../hook/dialogHook";
import { NOTIFICATION_INFO } from "../../constants/keyNamesSessionStorage";
import MessageBusService from "../../../services/MessageBusService";
import classNames from "classnames";
import { MdDeleteForever } from "react-icons/md";
import { currencyMask } from "../../helpers/masks";
import { useLoading } from "../../hook/loadingHook";
import GoalStatistics from "./GoalStatistics";
import { ButtonCart } from "./ButtonCart";

let timerTimeout;
const ONE_SECOND_IN_MILLISECONDS = 1000 * 60;

export const Header = () => {
  const location = useLocation();
  const { setShowLoading } = useLoading();
  const navigate = useNavigate();
  const { isCashdeskOpen, cashdesk } = useCashdesk();
  const { setIsOpenMenu, setVisibleSidebarCart, infoTable } =
    useWrapperRouters();
  const { showAlert } = useDialog();
  const [notification, setNotification] = useState(false);
  const [notificationMessages, setNotificationMessages] = useState(
    JSON.parse(sessionStorage.getItem(NOTIFICATION_INFO)) || []
  );
  const isPaymentPage = location.pathname.startsWith("/payment-");
  const isTablePage = location.pathname.startsWith("/saller-table");
  const paymentAlertMessage = () => {
    showAlert({
      message:
        "A ação de abrir o menu não é permitida durante o pagamento de uma venda!",
    });
  };

  const handleReceiveAlert = (payload) => {
    setNotificationMessages((curr) => {
      const updated = [...curr, payload];
      sessionStorage.setItem(
        NOTIFICATION_INFO,
        JSON.stringify([...updated, payload])
      );

      return updated;
    });

    setNotification(true);
  };

  const handleReceiveAttMigration = (payload) => {
    if (payload.key === "MENU_CHARGE_START") {
      setShowLoading(true, "Atualizando cardápio, aguarde!");
    }
    if (payload.key === "MENU_CHARGE_DONE") {
      setShowLoading(false);
    }
  };

  const handleRemoveAlert = useCallback(
    (pos) => {
      const updatedNotification = [...notificationMessages];
      updatedNotification.splice(pos, 1);

      sessionStorage.setItem(
        NOTIFICATION_INFO,
        JSON.stringify(updatedNotification)
      );
      setNotificationMessages(updatedNotification);
      setNotification(false);
    },
    [notificationMessages]
  );

  useEffect(() => {
    const messageBus = MessageBusService.GetInstance();
    messageBus.Subscribe("pdv.notification", (ch, payload) =>
      handleReceiveAlert(payload)
    );
    messageBus.Subscribe("pdv.menucharge", (ch, payload) =>
      handleReceiveAttMigration(payload)
    );

    return () => {
      messageBus.Unsubscribe("pdv.notification");
    };
  }, []);

  const [timeNow, setTimeNow] = useState(new Date());

  const generateNewCurrentTime = () => {
    setTimeNow(new Date());
  };

  useEffect(() => {
    if (timerTimeout) {
      clearInterval(timerTimeout);
    }

    timerTimeout = setInterval(
      generateNewCurrentTime,
      ONE_SECOND_IN_MILLISECONDS
    );

    return () => {
      if (timerTimeout) {
        clearInterval(timerTimeout);
      }
    };
  }, []);

  const CashdeskStatus = useCallback(() => {
    if (!isCashdeskOpen) {
      if (!cashdesk || cashdesk?.closingDate) {
        return (
          <span className="data-container-cashdesk">
            <strong>Caixa</strong>FECHADO
          </span>
        );
      }

      return (
        <span className="data-container-cashdesk">
          <strong>Caixa</strong>Reduzido
        </span>
      );
    } else {
      return (
        <>
          <span className="data-container-cashdesk">
            <span>Abertura</span>
            <strong>{cashdesk.openingCount}</strong>
          </span>
          <span className="data-container-cashdesk">
            <span>Dt. Abertura</span>
            <strong>
              {formatDateHelper(
                new Date(cashdesk?.openingDate),
                "dd/MM/yyyy HH:mm"
              )}
            </strong>
          </span>
        </>
      );
    }
  }, [isCashdeskOpen, cashdesk]);

  const Notifications = useCallback(() => {
    return (
      <div className="dropdown">
        <div className="notification">
          <i className="far fa-bell"></i>
          <div
            className={classNames(
              {
                visible: notification,
              },
              "notificationCounter"
            )}
          >
            {notificationMessages.length}
          </div>
        </div>

        {notificationMessages.length > 0 && (
          <div className="dropdown-content">
            {notificationMessages?.map((i, pos) => (
              <div className="notification-content" key={`notify${pos}`}>
                <span className="textNotification">{i.message}</span>
                <MdDeleteForever
                  className="isRed"
                  onClick={() => handleRemoveAlert(pos)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }, [handleRemoveAlert, notification, notificationMessages]);

  return (
    <>
      <MenuBar />
      <Container className={classNames("header-main-top", { isSmartPos })}>
        <div className="logo-wrapper">
          <div
            role="button"
            onClick={() =>
              isPaymentPage ? paymentAlertMessage() : setIsOpenMenu(true)
            }
            className="menu-bottom"
          >
            <AAMenu size="2rem" />
          </div>
          <div className="logo">
            <div
              onClick={() => {
                navigate(PagesEnum.HOME);
                setVisibleSidebarCart();
              }}
            >
              <LogoPDV isHideName={isSmartPos} />
              {buildInfo?.releaseVersion && (
                <div
                  className="version"
                  onClick={() => {
                    if (window?.uploadLogStorage) {
                      window.uploadLogStorage(
                        formatDateHelper(new Date(), "dd-MM-yyyy")
                      );
                    }
                  }}
                >
                  <span>{buildInfo.releaseVersion}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="details-container-main">
          <div className="details-container">
            {isCashdeskOpen && (
              <span className="name">
                <strong>{cashdesk.user}</strong>
              </span>
            )}
            <span className="pdv">
              <span className="label">PDV</span>
              <strong className="value">Caixa {getTerminalID()}</strong>
            </span>
            <CashdeskStatus />
          </div>
          {isTablePage && (
            <span className="table__container_details">
              <span className="spaceOccupation">
                OCUPAÇÃO:
                <strong>
                  {(
                    infoTable?.porcentTablesActive +
                      infoTable?.porcentTablesClosing ?? 0
                  )?.toFixed(2)}
                  %
                </strong>
              </span>
              <span className="spaceOccupation">
                TOTAL VENDA:
                <strong>{currencyMask(infoTable?.totalValue)}</strong>
              </span>
              <span className="textModel">
                MODO: <strong>MESA</strong>
              </span>
            </span>
          )}
          <div className="notification">
            <span>
              <strong className="timerNew">
                {formatDateHelper(timeNow, "HH:mm")}
              </strong>
            </span>
            <Notifications />
            <GoalStatistics />
          </div>
          {isSmartPos && <ButtonCart />}
        </div>
      </Container>
    </>
  );
};
