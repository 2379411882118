import React, { Component } from "react";
import PropTypes from "prop-types";

import { PeriodWraper } from "./period.style";
import DatePicker from "../../components/date-picker";
import { FormControl } from "../form-control";

export class Period extends Component {
  static propTypes = {
    object: PropTypes.objectOf(PropTypes.any),
    labelStart: PropTypes.string,
    labelEnd: PropTypes.string,
    fieldStart: PropTypes.string,
    fieldEnd: PropTypes.string,
    requiredStart: PropTypes.bool,
    requiredEnd: PropTypes.bool,
    initialValueStart: PropTypes.objectOf(PropTypes.any),
    initialValueEnd: PropTypes.objectOf(PropTypes.any),
    showFieldEnd: PropTypes.bool
  };

  static defaultProps = {
    object: null,
    labelStart: "Data Inicial",
    labelEnd: "Data Final",
    requiredStart: false,
    requiredEnd: false,
    fieldStart: "startDate",
    fieldEnd: "endDate",
    initialValueStart: null,
    initialValueEnd: null,
    showFieldEnd: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessageStart: null,
      errorMessageEnd: null,
    };
  }

  validate = () => {
    let valid = this.startRef.validate() && this.endRef.validate();

    if (valid) {
      const initialDate = this.startRef.getValue();
      const endDate = this.endRef.getValue();

      if (new Date(initialDate) > (new Date (endDate))) {
        this.setState({
          errorMessageEnd: "A data inicial é maior que a data final.",
        });
        valid = false;
      } else {
        this.setState({
          errorMessageEnd: "",
        });
      }
    }

    return valid;
  };

  getValue = () => {
    return {
      start: this.startRef.getValue(),
      end: this.endRef.getValue(),
    };
  };

  changeInitialDate = (param) => {
    if (typeof this.props.changeDate === "function") this.props.changeDate(param);
  }


  render() {
    const {
      labelStart,
      labelEnd,
      fieldStart,
      fieldEnd,
      initialValueStart,
      initialValueEnd,
      requiredStart,
      requiredEnd,
      object,
      showFieldEnd = true,
    } = this.props;

    const { errorMessageStart, errorMessageEnd } = this.state;

    return (
      <PeriodWraper>
        <FormControl
          ref={(ref) => (this.startRef = ref)}
          required={requiredStart}
          label={labelStart}
          component={DatePicker}
          object={object}
          field={fieldStart}
          flex="0.5"
          initialvalue={initialValueStart || {}}
          errorMessage={errorMessageStart}
          onChange={this.changeInitialDate}
        />
        {showFieldEnd && (<FormControl
          ref={(ref) => (this.endRef = ref)}
          required={requiredEnd}
          label={labelEnd}
          component={DatePicker}
          object={object}
          field={fieldEnd}
          flex="0.5"
          initialvalue={initialValueEnd}
          errorMessage={errorMessageEnd}
        />)}
      </PeriodWraper>
    );
  }
}
