import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import App from "./App";

import store from "./store";
import Configuration, { isPDVOne } from "./aaone/configuration";
import { GlobalStyle } from "./aaone/styles/main";
import { VersionProvider } from "./aaone/contexts/versionContext";
import { LayoutProvider } from "./aaone/contexts/layout";
import { isLayoutUtil } from "./aaone/shared/utils/isLayout";

localStorage.removeItem("errorResponseAxios");
if (isLayoutUtil("HABIBS")) {
  require("./aaone/assets/fonts/Bib'sans-Bold.otf");
}

if (!window.desktopApp) {
  /* eslint-disable no-unused-expressions */
  import("./aaone/App_Tablet.css");
}

const AAoneTheme = React.lazy(() => import("./aaone/themes/aaone"));
const HabibsTheme = React.lazy(() => import("./aaone/themes/habibs"));
const RagazzoTheme = React.lazy(() => import("./aaone/themes/ragazzo"));
const RagazzoExpressTheme = React.lazy(() =>
  import("./aaone/themes/ragazzoexpress")
);
const BobsTheme = React.lazy(() => import("./aaone/themes/bobs"));
const NectarTheme = React.lazy(() => import("./aaone/themes/nectar"));
const MontanaTheme = React.lazy(() => import("./aaone/themes/montana"));

const setLayout = () => {
  if (window.desktopApp) {
    window.desktopApp.publish("machine.devices.tef.getTypeTef", {});
  }
  return Configuration.layout;
};

const ThemeSelector = ({ children }) => (
  <>
    {!isPDVOne && (
      <React.Suspense fallback={<></>}>
        {isLayoutUtil("AAONE") && <AAoneTheme />}
        {isLayoutUtil("HABIBS") && <HabibsTheme />}
        {isLayoutUtil("RAGAZZO") && <RagazzoTheme />}
        {isLayoutUtil("RAGAZZOEXPRESS") && <RagazzoExpressTheme />}
        {isLayoutUtil("BOBS") && <BobsTheme />}
        {isLayoutUtil("MONTANA") && <MontanaTheme />}
        {isLayoutUtil("NECTAR") && <NectarTheme />}
      </React.Suspense>
    )}
    <GlobalStyle />
    {children}
  </>
);

const RenderApp = () => (
  <ThemeSelector>
    <LayoutProvider>
      <Provider store={store}>
        <VersionProvider>
          <App />
        </VersionProvider>
      </Provider>
    </LayoutProvider>
  </ThemeSelector>
);

ReactDOM.render(<RenderApp />, document.getElementById("root"));
serviceWorker.unregister();
