import React from 'react';

import linxLogo from "../../aaone/assets/linx.png";

import { Container } from './styles';

export const LoadingStartApp = () => {
  return (
    <Container>
      <img src={linxLogo} alt="Linx" />
      <div className="message">
        <div className="ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>CARREGANDO</div>
      </div>
    </Container>
  );
}
