import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { cancelOperation } from "../../../../services/verifyRedeemValueService";
import { useCart } from "../../../hook/cartHook";
import { LoadingWhiteWithText } from "../../../components/LoadingWhiteWithText";
import { PagesEnum } from "../../../constants/pagesEnum";
import { Container } from "../../paymentMethods/styles";
import { processesOperationService } from "../../../../services/processesOperationService";
import { sendSaleOrderService } from "../../../../services/sendSaleOrderService";
import { transactionData } from "../../../../aaone/shared/utils/transactionNumber";
import { printerActionService } from "../../../../services/actions/printerActionService";
import { getPaymentMethodName } from "../../../helpers/getPaymentMethodName";
import { nsuValueHelper } from "../../../helpers/nsuValueHelper";
import { useCashdesk } from "../../../hook/cashdeskHook";

const TEXT_STATUS_DEFAULT = "Transação em andamento. Por favor, aguarde.";
const TRANSMITTING_NOTE = "Aguarde transmitindo a nota...";

let timeout;

export const PaymentTestQAPage = () => {
  const {
    reshopTotalPrice,
    totalPrice,
    totalPriceWDiscount,
    itemsInCart,
    Sale,
    manualDiscount,
  } = useCart();

  const navigate = useNavigate();
  const location = useLocation();
  const { cashdesk } = useCashdesk();

  const [starting, setStarting] = useState(true);
  const [textStatus, setTextStatus] = useState(TEXT_STATUS_DEFAULT);

  const goToPaymentMethods = () => {

    navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
  };

  useEffect(() => {
    const paymentMethodName = getPaymentMethodName();

    if (timeout) {
      setTextStatus(TEXT_STATUS_DEFAULT);
      clearTimeout(timeout);
    }

    if (paymentMethodName !== "TESTEQA") {
      goToPaymentMethods();
    } else {
      setStarting(false);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      processesOperationService({
        cartTotalPrice: totalPrice,
        cartTotalPriceWithoutQuantity: totalPrice,
        itemsCart: itemsInCart,
        totalInCart: itemsInCart.length,
      }).then(async () => {
        try {
          const paymentTransition = "TESTEQA";

          timeout = setTimeout(() => {
            setTextStatus(TRANSMITTING_NOTE);
          }, 3000);

          const orderResult = await sendSaleOrderService({
            payments: [
              {
                dateTimeInsert: transactionData(
                  new Date(),
                  "MM/dd/yyyy hh:mm:ss"
                ),
                sitefCode: 0,
                value: totalPriceWDiscount,
                paymentTransition,
                reshop: false,
                paymentCode: 0,
                authorizationCodeOnline: null,
                qrLinxTransactionId: null,
                qrLinxTradingName: null,
                sticker: null,
                carPlate: null,
                semPararLane: null,
                transactionDatetime: "NaN/NaN/NaN NaN:NaN:NaN",
              },
            ],
            options: {
              hasTef: false,
              paymentTransition,
            },
            prices: {
              totalPrice,
              totalPriceWDiscount,
              reshopTotalPrice: reshopTotalPrice ? reshopTotalPrice : 0,
              totalManualDiscount: manualDiscount,
            },
            items: itemsInCart,
            Sale,
            cashDeskOpenDateId: cashdesk.cashDeskOpenDate.id,
          });
          const {
            errorWhenAuthorizing,
            pathDocFiscal,
            saleControl,
            danfeBase64
          } =
            orderResult?.payload?.orderSale;

          printerActionService({
            errorWhenAuthorizing,
            pathDocFiscal,
            saleControl,
            danfeBase64
          });

          setTimeout(() => {
            navigate(PagesEnum.PAYMENT_SUCCESSFUL, { replace: true });
          }, 250);
        } catch (error) {
          console.log(error);
          cancelOperation(nsuValueHelper()).then(() => {
            goToPaymentMethods();
          });
        }
      });
    })();
  }, [reshopTotalPrice, totalPrice]);

  if (starting) {
    return <></>;
  }

  return (
    <Container>
      <div className="loading__container">
        <LoadingWhiteWithText>{textStatus}</LoadingWhiteWithText>
      </div>
    </Container>
  );
};
