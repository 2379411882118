import styled from "styled-components";

export const OptionalCampaignContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .actions {
    button {
      margin: 8px;
    }
  }

  .carousel__optionalCampaign {
    position: relative;
    width: 100%;

    .slick-slider {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0;
      width: 100%;
      height: 100%;

      grid-gap: 16px;
      gap: 16px;

      .slick-list {
        width: 100%;
        height: 100%;
      }

      .slick-slide > div:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
`;

export const CampaignDescription = styled.div`
  font-family: "Dosis", sans-serif;
  font-size: 1.2em;
  min-width: 0;
  width: 100%;
  max-width: 100%;

  :not(.isSmartPos) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.isSmartPos {
    white-space: pre-wrap;
    word-break: break-all;
    overflow-wrap: break-word;
    overflow: hidden;

    position: relative;
  }

  .name {
    font-weight: bold;
    color: #333333;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  height: 80%;
  justify-content: space-between;
  position: relative;

  &.isSmartPos {
    .cell-wrap-text {
      white-space: normal !important;
    }

    .ag-row {
      height: 100% !important;
    }
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 25px;
  bottom: 25px;
  flex-direction: column;
  position: absolute;
  right: 0;

  &.isSmartPos {
    bottom: 50px;
    top: 50px;
  }
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;
