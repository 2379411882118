import React from "react";
import InfoModal from "./info-modal";

const InfoModalContainer = (props) => {
  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <>
      {props.message && (
        <InfoModal visible handleClose={handleClose} message={props} />
      )}
    </>
  );
};

export default InfoModalContainer;
