import React from "react";

import ModalWrapper from "./style";

import { currency } from "../../utils/currency";

const ModalCombo = (props) => {
  const { selectedCombo } = props;

  const { title, value, valuePoints, image, quantity, currencyPoints } = props;

  const handleCombo = () => {
    selectedCombo(true);
  };

  const handleIndividual = () => {
    selectedCombo(false);
  };

  return (
    <>
      <ModalWrapper>
        <div className="modalcombo">
          <h1 className="category-title">Combo vale a pena!</h1>
          <h2 className="category-subTitle">
            Aproveito o nosso combo e economize mais
          </h2>

          <div
            className="container container-combo"
            onClick={() => handleCombo()}
          >
            {image === null ? (
              <div className="no-image" />
            ) : (
              <img className="testexd" src={image} alt={image} />
            )}
            <div className="divdescription">
              <h1 className="title">COMBO {title} </h1>
              <h2 className="descriptioncombo">
                {title} + batata + bebida 300ml
              </h2>
              <h2 className="descriptionvalue">
                DE{" "}
                {!currencyPoints
                  ? currency(value * quantity)
                  : `${valuePoints} PONTOS`}
              </h2>
              <h2 className="subtitlevalor">
                POR{" "}
                {!currencyPoints
                  ? currency(value * quantity)
                  : `${valuePoints} PONTOS`}
              </h2>
            </div>
          </div>
          <div
            className="container container-individual"
            onClick={() => handleIndividual()}
          >
            {image === null ? (
              <div className="no-image" />
            ) : (
              <img className="testexd" src={image} alt={image} />
            )}
            <div className="divdescription">
              <h1 className="title">{title} INDIVIDUAL</h1>
              <h2 className="subtitle">
                {!currencyPoints
                  ? currency(value * quantity)
                  : `${valuePoints} PONTOS`}
              </h2>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default ModalCombo;
