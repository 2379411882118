import React, { Component } from "react";
import Modal from "react-modal";
import PropType from "prop-types";
import classNames from "classnames";

import { isSmartPos } from "../../../aaone/configuration";

import MessageBoxWrapper from "./style";
import { Button } from "../../components/Button";

class MessageBox extends Component {
  constructor(props) {
    super(props);

    this.keyDown = this.keyDown.bind(this);
    this.state = {
      btnConfirmEnabled: false,
      btnConfirmCountDown: 0,
      btnCancelCountDown: 0,
    };

    this.customStyle = {
      content: {
        maxWidth: "600px",
        // height: '210px',
        bottom: "initial",
        top: "50%",
        transform: "translateY(-50%)",
        background: "#ffffff",
        margin: "auto",
        border: "none",
      },
      overlay: {
        background: "rgba(0, 0, 0, 0.60)",
        display: "block",
        zIndex: "99999",
      },
      iconCloseModal: {
        float: "right",
        cursor: "pointer",
      },
    };

    this.countDownEnableConfirmButton =
      this.countDownEnableConfirmButton.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      // this.modalRef.addEventListener('keydown', this.keyDown, false);

      if (this.cancelBtnRef) {
        this.cancelBtnRef.focus();
      } else if (this.confirmBtnRef) {
        this.confirmBtnRef.focus();
      }
    });

    if (this.props.delayConfirmButton > 0) {
      this.setState(
        (prevState, props) => ({
          btnConfirmEnabled: true,
          btnConfirmCountDown: props.delayConfirmButton,
        }),
        () => {
          this.countDownEnableConfirmButton();
        }
      );
    }
  }

  componentWillUnmount() {
    // this.modalRef.removeEventListener('keydown', this.escFunction);
  }

  countDownEnableConfirmButton() {
    if (this.state.btnConfirmCountDown > 0) {
      setTimeout(() => {
        this.setState((prevState, props) => ({
          btnConfirmCountDown: prevState.btnConfirmCountDown - 1,
        }));
        this.countDownEnableConfirmButton();
      }, 1000);
    } else {
      this.setState({
        btnConfirmEnabled: false,
        btnConfirmCountDown: 0,
      });
    }
  }

  keyDown(e) {
    switch (e.key) {
      case "Escape": {
        e.stopPropagation();

        const action = this.props.handleClose || this.props.handleCancel;

        if (action) {
          action();
        }

        break;
      }
      case "ArrowLeft": {
        e.stopPropagation();
        this.confirmBtnRef.focus();
        break;
      }
      case "ArrowRight": {
        e.stopPropagation();
        this.cancelBtnRef.focus();
        break;
      }
      default:
        break;
    }
  }

  render() {
    const {
      message,
      handleCancel,
      handleClose,
      handleConfirm,
      okText,
      cancelText,
      title,
    } = this.props;

    return (
      message && (
        <Modal
          contentRef={(ref) => {
            this.modalRef = ref;
          }}
          isOpen
          closeOnOverlayClick={false}
          center
          style={this.customStyle}
          ariaHideApp={false}
        >
          {okText !== "OK" && (
            <span
              className="material-icons attention close-modal"
              style={this.customStyle.iconCloseModal}
              onClick={handleClose || handleCancel}
            >
              cancel
            </span>
          )}
          <MessageBoxWrapper>
            <h3 id="title">{title}</h3>
            <p>{message}</p>
            <div className={classNames({ isSmartPos }, "modal__footer")}>
              {handleCancel && (
                <Button
                  id="btn-cancel"
                  ref={(btn) => {
                    this.cancelBtnRef = btn;
                  }}
                  className="btn-default btn-cancel"
                  type="button"
                  onClick={handleCancel}
                >
                  {this.state.btnCancelCountDown
                    ? `(${this.state.btnCancelCountDown})`
                    : cancelText}
                </Button>
              )}
              <Button
                id="btn-confirm"
                ref={(btn) => {
                  this.confirmBtnRef = btn;
                }}
                className="btn-default btn-outline"
                type="button"
                onClick={handleConfirm || handleClose || handleCancel}
                disabled={this.state.btnConfirmEnabled}
              >
                {this.state.btnConfirmCountDown
                  ? `(${this.state.btnConfirmCountDown})`
                  : okText}
              </Button>
            </div>
          </MessageBoxWrapper>
        </Modal>
      )
    );
  }
}

MessageBox.propTypes = {
  message: PropType.oneOfType([PropType.string, PropType.element]),
  title: PropType.string,
  handleClose: PropType.func,
  handleCancel: PropType.func,
  handleConfirm: PropType.func,
  okText: PropType.string,
  cancelText: PropType.string,
  delayConfirmButton: PropType.number,
};

MessageBox.defaultProps = {
  message: null,
  title: "Mensagem do sistema",
  handleClose: null,
  handleCancel: null,
  handleConfirm: null,
  okText: "OK",
  cancelText: "NÃO",
  delayConfirmButton: 0,
};

export default MessageBox;
