import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useState } from "react";
import UAParser from "ua-parser-js";

import {
  getCurrentSystemDataInStorage,
  getCurrentSystemVersionService,
} from "../../../services/getCurrentSystemVersion";
import { transactionData } from "../../shared/utils/transactionNumber";
import buildInfo from "../../../buildinfo.json";
import { Container, InfoWarn, Main } from "./styles";
import linxLogo from "../../assets/linx.png";
import { isPDVOne } from "../../configuration";
import { PDV_COD_LOJA_KEY_NAME } from "../../../pdv-one/constants/keyNamesSessionStorage";

export const VersionAaContext = createContext({});

const getCurrentTime = () => transactionData(new Date(), "dd/MM/yyyy hh:mm:ss");

let isLoadingCurrentVersion = false;
let timeInTimeout;
export const VersionProvider = ({ children }) => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [versionInfo, setVersionInfo] = useState(() => {
    const currentSystemVersion = getCurrentSystemDataInStorage();

    if (currentSystemVersion?.front) {
      return currentSystemVersion;
    }

    return null;
  });

  const parser = new UAParser();
  const os = parser.getOS();
  const currentOs = `${os.name} ${os.version} ${
    parser.getCPU()?.architecture ?? ""
  }`;

  const [isHide, setIsHide] = useState(false);
  const [isHideShortcut, setIsHideShortcut] = useState(true);

  const hide = () => {
    setIsHide(true);
  };

  const show = () => {
    setIsHide(false);
  };

  const onEventKeyUp = useCallback((e) => {
    const shortcuts = ["F1", "i"];

    if (shortcuts.includes(e.key)) {
      setIsHideShortcut((isVisible) => !isVisible);
    }
  }, []);

  const isHideInfo = useMemo(
    () => isHide && isHideShortcut,
    [isHide, isHideShortcut]
  );

  useEffect(() => {
    if (!isLoadingCurrentVersion) {
      isLoadingCurrentVersion = true;

      getCurrentSystemVersionService().then((currentVersionInfo) => {
        setVersionInfo(currentVersionInfo);

        if (currentVersionInfo) {
          const frontVersion = `AAOne - ${currentVersionInfo.front.version} - PDV: ${currentVersionInfo?.terminalId}`;
          document.title = frontVersion;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isHide) {
      setTimeout(() => {
        hide();
      }, 5000);
    }
  }, [isHide]);

  useEffect(() => {
    if (!isPDVOne) {
      window.addEventListener("keyup", onEventKeyUp);
    }

    return () => {
      window.removeEventListener("keyup", onEventKeyUp);
    };
  }, [onEventKeyUp]);

  useEffect(() => {
    if (timeInTimeout) {
      clearInterval(timeInTimeout);
    }

    if (!isHideInfo) {
      timeInTimeout = setInterval(() => {
        setCurrentTime(getCurrentTime);
      }, 1000);
    }
  }, [isHideInfo]);

  return (
    <VersionAaContext.Provider value={{ show, hide }}>
      <Main className={isHideInfo ? "hide" : ""}>
        {versionInfo &&
          !buildInfo.electronMinVersionRequired
            .split(",")
            .map((v) => v.trim())
            .includes(versionInfo.shortcutIcon.version) && (
            <InfoWarn>
              TERMINAL COM VERSÃO DO ATALHO DO AUTO ATENDIMENTO INCOMPATÍVEL,
              FAVOR ATUALIZAR!
            </InfoWarn>
          )}
        {versionInfo && (
          <Container>
            <header className="header">
              <span>AAOne</span>
              <img className="linx" src={linxLogo} alt="Linx" />
            </header>
            <ul className="content">
              <li className="item">
                <div className="label">API</div>
                <div className="value">{versionInfo.api?.version}</div>
              </li>
              <li className="item">
                <div className="label">WS</div>
                <div className="value">{versionInfo.ws?.version}</div>
              </li>
              <li className="item">
                <div className="label">Front</div>
                <div className="value">{versionInfo.front?.version}</div>
              </li>
              <li className="item">
                <div className="label">Atalho AAOne</div>
                <div className="value">{versionInfo.shortcutIcon?.version}</div>
              </li>
              <li className="item">
                <div className="label">Sistema</div>
                <div className="value">{currentOs}</div>
              </li>
            </ul>
            <footer className="footer">
              Loja {window.sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME) || ""} - PDV{" "}
              {versionInfo.terminalId}
              <br />
              {currentTime}
            </footer>
          </Container>
        )}
      </Main>
      {children}
    </VersionAaContext.Provider>
  );
};

export const useVersionAa = () => {
  const context = useContext(VersionAaContext);

  return context;
};
