import styled from "styled-components";

export const DiscountCartDisplay = styled.section`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.background.secondary};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.medium};
  color: ${(props) => props.theme.colors.dark};
  margin: 5px 0;
  padding: 0 5px 0 10px;
  text-transform: uppercase;

  &.disabled {
    background-color: #dddddd;
    opacity: 0.5;

    &,
    & * {
      pointer-events: none;
    }
  }

  .margin-right {
    margin-right: 20px;
  }

  .alert-add-discount {
    font-family: ${(props) => props.theme.fontFamily.primary};
    color: red;

    &:hover {
      cursor: pointer;
      color: #ff0000b8;
    }
  }

  .discount-value-click {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.palette.color[1]};
    }
  }
`;

DiscountCartDisplay.displayName = "DiscountCartDisplay";

export const DiscountButton = styled.img`
  align-self: center;
  width: 20px;
  height: 20px;
`;

DiscountButton.displayName = "DiscountButton";

export const DiscountCartModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

DiscountCartModalContainer.displayName = "DiscountCartModalContainer";

export const InputDiscount = styled.input`
  text-align: right;
  border-color: ${(props) => (props.valid ? "#ef5350" : "#C8CBD0")};
`;

InputDiscount.displayName = "InputDiscount";

export const DiscountCartHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;

  h2 {
    color: #414141;
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-weight: 600;
    opacity: 0.8;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    grid-gap: 1rem;
    gap: 1rem;
  }
`;

DiscountCartHeader.displayName = "DiscountCartHeader";

export const DiscountCartMain = styled.main`
  display: flex;
  flex-direction: column;
`;

DiscountCartMain.displayName = "DiscountCartMain";

export const DiscountCartKeyboard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .keyboard-numeric {
    padding: 0;

    .button {
      font-size: 1rem;
    }
  }
`;

DiscountCartKeyboard.displayName = "DiscountCartKeyboard";

export const ButtonItem = styled.button`
  width: 32%;
  height: 45px;
  margin-bottom: 5px;
  border: 1px solid ${(props) => props.theme.background.primary};
  font-size: 18px;
  box-shadow: 0px 1px 0px #848688;
  padding: 0;
  border-radius: 6px;
  background-color: white;
  outline: none;
`;

ButtonItem.displayName = "ButtonItem";

export const DiscountCartFooter = styled.footer`
  display: flex;
  justify-content: space-between;
`;

DiscountCartFooter.displayName = "DiscountCartFooter";

export const ButtonDiscount = styled.button`
  max-width: 120px;
  width: 100%;
  height: 40px;
  font-size: 0.75rem !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;

  margin: 10px 0;

  background: #411e5a linear-gradient(90deg, #411e5a 0%, #411e5a 100%);
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 4px;
  border: none;

  color: white;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-left: 0.5rem;
  }
`;

ButtonDiscount.displayName = "ButtonDiscount";

export const ButtonClose = styled.button`
  height: 36px;
  width: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.background.secondary};
  border: 1px solid ${(props) => props.theme.background.primary};

  box-shadow: 0px 1px 0 #848688;
  border-radius: 6px;
  padding: 0;

  font-size: 1rem !important;
  line-height: 100%;
`;

ButtonClose.displayName = "ButtonClose";

export const ButtonFooter = styled.button`
  max-width: 120px;
  height: 50px;
  width: 100%;

  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;

  margin: 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &.confirm {
    background: #411e5a linear-gradient(90deg, #411e5a 0%, #411e5a 100%);
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 4px;
    border: none;
    color: white;
  }

  &.cancel {
    background: #ffffff 0% 0% no-repeat padding-box;
    letter-spacing: 0;
    border: 2px solid #cccccc;
    border-radius: 4px;
    color: #411e5a;
    margin-right: 16px;
  }
`;

ButtonFooter.displayName = "ButtonFooter";

export const ErrorMessage = styled.small`
  font-size: small;
  color: #ef5350;
  padding: 2px;
  text-align: center;
  font-style: italic;
  height: 20px;
`;

ErrorMessage.displayName = "ErrorMessage";
