import React from "react";
import { render } from "react-dom";
import PrintTemplate from "../../components/print-template";
import { formatDateHelper } from "../formatDateHelper";
import { currencyMask } from "../masks";
import { printerActionService } from "../../../services/actions/printerActionService";
import { lineToPrintHelper } from "../lineToPrintHelper";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const handlePrintCoupon = (cashdesk, currWithdraw) => {
  var html = document.createElement("html");

  render(
    <PrintTemplate>
      <>
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "SANGRIA DE CAIXA"
              : "                 SANGRIA DE CAIXA"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        PDV: {cashdesk.posId}{" "}
        {isMobile ? "\n" : ""}Data:{" "}
        {formatDateHelper(
          new Date(currWithdraw.creationDate),
          "dd/MM/yyyy HH:mm:ss"
        )}
        {isMobile ? "\n" : ""}
        <br />
        Operador: {currWithdraw.operatorName}
        {isMobile ? "\n" : ""}
        <br />
        Responsável: {currWithdraw.adminName}
        {isMobile ? "\n" : ""}
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <table>
          <tr>
            <td className="shrink">Controle: </td>
            <td className="expand">
              {currWithdraw.referenceSequence}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Valor: </td>
            <td className="expand">
              <b>
                {currencyMask(currWithdraw.amount)}
                {isMobile ? "\n" : ""}
              </b>
            </td>
          </tr>
          <tr>
            <td className="shrink">Histórico: </td>
            <td className="expand">
              {currWithdraw.description} {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Número Envelope: </td>
            <td className="expand">
              {currWithdraw.numberEnvelop}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
        </table>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          {!isMobile
            ? "---------------------------------------------------"
            : lineToPrintHelper}
          <b>
            {!isMobile
              ? "Assinatura/carimbo do responsável"
              : "         Assinatura/carimbo do responsável"}
          </b>
        </div>
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "*NÃO É DOCUMENTO FISCAL*"
              : "            *NÃO É DOCUMENTO FISCAL*"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
      </>
    </PrintTemplate>,
    html
  );

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    printDefault(html.outerHTML);
  }
};

export const handlePrintChargeback = (cashdesk, currWithdraw) => {
  var html = document.createElement("html");

  render(
    <PrintTemplate>
      <>
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "COMPROVANTE DE ESTORNO"
              : "            COMPROVANTE DE ESTORNO"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        PDV: {cashdesk.posId} DATA:{" "}
        {formatDateHelper(
          new Date(currWithdraw.creationDate),
          "dd/MM/yyyy HH:mm:ss"
        )}
        {isMobile ? "\n" : ""}
        <br />
        Operador: {currWithdraw.operatorName}
        {isMobile ? "\n" : ""}
        <br />
        Responsável: {currWithdraw.adminName}
        {isMobile ? "\n" : ""}
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <table>
          <tr>
            <td className="shrink">Controle: </td>
            <td className="expand">
              {currWithdraw.referenceSequence}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Valor: </td>
            <td className="expand">
              <b>
                {currencyMask(currWithdraw.amount)}
                {isMobile ? "\n" : ""}
              </b>
            </td>
          </tr>
          <tr>
            <td className="shrink">Histórico: </td>
            <td className="expand">
              {currWithdraw.description}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
        </table>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          {!isMobile
            ? "---------------------------------------------------"
            : lineToPrintHelper}
          <b>
            {!isMobile
              ? "Assinatura/carimbo do responsável"
              : "         Assinatura/carimbo do responsável"}
          </b>
        </div>
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "*NÃO É DOCUMENTO FISCAL*"
              : "              *NÃO É DOCUMENTO FISCAL*"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
      </>
    </PrintTemplate>,
    html
  );

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    printDefault(html.outerHTML);
  }
};
