import {
  CART_COUPONS_KEY_NAME,
  RESHOP_DISCOUNTS,
  RESHOP_TOTAL_DISCOUNT_KEY_NAME,
  DATA_USER_AUTH_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import SaleUseCase from "../../domain/useCases/sale/SaleUseCase";
import { registryCanceledItem } from "../../../services/orderService";
import { PagesEnum } from "../../constants/pagesEnum";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_COUPON = "ADD_COUPON";
export const REMOVE_COUPON = "REMOVE_COUPON";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const ADD_DISCOUNTS_DATA_RESHOP = "ADD_DISCOUNTS_DATA_RESHOP";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_TABLE = "CLEAR_TABLE";
export const DUPLICATE_PRODUCT = "DUPLICATE_PRODUCT";

const Sale = new SaleUseCase();

const addProductToCart = (product, state) => {
  Sale.addItem(product);
  const cart = [...state.cart, product];

  return { ...state, cart, Sale };
};

const duplicateProductInCart = (product, state) => {
  Sale.duplicateProduct(product);
  return { ...state, Sale };
};

const removeProductFromCart = (action, state) => {
  Sale.removeItem(action.product);

  const cart = [...state.cart];

  const operator = JSON.parse(
    sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME)
  );
  const manager = action.manager ? action.manager : operator;
  const checkItensRemoveCart = action.product;

  registryCanceledItem({
    managerName: manager.login,
    managerReferenceId: manager.employeeCode,
    sellerName: operator.login,
    sellerReferenceId: operator.employeeCode,
    productName: checkItensRemoveCart.ClassSaleStep.getSteps().description,
    productPrice: checkItensRemoveCart.ClassSaleStep.getSteps().value,
    productReferenceId:
      checkItensRemoveCart.ClassSaleStep.getSteps().productCode,
    quantity: checkItensRemoveCart.quantity,
    subTotal:
      checkItensRemoveCart.quantity *
      checkItensRemoveCart.ClassSaleStep.getSteps().value,
  });

  return { ...state, cart, Sale };
};

export const addDiscountsDataReshop = (data, state) => {
  console.log("adding discounts data", data);

  const discountsReshop = data;

  sessionStorage.setItem(RESHOP_DISCOUNTS, JSON.stringify(data));

  return { ...state, discountsReshop };
};

const addCouponToCart = (coupon, state) => {
  console.log("adding coupon", coupon);

  const coupons = [...state.coupons];

  const couponIndex = coupons.findIndex(
    (currentCoupon) => currentCoupon.value === coupon.value
  );

  if (couponIndex < 0) {
    coupons.push(coupon);
  } else {
    coupons[couponIndex] = coupon;
  }

  sessionStorage.setItem(CART_COUPONS_KEY_NAME, JSON.stringify(coupons));

  return { ...state, coupons };
};

export const removeCouponInCart = (couponName, state) => {
  console.log("removing coupon", couponName);

  const oldCoupons = [...state.coupons];

  const coupons = oldCoupons.filter(
    (currentCoupon) => currentCoupon.value !== couponName
  );

  sessionStorage.setItem(CART_COUPONS_KEY_NAME, JSON.stringify(coupons));

  return { ...state, coupons };
};

const clearCart = (action, state) => {
  const saleItems = Sale.getItems();

  Sale.emptyItems();

  const cart = [];
  sessionStorage.removeItem(RESHOP_TOTAL_DISCOUNT_KEY_NAME);

  const operator = JSON.parse(sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME));
  const manager = action.manager ? action.manager : operator;

  const pathname = action?.pathname ?? window.location.hash;
  const currentPathname = pathname.startsWith("#")
    ? pathname.substring(1)
    : pathname;

  if (!currentPathname.startsWith(PagesEnum.PAYMENT_SUCCESSFUL)) {
    saleItems.forEach((item) => {
      registryCanceledItem({
        managerName: manager.login,
        managerReferenceId: manager.employeeCode,
        sellerName: operator.login,
        sellerReferenceId: operator.employeeCode,
        productName: item.ClassSaleStep.getSteps().description,
        productPrice: item.ClassSaleStep.getSteps().value,
        productReferenceId: item.ClassSaleStep.getSteps().productCode,
        quantity: item.quantity,
        subTotal: item.quantity * item.ClassSaleStep.getSteps().value,
      });
    });
  }

  return { ...state, cart, Sale };
};

const clearCartTable = (state) => {
  Sale.emptyItems();

  return { ...state, Sale };
};

export const cartReducer = (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return addProductToCart(action.product, state);
    case REMOVE_PRODUCT:
      return removeProductFromCart(
        { product: action.product, manager: action.manager },
        state
      );
    case ADD_DISCOUNTS_DATA_RESHOP:
      return addDiscountsDataReshop(action.discountsData, state);
    case ADD_COUPON:
      return addCouponToCart(action.coupon, state);
    case REMOVE_COUPON:
      return removeCouponInCart(action.couponName, state);
    case CLEAR_CART:
      return clearCart(
        { pathname: action.pathname, manager: action.manager },
        state
      );
    case CLEAR_TABLE:
      return clearCartTable(state);
    case DUPLICATE_PRODUCT:
      return duplicateProductInCart(action.product, state);
    default:
      return state;
  }
};
