import React, { useEffect, useState, useRef } from "react";
import { CreateGlobal, DivWallets } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { sendSaleOrderService } from "../../../../services/sendSaleOrderService";
import { processesOperationService } from "../../../../services/processesOperationService";

import { useCart } from "../../../hook/cartHook";
import { PagesEnum } from "../../../constants/pagesEnum";
import { transactionData } from "../../../../aaone/shared/utils/transactionNumber";

import { LoadingDots } from "../../../components/LoadingDots";
import { Container } from "../../paymentMethods/styles";
import { printerActionService } from "../../../../services/actions/printerActionService";
import {
  getPaymentsSerializedToOrder,
  getTotalAlreadyPaid,
  saveLocalPayment,
} from "../../../helpers/multiPaymentsHelper";
import { HeaderPaymentMethod } from "../../../components/Header/paymentMethod";
import { useDialog } from "../../../hook/dialogHook";
import QRCode from "qrcode.react";
import BoxWallet from "../../../../aaone/shared/components/box-wallet";
import {
  createPaymentService,
  cancelPaymentService,
  checkPayment,
  createPaymentLinxPayService,
} from "../../../../services/linxPayServices";
import { LINXPAY_POSID } from "../../../constants/keyNamesSessionStorage";
import { printQRCode } from "../../../helpers/printer/printQrcode";

let timeoutGoToPaymentSuccess;
let timeoutFallback;
let isDisableSetText = false;
let timeoutSuccessful;

export const PaymentQrLinxPage = ({}) => {
  const {
    reshopTotalPrice,
    nsuValue,
    totalPrice,
    totalPriceWDiscount,
    itemsInCart,
    Sale,
    addPaymentSuccess,
    manualDiscount,
  } = useCart();
  const [qrCodeData, setQrCodeData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useDialog();
  const [timer, setTimer] = useState("00:00:00");
  const Ref = useRef(null);
  const [textStatus, setTextStatus] = useState(null);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [successDataQrLinx, setSuccessDataQrLinx] = useState(null);
  const [isDisableBackButton, setIsDisableBackButton] = useState(false);
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  const [successfulPrint, setSuccessfulPrint] = useState(false);

  const { PAYMENT_METHOD = "QRLINX" } = location.state || {};

  const navigateToPaymentsMethod = () => {
    navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
  };

  const goToPaymentSuccessful = () => {
    const isFinish = !!sessionStorage.getItem("IS_FINISH_CARD");

    if (isFinish) {
      sessionStorage.removeItem("IS_FINISH_CARD");
    }

    navigate(
      isFinish ? PagesEnum.PAYMENT_SUCCESSFUL : PagesEnum.PAYMENT_METHODS,
      {
        replace: true,
      }
    );
  };

  const { totalPriceToPayNow, totalChangeMoney } = location.state || {};
  const totalPriceToPayNowFloat = Number(totalPriceToPayNow)
    .toFixed(2)
    .replace(",", "")
    .replace(".", ",");

  const sendPayment = (dataCheck, data) => {
    console.log(`sendPayment [QRLINX]`);

    const paymentTransition = PAYMENT_METHOD;

    const payment = {
      dateTimeInsert: dataCheck.data.date_time,
      dtefCode: 0,
      sitefCode: 0,
      transactionType: paymentTransition,
      description: paymentTransition,
      value: Number(dataCheck.data.value),
      controlCode: dataCheck.data.payment_id,
      authorizationCodeOnline: dataCheck.data.order_id,
      paymentTransition: paymentTransition,
      // paymentCode: 0,
      qrLinxTransactionId: dataCheck.data.transaction_id,
      qrLinxTradingName:
        dataCheck.data.client_payer_payments[0].account.bank.name,
      transactionDatetime: dataCheck.data.date_time,
    };
    saveLocalPayment(paymentTransition, {
      amountPaid: Number(dataCheck.data.value),
      changeMoney: totalChangeMoney,
      ...payment,
    });

    addPaymentSuccess();

    if (window.Android) {
      console.log(
        "Prices and Items: " +
          JSON.stringify({
            payment_method: paymentTransition,
            totalAlreadyPaid: getTotalAlreadyPaid(),
            totalPrice,
            totalManualDiscount: manualDiscount,
            reshopTotalPrice,
            totalPriceWDiscount,
            itemsInCart,
          })
      );
    }

    if (getTotalAlreadyPaid() >= totalPriceWDiscount) {
      processesOperationService({
        cartTotalPrice: totalPrice,
        cartTotalPriceWithoutQuantity: totalPrice,
        itemsCart: itemsInCart,
        totalInCart: itemsInCart.length,
      }).then(async () => {
        const payments = getPaymentsSerializedToOrder();

        setTextStatus("Transação em andamento. Por favor, aguarde.");

        sendSaleOrderService({
          payments,
          options: {
            hasTef: true,
            paymentTransition,
          },
          prices: {
            totalPrice,
            totalPriceWDiscount,
            reshopTotalPrice: reshopTotalPrice ? reshopTotalPrice : 0,
            totalManualDiscount: manualDiscount,
          },
          items: itemsInCart,
          Sale,
        })
          .then((orderResult) => {
            sessionStorage.setItem("IS_FINISH_CARD", "ok");
            setTextStatus("Aguarde, transmitindo sua via do qr linx");
            setSuccessfulPayment(true);
            if (orderResult?.payload?.orderSale) {
              const {
                errorWhenAuthorizing,
                pathDocFiscal,
                saleControl,
                danfeUrl = null,
                danfeBase64 = null,
              } = orderResult?.payload?.orderSale || {};

              printerActionService({
                errorWhenAuthorizing,
                pathDocFiscal,
                saleControl,
                danfeUrl: danfeUrl ? danfeUrl : null,
                danfeBase64: danfeBase64 ? danfeBase64 : null,
              });
            }
          })
          .catch(() => {
            setTextStatus("Ocorreu um erro para ao transmitir nota...");

            setTimeout(() => {
              navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
            }, 2000);
          });
      });
    } else {
      setSuccessfulPayment(true);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 123);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const handleGoToPayments = () => {
    setTextStatus("Aguarde, voltando para os métodos de pagamentos...");
    showAlert({
      title: "Atenção",
      onConfirmText: "Cancelar Cobrança",
      message: (
        <>
          O Pagamento via QRCode será cancelado! Caso o valor tenha sido
          debitado da conta, o mesmo será estornado em até 48h <br />
          Tem Certeza que deseja cancelar a cobrança ?
        </>
      ),
      handleConfirm: () => {
        isDisableSetText = true;
        setIsLoadingInvoice(true);
        setIsDisableBackButton(true);
        cancelPaymentService({
          amount: location.state.totalPriceToPayNow,
          payment_id: qrCodeData.payment_id,
        });

        if (timeoutFallback) {
          clearTimeout(timeoutFallback);
        }
        timeoutFallback = setTimeout(() => {
          navigateToPaymentsMethod();
        }, 6000);
        setQrCodeData({});
      },
      handleCancel: () => {
        clearTimer(getDeadTime());
      },
    });
  };

  useEffect(() => {
    if (timer == "00:00:01") {
      showAlert({
        title: "Atenção",
        onConfirmText: "Cancelar Cobrança",
        message: (
          <>
            O Pagamento via QRCode será cancelado! Caso o valor tenha sido
            debitado da conta, o mesmo será estornado em até 48h <br />
            Tem Certeza que deseja cancelar a cobrança ?
          </>
        ),
        handleConfirm: () => {
          handleGoToPayments();
          setQrCodeData({});
        },
        handleCancel: () => {
          clearTimer(getDeadTime());
        },
      });
    }
  }, [timer]);

  useEffect(() => {
    isDisableSetText = false;

    if (timeoutGoToPaymentSuccess) {
      clearTimeout(timeoutGoToPaymentSuccess);
    }

    if (timeoutFallback) {
      clearTimeout(timeoutFallback);
    }
  }, [location]);

  useEffect(() => {
    if (timeoutSuccessful) {
      clearTimeout(timeoutSuccessful);
    }

    if (timeoutGoToPaymentSuccess) {
      clearTimeout(timeoutGoToPaymentSuccess);
    }

    timeoutSuccessful = setTimeout(() => {
      if (successfulPayment) {
        if (successfulPrint) {
          if (timeoutGoToPaymentSuccess) {
            clearTimeout(timeoutGoToPaymentSuccess);
          }

          goToPaymentSuccessful();
        }

        timeoutGoToPaymentSuccess = setTimeout(() => {
          goToPaymentSuccessful();
        }, 15000);
      }
    }, 2000);

    return () => {
      if (timeoutSuccessful) {
        clearTimeout(timeoutSuccessful);
      }

      if (timeoutGoToPaymentSuccess) {
        clearTimeout(timeoutGoToPaymentSuccess);
      }
    };
  }, [location, successfulPayment, successfulPrint]);

  useEffect(() => {
    if (sessionStorage.getItem(LINXPAY_POSID)) {
      createPaymentLinxPayService(
        { amount: location.state.totalPriceToPayNow },
        () => {
          navigateToPaymentsMethod();
          showAlert("Ocorreu um erro ao criar um Pagamento através do QRLinx");
        }
      ).then(({ data }) => {
        setQrCodeData(data.data);

        if (data?.data?.qrcode) {
          printQRCode(data?.data?.qrcode);
        }

        checkPayment(
          {
            payment_id: data.data.payment_id,
            amount: location.state.totalPriceToPayNow,
          },
          (data) => {
            console.log("useEffect on QRLINX =>", { data });

            setIsLoadingInvoice(true);
            setIsDisableBackButton(true);
            sendPayment(data);

            setSuccessDataQrLinx(true);
          }
        );
      });
    } else {
      setTextStatus(
        "QrLinx não foi ativado com sucesso, aguarde ou verifique suas configurações"
      );
      if (timeoutFallback) {
        clearTimeout(timeoutFallback);
      }

      timeoutFallback = setTimeout(() => {
        navigateToPaymentsMethod();
      }, 3000);
    }
    console.log(qrCodeData.payment_id);
    return;
  }, []);

  return (
    <Container>
      <div className="content__methods">
        <HeaderPaymentMethod
          onClickBackButton={handleGoToPayments}
          disabledBackButton={isDisableBackButton}
        >
          Pagamento
        </HeaderPaymentMethod>
        <CreateGlobal />
        <div className="container payment__with__card">
          {
            <>
              {successDataQrLinx && qrCodeData && (
                <>
                  <div className="icon__payment__container">
                    {<LoadingDots />}
                  </div>
                </>
              )}
              {!qrCodeData.qrcode && (
                <>
                  <div className="icon__payment__container">
                    {<LoadingDots />}
                  </div>
                </>
              )}
              {qrCodeData.qrcode && !successDataQrLinx && (
                <div>
                  <h2 className="TimerCouldownPdv">{timer}</h2>
                  <QRCode
                    value={qrCodeData.qrcode ?? ""}
                    size={300}
                    className="qrcode"
                  />
                  <DivWallets>
                    {qrCodeData.wallets &&
                      qrCodeData.wallets.map((item) => {
                        return (
                          item.logo_link && (
                            <BoxWallet
                              className="qrWallets"
                              key={item.cnpj}
                              image={item.logo_link}
                            />
                          )
                        );
                      })}
                  </DivWallets>
                </div>
              )}
            </>
          }
          <div className="text">
            {!textStatus
              ? qrCodeData.qrcode
                ? "Aguarde Pagamento..."
                : "Validando..."
              : textStatus}
          </div>
        </div>
      </div>
    </Container>
  );
};
