import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  flex: 1;

  .image {
    width: 150px;
  }

  .text {
    margin: 1rem auto;
    text-align: center;
    color: #000;

    line-height: 2.75rem;
    font-size: 2rem;
    font-weight: 700;

    max-width: 570px;
    width: 100%;

    .subtext {
      color: #000;
      font-size: 1.25rem;
      text-align: start;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .action-buttons {
    button {
      margin: 0 8px;
    }
  }

  &.isSmartPos {
    .image {
      width: 75px;
    }

    .text {
      margin: 1rem auto;
      text-align: center;
      color: #000;

      line-height: 2.1rem;
      font-size: 1.5rem;
      font-weight: 600;

      max-width: 320px;
      width: 100%;

      .subtext {
        color: #000;
        font-size: 1rem;
        text-align: start;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: row;
      button {
        margin: 0 3px;
      }
    }
  }
`;
