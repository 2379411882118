import styled, { css } from "styled-components";
import { default as ModalComboWrapper } from "../modal-combo/style";
import { default as ProductModalWrapper } from "../modal-product/style";
import { default as GenericModalWrapper } from "../modal/style";

const displayBanner = () => {
  return localStorage.getItem("styleAA") === "SHOP" ? "none" : "";
}

export const ScrollContainerWrapper = styled.div`
  ${(props) =>
    props.$driverLayout &&
    css`
      width: 100%;
      overflow: hidden;

      ${GenericModalWrapper} {
        transform: translate(-50%, -50%);
      }

      ${ProductModalWrapper} {
        transform: translate(-50%, -50%);
      }

      ${ModalComboWrapper} {
        transform: translate(-50%, -50%);
      }

      * {
        transition: top 0.35s ease-out, bottom 0.35s ease-out, left 0.35s ease-out, right 0.35s ease-out;
      }

      .content-wrapper.full.car2 {
        .title {
          margin-top: 0px;
        }
        .App.keyboard {
          margin-top: 350px;
          .habibs {
            margin-top: 147px;
          }
        }
      }

      .content-wrapper.full {
        height: 80vh;
        
        .footer {
          bottom: 18%;
        }
        .summary {
          margin-top: 0.25em;
          max-height: 36vh;
          min-height: 36vh;
        }
        .box-points {
          padding: 160px 50px;
          margin: 52px auto;
        }
        .keyboard {
          input {
            &:first-of-type {
              height: 7.563rem;
              padding: 0.5em;
              margin-top: 0px;
            }
          }
        }
        .lnr-eye {
          margin-top: 34px;
        }
        .lnr-eyeclose {
          margin-top: 34px;
        }
        .App.keyboard {
          margin: 7.25em auto;
          .habibs {
            margin-top: 147px;
          }
        }
      }

      .content-wrapper {
        .title {
          margin: 0.6em 0 0.2em;
        }
        .payment-page {
          height: 68% !important;
          overflow-y: scroll !important;
        }
        .payament-list {
          display: grid;
          grid-template-columns: 380px 380px;
          /* Is property [grid-gap] has obsolete. */
          grid-gap: 3em;
          padding: 0 125px;
          margin: 1.25em 0;
        }
        
        .payament-list .card {
          box-sizing: border-box;
          max-width: 300px;
          height: 280px;
          padding: 36px 0;
          margin: 0;
        }
      
        .payament-list .card-title {
          font-size: 1.5625rem;
          margin-top: 50px;
          margin-top: 45px;
        }

        &.full.background-fidelity {
          .footer {
            bottom: 20%;
          }
        }

        &.background-fidelity {
          img {
            min-height: 280px;
            max-height: 300px;
            width: auto;
            margin-top: -30px;
          }
        }
      }

      .keyboard.fidelidade {
        margin: 0 auto;
        input {
          &:first-of-type {
            height: 7.563rem;
          }
        }
        span {
          &:first-of-type {
            margin: 0.2em 0;
          }
        }
        p {
          &:first-of-type {
            margin: 0rem 0 1rem;
          }
        }
        .numericKeypad {
          margin-bottom: 0;
        }
      }
      #order-page {
        height: 71vh;
        margin-bottom: 9vh;
        .highlight-add-item {
          bottom: 29vh;
        }
        .invalid-coupon-modal {
          height: 80vh !important;
        }
        #page-content {
          .keyboard.resgate {
            ${props => props.$reshopUser && css`
              margin: 0 auto 192px;`
            }
            height: 80vh;

            .numericKeypad {
              padding: 1.25em 2em;
              grid-gap: 0.75em;
            }
            button {
              padding: 0.4em;
            }
            input {
              &:first-of-type {
                height: 7.563rem;
                padding: 1rem 2rem;
              }
            }
            span {
              &:first-of-type {
                display: none;
              }
            }
            p {
              &:first-of-type {
                margin: 0rem 0 1rem;
              }
            }

            .divInput input{
              margin-top: 0px;
            }
          }
          .sticky {
            min-height: 207px;
          }
          .numericKeypadAlfa {
            padding: 180px 0px 0px 30px !important;
            height: 370px;
          }
          .inputAlfaNumeric {
            margin-top: 1px !important;
          }
          .box-campaign {
            bottom: 15% !important;
          } 
          .backbutton-insertcupom-container {
            margin-top: -70px;
          }
          .content-campaign {
            max-height: 200px;
          }
          .resgate-cupom {
            min-height: 55vh;
            margin-bottom: 2em;

            button {
              bottom: 7%;
            }
          }
          .backbutton-insertcupom-container {
            bottom: 2%;
          }
          height: 68vh;
          .blackout {
            width: 100%;
            position: absolute;
            left: 0;
            .counter {
              .accelerators {
                button {
                  margin: 0.5em 0.4em;
                }
              }
              .button-count {
                margin: 50px 10px;
              }
            }
          }
          .step-actions {
            bottom: 2vh !important;
          }
          .groupSales {
            .step-title {
              margin-bottom: 1em;
              margin-left: 15px;
            }
            .combo-individual-featured-image {
              img {
                width: auto;
                height: 330px;
              }
            }
            .combo-individual-products {
              max-height: 700px;

              .flavor-select-list {
                padding: 1em 1.5em 1em;
                li {
                  padding: 0.4em;
                }
              }
            }
          }

          .box-campaign{
            z-index: 2;
            display: flex;
            flex-direction: column;
            width: 94%;
            position: absolute;
            bottom: 13%;
            overflow: auto;
          }

          .text-campaign{
            color: var(--color-text-category);
            text-align: center;
            text-transform: uppercase;
            font-size: 30px;
            margin: 0.35em 0;
          }

        }
        .listCategory {
          .listCategory-item {
            padding: 0.418em 0;

            &:last-child {
              margin-bottom: 2rem;
            }
          }
        }
      }

      #cart {
        .blackout {
          height: 80%;

          & > * {
            transform: translate(-50%, -50%);
          }
        }
      }

      .waiting-modal {
        height: 80vh !important;
        bottom: ${props.$pinnedViewAtBottom ? 0 : "20%"};
      }

      // When on bottom
      ${props.$pinnedViewAtBottom &&
      css`
        #order-page {
          .highlight-add-item {
            bottom: 9vh !important;
          }
        }
        .content-wrapper.full {
          .footer {
            bottom: 0%;
          }
        }
        .content-wrapper.full.background-fidelity {
          .footer {
            bottom: 0%;
          }
        }

        #cart {
          .cart-details {
            bottom: 0%;
          }

          .blackout {
            top: 20%;
            bottom: 0;

            & > * {
              transform: translate(-50%, -50%);
            }
          }
        }
      `}

      // When on top
    ${!props.$pinnedViewAtBottom &&
      css`
        #cart {
          .cart-summary {
            bottom: 28.35%;

            .cart-summary-items {
            }
          }

          .cart-summary.cart-summary--active {
            .cart-summary-items {
            }
          }

          .cart-details {
            bottom: 20%;
          }
        }
      `}
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  bottom: 0;
  height: 19.6vh;
  max-height: 19.6vh;
  transition: max-height 0.35s ease-out;
  z-index: 3;
  border-color: var(--color-fidelity);
  border-style: solid;
  border-width: 0;
  display:${displayBanner};

  button {
    border-color: var(--color-fidelity);
    border-width: 4px;
    padding: 0.5rem 4rem;
    background-color: var(--color-fidelity);
    color: var(--color-background);
    font-size: 1.8rem;
    border-radius: 1rem;
  }

  ${(props) =>
    props.$position === "top" &&
    css`
      align-items: flex-end;
      border-bottom-width: 8px;

      button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -8px;
      }
    `}

  ${(props) =>
    props.$position === "bottom" &&
    css`
      align-items: flex-start;
      border-top-width: 8px;

      button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: -8px;
      }
    `}

  ${(props) =>
    props.$hidden &&
    css`
      padding: 0;
      max-height: 0;
      border-top: none;
      border-bottom: none;

      button {
        margin: 0;
      }
    `}
`;
