import styled from "styled-components";
export const ContentImg = styled.div`
  width: 100%;
  text-align: center;
  height: 600px;
  img {
    width: 600px;
  }
`;

export const ShowPrice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  p {
    width: 38%;
    text-align: center;
    font-size: 1.8rem;
    &.pay {
      width: 100%;
      font-size: 2.7rem;
      font-weight: bold;
    }
  }
`;

export const ContentQrCode = styled.div`
  height: 70%;
  display: flex;
  align-items: center;
  div {
    width: 100%;
  }
`;

export const TitleStatusPayment = styled.p`
  margin-top: 200px;
  font-size: 4rem;
  font-style: italic;
  color: var(--color-title-secondary);
`;
