import React from "react";

import { useWrapperRouters } from "../../hook/wrapperRoutersHook";

export const SettingsPage = () => {
  const { togglePositionSidebarCart } = useWrapperRouters();

  return (
    <>
      <button onClick={togglePositionSidebarCart}>Esquerda/direita carrinho</button>
    </>
  );
};
