import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import { TefActionsButtons } from "./cell-renderers";
import {
  CancelOrderListWrapper,
  Filters,
  InputFilters,
  TableContainer,
} from "./style";
import { Button } from "../Button";
import { Card } from "../Card";
import { DatePicker } from "../date-picker";
import { FormControl } from "../form-control";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useLoading } from "../../hook/loadingHook";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { getFinishedTefOrders } from "../../../services/orderService";
import { getRetailerId, isSmartPos } from "../../../aaone/configuration";
import { currencyMask } from "../../helpers/masks";
import ModalPrintCancelTef from "../modals/ModalPrintCancelTef";
import { format } from "date-fns";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import classNames from "classnames";
import { BoxItems } from "../BoxItems";
import { useDialog } from "../../hook/dialogHook";
import { cancelPayment } from "../../helpers/paymentHelper";

const MonitorTefList = (props) => {
  const [columnsAgGrid, setColumnsAgGrid] = useState([]);
  const [listItens, setListItens] = useState([]);
  const now = new Date();
  const [filters, setFilters] = useState({
    initialDate: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    ),
    finalDate: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ),
  });
  const { setVisibleSidebarCart } = useWrapperRouters();
  const navigate = useNavigate();
  const { setShowLoading } = useLoading();
  const { showAlert } = useDialog();
  const [showModalPrintCalcelTef, setShowModalPrintCalcelTef] = useState(false);
  const [tefOrderSelected, setTefOrderSelected] = useState({});
  const [typeAction, setTypeAction] = useState("");

  const handleClose = () => {
    navigate(PagesEnum.HOME);
    setVisibleSidebarCart();
  };

  const handleActionsTef = (data, action) => {
    setTypeAction(action);
    setShowModalPrintCalcelTef(true);
    setTefOrderSelected(data);
  };

  const handleCloseModalPrintCancelTef = () => {
    setTypeAction("");
    setTefOrderSelected({});
    setShowModalPrintCalcelTef(false);
  };

  const handleConfirmModalPrintCancelTef = (action) => {
    if (action === "cancel") {
      if (window.desktopApp) {
        setShowLoading(
          true,
          window.desktopApp &&
            "Cancelando pagamento, siga as instruções no PinPad."
        );
      }

      cancelPayment(
        tefOrderSelected,
        () => {
          showAlert({
            message: "Pagamento cancelado com sucesso.",
          });

          setTimeout(() => {
            setShowLoading(false);
            handleGetTef(filters.initialDate, filters.finalDate);
          }, 300);
        },
        (payload) => {
          if (payload.error) {
            showAlert({
              message: payload.error,
            });
          } else {
            showAlert({
              message: "Ocorreu um erro ao cancelar o pagamento.",
            });
          }

          setTimeout(() => {
            setShowLoading(false);
            handleGetTef(filters.initialDate, filters.finalDate);
          }, 300);
        }
      );
    } else {
      if (window.Android) {
        try {
          window.Android.rePrinterTransaction(
            formatDateHelper(new Date(), "yyyyMMdd")
          );
        } catch (error) {
          console.log("ERROR ON RePrintTefCoupon: " + JSON.stringify(error));
        }
      } else {
        try {
          setShowLoading(
            true,
            window.desktopApp && "Aguarde, reimprimindo cupom."
          );

          window.desktopApp.subscribe(
            "machine.devices.tef.reprintcoupon.completed",
            () => {
              setShowLoading(false);

              window.desktopApp.unsubscribe(
                "machine.devices.tef.reprintcoupon.completed"
              );
            }
          );

          window.desktopApp.publish(
            "machine.devices.tef.reprintcoupon",
            tefOrderSelected
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    setVisibleSidebarCart(false);
  }, [setVisibleSidebarCart]);

  useEffect(() => {
    const init = () => {
      const columns = [
        {
          title: "Cupom",
          propName: "couponNumber",
          showFilter: true,
          typeFilter: "agTextColumnFilter",
        },
        {
          title: "NSU",
          propName: "controlCode",
          showFilter: true,
          typeFilter: "agTextColumnFilter",
        },
        {
          title: "PDV",
          propName: "posId",
          // showFilter: true,
          // typeFilter: "agTextColumnFilter",
        },
        {
          title: "Operador",
          propName: "seller",
          showFilter: true,
          typeFilter: "agTextColumnFilter",
        },
        {
          title: "Consumidor",
          propName: "consumer",
          showFilter: true,
          typeFilter: "agTextColumnFilter",
        },
        {
          title: "Total",
          propName: "total",
          showFilter: true,
          typeFilter: "agTextColumnFilter",
        },
        {
          title: "Data",
          propName: "dateDoc",
          showFilter: true,
          typeFilter: "agTextColumnFilter",
        },
        {
          title: "Status",
          propName: "canceled",
          showFilter: true,
          typeFilter: "agSetColumnFilter",
        },
        { title: "Ações", propName: "actions", showfilter: false },
      ];
      setColumnsAgGrid(columns);

      if (window.desktopApp) {
        window.desktopApp.subscribe(
          "machine.devices.tef.reprintcoupon.completed",
          handleSucessTefActions
        );

        // window.desktopApp.subscribe('machine.devices.coupon.cancellation', handleSucessTefActions);
        window.desktopApp.subscribe(
          "machine.devices.printer.completed",
          handleSucessTefActions
        );
      }
    };
    init();

    return () => {
      if (window.desktopApp) {
        window.desktopApp.unsubscribe(
          "machine.devices.tef.reprintcoupon.completed"
        );
        // window.desktopApp.unsubscribe('machine.devices.coupon.cancellation');
        window.desktopApp.unsubscribe("machine.devices.printer.completed");
      }
    };
  }, []);

  function getListOrder(payload) {
    let listOrder = [];

    let list = payload.filter((cl) =>
      cl.payments.some((r) => r.controlCode != null)
    );

    for (var item in list) {
      list[item].payments.forEach((element) => {
        if (element.controlCode !== null) {
          listOrder.push({
            couponNumber: list[item].receiptNumber,
            consumer: list[item].documentNumber
              ? list[item].documentNumber
              : "Não informado",
            posId: list[item].posId,
            seller: list[item].currentSellerName ?? " ---- ",
            totalDouble: element.amount,
            total: currencyMask(element.amount),
            idOrder: list[item].id,
            idPayment: element.id,
            fiscalDocument: list[item].fiscalDocument,
            dataTransaction: list[item].createdAt,
            dateDoc: format(
              new Date(list[item].createdAt),
              "dd/MM/yyyy HH:mm:ss"
            ),
            controlCode: element.controlCode,
            itens: [
              {
                name:
                  "NSU: " + element.controlCode + " - " + element.brand.name,
                price: currencyMask(element.amount),
                quantity: 1,
                subTotal: currencyMask(element.amount),
              },
            ],
            payments: [element],
            canceled: element.canceled ? "Cancelado" : "Ativo",
          });
        }
      });
    }

    setListItens(listOrder);
  }

  async function handleGetTef(startDate, endDate) {
    try {
      if (startDate && endDate) {
        setShowLoading();
        const params = {
          retailerId: getRetailerId(),
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        };
        await getFinishedTefOrders(params).then((result) => {
          getListOrder(result);

          setShowLoading(false);
        });
      }
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  }

  function handleSucessTefActions() {
    handleCloseModalPrintCancelTef();
    handleGetTef(filters.initialDate, filters.finalDate);
  }

  return (
    <Card
      title="Reimpressão e Cancelamento TEF"
      handleClose={handleClose}
      fullHeight
      closeEnable
    >
      <CancelOrderListWrapper>
        <Filters className={classNames({ isSmartPos })}>
          <InputFilters className={classNames({ isSmartPos })}>
            <FormControl
              required
              label="Data Inicial"
              component={DatePicker}
              object={filters}
              field="initialDate"
              selected={(value) =>
                setFilters({ ...filters, initialDate: value })
              }
            />

            <FormControl
              required
              label="Data Final"
              component={DatePicker}
              object={filters}
              field="finalDate"
              selected={(value) => setFilters({ ...filters, finalDate: value })}
            />
          </InputFilters>

          <Button
            className="button btn-primary"
            onClick={() => handleGetTef(filters.initialDate, filters.finalDate)}
          >
            <div>
              <i className="material-icons">search</i>
              Pesquisar
            </div>
          </Button>
        </Filters>
        {isSmartPos ? (
          <BoxItems
            items={listItens}
            onPrint={(listItens) => handleActionsTef(listItens, "print")}
            onCancel={(listItens) => handleActionsTef(listItens, "cancel")}
          />
        ) : (
          <TableContainer>
            <AgGridReact
              className="ag-theme-balham pdv"
              rowData={listItens}
              localeText={agGridLocalePtBr}
              getRowNodeId={(data) => data.id}
              rowSelection="multiple"
              rowHeight={50}
              headerHeight={40}
              suppressContextMenu
              suppressRowClickSelection
              rowBuffer={0}
              frameworkComponents={{
                TefActionsButtons,
              }}
            >
              {columnsAgGrid.map((column) => {
                const cellStyle = {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                  lineHeight: "15px",
                  wordBreak: "break-word",
                };

                const additionalProps = {
                  suppressMenu: true,

                  ...(column.propName === "couponNumber" && {
                    flex: 0.6,
                    wrapText: true,
                  }),

                  ...(column.propName === "controlCode" && {
                    flex: 0.8,
                  }),

                  ...(column.propName === "posId" && {
                    flex: 0.3,
                  }),

                  ...(column.propName === "seller" && {
                    flex: 0.8,
                    wrapText: true,
                  }),

                  ...(column.propName === "consumer" && {
                    flex: 0.7,
                  }),

                  ...(column.propName === "total" && {
                    flex: 0.7,
                    cellStyle: {
                      ...cellStyle,
                      fontWeight: "bold",
                    },
                  }),

                  ...(column.propName === "dateDoc" && {
                    width: 140,
                    propName: "",
                  }),

                  ...(column.propName === "canceled" && {
                    flex: 0.7,
                    width: 20,
                    propName: "",
                  }),

                  ...(column.propName === "actions" && {
                    width: 160,
                    propName: "",
                    cellRenderer: "TefActionsButtons",
                    cellRendererParams: {
                      onClickPrinter: (data) => handleActionsTef(data, "print"),
                      onClickCancel: (data) => handleActionsTef(data, "cancel"),
                    },
                  }),
                };

                return (
                  <AgGridColumn
                    key={column.propName}
                    cellStyle={cellStyle}
                    field={column.propName}
                    {...additionalProps}
                    headerName={column.title}
                    floatingFilter={column.showFilter}
                    filter={column.typeFilter}
                  />
                );
              })}
            </AgGridReact>
          </TableContainer>
        )}
      </CancelOrderListWrapper>
      <ModalPrintCancelTef
        typeAction={typeAction}
        showModal={showModalPrintCalcelTef}
        tefOrder={tefOrderSelected}
        handleClose={handleCloseModalPrintCancelTef}
        handleConfirm={handleConfirmModalPrintCancelTef}
      />
    </Card>
  );
};

export default MonitorTefList;
