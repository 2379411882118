import React, { useState, useEffect, useCallback } from "react";
import { showFullValue } from "../../../configuration";
import { currency } from "../../utils/currency";

import {
  FlavorSelectListWrapper,
  SpanPrice,
  DivSpace,
  ContentButtons,
} from "./style";

const StepCompositionList = ({
  steps,
  listProducts,
  listProductsSelected,
  onAddItem,
  handleRemoveItem,
}) => {
  const [items, setItems] = useState([]);

  const handleAddComposition = (product) => {
    const newProduct = { ...product };
    const seq = steps.length + 1;

    const quantity = product.selectedAmount < 0 ? 0 : product.selectedAmount;

    newProduct.vit_numlancto = seq;
    newProduct.vit_numlanctoprincipal = seq;
    newProduct.quantity = quantity;
    newProduct.qtd = quantity;
    newProduct.isProductComposition = {
      type: "ADDITIONAL",
    };

    if (product.operationType === "R") {
      newProduct.qtd = -1;
      newProduct.value = 0;
      newProduct.isProductComposition = {
        type: "WITHDRAWN",
      };
    }

    onAddItem(newProduct, undefined, true);
  };

  const handleReorderItems = (items) => {
    return [
      ...items.filter((item) => item.operationType === "R"),
      ...items.filter((item) => item.operationType === "A"),
    ];
  };

  useEffect(() => {
    const listProductsReorded = handleReorderItems(listProducts);
    const itemsSerialized = listProductsReorded.map((product) => {
      const foundModifiedProduct = listProductsSelected.find(
        (productModified) => productModified.productCode === product.productCode
      );

      let quantitySelected =
        product.operationType === "R" ? 1 : product?.qtd ?? 0;

      if (foundModifiedProduct) {
        let quantity = 0;
        listProductsSelected.forEach((item) => {
          if (item.productCode === product.productCode) {
            quantity = item?.qtd ?? 0;
          }
        }, 0);

        quantitySelected = quantity;
      }

      let newQuantity = foundModifiedProduct?.qtd ?? quantitySelected;

      if (newQuantity < 0) {
        newQuantity = 0;
      }

      return {
        ...product,
        qtd: newQuantity,
        quantity: newQuantity,
        selectedAmount: newQuantity,
      };
    });

    setItems(itemsSerialized);
  }, [listProducts]);

  const serializeAnItem = (item, quantity) => {
    const objQuantity = {
      quantity,
      qtd: quantity,
      selectedAmount: quantity,
    };

    const findListProducts = listProducts.findIndex(
      (currentListProduct) =>
        currentListProduct.productCode === item.productCode
    );

    if (findListProducts > -1) {
      listProducts[findListProducts] = {
        ...listProducts[findListProducts],
        ...objQuantity,
      };
    }

    return {
      ...item,
      ...objQuantity,
    };
  };

  const addAnItem = useCallback(
    (index) => {
      const newItems = [...items].map((currentItem, indexItem) => {
        if (index !== indexItem) {
          return currentItem;
        }

        const quantity = currentItem.qtd + 1;

        return serializeAnItem(currentItem, quantity);
      });

      setItems(newItems);

      if (newItems[index].operationType === "A")
        handleAddComposition(newItems[index]);
      else handleRemoveItem(newItems[index]);
    },
    [items]
  );

  const removeAnItem = useCallback(
    (index) => {
      if (items[index].qtd === 0) return;

      const newItems = [...items].map((currentItem, indexItem) => {
        if (index !== indexItem) {
          return currentItem;
        }

        const quantity = currentItem.qtd - 1;

        return serializeAnItem(currentItem, quantity);
      });

      setItems(newItems);

      if (newItems[index].operationType === "A")
        handleRemoveItem(newItems[index]);
      else handleAddComposition(newItems[index]);
    },
    [items]
  );

  return (
    <>
      <FlavorSelectListWrapper className="background-flavor-select-list flavor-select-list">
        {items.map((item, index) => {
          if (item.vit_numlanctoprincipal) return false;

          const handleDisableSum = (item) => {
            if (item.operationType === "A") return true;
            else if (item.operationType === "R" && item.qtd === 0) return true;
            else return false;
          };

          return (
            <li key={`${item.id}-${index}`}>
              {item.image !== null ? (
                <img
                  className="flavor-select-list-image"
                  src={item.image}
                  alt={item.name}
                />
              ) : (
                <div className="no-image-combo" />
              )}
              <div className="flavor-select-list-item">
                <p className="flavor-select-list-item-name">
                  {item.description}{" "}
                  {item.operationType === "A" && (
                    <SpanPrice>{`(+${currency(item.value)})`}</SpanPrice>
                  )}
                  {showFullValue && +item["mixValue"] > 0
                    ? currency(item["mixValue"])
                    : ""}
                </p>
              </div>
              <ContentButtons className="flavor-select-list-action">
                <button type="button" onClick={() => removeAnItem(index)}>
                  -
                </button>
                <span className="quantity">{item.qtd}</span>
                {handleDisableSum(item) && (
                  <button type="button" onClick={() => addAnItem(index)}>
                    +
                  </button>
                )}
                {!handleDisableSum(item) && <DivSpace>&nbsp;</DivSpace>}
              </ContentButtons>
            </li>
          );
        })}
      </FlavorSelectListWrapper>
    </>
  );
};

export default React.memo(StepCompositionList);
