import { css } from "styled-components";

import font from "../../assets/fonts/Cocogoose-Bold.ttf";
import noImageCoco from "../../assets/cocobambu/no-image.png";
import noImage from "../../assets/no-image.png";
import prismaRex from "../../assets/prisma-rex.png";

export const cocoBambuTheme = css`
  @font-face {
    font-family: "Cocogoose";
    src: local("Cocogoose"), url(${font}) format("truetype");
    font-weight: normal;
  }

  :root {
    --no-image: url(${noImageCoco});
    --font-family-main: "Cocogoose";

    --color-background-light: #fff;

    --color-background: #f3f1f1;
    --color-background-modal: #fff;
    --color-background-primary: #20422f;
    --color-background-cart: #20422f;
    --color-progress: #20422f;

    --color-box-shadow-primary: #f5f5f5;
    --color-box-shadow-secondary: var(--color-background);
    --color-background-progress-bar: #fff;

    --color-title-category: #bec4c1;
    --color-title-secondary: #e4f3ea;
    --color-subtitle: #1b3527;

    --color-background-card-primary: #1b3527;
    --color-background-card-secondary: #1b3527;

    --color-background-keyboard: rgba(168, 2, 14, 0.3);
    --color-keyboard-background: #ffffff;
    --color-keyboard-input: #3c3c3b;

    --color-text-category: #000;
    --color-title-progress: #000;
    --color-title-modal: #000;
    --color-text-flavor-list: #707070;

    --color-fidelity: #1b3527;
    --color-background-keyboard: #3c3c3b;
    --color-additem: #20422f;

    --color--background-banner: #1b3527;
    --color-button-secondary: #20422f;

    --color-trash: #20422f;
    --color-button-resgate: #ffede1;

    --color-button-banner: #fff;

    --color-title-points: #ffd2b4;
    --color-box-points: #ffd2b4;
  }

  :root {
    touch-action: pan-x pan-y;
    height: 100%;
  }

  body {
    font-family: var(--font-family-main);
    background-color: var(--color-background);
  }

  .background-primary {
    background-color: var(--color-background-primary);
  }

  .background-fidelity {
    background-color: var(--color-fidelity);
  }

  .background-cart {
    background-color: var(--color-background-cart);
  }

  .background-summary {
    color: var(--color-text-flavor-list);
    background-color: var(--color-background);
  }

  /* HELPS */

  .no-image {
    height: 70px;
    width: 110px;
    background: url(${noImage});
    background-size: contain;
    background-repeat: no-repeat;
  }
  /* END HELPS */

  /* BUTTONS */
  .wrapper-button {
    margin: 66px 0 80px;
    padding: 0 70px;
  }

  button {
    font-size: 2.0rem;
    font-family: var(--font-family-main);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    border: 0;
    padding: 1em 0.8em;
    border-radius: 999px;
  }

  .button-backstep {
    display: inline-block;
    color: var(--color-title-category);
    text-align: center;
    width: 100%;
    border-radius: 20px;
    position: relative;
    background-color: var(--color-background-light);
  }

  .button-primary {
    color: var(--color-title-category);
    background-color: #1b3527;
  }

  .button-secondary {
    color: var(--color-background-light);
    background-color: var(--color-fidelity);
  }

  .button-outline-primary {
    outline: none;
    color: var(--color-title-secondary);
    border: 2px solid var(--color-title-secondary);
    background-color: transparent;
  }

  .button-outline-backstep {
    color: var(--color-title-category);
    border: 2px solid var(--color-title-secondary);
    background-color: transparent;
  }

  .button--highlight {
    padding: 1.6em 0;
  }

  .button--jump {
    padding: 0.8em 0;
  }

  .button--clear {
    outline: none;
    border-radius: 20px;
    color: var(--color-background-progress-bar);
    font-size: 1.25rem;
    width: 138px;
    margin-right: 40px;
    border: 0.1em solid var(--color-background-progress-bar);
    background-color: transparent;
  }

  .button-close {
    text-transform: none;
    border: none;
    padding: 0.3em 0.8em;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    width: 810px;
    height: 207px;
  }

  .finalizar {
    outline: none;
    width: 400px;
  }

  .button-withdrawn {
    width: 478px !important;
    padding: 1em 0.5em;
  }

  .button--block {
    width: 80%;
  }

  .redeem-coupon-action {
    border-radius: 63px;
    background-color: var(--color-title-secondary);
    display: flex;
    width: 90%;
    margin: 20px auto 0;
    height: 70px;
  }

  .resgateCupom {
    background: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    color: var(--color-background-primary);
  }

  .resgate {
    margin-left: 20px;
    font-size: 30px;
  }

  button[disabled] {
    outline: none;
    opacity: 0.6;
  }

  .background-cart button :not(.button-clear) {
    font-size: 2.188rem;
  }

  .warn {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    color: var(--color-background-light);
    font-size: 2rem;
    text-align: left;
    margin: 0.3em 0;
    padding: 0.5em;
    border-radius: 1.25rem;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .box {
    display: grid;
    margin: 6.25em 0;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 5em;
  }

  .box.withdraw {
    margin-top: 15em;
  }

  /* END BUTTONS */

  /* NAV, CONTENT */

  .listCategory-item {
    box-sizing: border-box;
    color: var(--color-text-category);
    font-size: 1.2rem;
    text-transform: uppercase;
    width: 100%;
    padding: 1.18em 0;
    margin: 0.1em 0;
    position: relative;
  }

  .listCategory-item--active {
    font-weight: 700;
    background-color: var(--color-background);
  }

  .listCategory-item img {
    height: 110px;
    width: 140px;
  }

  .listCategory-item-name {
    max-width: 100%;
    margin: 0 auto;
  }

  .ui-border {
    content: "";
    height: 113%;
    width: 30px;
    position: absolute;
    top: -11px;
    right: 0;
    border-radius: 10px 0 0 10px;
  }

  .category-title,
  .category-subTitle,
  .groupSales-item {
    font-family: var(--font-family-main);
    text-align: center;
  }

  .groupSales-item-selected {
    border: 2px solid var(--color-background-cart);
    border-radius: 20px;
  }

  .category-title {
    color: var(--color-title-category);
    font-size: 3.8rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;
  }

  .category-subTitle {
    color: var(--color-subtitle);
    font-size: 2rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  .groupSales-item {
    color: var(--color-text-category);
    /*text-transform: uppercase;*/
    font-size: 23px;
    font-weight: 400;
    margin: 0;
    margin-top: 6px;
  }

  .groupSales-item img,
  .groupSales-item .no-image {
    width: 270px;
    height: 180px;
    padding: 2em 1em;
    border-radius: 1.5em;
    box-shadow: -6px -6px 8px var(--color-background-light),
      6px 6px 20px #d8d2d2;
    background: var(--color-box-shadow-primary);
    background-repeat: no-repeat;
  }

  .groupSales-item .no-image {
    line-height: 190px;
    width: 270px;
    height: 180px;
    margin-left: 25px;
    background: url(${noImage}) no-repeat 50%;
    background-size: contain;
  }

  .groupSales-item figcaption {
    width: 80%;
    margin: 1em auto 5px;
  }

  .groupSales-price {
    color: var(--color-title-category);
    font-weight: 700;
    margin: 0;
  }

  .badge-content {
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .badge {
    height: 42px;
    width: 42px;
    display: flex;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 1.5rem;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    align-content: center;
    border-radius: 20px;
    flex-direction: row;
    justify-content: center;
  }

  .badge-invisible {
    transform: scale(0) translate(50%, -50%);
  }

  .badge-top-right {
    top: 0;
    right: 0;
    transform-origin: 100% 0%;
  }

  .badge-color-primary {
    background-color: var(--color-background-cart);
    color: var(--color-background-light);
  }

  /* END NAV, CONTENT */

  /* ORDER SUMMARY */
  .content-wrapper {
    box-sizing: border-box;
    color: var(--color-background-progress-bar);
    text-align: center;
    padding: 4em 3em 0;
  }

  .content-wrapper.full {
    overflow-y: auto;
    height: 100vh;
  }

  .content-wrapper .title {
    font-size: 3.75rem;
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
    margin: 3.617em 0 0.2em;
    height: 60px;
  }

  .content-wrapper .subtitle {
    color: var(--color-title-secondary);
    font-size: 2rem;
    font-weight: 400;
    margin: 0 0 1em;
  }

  /* WITHDRAWN */
  .withdraw-images img {
    display: inline-block;
    height: 280px;
    width: 320px;
  }

  /* END WITHDRAWN */

  /* NumericKeypad */
  .keyboard {
    width: 80%;
    margin: 80px auto;

    .divInput {
      position: relative;

      .lnr-eyeclose {
        transform: translateY(-50%);
        position: absolute;
        right: 3rem;
        bottom: 25%;
      }
      .lnr-eye {
        transform: translateY(-50%);
        position: absolute;
        right: 3rem;
        bottom: 25%;
    }
    }
  }

  .keyboard input {
    box-sizing: border-box;
    font-size: 65px;
    text-align: center;
    height: 9.563rem;
    width: 100%;
    padding: 1em;
    margin: 1rem 0;
    border-radius: 1.25rem;
    background-color: var(--color-background-progress-bar);
    color: var(--color-text-category);
  }

  .key-1 {
    grid-area: key-1;
  }

  .key-2 {
    grid-area: key-2;
  }

  .key-3 {
    grid-area: key-3;
  }

  .key-4 {
    grid-area: key-4;
  }

  .key-5 {
    grid-area: key-5;
  }

  .key-6 {
    grid-area: key-6;
  }

  .key-7 {
    grid-area: key-7;
  }

  .key-8 {
    grid-area: key-8;
  }

  .key-9 {
    grid-area: key-9;
  }

  .key-0 {
    grid-area: key-0;
  }

  .key-limpar {
    grid-area: key-limpar;
  }

  .key-c {
    grid-area: key-c;
  }

  .key-add {
    grid-area: key-add;
  }

  .numericKeypad {
    display: grid;
    grid-template-areas:
      "key-1 key-2 key-3"
      "key-4 key-5 key-6"
      "key-7 key-8 key-9"
      "key-limpar key-0 key-c"
      "key-add key-add key-add";
    grid-template-columns: repeat(3, 1fr);
    width: auto;
    margin: 0 auto 5em;
    grid-gap: 1em;
    padding: 2em;
    border-radius: 20px;
    background-color: var(--color-keyboard-input);
  }

  .numericKeypad button,
  .keyboard-login button {
    outline: none;
    font-size: 45px;
    color: var(--color-background-keyboard);
    padding: 0.5em;
    border: 3px solid var(--color-keyboard-input);
    background-color: var(--color-keyboard-background);
    border-radius: 20px;
  }

  .numericKeypad .key-limpar {
    font-size: 30px;
  }

  .numericKeypad .key-add {
    color: var(--color-title-category);
    background-color: var(--color-title-secondary);
    border: 3px solid var(--color-title-secondary);
  }

  .keyboard-login {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5em;
    width: 65%;
    padding: 1em;
    margin: 60px auto;
    border-radius: 20px;
    background-color: var(--color-keyboard-input);
  }

  .keyboard-login .key {
    outline: none;
    width: 100%;
  }

  .keyboard-login .key[value="limpar"] {
    font-size: 2rem;
  }

  /* End NumericKeypad */

  /* LOGIN */
  .login {
    display: flex;
    height: 75%;
    flex-direction: column;
    justify-content: center;
    font-size: 3rem;
  }

  .login label {
    text-align: left;
    margin-bottom: 0.2em;
  }

  .login select,
  .login input {
    box-sizing: border-box;
    font-size: 3rem;
    height: 9.563rem;
    width: 100%;
    padding: 0.5em 1em;
    border-radius: 20px;
  }

  .login select {
    margin-bottom: 5rem;
  }

  .login button {
    outline: none;
    width: 50%;
    margin: 0 auto;

    &.button-primary {
      padding: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0rem;
      height: auto;
    }
  }
  /* END LOGIN */

  /* PLUGIN simple-keyboard */
  .simple-keyboard {
    color: #000;
    font-size: 2rem;
  }

  .App.keyboard {
    width: 80%;
    margin: 15.25em auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .App.keyboard input {
    font-size: 2.9rem;
  }

  .keyboard p + input {
    margin-top: 0;
  }

  p.label {
    color: var(--color-text-category);
    font-size: 33px;
    text-align: center;
  }

  .App.keyboard .hg-theme-default .hg-button {
    height: 90px;
    font-size: 40px;
    background-color: var(--color-background);
    color: var(--color-background-primary);
    min-width: 70px !important;
    border: 10px solid var(--color-keyboard-input);
    border-right: 0px solid #006d64;
  }
  /* END PLUGIN */

  /* PAYAMENT */
  .payament-list {
    display: grid;
    grid-template-columns: 380px 380px;
    /* Is property [grid-gap] has obsolete. */
    grid-gap: 3em;
    padding: 0 70px;
    margin: 6.25em 0;
  }

  .payament-list .card {
    box-sizing: border-box;
    max-width: 380px;
    height: 360px;
    padding: 50px 0;
    margin: 0;
  }

  .payament-list .card-img {
    width: initial;
  }

  .payament-list .card-title {
    font-size: 1.5625rem;
    margin-top: 30px;
  }
  /* END PAYAMENT */

  /* CUPOM */
  .cupom-template * {
    margin: 0;
  }

  .cupom-template {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    color: var(--color-title-secondary);
    text-align: center;
    height: 100vh;
    padding: 10em 2em 2em;
    background-color: var(--color-background-primary);
  }

  .cupom-template .name {
    font-size: 5.875rem;
    text-transform: uppercase;
    margin-top: 5em;
  }

  .cupom-template .points {
    color: var(--color-keyboard-input2);
    background-color: var(--color-button-secondary);
    font-size: 33px;
    text-transform: uppercase;
    min-width: 774px;
    border: 2px solid var(--color-button-secondary);
    padding: 35px;
    margin: 20px 0;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .cupom-template .points strong {
    color: var(--color-title-secondary);
  }

  .cupom-template .message {
    text-transform: uppercase;
  }

  .cupom-template .message {
    font-size: 3.75rem;
  }

  .cupom-template .warn-cupom {
    color: var(--color-background-progress-bar);
    font-size: 2.188rem;
    margin-top: 1.857em;
    margin-bottom: 1.3em;
  }

  .cupom-template .code {
    font-size: 16.25rem;
    color: var(--color-title-category);
    width: 774px;
    border-radius: 0.154em;
    background-color: var(--color-background-progress-bar);
  }

  .cupom-template .promotion {
    height: 30vh;
    width: 100vw;
    border-radius: 2.5em 2.5em 0 0;
    position: fixed;
    bottom: -90px;
    background-color: var(--color-background-light);
  }

  .promotion img {
    position: relative;
    top: 0;
  }
  /* END CUPOM */

  /* CAROUSEL */
  .carousel {
    height: 100vh;
  }

  .carousel div {
    height: inherit;
  }

  .carousel.fade button {
    outline: none;
    font-size: 43px;
    width: 822px;
    position: fixed;
    bottom: 8em;
    transform: translate(-50%, 50%);
    left: 50%;
  }

  .fade {
    animation: fade 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  /* END CAROUSEL */

  .habibs {
    margin-top: 184px;
    padding: 2em 1em !important;
    border-radius: 20px !important;
    background-color: var(--color-keyboard-input) !important;
  }

  .App.keyboard .hg-theme-default .hg-row .hg-button-container,
  .App.keyboard .hg-theme-default .hg-row .hg-button:not(:last-child) {
    margin-right: 0;
  }

  .App.keyboard .hg-theme-default .hg-row:nth-child(2) {
    padding: 0 5.9%;
  }

  .App.keyboard .hg-theme-default .hg-row:nth-child(3) {
    padding: 0 9%;
  }

  .App.keyboard .hg-theme-default .hg-row:nth-child(4) .hg-button {
    width: 85%;
  }

  /* loader */
  #wrapper-loader {
    text-align: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 100%;
    height: 250px;
  }

  #wrapper-loader p {
    font-size: 2rem;
  }

  #loader {
    margin: 0 auto;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Modal balckout */
  .blackout {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .filters {
    box-sizing: border-box;
    color: var(--color-title-category);
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    width: 90%;
    margin: 1em auto 0;
    padding: 1em 1em 2em;
    border-radius: 20px;
    background-color: var(--color-background-progress-bar);
  }

  .filters-tags {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .filters .tag {
    display: inline-block;
    color: var(--color-title-category);
    font-size: 25px;
    font-weight: 700;
    border: 2px solid var(--color-title-category);
    border-radius: 999px;
    padding: 1em 1em;
  }

  .filters .selected {
    background: var(--color-title-category);
    display: inline-block;
    color: var(--color-background-light);
    font-size: 25px;
    font-weight: 700;
    border: 2px solid var(--color-title-secondary) 80;
    border-radius: 20px;
    padding: 1em 1em;
  }

  .arrow-up,
  .arrow-down {
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-title-category);
    margin: 0 0.5em;
  }

  .arrow-left {
    font-size: 3.5rem;
    position: absolute;
    top: 15px;
    left: 20px;
    color: var(--color-title-category);
  }

  .highlight-add-item {
    color: var(--color-additem);
    text-transform: uppercase;
    line-height: 60px;
    width: 100%;
    border-radius: 20px 20px 20px 0;
    position: fixed;
    bottom: 175px;
    left: 0;
    z-index: 12;
    background: var(--color-title-secondary);
  }

  .highlight-add-item:after {
    content: "";
    height: 40px;
    width: 25px;
    position: absolute;
    bottom: -40px;
    border-radius: 20px 0 0 0;
    left: -5px;
  }

  .added-item {
    font-size: 23px;
    padding-right: 200px;
    padding-left: 60px;
  }

  .added-item span {
    color: inherit;
    font-size: 17px;
    padding: 0.1em 1.5em;
    border-radius: 50px;
    position: absolute;
    right: 55px;
    background: var(--color-background-light);
  }

  .sticky {
    z-index: 2;
    min-height: 237px;
    text-align: center;
    background-color: var(--color-background);
    flex-shrink: 0;
  }

  /* Flavor Select List */

  .flavor-select-list {
    padding: 2em 1.5em 2em;
    margin-bottom: 0;
    border-radius: 1.25em;
    /* max-height: 20vh;
  min-height: 20vh; */
    overflow-y: auto;
  }

  .flavor-select-list li {
    border-bottom: 2px solid #f3f1f1;
  }

  .flavor-select-list-image {
    height: 70px;
    width: 110px;
    font-weight: 100;
    border-radius: 20px;
  }

  .flavor-select-list-action button {
    color: var(--color-background-light);
    padding: 0.1em 0.7em;
    background-color: var(--color-background-cart);
    border-radius: 20px;
  }

  .flavor-select-list-action .quantity {
    display: inline-block;
    font-size: 2.375rem;
    font-weight: 700;
    min-width: 60px;
    padding: 0 0.3em;
    text-align: center;
  }

  .flavor-select-list-item {
    font-size: 1.25rem;
    text-align: left;
    text-transform: uppercase;
    color: var(--color-text-flavor-list);
  }

  .flavor-select-list-item-name {
    margin: 0.3em;
  }

  .background-flavor-select-list {
    color: #707070;
    background-color: var(--color-background-light);
  }

  .flavor-select-list-info {
    padding: 1em;
    display: grid;
    grid-template-columns: 7fr 1fr auto;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flavor-select-list-info > strong {
    color: var(--color-text-flavor-list);
  }

  .flavor-select-list-info .proceed {
    min-width: max-content;
    color: var(--color-fidelity);
  }

  .flavor-select-list-info .no-proceed {
    min-width: max-content;
    color: var(--color-title-category);
  }

  .flavor-select-list-info .required {
    margin-left: 0.8em;
    background: var(--color-title-category);
    color: var(--color-background-light);
    text-transform: uppercase;
    padding: 0.2em 0.7em;
    border-radius: 0.4em;
    font-size: 1.2em;
  }

  .flavor-select-list-info .no-required {
    margin-left: 0.8em;
    background: var(--color-fidelity);
    color: var(--color-background-light);
    text-transform: uppercase;
    padding: 0.2em 0.7em;
    border-radius: 0.4em;
  }

  .cancelTEF button {
    position: relative;
    top: -120px;
  }

  .hg-button.hg-functionBtn.hg-button-bksp span {
    font-size: 1px;
  }

  .hg-button.hg-functionBtn.hg-button-bksp span:after {
    content: "←";
    font-size: 55px;
  }

  .prismaImage {
    background: url(${prismaRex}) 50% 50% no-repeat;
    margin: 35px auto;
  }

  .ragazo-mais {
    margin-top: -250px;
  }

  .title-points {
    color: var(--color-background-light);
    font-size: 75px;
    font-weight: 700;
    text-transform: none;
    word-wrap: break-word;
    margin: 0;
  }

  .box-points {
    text-align: center;
    width: 75%;
    border: 3px solid var(--color-box-points);
    padding: 150px 50px;
    margin: 240px auto;
    border-radius: 30px;
  }

  .text-point,
  .points-value {
    margin: 12px 0;
  }

  .text-point {
    font-size: 50px;
  }

  .points-value {
    color: var(--color-title-points);
    font-size: 110px;
  }

  .serverError {
    width: 100%;
    padding: 0.8em;
    position: fixed;
    top: 0;
    right: 0;
    animation-name: messageserver;
    animation-duration: 1s;
    background-color: #f8d7da;
  }

  .serverError h1,
  .serverError h2 {
    color: #721c24;
    text-transform: uppercase;
    margin: 0;
    border-color: #f5c6cb;
  }

  .btn-serverError {
    color: #fff;
    border-color: #dc3545;
    margin: 0.5em 0;
    background-color: #dc3545;
  }

  @keyframes messageserver {
    from {
      top: -100px;
    }
    to {
      top: 0;
    }
  }

  .fidelityPoints {
    font-size: 60px;
    font-weight: 700;
    position: absolute;
    right: 40px;
  }

  .fidelityPoints::after {
    content: " pontos";
    font-size: 20px;
    font-weight: 400;
    position: relative;
    top: -10px;
  }

  .category-title-fidelityPoints {
    color: var(--color--background-banner);
    text-align: left;
    padding-left: 40px;
  }

  .resgate-cupom {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
    position: relative;
  }

  .resgate-cupom img {
    width: 100%;
    height: auto;
  }

  .resgate-cupom button {
    outline: none;
    font-weight: bold;
    text-transform: uppercase;
    width: 90%;
    bottom: 18%;
    position: absolute;
    background-color: var(--color--background-banner);
    color: var(--color-button-banner);
  }

  .hide {
    display: none;
  }

  .tag-fidelity {
    display: inline-block;
    color: var(--color-background-modal);
    text-transform: uppercase;
    border-radius: 20px;
    padding: 0.5em 1em;
    background-color: var(--color-button-secondary);
  }

  .keyboard.resgate {
    height: 100vh;
    margin: 50px auto 220px;
  }

  .keyboard.resgate .numericKeypad {
    background-color: var(--color-background-light);
    border: 2px solid var(--color-title-secondary);
  }

  .keyboard.resgate .numericKeypad button {
    outline: none;
    background-color: var(--color-button-resgate);
    border: 3px solid var(--color-title-secondary);
    color: var(--color-background-primary);
  }

  .keyboard.resgate .key-add {
    color: var(--color-title-category) !important;
    background-color: var(--color-title-secondary) !important;
    border-radius: 999px;
  }

  .keyboard.resgate .input {
    color: var(--color-title-category);
    border: 2px solid var(--color-title-secondary);
  }

  .keyboard.fidelidade .key-add {
    /*background-color: var(--color-subtitle);*/
    border: 3px solid var(--color-background-keyboard);
    color: var(--color-title-category);
  }

  .keyboard.fidelidade .numericKeypad {
    background-color: var(--color-background-keyboard);
  }

  .keyboard.fidelidade .numericKeypad button {
    outline: none;
    border: 3px solid var(--color-background-light);
  }

  .keyboard.fidelidade .key-add {
    border: 3px solid var(--color-title-secondary) !important;
  }
  .modal.automatic-reset button {
    text-transform: uppercase;
    margin: 1em 1em !important;
    width: 85%;
  }

  .hasIMG img {
    min-height: 380px;
    width: 700px;
    margin-bottom: -15px;
  }

  .image-position {
    min-height: 380px;
    width: 70%;
    margin-bottom: -15px;
  }

  .modal_blackout {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 20;
    background: rgba(0, 0, 0, 0.82);
  }

  .modal_resgateCupom {
    box-sizing: border-box;
    text-align: center;
    text-transform: uppercase;
    width: 80%;
    height: 50vh;
    padding: 54px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-background-modal);
  }

  .modal_resgateCupom__title {
    color: var(--color-title-category);
    font-family: inherit;
    font-size: 60px;
  }

  .modal_resgateCupom__product {
    color: var(--color-text-category);
    font-size: 25px;
  }

  .modal_resgateCupom__value {
    color: var(--color-title-category);
    font-size: 25px;
  }

  .modal_resgateCupom .buttons {
    width: inherit;
    margin: 0 auto;
  }

  .modal_resgateCupom .buttons button {
    color: var(--color-title-category);
    width: 45%;
    margin: 0px 10px;
  }

  .fidconfirm .leitora-confirm {
    width: 360px;
    height: 230px;
    margin-top: 90px;
  }

  .fidconfirm .titulo {
    color: var(--color-title-secondary);
    font-size: 60px;
    margin: 100px auto;
    text-align: center;
    max-width: 600px;
  }

  .fidconfirm .box {
    width: 800px;
    height: 190px;
    background-color: var(--color-background-progress-bar);
    border: 4px solid var(--color-background-card-primary);
    border-radius: 20px;
    margin: 0 auto;
    color: var(--color-background-card-primary);
    box-sizing: border-box;
    padding: 45px 80px;
    font-size: 30px;
    margin-top: 190px;
  }

  .box strong {
    font-size: 45px;
  }

  .box p {
    margin: -6px;
  }

  .keyboard p {
    color: var(--color-button-banner);
  }

  .keyboard.resgate p {
    color: var(--color-text-category);
  }

  .backbutton-insertcupom-container {
    position: absolute;
    z-index: 2;
    bottom: 6%;
    width: 94%;
    display: flex;
    justify-content: center;
  }

  .backbutton-insertcupom-container button {
    color: var(--color-title-category);
  }

  .keyboard.prisma .numericKeypad {
    margin-top: -20px;
  }

  .prisma .title {
    margin-top: 1em;
    text-transform: none;
  }

  .prisma img {
    margin-top: -40px;
    margin-bottom: -160px;
    width: 240px;
  }

  .footer .buttonAddCar.buttonAddCarRagazzo {
    border: 2px solid var(--color-title-secondary);
    color: var(--color-title-secondary);
    background-color: transparent;
  }

  footer.buttonFidelityRecuse.footer {
    bottom: 0;
  }
`;
