import styled from "styled-components";

export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px;

  input {
    font-size: 1.5rem;
    width: 90px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #411e5a;
  }

  .btn-quantity {
    width: 60px;
    height: 60px;
    border: 2px solid #411e5a;
    border-radius: 5px;
    color: #411e5a;
  }

  &.isSmartPos .btn-quantity {
    height: 44px;
  }
`;

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  label {
    margin-left: 2rem;
    margin-top: 0.5rem;
  }

  .button-content {
    display: flex;
    padding: 10px;
  }

  .button__submit {
    background-color: #411e5a;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    width: 100%;
    margin: 5px;
  }

  .button__cancel {
    background-color: #fff;
    border: 2px solid #2e0037;
    color: #2e0037;
  }

  .toggle__button {
    padding: 0 0.75rem;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    label {
      margin: 0;
      padding: 0;
    }
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;
    height: 65%;

    background-color: #fff;
    position: relative;

    border-radius: 0.5rem;
    margin: 0 auto;

    .detail-items {
      height: 34.5rem;
      overflow-y: scroll;
    }

    .header__content {
      padding: 10px;
    }

    .fixed__content {
      position: sticky;
      bottom: 0;
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid rgba(206, 206, 206, 0.47);

      .title {
        color: #2e0037;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0 1rem;
      }

      .button__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        cursor: pointer;
      }

      strong {
        font-size: 2rem;
        color: #2e0037;
      }

      .table-status {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .header {
      &.status {
        border: 0;
        font-size: 1.5rem;
      }
    }

    .content__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 0.5rem;

      .button__text {
        margin-left: 0.5rem;
      }

      .button {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        padding: 0.5rem 0.5rem;
        width: 100%;

        text-transform: uppercase;
        color: #fff;

        cursor: pointer;

        & + .button {
          margin-left: 1rem;
        }

        &.button__white {
          background-color: #fff;
          border: 2px solid #2e0037;
          color: #2e0037;
          font-weight: 600;
          border-radius: 5px;
        }
      }
    }

    .info__text__container {
      margin-left: 0.5rem;

      > svg {
        position: absolute;
        top: 0.5rem;
      }

      input {
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #cecece;
      }

      .title-client {
        color: #2e0037;
        font-size: 1.25rem;
        font-weight: 700;
      }

      .identification-client-container {
        overflow: hidden;

        .identification-client {
          border: 1px solid #cecece;
          height: 350px;
          width: 100%;
          padding: 15px 50px 15px 20px;
          border-radius: 5px;
          margin-top: 10px;
          overflow: auto;

          ::-webkit-scrollbar {
            width: 5px;
          }

          ::-webkit-scrollbar-thumb {
            background-color: rgba(46, 0, 55, 0.4);
            border-radius: 20px;
            border: 0;
          }

          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          .client-position-name {
            display: grid;
            grid-template-columns: 20px 200px;
            grid-column-gap: 10px;
            grid-row-gap: 300px;

            .number {
              display: flex;
              font-size: 1.8rem;
              font-weight: 700;
              color: #2e0037;
            }
          }
        }
      }

      .keyboard-numeric {
        .button {
          font-size: 1.5rem;
          border-radius: 0.5rem;
        }

        .button-send-item {
          background: #09843a;
          color: #fff;
        }
      }

      .info__products {
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 5px;
        margin-top: 10px;
        resize: none;
        width: 100%;
      }

      .text-row {
        display: flex;
        justify-content: space-between;
        padding: 3px;
      }

      .info__text {
        display: flex;
        flex-direction: column;
        border: 2px dashed #bdbdbd;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        resize: none;
        width: 100%;

        &,
        &::placeholder {
          color: #000;
        }
      }
    }

    .content__container_quantity {
      display: flex;
      flex-direction: row;
      padding: 0 1.5rem;
      justify-content: space-between;

      .quantity__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 302px;

        .input__wrapper {
          margin: 5px;
        }
      }

      .keyboard-numeric {
        min-width: 302px;
        width: 100%;
      }
    }

    &.isSmartPos {
      padding-bottom: 1rem;
      height: auto;

      .content__container_quantity {
        flex-direction: column;

        .identification-client {
          height: 150px;
          width: 100%;

          padding: 1rem;

          .client-position-name {
            grid-template-columns: 20px 1fr;
          }
        }
      }
    }
  }
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #411e5a;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #411e5a;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
