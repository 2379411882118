import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import FISCAL_TYPES from "../../constants/fiscalTypes";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { FormControl } from "../form-control";
import { Button, Card, SelectBasic as Select } from "../modules";
import {
  ContainerTable,
  DirectionalArrow,
  DirectionalArrowsContainer,
  Filters,
  SalesReturnWrapper,
  AgGridActions,
} from "./style";

const columns = [
  {
    title: "Nº Nota",
    propName: "receiptNumber",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Série",
    propName: "receiptSeries",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Status",
    propName: "status",
    showFilter: true,
    typeFilter: "agSetColumnFilter",
  },
  {
    title: "Data Atendimento",
    propName: "dtAtendimento",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Data Autorização",
    propName: "dtAutorizacao",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Valor",
    propName: "total",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  { title: "Ações", propName: "viewDetails" },
];

const filters = {
  initialDate: null,
  finalDate: null,
  status: null,
  type: null,
};

const itemsStatusSale = [
  { id: 0, name: "Todos" },
  //{ id: 1, name: "Balcão" },
  //{ id: 2, name: "Delivery" },
  //{ id: 3, name: "Mesa" },
  //{ id: 4, name: "Cartão" },
];

const itemsTypes = [
  { id: 0, name: "Não Homologado" },
  { id: 1, name: "NFC-e" },
  { id: 2, name: "CF-e(SAT)" },
];

const ROW_HEIGHT = 55;

const SalesReturn = (props) => {
  const title = "Devolução de Venda";
  const { listItens, fiscalType, setOpenNoteDetails, handleCancel } = props;
  const { setVisibleSidebarCart } = useWrapperRouters();
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [shouldShowCancelButton, setShouldShowCancelButton] = useState(false);

  const [api, setApi] = useState({
    gridApi: null,
    gridColumnApi: null,
  });

  useEffect(() => {
    setVisibleSidebarCart(false);
  });

  useEffect(() => {
    props.handleClick({ ...filters, ...props.filters });
  }, []);

  useEffect(() => {
    let activeFilter = filters;

    if (activeFilter.status?.id === 1 || activeFilter.status?.id === 0) {
      setShouldShowCancelButton(true);
    } else {
      setShouldShowCancelButton(false);
    }
  });

  useEffect(() => {
    return () => {
      setSelectedItems([]);
      setShouldShowCancelButton(false);
      setApi({
        gridApi: null,
        gridColumnApi: null,
      });

      filters.initialDate = null;
      filters.finalDate = null;
    };
  }, []);

  const handleSearch = () => {
    setSelectedItems([]);
    props.handleClick(filters);

    props.setMonitorNotesFilters({
      initialDate: filters.initialDate,
      finalDate: filters.finalDate,
      status: filters.status,
      type: filters.type,
    });
  };

  const scrollGrid = (direction) => {
    if (!api.gridApi) {
      return;
    }

    const displayedRowCount = api.gridApi.getDisplayedRowCount();
    if (displayedRowCount === 0) {
      return;
    }

    let nodeIndex = 0;
    const lastRowIndex = displayedRowCount - 1;
    const displayedRows =
      Math.floor(
        api.gridApi?.["gridBodyCon"].eBodyViewport.clientHeight / ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = api.gridApi.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = api.gridApi.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    api.gridApi.ensureIndexVisible(nodeIndex);
  };

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  const setListStatus = (type = FISCAL_TYPES.NAO_HOMOLOGADO) => {
    if (type === FISCAL_TYPES.NFCE || type === FISCAL_TYPES.NAO_HOMOLOGADO)
      return itemsStatusSale;
  };

  const setInitialStatus = (type = FISCAL_TYPES.NAO_HOMOLOGADO) => {
    const list = setListStatus(type);

    if (props?.filters?.status) {
      return props.filters.status;
    }

    return type === 1 ? list[0] : list[0];
  };

  /**
   * Seleciona o tipo Fiscal
   */
  const setType = () => {
    return itemsTypes[fiscalType];
  };

  return (
    <Card
      title={title}
      handleClose={handleClose}
      closeEnable
      fullHeight
      className=""
      id="cardList"
    >
      <SalesReturnWrapper>
        <Filters>
          <FormControl
            required
            label="Tipos de Venda"
            component={Select}
            object={filters}
            field="status"
            flex="1"
            propToShow="name"
            disabled={window.desktopApp}
            options={setListStatus(fiscalType)}
            initialvalue={props.filters?.status || setInitialStatus(fiscalType)}
          />
          <div style={{ marginLeft: "10px" }}>
            <FormControl
              required
              label="Tipo Emissor Fiscal"
              component={Select}
              object={filters}
              field="type"
              flex="0.7"
              propToShow="name"
              options={itemsTypes}
              initialvalue={setType()}
              disabled={window.desktopApp}
            />
          </div>
          <Button className="btn-purple" onClick={handleSearch}>
            <i className="material-icons">search</i>
            Pesquisar
          </Button>
        </Filters>

        <ContainerTable id="containerTable">
          <DirectionalArrowsContainer>
            <DirectionalArrow className="top" onClick={() => scrollGrid("top")}>
              <span className="material-icons">keyboard_arrow_up</span>
            </DirectionalArrow>

            <DirectionalArrow
              className="bottom"
              onClick={() => scrollGrid("bottom")}
            >
              <span className="material-icons">keyboard_arrow_down</span>
            </DirectionalArrow>
          </DirectionalArrowsContainer>

          <div
            className="ag-theme-balham monitor"
            style={{ width: `calc(100% - 40px)` }}
          >
            <AgGridReact
              rowData={listItens}
              localeText={agGridLocalePtBr}
              getRowNodeId={(data) => data.id + data?.receiptNumber}
              rowSelection="multiple"
              rowHeight={ROW_HEIGHT}
              headerHeight={40}
              suppressContextMenu
              suppressRowClickSelection
              rowBuffer={0}
              onSelectionChanged={(params) => {
                setSelectedItems(params.api.getSelectedRows());
              }}
              frameworkComponents={{
                showDetailsRenderer: (props) => showDetailsRenderer(props, handleCancel),
              }}
              onGridReady={(params) => {
                const { api, columnApi } = params;

                setApi({
                  gridApi: api,
                  gridColumnApi: columnApi,
                });
              }}
            >
              {columns.map((column) => {
                const cellStyle = {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                  wordBreak: "break-word",
                };

                const additionalProps = {
                  suppressMenu: true,

                  ...(column.propName === "receiptNumber" && {
                    flex: 0.55,
                    wrapText: true,
                  }),

                  ...(column.propName === "receiptSeries" && {
                    flex: 0.55,
                  }),

                  ...(column.propName === "status" && {
                    flex: 0.97,
                    wrapText: true,
                    cellClass: (params) => [
                      "status",
                      String(params?.data?.status || "")
                        .toLowerCase()
                        .replace(/\s/g, "-"),
                    ],
                  }),

                  ...((column.propName === "dtAtendimento" ||
                    column.propName === "dtAutorizacao") && {
                    flex: 0.82,
                  }),

                  ...(column.propName === "total" && {
                    flex: 0.55,
                    cellStyle: {
                      ...cellStyle,
                      fontWeight: "bold",
                    },
                  }),

                  ...(column.propName === "viewDetails" && {
                    flex: 1.2,
                    cellStyle: {
                      "line-height": "400%",
                    },
                    cellRenderer: "showDetailsRenderer",
                    cellRendererParams: {
                      onClick: (data) => {
                        setOpenNoteDetails(data);
                      },
                    },
                  }),
                };

                return (
                  <AgGridColumn
                    key={column.propName}
                    cellStyle={cellStyle}
                    field={column.propName}
                    {...additionalProps}
                    headerName={column.title}
                    floatingFilter={column.showFilter}
                    filter={column.typeFilter}
                  />
                );
              })}
            </AgGridReact>
          </div>
        </ContainerTable>
      </SalesReturnWrapper>
    </Card>
  );
};

const showDetailsRenderer = (props, handleCancel) => (
  <AgGridActions>
    <button
      className="btn-card-list"
      onClick={() => props.onClick(props.data)}
    >
      <i className="material-icons">visibility</i>
      <span>Detalhes</span>
    </button>
    <button
      className="btn-card-list"
      onClick={() => handleCancel(props.data)}
    >
      <i className="material-icons">cancel</i>
      <span>Cancelar</span>
    </button>
  </AgGridActions>
);

export default SalesReturn;
