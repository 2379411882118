import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content {
    width: 100%;
    flex: 1;

    .form__container {
      max-width: 400px;
      width: 100%;

      margin: 0 auto;

      button,
      input {
        padding: 0.25rem;
        font-size: 1.5rem;
      }

      .inputs__group,
      .keyboard__group {
        display: flex;
        flex-direction: row;

        padding: 0 1rem;

        input {
          background-color: buttonface;
          font-size: 1rem;
          flex: 1;

          padding: 1rem;

          border-radius: 1rem;
          outline: none;
          border: 0;

          &::placeholder {
            color: #000;
          }

          margin: 0 1rem;
        }

        .button__clear {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0.5rem 1.25rem;
        }
      }

      .keyboard__group {
        .keyboard-numeric {
          flex: 1;

          button {
            padding: 0.5rem;
          }
        }

        .button__submit {
          background-color: ${({ theme }) => theme.colors.purple};
          color: #fff;
          border: 0;

          text-transform: uppercase;

          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;

          padding: 0.5rem;
          font-size: 1rem;

          border-radius: 1rem;
          padding: 0.5rem;
          margin: 1rem 0;

          &::disabled {
            background-color: ${({ theme }) => theme.colors.purpleDisabled};
          }
        }
      }
    }

    .troco__container {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      .troco__valor {
        font-size: 1.5rem;
      }
    }
  }

  &.isSmartPos {
    .content {
      .form__container {
        .input__container_value {
          padding: 0;

          input {
            margin: 0 0.5rem 0 0;
          }
        }

        .keyboard__group {
          flex-direction: column;
          padding: 0;

          .keyboard-numeric {
            margin-top: 1rem;
            padding: 0;
          }

          .button.button__submit {
            padding: 1rem;
          }
        }
      }
    }
  }
`;
