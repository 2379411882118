import React, { useCallback, useEffect } from "react";
import { MdArrowBack, MdLocalMall, MdNoteAlt, MdRestaurant } from "react-icons/md";

import { Container } from "./styles";
import { useCart } from "../../../hook/cartHook";
import { OBSERVATION_ORDER_KEY_NAME, PDV_ORDER_IS_TAKE_OUT } from "../../../constants/keyNamesSessionStorage";
import { KeyboardProvider } from "../../../contexts/KeyboardContext";

export const ModalToEat = ({ handleClose }) => {
  const { handleOpenModalCPFInInvoice } = useCart();

  /**
   * @param string V = VIAGEM, L= LOCAL
   */
  const handleSelectLocal = useCallback(
    (method) => {
      localStorage.setItem(PDV_ORDER_IS_TAKE_OUT, method);
      handleClose();
      handleOpenModalCPFInInvoice();
    },
    [handleOpenModalCPFInInvoice]
  );

  const onChangeValueNameClient = useCallback((e) => {
    localStorage.setItem(OBSERVATION_ORDER_KEY_NAME, String(e?.target?.value ?? ""));
  }, []);

  useEffect(() => {
    localStorage.removeItem(OBSERVATION_ORDER_KEY_NAME);
  }, []);

  return (
    <KeyboardProvider>
      <Container>
        <div className="content__wrapper">
          <header className="header">
            <div className="title">Comer aqui ou viagem?</div>
            <button onClick={handleClose} className="button__close">
              <MdArrowBack size="1rem" />
            </button>
          </header>
          <div className="content__container">
            <div
              role="button"
              onClick={() => handleSelectLocal("L")}
              className="button button__here"
            >
              <MdRestaurant size="1.5rem" />
              <div>Comer Aqui</div>
            </div>
            <div
              role="button"
              onClick={() => handleSelectLocal("V")}
              className="button button__toTrip"
            >
              <MdLocalMall size="1.5rem" />
              <div>Viagem</div>
            </div>
          </div>
          <div className="info__text__container">
            <h4>Informações Adicionais do Pedido</h4>
            <input
              className="info__text"
              name="info"
              id="info"
              onChange={onChangeValueNameClient}
              data-use-touch-keyboard
              data-x-pos="right"
              data-y-pos="top"
              placeholder="Ex: Nome do cliente"
            />
          </div>
        </div>
      </Container>
    </KeyboardProvider>
  );
};
