import React from "react";
import { LicenseManager } from "ag-grid-enterprise";

import { ThemeProvider } from "styled-components";
import { AuthProvider } from "./contexts/authContext";
import { CashDeskProvider } from "./contexts/cashdeskContext";
import theme from "./styles/theme";

import { VerticalLayoutProvider } from "./contexts/verticalContext";

import { AgGridKey } from "../aaone/configuration";
import { Routes } from "./routes";
import { DialogProvider } from "./contexts/dialogContext";
import { LoadingProvider } from "./contexts/loadingContext";
import { MigrationProvider } from "./contexts/migrationContext";

LicenseManager.setLicenseKey(AgGridKey);

window.sendingToFinishPayment = false;

export const PdvOnePage = () => {

  React.useEffect(() => {

    document.title = "PDV One";
  });

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <DialogProvider>
          <AuthProvider>
            <CashDeskProvider>
              <VerticalLayoutProvider>
                <MigrationProvider>
                  <Routes />
                </MigrationProvider>
              </VerticalLayoutProvider>
            </CashDeskProvider>
          </AuthProvider>
        </DialogProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
};
