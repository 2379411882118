import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../../helpers/mediaQueryHelper";

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .content {
    background-color: #fff;
    max-width: 850px;
    width: 100%;

    position: relative;

    border-radius: 0.5rem;
    margin: 0 auto;

    .button__close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 1;
    }

    .form-container {
      display: flex;
      flex-direction: row;

      .inputs-group {
        max-width: 400px;
        width: 100%;

        .input-container {
          input {
            background: transparent;

            border: 0;
            border-bottom: 1px solid #eee;

            font-size: 2rem;
            padding: 1rem;

            outline: none !important;

            width: 100%;
          }
        }
      }
    }

    .product__result {
      display: flex;
      flex-direction: column;

      padding: 3rem;
      flex: 1;
    }

    .empty__product {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      flex: 1;

      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
      .content .form-container {
        flex-direction: column;

        .inputs-group  {
          max-width: 100%;
        }
      }
  `)}
`;
