import React, {useEffect} from 'react';
import { useDispatch } from "react-redux";
import {useViewDispatch} from "../../../context";
import errorIcon from "../../assets/error404.svg";
import {HandleCheckStatusService} from "../../../services/api";
import { checkout } from "../../actions/cart";

const ServiceStatusProxyContainer = (props) => {
  const { modalTimer } = props;
  const viewDispatch = useViewDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    const axiosError = JSON.parse(localStorage.getItem("errorResponseAxios"));
    if (!axiosError || axiosError.status) viewDispatch('step.login');

    const executeConsultService = () => {
      const requestAxios = JSON.parse(localStorage.getItem("errorResponseAxios"));
      const storagePayments = JSON.parse(localStorage.getItem("payments"));

      if (!storagePayments) {
        if (requestAxios !== null) {
          Object.keys(requestAxios).forEach((serviceName) => {
            const statusService = requestAxios[serviceName];

            if (!statusService) {
              viewDispatch('step.serviceStatusProxy');
              localStorage.setItem("off-system", 'true');
              consultServiceStatus(serviceName);
            }
          });
        }
      }
    };

    window.removeEventListener('errorResponseAxios', executeConsultService);
    window.addEventListener('errorResponseAxios', executeConsultService, false);
  }, []);

  const consultServiceStatus = (service) => {
    HandleCheckStatusService(service)
      .then(() => {
        const accessToken = window.sessionStorage.getItem("Access_Token");
        const checkInRoutePayment = window.localStorage.getItem("inRouteToPayment");

        if (!checkInRoutePayment) {
          if (accessToken && !!accessToken.length) {
            viewDispatch('step.banner');
            dispatch(checkout());
          } else {
            viewDispatch('step.login');
          }
        } else {
          viewDispatch('step.payment');
        }

        localStorage.removeItem("off-system");
      })
      .catch(() => {
        consultServiceStatus(service);
      });

      modalTimer(false);
  };

  return (
    <>
      <div className='background-error-screen'>
        <div style={{marginTop: "30%"}} id="loader"></div>
        <h1 className='text-error-screen '>Estamos tentando conectar ao servidor</h1>
        <img src={errorIcon} className='image-error-screen'/>
      </div>
    </>
  );
};

export default React.memo(ServiceStatusProxyContainer);
