import React, { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useTranslation } from "react-i18next";

import { Content, Button } from "../../shared/components/modules";
import car from "../../assets/placa-carro.svg";
import { useCallback } from "react";
import { getThemeSettings, isShowTellInfo } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { useViewDispatch } from "../../../context";

const CarIdentificationContainer = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const {
    isLayoutAAOne,
    getTranslateName,
    getScreenImageBySlug,
    getScreenTitleBySlug,
    getScreenIsActiveBySlug
  } = useLayoutAa();

  const titleAskVehicleLicenseText =
    getTranslateName(t, "describe_ask_vehicle_license") ||
    getScreenTitleBySlug("PLACA_VEICULO") ||
    DEFAULT_LOCAL_TEXTS.describe_ask_vehicle_license;
  const describeAskVehicleLicenseText =
    getTranslateName(t, "describe_ask_vehicle_license") ||
    DEFAULT_LOCAL_TEXTS.describe_ask_vehicle_license;
  const isShowCPFPage = getScreenIsActiveBySlug("CPF_NA_NOTA") ?? true;
  const buttonBackText = getTranslateName(t, "buttons.back") || "Voltar";
  const buttonToContinueText = getTranslateName(t, "buttons.toContinue") || getThemeSettings?.text?.button_back_cpf_invoice || "Continuar";

  const [input, setInput] = useState("");
  const keyboard = useRef();

  const onChange = useCallback((input) => {
    if (input.length < 9) {
      setInput(input);
    }
  }, []);

  const onChangeInput = useCallback((event) => {
    const input = event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  }, []);

  const next = useCallback(() => {
    window.localStorage.setItem("carIdentification", input);

    if (isShowTellInfo) {
      return viewDispatch("step.tellIdentification");
    }

    return viewDispatch("step.orderIdentification");
  }, [input, viewDispatch]);

  const backStep = useCallback(() => {
    if (isShowCPFPage) {
      return viewDispatch("step.cpf");
    } else {
      return viewDispatch("step.orderSummary");
    }
  }, [viewDispatch, isShowCPFPage]);

  const headerImageSrc = isLayoutAAOne ? getScreenImageBySlug("PLACA_VEICULO", "PLACACARRO") : car;

  return (
    <Content
      bgColor="background-primary"
      title={titleAskVehicleLicenseText}
      subtitle={describeAskVehicleLicenseText}
      contentChildren={true}
      hasImg={true}
      srcImg={headerImageSrc}
      imgPosition="bottom"
      className="full car2"
      imageClass="car"
    >
      <div className="App keyboard">
        <input
          value={input}
          placeholder={""}
          onChange={onChangeInput}
          className="input-car"
        />

        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName="default"
          onChange={onChange}
          theme={"hg-theme-default hg-layout-default habibs car3"}
          onKeyPress={(button) => {
            console.log("Button pressed", button);
          }}
          disableRowButtonContainers={true}
          layout={{
            default: [
              "1 2 3 4 5 6 7 8 9 0",
              "Q W E R T Y U I O P",
              "A S D F G H J K L",
              "Z X C V B N M {bksp}",
            ],
          }}
        />
      </div>

      <footer className="footer">
        <Button
          text={buttonBackText}
          buttonUIType="outline-primary"
          buttonUI={"normal"}
          handleClick={() => backStep()}
        />

        <Button
          className="buttonAddCar"
          disabled={!input.length}
          text={buttonToContinueText}
          buttonUIType="primary"
          buttonUI={"normal"}
          handleClick={() => next()}
        />
      </footer>
    </Content>
  );
};

export default CarIdentificationContainer;
