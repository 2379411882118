import React, { createContext, useCallback, useMemo, useState } from "react"
import { DATA_USER_AUTH_KEY_NAME, TABLELIST_INFO } from "../../constants/keyNamesSessionStorage";
import { saleInProgressUpdateStatus, saleInProgressUpdateStatusPosition } from "../../../services/saleInProgressStatus";
import { sallerCardSelect } from "../../../services/sallerCardSelect";
import { useCashdesk } from "../../hook/cashdeskHook";
import { tableInProgressService } from "../../../services/tableInProgress";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useLoading } from "../../hook/loadingHook";

export const SallerTableContainerContext = createContext({});

export const SallerTableContainerProvider = ({ children }) => {
  const cashdesk = useCashdesk();
  const { setInfoTable } = useWrapperRouters();
  const [selectedSaleInProgressId, setSelectedSaleInProgressId] =
    useState(null);
  const [isOpenModalSaller, setIsOpenModalSaller] = useState(false);
  const [keyboardActions, setKeyboardActions] = useState();
  const [tableMap, setTableMap] = useState([]);
  const { setShowLoading } = useLoading();

  const [tableStatus, setTableStatus] = useState("");
  const [closedPositionsCount, setClosedPositionsCount] = useState(0);
  const [numbersCustomers, setnumbersCustomers] = useState(null);
  const [isTableOpen, setIsTableOpen] = useState(tableStatus === "ACTIVE");
  const [isPrint, setPrint] = useState(null);
  const [valuePerPerson, setValuePerPerson] = useState(0);
  const [positionStatus, setPositionStatus] = useState([]);
  const [areAllPositionsSelectedActive, setAreAllPositionsSelectedActive] = useState(
    false
  );
  const [areAllPositionsSelectedClose, setAreAllPositionsSelectedClose] = useState(
    false
  );

  const operator = JSON.parse(sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME));


  // Verifica se todas as posições já foram fechadas e fecha a mesa
  const checkIfAllPositionsClosed = (updatedNumberOfCustomers) => {
    const areAllClosed =
      closedPositionsCount === selectedSaleInProgressId?.positions.length;

    if (areAllClosed) {
      const newTableStatus = "CLOSING";
      handleUpdateTableStatus(newTableStatus, updatedNumberOfCustomers);
      setIsTableOpen(false);
    }
  };

  // Abre a mesa quando uma posição é reaberta com a mesa fechada
  const handleOpenTableOnOpenPosition = (newStatus) => {
    if (tableStatus === "CLOSING" && newStatus === "ACTIVE") {
      setIsTableOpen(true);
      setTableStatus(newStatus);
    }
  };


  const handleUpdateTableStatus = (newStatus, updatedNumberOfCustomers) => {
    setnumbersCustomers(updatedNumberOfCustomers);
    setClosedPositionsCount(0);
    saleInProgressUpdateStatus({
      newStatus,
      saleInProgressId: selectedSaleInProgressId._id,
      cashDeskId: cashdesk.cashdesk.id,
      sellerReferenceIdFinished: operator.employeeCode,
      sellerNameFinished: operator.login,
      numberOfCustomers: updatedNumberOfCustomers,
      payments: {},
    }).then(() => {
      setTableStatus(newStatus);
      handleSearchTable({cashdesk: cashdesk.cashdesk});
    });
  };

  const handleUpdatePositionStatus = (newStatus, updatedNumberOfCustomers) => {
    const selectedPositions = positionStatus
      .filter(
        (item, index, self) =>
          self.findIndex(
            (otherItem) => otherItem.position === item.position
          ) === index
      )
      .map((item) => item.position);

    setnumbersCustomers(updatedNumberOfCustomers);

    saleInProgressUpdateStatusPosition({
      newStatus,
      saleInProgressId: selectedSaleInProgressId._id,
      cashDeskId: cashdesk.cashdesk.id,
      sellerReferenceIdFinished: operator.employeeCode,
      sellerNameFinished: operator.login,
      positions: selectedPositions,
      payments: {},
    }).then(() => {
      checkIfAllPositionsClosed();
      handleSearchTable({cashdesk: cashdesk.cashdesk});
      handleOpenTableOnOpenPosition(newStatus);
      handleSelectSaller(selectedSaleInProgressId._id)
    });

    if (newStatus === "CLOSING") {
      setClosedPositionsCount(
        (prevCount) => prevCount + selectedPositions.length
      );
    }
  };

  const updatePositionStatusAndHighlightRows = (newStatus, updatedNumberOfCustomers, positions) => {
    handleUpdatePositionStatus(newStatus, updatedNumberOfCustomers);
    const updatedPositionStatus = positionStatus.map((position) => {
      if (positions.includes(position.position)) {
        return {
          ...position,
          status: newStatus,
        };
      }
      return position;
    });

    setPositionStatus(updatedPositionStatus);

    updatedPositionStatus.forEach((currentPosition) => {
      if (currentPosition.status === "ACTIVE") {
        const positionValue = currentPosition.position;

        document
          .querySelectorAll(".ag-row.ag-row-group")
          .forEach((element) => {
            const position = element.getAttribute("style");
            if (
              position &&
              position.includes(`--position-row: ${positionValue};`)
            ) {
              element.style["background-color"] = "#4594f2";
            }
          });
      }
    });
  }

  const handleSelectSaller = useCallback(async (saleInProgressId) => {
    try {
      const res = await sallerCardSelect({ saleInProgressId });
      setSelectedSaleInProgressId(res);
      setIsOpenModalSaller(true);
    } catch (err) {
      if (window.Android) {
        console.log(`error on handleSelectSaller -> ${JSON.stringify(err)}`);
      } else {
        console.error("error on handleSelectSaller ->", err);
      }
    }
  }, []);

  const handleSearchTable = useCallback(async ({ cashdesk }) => {
    try {
      const res = await tableInProgressService({
        CashDeskOpenDateId: cashdesk.cashDeskOpenDate.id,
        tableNumber: keyboardActions?.value ?? "",
      });
      setTableMap(res.tableMap);
      sessionStorage.setItem(TABLELIST_INFO, JSON.stringify(res.info));
      setInfoTable(res.info);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  }, []);

  const value = useMemo(() => ({
    handleSearchTable,
    selectedSaleInProgressId,
    setSelectedSaleInProgressId,
    isOpenModalSaller,
    setIsOpenModalSaller,
    keyboardActions,
    setKeyboardActions,
    tableMap,
    setTableMap,
    tableStatus,
    setTableStatus,
    closedPositionsCount,
    setClosedPositionsCount,
    numbersCustomers,
    setnumbersCustomers,
    isTableOpen,
    setIsTableOpen,
    isPrint,
    setPrint,
    valuePerPerson,
    setValuePerPerson,
    positionStatus,
    setPositionStatus,
    areAllPositionsSelectedActive,
    setAreAllPositionsSelectedActive,
    areAllPositionsSelectedClose,
    setAreAllPositionsSelectedClose,
    handleUpdatePositionStatus,
    updatePositionStatusAndHighlightRows,
    checkIfAllPositionsClosed,
    handleSelectSaller,
    handleUpdateTableStatus
  }), [
    handleSearchTable,
    selectedSaleInProgressId,
    setSelectedSaleInProgressId,
    isOpenModalSaller,
    setIsOpenModalSaller,
    keyboardActions,
    setKeyboardActions,
    tableMap,
    setTableMap,
    tableStatus,
    setTableStatus,
    closedPositionsCount,
    setClosedPositionsCount,
    numbersCustomers,
    setnumbersCustomers,
    isTableOpen,
    setIsTableOpen,
    isPrint,
    setPrint,
    valuePerPerson,
    setValuePerPerson,
    positionStatus,
    setPositionStatus,
    areAllPositionsSelectedActive,
    setAreAllPositionsSelectedActive,
    areAllPositionsSelectedClose,
    setAreAllPositionsSelectedClose,
    handleUpdatePositionStatus,
    updatePositionStatusAndHighlightRows,
    checkIfAllPositionsClosed,
    handleSelectSaller,
    handleUpdateTableStatus
  ]
  );

  return (
    <SallerTableContainerContext.Provider
      value={value}>
      {children}
    </SallerTableContainerContext.Provider>
  )
}
