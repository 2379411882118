import React, { Component, createContext, useContext } from "react";

export const ErrorBoundariesContext = createContext({});
export class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    this.setState({
      errors: {
        error,
        errorInfo,
      },
    });
  }

  render() {
    return (
      <ErrorBoundariesContext.Provider
        value={{
          hasError: this.state.hasError,
          errors: this.state.errors,
          disableError: () => {
            this.setState({ hasError: false });
          },
        }}
      >
        {this.props.children}
      </ErrorBoundariesContext.Provider>
    );
  }
}

export const useErrorBoundaries = () => {
  const context = useContext(ErrorBoundariesContext);

  return context;
};
