import React from "react";
import { render } from "react-dom";
import PrintTemplate from "../../components/print-template";
import { formatDateHelper } from "../formatDateHelper";
import { printerActionService } from "../../../services/actions/printerActionService";
import { lineToPrintHelper } from "../lineToPrintHelper";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const printReduceCashDesk = (cashdesk) => {
  try {
    var html = document.createElement("html");

    render(
      <PrintTemplate>
        <>
          {!isMobile
            ? "--------------------------------------------------------"
            : lineToPrintHelper}
          <br />
          <div style={{ textAlign: "center" }}>
            <b>REDUÇÃO DE CAIXA</b>
          </div>
          {!isMobile
            ? "--------------------------------------------------------"
            : lineToPrintHelper}
          <br />
          PDV: {cashdesk.posId}
          {isMobile ? "\n" : ""}
          <br />
          <table>
            <tbody>
              <tr>
                <td className="shrink">Data Abertura:{isMobile ? " " : ""}</td>
                <td className="expand">
                  {formatDateHelper(
                    new Date(cashdesk.openingDate),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">Abertura:{isMobile ? " " : ""}</td>
                <td className="expand">
                  {cashdesk.openingCount}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">Responsável:{isMobile ? " " : ""}</td>
                <td className="expand">
                  {cashdesk.responsable}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">Operador:{isMobile ? " " : ""}</td>
                <td className="expand">
                  {cashdesk.user}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">Data Redução:{isMobile ? " " : ""}</td>
                <td className="expand">
                  {formatDateHelper(
                    new Date(cashdesk.reducingDate),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
            </tbody>
          </table>
          {!isMobile ? "==================================" : lineToPrintHelper}
          {isMobile ? "\n\n\n\n\n\n" : ""}
          <br />
        </>
      </PrintTemplate>,
      html
    );
  } catch (e) {
    console.error(`printReduceCashDesk | ${JSON.stringify(e)}`);
  }

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    console.log(`printDefault | {}`);
    printDefault(html.outerHTML);
  }
};
