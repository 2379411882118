import styled from "styled-components";

const ModalWrapper = styled.section`
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);

  .tefError {
    .title {
      margin-bottom: 100px;
      margin-top: 0px;
    }
    button {
      width: 50%;
    }
  }

  .modal {
    text-align: center;
    padding: 5em 2em;
    border-radius: 1.25em;
    box-sizing: border-box;
    background-color: var(--color-background-modal);

    .title {
      color: var(--color-title-category);
      font-size: 3.75rem;
      text-transform: uppercase;
    }

    .subtitle {
      color: var(--color-background-primary);
      font-size: 2rem;
    }

    button {
      color: var(--color-title-category);
      margin: 1em 1em;
      padding: 1em 2em;
      outline: none;
    }
  }

  .modal-points .title {
    color: var(--color-title-category);
  }

  .modal-points .subtitle {
    color: var(--color-title-modal);
    font-size: 40px;
    width: 70%;
    margin: 0 auto;
  }
`;

export default ModalWrapper;
