
export const transactionData = (date, format)  => {
    const beautifyNumbers = (value, quantity) => {
        if (typeof value !== 'string') value = value.toString();

        return (value.length < quantity ? '0'.repeat(quantity - value.length) : '') + value;
    }

    let d = new Date(date),
        day = beautifyNumbers(d.getDate(), 2),
        month = beautifyNumbers(d.getMonth() + 1, 2), // 0 to 11
        year = beautifyNumbers(d.getFullYear(), 2),
        hour = beautifyNumbers(d.getHours(), 2),
        minute = beautifyNumbers(d.getMinutes(), 2),
        second = beautifyNumbers(d.getSeconds(), 2),
        millisec = beautifyNumbers(d.getMilliseconds(), 3),
        utcHour = "00",
        utcMinute = "00"
        ;

    if (d.getTimezoneOffset() > 0) {
        utcHour = beautifyNumbers(d.getTimezoneOffset() / 60, 2);
        utcMinute = beautifyNumbers(d.getTimezoneOffset() % 60, 2);
    }

    if (!format)
        format = 'dd/MM/yyyy hh:mm:ss';

    return format
        .replace('dd', day)
        .replace('MM', month)
        .replace('yyyy', year)
        .replace('yy', year.toString().substring(2, 4))
        .replace('hh', hour)
        .replace('mm', minute)
        .replace('ss', second)
        .replace('zzz', millisec)
        .replace('utc', `-${utcHour}:${utcMinute}`)
        ;
}