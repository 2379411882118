import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import { Container } from "./styles";

export const KeyboardNumeric = ({ newButtons, setActions, className = "" }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState("");

  const buttons = newButtons ?? [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    {
      className: "clear",
      value: "Limpar",
    },
    "0",
    {
      className: "backspace",
      value: "←",
    },
  ];

  const set = useCallback(
    (value) => {
      if (!isDisabled) {
        setValue(value);
      }
    },
    [isDisabled]
  );

  const setDisabled = useCallback((disabled) => {
    setIsDisabled(disabled);
  }, []);

  const resetValues = useCallback(() => {
    setValue("");
  }, []);

  const handleChangeValue = useCallback(
    (inputValue, inputClassName) => {
      if (inputClassName === "backspace") {
        setValue((currentValue) => {
          return currentValue.substring(0, currentValue.length - 1);
        });
        setDisabled(false);
      } else if (inputClassName === "clear") {
        setDisabled(false);
        setValue("");
      } else {
        if (!isDisabled) {
          setValue((currentValue) => `${currentValue}${inputValue}`);
        }
      }
    },
    [isDisabled]
  );

  useEffect(() => {
    if (setActions) {
      setActions({
        value,
        set,
        setDisabled,
        resetInput: resetValues,
      });
    }
  }, [value]);

  return (
    <Container
      className={classNames(
        {
          "keyboard-numeric": true,
        },
        className
      )}
    >
      {buttons.map((button, index) => {
        const classValueName =
          typeof button === "object" ? button.className : button;
        const className = `button button-${classValueName}-item`;
        const text = typeof button === "object" ? button.value : button;

        return (
          <button
            type={button?.type ? button?.type : "button"}
            key={String(index)}
            className={className}
            onClick={() =>
              button?.onClick
                ? button.onClick()
                : handleChangeValue(text, classValueName)
            }
            disabled={button?.disabled}
          >
            {text}
          </button>
        );
      })}
    </Container>
  );
};
