import React from "react";
import {
  BoxBottom,
  BoxTop,
  BoxWrapper,
  ButtomBox,
  ButtonContainer,
  ContentData,
  CouponWrapper,
  CupomNsuContainer,
  Header,
  InfoBody,
  InfoBottom,
  InfoHeader,
  NSUWrapper,
  TextData,
  TotalWrapper,
} from "./style";
import Button from "../Button";
import { currencyMask } from "../../helpers/masks";

export const BoxItems = ({ items, onPrint, onCancel }) => {
  return (
    <>
      {items.map((item, index) => (
        <BoxWrapper>
          <InfoHeader>
            <CouponWrapper>
              <TextData>Cupom</TextData>
              <h4>{item?.couponNumber}</h4>
            </CouponWrapper>
            <NSUWrapper>
              <TextData>NSU</TextData>
              <h4>{item?.controlCode}</h4>
            </NSUWrapper>
          </InfoHeader>
          <InfoBody>
            <TotalWrapper>
              <TextData>Total</TextData>
              <h3>{item?.total}</h3>
            </TotalWrapper>
          </InfoBody>
          <InfoBottom>
            <TextData>
              Data: <strong>{item?.dateDoc}</strong>
            </TextData>
            <TextData>
              Operador: <strong>{item?.seller}</strong>
            </TextData>
            <TextData>
              Consumidor: <strong>{item?.consumer}</strong>
            </TextData>
            <TextData>
              Status: <strong>{item?.state}</strong>
            </TextData>
          </InfoBottom>
          <ButtonContainer>
            <Button className="btn-cancel" onClick={() => onCancel(item)}>
              <div>
                <i className="material-icons">cancel</i>
                Cancelar
              </div>
            </Button>
            <Button className="btn-print" onClick={() => onPrint(item)}>
              <div>
                <i className="material-icons">print</i>
                Imprimir
              </div>
            </Button>
          </ButtonContainer>
        </BoxWrapper>
      ))}
    </>
  );
};
