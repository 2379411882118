import styled from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);

  .container__loading {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    &.container__withText {
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.25);
      background-color: #fff;

      padding: .5rem 1rem;

      text-transform: uppercase;
      border-radius: 5px;
      margin-top: 2rem;
    }

    .loading__text {
      text-transform: uppercase;
      margin-top: 2rem;
    }
  }
`;
