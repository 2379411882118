import React from "react";
import styled from "styled-components";

export const ChangeTableIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.17 13.26L13.07 12.16C13.78 10.83 13.6 9.15 12.48 8.03C11.79 7.34 10.9 7 10 7C9.97 7 9.94 7.01 9.91 7.01L11 8.1L9.94 9.16L7.11 6.33L9.94 3.5L11 4.56L10.04 5.52C11.31 5.53 12.57 6 13.54 6.96C15.24 8.67 15.45 11.32 14.17 13.26ZM12.89 14.67L10.06 17.5L9 16.44L9.95 15.49C8.69 15.48 7.43 14.99 6.47 14.03C4.76 12.32 4.55 9.68 5.83 7.74L6.93 8.84C6.22 10.17 6.4 11.85 7.52 12.97C8.22 13.67 9.15 14.01 10.08 13.98L9 12.9L10.06 11.84L12.89 14.67Z"
      fill="#2e0037"
    />
  </svg>
);

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .custom-description {
    padding-left: 10px;
  }

  .ag-theme-balham {
    &.monitor {
      width: 65%;
      margin-left: 10px;
      margin-top: 10px;
      margin-right: 15px;
      height: 420px;

      .ag-row-group {

        .ag-icon {
          color: #fff;
        }

        .ag-group-child-count {
          display: none;
        }

        .ag-group-value {
          color: #fff;
          padding: 8px 0;
          width: calc(100% - 16px);
          border-radius: 4px;
          width: 100%;
        }
      }

      .ag-header {
        background: #2e0037;
      }

      .ag-root-wrapper {
        border-radius: 5px;
      }

      .ag-header-row {
        color: #fff;
      }

      .ag-status-bar {
        background-color: #fbf7ff;
        color: #2e0037;
      }

      .ag-row {
        background-color: #fbf7ff;
      }

      .ag-row-odd {
        background-color: #fff;
      }

      *::-webkit-scrollbar {
        width: 1px;
      }

      .ag-cell-label-container {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }

  .ag-floating-bottom-container .ag-row {
    border: none !important;
    background-color: #ede7f5;
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;

    background-color: #fff;
    max-width: 80%;
    width: 100%;

    position: relative;

    border-radius: 0.5rem;
    margin: 0 auto;

    .payment-individualization {
      display: flex;

      span {
        font-size: 25px;
        margin: 5px;
      }
    }

    input {
      padding: 10px;
      border: 1px solid rgba(128, 128, 128, 0.34);
      border-radius: 5px;
    }

    .ag-grid {
      display: flex;
      margin: 10px;
      flex-direction: column;

      .content-aggrid {
        display: flex;
        flex-direction: row;
      }
    }

    .detail-items {
      height: 34.5rem;
      overflow-y: scroll;
    }

    .header__content {
      position: sticky;
      bottom: 0;
      padding: 10px;
    }

    .fixed__content {
      position: sticky;
      bottom: 0;
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid rgba(206, 206, 206, 0.47);

      .title {
        color: #2e0037;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0 1rem;
      }

      .button__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        cursor: pointer;
      }

      strong {
        font-size: 2rem;
        color: #2e0037;
      }

      .position-nav {
        display: flex;
        flex-direction: column;

        span {
          margin-right: 3rem;
        }

        .position-selector {
          display: flex;
          margin-top: 10px;

          select {
            margin-right: 8px;
          }
        }
      }

      .button__action {
        border: 2px solid ${({ theme }) => theme.colors.purple};
        color: ${({ theme }) => theme.colors.purple};
        border-radius: 0.5rem;
        padding: 0.375rem;

        display: flex;
        align-items: center;
        justify-content: center;

        & + .button__action {
          margin-left: 0.5rem;
        }

        &.button__success {
          background-color: #479c4b;
          border-color: ${({ theme }) => theme.colors.success};
          color: #fff;
        }

        &:disabled {
          border-color: #9a9a9a;
          color: #9a9a9a;
        }
      }
    }

    .table-status {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .active {
      background-color: #0574e9;
      padding: 5px 20px;
      border-radius: 20px;
      display: inline-block;
      color: #fff;
    }

    .closing {
      background-color: #c7251b;
      padding: 5px 20px;
      border-radius: 20px;
      display: inline-block;
      color: #fff;
    }

    .subtitle {
      padding: 5px 15px;
      border-radius: 50%;
      height: 30px;
      margin-right: 10px;

      &.active {
        background-color: #0574e9;
      }
      &.closing {
        background-color: #c7251b;
      }
      &.payment {
        background-color: #1d7b2d;
      }
    }

    .header {
      &.status {
        justify-content: space-around;
        border: 0;
      }
    }

    .content__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 0.5rem;

      .button__text {
        text-align: center;
      }

      .button {
        display: flex;
        flex-direction: column;

        align-items: center;
        align-self: stretch;
        justify-content: center;

        padding: 0.5rem 0.5rem;
        width: 100%;

        text-transform: uppercase;
        color: #fff;

        cursor: pointer;

        & + .button {
          margin-left: 1rem;
        }

        &.button__here {
          background-color: ${({ theme }) => theme.colors.warning};
          font-weight: 600;
          border-radius: 5px;
        }

        &.button__toTrip {
          background-color: ${({ theme }) => theme.colors.purple};
          font-weight: 600;
          border-radius: 5px;
        }

        &.button__close {
          background-color: #c7251b;
          color: #fff;
          font-weight: 600;
          border-radius: 5px;

          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        &.button__payment {
          background-color: #1d7b2d;
          color: #fff;
          font-weight: 600;
          border-radius: 5px;
        }

        &.button__white {
          background-color: #fff;
          border: 2px solid #2e0037;
          color: #2e0037;
          font-weight: 600;
          border-radius: 5px;
        }
      }
    }

    .info__text__container {
      width: 35%;
      margin-left: 5px;

      > svg {
        position: absolute;
        top: 0.5rem;
      }

      .info__products {
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 5px;
        margin-top: 10px;
        resize: none;
        width: 100%;
      }

      .text-row {
        display: flex;
        justify-content: space-between;
        padding: 3px;
      }

      .info__text {
        display: flex;
        flex-direction: column;
        border: 2px dashed #bdbdbd;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        resize: none;
        width: 100%;

        &,
        &::placeholder {
          color: #000;
        }
      }
    }
  }

  .position-status-closing {
    background-color: #c7251b !important;
  }

  .position-status-active {
    background-color: #4594f2 !important;
  }
`;
