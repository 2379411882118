import React, { memo } from "react";
import { MdOutlineShoppingCart } from "react-icons/md";

import { useCart } from "../../../hook/cartHook";

import { Container } from "./styles";

export const ButtonCart = memo(() => {
  const { setIsCartVisible, itemsInCart } = useCart();

  const handleButtonCartAction = () => {
    setIsCartVisible((isVisible) => !isVisible);
  };

  return (
    <Container type="button" onClick={handleButtonCartAction}>
      {itemsInCart.length > 0 && (
        <div className="budget">{itemsInCart.length}</div>
      )}
      <MdOutlineShoppingCart size={28} />
    </Container>
  );
});
