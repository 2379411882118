import React from "react";
import { SimpleItemTableSmartPos } from "../SimpleItemTableSmartPos";

export const CardContentSmartPos = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <div key={`simple-item-${index}-table-smart-pos`}>
          <SimpleItemTableSmartPos items={item.list} />
        </div>
      ))}
    </>
  );
};
