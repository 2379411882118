import styled, { css } from "styled-components";

const style = css`
  display: inline-block;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 300;
  border-color: transparent;
  height: 45px;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 8px !important;

  &,
  &:visited {
    display: inline-block;
    margin: 0;
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover i {
    color: white;
  }

  i {
    vertical-align: middle;
    color: ${(props) => props.theme.colors.primary};
  }

  span {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &.btn-sm {
    height: 29px;
  }

  &.btn-clear {
    width: 80%;
    height: 100%;
    font-size: 18px;
  }

  &.btn-clear-large {
    width: 100%;
    height: 100%;
    font-size: 18px;
  }

  &.btn-default {
    color: ${(props) => props.theme.colors.primary};
    text-transform: ${(props) => props.theme.text.uppercase};
    padding: 15px 25px !important;
    background-color: #fff;
  }

  &.btn-primary {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 4px !important;
    color: #fff;
    font-size: 14px;
  }

  &.btn-text {
    color: ${(props) => props.theme.colors.primary};
    line-height: 40px;
  }

  &.btn-finish {
    margin-top: 2%;
    width: 150px;
    height: 50px;
    color: ${(props) => props.theme.colors.primary};
    background-color: #f8f9fa;
    border: 1px solid #e9e9e9;

    &:hover,
    &:focus {
      border: 2px solid ${(props) => props.theme.colors.primary};
    }
  }

  &.btn-outline {
    background-color: unset;
    border: 1px solid #e9e9e9 !important;
    border-radius: 4px;
    color: #000;
  }

  &.btn-cancel {
    background-color: unset;
    border: 1px solid #e9e9e9 !important;
    border-radius: 4px;
    color: #000;
  }

  &.btn-pay-values {
    background-color: white;
    border: 1px solid #eeeeee;
    color: #757575;
    border-radius: 1px;

    &:active {
      background-color: ${(props) => props.theme.colors.primary};
      color: white;
    }
  }

  &.btn-confirm-pay {
    border: 2px solid ${(props) => props.theme.colors.primary};
    height: 100%;

    &:active {
      background-color: ${(props) => props.theme.colors.primary};
      color: white;
    }
  }

  &.btn-purple {
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 4px !important;
    padding: 0.7em !important;
    color: #fff;
  }

  &.btn-purple[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-purple-outline {
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.colors.primary} !important;
    border-radius: 4px !important;
    color: ${(props) => props.theme.colors.primary};
    padding: 0.7em !important;
  }

  &.btn-purple-outline[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-secondary {
    color: ${(props) => props.theme.colors.primary};
    background-color: transparent;
  }

  &.btn-transparent {
    text-transform: uppercase;
    background: none;
  }
`;

export const ButtonWrapper = styled.button`
  ${style}
`;
export const AnchorWrapper = styled.a`
  ${style}
`;
