import styled from "styled-components";

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .content__wrapper {
    background-color: #fff;
    max-width: 420px;
    width: 100%;

    position: relative;

    border-radius: 0.5rem;
    margin: 0 auto;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0 1rem;
      }

      .button__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        cursor: pointer;
      }
    }

    .content__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 1rem;

      .button {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: space-between;

        padding: 1rem 0.5rem;
        width: 100%;

        text-transform: uppercase;
        color: #fff;

        cursor: pointer;

        & + .button {
          margin-left: 1rem;
        }

        &.button__here {
          background-color: ${({ theme }) => theme.colors.warning};
        }

        &.button__toTrip {
          background-color: ${({ theme }) => theme.colors.purple};
        }

        svg {
          margin-bottom: 0.5rem;
        }
      }
    }

    .info__text__container {
      padding: 0 1rem 1rem;
      width: 100%;
      margin-bottom: 6px;

      position: relative;

      > svg {
        position: absolute;
        top: 0.5rem;
      }

      .info__text {
        border: 3px dashed #2e0037;
        padding-left: 0.5rem;
        margin-top: 20px;
        resize: none;
        width: 100%;

        &,
        &::placeholder {
          color: #000;
        }
      }
    }
  }
`;
