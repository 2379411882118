import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";
import { isSmartPos } from "../../../aaone/configuration";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;

  width: 100%;
  padding: 0;

  grid-gap: 16px;
  gap: 16px;
  bottom: 20px;

  &.isCategoryFidelity,
  .isCategoryFidelity {
    flex: 1;
  }

  &.main__in_smart_pos {
    overflow: auto;
    height: auto;
    min-height: 165px;
  }

  .carousel__products {
    position: relative;
    width: 100%;

    &.is__smart__pos__container {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0 1rem;
    }

    .warn-info {
      padding: 1rem;
      margin-top: 0.5rem;
      background: #ffeee9;

      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      .title {
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }

    .slick-slider {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0;
      width: 100%;

      grid-gap: 16px;
      gap: 16px;
      bottom: 10px;

      .slick-current {
        margin-bottom: 5px;
      }

      .slick-list {
        width: 100%;
      }

      .slick-slide > div {
        margin-top: 1rem;
      }
    }
  }

  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .carousel__products .slick-slider .slick-slide > div {
      margin-top: 0.25rem;
    }
  `)}
`;
export const BackButton = styled.button`
  width: 60px !important;
  padding: 8px !important;
  border-radius: 7px !important;
  background: none;
  color: rgb(107, 107, 107);
  font-weight: bold;
  font-size: 0.7rem !important;
  margin-top: ${isSmartPos ? "5px" : "16px"};
  margin-left: ${isSmartPos ? "-20px" : ""};
  height: 64px;
  z-index: 1;
  font-weight: bold;

  ${(props) =>
    !props.showButtonBack &&
    css`
      display: none;
    `}
`;

export const ContentBackButton = styled.div`
  ${(props) =>
    props.hasArrowButton &&
    css`
      position: absolute;
      z-index: 2;
    `}

  &.isSmartPos {
    position: absolute;
    z-index: 2;

    .text {
      display: none;
    }
  }
`;

export const Content = styled.div`
  z-index: 1;
  ${(props) =>
    !props.hasArrowButton &&
    css`
      height: 100%;
      display: flex;
    `}

  .no_arrow__has__button_back {
    width: calc(
      100% - 64px
    ) !important; // 64px é a largura do botão voltar mais um pouco
  }

  .slick-track {
    display: flex;
    justify-content: center;
  }

  &.container__mobile {
    flex-direction: column;

    .no_arrow__has__button_back {
      width: 100% !important;
    }
  }
`;
