const axios = require('axios').default;

// Criação de ordem de pagamento na rota /paymentOrder (POST), retorna a ID do obj quando sucesso
const createPaymentOrder = async (middlewareEndPoint, order) => {
  try {
    const response = await axios.post(`http://${middlewareEndPoint}:3335/paymentOrder`, order, {
      headers: {
        "Content-Type": 'application/json;charset=utf-8',
        "Access-Control-Allow-Origin": '*'
      }
    });
    return response.data?.data?._id;
  } catch (error) {
    console.log(error);
    return Promise.reject("Error in createPaymentOrder :: ");
  }
  return null;
}

// Cancelamento de ordem de pagamento
const cancelPaymentOrder = async (middlewareEndPoint, id) => {
  try {
    const response = await axios.post(`http://${middlewareEndPoint}:3335/cancellationOrder`, { id }, {
      headers: {
        "Content-Type": 'application/json;charset=utf-8',
        "Access-Control-Allow-Origin": '*'
      }
    });
    return !!response.data?.paymentOrder;
  } catch (error) {
    console.log(error);
    return Promise.reject("Error in cancelPaymentOrder :: ");
  }
  return false;
}

// Atualização do path do doc fiscal de uma ordem de pagamento
const updateNFPathToPrint = async (middlewareEndPoint, updateDocPathObj) => {
  try {
    const response = await axios.put(`http://${middlewareEndPoint}:3335/nfprint`, updateDocPathObj, {
      headers: {
        "Content-Type": 'application/json;charset=utf-8',
        "Access-Control-Allow-Origin": '*'
      }
    });
    return response.data?.success;
  } catch (error) {
    console.log(error);
    return Promise.reject("Error in updateNFPathToPrint :: ");
  }
  return false;
}

// Polling para obtenção do status de pagamento, exige o objId, retornado quando criado a ordem de pagamento
const paymentStatusPolling = async (middlewareEndPoint, objId, maxAttempts = 180, intervalInMs = 2000) => {
  console.log('Start polling')
  var processed = false;
  var attempts = 0;
  const sleep = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));

  const getOrderStatus = async () => {
    try {
      const response = await axios.get(`http://${middlewareEndPoint}:3335/paymentstatus?id=${objId}`, {
        headers: {
          "Content-Type": 'application/json;charset=utf-8',
          "Access-Control-Allow-Origin": '*'
        }
      });
      return response?.data;
    } catch (error) {
      console.log(error);
      return Promise.reject("Error in paymentStatusPolling :: ");
    }
    return null;
  };

  let reqResponse;
  while (!processed && attempts < maxAttempts) {
    attempts++;
    console.log(`Aguardando alteração de status do pagamento: ${attempts}/${maxAttempts}`);
    reqResponse = await getOrderStatus();
    processed = reqResponse.orderStatus === 'success' || reqResponse.orderStatus === 'failure';

    if (attempts >= maxAttempts) console.log('Tentativas esgotadas');
    await sleep(intervalInMs);
  }
  console.log('Ordem processada:', processed ? 'sim' : 'não', ' | Status: ' + reqResponse.orderStatus);

  return {
    processed,
    orderStatus: reqResponse.orderStatus,
    id: reqResponse._id,
    paymentResponseStone: reqResponse.paymentResponseStone
  };
}

export { createPaymentOrder, paymentStatusPolling, updateNFPathToPrint, cancelPaymentOrder };
