import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  flex: 1;

  .text {
    margin: 1rem auto 0;
    text-align: center;
    color: #000;

    line-height: 2.75rem;
    font-size: 2.25rem;
    font-weight: 700;

    max-width: 570px;
    width: 100%;
  }
`;
