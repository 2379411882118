import styled from "styled-components";

export const FidelityTabsContent = styled.div`
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  font-weight: bold;
`;

export const FidelityTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #dadada;
  height: 3.75rem;
  width: 100%;
  border-bottom: 10px solid #bdbdbd;

  &.active {
    background: #ffffff;
    border-bottom: 10px solid #ffb200;

  }
`;
