import styled from "styled-components";

export const ContainerTitle = styled.section`
  display: flex;
  align-items: center;
  margin: 1em 0;
  color: var(--color-background-cart);
`;

export const CountPage = styled.strong`
  font-size: 3.4em;
  color: var(--color-title-category);
`;

export const Title = styled.span`
  font-size: 2em;
  margin-left: 0px;
`;
