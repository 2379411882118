import React from 'react';
import { LoadingDots } from '../LoadingDots';

import { Wrapper } from './styles';


export const LoadingWhiteWithText = ({ children }) => {
  return (
    <Wrapper>
      <LoadingDots />
      <div className="text">{children}</div>
    </Wrapper>
  );
}
