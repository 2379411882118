import styled, { css } from "styled-components";
import { isSmartPos } from "../../../../aaone/configuration";

export const ReportCashierClosingWrapper = styled.div`
  margin: 10px 0 0;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  .react-datepicker__aria-live {
    display: none;
  }
`;

export const TableContainer = styled.div`
  height: 85%;
  overflow: auto;
  padding: 5px;
  width: 100%;

  .ag-theme-balham {
    height: 60vh !important;

    &.monitor {
      .ag-header {
        background: #e7eaf1;
      }

      *::-webkit-scrollbar {
        width: 1px;
      }

      .ag-cell-label-container {
        font-weight: 700;
        font-size: 12px;
      }

      .ag-row-odd {
        background-color: #fafafd;
      }
    }
  }
`;

export const ActionsButtons = styled.div`
  display: flex;
  justify-content: center;

  button {
    text-align: center;
    padding: 15px 20px;
    margin: 15px;
    line-height: 0;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: normal;
  margin-bottom: 20px;

  input {
    height: 30px;
    padding-left: 10px;
  }

  &.isSmartPos {
    height: 110px;

    .react-datepicker-popper {
      max-width: 500px;
      width: 76%;
      z-index: 50;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #000;
      display: inline-block;
      width: 1.7rem;
      line-height: 2.7rem;
      text-align: center;
    }
  }

  .btn-pronto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #411e5a;
    border-radius: 4px !important;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: 14px;
    padding: 20px 8px;
    margin-top: 15px;
    outline: none;

    .material-icons {
      color: #fff;
    }
  }

  &.isSmartPos {
    flex-direction: column;

    input {
      width: 287px;
    }

    .btn-pronto {
      align-items: center;
    }
  }

  .refresh {
    margin: -5px 0 0 10px;
    padding: 0 12px;
    color: #5b2e90;
    font-size: 36px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    i {
      &:hover {
        cursor: pointer;
        color: #ffb201;
      }
      padding-bottom: 4px;
    }
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 100px;
  bottom: 80px;
  flex-direction: column;
  position: absolute;
  right: 50px;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;

export const Modal = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ModalContent = styled.div`
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 80vw;
  position: relative;
`;

export const ActionCellRender = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-around;
`;
