import React from "react";
import { MdOutlineExpandMore, MdKeyboardArrowRight } from "react-icons/md";
import { AccordionTitle, AccordionContent, Container } from "./styles";

const Accordion = ({ title, value, content, activeAccordion, setActiveAccordion }) => {
  const isExpanded = activeAccordion === title;
  const icon = isExpanded ? <MdOutlineExpandMore size={24} /> : <MdKeyboardArrowRight size={24} />;

  const toggleAccordion = () => {
    if (isExpanded) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(title);
    }
  };

  return (
    <Container>
      <div className={`accordion ${isExpanded ? "expanded" : "collapsed"}`}>
        <div className="accordion-header" onClick={toggleAccordion}>
          <AccordionTitle
            className={isExpanded ? "expanded-title" : ""}
          >
            <div>{title}</div>
            <div>{value}{icon}</div>
          </AccordionTitle>
        </div>
        {isExpanded && <AccordionContent>{content}</AccordionContent>}
      </div>
    </Container>
  );
};

export default Accordion;
