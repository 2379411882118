import {
  format,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";

export const verifyRestriction = ({ restriction, startHour }) => {
  const startTime = restriction.startTime.split(":");
  const endTime = restriction.endTime.split(":");

  if (
    isAfter(
      startHour,
      new Date(
        format(
          setSeconds(
            setMinutes(setHours(new Date(), startTime[0]), startTime[1]),
            startTime[2]
          ),
          "yyyy-MM-dd HH:mm:ss"
        )
      )
    ) &&
    isBefore(
      startHour,
      new Date(
        format(
          setSeconds(
            setMinutes(setHours(new Date(), endTime[0]), endTime[1]),
            startTime[2]
          ),
          "yyyy-MM-dd HH:mm:ss"
        )
      )
    )
  ) {
    return false;
  }

  return true;
};
