import { TEF_CONFIG } from "../constants/keyNamesSessionStorage";

export const getTefConfigHelper = () => {
  try {
    let tefConfig = localStorage.getItem(TEF_CONFIG);

    if (!tefConfig || tefConfig === "undefined") {
      tefConfig = "{}";
    }

    const params = JSON.parse(tefConfig);

    return params;
  } catch (error) {
    return {};
  }
};

export const setTEFActivationStatus = (tefStatus = false) => {
  try {
    let tefConfig = localStorage.getItem(TEF_CONFIG);

    if (!tefConfig || tefConfig === "undefined") {
      tefConfig = "{}";
    }

    const params = JSON.parse(tefConfig);

    params.tefStatus = tefStatus;

    localStorage.setItem(TEF_CONFIG, JSON.stringify(params));

    return params;
  } catch (error) {
    return {};
  }
};

export const setTEFPrintViaStatus = (printVia = true) => {
  try {
    let tefConfig = localStorage.getItem(TEF_CONFIG);

    if (!tefConfig || tefConfig === "undefined") {
      tefConfig = "{}";
    }

    const params = JSON.parse(tefConfig);

    params.printVia = printVia;

    localStorage.setItem(TEF_CONFIG, JSON.stringify(params));

    return params;
  } catch (error) {
    return {};
  }
};
