import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { startOfDay, endOfDay, format, parseISO } from "date-fns";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getListCanceledItems } from "../../../../services/orderService";
import { PagesEnum } from "../../../constants/pagesEnum";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import { currencyString } from "../../../helpers/masks";
import { useWrapperRouters } from "../../../hook/wrapperRoutersHook";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import { Period } from "../../../components/period/period";
import {
  ContainerTable,
  DirectionalArrow,
  DirectionalArrowsContainer,
  PeriodWrapper,
} from "./style";
import { useLoading } from "../../../hook/loadingHook";
import { isSmartPos } from "../../../../aaone/configuration";
import { ItemsSmartPos } from "./ItemsSmartPos";
import classNames from "classnames";
import { CardContentSmartPos } from "../../../components/CardContentSmartPos";

const filters = {
  initialDate: new Date(),
  finalDate: new Date(),
};

const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 40;

const CanceledItems = () => {
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { setShowLoading } = useLoading();
  const navigate = useNavigate();

  const title = "Relatório de Itens Cancelados";

  const autoGroupColumnDef = {
    floatingFilter: true,
    filter: "agTextColumnFilter",
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      width: 100,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
    };
  }, []);

  const columns = [
    {
      headerName: "Data",
      field: "createdAt",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Item",
      field: "productName",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Quantidade",
      field: "quantity",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Valor Unitário",
      field: "productPrice",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Valor Total",
      field: "subTotal",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Responsável",
      field: "managerName",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Operador",
      field: "sellerName",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
  ];

  const [listReport, setListReport] = React.useState([]);
  const [pinnedRowData, setPinnedRowData] = React.useState([]);
  const periodRef = React.useRef(null);
  const agGridRef = React.useRef(null);

  const handleSearch = async () => {
    try {
      setShowLoading();
      setListReport([]);
      setPinnedRowData([]);

      const initialDate =
        format(startOfDay(filters.initialDate), "yyyy-MM-dd") + " 00:00:00Z";
      const finalDate =
        format(endOfDay(filters.finalDate), "yyyy-MM-dd") + " 23:59:59Z";
      const response = await getListCanceledItems({
        initialDate,
        finalDate,
      });

      let total = {
        productPrice: 0,
        subTotal: 0,
      };

      let resFormatted = response.map((item) => {
        const createdAt = parseISO(item.createdAt);
        total.productPrice += item.productPrice;
        total.subTotal += item.subTotal;

        return {
          ...item,
          createdAt: format(createdAt, "dd/MM/yyyy HH:mm:ss"),
          productPrice: currencyString(item.productPrice),
          subTotal: currencyString(item.subTotal),
        };
      });

      setListReport(resFormatted);
      setPinnedRowData([
        {
          ...total,
          productPrice: currencyString(total.productPrice),
          subTotal: currencyString(total.totalSales),
        },
      ]);

      setListReport(response);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  const scrollGrid = (direction) => {
    if (agGridRef.current?.api?.getDisplayedRowCount() === 0) return;

    let nodeIndex = 0;
    const lastRowIndex = agGridRef.current.api?.getDisplayedRowCount() - 1;

    const displayedRows =
      Math.floor(
        agGridRef.current.api?.["gridBodyCon"].eBodyViewport.clientHeight /
          ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = agGridRef.current.api?.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = agGridRef.current.api?.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    if (agGridRef.current.api) {
      agGridRef.current.api.ensureIndexVisible(nodeIndex);
    }
  };

  useEffect(() => {
    setVisibleSidebarCart(false);
  });

  useEffect(() => {
    return () => {
      filters.initialDate = new Date();
      filters.finalDate = new Date();
    };
  }, []);

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  const itemsSerializedSmartPos = listReport.map((item) => {
    const columnSerialized = columns.map((currentColumn) => ({
      label: currentColumn.headerName ?? null,
      key: currentColumn?.field ?? null,
    }));

    const returnSerialized = columnSerialized.map((currentItemList) => ({
      label: currentItemList.label,
      children: item[currentItemList.key],
    }));

    return {
      onClick: () => {
        this.props.onHandleSelectPdv(item);
      },
      list: returnSerialized,
    };
  });

  return (
    <Card
      title={title}
      handleClose={handleClose}
      closeEnable
      fullHeight
      className=""
      id="cardList"
    >
      <PeriodWrapper className={classNames({ isSmartPos })}>
        <Period
          ref={periodRef}
          object={filters}
          fieldStart="initialDate"
          fieldEnd="finalDate"
        />

        <Button className="btn-pronto" onClick={handleSearch}>
          <div>
            <i className="material-icons">search</i>
            Pesquisar
          </div>
        </Button>
      </PeriodWrapper>
      <ContainerTable
        id="containerTable"
        className={classNames({ isSmartPos })}
      >
        {!isSmartPos && (
          <DirectionalArrowsContainer>
            <DirectionalArrow className="top" onClick={() => scrollGrid("top")}>
              <span className="material-icons">keyboard_arrow_up</span>
            </DirectionalArrow>

            <DirectionalArrow
              className="bottom"
              onClick={() => scrollGrid("bottom")}
            >
              <span className="material-icons">keyboard_arrow_down</span>
            </DirectionalArrow>
          </DirectionalArrowsContainer>
        )}
        <div className="ag-theme-balham pdv">
          {isSmartPos ? (
            <CardContentSmartPos items={itemsSerializedSmartPos} />
          ) : (
            <AgGridReact
              rowData={listReport}
              localeText={agGridLocalePtBr}
              autoGroupColumnDef={autoGroupColumnDef}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              ref={agGridRef}
              rowHeight={ROW_HEIGHT}
              headerHeight={HEADER_HEIGHT}
              rowStyle={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: "15px",
                wordBreak: "break-word",
              }}
              onGridReady={({ api }) => {
                api.sizeColumnsToFit();
              }}
              pinnedBottomRowData={pinnedRowData}
            >
              {columns.map((column) => {
                const cellStyle = {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  lineHeight: "30px",
                  wordBreak: "break-word",
                };

                return (
                  <AgGridColumn
                    key={column.field}
                    cellStyle={cellStyle}
                    field={column.field}
                    headerName={column.headerName}
                  />
                );
              })}
            </AgGridReact>
          )}
        </div>
      </ContainerTable>
    </Card>
  );
};

export default CanceledItems;
