import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRetailerId } from "../../../../aaone/configuration";
import { getHourlyReportService } from "../../../../services/orderService";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { Period } from "../../../components/period/period";
import { PagesEnum } from "../../../constants/pagesEnum";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import { formatDateHelper } from "../../../helpers/formatDateHelper";
import { currencyString } from "../../../helpers/masks";
import { useWrapperRouters } from "../../../hook/wrapperRoutersHook";
import { useLoading } from "../../../hook/loadingHook";
import {
  ContainerTable,
  DirectionalArrow,
  DirectionalArrowsContainer,
  PeriodWrapper,
} from "./style";

const filters = {
  period: new Date(),
};

const ROW_HEIGHT = 55;
const HEADER_HEIGHT = 40;

const ReportSalesDaily = () => {
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { setShowLoading } = useLoading();
  const navigate = useNavigate();

  const title = "Relatório de Vendas por Hora";
  const columns = [
    { headerName: "Intervalo", field: "interval", resizable: false },
    { headerName: "Qt. Produtos", field: "totalProducts", resizable: false },
    { headerName: "Vendas", field: "totalSales", resizable: false },
    { headerName: "TC", field: "totalCoupon", resizable: false },
    { headerName: "TM", field: "averageTicket", resizable: false },
  ];

  const [listReport, setListReport] = React.useState([]);
  const [pinnedRowData, setPinnedRowData] = React.useState([]);
  const periodRef = React.useRef(null);
  const agGridRef = React.useRef(null);

  useEffect(() => {
    setVisibleSidebarCart(false);
  });

  useEffect(() => {
    return () => {
      filters.period = new Date();
      filters.initialDate = new Date();
      filters.finalDate = new Date();
    };
  }, []);

  const handleSearch = async () => {
    try {
      setShowLoading();
      const res = await getHourlyReportService({
        retailerId: getRetailerId(),
        createdAt: formatDateHelper(filters.period, "yyyy-MM-dd"),
      });

      let total = {
        interval: "Total",
        totalProducts: 0,
        totalCoupon: 0,
        averageTicket: 0,
        totalSales: 0,
      };

      let resFormatted = res.map((item) => {
        total.totalProducts += item.totalProducts;
        total.totalCoupon += item.totalCoupon;
        total.totalSales += item.totalSales;

        const totalProductsFormatted = item.totalProducts.toString().replace('.', ',');

        return {
          ...item,
          totalProducts: totalProductsFormatted,
          totalSales: currencyString(item.totalSales),
          averageTicket: currencyString(item.averageTicket),
        };
      });

      setListReport(resFormatted);
      setPinnedRowData([
        {
          ...total,
          totalSales: currencyString(total.totalSales),
          averageTicket: currencyString(
            total.totalSales / total.totalCoupon || 0
          ),
        },
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  const scrollGrid = (direction) => {
    if (agGridRef.current?.api?.getDisplayedRowCount() === 0) return;

    let nodeIndex = 0;
    const lastRowIndex = agGridRef.current.api?.getDisplayedRowCount() - 1;

    const displayedRows =
      Math.floor(
        agGridRef.current.api?.["gridBodyCon"].eBodyViewport.clientHeight /
          ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = agGridRef.current.api?.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = agGridRef.current.api?.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    if (agGridRef.current.api) {
      agGridRef.current.api.ensureIndexVisible(nodeIndex);
    }
  };

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  return (
    <Card
      title={title}
      handleClose={handleClose}
      closeEnable
      fullHeight
      className=""
      id="cardList"
    >
      <PeriodWrapper>
        <div className="periodSelect">
          <Period
            ref={periodRef}
            object={filters}
            showFieldEnd={false}
            labelStart="Periodo"
            fieldStart="period"
          />
        </div>
        <Button
          id="confirmar"
          className="btn-pronto"
          type="submit"
          onClick={handleSearch}
        >
          <div>
            <i className="material-icons">search</i>
            Pesquisar
          </div>
        </Button>
      </PeriodWrapper>
      <ContainerTable id="containerTable">
        <DirectionalArrowsContainer>
          <DirectionalArrow className="top" onClick={() => scrollGrid("top")}>
            <span className="material-icons">keyboard_arrow_up</span>
          </DirectionalArrow>

          <DirectionalArrow
            className="bottom"
            onClick={() => scrollGrid("bottom")}
          >
            <span className="material-icons">keyboard_arrow_down</span>
          </DirectionalArrow>
        </DirectionalArrowsContainer>
        <div
          className="ag-theme-balham pdv"
          style={{ width: `calc(100% - 40px)` }}
        >
          <AgGridReact
            rowData={listReport}
            localeText={agGridLocalePtBr}
            ref={agGridRef}
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            rowStyle={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              lineHeight: "15px",
              wordBreak: "break-word",
            }}
            onGridReady={({ api }) => {
              api.sizeColumnsToFit();
            }}
            pinnedBottomRowData={pinnedRowData}
          >
            {columns.map((column) => {
              const cellStyle = {
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                lineHeight: "15px",
                wordBreak: "break-word",
              };

              return (
                <AgGridColumn
                  key={column.field}
                  cellStyle={cellStyle}
                  field={column.field}
                  headerName={column.headerName}
                />
              );
            })}
          </AgGridReact>
        </div>
      </ContainerTable>
    </Card>
  );
};

export default ReportSalesDaily;
