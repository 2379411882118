import * as React from "react";
import { isSmartPos } from "../../../aaone/configuration";

export const LogoPDV = ({ isLight = false, isHideName = false, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isHideName ? 37.57 : 122.57}
      height={24.664}
      viewBox={isHideName ? "0 0 32.57 24.664" : "0 0 122.57 24.664"}
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1={0.16}
          y1={1.072}
          x2={0.763}
          y2={0.329}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff411f" />
          <stop offset={1} stopColor="#ffbc00" />
        </linearGradient>
      </defs>
      {!isHideName && (
        <g data-name="Group 1558" fill={isLight ? "#fff" : "#000"}>
          <path
            data-name="Path 1230"
            d="M113.794 31.537V27.8a.809.809 0 01.275-.668.956.956 0 011.257 0 .809.809 0 01.275.668v10.142a3.851 3.851 0 01-7.7 0V34.6a3.891 3.891 0 013.851-3.851 2.95 2.95 0 012.042.788zm-.589 1.611a2.043 2.043 0 00-3.5 1.454v3.34a1.955 1.955 0 00.595 1.458 2.043 2.043 0 003.5-1.454V34.6a2.018 2.018 0 00-.6-1.452z"
            transform="translate(-15.77 -12.431) translate(51.972 18.117) translate(-107.9 -26.9)"
          />
          <path
            data-name="Path 1231"
            d="M143.02 40.651v1.257a.942.942 0 01-.9.9h-5.03v1.179a2.012 2.012 0 00.668 1.454 2.164 2.164 0 001.572.629 2.37 2.37 0 001.729-.668.879.879 0 01.707-.432.782.782 0 01.629.275 1.012 1.012 0 01.275.629.828.828 0 01-.2.511.736.736 0 01-.236.275 3.906 3.906 0 01-2.986 1.179 4.118 4.118 0 01-2.829-1.14 3.506 3.506 0 01-1.218-2.711v-3.34a3.834 3.834 0 016.562-2.711 3.759 3.759 0 011.257 2.714zm-1.769.349v-.354a1.955 1.955 0 00-.589-1.454 2.043 2.043 0 00-3.5 1.454V41z"
            transform="translate(-15.77 -12.431) translate(51.972 18.117) translate(-124.473 -32.91)"
          />
          <path
            data-name="Path 1232"
            d="M168.633 47.431v-.668a3.048 3.048 0 01-2.083.786 3.891 3.891 0 01-3.85-3.849v-2.949a3.851 3.851 0 017.7 0v6.719a3.891 3.891 0 01-3.851 3.851 4.6 4.6 0 01-1.847-.393 2.821 2.821 0 01-1.375-1.1.925.925 0 01-.2-.511.942.942 0 01.9-.9.794.794 0 01.707.432 2.019 2.019 0 001.729.668 1.955 1.955 0 001.454-.589 2.233 2.233 0 00.716-1.497zm0-3.772v-2.947a1.955 1.955 0 00-.589-1.454 2.043 2.043 0 00-3.5 1.454v2.947a1.955 1.955 0 00.589 1.454 2.088 2.088 0 002.908 0 2.117 2.117 0 00.592-1.454z"
            transform="translate(-15.77 -12.431) translate(51.972 18.117) translate(-141.167 -32.971)"
          />
          <path
            data-name="Path 1233"
            d="M198.3 38.1v6.091a3.851 3.851 0 01-7.7 0V38.1a.809.809 0 01.275-.668.956.956 0 011.257 0 .81.81 0 01.275.668v6.091a1.955 1.955 0 00.593 1.457 2.043 2.043 0 003.5-1.454V38.1a.809.809 0 01.275-.668.956.956 0 011.257 0 .809.809 0 01.268.668z"
            transform="translate(-15.77 -12.431) translate(51.972 18.117) translate(-158.104 -33.153)"
          />
          <path
            data-name="Path 1234"
            d="M224.416 44.5a2.812 2.812 0 01-1.1 2.318 4.049 4.049 0 01-2.593.864 4.689 4.689 0 01-1.729-.354 2.661 2.661 0 01-1.3-1.022.939.939 0 01-.2-.55.942.942 0 01.9-.9.94.94 0 01.707.393 1.906 1.906 0 001.572.668 2.248 2.248 0 001.415-.432 1.229 1.229 0 00.511-.982 1.246 1.246 0 00-.511-1.022 4.781 4.781 0 00-1.257-.707c-.472-.2-.982-.393-1.493-.589a2.765 2.765 0 01-1.257-.982 2.511 2.511 0 01-.511-1.65 2.581 2.581 0 01.982-2.161 3.644 3.644 0 012.279-.786 4.014 4.014 0 011.729.432 3.453 3.453 0 011.336 1.14 1.114 1.114 0 01.157.511.782.782 0 01-.275.629 1.025 1.025 0 01-.668.275.879.879 0 01-.707-.432 1.765 1.765 0 00-1.532-.786 1.61 1.61 0 00-1.1.354 1.021 1.021 0 00.118 1.69 12.193 12.193 0 001.257.668c.472.2.982.432 1.493.629a2.526 2.526 0 011.257 1.061 3.1 3.1 0 01.52 1.723z"
            transform="translate(-15.77 -12.431) translate(51.972 18.117) translate(-174.434 -32.788)"
          />
          <path
            data-name="Path 1235"
            d="M247.6 42.22a.967.967 0 01-.511.747 3.448 3.448 0 01-2.122.707 3.123 3.123 0 01-2.2-.825 2.764 2.764 0 01-.9-2.083v-6.13H240.6a.809.809 0 01-.668-.275.956.956 0 010-1.257.809.809 0 01.668-.275h1.257V30.9a.809.809 0 01.275-.668.956.956 0 011.257 0 .809.809 0 01.275.668v1.925h2.836a.809.809 0 01.668.275.956.956 0 010 1.257.809.809 0 01-.668.275h-2.829v6.169a1.168 1.168 0 00.314.786 1.2 1.2 0 00.943.314 2.048 2.048 0 00.982-.314 1.99 1.99 0 01.747-.314.839.839 0 01.668.314.689.689 0 01.275.633z"
            transform="translate(-15.77 -12.431) translate(51.972 18.117) translate(-187.91 -28.782)"
          />
        </g>
      )}
      {!isHideName && (
        <g data-name="Group 1559" fill={isLight ? "#fff" : "#000"}>
          <path
            data-name="Path 1236"
            d="M283.008 47.754v2.4a2.554 2.554 0 11-5.108 0v-2.4a2.556 2.556 0 014.362-1.808 2.373 2.373 0 01.746 1.808zm-.786 2.4v-2.4a1.72 1.72 0 00-.511-1.257 1.768 1.768 0 00-3.026 1.257v2.4a1.72 1.72 0 00.511 1.257 1.8 1.8 0 002.515 0 1.72 1.72 0 00.511-1.26z"
            transform="translate(-15.77 -12.431) translate(118.771 25.268) translate(-277.9 -45.161)"
          />
          <path
            data-name="Path 1237"
            d="M301.408 47.754v4.44a.306.306 0 01-.118.275.393.393 0 01-.668-.275v-4.44a1.72 1.72 0 00-.511-1.257 1.768 1.768 0 00-3.026 1.257v4.44a.306.306 0 01-.118.275.393.393 0 01-.668-.275v-4.44a2.556 2.556 0 014.362-1.808 2.373 2.373 0 01.747 1.808z"
            transform="translate(-15.77 -12.431) translate(118.771 25.268) translate(-289.07 -45.161)"
          />
          <path
            data-name="Path 1238"
            d="M319.808 47.693v.9a.4.4 0 01-.393.393h-3.89v1.1a1.675 1.675 0 00.55 1.218 1.9 1.9 0 001.336.511 1.651 1.651 0 001.454-.629.4.4 0 01.707.236.559.559 0 01-.079.236 2.145 2.145 0 01-.943.707 3.153 3.153 0 01-1.179.236 2.687 2.687 0 01-2.672-2.554v-2.4a2.556 2.556 0 014.362-1.808 2.685 2.685 0 01.747 1.854zm-.786.511v-.511a1.72 1.72 0 00-.511-1.257 1.66 1.66 0 00-1.257-.511 1.72 1.72 0 00-1.257.511 1.66 1.66 0 00-.511 1.257v.507z"
            transform="translate(-15.77 -12.431) translate(118.771 25.268) translate(-300.24 -45.1)"
          />
        </g>
      )}
      <path
        data-name="Path 1239"
        d="M44.479 25.6c2.593-2.554 3.026-6.169 1.022-8.173a4.559 4.559 0 00-4.126-1.179 7.77 7.77 0 00-4.047 2.2 7.04 7.04 0 00-2.24 5.423l-2.358 2.355-.63-.626-.079-.079-8.521-8.53a2.111 2.111 0 00-2.986 0 7.439 7.439 0 00-1.925 3.261 5.33 5.33 0 001.493 5.03l4.48 4.362a2.111 2.111 0 002.986 0l.354-.354.864.864-4.087 4.087a2.835 2.835 0 003.89 4.126l.118-.118 4.087-4.087 4.165 4.087a2.834 2.834 0 004.008-4.008l-4.165-4.087 2.318-2.317a7.1 7.1 0 005.379-2.237z"
        transform="translate(-15.77 -12.431) translate(-1.623 -2.254)"
        fill="url(#a)"
      />
      <path
        data-name="Path 1240"
        d="M47.852 48.407l-3.576-3.5 1.454-1.454a6.232 6.232 0 002.083-.314.854.854 0 00.55-1.061.838.838 0 00-1.061-.55 4.193 4.193 0 01-1.847.2 1.067 1.067 0 00-.668.236l-2.318 2.318-3.969 4.047-4.126 4.087-.079.079a1.974 1.974 0 11-2.711-2.868.834.834 0 00-1.14-1.218 3.669 3.669 0 105.069 5.3l.118-.118 1.886-1.886 1.022-1.022.589-.589 3.576 3.5a3.654 3.654 0 105.148-5.187zm-1.179 4.008a1.945 1.945 0 01-2.79 0l-3.576-3.5 2.79-2.79 3.576 3.5a1.98 1.98 0 01.001 2.79z"
        transform="translate(-15.77 -12.431) translate(-8.219 -17.638)"
        fill={isLight ? "#fff" : "#000"}
      />
      <path
        data-name="Path 1241"
        d="M44.152 13.951a5.57 5.57 0 00-4.872-1.415 8.282 8.282 0 00-4.48 2.436 8.2 8.2 0 00-2.515 5.658l-1.493 1.493-8.566-8.605a2.965 2.965 0 00-4.165 0 7.638 7.638 0 00-2.122 3.654 6.021 6.021 0 001.729 5.816l4.4 4.4a2.941 2.941 0 004.165 0l.354-.354a.834.834 0 00-1.179-1.179l-.354.354a1.282 1.282 0 01-1.768 0l-4.48-4.44a4.421 4.421 0 01-1.257-4.2A5.95 5.95 0 0119.24 14.7a1.282 1.282 0 011.768 0l6.13 6.091v.039l4.44 4.44a.866.866 0 00.589.236.8.8 0 00.589-.236.855.855 0 000-1.179l-.786-.786 1.768-1.768a.831.831 0 00.236-.668 6.307 6.307 0 012-4.755 6.736 6.736 0 013.576-1.965 3.916 3.916 0 013.379.943 3.746 3.746 0 01.982 3.183 6.764 6.764 0 01-1.965 3.812.834.834 0 001.179 1.179 8.473 8.473 0 002.476-4.794 5.093 5.093 0 00-1.449-4.521z"
        fill={isLight ? "#fff" : "#000"}
        transform="translate(-15.77 -12.431)"
      />
    </svg>
  );
};
