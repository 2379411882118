import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCart } from "../../hook/cartHook";
import { PagesEnum } from "../../constants/pagesEnum";
import { Container } from "../paymentMethods/styles";
import { Wrapper } from "./style";
import {
  DATA_USER_AUTH_KEY_NAME,
  PDV_ORDER_AUTHORIZATION_STATUS,
} from "../../constants/keyNamesSessionStorage";
import {
  FISCAL_STATUS_APPROVED,
  FISCAL_STATUS_CONNECTION_ERROR,
  FISCAL_STATUS_CONTINGENCY,
} from "../../constants/fiscalStatus";
import { saleInProgressUpdateStatus } from "../../../services/saleInProgressStatus";
import { useCashdesk } from "../../hook/cashdeskHook";
import { openDrawer } from "../../helpers/openDrawer";
import {
  confirmTEFPayments,
  undoTEFPayments,
} from "../../helpers/paymentHelper";
import { useLoading } from "../../hook/loadingHook";
import { apiDegustOneOrder } from "../../../services/api";
import { printerActionService } from "../../../services/actions/printerActionService";
import { sendToProductionPrinter } from "../../../services/productionPrinterService";
import { useDialog } from "../../hook/dialogHook";
import { getLocalPayments } from "../../helpers/multiPaymentsHelper";
import { currencyMask } from "../../helpers/masks";
import { isSmartPos } from "../../../aaone/configuration";
import classNames from "classnames";

let timeout;

const TEXT_DEFAULT = "Finalizado com sucesso";
const TEXT_BUTTON_BACK_DEFAULT = "Voltar para início";
const IMG_DEFAULT = "./pdv-one/venda-checked.svg";
const TEXT_CONTINGENCY = "Venda efetuada em contingência";
const TEXT_BUTTON_BACK_ERROR = "Cancelar Venda";
const TEXT_ERROR =
  "Venda finalizada com rejeição na nota.\nAjuste os dados e realize o reenvio da nota no monitor de notas";
const TEXT_ERROR_CONN = (
  <>
    Venda não finalizada por erro de conexão.
    <br />A venda não foi integrada para a cozinha e os pagamentos serão
    estornados.
  </>
);
let timeOutSeconds = 6000;

const IMG_ERROR = "./pdv-one/venda-error.svg";
let orderStatus = {};

export const PaymentSuccessfulPage = () => {
  const { setShowLoading } = useLoading();
  const [text, setText] = useState(TEXT_DEFAULT);
  const [textButtonBack, setTextButtonBack] = useState(
    TEXT_BUTTON_BACK_DEFAULT
  );
  const [img, setImg] = useState(IMG_DEFAULT);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const { showAlert } = useDialog();
  // const [orderStatus, setOrderStatus] = useState({});

  const operator = JSON.parse(sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME));

  const { clearCart } = useCart();

  const [tableNumber, setTableNumber] = useState("");

  const navigate = useNavigate();
  const cashdesk = useCashdesk();

  const handleGoToHome = useCallback(() => {
    if (!orderStatus.isSuccess && !FISCAL_STATUS_CONTINGENCY.includes(orderStatus?.status)) {
      const allPayments = getLocalPayments().filter(
        (payment) => payment.details?.controlCode
      );

      setShowLoading(true, "Aguarde, verificando e desfazendo pagamento(s).");
      // Desfaz os pagamentos em caso de erro
      undoTEFPayments(() => {
        if (allPayments.length) {
          showAlert({
            message: (
              <>
                Os pagamentos abaixo foram cancelados.
                {allPayments.length ? (
                  <>
                    {allPayments
                      .filter((payment) => payment.details.controlCode)
                      .map((payment) => (
                        <>
                          <br />
                          NSU: {payment.details.controlCode} | Valor:{" "}
                          {currencyMask(payment.details.amountPaid)}
                        </>
                      ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ),
          });
        }

        setShowLoading(false);
        clearCart();
        navigate(PagesEnum.HOME);
      });
    } else {
      clearCart();
      navigate(PagesEnum.HOME);

      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [setShowLoading, clearCart, navigate]);

  const handleTryAgain = useCallback(
    (orderId) => {
      try {
        setShowLoading(true, "Aguarde, retransmitindo a nota");

        apiDegustOneOrder
          .post("/api/order/createOrder", {
            orderId,
            ...orderStatus.bodyOrder,
          })
          .then(({ data }) => {
            if (data.fiscalResult?.isSuccess) {
              setText(TEXT_DEFAULT);
              setImg(IMG_DEFAULT);
              setTextButtonBack(TEXT_BUTTON_BACK_DEFAULT);
              setShowTryAgain(false);
              orderStatus = {
                ...orderStatus,
                status: data.status,
                isSuccess: data.fiscalResult?.isSuccess,
                reason: data.fiscalResult?.reason,
              };

              sendToProductionPrinter(data.orderId);

              printerActionService({
                saleControl: orderStatus.saleControl,
                danfeUrl: data.danfeUrlFiscalFlow
                  ? data.danfeUrlFiscalFlow
                  : null,
                danfeBase64: data.danfeBase64 ? data.danfeBase64 : null,
              });

              confirmTEFPayments();

              timeout = setTimeout(handleGoToHome, timeOutSeconds);
            }

            setShowLoading(false);
          });

        if (timeout) {
          clearTimeout(timeout);
        }
      } catch (error) {
        setShowLoading(false);

        if (window.Android) {
          console.log(`error on handleTryAgain => ${JSON.stringify(error)}`);
        } else {
          console.error(`error on handleTryAgain => ${error}`);
        }
      }
    },
    [handleGoToHome, setShowLoading]
  );

  useEffect(() => {
    orderStatus = JSON.parse(
      sessionStorage.getItem(PDV_ORDER_AUTHORIZATION_STATUS) ?? "{}"
    );

    const tableSelectedInHome = JSON.parse(
      sessionStorage.getItem("tableSelected")
    );
    setTableNumber(tableSelectedInHome);
    if (tableSelectedInHome) {
      saleInProgressUpdateStatus({
        newStatus: "FINISHED",
        saleInProgressId: tableSelectedInHome?.saleInProgressId,
        cashDeskId: cashdesk?.cashdesk?.id,
        sellerReferenceIdFinished: operator.employeeCode,
        sellerNameFinished: operator.login,
        payments: {},
      });
    }
    sessionStorage.removeItem("tableSelected");
    sessionStorage.removeItem("customersSelected");
    window.sendingToFinishPayment = false;

    if (FISCAL_STATUS_CONTINGENCY.includes(orderStatus?.status)) {
      setText(TEXT_CONTINGENCY);
      setImg(IMG_DEFAULT);

      clearCart();
    } else if (FISCAL_STATUS_CONNECTION_ERROR.includes(orderStatus?.status)) {
      setText(
        <>
          {TEXT_ERROR_CONN}
          <br />
          <div className="subtext">
            {orderStatus.status} - {orderStatus.reason}
          </div>
        </>
      );
      setImg(IMG_ERROR);
      setTextButtonBack(TEXT_BUTTON_BACK_ERROR);
      setShowTryAgain(true);
    } else if (!FISCAL_STATUS_APPROVED.includes(orderStatus?.status)) {
      setText(
        <>
          {TEXT_ERROR}
          <br />
          <div className="subtext">
            {orderStatus.status} - {orderStatus.reason}
          </div>
        </>
      );
      setImg(IMG_ERROR);
      setTextButtonBack(TEXT_BUTTON_BACK_ERROR);
      setShowTryAgain(true);

      // clearCart();
    } else if (FISCAL_STATUS_APPROVED.includes(orderStatus?.status)) {
      timeout = setTimeout(handleGoToHome, timeOutSeconds);

      //Abre a gaveta
      openDrawer();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Container>
      <div className="loading__container">
        <Wrapper className={classNames({ isSmartPos })}>
          <div className="image">
            <img src={img} alt="" />
          </div>
          <div className="text">{text}</div>
          <div className="action-buttons">
            {showTryAgain && (
              <button onClick={() => handleTryAgain(orderStatus.orderId)}>
                Tentar novamente
              </button>
            )}
            <button onClick={handleGoToHome}>{textButtonBack}</button>
          </div>
        </Wrapper>
      </div>
    </Container>
  );
};
