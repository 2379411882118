import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "./styles";
import { MdArrowBack } from "react-icons/md";
import { useCart } from "../../../hook/cartHook";
import { KeyboardNumeric } from "../../KeyboardNumeric";
import { maskCPFHelper } from "../../../helpers/maskCPFHelper";
import { isCNPJValidHelper } from "../../../helpers/isCNPJValidHelper";
import { isCPFValidHelper } from "../../../helpers/isCPFValidHelper";
import { escOnlyNumbersHelper } from "../../../helpers/escOnlyNumbersHelper";
import { maskCNPJHelper } from "../../../helpers/maskCNPJHelper";
import {
  CPF_INVOICE_KEY_NAME,
  CPF_RESHOP_KEY_NAME,
} from "../../../constants/keyNamesSessionStorage";
import { PagesEnum } from "../../../constants/pagesEnum";
import { isSmartPos } from "../../../../aaone/configuration";

let firstLoaded = false;

export const ModalCPFInInvoice = ({ handleClose }) => {
  const navigate = useNavigate();
  const { handleOpenModalToEat } = useCart();
  const inputRef = useRef(null);

  const [cpfFidelity] = useState(sessionStorage.getItem(CPF_RESHOP_KEY_NAME));

  const [keyboardActions, setKeyboardActions] = useState();

  // IsValid
  const [isInputValid, setIsInputValid] = useState(false);

  const handleCloseOrBack = useCallback(() => {
    firstLoaded = false;
    handleClose();
    handleOpenModalToEat();
  }, [handleOpenModalToEat]);

  const handleSubmitCPFToInvoice = useCallback((e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const cpf = data.get("cpf") || "";

    sessionStorage.setItem(CPF_INVOICE_KEY_NAME, String(cpf).trim());

    firstLoaded = false;

    handleClose();
    navigate(PagesEnum.PAYMENT_METHODS);
  }, []);

  useEffect(() => {
    if (keyboardActions?.value.length >= 0 && inputRef?.current) {
      const value = escOnlyNumbersHelper(keyboardActions?.value ?? "");

      let serializedValue = value;

      const isCPFValid = isCPFValidHelper(value);
      const isCNPJValid = isCNPJValidHelper(value);

      if (!isCNPJValid && !isCPFValid) {
        setIsInputValid(false);
        serializedValue = escOnlyNumbersHelper(value);

        //TODO - Adicionar flag de error;
      }

      if (isCPFValid) {
        serializedValue = maskCPFHelper(serializedValue);
      }

      if (serializedValue.length >= 14 && !isCPFValid) {
        serializedValue = maskCNPJHelper(serializedValue);
      }

      if (isCPFValid || isCNPJValid) {
        setIsInputValid(true);
      }

      const setDocNumber = (docNumber) => {
        inputRef.current.value = docNumber;
        keyboardActions.set(docNumber);
      };

      if (serializedValue) {
        setDocNumber(serializedValue);
      } else {
        if (cpfFidelity) {
          setDocNumber(firstLoaded ? "" : maskCPFHelper(cpfFidelity));
          firstLoaded = true;
        } else {
          setDocNumber("");
          setIsInputValid(false);
        }
      }
    }
  }, [keyboardActions?.value]);

  return (
    <Container>
      <div className="content__wrapper">
        <header className="header">
          <div className="title">CPF/CNPJ na nota?</div>
          <button onClick={handleCloseOrBack} className="button__close">
            <MdArrowBack size="1rem" />
          </button>
        </header>
        <form
          onSubmit={handleSubmitCPFToInvoice}
          className="content__container"
        >
          <div className="input__container">
            <input
              type="text"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              role="presentation"
              readOnly
              autoFocus={!isSmartPos}
              onFocus={(e) => {
                if(!isSmartPos){
                  e.currentTarget.focus();
                  e.currentTarget.removeAttribute("readOnly");
                }
              }}
              onBlur={!isSmartPos ? (e) => e.currentTarget.focus() : undefined}
              onChange={(e) => {
                if (keyboardActions?.set) {
                  keyboardActions.set(e.target.value);
                }

                return e;
              }}
              ref={inputRef}
              name="cpf"
              id="cpf"
              placeholder="Informe o CPF/CNPJ"
            />
          </div>
          <KeyboardNumeric
            newButtons={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              {
                className: "doubleZero",
                value: "00",
              },
              "0",
              {
                className: "backspace",
                value: "←",
              },
            ]}
            setActions={setKeyboardActions}
          />
          <div className="buttons__container">
            <button
              disabled={keyboardActions?.value.length > 0 && !isInputValid}
              type="submit"
              className="button button__submit"
            >
              Pronto
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
