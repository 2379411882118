export const LayoutCustomizationItem = (items) => {
  const customizations = [];

  if (!!items.length) {
    const layoutItemDefault = (item) => {
      return `${item.qtd || 1} ${item.description || item.name}`;
    }

    const layoutItemsAdded = (item) =>
      `+${item.qtd || 1} ${item.description || item.name}`;

    const layoutItemsRemoved = (item) =>
      `SEM ${item.description || item.name}`;

    items.map((item) => {
      if (item.isProductComposition !== undefined) {
        if (item.isProductComposition.type === "WITHDRAWN")
          customizations.push(layoutItemsRemoved(item));
        else
          customizations.push(layoutItemsAdded(item));
      } else {
        customizations.push(layoutItemDefault(item));
      }
    });
  }

  return customizations;
}

