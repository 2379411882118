import { apiProducts } from "./api";

/**
 * @param {*} params
 */

export const tableInProgressService = async (req) => {
  try {
    const { data } = await apiProducts.get(
      `api/sale-in-progress/all-tables/${req.CashDeskOpenDateId}`
    );

    return data;
  } catch (error) {
    console.log("Error in getHourlyReportService ::", error);
    return Promise.reject(`Error in getHourlyReportService :: ${error}`);
  }
};

