import {
  FISCAL_FLOW_ENDPOINT_KEY_NAME,
  PDV_COD_LOJA_KEY_NAME,
  SYSTEM_DATA_KEY_NAME,
} from "../../pdv-one/constants/keyNamesSessionStorage";

export const isDesktopApp = !!window.desktopApp;

if (isDesktopApp) {
  if (!sessionStorage.getItem("AA_Config")) {
    window.sessionStorage.setItem(
      "AA_Config",
      JSON.stringify({ REACT_APP_LAYOUT: "RAGAZZOEXPRESS" })
    );
    document.location.reload();
  }

  window.desktopApp.publish("machine.devices.tef.getTypeTef", {});
}

const GetConfig = JSON.parse(window.sessionStorage.getItem("AA_Config"));

export const isKiosk = !!GetConfig?.window?.kiosk ?? false;

const GetConfigDevice =
  JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {};

export const getBFFEndpoint = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_BFF_ENDPOINT;
  } else {
    return (
      GetConfigDevice?.REACT_APP_BFF_ENDPOINT ??
      "http://" + GetConfigDevice.IP_SERVIDOR + ":3334"
    );
  }
};

export const getFiscalType = () => {
  if (isDesktopApp) {
    return GetConfig.danfe?.fiscalType;
  } else {
    return GetConfigDevice?.danfe?.fiscalType;
  }
};

export const getFiscalFlowEndpointConfig = () => {
  if (isDesktopApp) {
    const { danfe } = GetConfig;

    return (
      (danfe?.fiscalType == 1 ? danfe?.MIDeUrl : danfe?.sat?.url) ??
      "http://" + getIpServer() + ":8089"
    );
  } else {
    return (
      (GetConfigDevice?.danfe?.fiscalType == 1
        ? GetConfigDevice?.danfe?.MIDeUrl
        : GetConfigDevice?.danfe?.sat?.url) ??
      "http://" + getIpServer() + ":8089"
    );
  }
};

export const getFiscalFlowEndpoint = () => {
  const customUrl = localStorage.getItem(FISCAL_FLOW_ENDPOINT_KEY_NAME);

  if (customUrl) {
    return customUrl;
  }

  return getFiscalFlowEndpointConfig();
};

export const getCashdeskEndpoint = () => {
  if (isDesktopApp) {
    return (
      GetConfig?.REACT_APP_CASHDESK_ENDPOINT ??
      "http://" + getIpServer() + ":9020"
    );
  } else {
    return (
      GetConfigDevice?.REACT_APP_CASHDESK_ENDPOINT ??
      "http://" + getIpServer() + ":9020"
    );
  }
};

export const getRoutingEndpoint = () => {
  if (isDesktopApp) {
    return (
      GetConfig?.REACT_APP_ROUTING_ENDPOINT ??
      "http://" + getIpServer() + ":9260"
    );
  } else {
    return (
      GetConfigDevice?.REACT_APP_ROUTING_ENDPOINT ??
      "http://" + getIpServer() + ":9260"
    );
  }
};

export const getHubEndpoint = () => {
  if (isDesktopApp) {
    return (
      GetConfig?.REACT_APP_SOCKETMANAGER_ENDPOINT ??
      "http://" + getIpServer() + ":9040"
    );
  } else {
    return (
      GetConfigDevice?.REACT_APP_SOCKETMANAGER_ENDPOINT ??
      "http://" + getIpServer() + ":9040"
    );
  }
};

export const getDegustOneOrderEndpoint = () => {
  if (isDesktopApp) {
    return (
      GetConfig?.REACT_APP_DEGUST_ONE_ORDER_ENDPOINT ??
      "http://" + getIpServer() + ":9050"
    );
  } else {
    return (
      GetConfigDevice?.REACT_APP_DEGUST_ONE_ORDER_ENDPOINT ??
      "http://" + getIpServer() + ":9050"
    );
  }
};

export const getDegustOneTaxEndpoint = () => {
  if (isDesktopApp) {
    return (
      GetConfig?.REACT_APP_DEGUST_ONE_TAX_ENDPOINT ??
      "http://" + getIpServer() + ":9110"
    );
  } else {
    return (
      GetConfigDevice?.REACT_APP_DEGUST_ONE_TAX_ENDPOINT ??
      "http://" + getIpServer() + ":9110"
    );
  }
};

export const getProductsAll = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_API_ENDPOINT_PRODUCTSALL;
  } else {
    return (
      GetConfigDevice?.REACT_APP_API_ENDPOINT_PRODUCTSALL ??
      "http://" + GetConfigDevice.IP_SERVIDOR + ":3333"
    );
  }
};

export const getTerminalID = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_TERMINALID;
  } else {
    return GetConfigDevice?.REACT_APP_TERMINALID ?? GetConfigDevice?.TERMINALID;
  }
};

export const getStoreServerId = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_STORE_SERVER_ID;
  } else {
    return GetConfigDevice?.REACT_APP_STORE_SERVER_ID ?? "";
  }
};

export const getStoreFranchiseId = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_STORE_FRANCHISE_ID;
  } else {
    return GetConfigDevice?.REACT_APP_STORE_FRANCHISE_ID ?? "";
  }
};

export const getStoreId = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_STORE_ID;
  } else {
    return GetConfigDevice?.REACT_APP_STORE_ID ?? "";
  }
};

export const getRetailerId = () => {
  // return (
  //   JSON.parse(
  //     sessionStorage.getItem(SYSTEM_DATA_KEY_NAME)
  //   )?.retailerId?.toLowerCase() || ""
  // );

  if (isDesktopApp) {
    return GetConfig.retailerId;
  } else {
    return GetConfigDevice?.retailerId ?? "";
  }
};

export const getPosId = () => {
  if (isDesktopApp) {
    return GetConfig.posId;
  } else {
    return GetConfigDevice?.posId ?? "";
  }
};

export const getProductionPrinterModel = () => {
  if (isDesktopApp) {
    return GetConfig.PRODUCTION_PRINTER_NAME || '';
  } else {
    return '';
  }
};

export const getPosType = () => {
  if (isDesktopApp) {
    return GetConfig.posType;
  } else {
    return GetConfigDevice?.posType ?? "";
  }
};

export const getSerieNFCe = () => {
  return (
    JSON.parse(sessionStorage.getItem(SYSTEM_DATA_KEY_NAME))?.receiptSeries || 0
  );
};

export const getReshopEndpoint = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_RESHOP_ENDPOINT;
  } else {
    return GetConfigDevice?.REACT_APP_RESHOP_ENDPOINT ?? GetConfigDevice.RESHOP;
  }
};

export const getReshopClientEndPoint = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_RESHOP_CLIENT_ENDPOINT;
  } else {
    return GetConfigDevice?.REACT_APP_RESHOP_CLIENT_ENDPOINT ?? "";
  }
};

export const getStyleAA = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_LAYOUT_STYLE;
  } else {
    return "SHOP";
  }
};

export const getPistaDrive = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_PISTA_DRIVE;
  }

  return (
    GetConfigDevice?.REACT_APP_PISTA_DRIVE ?? process.env.REACT_APP_PISTA_DRIVE
  );
};

const getCarSpace = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_VAGA_DRIVE;
  }

  return (
    GetConfigDevice?.REACT_APP_VAGA_DRIVE ?? process.env.REACT_APP_VAGA_DRIVE
  );
};

const getTimeModal = () => {
  if (isDesktopApp && GetConfig.REACT_APP_TIME_MODAL !== undefined) {
    return GetConfig.REACT_APP_TIME_MODAL;
  } else {
    return 30;
  }
};

const getTimeModalIdle = () => {
  if (isDesktopApp && GetConfig.REACT_APP_TIME_MODAL !== undefined) {
    return GetConfig.REACT_APP_TIME_MODAL_IDLE;
  } else {
    return 60;
  }
};

export const getShowOptionToEat = () => {
  if (isDesktopApp && GetConfig.SHOW_OPTIONTOEAT_PAGE !== undefined) {
    return GetConfig.SHOW_OPTIONTOEAT_PAGE;
  } else {
    return GetConfigDevice?.SHOW_OPTIONTOEAT_PAGE ?? true;
  }
};

export const getClientEndpointReshop = () => {
  if (isDesktopApp) {
    return GetConfig.REACT_APP_RESHOP_CLIENT_ENDPOINT;
  } else {
    return GetConfigDevice?.REACT_APP_RESHOP_CLIENT_ENDPOINT ?? "";
  }
};

export const getIpServer = () => {
  const hostname = window?.location?.hostname
    ? window.location.hostname
    : undefined;

  return (GetConfigDevice?.REACT_APP_IP_SERVIDOR || hostname).replace("http://", "");
};

export const getCodLoja = () => {
  const codLoja = sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME) || "";

  return codLoja;
};

export const retailerId =
  JSON.parse(sessionStorage.getItem(SYSTEM_DATA_KEY_NAME))?.retailerId || "";

export const isDisabledStartButton = !!(
  GetConfig?.DISABLED_START_BUTTON ??
  GetConfigDevice?.DISABLED_START_BUTTON ??
  false
);

export const isDisabledReShop = isDesktopApp
  ? !!(GetConfig?.DISABLED_RE_SHOP ?? false)
  : GetConfigDevice?.DISABLED_RE_SHOP ?? false;

export const isShowPagerPage = isDesktopApp
  ? !!(GetConfig?.SHOW_PAGER_PAGE ?? false)
  : GetConfigDevice?.SHOW_PAGER_PAGE ?? false;

export const isShowTellInfo = isDesktopApp
  ? !!(GetConfig?.SHOW_TELL_INFO ?? false)
  : GetConfigDevice?.SHOW_TELL_INFO ?? false;

export const showFullValue = isDesktopApp
  ? !!(GetConfig?.SHOW_FULL_VALUE ?? false)
  : GetConfigDevice?.SHOW_FULL_VALUE ?? false;

export const showFidelityPage = isDesktopApp
  ? !!(GetConfig?.SHOW_FIDELITY_PAGE ?? true)
  : GetConfigDevice?.DISABLED_RE_SHOP ?? true;

export const showPrismaPage = isDesktopApp
  ? !!(GetConfig?.SHOW_PRISMA_PAGE ?? true)
  : GetConfigDevice?.SHOW_PRISMA_PAGE ?? true;

export const showOptionName = !!(
  GetConfig?.PAGE_OPTION_NAME ??
  GetConfigDevice?.PAGE_OPTION_NAME ??
  true
);

export const showImageInVo = !!(
  GetConfig?.SHOW_IMAGE_IN_VO ??
  GetConfigDevice?.SHOW_IMAGE_IN_VO ??
  false
);

export const isShowYourNameTabletConfig =
  GetConfigDevice?.IS_SHOW_YOUR_NAME ?? true;

export const isDisableLoyaltPoints = !!(
  GetConfig?.DISABLE_LOYALTY_POINTS ?? false
);

export const isActiveBannerHeaderAndBottom = !!(
  GetConfig?.SHOW_BANNER_HEADER_AND_BOTTOM ?? false
);

export const isShowIconPaymentPix = !!(
  GetConfig?.SHOW_ICON_PIX_PAYMENT ?? false
);

export const isShowCustomCombo = !!(GetConfig?.SHOW_CUSTOM_COMBO ?? false);

export const isPDVOne = !!(
  GetConfigDevice?.IS_PDV_ONE ??
  GetConfig?.IS_PDV_ONE ??
  false
);

export const getThemeSettings =
  GetConfig?.THEME_OPTIONS ?? GetConfigDevice?.THEME_OPTIONS ?? {};

export const getConsultFidelityReshop = () => {
  if (window.desktopApp) {
    return GetConfig.REACT_APP_RESHOP_CONSULT_FIDELITY || getReshopEndpoint();
  } else {
    return (
      GetConfigDevice?.REACT_APP_RESHOP_CONSULT_FIDELITY || getReshopEndpoint()
    );
  }
};

export const layoutNameConfig =
  String(GetConfig?.LAYOUT_NAME ?? GetConfigDevice?.LAYOUT_NAME ?? "").length >
  0
    ? String(GetConfig?.LAYOUT_NAME ?? GetConfigDevice?.LAYOUT_NAME)
    : null;

export const getLayoutName = () => {
  const layoutNameInStorage = localStorage.getItem("layout") || "AAONE";

  if (window.desktopApp) {
    const layoutNameInConfig = layoutNameConfig;

    if (layoutNameInConfig) {
      localStorage.setItem("layout", layoutNameInConfig);

      return layoutNameInConfig;
    }
  }

  return layoutNameInStorage;
};

export const AgGridKey =
  "CompanyName=Linx,LicensedGroup=Degust One,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-037636,SupportServicesEnd=6_April_2024_[v2]_MTcxMjM1ODAwMDAwMA==71711d1f97c96ca398571dd196f7d32e";

export const isSmartPos = window?.mobileConfig?.HARDWARE
  ? Boolean(
      String(window?.mobileConfig?.HARDWARE).toUpperCase().trim() !== "DEFAULT"
    )
  : false;

const configuration = () => ({
  endPoint: getBFFEndpoint(),
  endPointProductsAll: getProductsAll(),
  terminalid: getTerminalID(),
  layout: localStorage.getItem("layout") || "AAONE",
  endPointReshop: getReshopEndpoint(),
  endPointReshopClient: getReshopClientEndPoint(),
  styleAA: localStorage.setItem("styleAA", getStyleAA()),
  pistaDrive: localStorage.setItem("pistaDrive", getPistaDrive()),
  carSpace: localStorage.setItem("carSpace", getCarSpace()),
  timeModal: localStorage.setItem("timeModal", getTimeModal()),
  timeModalIdle: localStorage.setItem("timeModalIdle", getTimeModalIdle()),
  showOptionToEat: localStorage.setItem(
    "showOptionToEat",
    getShowOptionToEat()
  ),
  serieNFCe: getSerieNFCe(),
  storeServerId: getStoreServerId(),
  storeFranchiseId: getStoreFranchiseId(),
  storeId: getStoreId(),
  retailerId: getRetailerId(),
});

export default configuration();
