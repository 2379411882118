import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../../helpers/mediaQueryHelper";
import noImage from "../../../../linx/no_image_one.jpg";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 8px 8px;
  grid-gap: 16px;
  gap: 16px;

  margin-right: 1rem;
  min-height: 178px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #ffeee9;
  border-radius: 0.25rem;

  flex: 1;

  &.unavailable {
    opacity: 0.5;
  }

  .picture {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    margin-bottom: 0.25rem;
    padding: 0;
    grid-gap: 8px;
    gap: 8px;

    height: 116px;
    width: 100%;

    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 2px;

    animation: none;

    &.no__image {
      background-image: url(${noImage}) !important;
    }

    flex: 1;

    .code {
      background: #eee;
      color: #797979;
      padding: 0.25rem;
      font-size: 0.85rem;
      font-weight: 700;

      &.skeleton {
        width: 50%;
      }
    }
  }

  .title {
    width: 100%;
    height: 34px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.805rem;
    /* line-height: 1.125rem; */
    text-align: center;

    color: #000000;

    overflow: hidden !important;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .price {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    color: #000;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin-top: 0.25rem;
    width: 100%;
    position: relative;

    .product-fractional {
      position: absolute;
      bottom: 0;
      right: 0;
      font-weight: 700;
    }
  }

  animation: fadeInAnimation ease-in 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .loading-container {
    background-color: rgba(255, 238, 233, 0.59);
  }
  .loading-dots-product {
    width: 100%;
    height: 118px;
    padding-left: 12px;
  }
  .title.loading {
    opacity: 0.5;
  }
  .price.loading {
    opacity: 0.5;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    &:not(.no-vertical) {
      min-height: auto;

      .picture {
        height: 68px;
        flex: none;

        .code {
          background: #fff;
          padding: 0.15rem;
          font-size: 0.75rem;
        }
      }

      .title {
        height: 44px;
      }

      .price {
        margin: 0.25rem;
      }
    }
  `)}
`;
