import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .loading__container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
  }

  .content__methods {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;

    .content__container {
      padding: 1rem 0;
    }

    .methods__wrapper {
      max-width: 600px;
      width: 100%;

      margin: 0 auto;

      .methods__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;

        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        list-style: none;

        padding: 0;
        margin: 0;

        li {
          display: flex;
          flex-direction: column;

          text-align: center;
          align-items: center;
          justify-content: center;

          background-color: #fff;
          border: 1px solid #eee;
          border-radius: 0.25rem;

          text-transform: uppercase;

          padding: 1rem 0.5rem;

          cursor: pointer;

          .icon {
            max-width: 70px;
            height: 52px;
            width: 100%;

            margin-bottom: 0.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 100%;
              max-height: 52px;
              width: 100%;
            }
          }

          &.isTestQa .icon,
          &.isTestQa {
            border-color: #00860b;
            background-color: #adff2f;

            svg {
              color: #00860b;
            }
          }

          img,
          span {
            font-size: 0.75rem;
            font-weight: 700;
          }
        }
      }

      .buttons__actions {
        margin-top: 1.5rem;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .button {
          text-align: center;
          padding: 1rem 1rem;

          font-size: 0.85rem;
          font-weight: 700;

          border-radius: 0.25rem;

          flex: 1;

          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }

          &.button__primary {
            background-color: #2e0037;
            color: #fff;
          }
        }
      }
    }
  }

  .payment__with__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .qrcode__container {
      max-height: 316px;
      max-width: 316px;

      display: none;

      &,
      > svg {
        height: 100%;
        width: 100%;
      }

      &.qrcode__active {
        border: 0.5rem solid ${({ theme }) => theme.colors.primary};
        border-radius: 1rem;
        padding: 1rem;
        display: block;
      }
    }

    .icon-payment-container {
      height: 160px;
      width: 255px;
    }
  }
`;
