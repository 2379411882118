import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Pizza } from "../../shared/components/modules";
import { addToCart } from "../../actions/cart";
import { useDispatch } from "react-redux";
import { useLayoutAa } from "../../contexts/layout";

const PizzaContainer = ({
  backStepCategory,
  flavor = [],
  product,
  cartItems,
  controlNotification,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getTranslateName } = useLayoutAa();

  const [pizza, setPizza] = useState([]);
  const [listFlavor, setListFlavor] = useState([]);

  const firstPizzaSelectionText = getTranslateName(t, "first_pizza_selection") || "ESCOLHA A PRIMEIRA PIZZA";
  const secondPizzaSelectionText = getTranslateName(t, "second_pizza_selection") || "ESCOLHA A SEGUNDA PIZZA";
  const firstFlavorSelectionText = getTranslateName(t, "first_flavor_selection") || "ESCOLHA O PRIMEIRO SABOR";
  const secondFlavorSelectionText = getTranslateName(t, "second_flavor_selection") || "ESCOLHA O SEGUNDO SABOR";
  const pizzaFlavorSelectionText = getTranslateName(t, "pizza_flavor_selection") || "ESCOLHA O SABOR DA SUA PIZZA";

  useEffect(() => {
    const list = [];
    flavor.forEach((item) => list.push(item.product));
    setListFlavor(list);
    if (product.hasOrientedSale) {
      setPizza([
        {
          title: (
            <div>
              <span
                style={{ display: "inline", color: "red", fontSize: "50px" }}
              >
                1
              </span>
              <span style={{ marginLeft: "30px" }}>
                ESCOLHA A PRIMEIRA PIZZA
              </span>
            </div>
          ),
        },
        {
          title: (
            <div>
              <span
                style={{ display: "inline", color: "red", fontSize: "50px" }}
              >
                1
              </span>
              <span style={{ marginLeft: "30px" }}>
                ESCOLHA A PRIMEIRA PIZZA
              </span>
            </div>
          ),
        },
        {
          title: (
            <div>
              <span
                style={{ display: "inline", color: "red", fontSize: "50px" }}
              >
                2
              </span>
              <span style={{ marginLeft: "30px" }}>
                ESCOLHA A SEGUNDA PIZZA
              </span>
            </div>
          ),
        },
        {
          title: (
            <div>
              <span
                style={{ display: "inline", color: "red", fontSize: "50px" }}
              >
                2
              </span>
              <span style={{ marginLeft: "30px" }}>
                ESCOLHA A SEGUNDA PIZZA
              </span>
            </div>
          ),
        },
      ]);
    }

    if (
      product.hasOrientedSale &&
      String(product.description ?? "")
        .toUpperCase()
        .trim() !== "PIZZA CASADINHA MEIA"
    ) {
      setPizza([
        {
          title: (
            <div>
              <span
                style={{ display: "inline", color: "red", fontSize: "50px" }}
              >
                1
              </span>
              <span style={{ marginLeft: "30px" }}>
                {firstPizzaSelectionText}
              </span>
            </div>
          ),
        },
        {
          title: (
            <div>
              <span
                style={{ display: "inline", color: "red", fontSize: "50px" }}
              >
                2
              </span>
              <span style={{ marginLeft: "30px" }}>
                {secondPizzaSelectionText}
              </span>
            </div>
          ),
        },
      ]);
    }

    if (product.subProductCombinationAutomatic === false) {
      if (
        String(product.description ?? "")
          .toUpperCase()
          .trim() === "MEIO A MEIO A PARTIR DE"
      ) {
        setPizza([
          {
            flavor: [
              {
                title: (
                  <div>
                    <span
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: "50px",
                      }}
                    >
                      1
                    </span>
                    <span style={{ marginLeft: "30px" }}>
                      {firstFlavorSelectionText}
                    </span>
                  </div>
                ),
              },
              {
                title: (
                  <div>
                    <span
                      style={{
                        display: "inline",
                        color: "red",
                        fontSize: "50px",
                      }}
                    >
                      2
                    </span>
                    <span style={{ marginLeft: "30px" }}>
                      {secondFlavorSelectionText}
                    </span>
                  </div>
                ),
              },
            ],
          },
        ]);
      }
    }
    if (
      product.hasOrientedSale !== false &&
      product.subProductCombinationAutomatic === false
    ) {
      if (
        String(product.description ?? "")
          .toUpperCase()
          .trim() === "PIZZA INTEIRA A PARTIR DE"
      ) {
        setPizza({
          title: (
            <div>
              <span style={{ marginLeft: "30px" }}>
                {pizzaFlavorSelectionText}
              </span>
            </div>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPizzaSelectionText, secondPizzaSelectionText, firstFlavorSelectionText, secondFlavorSelectionText, pizzaFlavorSelectionText]);

  const onSubmit = (item, entirePizza) => {
    const seq =
      cartItems.filter((item) => item.vit_numlancto).length < 1
        ? 1
        : cartItems.map((item) => item.vit_numlancto).sort((a, b) => b - a)[0] +
          1;

    if (entirePizza) {
      product.vit_numlancto = seq;
      item.vit_numlancto = seq + 1;
      item.vit_numlanctoprincipal = seq;

      addCart(product, [item]);
      addCart(item);
    } else {
      product.vit_numlancto = seq;

      item.forEach((flavor, index) => {
        flavor.vit_numlancto = seq + (index + 1);
        flavor.vit_numlanctoprincipal = seq;
      });

      addCart(product, item);
      item.forEach((flavor) => addCart(flavor));
    }

    backStepCategory();
  };

  const addCart = (item, list = []) => {
    console.log(list.length);
    console.log(list);
    let productValue = item.value > "0" ? item.value : "0";
    dispatch(
      addToCart({
        id: item._id,
        name: `${item.description}`,
        customizations:
          list.length > 0
            ? list.map((item) => `${item.qtd || 1} ${item.description}`)
            : [],
        price:
          list.length > 3
            ? list[0].value +
              (list[1] ? list[1].value : 0) +
              list[2].value +
              (list[3] ? list[3].value : 0)
            : list.value || list.length > 0
            ? list[0].value + (list[1] ? list[1].value : 0)
            : list.value,
        value: productValue,
        qtd: item.qtd || 1,
        image: item.image,
        vit_numlancto: item.vit_numlancto || null,
        vit_numlanctoprincipal: item.vit_numlanctoprincipal || null,
        code: item.productCode,
        classification: item.classification,
        quantityDouble: item.qtd || 1,
        promotion: list.length > 0 ? item.promotion : {},
        currencyPoints: list.currencyPoints || false,
        valuePoints: list.currencyPoints ? list.valuePoints : 0,
        nuuidOne: product.nuuidOne
      })
    );

    if (list.length > 0)
      controlNotification(
        `${item.description} + ${list
          .map((item) => item.description)
          .join(" ")} `
      );
  };

  return (
    <Pizza
      backStepCategory={() => backStepCategory()}
      pizza={pizza}
      flavor={listFlavor}
      onSubmit={onSubmit}
    />
  );
};

export default PizzaContainer;
