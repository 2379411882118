import React, { useState, useEffect } from "react";
import { RadioButtonList, PizzaFlavor, StepList, Step } from "../modules";
import { Amount } from "./style";
import { currency } from "../../utils/currency";

const Pizza = (props) => {
  const { pizza, flavor } = props;

  const [entirePizza, setEntire] = useState(false);
  const [doublePizza, setDouble] = useState(false);
  const [controlBackstep, setBackstep] = useState(false);

  const [listPizza, setListPizza] = useState([]);
  const [pizzaEntire, setPizzaEntire] = useState({});

  const [imagePizza, setImage] = useState("");
  const [imagePizza1, setImage1] = useState("");
  const [imagePizza2, setImage2] = useState("");
  const [imagePizza3, setImage3] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    pizzaControl();

    return () => {
      setEntire(false);
      setDouble(false);
      setListPizza([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pizza]);

  const setImages = (image, reset = false, index = 0 || 1) => {
    if (reset || entirePizza) {
      setImage(image);
      setImage1(image);
      setImage2(image);
      setImage3(image);

      return;
    }
    if (doublePizza && pizza.length > 0 && pizza[index].flavor) {
      if (index === 0) setImage(image);
      if (index === 1) setImage1(image);
      if (index === 2) setImage2(image);
      if (index === 3) setImage3(image);
    } else if (pizza.length > 0 && pizza[0].flavor)
      index === 0 ? setImage(image) : setImage1(image);
  };

  const pizzaControl = () => {
    setImages("", true);

    if (pizza.length === 1) {
      setListPizza([...pizza[0].flavor]);
      return;
    }

    if (pizza.length === 2 || pizza.length === 4) {
      setDouble(true);
      setListPizza(pizza);

      return;
    }

    if (!pizza.length) {
      setEntire(true);

      const newPizza = pizza;
      if (flavor && flavor.length > 0) {
        newPizza["flavor"] = flavor[0];
        setImages(flavor[0].image, false, null);
      }

      setPizzaEntire(newPizza);
      return;
    }
  };

  const onChange = (item, index) => {
    if (controlBackstep) {
      setBackstep(false);
      return;
    }
    entirePizza ? setObjPizza(item) : setObjListPizza(item, index);
  };

  const setObjPizza = (item) => {
    const obj = pizzaEntire;
    obj["flavor"] = item;
    setImages(item.image);
    setTotal(item.value);
  };

  const setObjListPizza = (item, index) => {
    const obj = listPizza;
    obj[index]["flavor"] = item;
    setImages(item.image, false, index);
    setTotal(sum(obj));
    setListPizza(obj);
  };

  const sum = (obj) => {
    let value;
    if (doublePizza && obj.length > 3) {
      if (obj[0] && obj[0]["flavor"]) value = +obj[0]["flavor"].value;

      if (obj[1] && obj[1]["flavor"]) value += +obj[1]["flavor"].value;

      if (obj[2] && obj[2]["flavor"]) value += +obj[2]["flavor"].value;
      if (obj[3] && obj[3]["flavor"]) value += +obj[3]["flavor"].value;
    } else {
      if (obj[0] && obj[0]["flavor"]) value = +obj[0]["flavor"].value;

      if (obj[1] && obj[1]["flavor"]) value += +obj[1]["flavor"].value;
    }
    return value;
  };

  const handleSubmit = () => {
    const item = entirePizza
      ? pizza.flavor
      : listPizza.map((item) => item.flavor);
    props.onSubmit(item, entirePizza);
  };

  const layoutStep = (item, index, onSubmit, radioButtonSelected) => {
    if (onSubmit) {
      return (
        <Step
          title={item.title}
          onPrevious={() =>
            index === 0 ? props.backStepCategory() : setBackstep(true)
          }
          key={index}
          onSubmit={() => handleSubmit()}
        >
          {layoutPizza(item, index, radioButtonSelected)}
        </Step>
      );
    }

    return (
      <Step
        title={item.title}
        onPrevious={() =>
          index === 0 ? props.backStepCategory() : setBackstep(true)
        }
        key={index}
      >
        {layoutPizza(item, index, radioButtonSelected)}
      </Step>
    );
  };

  const layoutPizzaFlavor = () => {
    const halfToHalf = pizza.length > 0 && pizza[0].flavor;
    if (doublePizza && pizza.length > 3) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PizzaFlavor
            halfToHalf={halfToHalf}
            image={imagePizza}
            image1={imagePizza1}
          />

          <PizzaFlavor
            halfToHalf={halfToHalf}
            image={imagePizza2}
            image1={imagePizza3}
          />
        </div>
      );
    }
    if (doublePizza) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PizzaFlavor
            halfToHalf={false}
            image={imagePizza}
            image1={imagePizza1}
          />

          <PizzaFlavor
            halfToHalf={false}
            image={imagePizza1}
            image1={imagePizza2}
          />
        </div>
      );
    }

    return (
      <PizzaFlavor
        halfToHalf={halfToHalf}
        image={imagePizza}
        image1={imagePizza1}
      />
    );
  };
  const layoutPizza = (item, index, radioButtonSelected) => (
    <>
      {layoutPizzaFlavor()}
      <RadioButtonList
        groupName={`pizza_${index}`}
        description={"description"}
        value={"_id"}
        listRadioButton={flavor}
        onChangeValue={(item) => onChange(item, index)}
        styleClass="column"
        defaultValue={radioButtonSelected}
      />

      <Amount>
        <span>Total:</span>
        <strong className="tabletTotal">{currency(total)}</strong>
      </Amount>
    </>
  );

  return (
    <>
      {entirePizza && (
        <StepList>{layoutStep(pizza, 0, true, pizzaEntire.flavor)}</StepList>
      )}
      {!entirePizza && (
        <StepList>
          {listPizza.map((item, index) =>
            layoutStep(item, index, index === listPizza.length - 1, item.flavor)
          )}
        </StepList>
      )}
    </>
  );
};

export default Pizza;
