import styled from 'styled-components';

const BannerWrapper = styled.section`
    height: 100vh;
    width: 100vw;

    background-size: cover;

    button {
        position: relative;
        top: -5em;
    }
`;


export default BannerWrapper;