import { PDV_STORE_PARAMS } from "../constants/keyNamesSessionStorage";

export const getSystemParamsHelper = () => {
  try {
    let storeParamsStorage = sessionStorage.getItem(PDV_STORE_PARAMS);

    if (!storeParamsStorage || storeParamsStorage === "undefined") {
      storeParamsStorage = "{}";
    }

    const params = JSON.parse(storeParamsStorage);

    return params;
  } catch (error) {
    return {};
  }
};
