import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { SectionWrapper, NavWrapper, ContentWrapper } from "./style";
import { ModalProduct, ModalCombo, Button, Keyboard } from "../modules";
import resgateCupom from "../../../assets/banner/resgate-cupom.png";
import resgateCupom2 from "../../../assets/banner/banner-fidelidade.png";
import resgateCupomDefault from "../../../assets/banner/banner.png";
import { Modal } from "../../components/modules";
import { currency } from "../../utils/currency";
import qrcode from "../../../assets/qr-code.svg";
import { ModalDrinks } from "../../../shared/components/modules";
import { CampaignContainer } from "../../../container/modules";
import { showFidelityPage } from "../../../configuration";
import iccupomadd from "../../../assets/iccupomadd.svg";
import KeyboardAlfa from "../keyboard-alfanumeric";
import { useLayoutAa } from "../../../contexts/layout";
import { useViewDispatch } from "../../../../context";

const Sidebar = (props) => {
  const [notification, setNotification] = useState(false);
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const { getTranslateName, isLayout, getScreenIsActiveBySlug } = useLayoutAa();

  const isShowFidelityPage = getScreenIsActiveBySlug("FIDELIDADE_DIGITE_CPF") ?? showFidelityPage;

  const buttonToAddText = getTranslateName(t, "buttons.toAdd") || "Adicionar";

  const searchFiltersText = getTranslateName(t, "search_filters") || "Use os filtros para facilitar sua busca";

  const added = getTranslateName(t, "cart.added") || "Adicionado ✔";

  const {
    categoryCode,
    title,
    listItems,
    content,
    subTitle,
    filters,
    modalProduct,
    setModalDrinks,
    modalDrinks,
    modalCombo,
    itemProduct,
    itemProductValue,
    itemProductValuePoints,
    itemProductImage,
    itemProductCode,
    itemId,
    updateStateModalProduct,
    updateStateModalCombo,
    backstep,
    backStepCategory,
    groupIsColumns = true,
    addSuggestion,
    suggestion,
    notificationContainer,
    removeNotificationContainer,
    accelerator,
    itemProductClassification,
    itemProductHasOrientedSale,
    itemProductDetailedDescription,
    itemProductQuantity,
    itemProductPromotion,
    currencyPoints,
    sendProduct,
    closeModalCoupon,
    showKeyboard,
    checkModalPoints,
    closeModalPoints,
    invalidProductCode,
    promocodeReapt,
    modalAddCupom,
    modalCupomAdd,
    productDescriptionModalCupom,
    productDisplayValueModalCupom,
    productImageModalCupom,
    imageFidelity,
    availableFidelityPoint,
    insertCupom,
    setInsertCupom,
    onCloseModalProduct,
    adultConsumptionAlert,
    setAdultConsumptionAlert,
    isComboUnic,
    orientedSale,
    nuuidOne
  } = props;

  const keyboardRef = useRef(null);

  const [controlIndex, setIndex] = useState(0);
  const [points, setPoints] = useState(0);
  const [hasCpf, setCpf] = useState(false);
  const [checkCoupon, setCheckCoupon] = useState(false);
  const [lastProductCode, setProductCode] = useState(0);

  const closeModal = useCallback(
    (productAdded) => {
      updateStateModalProduct(productAdded);
      if (onCloseModalProduct) {
        onCloseModalProduct(productAdded);
      }

      setModalDrinks(false);
    },
    [onCloseModalProduct, setModalDrinks, updateStateModalProduct]
  );
  const handleSelectedCombo = useCallback(
    (selectedCombo) => {
      updateStateModalCombo(selectedCombo);
    },
    [updateStateModalCombo]
  );

  const showNotification = useCallback(() => {
    setNotification(true);

    const suggestionIndex = !suggestion ? 0 : controlIndex + 1;
    setIndex(suggestionIndex);
    addSuggestion(suggestionIndex);
  }, [addSuggestion, controlIndex, suggestion]);

  // MODAL DA BEBIDA ALCÓLICA
  useEffect(() => {
    if (adultConsumptionAlert && !localStorage.getItem("adultConsumption")) {
      setModalDrinks(true);
      setAdultConsumptionAlert("");
    }
  }, [adultConsumptionAlert, setAdultConsumptionAlert, setModalDrinks, filters]);

  useEffect(() => {
    const hideNotification = setTimeout(() => {
      setNotification(false);
      removeNotificationContainer();
    }, 1000);

    return () => {
      clearTimeout(hideNotification);
    };
  }, [notification, removeNotificationContainer, suggestion]);

  useEffect(() => {
    const removeNotification = setTimeout(
      () => removeNotificationContainer(),
      2000
    );
    let getSaldo = localStorage.getItem("saldoAtual");
    setPoints(getSaldo);
    setCpf(window.localStorage.getItem("cpf"));

    return () => {
      clearTimeout(removeNotification);
    };
  }, [itemProduct, removeNotificationContainer]);

  useEffect(
    () => setNotification(notificationContainer),
    [notificationContainer]
  );

  function Close() {
    window.close();
  }

  const cupomResgate = useCallback(
    (productCode, resultPromoCode) => {
      if (!!productCode) {
        setProductCode(productCode);
        return sendProduct(productCode, false, resultPromoCode);
      }

      setCheckCoupon(true);
    },
    [sendProduct]
  );

  const confirmAdd = useCallback(() => {
    sendProduct(lastProductCode, true);
  }, [lastProductCode, sendProduct]);

  const closeModalResgate = () => {
    setCheckCoupon(false);
    closeModalCoupon();
  };

  const modalStyleBlackout = {
    width: "100%",
    height: "100vh",
    position: "absolute",
    zIndex: "20",
    background: "rgba(0, 0, 0, 0.82)",
  };

  const isRagazzoLayout = isLayout("RAGAZZO");
  const isHabibsLayout = isLayout("HABIBS");
  const isBobsLayout = isLayout("Bobs");

  const imageBanner = useMemo(() => {
    const layouts = "RAGAZZOEXPRESS";

    return isLayout(layouts)
      ? resgateCupom
      : !isHabibsLayout
      ? resgateCupomDefault
      : resgateCupom2;
  }, [isHabibsLayout]);

  const filtersProducts = filters.some(i => i.props.children === title)
  return (
    <>
      <SectionWrapper id="order-page">
        {checkModalPoints && (
          <div style={modalStyleBlackout} onClick={closeModalPoints}>
            <Modal
              mClassName="modal-points"
              mTitle="Saldo insuficiente"
              mSubtitle="O seu saldo de pontos fidelidade é insuficiente para adicionar esse produto."
              mTextBtnPrimary="ENTENDI"
              mBtnPrimarClassName="primary"
              mTextBtnSecondary={false}
              mBtnSecondaryClassName={false}
            />
          </div>
        )}

        {(checkCoupon || invalidProductCode) && (
          <div
            className="invalid-coupon-modal"
            style={modalStyleBlackout}
            onClick={closeModalResgate}
          >
            <Modal
              mClassName="modal-points"
              mTitle={`${
                promocodeReapt
                  ? "Cupom Já Acionado"
                  : "Cupom não encontrado"
              } `}
              mSubtitle={`${
                promocodeReapt
                  ? "Esse Cupom Já Foi Adicionado!"
                  : "Confira se você digitou o cupom corretamente."
              } `}
              mTextBtnPrimary="ENTENDI"
              mBtnPrimarClassName="primary"
              mTextBtnSecondary={false}
              mBtnSecondaryClassName={false}
            />
          </div>
        )}
        {modalCupomAdd && (
          <div className="modalCupomAdd">
            <div>
              <img src={iccupomadd} className="icCupomAdd" />
              <span className="modalCupomAddSpan">Código promocional adicionado</span>
            </div>
          </div>
        )}

        {modalAddCupom && (
          <div className="modal_blackout" onClick={closeModalResgate}>
            <div className="modal_resgateCupom">
              <h1 className="modal_resgateCupom__title">Adicionar o cupom?</h1>

              <p className="modal_resgateCupom__product">
                {productDescriptionModalCupom}
              </p>
              <strong className="modal_resgateCupom__value">
                {currency(`${productDisplayValueModalCupom}`)}
              </strong>

              <br />

              <img
                src={productImageModalCupom}
                alt={productDescriptionModalCupom}
                height="332px"
                width="490px"
                style={{ marginBottom: "4em" }}
              />

              <div className="buttons">
                <Button
                  text="Não"
                  buttonUIType="outline-primary"
                  buttonUI="highlight"
                  handleClick={() => console.log("")}
                />

                <Button
                  text="Sim"
                  buttonUIType="primary"
                  buttonUI="highlight"
                  handleClick={() => confirmAdd()}
                />
              </div>
            </div>
          </div>
        )}

        <NavWrapper className="navTablet">
          <ul className="listCategory">{listItems}</ul>
        </NavWrapper>

        <ContentWrapper className="contentTablet" id="page-content">
          {modalProduct && (
            <div className="blackout">
              <ModalProduct
                title={itemProduct}
                value={itemProductValue}
                valuePoints={itemProductValuePoints}
                image={itemProductImage}
                id={itemId}
                modalClose={closeModal}
                code={itemProductCode}
                notification={showNotification}
                suggestion={suggestion}
                accelerator={accelerator}
                orientedSale={orientedSale}
                classification={itemProductClassification}
                hasOrientedSale={itemProductHasOrientedSale}
                detailedDescription={itemProductDetailedDescription}
                quantity={itemProductQuantity}
                promotion={itemProductPromotion}
                currencyPoints={currencyPoints}
                backStepCategory={backStepCategory}
                isComboUnic={isComboUnic}
                nuuidOne={nuuidOne}
              />
            </div>
          )}

          {modalDrinks && (
            <div className="blackout">
              <ModalDrinks
                onPrimaryClick={() => {
                  setModalDrinks(false);
                  localStorage.setItem("adultConsumption", 1);
                }}
                onSecondaryClick={() => {
                  closeModal();
                }}
                modalClose={closeModal}
                mTitle="ATENÇÃO"
                mSubtitle="A venda de bebidas alcoólicas para menores de 18 anos é proibida"
                mTitleSub="Você tem mais de"
                mTextBtnPrimary="Sim"
                mTextBtnSecondary="Não"
                mBtnPrimarClassName="primary"
                mBtnSecondaryClassName="outline-primary"
              />
            </div>
          )}

          {modalCombo && (
            <div className="blackout">
              <ModalCombo
                title={itemProduct}
                value={itemProductValue}
                valuePoints={itemProductValuePoints}
                image={itemProductImage}
                id={itemId}
                modalClose={closeModal}
                code={itemProductCode}
                notification={showNotification}
                suggestion={suggestion}
                accelerator={accelerator}
                classification={itemProductClassification}
                hasOrientedSale={itemProductHasOrientedSale}
                quantity={itemProductQuantity}
                promotion={itemProductPromotion}
                currencyPoints={currencyPoints}
                selectedCombo={handleSelectedCombo}
                nuuidOne={nuuidOne}
              />
            </div>
          )}

          <section className="sticky">
            <button className="button-close" onDoubleClick={() => Close()}>
              <h1
                className={`${
                  availableFidelityPoint && hasCpf
                    ? "category-title-fidelityPoints"
                    : ""
                } category-title`}
              >
                {insertCupom ? (
                  <div className="titleCupomTablet">{"CUPONS"}</div>
                ) : (
                  <div
                    className={`${
                      categoryCode === "31" || categoryCode === "59"
                        ? "category-title-habibers"
                        : ""
                    } category-title`}
                  >
                    {title}
                    {availableFidelityPoint && hasCpf && (
                      <span className="fidelityPoints">
                        {availableFidelityPoint && hasCpf ? `${points}` : ""}
                      </span>
                    )}
                  </div>
                )}
              </h1>
              {!insertCupom && (
                <h2 className="category-subTitle"> {subTitle} </h2>
              )}
            </button>

            {!insertCupom && (
              <>
                {filters.length > 0 && !filtersProducts && !backstep && !imageFidelity && (
                  <div>
                    {(categoryCode === "31" ||
                      categoryCode === "7" ||
                      categoryCode === "10") &&
                      !isRagazzoLayout && (
                        <section className="filters">
                          <>
                            <p>{searchFiltersText}</p>
                            <div className="filters-tags">{filters}</div>
                          </>
                        </section>
                      )}
                    {(categoryCode === "59" ||
                      categoryCode === "60" ||
                      categoryCode === "17") &&
                      isRagazzoLayout && (
                        <section className="filters">
                          <>
                            <p>{searchFiltersText}</p>
                            <div className="filters-tags">{filters}</div>
                          </>
                        </section>
                      )}
                  </div>
                )}

                {categoryCode === "7" &&
                  isHabibsLayout &&
                  groupIsColumns && (
                    <section className="redeem-coupon-action">
                      <button
                        className="resgateCupom"
                        onClick={() => setInsertCupom(true)}
                      >
                        <img className="qrcode" src={qrcode} alt={qrcode} />
                        <span className="resgate">Resgate um cupom</span>
                      </button>
                    </section>
                )}
                {categoryCode === "58" &&
                  isBobsLayout &&
                  groupIsColumns && (
                    <section className="redeem-coupon-action">
                      <button
                        className="resgateCupom"
                        onClick={() => setInsertCupom(true)}
                      >
                        <img className="qrcode" src={qrcode} alt={qrcode} />
                        <span className="resgate">Resgate um cupom</span>
                      </button>
                    </section>
                )}
                {categoryCode === "17" &&
                  isRagazzoLayout &&
                  groupIsColumns && (
                    <section className="redeem-coupon-action">
                      <button
                        className="resgateCupom"
                        onClick={() => setInsertCupom(true)}
                      >
                        <img className="qrcode" src={qrcode} alt={qrcode} />
                        <span className="resgate">Resgate um cupom</span>
                      </button>
                    </section>
                  )}
              </>
            )}
          </section>

          {imageFidelity && !hasCpf && isShowFidelityPage && (
            <section className="resgate-cupom" height="938px;" width="782px">
              <img src={imageBanner} alt="" />

              <Button
                text="Entrar"
                buttonUIType="secondary"
                buttonUI="highlight"
                handleClick={() => {viewDispatch("step.fidelity");}}
              />
            </section>
          )}

          {insertCupom && showKeyboard && (
            <>
              <KeyboardAlfa
                classN="resgate"
                handleClick={(n,resultPromoCode) => cupomResgate(n,resultPromoCode)}
                textPlaceholder="Código"
                textLabel="INFORME O CÓDIGO DO SEU CUPOM"
                isFidelity={false}
                typeInput="numeric"
                ref={keyboardRef}
                theme={"hg-theme-default hg-layout-default habibs"}
              />
                <>
                  <CampaignContainer />
                  <div className="backbutton-insertcupom-container">
                    <Button
                      width={453}
                      text="Voltar"
                      buttonUIType="outline-primary"
                      buttonUI={"normal"}
                      handleClick={() => setInsertCupom(false)}
                    />
                    <button
                      className="done-button"
                      onClick={() => keyboardRef.current.handleDoneOnClickCupom()}
                    >
                      {buttonToAddText}
                    </button>
                  </div>
                </>
            </>
          )}

          <section className="gridscroll">
            {backstep && (
              <section className="wrapper-button">
                <button
                  className="button-backstep"
                  onClick={() => backStepCategory()}
                >
                  <span className="arrow-left">
                    <i className="fad fa-regular fa-chevron-left"></i>
                  </span>
                  Voltar
                </button>
              </section>
            )}

            <div
              className={`groupSales ${
                !groupIsColumns ? "group-sales-rows" : ""
              }`}
            >
              {content}
            </div>
          </section>
        </ContentWrapper>

        {notification && (
          <section className="highlight-add-item">
            <p className="added-item">
              {itemProduct} <span>{added}</span>
            </p>
          </section>
        )}
      </SectionWrapper>
    </>
  );
};

export default Sidebar;
