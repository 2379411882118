import styled from 'styled-components';

export const TableWrapper = styled.div`
.table {
    width: 100%;
    border-spacing:0;
    border-collapse: collapse;
    tr {
        td {
            border-bottom: 1px solid #eee;

            &:first-child {
                padding-right: 20px;
                padding-left: 20px;
            }
            &:last-child {
                padding-right: 10px;
            }
        }
    }
    thead {
        tr {
            padding-bottom: 10px;
            td {
                text-transform: uppercase;
                font-weight: bold;
                color: black;
                padding-bottom: 10px;
                font-size: 12px;
            }
        }
    }
    tbody {
        tr {
            font-size: 14px;
            &:last-child {
                border-bottom: 1px solid #eee;
            }
            td {
                height: 26px;
                padding: 15px 0 9px 0;
                word-wrap: break-word;
                vertical-align: top;

                img {
                    border-radius: 50%;
                    height: 56px;
                    width: 56px;
                }
            }
            &.table__tr--link:hover td {
                cursor: pointer;

                &:first-child {
                border-radius: 5px 0 0 5px;
                }

                &:last-child {
                border-radius: 0 5px 5px 0;
                }
            }
            .is-actived{
                color:black;
                font-weight: bold;
                background-color: #18a3b1;
                color: white;
            }
        }
    }
}

.collapsable-cell {
    display: block;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
}

.collapsable-value {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 29px;
    height: 30px;
}

.expand {
    right: 0;
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: top;
    margin-top: 3px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
`;

export default TableWrapper;
