export default class UtilsUseCase {
  getLevelNameInProduct(product) {
    let nextLevelPositionName = "nivel1";

    Object.keys(product).forEach(position => {
      const splitedLevel = position.split("nivel");

      if (splitedLevel[1] !== undefined) {
        nextLevelPositionName = position;
      }
    });

    return nextLevelPositionName;
  }

  getDescription(allDescriptions, environment) {
    const adaptEnvironment = {
      1: allDescriptions?.descriptionPDV,
      2: allDescriptions?.descriptionAAOneNew,
      3: allDescriptions?.descriptionPDV,
      4: allDescriptions?.descriptionMobile
    };

    return adaptEnvironment[environment];
  }
}

