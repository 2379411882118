import React from "react";
import { movementsEnum } from "../../constants/movementsEnum";
import PrintTemplate from "../../components/print-template";
import { PDV_STORE_PARAMS } from "../../constants/keyNamesSessionStorage";
import { render } from "react-dom";
import { printerActionService } from "../../../services/actions/printerActionService";
import { currencyMask } from "../masks";
import { formatDateHelper } from "../formatDateHelper";
import { lineToPrintHelper } from "../lineToPrintHelper";
import { printDefault } from "./printDefault";
import { getSystemParamsHelper } from "../getSystemParamsHelper";

const modelTypeEnum = {
  FECHAMENTO: 1,
};

const isMobile = !!window.Android;
export const printCloseReport = ({
  cashdesk,
  cashdeskValues,
  modelType = modelTypeEnum.FECHAMENTO,
  getHTML = false,
  monitorNotes,
}) => {
  const systemParams = getSystemParamsHelper();

  cashdesk.storeDetails = systemParams;
  cashdesk.movementsWithdraw = cashdesk?.movements?.filter(
    (mov) => mov.movementType === movementsEnum.WITHDRAW && !mov.isReversed
  );
  cashdesk.movementsSupply = cashdesk?.movements?.filter(
    (mov) => mov.movementType === movementsEnum.SUPPLY && !mov.isReversed
  );

  var html = document.createElement("html");
  render(
    <PrintTemplate>
      <br />
      {!isMobile
        ? "--------------------------------------------------------"
        : lineToPrintHelper}
      <br />
      <div style={{ textAlign: "center" }}>
        <b>{cashdesk.storeDetails.storeName}</b>
      </div>
      {!isMobile
        ? "--------------------------------------------------------"
        : lineToPrintHelper}
      <br />
      CNPJ: {cashdesk.storeDetails.storeCNPJ} / IE:{" "}
      {cashdesk.storeDetails.storeDetails.ie}
      {isMobile ? "\n" : ""}
      <br />
      {!isMobile
        ? "--------------------------------------------------------"
        : lineToPrintHelper}
      <br />
      PDV: {cashdesk.posId} {isMobile ? "\n" : ""}{" "}
      {cashdesk.isAAOne ? "Auto Atendimento" : ""}
      <br />
      <table>
        <tbody>
          <tr>
            <td className="shrink">Data Abertura{isMobile ? " " : ""}</td>
            <td className="expand">
              {formatDateHelper(
                new Date(cashdesk.openingDate),
                "dd/MM/yyyy HH:mm:ss"
              )}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Abertura{isMobile ? " " : ""}</td>
            <td className="expand">
              {cashdesk.openingCount}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          {cashdesk.reducingDate && (
            <tr>
              <td className="shrink">Reducao{isMobile ? " " : ""}</td>
              <td className="expand">
                {formatDateHelper(
                  new Date(cashdesk.reducingDate),
                  "dd/MM/yyyy HH:mm:ss"
                )}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
          )}
          <tr>
            <td className="shrink">Responsável{isMobile ? " " : ""}</td>
            <td className="expand">
              {cashdesk.responsable}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Operador{isMobile ? " " : ""}</td>
            <td className="expand">
              {cashdesk.user}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Fundo Inicial{isMobile ? " " : ""}</td>
            <td className="expand">
              {currencyMask(cashdeskValues.operationalResult.openingAmount)}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
        </tbody>
      </table>
      {!isMobile ? "==================================" : lineToPrintHelper}
      <br />
      {!cashdesk.isBlindCashDesk && (
        <>
          <div style={{ textAlign: "center" }}>
            {!isMobile
              ? "*** RESULTADO OPERACIONAL ***"
              : "            *** RESULTADO OPERACIONAL ***\n"}
          </div>
          <br />
          <table>
            <tbody>
              <tr>
                <td className="shrink">Venda Balcão{isMobile ? " " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.balcaoTotal)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">Venda Delivery{isMobile ? " " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.deliveryTotal)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">
                  Faturamento Bruto{isMobile ? " " : ""}
                </td>
                <td className="expand">
                  {currencyMask(cashdeskValues.grossTotal)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">(+) Suprimento{isMobile ? " " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.operationalResult.supply)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">(-) Sangria{isMobile ? " " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.operationalResult.withdraw * -1)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">(-) Desconto{isMobile ? " " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.discount)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink"></td>
                <td className="expand">
                  {isMobile ? lineToPrintHelper : "----------"}
                </td>
              </tr>
              <tr>
                <td className="shrink">
                  RES. OPERACIONAL{isMobile ? " " : ""}
                </td>
                <td className="expand">
                  {currencyMask(cashdeskValues.operationalResult.total)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <div>
            {!isMobile
              ? "=================================="
              : lineToPrintHelper}
          </div>
          <div style={{ textAlign: "center" }}>
            {!isMobile
              ? "*** CONTAGEM DE NUMERARIOS ***"
              : "          *** CONTAGEM DE NUMERÁRIOS ***"}
          </div>
          <br />
          {isMobile ? "\n" : ""}
          {modelType === modelTypeEnum.FECHAMENTO ? (
            <table border="0">
              <thead>
                <tr>
                  <td align="left">
                    Tipo Pagto
                    {isMobile ? " | " : ""}
                  </td>
                  <td align="center">
                    Sistema
                    {isMobile ? " | " : ""}
                  </td>
                  <td align="right">Manual{isMobile ? "\n" : ""}</td>
                </tr>
              </thead>
              <tbody>
                {cashdeskValues.counterNumbers.paymentsBrands.length &&
                  cashdeskValues.counterNumbers.paymentsBrands.map((brand) => (
                    <>
                      <tr>
                        <td align="left">
                          {brand.name} {isMobile ? " | " : ""}
                        </td>
                        <td align="center">
                          {currencyMask(brand.systemAmount)}
                          {isMobile ? " | " : ""}
                        </td>
                        <td align="right">
                          {currencyMask(brand.amount)}
                          {isMobile ? "\n" : ""}
                        </td>
                      </tr>
                    </>
                  ))}
                {window.desktopApp && (
                  <tr>
                    <td align="left">-------</td>
                    <td align="center">-------</td>
                    <td align="right">-------</td>
                  </tr>
                )}
                <tr>
                  <td align="left">TOTAL {isMobile ? " " : ""}</td>
                  <td align="center">
                    {currencyMask(
                      cashdeskValues.counterNumbers.totalSystemAmount
                    )}{" "}
                    {isMobile ? " | " : ""}
                  </td>
                  <td align="right">
                    {currencyMask(cashdeskValues.counterNumbers.totalAmount)}
                    {isMobile ? "\n" : ""}
                  </td>
                </tr>
                <tr>
                  <td align="left">DIFERENCA {isMobile ? " " : ""}</td>
                  <td align="center">
                    {currencyMask(
                      cashdeskValues.counterNumbers.diferenceAmount
                    )}
                    {isMobile ? "\n" : ""}
                  </td>
                  <td align="right"></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table border="0">
              <tbody>
                <tr>
                  <td align="left">Tipo Pagto {isMobile ? " | " : ""}</td>
                  <td align="right">Sistema {isMobile ? "\n" : ""}</td>
                </tr>

                {cashdesk.closingValues.map((cash) => (
                  <>
                    {cash.brands.length ? (
                      <tr>
                        <td align="left">@brand.Name{isMobile ? " | " : ""}</td>
                        <td align="right">
                          @brand.SystemAmount.ToString("N2", new
                          CultureInfo("pt-BR")){isMobile ? "\n" : ""}
                        </td>
                      </tr>
                    ) : cash.systemAmount ? (
                      <tr>
                        <td align="left">
                          @cash.PaymentType.Nam{isMobile ? " | " : ""}e
                        </td>
                        <td align="right">
                          @cash.SystemAmount.ToString("N2", new
                          CultureInfo("pt-BR")){isMobile ? "\n" : ""}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
                {window.desktopApp && (
                  <tr>
                    <td align="left">-------</td>
                    <td align="right">-------</td>
                  </tr>
                )}
                <tr>
                  <td align="left">TOTAL{isMobile ? " " : ""}</td>
                  <td align="right">
                    {
                      '@Model.CashCounts.Sum(c => c.SystemAmount).ToString("N2", new CultureInfo("pt-BR"))'
                    }
                    {isMobile ? "\n" : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <div style={{ textAlign: "left" }}>
            Explic: {cashdesk.closingDescription}
            {isMobile ? "\n" : ""}
          </div>
          <br />

          <div>
            {!isMobile
              ? "--------------------------------------------------------"
              : lineToPrintHelper}
          </div>

          <table>
            <tbody>
              <tr>
                <td className="shrink">Troco {isMobile ? " | " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.totalChange)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">
                  TC-Total Cupom{isMobile ? " | " : ""}
                </td>
                <td className="expand">
                  {cashdeskValues.totalCoupon}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
              <tr>
                <td className="shrink">
                  TM-Ticket Medio por Cupom{isMobile ? " " : ""}
                </td>
                <td className="expand">
                  {currencyMask(cashdeskValues.averageTicket)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            {!isMobile
              ? "--------------------------------------------------------"
              : lineToPrintHelper}
          </div>
          <br />
          <div style={{ textAlign: "center" }}>Resumo por Tipo de Venda</div>
          <br />
          <table>
            <tbody>
              <tr>
                <td className="shrink">Total Bruto{isMobile ? " " : ""}</td>
                <td className="expand">
                  {currencyMask(cashdeskValues.total)}
                  {isMobile ? "\n" : ""}
                </td>
              </tr>
            </tbody>
          </table>
          <br />

          {cashdesk.movementsWithdraw.length > 0 && (
            <>
              <text>
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
                <div style={{ textAlign: "center" }}>
                  {!isMobile
                    ? "SANGRIA DE CAIXA"
                    : "                SANGRIA DE CAIXA"}
                </div>
                <br />
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
                CONTROLE HISTORICO{isMobile ? "\n" : ""}
                <br />
                <table border="0">
                  <thead>
                    <tr>
                      <td align="left">Valor{isMobile ? " | " : ""}</td>
                      <td align="center">Motivo{isMobile ? " | " : ""}</td>
                      <td align="right">Data/Hora{isMobile ? "\n" : ""}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {cashdesk.movementsWithdraw.map((movWithdraw) => (
                      <tr>
                        <td align="left">
                          {currencyMask(movWithdraw.amount)}
                          {isMobile ? " | " : ""}
                        </td>
                        <td align="center">
                          {movWithdraw.description}
                          {isMobile ? " | " : ""}
                        </td>
                        <td align="right">
                          {formatDateHelper(
                            new Date(movWithdraw.creationDate),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          {isMobile ? "\n" : ""}
                        </td>
                      </tr>
                    ))}
                    {window.desktopApp ? (
                      <tr>
                        <td>---------</td>
                      </tr>
                    ) : (
                      lineToPrintHelper
                    )}
                    <tr>
                      <td>
                        {isMobile ? "Total: " : ""}
                        {currencyMask(
                          cashdesk.movementsWithdraw.reduce((acc, curr) => {
                            return acc + curr.amount;
                          }, 0)
                        )}
                        {isMobile ? "\n" : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                Operador: {cashdesk.user}
                <br />
                <br />
                {isMobile ? "\n\n" : ""}
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
                TOTAL SANGRIA:{" "}
                {currencyMask(cashdeskValues.operationalResult.withdraw * -1)}
                {isMobile ? "\n" : ""}
                <br />
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
              </text>
            </>
          )}
          <br />
          {cashdesk.movementsSupply.length > 0 && (
            <>
              <text>
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
                <div style={{ textAlign: "center" }}>SUPRIMENTO DE CAIXA</div>
                <br />
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
                CONTROLE HISTORICO
                <br />
                <table border="0">
                  <thead>
                    <tr>
                      <td align="left">Valor{isMobile ? " | " : ""}</td>
                      <td align="center">Motivo{isMobile ? " | " : ""}</td>
                      <td align="right">Data/Hora{isMobile ? "\n" : ""}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {cashdesk.movementsSupply.map((movSupply) => (
                      <tr>
                        <td align="left">
                          {currencyMask(movSupply.amount)}
                          {isMobile ? " | " : ""}
                        </td>
                        <td align="center">
                          {movSupply.description}
                          {isMobile ? " | " : ""}
                        </td>
                        <td align="right">
                          {formatDateHelper(
                            new Date(movSupply.creationDate),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          {isMobile ? "\n" : ""}
                        </td>
                      </tr>
                    ))}
                    {window.desktopApp && (
                      <tr>
                        <td>---------</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        {currencyMask(
                          cashdesk.movementsSupply.reduce((acc, curr) => {
                            return acc + curr.amount;
                          }, 0)
                        )}
                        {isMobile ? "\n" : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                Operador: {cashdesk.user}
                <br />
                <br />
                {isMobile ? "\n\n" : ""}
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
                {isMobile ? "\n" : ""}
                TOTAL SUPRIMENTO:{" "}
                {currencyMask(cashdeskValues.operationalResult.supply)}
                {isMobile ? "\n" : ""}
                <br />
                {!isMobile
                  ? "--------------------------------------------------------"
                  : lineToPrintHelper}
                <br />
              </text>
            </>
          )}
          <table border="0">
            <tbody>
              <tr>
                <td align="left">Periodo{isMobile ? " | " : ""}</td>
                <td align="center">Vendas{isMobile ? " | " : ""}</td>
                <td align="center">TC{isMobile ? " | " : ""}</td>
                <td align="right">TM{isMobile ? "\n" : ""}</td>
              </tr>

              {cashdeskValues.reportByHourly?.map((order) => (
                <>
                  <tr>
                    <td align="left">
                      {order.interval}
                      {isMobile ? " | " : ""}
                    </td>
                    <td align="center">
                      {currencyMask(order.totalSales)}
                      {isMobile ? " | " : ""}
                    </td>
                    <td align="center">
                      {order.totalCoupon}
                      {isMobile ? " | " : ""}
                    </td>
                    <td align="right">
                      {currencyMask(order.averageTicket)}
                      {isMobile ? "\n" : ""}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div>
            {!isMobile
              ? "--------------------------------------------------------"
              : lineToPrintHelper}
          </div>
        </>
      )}
      {monitorNotes?.length > 0 && (
        <>
          <br />
          <div style={{ textAlign: "center" }}>Notas Canceladas/Devolvidas</div>
          <table border="0">
            <tbody>
              <tr>
                <td align="left">N°{isMobile ? " | " : ""}</td>
                <td align="center">Série{isMobile ? " | " : ""}</td>
                <td align="center">Status{isMobile ? " | " : ""}</td>
                <td align="center">Valor{isMobile ? " | " : ""}</td>
              </tr>
              {monitorNotes?.map((order) => (
                <>
                  <tr>
                    <td align="left">
                      {order.receiptNumber}
                      {isMobile ? " | " : ""}
                    </td>
                    <td align="center">
                      {order.receiptSeries}
                      {isMobile ? " | " : ""}
                    </td>
                    <td align="center">
                      {order.status === 10 ? "Devolvida" : "Cancelada"}
                      {isMobile ? " | " : ""}
                    </td>
                    <td align="right">
                      {currencyMask(order.total)}
                      {isMobile ? "\n" : ""}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div>
            {!isMobile
              ? "--------------------------------------------------------"
              : lineToPrintHelper}
          </div>
        </>
      )}
      <br />
      <br />
      {isMobile ? "\n\n\n\n" : ""}
      Assinatura/carimbo Operador
      <br />
      <br />
      <br />
      <br />
      {isMobile ? "\n\n\n\n" : ""}
      Assinatura/carimbo Gerente
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {isMobile ? "\n\n\n\n\n\n\n\n" : ""}
    </PrintTemplate>,
    html
  );

  if (window.desktopApp && !getHTML) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (getHTML) {
    return html.outerHTML;
  } else if (isMobile) {
    console.log(`printDefault | {}`);
    printDefault(html.outerHTML);
  }
};
