import React, { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import CashWithdraw from "../../components/CashWithdraw";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useCashdesk } from "../../hook/cashdeskHook";
import { getRetailerId } from "../../../aaone/configuration";
import { listMovementsService } from "../../../services/cashdeskService";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { MessageBox } from "../../components/modules";
import { getPaymentsMethodService } from "../../../services/getPaymentsMethodService";
import { useAuth } from "../../hook/authHook";
import { movementsEnum } from "../../constants/movementsEnum";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import {
  handlePrintChargeback,
  handlePrintCoupon,
} from "../../helpers/printer/withdraw";
import { openDrawer } from "../../helpers/openDrawer";

const withHooks = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();
    const navigate = useNavigate();
    const cashdesk = useCashdesk();
    const location = useLocation();
    const dialog = useDialog();
    const loading = useLoading();
    const auth = useAuth();

    return (
      <Component
        {...props}
        setVisibleSidebarCart={setVisibleSidebarCart}
        navigate={navigate}
        cashdesk={cashdesk}
        location={location}
        dialog={dialog}
        loading={loading}
        auth={auth}
      />
    );
  };
};

export class CashWithdrawContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controlNumber: 0,
      openCard: true,
      openCardList: false,
      listWithdraw: [],
      movements: {},
      message: false,
      paymentType: null,
      manager: {},
      operator: {},
    };
  }

  componentDidMount() {
    if (!this.props.cashdesk.isCashdeskOnCurrentDay) {
      // TODO: Customizar alerta
      this.props.dialog.showAlert({
        message:
          "Para realizar essa operação, realize o fechamento do caixa e encerramento do dia anterior.",
      });

      // Ele não transferia a página, tive que colocar um timeout
      setTimeout(() => {
        this.props.navigate(PagesEnum.HOME);
      }, 300);

      return;
    }
    try {
      this.props.loading.setShowLoading(true);
      const { state } = this.props.location;

      const manager = state?.auth ? state.auth.user : this.props.auth.profile;
      const operator = this.props.auth.profile;

      const promises = [];

      promises.push(
        listMovementsService({
          retailerId: getRetailerId(),
          movementType: movementsEnum.WITHDRAW,
          initialDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
          finalDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
        }).then((res) => {
          const withdraws = res.filter(
            (movement) =>
              movement.openingCount ===
              this.props?.cashdesk?.cashdesk?.openingCount
          );

          this.setState({
            controlNumber: res.length
              ? res[res.length - 1].referenceSequence + 1
              : 1,
            listWithdraw: withdraws,
          });
        })
      );

      promises.push(
        getPaymentsMethodService().then((res) => {
          this.setState({
            paymentType: res.find(
              (payment) => payment.paymentOneBaseCode === 1
            ),
          });
        })
      );

      this.setState({
        manager,
        operator,
      });
      Promise.all(promises).then(() => {
        this.props.loading.setShowLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.props.loading.setShowLoading(false);
    }
  }

  handleClick(amount, description, numberEnvelop) {
    try {
      if (!amount && !description && !numberEnvelop) return;

      this.props.loading.setShowLoading(true);
      const req = {
        description: description,
        amount: -amount,
        numberEnvelop: numberEnvelop,
        operatorName: this.state.operator.login,
        adminName: this.state.manager.login,
        paymentType: {
          id: this.state.paymentType?.nuuidOne,
          name: this.state.paymentType?.transactionType,
          referenceId: this.state.paymentType?.paymentOneBaseCode,
        },
        responsableId: this.state.manager.nuuidOne,
        userId: this.state.operator.nuuidOne,
        responsableReferenceId: this.state.manager.employeeCode,
        userReferenceId: this.state.operator.employeeCode,
      };

      this.props.cashdesk
        .cashWithdraw(req)
        .then((res) => {
          this.handlePrinter({
            cashdesk: res,
          });
          this.props.dialog.showAlert({
            message: "Sangria realizada com sucesso!",
          });

          //Abre a gaveta
          openDrawer();

          this.handleClose();
          this.setState({
            disableConfirm: false,
          });
          this.props.loading.setShowLoading(false);
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            disableConfirm: false,
          });
          this.props.loading.setShowLoading(false);
        });
    } catch (err) {
      console.error(err);
      this.setState({
        disableConfirm: false,
      });
      this.props.loading.setShowLoading(false);
    }
  }

  handleChargeback(itemMovement) {
    try {
      this.props.loading.setShowLoading(true);
      this.props.cashdesk
        .chargeback({
          movementId: itemMovement.id,
        })
        .then((res) => {
          this.props.dialog.showAlert({
            message: "Estorno realizado com sucesso!",
          });
          this.handlePrinter({
            cashdesk: res.cashDesk,
            movementId: itemMovement.id,
          });
          this.handleClose();
          this.props.loading.setShowLoading(false);
        });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  onCashOutFinish() {
    this.props.cashWithdrawBoxShow(false);
    this.props.onCartShow();
  }

  handleClose() {
    this.props.navigate(PagesEnum.HOME);
    this.props.setVisibleSidebarCart();
  }

  handleHidden(expandCardList) {
    const objState = {
      openCardList: expandCardList,
      openCard: !expandCardList,
    };
    this.setState(objState);
  }

  confirmChargeback = (itemMovement) => {
    this.setState({
      ...this.state,
      message: true,
      itemMovement: itemMovement,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      message: false,
    });
  };

  handlePrinter({ cashdesk, movementId }) {
    const currWithdraw = movementId
      ? cashdesk.movements.filter((movement) => movement.id === movementId)[0]
      : cashdesk.movements[cashdesk.movements.length - 1];

    if (!currWithdraw.isReversed) {
      handlePrintCoupon(cashdesk, currWithdraw);
      handlePrintCoupon(cashdesk, currWithdraw);
    } else {
      handlePrintChargeback(cashdesk, currWithdraw);
    }
  }

  render() {
    // Se o caixa estiver no dia anterior
    if (!this.props.cashdesk.isCashdeskOnCurrentDay) {
      return <></>;
    }

    const { openCard, openCardList } = this.state;

    const messageBox = this.state.message && (
      <MessageBox
        message="Deseja realmente estornar sangria?"
        handleCancel={() => this.closeModal()}
        handleConfirm={() => this.handleChargeback(this.state.itemMovement)}
        okText="Sim"
      />
    );

    return (
      <KeyboardProvider>
        <CashWithdraw
          title="SANGRIA"
          manager={this.state.manager}
          handleClick={(amount, description, numberEnvelop) =>
            this.handleClick(amount, description, numberEnvelop)
          }
          handleClose={() => this.handleClose()}
          handleHidden={(isCardList) => this.handleHidden(isCardList)}
          handlePrinter={(movementId) =>
            this.handlePrinter({
              cashdesk: this.props.cashdesk.cashdesk,
              movementId,
            })
          }
          handleChargeback={(itemMovement) =>
            this.confirmChargeback(itemMovement)
          }
          expandCard={openCard}
          expandCardList={openCardList}
          listWithdraw={this.state.listWithdraw}
          controlNumber={this.state.controlNumber}
          setVisibleSideKeyboardContainer={(visible) =>
            this.props.setVisibleSideKeyboardContainer(visible)
          }
          setVisibleBottomKeyboardContainer={(visible) =>
            this.props.setVisibleBottomKeyboardContainer(visible)
          }
        />
        {messageBox}
      </KeyboardProvider>
    );
  }
}

export default withHooks(CashWithdrawContainer);
