import React from "react";
import { BoxWrapper, ButtonContainer, InfoSuppleData, TextData } from "./style";
import Button from "../../Button";
import { currencyMask } from "../../../helpers/masks";

export const InfoSupply = ({ items, handlePrinter, handleChargeback }) => {
  return (
    <>
      {items.map((item) => (
        <BoxWrapper>
          <InfoSuppleData>
            <TextData>
              <label>Nº Controle:</label>
              <label>
                <strong>{item?.controlNumber}</strong>
              </label>
            </TextData>
            <TextData>
              <label>Histórico:</label>
              <label>
                <strong>{item?.description}</strong>
              </label>
            </TextData>
            <TextData>
              <label>Cancelado:</label>
              <label>
                <strong>{item.isReversed ? " Sim" : " Não"}</strong>
              </label>
            </TextData>
            <TextData>
              <label>Valor:</label>
              <label>
                <strong>{currencyMask(item.amount)}</strong>
              </label>
            </TextData>
          </InfoSuppleData>
          <ButtonContainer>
            <Button
              className="btn-cancel"
              onClick={() => handleChargeback(item)}
            >
              <div>
                <i className="material-icons">money_off</i>
                ESTORNAR
              </div>
            </Button>
            <Button className="btn-print" onClick={() => handlePrinter(item)}>
              <div>
                <i className="material-icons">print</i>
                IMPRIMIR
              </div>
            </Button>
          </ButtonContainer>
        </BoxWrapper>
      ))}
    </>
  );
};
