import React, { useState, useEffect } from "react";
import { RadioButtonGroup, RadioButtonComponent } from "./style";
import { currency } from "../../utils/currency";
import { useCallback } from "react";
import { showImageInVo } from "../../../configuration";
import Compositon from "../compositon/index";

const RadioButtonList = (props) => {
  const {
    listRadioButton,
    description,
    groupName,
    value,
    onChangeValue,
    styleClass = "list",
    defaultValue,
    displayValue,
  } = props;

  const [radioSelected, setRadio] = useState({});

  useEffect(() => {
    if (!radioSelected[value]) {
      const radio = defaultValue
        ? listRadioButton.find((item) => item[value] === defaultValue[value])
        : listRadioButton[0];

      setRadio(radio);
      onChangeValue(radio);
    }

    return () => setRadio({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = useCallback(
    (event) => {
      const radio = listRadioButton.find(
        (radio) => radio[value] === event.target.value
      );

      setRadio(radio);
      onChangeValue(radio);
    },
    [listRadioButton, onChangeValue, value]
  );

  return (
    <>
      <RadioButtonGroup className={`radio-button-group--${styleClass}`}>
        {listRadioButton.length > 0 &&
          listRadioButton.map((item, index) => {
            return (
              <>{showImageInVo  ?
                  <div className="">
                    <RadioButtonComponent
                      key={`radio-button-component-${index}`}
                      className={
                        item[value] === radioSelected[value] ? "checked " : ""
                      }
                    >
                      <img
                        className="imageProductVo"
                        src={item.image}
                        alt={item.name}
                      />
                      <input
                        key={`radio-button-input-${index}`}
                        className="radio-button-input"
                        type="radio"
                        name={groupName}
                        checked={item[value] === radioSelected[value]}
                        value={item[value]}
                        onChange={onChange}
                      />
                      <span key={`span-radio-check-${index}`} className="radio-check"></span>
                      <span key={`span-radio-label-${index}`} className="radio-button-label">
                        {item[description]}
                        {displayValue && +item[displayValue] > 0 && (
                          <strong> + {currency(item[displayValue])} </strong>
                        )}
                      </span>
                    </RadioButtonComponent>
                  </div>
                  :
                  <RadioButtonComponent
                  key={`radio-button-component-${index}`}
                  className={
                    item[value] === radioSelected[value] ? "checked" : "acessibilidadeStyle"
                  }
                >
                  <input
                    key={`radio-button-input-${index}`}
                    className="radio-button-input"
                    type="radio"
                    name={groupName}
                    checked={item[value] === radioSelected[value]}
                    value={item[value]}
                    onChange={onChange}
                  />
                  <span key={`span-radio-check-${index}`} className="radio-check"></span>
                  <span key={`span-radio-label-${index}`} className="radio-button-label">
                    {item[description]}
                    {displayValue && +item[displayValue] > 0 && (
                      <strong> + {currency(item[displayValue])} </strong>
                    )}
                  </span>
                </RadioButtonComponent>
              }
                {
                  !!item.compositions.length &&
                  item[value] === radioSelected[value] &&
                  <Compositon
                    key={`compositions-${index}`}
                    compositions={item.compositions}
                    setRadio={setRadio}
                    onChangeValue={onChangeValue}
                    item={item}
                  />
                }
              </>
            );
          })}
      </RadioButtonGroup>
    </>
  );
};

export default React.memo(RadioButtonList);
