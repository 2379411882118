import React, { useState } from "react";
import { CartContainer, SidenavContainer } from "../modules";
import { Modal } from "../../shared/components/modules";
import { getThemeSettings } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { useTranslation } from "react-i18next";

const HomeContainer = () => {
  const { isLayout } = useLayoutAa();
  const [modal, setModal] = useState(false);
      const { t } = useTranslation();
    const { getTranslateName } = useLayoutAa();

    const clearCardTitleText = getTranslateName(t, "clearCart.title") || "INICIAR NOVO PEDIDO?";
    const clearCardBtnYes = getTranslateName(t, "clearCart.buttons.confirm") || "SIM";
    const clearCardBtnNo = getTranslateName(t, "clearCart.buttons.cancel") || "NÃO";

  return (
    <>
      {modal && (
        <div className="blackout" onClick={() => setModal(false)}>
          <Modal
            className="btnnewragazzo"
            mTitle={
              getThemeSettings?.text?.ask_start_new_order
                ? getThemeSettings?.text?.ask_the_start_new_order
                : clearCardTitleText
            }
            mTextBtnPrimary={
              getThemeSettings?.text?.button_cancel_ask_the_start_new_order
                ? getThemeSettings?.text?.button_cancel_ask_the_start_new_order
                : clearCardBtnNo
            }
            mTextBtnSecondary={
              getThemeSettings?.text?.button_confirm_ask_the_start_new_order
                ? getThemeSettings?.text?.button_confirm_ask_the_start_new_order
                : clearCardBtnYes
            }
            mBtnPrimarClassName={
              isLayout("RAGAZZO") ? "outline-primaryNew" : "outline-primary"
            }
            mBtnSecondaryClassName="buttonAddCar"
          />
        </div>
      )}

      <SidenavContainer />

      <CartContainer statusModal={setModal} />
    </>
  );
};

export default HomeContainer;
