import React, { Component } from "react";
import PanelStatusPdv from "./panel-status-pdv";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { isSmartPos } from "../../../aaone/configuration";

class PanelStatusPdvContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listCashDesks: [],
    };
  }

  componentDidMount() {
    this.startPanel();
  }

  setStatusGrid = (item) => {
    if (item.closingDate) {
      return "FECHADO";
    } else if (item.reducingDate) {
      return "REDUZIDO";
    } else return "ABERTO";
  };

  startPanel = () => {
    let listCashDesks = this.props.listCashdesks.map((item) => ({
      ...item,
      openingDateFormatted: formatDateHelper(
        new Date(item.openingDate),
        "dd/MM/yyyy HH:mm:ss"
      ),
      formattedPosId: `${item.posId} ${item.isAAOne ? "(AA)" : ""}`,
      openingCount: item.openingCount,
      reducingDateFormatted: item.reducingDate
        ? formatDateHelper(new Date(item.reducingDate), "dd/MM/yyyy HH:mm:ss")
        : "",
      status: this.setStatusGrid(item),
    }));

    this.setState({ listCashDesks });
  };

  render() {
    const { listCashDesks } = this.state;

    return (
      <PanelStatusPdv
        title={
          isSmartPos
            ? "SELECIONE A ABERTURA"
            : "SELECIONE A ABERTURA PARA O FECHAMENTO"
        }
        handleClose={() => this.props.handleClose()}
        onHandleSelectPdv={this.props.onHandleSelectClosingDate}
        list={listCashDesks}
      />
    );
  }
}

export default PanelStatusPdvContainer;
