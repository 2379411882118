import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .content {
    background-color: #fff;
    max-width: 600px;
    width: 100%;

    overflow: hidden;

    border-radius: 0.5rem;
    margin: 0 auto;
    padding: 1rem;

    .mx-10 {
      margin-right: 10px;
      margin-left: 10px;
    }

    .p-10 {
      padding: 10px;
    }

    .d-flex {
      display: flex;
    }

    .flex-grow-1 {
      flex-grow: 1;
    }

    .row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
    }

    .header-modal {
      display: flex;
      justify-content: flex-end;

      .btn-close {
        background-color: transparent;
        border: none;
        padding: 5px;
      }
    }

    .form__container {
      width: 100%;
      display: flex;

      .keyboard-numeric-content,
      .details-content {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      .details-content {
        font-family: "Inter", sans-serif;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        justify-content: space-between;
        padding: 1rem;
        .details {
          display: grid;
          grid-row-gap: 20px;

          span {
            font-family: Dosis;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 700;

            &.points {
              color: #0574e9;
            }
          }

          .input-adornment {
            display: inline-block;
            position: relative;
            input {
              padding-right: 29px;
            }
            .icon {
              align-items: center;
              display: flex;
              height: 25px;
              justify-content: center;
              margin: 2px;
              position: absolute;
              right: 0px;
              top: calc(50% - 2px);
              transform: translate(0%, -50%);
              width: 25px;

              .loading {
                animation: ${rotate} 2s linear infinite;
              }

              .checked {
                color: #09843a;
              }
            }
          }

          .invalid-cpf {
            color: #c0000c;
            font-size: 12px;
            font-weight: 600;
            margin-top: 0.25rem;
          }
        }
      }

      input {
        border: 1px solid #c8cbd0;
        height: 40px;
        width: 100%;
      }
    }

    .keyboard-numeric {
      button {
        border-radius: 5px !important;
        color: #411e5a;
        font-size: 1.3rem !important;

        &.button-backspace-item {
          color: #000;
        }

        &.button-send-item {
          background-color: #09843a;
          color: #fff;
        }
      }
    }

    .buttons__groups {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 1rem;
      width: 100%;
    }

    button {
      cursor: pointer;
    }

    .button__simple {
      flex: 1;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &.button__no-border__radius {
        border-radius: 0;
      }

      span {
        margin: 0 0.5rem;
      }

      & + .button__simple {
        margin-left: 1rem;
      }

      &.button__login {
        background-color: ${({ theme }) => theme.colors.purple};
        color: #fff;

        &::disabled {
          background-color: ${({ theme }) => theme.colors.purpleDisabled};
        }
      }
    }
  }

  &.isSmartPos {
    .form__container {
      flex-direction: column;

      .details-content {
        width: 100%;
      }

      .buttons__container {
        margin-top: 1rem;

        .button__default + .button__default {
          margin-left: 1rem;
        }
      }

      .keyboard-numeric-content {
        display: none !important;
      }
    }
  }
`;
