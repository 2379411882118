import styled from "styled-components";

export const AccessibilityWrapper = styled.div`
  background: #fff;
  color: #000;

  border-radius: 0.5rem;
  font-size: 1.85rem;
  padding: 0.75rem;

  position: absolute;
  z-index: 999999999;
  right: 2rem;
  top: 2rem;

  .container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      margin-right: 16px;
      font-size: 24px;
    }

    .button-font-size {
      font-weight: bold;
      font-size: 40px;
      width: 56px;
    }
  }
`;
