import React from "react";
import { CurrencySelectorWrapper, CurrencySelectorButton } from "./style";
import CurrencyInput from "react-currency-input";
import classNames from "classnames";
import { isSmartPos } from "../../../../aaone/configuration";

const buttons = isSmartPos
  ? []
  : [
      { label: "R$ 5,00", value: 5 },
      { label: "R$ 10,00", value: 10 },
      { label: "R$ 20,00", value: 20 },
      { label: "R$ 50,00", value: 50 },
      { label: "R$ 100,00", value: 100 },
    ];

const CurrencySelector = ({ setValue = () => {}, inputValue }) => {
  const updateValue = (btn) => {
    var newValue = parseFloat(inputValue || 0) + parseFloat(btn.value);
    setValue(newValue);
  };

  const handleChange = (value) => {
    value = String(value).replace(".", "").replace(",", ".");
    setValue(parseFloat(value));
  };

  return (
    <CurrencySelectorWrapper className={classNames({ isSmartPos })}>
      <div className="moeda">
        <CurrencyInput
          id="currency-input-opening-amount"
          value={inputValue ? inputValue : "0,00"}
          defaultValue="0"
          inputMode="numeric"
          onChange={(value) => handleChange(value)}
          decimalSeparator=","
          thousandSeparator="."
          autoFocus={false}
          onClick={() => {
            if (!inputValue) {
              handleChange("0.00");
            }
          }}
          readOnly={inputValue === null}
        />
      </div>
      {buttons.map((button) => (
        <CurrencySelectorButton
          onClick={() => updateValue(button)}
          key={button.value}
        >
          {button.label}
        </CurrencySelectorButton>
      ))}
    </CurrencySelectorWrapper>
  );
};

export default CurrencySelector;
