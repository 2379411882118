export const PagesEnum = {
  LOGIN: "/",
  HOME: "/home",
  SETTINGS: "/settings",
  SAVE_LOGS: "/save-logs",
  PAYMENT_METHODS: "/payment-methods",
  PAYMENT_SUCCESSFUL: "/payment-successful",

  //  Payment Methods
  PAYMENT_HOW_MUCH_WILL_YOU_PAY: "/payment-how-much-will-you-pay",
  PAYMENT_TESTQA_METHOD: "/payment-testqa-method",
  PAYMENT_CASH_METHOD: "/payment-cash-method",
  PAYMENT_WITH_CARD_METHOD: "/payment-with-card-method",
  PAYMENT_QR_LINX_METHOD: "/payment-qrlinx-method",
  PAYMENT_POS_METHOD: "/payment-pos",

  //  MENU
  CASH_OPEN: "/cash-open",
  CASH_SUPPLY: "/cash-supply",
  CASH_CLOSE: "/cash-close",
  CASH_CLOSE_WITHDRAW: "/cash-close-withdraw", // Sangria de caixa no Fechamento
  CASH_CLOSE_REDUCE: "/cash-close-reduce", // Sangria de caixa na Redução
  CASH_WITHDRAW: "/cash-withdraw",
  CASH_ARMOURED_CAR: "/armoured-car",
  CASH_REDUCE: "/cash-reduce",
  PRODUCTS_UNAVAILABLE: "/products-unavailable",
  SALES_HOURLY: "/sales-hourly",
  SALES_DAILY: "/sales-daily",
  REPORT_CASHIER_CLOSING: "/cashier-closing",
  MONITOR_TEF: "/monitor-tef",
  MONITOR_NOTES: "/monitor-notes",
  SERIES_NFCE_CONFIG: "/series-nfce-config",
  SALES_RETURN: "/sales-return",
  PRINTER_SETTINGS: "/printer-settings",
  MIDE_SETTINGS: "/mide-settings",
  CANCELED_ITEMS: "/canceled-items",
  SALLER_TABLE: "/saller-table",
  TEF_SETTINGS: "/tef-settings",

  // Auth Validator
  NEED_AUTH: "/need-auth",
  FIDELITY_COUPON: "/fidelity/coupon",
};
