import React from "react";

export const LinxReshopIconLogo = ({ ...props }) => {
  return (
    <svg
      width={71}
      height={66}
      viewBox="0 0 71 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.19 59.03c11.24 0 20.35-9.111 20.35-20.35s-9.11-20.35-20.35-20.35c-11.239 0-20.35 9.111-20.35 20.35s9.111 20.35 20.35 20.35z"
        fill="url(#paint0_linear_102_144)"
      />
      <path
        d="M19.93 21.35a2 2 0 00-2.1.19c-1.13.89-2.53 4.66-3.06 8.23a22.36 22.36 0 006.33 19 22.13 22.13 0 0011.78 6.35c2.833.44 5.717.44 8.55 0a22.81 22.81 0 0014.11-9.06l.66-.89.62-1.09a22.93 22.93 0 001.47-19.22A26.002 26.002 0 0057 22.1l-.27-.5a14.75 14.75 0 00-1.18-1.84A22.66 22.66 0 0028 12.22c-1.83.78-2.8 1.45-2.61 2.78a1.92 1.92 0 001 1.55 2.84 2.84 0 002.61-.18 19 19 0 0110.22-1.76 18.56 18.56 0 0115.89 14.07c.338 1.417.472 2.875.4 4.33a14.4 14.4 0 01-.51 4.47 24.938 24.938 0 01-1 3.06l-.37.83-.39-.78A11.55 11.55 0 0046 35l-.79-.23.37-.66c.91-1.61 1.19-2.93 1.21-5.65A9.93 9.93 0 0040 18.18a11.47 11.47 0 00-5.55-.07 9.999 9.999 0 00-6.61 6.55 11.002 11.002 0 00-.41 3.76 10.37 10.37 0 001.28 5.82l.28.45-1.41.47c-.93.31-1.828.713-2.68 1.2a12.71 12.71 0 00-3.85 4.27l-.34.7-.27-.58a18.46 18.46 0 010-15.68c.3-.632.499-1.307.59-2a2 2 0 00-1.1-1.72zM42 31.93a5.6 5.6 0 01-4.9 2.94 5.37 5.37 0 01-5-3.11c-.55-1.11-.56-1.2-.56-3.32s0-2.26.44-3.12a5.34 5.34 0 012.67-2.82 3.69 3.69 0 012.67-.5 4.49 4.49 0 012.42.57 5.669 5.669 0 012.94 4.86 9.74 9.74 0 01-.68 4.5zm-9.46 6.21c.402.081.794.205 1.17.37a7.83 7.83 0 003.3.44 8.381 8.381 0 003.48-.45l1.14-.4 1.74.46c2.84.76 3.64 1.13 4.75 2.23a6.32 6.32 0 012 3.92l.2 1.17-.87.77a18.82 18.82 0 01-7.75 4.15c-1.483.41-3.025.565-4.56.46a13.821 13.821 0 01-4.54-.45 18.941 18.941 0 01-7.72-4.11c-1-.83-1-1-.71-2.49a7.1 7.1 0 013.46-4.57 27.767 27.767 0 014.95-1.5h-.04z"
        fill="#414042"
      />
      <path
        d="M21.76 20.22a2 2 0 00.88.21c.355-.006.7-.11 1-.3a2.14 2.14 0 00.95-1.82 1.62 1.62 0 00-.59-1.38 1.879 1.879 0 00-2.79 0 2 2 0 00-.58 1.76 2.07 2.07 0 001.13 1.53zM69.48 26.42a32.058 32.058 0 00-1.91-6.11 32.631 32.631 0 00-3-5.53 32.269 32.269 0 00-4-4.81 33.097 33.097 0 00-4.86-4c-.84-.56-1.71-1.08-2.6-1.57l1.4-.16a2.07 2.07 0 001.85-2 1.999 1.999 0 00-.68-1.47 2.07 2.07 0 00-1.57-.48l-9.47 1.1a1.63 1.63 0 00-1.32 1A1.6 1.6 0 0043.47 4l5.7 7.57a2.002 2.002 0 002.88.36 2.05 2.05 0 00.31-2.81l-.63-.83c.58.33 1.14.68 1.69 1a28.51 28.51 0 014.26 3.48 28.577 28.577 0 016.17 9.07 27.787 27.787 0 011.68 5.36 28.28 28.28 0 01-1.67 16.83 2 2 0 00.74 2.48c.332.212.716.327 1.11.33a2 2 0 001.86-1.22 32.42 32.42 0 001.91-19.23v.03z"
        fill="#414042"
      />
      <path
        d="M7.92 38l4.74-8.12a2.06 2.06 0 00-.54-2.69 2 2 0 00-2.38.03 2.07 2.07 0 00-.56.64l-.27.57c.05-.42.11-.83.19-1.19.378-1.839.94-3.635 1.68-5.36A28.581 28.581 0 0117 12.8a28.518 28.518 0 014.26-3.48 28.8 28.8 0 014.89-2.63A29.201 29.201 0 0131.51 5a29.93 29.93 0 015.76-.58 2 2 0 001.913-2.623 2 2 0 00-.413-.717 2.05 2.05 0 00-1.51-.66A33.17 33.17 0 0024.53 3 33.582 33.582 0 0019 6a33.122 33.122 0 00-8.87 8.79 32.1 32.1 0 00-4.94 11.64c-.2 1-.36 1.94-.47 2.92l-.85-1.47A2.09 2.09 0 001.22 27a2 2 0 00-1.1 1.22 2 2 0 00.18 1.61L5.07 38a1.65 1.65 0 002.85 0zM61.54 50.09l-9.23 2.38a2 2 0 00-1.39 2.63 2.08 2.08 0 002.47 1.23l.76-.2-.73.51a29.53 29.53 0 01-4.89 2.63 28.436 28.436 0 01-5.41 1.66 29.46 29.46 0 01-11.61 0 28.432 28.432 0 01-5.41-1.66 29.523 29.523 0 01-4.89-2.63A29.09 29.09 0 0117 53.15a28.46 28.46 0 01-6.16-9A2.06 2.06 0 008.33 43a1.999 1.999 0 00-1.27 2.7 31.5 31.5 0 003 5.52 31.853 31.853 0 004 4.82A32.518 32.518 0 0019 60a32.165 32.165 0 005.57 3 33.07 33.07 0 0025.56 0 31.904 31.904 0 005.58-3c.64-.42 1.27-.88 1.87-1.35l-.41 1.49a2 2 0 001.12 2.42 1.9 1.9 0 00.81.17 2 2 0 001.9-1.51l2.54-9.1a1.59 1.59 0 00-.41-1.58 1.62 1.62 0 00-1.59-.45z"
        fill="#414042"
      />
      <defs>
        <linearGradient
          id="paint0_linear_102_144"
          x1={2.46009}
          y1={38.68}
          x2={53.4601}
          y2={38.68}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF411F" />
          <stop offset={1} stopColor="#FFBC00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
