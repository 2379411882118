import { createGlobalStyle } from "styled-components";
import { cocoBambuTheme } from "./themes/coco";
import { getThemeSettings } from "../configuration";
import { dynamicTheme } from "./themes/dynamic";
import { isLayoutUtil } from "../shared/utils/isLayout";

export const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 8px;
        animation: ellipsis1 0.6s infinite;
      }
      &:nth-child(2) {
        left: 8px;
        animation: ellipsis2 0.6s infinite;
      }
      &:nth-child(3) {
        left: 32px;
        animation: ellipsis2 0.6s infinite;
      }
      &:nth-child(4) {
        left: 56px;
        animation: ellipsis3 0.6s infinite;
      }
    }
  }

  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  ${isLayoutUtil("cocobambu") && cocoBambuTheme}
  ${Object.keys(getThemeSettings).length > 0 && dynamicTheme}
`;
