import styled from "styled-components";

const CurrencySelectorWrapper = styled.div`
  display: grid;
  grid-gap: 10px;

  input,
  button {
    appearance: none;
    border: 1px solid #c8cbd0 !important;
    border-radius: 4px !important;
    height: 50px;
    font-family: inherit;
    font-size: 1rem;
    padding: 5px;
    outline: none;
    background-color: transparent;

    @media only screen and (${(props) =>
        props.theme?.minimumResolution?.media}) {
      height: 40px;
    }
  }

  button {
    transition: background 0.8s;
  }

  button:active {
    transition: background 0s;
    background-size: 100%;
    background-color: #f1f3f6b8;
  }

  .moeda {
    display: inline-block;
    position: relative;

    input {
      padding-left: 32px;
    }
  }

  .moeda:before {
    position: absolute;
    content: "R$";
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  :not(.isSmartPos) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  &.isSmartPos {
    *:not(.moeda) {
      input,
      button {
        font-size: 0.25rem !important;
      }
    }

    .moeda:before {
      font-size: 0.75rem !important;
    }
  }
`;

const CurrencySelectorButton = styled.button``;

export { CurrencySelectorWrapper, CurrencySelectorButton };
