import styled, { css } from "styled-components";

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px;

  border: 1px solid #e6e6e6;
  border-radius: 8px;

  height: max-content;

  margin-bottom: 10px;
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const InfoBody = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 5px;

  button {
    width: 100%;
    align-items: center;
    align-content: center;
  }

  .btn-print {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #411e5a;
    border-radius: 4px !important;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: 13px !important;
    padding: 7px 20px;

    margin-top: 10px;
    outline: none;
    .material-icons {
      color: #fff;
      font-size: 18px;
      margin-right: 3px;
    }
  }

  .btn-cancel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #df1f1a;
    border-radius: 4px !important;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: 13px !important;
    padding: 7px 20px;
    margin-top: 10px;
    outline: none;

    .material-icons {
      color: #fff;
      font-size: 18px;
      margin-right: 3px;
    }
  }
`;

export const TextData = styled.label`
  margin-top: 5px;
`;

export const ContentData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export const TotalWrapper = styled.div`
  margin-top: 5px;
`;

export const CouponWrapper = styled.div`
  width: 50%;
`;

export const NSUWrapper = styled.div`
  width: 50%;
`;
