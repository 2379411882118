import { cpf } from "cpf-cnpj-validator";
import { escOnlyNumbersHelper } from "./escOnlyNumbersHelper";

export const isCPFValidHelper = (value) => {
  const escValue = escOnlyNumbersHelper(value);

  if(escValue === "12345678909"){
    return true;
  }

  const isValid = cpf.isValid(escValue, false);

  return isValid;
}
