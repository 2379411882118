import Configuration from "../aaone/configuration";
import { isLayoutUtil } from "../aaone/shared/utils/isLayout";
import { transactionData } from "../aaone/shared/utils/transactionNumber";
import { CASHDESK_OPEN_ID } from "../pdv-one/constants/keyNamesSessionStorage";
import { getSystemParamsHelper } from "../pdv-one/helpers/getSystemParamsHelper";
import { apiBff, apiProducts } from "./api";
import { callDegustOneAuthorizeService } from "./callDegustOneAuthorizeService";
import { getCurrentSystemDataInStorage } from "./getCurrentSystemVersion";

const reshopCalculateValues = (items) => {
  console.log("reshopCalculateValues :: items ", items);

  try {
    const dataItem = items.map((item, index) => {
      const itemObj = item?.ClassSaleStep?.getSteps() ?? {};

      const CodigoProduto = itemObj?.productCode
        ? itemObj.productCode
        : item.code;
      const PrecoUnitario = itemObj?.value
        ? itemObj?.categoryIsFidelity
          ? 0.01
          : itemObj.value
        : item.price;
      const ProdutoResgate = itemObj?.categoryIsFidelity
        ? itemObj.categoryIsFidelity
        : item.currencyPoints;
      const ItemPoints = itemObj?.pointPrice
        ? itemObj.pointPrice
        : item.valuePoints;
      const Qtde = item?.quantity ? item.quantity : item.qtd;
      const PontosResgate = Qtde * ItemPoints;

      return {
        CodigoProduto,
        CodigoSku: CodigoProduto,
        CodigoVendedor: "0",
        Desconto: 0,
        Item: item[index],
        PontosResgate,
        PrecoUnitario,
        ProdutoResgate,
        Qtde: item.qtd,
        ValorDescontoUnico: 0.0,
        ValorLiquido: Qtde * PrecoUnitario,
      };
    });

    let clientValue = 0;
    let reshopValue = 0;

    const descontoReshop = localStorage.getItem("descontoReshop");

    dataItem.forEach((item) => {
      if (item.ProdutoResgate) {
        reshopValue += item.ValorLiquido;
      } else {
        clientValue += item.ValorLiquido;
      }
    });

    if (descontoReshop) {
      clientValue -= descontoReshop;
    }
    return { clientValue, reshopValue };
  } catch (error) {
    console.error(
      `Error in reshopCalculateValues(params: ${items}) :: `,
      error
    );
    return Promise.reject(
      `Error in reshopCalculateValues(params: ${items}) :: `
    );
  }
};

const payament = async () => {
  try {
    const { data } = await apiProducts.get("/api/payments");
    return data;
  } catch (error) {
    console.error(`Error in payament :: `, error);
    return Promise.reject("Error in payament ::");
  }
};

const sendSaleOrder = async (items) => {
  console.log(`sendSaleOrder :: [Etapa AA] items ${JSON.stringify(items)}`);

  let reshopCampaigns = [];
  let discountReshopCampaigns = [];

  if (localStorage.getItem("itensReshopForDiscount")) {
    try {
      discountReshopCampaigns = JSON.parse(
        localStorage.getItem("itensReshopForDiscount")
      );
    } catch (error) {
      discountReshopCampaigns = [];
    }

    discountReshopCampaigns.forEach((t) => {
      reshopCampaigns = [
        ...reshopCampaigns,
        ...t.Itens.map((i) => ({
          code: t.Id,
          description: t.DescricaoCampanha,
          name: t.NomeCampanha,
          discount: Number(i.ValorTotalDescontoItem.toFixed(2)),
          productCode: i.CodigoProduto,
          itemReshopIndex: i.Item,
        })),
      ];
    });
  }

  let data = {};
  let campaign = null;
  let valueTotalCombo = 0;
  let porcentagemItem = 0;
  let totalDiscount = 0;

  let dataItem = items.map((i, index) => {
    const discount = i.promotion?.discount ? i.promotion.discount * i.qtd : 0;
    totalDiscount += discount;

    let produtoPrincipal = null;
    let produtoCombinado = i.customizations?.length > 0;
    if (
      i.vit_numlanctoprincipal != null &&
      i.vit_numlanctoprincipal != undefined
    ) {
      produtoPrincipal = items.find(
        (f) => f.vit_numlancto == i.vit_numlanctoprincipal
      );

      if (produtoPrincipal.customizations?.length > 0) {
        produtoCombinado =
          produtoPrincipal.customizations.find((c) => c.includes(i.name)) !=
          null;
      }

      if (produtoCombinado) i.price = i.value;
    }

    if (i.value && !isLayoutUtil("BOBS")) {
      data = {
        dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        prismaNumber: i.prismaNumber,
        pagerNumber: i.pagerNumber,
        productCode: i.code,
        nuuidOne: i.nuuidOne,
        description: i.name,
        price: (i.value ? 0 : i.value[0]) || i.value,
        value:
          (i.vit_numlanctoprincipal ? 0 : i.price) || i.vit_numlanctoprincipal
            ? i.value
            : 0,
        quantity: i.qtd,
        discount: i.reshopCampaigns
          ? discount
          : isLayoutUtil("AAONE") && index === 0
          ? Number(localStorage.getItem("descontoReshop"))
          : discount,
        subtotal: (i.qtd * i.value ? 0 : (i.price ?? 0) * i.qtd).toFixed(2),
        vit_numlancto: i.vit_numlancto || null,
        vit_numlanctoprincipal: i.vit_numlanctoprincipal || null,
        originalValue: i.originalValue ?? null,
        promotion: i.promotion || {},
        isCombination: i.isCombination || false,
        reshopPontos: i.valuePoints,
        observation: i.observation,
      };

      if (i.vit_numlanctoprincipal == null) {
        valueTotalCombo = isLayoutUtil("BOBS")
          ? i.price * i.qtd
            ? i.price * i.qtd
            : 0
          : i.qtd * i.value
          ? 0
          : i.price * i.qtd;
        console.log(valueTotalCombo);
        campaign = reshopCampaigns
          .filter(
            (c) =>
              c.productCode == i.code && i.vit_numlancto == c.itemReshopIndex
          )
          .map((c) => {
            return Object.assign(c, { discount: c.discount });
          });
      }

      porcentagemItem =
        (((i.vit_numlanctoprincipal ? 0 : i.price) || i.vit_numlanctoprincipal
          ? i.value * i.qtd
          : 0) *
          100) /
        valueTotalCombo;

      if (
        campaign &&
        (i.vit_numlanctoprincipal != null || isLayoutUtil("BOBS"))
      ) {
        data.reshopCampaigns = campaign.map((i) => {
          const teste1234 = i.discount;
          return Object.assign({}, i, {
            discount: Number((i.discount * porcentagemItem) / 100),
          });
        });
      }

      return data;
    }

    data = {
      dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
      productCode: i.code,
      nuuidOne: i.nuuidOne,
      prismaNumber: i.prismaNumber,
      pagerNumber: i.pagerNumber,
      description: i.name,
      value: i.price,
      quantity: i.qtd,
      discount: i.reshopCampaigns
        ? 0
        : isLayoutUtil("AAONE") && index === 0
        ? Number(localStorage.getItem("descontoReshop"))
        : 0,
      subtotal: (i.qtd * i.price).toFixed(2),
      vit_numlancto: i.vit_numlancto || null,
      vit_numlanctoprincipal: i.vit_numlanctoprincipal || null,
      promotion: i.promotion || {},
      isCombination: i.isCombination || produtoCombinado || false,
      reshopPontos: i.valuePoints,
    };
    console.log(index);

    const campaignSingle = reshopCampaigns.filter(
      (c) => c.productCode == i.code
    );

    if (campaignSingle) {
      data.reshopCampaigns = campaignSingle;
    }

    return data;
  });

  let valueTotal = JSON.parse(localStorage.getItem("clientValue"));
  let reshopTotal = JSON.parse(localStorage.getItem("reshopValue"));

  const sum = valueTotal + reshopTotal;
  const sumTotalOrder = valueTotal + reshopTotal + totalDiscount * -1;

  const payments = JSON.parse(window.localStorage.getItem("payments"));
  console.log(`getItem(payments) | ${JSON.stringify(payments)}`);

  const hasItensReshop = items.some((i) => i.currencyPoints === true);
  const everyItensReshop = items.every((i) => i.currencyPoints === true);

  const reshopValue = JSON.parse(localStorage.getItem("reshopValue")) ?? 0;
  const sendRehopValue = parseFloat(reshopValue?.toFixed(2));

  let listPaymentFidelity = {
    dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
    description: "Fidelidade",
    controlCode: JSON.parse(localStorage.getItem("IdTransacao")),
    authorizationCode: JSON.parse(localStorage.getItem("Nsu")),
    value: sendRehopValue,
    reshop: true,
    paymentCode: getSystemParamsHelper().defaultPaymentCodeReshop ?? 0,
  };

  const getListPayments = () => {
    let dataPayments = payments.map((payment) => {
      return {
        dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        dtefCode: payment.tefGateway !== "SITEF" ? payment.brandCode : 0,
        sitefCode:
          payment.tefGateway === "SITEF"
            ? payment.transactionType === "PIX"
              ? payment.idCarteiraDigital
              : payment.brandCode
            : 0,
        transactionType: payment.transactionType,
        description: payment.transactionType,
        value: parseFloat(payment?.amount?.toFixed(2)),
        controlCode: payment.controlCode
          ? payment.controlCode
          : payment.controlCode,
        authorizationCode: payment.authorizationCode
          ? payment.authorizationCode
          : payment.controlCode,
        paymentTransition: window.localStorage.getItem("paymentTransition"),
        reshop: payment.reshop,
        // paymentCode: 0,
        authorizationCodeRede: payment.authorizationCodeRede
          ? payment.authorizationCodeRede
          : payment.receiptNumber,
        authorizationCodeOnline: payment.authorizationCodeOnline
          ? payment.authorizationCodeOnline
          : null,
        qrLinxTransactionId: payment.transactionId ?? null,
        qrLinxTradingName:
          payment.tefGateway !== undefined
            ? payment.tradingName ||
              "" + "" + payment.tefGateway.replace("Payment Method:", "")
            : null,
        sticker: payment.sticker ?? null,
        paymentResponseStone: JSON.parse(
          localStorage.getItem("paymentResponseStone")
        ),
        carPlate: payment.carPlate ?? null,
        semPararLane: payment.semPararLane ?? null,
        transactionDatetime:
          transactionData(
            payment.transactionDatetime
              ? new Date(payment.transactionDatetime)
              : new Date(),
            "MM/dd/yyyy hh:mm:ss"
          ) ?? null,
      };
    });

    if (hasItensReshop !== everyItensReshop) {
      dataPayments.push(listPaymentFidelity);
    }

    if (everyItensReshop) {
      return [
        {
          dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
          description: "Resgate Fidelidade",
          controlCode: JSON.parse(localStorage.getItem("IdTransacao")),
          authorizationCode: JSON.parse(localStorage.getItem("NsuReshop")),
          value: sendRehopValue,
          reshop: true,
          paymentCode: "7",
          authorizationCodeOnline: null,
        },
      ];
    }

    return dataPayments;
  };

  try {
    const cashDeskOpenDateId = sessionStorage.getItem(CASHDESK_OPEN_ID) ?? undefined;

    const bodySale = {
      pdvCode: Configuration.terminalid,
      pistaDrive: localStorage.getItem("pistaDrive"),
      carSpace: localStorage.getItem("carSpace"),
      layoutSale: localStorage.getItem("styleAA"),
      carIdentification: localStorage.getItem("carIdentification"),
      prismaNumber: localStorage.getItem("prismaNumber"),
      pagerNumber: localStorage.getItem("pagerNumber"),
      operatorCode: localStorage.getItem("UserCode"),
      dateTimeStart: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
      totalProductValue: parseFloat(sum?.toFixed(2)),
      totalOrderValue: parseFloat(sum?.toFixed(2)),
      discount: 0,
      totalReshopDiscount: Number(localStorage.getItem("descontoReshop")),
      consumptionPlace: window.localStorage.getItem("typeCode"),
      client: {
        name: window.localStorage.getItem("name"),
        document: window.localStorage.getItem("cpfDocument"),
        phone: window.localStorage.getItem("phoneNumber"),
      },
      AAMobile: localStorage.getItem("AAMobile"),
      items: dataItem,
      payments: getListPayments(),
      paymentTransition: window.localStorage.getItem("paymentTransition"),
      reshop: {
        cpfReshop: localStorage.getItem("cpf"),
        numeroOperacao: localStorage.getItem("numeroOperacao"),
        totalPontosVenda: localStorage.getItem("getPointsTotal"),
        clienteValorPonto: localStorage.getItem("ValorPonto"),
        contingenciaReshop: localStorage.getItem("ContingenciaReshop"),
        NsuReshop: JSON.parse(localStorage.getItem("NsuReshop")),
      },
      typeSiteSaleId: getTypeSiteSaleId(),
      cashDeskOpenDateId
    };

    const systemParams = getCurrentSystemDataInStorage();

    if (systemParams?.isOneIntegration)
      data = await callDegustOneAuthorizeService(
        systemParams?.retailerId,
        bodySale
      ).catch((err) => {
        console.log("callDegustOneAuthorizeService :: error ", err);
        return Promise.reject(err.data || err.message);
      });
    else data = (await apiBff.post("/process", bodySale)).data;
    if (!data.isSuccess) {
      window.desktopApp.publish("machine.devices.tef.undo", {});

      console.error(`Error in sendSaleOrder(params: ${items}) :: `);
      return Promise.reject(`Error in sendSaleOrder(params: ${items}) :: `);
    }
    return data;
  } catch (error) {
    window.desktopApp.publish("machine.devices.tef.undo", {});

    console.error(`Error in sendSaleOrder(params: ${items}) :: `, error);
    return Promise.reject(`Error in sendSaleOrder(params: ${items}) :: `);
  }
};

const getAPIPayments = async ({ getall = false }) => {
  try {
    const { data } = await apiProducts.get("/api/payments", {
      params: {
        getall,
        stayungrouped: getall,
      },
    });

    return data;
  } catch (err) {
    console.error("/authenticate ", err);
    return Promise.reject("/authenticate");
  }
};

// Retorna o tipo de venda
const getTypeSiteSaleId = () => {
  try {
    const systemParams = getCurrentSystemDataInStorage();

    if (!systemParams.isAutoatendimentoOne) {
      return systemParams.vendaSiteBalcao;
    } else {
      return systemParams.vendaSiteAutoatendimento;
    }
  } catch (err) {
    console.error("getTypeSiteSaleId: " + err);
    return 0;
  }
};

export { sendSaleOrder, reshopCalculateValues, getAPIPayments, payament };
