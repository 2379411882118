import axios, { AxiosError } from "axios";
import * as AxiosLogger from "axios-logger";
import Configuration, {
  getFiscalFlowEndpoint,
  getBFFEndpoint,
  getCashdeskEndpoint,
  getRoutingEndpoint,
  getDegustOneOrderEndpoint,
  getDegustOneTaxEndpoint,
  getProductsAll,
  getReshopEndpoint,
  getTerminalID,
  getStoreServerId,
  getStoreFranchiseId,
  getStoreId,
  getRetailerId,
  isPDVOne,
} from "../aaone/configuration";

import { GET_ACCESS_TOKEN_KEY_NAME, TOKEN_USER_AUTH_KEY_NAME } from "../pdv-one/constants/keyNamesSessionStorage";
import { checkTokenIsValidService } from "./checkTokenIsValidService";
import { PagesEnum } from "../pdv-one/constants/pagesEnum";

const { terminalid } = Configuration;

const headers = {
  ...(terminalid && { terminalid: getTerminalID() }),
  logtoken: 1,
  storeserverid: getStoreServerId(),
  storefranchiseid: getStoreFranchiseId(),
  storeid: getStoreId(),
  retailernuuid: getRetailerId(),
};
const event = new Event("errorResponseAxios");

const alterStatusResponseServices = (response) => {
  let errorResponseAxios = JSON.parse(
    localStorage.getItem("errorResponseAxios")
  );

  if (errorResponseAxios === null) {
    errorResponseAxios = {
      API: true,
      BFF: true,
    };
  } else {
    errorResponseAxios[response.service] = response.status;
  }

  localStorage.setItem(
    "errorResponseAxios",
    JSON.stringify(errorResponseAxios)
  );
};

const checkServicesStatus = () => {
  const services = ["API"];

  services.forEach((service) => {
    const consultService = HandleCheckStatusService(service);

    consultService.catch(() => {
      alterStatusResponseServices({
        service,
        status: false,
      });

      window.dispatchEvent(event);
    });
  });
};

const checkUnauthorized = (error) => {
  const url = error.request.responseURL;
  if (!url.includes('authenticate') && !url.includes('layout')) {
    const [message] = error.response.data.message.split(' | ');

    if (error.response.data.tokenError) {
      if (sessionStorage.getItem(TOKEN_USER_AUTH_KEY_NAME)) {
        alert(message ?? 'Falha ao usar o token. Clique em trocar usuário e entre novamente.');
        sessionStorage.removeItem(TOKEN_USER_AUTH_KEY_NAME);
        window.location.href = `#${PagesEnum.LOGIN}`;
      }
    }
  }
}

const checkIfTokenIsValid = async config => {
  const tokenIsInvalidText = 'Token inválido';

  const isValid = await checkTokenIsValidService();

  if (!isValid) {
    throw new Error(tokenIsInvalidText);
  }

  return config;
};

const requestLoggerOptions = {
  dateFormat: "HH:MM:ss",
  status: true,
  headers: true,
};

const getAccessToken = (method = "bff") => {
  const keyStorageName = GET_ACCESS_TOKEN_KEY_NAME(method);

  const token =
    window.sessionStorage.getItem(keyStorageName) ??
    window.localStorage.getItem(keyStorageName) ??
    false;

  if (token) {
    return token.replace("Bearer ", "").trim();
  }

  return token;
};

const interceptorRequest = (method = "bff", prefixText = "API BFF") => {
  return (request) => {
    const access_token = getAccessToken(method);

    if (access_token) {
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${access_token}`,
        },
      };
    }

    AxiosLogger.requestLogger(request, {
      ...requestLoggerOptions,
      prefixText,
    });

    return request;
  };
};

const ApiDegustOneOrder = () => {
  const baseURL = getDegustOneOrderEndpoint() || "http://localhost:9050";
  return axios.create({
    baseURL,
    headers,
  });
};

const ApiDegustOneTax = () => {
  const baseURL = getDegustOneTaxEndpoint() || "http://localhost:9110";
  return axios.create({
    baseURL,
    headers,
  });
};

const ApiBff = () => {
  const baseURL = getBFFEndpoint() || "http://localhost:3334";
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      pdvone: isPDVOne,
    },
  });

  const access_token = getAccessToken("bff");

  if (access_token) {
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  }

  api.interceptors.request.use(interceptorRequest());

  api.interceptors.response.use(
    (response) => {
      alterStatusResponseServices({ status: true, service: "BFF" });
      return response;
    },
    (error) => {
      console.log(`${JSON.stringify(error)} - ERROR REQUEST API BFF`);

      if (error.request.status === 401) {
        checkUnauthorized(error);
      } else {
        checkServicesStatus();
      }
    }
  );

  return api;
};

const ApiProducts = () => {
  const baseURL = getProductsAll() || "http://localhost:3333";
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      pdvone: isPDVOne,
    },
  });

  const access_token = getAccessToken("products");

  if (access_token) {
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  }

  api.interceptors.request.use(interceptorRequest("products", "API Products"));

  api.interceptors.response.use(
    (response) => {
      alterStatusResponseServices({ status: true, service: "API" });
      return response;
    },
    (error) => {
      console.log(`${JSON.stringify(error)} - ERROR REQUEST API PRODUCT`);
      if (error.request.status === 401) {
        checkUnauthorized(error);
      } else {
        checkServicesStatus();
      }

      if (error.request?.status === 500 && (error.request?.responseURL || '').includes('webmenu/migration')) {
        throw new AxiosError(error);
      }

      return error?.response;
    }
  );

  return api;
};

const ApiReshop = () => {
  const baseURL = Configuration.endPointReshopClient
    ? getProductsAll()
    : getReshopEndpoint() || "https://reshop-stage.linx.com.br";

  if (Configuration.endPointReshopClient) {
    headers.reshopClientBaseURL = Configuration.endPointReshopClient;
    headers.reshopBaseURL = Configuration.endPointReshop;
  }

  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      pdvone: isPDVOne,
    },
  });

  const access_token = getAccessToken("reshop");

  if (access_token) {
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  }

  api.interceptors.request.use(interceptorRequest("reshop", "API Reshop"));

  return api;
};

export const HandleCheckStatusService = () => {
  let url = `${getProductsAll() || "http://localhost:3333"}/api/version`;

  const access_token = getAccessToken("products");

  const config = {
    method: "get",
    url,
    headers: {
      ...headers,
      pdvone: isPDVOne,
      Authorization: `Bearer ${access_token}`,
    },
  };

  return axios(config);
};

const ApiFiscalFlow = () => {
  const baseURL = getFiscalFlowEndpoint();
  return axios.create({
    baseURL,
    headers: {
    },
  });
};

const ApiCashdesk = () => {
  const baseURL = getCashdeskEndpoint();
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      pdvone: isPDVOne,
    },
  });

  const access_token = getAccessToken("bff");

  if (access_token) {
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  }

  api.interceptors.request.use(interceptorRequest());
  //TODO - Ativar se quiser em validação em todas requisição (remover validação em cashWithdrawService e createOrder antes de ativar aqui).
  // api.interceptors.request.use(checkIfTokenIsValid, error => Promise.reject(error));

  api.interceptors.response.use(
    (response) => {
      alterStatusResponseServices({ status: true, service: "BFF" });
      return response;
    },
    () => checkServicesStatus()
  );

  return api;
};

const ApiRouting = () => {
  const baseURL = getRoutingEndpoint();
  const api = axios.create({
    baseURL,
    headers: {
      ...headers,
      pdvone: isPDVOne,
    },
  });

  const access_token = getAccessToken("bff");

  if (access_token) {
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  }

  api.interceptors.request.use(interceptorRequest());
  //TODO - Ativar se quiser em validação em todas requisição (remover validação em cashWithdrawService e createOrder antes de ativar aqui).
  // api.interceptors.request.use(checkIfTokenIsValid, error => Promise.reject(error));

  api.interceptors.response.use(
    (response) => {
      alterStatusResponseServices({ status: true, service: "BFF" });
      return response;
    },
    () => checkServicesStatus()
  );

  return api;
};

export const endPointFidelityReshop = "/api/fidelidade";
export const apiBff = ApiBff();
export const apiDegustOneOrder = ApiDegustOneOrder();
export const apiDegustOneTax = ApiDegustOneTax();
export const apiProducts = ApiProducts();
export const apiReshop = ApiReshop();
export const apiCashdesk = ApiCashdesk();
export const apiRouting = ApiRouting();
export const apiFiscalFlow = ApiFiscalFlow();
