import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 4px;
  width: 100%;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col {
    width: 50%;
  }

  .p-8 {
    padding: 8px;
  }

  .align-items-center {
    align-items: center;
  }

  .brand-container {
    align-items: center;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colors.purple} !important;
    border-radius: 5px !important;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 !important;
    width: 100%;

    img {
      max-height: 50px !important;
      max-width: 100% !important;
    }
  }

  .details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    font-style: normal;
    justify-content: center;

    label {
      font-size: 12px !important;
    }

    .mr-10 {
      margin-right: 10px !important;
    }

    .cpf-content {
      color: #bdbdbd;
      font-size: 12px !important;
      font-style: italic;
      min-width: 100px;

      &.identified {
        color: #000;
        font-style: normal;
        font-weight: 700;
      }
    }

    .points {
      color: #0574e9;
      font-size: 14px !important;
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    .col {
      width: 100%;
    }
  }

  &.isSmartPos {
    padding: 0;

    .content__reshop {
      display: flex;
      flex-direction: row;
      flex-wrap: inherit;

      .logo {
        width: auto;

        .brand-container {
          padding: 6px !important;
        }
      }
    }
  }
`;
