import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const CashCheckWrapper = styled.div`
  display: flex;
  width: 100%;

  .cashclose-keyboard {
    border: none;
  }

  .simple-keyboard.hg-theme-default.myThemeNumeric {
    border: none;
    width: 270px;
  }

  .hide-system-amount {
    position: relative;

    &::after {
      width: 100%;
      height: 100%;
      content: "***********";
      position: absolute;
      left: 0;
      background: #f9f9f9;
    }
  }

  .edit-disabled {
    background-color: #efefef;
  }

  form {
    flex: 1;
  }

  ${(props) =>
    props.useTouchKeyboard === false &&
    css`
      display: flex;
      flex-direction: row-reverse;

      form {
        width: 100%;
      }
    `}

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    flex-direction: column;

    form {
      order: -1;
    }

    .cashclose-keyboard {
      display: none;
    }
  `)}
`;

export const CashGridWrapper = styled.div`
  display: flex;
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 1.5rem 0.5rem;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;

export const InputGroup = styled.div`
  font-family: Roboto, sans-serif;
  color: #414141;
  line-height: 2;

  padding: 5px 10px 0 5px;
  // margin: 11px 0 3px 0;

  span {
    color: #fe2e2e;
    height: 25px;
  }

  small {
    color: #888484;
  }

  input,
  textarea {
    width: 100%;
    height: 50px;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
    font: 400 13.3333px Arial;
    padding: 8px;
  }

  textarea {
    resize: none;
    height: 50px;
    padding-left: 15px;
    padding-top: 12px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CashCloseGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    display: flex;
    flex-direction: column;
  `)}
`;

export const CashCloseFormActions = styled.div`
  padding-top: 16px;

  .btn-custom {
    width: 130px;
    height: 50px;
    margin: 20px 0 0 10px;
    &:focus {
      background-color: #462171;
    }
  }

  text-align: center;

  div + div {
    margin-left: 15px;
  } /* Melhorar isso depois */

  button {
    font-family: Roboto, sans-serif;
    font-weight: 500;
  }
`;

export const GridCellEmpty = styled.div``;

export const CashCloseTable = styled.div`
  table {
    width: 85%;
    margin: 40px auto 20px;

    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      width: 85%;
    }
  }

  caption {
    font-weight: 600;
    margin: 2rem 0;
  }

  thead th {
    font-size: 0.9rem;
    font-weight: 100;
    text-align: left;
    //padding:10px;
    font-family: Roboto, sans-serif;
    width: 70%;
    border: 2px solid black;
    min-width: 100%;
  }

  thead tr {
    display: grid;
    grid-template-columns: 200px 20vw 20vw;
    padding: 0;
    margin: 0;
    position: fixed;
    // top: 162px;
    top: 180px;
    // padding-left: 7px;
    background: #fff;
    width: 60%;
    padding-top: 30px;
    z-index: 1;

    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      top: 160px;
      width: 55%;
      grid-template-columns: 17vw 17vw 17vw;
    }
  }

  tbody tr {
    display: grid;
    grid-template-columns: 200px 20vw 20vw;
    padding: 0;
    margin: 0;

    .input-text {
      padding: 3px 0px 0 4px;
      margin: 0px 0 3px 0;
    }
  }

  tbody td:first-child {
    font-weight: 500;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .hideSystemAmount {
    position: relative;

    &::after {
      width: 50%;
      height: 70%;
      content: "***********";
      position: absolute;
      left: 15px;
      background: #f9f9f9;

      @media only screen and (${(props) =>
          String(props.theme.minimumResolution.media)}) {
        top: 10px;
        left: 10px;
      }
    }
  }

  fieldset {
    padding: 15px 10px !important;
    margin: 20px 0;
    border: 1px solid #ccc;
    height: 10px;
    position: relative;
    z-index: 0;

    > button {
      // position: absolute;
      // right: 0;
      // top: -4px;
      // outline: none;
      // border: none;
      // background: #fff;
      // padding: 0 5px;
      // color: var(--highlight-color);
      position: absolute;
      right: 0;
      top: -10px;
      outline: none;
      border: none;
      background: #fff;
      padding: 5px 12px;
      color: var(--highlight-color);
    }

    &.expand-brand {
      height: inherit;

      > div {
        display: flex;
        grid-template-columns: 200px 1fr 1fr;
        padding: 0;
        margin: 0;
        @media only screen and (${(props) =>
            String(props.theme.minimumResolution.media)}) {
          grid-template-columns: 16vw 1fr 1fr;
        }

        div {
          display: inline-flex;
          align-items: center;
          width: 100%;

          .input-text {
            margin: 0;
            width: 100%;
          }
        }
      }
    }

    > div {
      display: none;
    }

    &.hidden {
      display: none;
    }

    &.without-border {
      // border: none;

      > legend {
        padding: 0;
      }
    }

    legend {
      padding: 0 20px;
    }
  }
  .div-label-counts {
    display: flex;
    justify-content: space-around;
  }
  .div-label-counts span {
    text-align: left;
    width: 50%;
  }
  .fix-width-div-label-counts {
    width: 50% !important;
  }
  .white-space-nowrap {
    white-space: nowrap;
  }
  .box-type-payment-cash-conference {
    display: flex !important;
  }
  .name-type-payment-cash-conference {
    width: 40% !important;
    display: flex;
  }
  .div-value-type-payment-cash-conference {
    width: 45% !important;
  }
  .span-expand-types-cash-conference {
    position: absolute;
    right: 0;
    top: -10px;
    outline: none;
    border: none;
    background: #fff;
    padding: 5px 12px;
    color: var(--highlight-color);
    cursor: pointer;
  }
`;

export const InputTotal = styled.input`
  ${(props) => css`
    text-align: right;
    background: rgba(128, 128, 128, 0.13);
    font-weigth: bold;
    color: ${(props) =>
      props.colorBackground ? props.colorBackground : "none"};
  `}
`;
