import React from "react";

import { useErrorBoundaries } from ".";

export const ErrorBoundary = ( { views, viewId }) => {
  const { hasError } = useErrorBoundaries();

  if(hasError){
    return views['error']
  }

  return views[viewId];
};
