import configuration, { getCodLoja } from "../aaone/configuration";

import { transactionData } from "../aaone/shared/utils/transactionNumber";
import {
  PDV_COD_LOJA_KEY_NAME,
  TOKEN_RESHOP_AUTH_KEY_NAME,
} from "../pdv-one/constants/keyNamesSessionStorage";
import { getCpfInvoiceHelper } from "../pdv-one/helpers/getCpfInvoiceHelper";

const defaultHeaders = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: window.sessionStorage.getItem(TOKEN_RESHOP_AUTH_KEY_NAME),
  };

  // Adicionar estes headers somente se for utilizar a API para acessar o client
  if (configuration.endPointReshopClient) {
    headers.reshopClientBaseURL = configuration.endPointReshopClient;
    headers.reshopBaseURL = configuration.endPointReshop;
  }
  return headers;
};

// Se houver uma config de client reshop, a baseURL deve ser a API, caso contrário o endpoint do reshop especificado
const reshopBaseURL = configuration.endPointReshopClient
  ? configuration.endPointProductsAll
  : configuration.endPointReshop;

const formatDate = () => {
  let data = new Date();
  let dataFormatada =
    data.getFullYear() +
    "-" +
    (data.getMonth() + 1) +
    "-" +
    data.getDate() +
    " " +
    data.getHours() +
    ":" +
    data.getMinutes() +
    ":" +
    data.getSeconds();
  return dataFormatada;
};

const numeroOperacao = `${configuration.terminalid}${transactionData(
  new Date(),
  "MM/dd/yyyy hh:mm:ss"
)}`
  .replace(" ", "")
  .replace(/\//g, "")
  .replace(/:/g, "");

export const verifyRedeemValue = (cartItems) => {
  const url = `${reshopBaseURL}/api/fidelidade/VerifyRedeemValue`;

  const dataItem = cartItems.map((i, indice) => {
    const itemObj = i?.ClassSaleStep?.getSteps();

    const CodigoProduto = itemObj?.productCode ? itemObj.productCode : i.code;
    const Qtde = i?.quantity ? i.quantity : i.qtd;
    const PrecoUnitario =
      itemObj?.value ? (itemObj?.categoryIsFidelity ? 0.01 : itemObj.value) : i.price;
    const ProdutoResgate = itemObj?.categoryIsFidelity
      ? itemObj.categoryIsFidelity
      : i.currencyPoints;
    const ItemPoints = itemObj?.pointPrice ? itemObj.pointPrice : i.valuePoints;
    const PontosResgate = Qtde * ItemPoints;

    let data = {
      Item: ++indice,
      CodigoVendedor: "0",
      CodigoProduto,
      CodigoSku: CodigoProduto,
      Qtde,
      PrecoUnitario,
      Desconto: 0,
      ValorLiquido: Qtde * PrecoUnitario,
      ValorDescontoUnico: 0.0,
      ProdutoResgate,
      PontosResgate,
    };

    return data;
  });

  const getPointsTotal = dataItem.reduce(function (prevVal, elem) {
    return prevVal + elem.PontosResgate;
  }, 0);

  const getValueTotal = dataItem.reduce(function (prevVal, elem) {
    return prevVal + elem.ValorLiquido;
  }, 0);

  const orderValorBruto = dataItem.reduce((acc, cur) => {
    return acc + cur.PrecoUnitario;
  }, 0);

  const calcValorBruto = orderValorBruto.toFixed(2);

  // cur.PrecoUnitario
  const orderValorLiquido = dataItem.reduce((acc, cur) => {
    return acc + cur.PrecoUnitario * cur.Qtde;
  }, 0);

  const calcValorLiquido = orderValorLiquido.toFixed(2);

  const body = JSON.stringify({
    DocumentoFidelidade: localStorage.getItem("cpf") || getCpfInvoiceHelper(),
    CodigoLoja: getCodLoja(),
    DataHora: formatDate(),
    TipoVenda: 1,
    CodigoTerminal: `${configuration.terminalid}`,
    CategoriaVenda: "1",
    QtdeTotal: dataItem.length,
    ValorBruto: Number(calcValorBruto),
    ValorDescontos: 0,
    ValorDescontosUnico: 0,
    ValorAcrescimos: 0,
    ValorLiquido: Number(calcValorLiquido),
    TransacaoAssociada: "",
    Promocode: "",
    NumeroOperacao: numeroOperacao,
    PontosResgateFidelidade: getPointsTotal,
    Itens: dataItem,
    Pagamentos: [],
  });

  const response = fetch(url, {
    method: "POST",
    headers: defaultHeaders(),
    body: body,
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      localStorage.setItem("numeroOperacao", numeroOperacao);

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e) => console.log("verifyRedeemValue ", e));

  return response;
};

export const processesOperation = (cartItems) => {
  const url = `${reshopBaseURL}/api/fidelidade/ProcessaOperacao`;

  const discountReshopCampaigns = JSON.parse(
    localStorage.getItem("itensReshopForDiscount")
  );

  let reshopCampaigns = [];
  discountReshopCampaigns.forEach((t) => {
    reshopCampaigns = [
      ...reshopCampaigns,
      ...t.Itens.map((i) => ({
        code: t.Id,
        discount: Number(i.ValorTotalDescontoItem.toFixed(2)),
        productCode: i.CodigoProduto,
        itemReshopIndex: i.Item,
      })),
    ];
  });
  const dataItem = cartItems.map((i, indice) => {
    let data = {
      Item: ++indice,
      CodigoVendedor: "0",
      CodigoProduto: i.code,
      CodigoSku: i.code,
      Qtde: i.qtd,
      PrecoUnitario: i.price,
      Desconto: 0,
      ValorLiquido: i.price * i.qtd,
      ValorDescontoUnico: 0.0,
      ProdutoResgate: i.currencyPoints,
      PontosResgate: i.qtd * i.valuePoints,
    };

    const campaignSingle = reshopCampaigns.filter(
      (c) => c.productCode == i.code
    );
    if (campaignSingle) {
      campaignSingle.map((d) => {
        const valorDescontoTotal = d.discount;
        data.PrecoUnitario = Number(
          (i.price - (valorDescontoTotal / i.qtd).toFixed(2)).toFixed(2)
        );
        data.ValorLiquido = Number(
          (i.price * i.qtd - valorDescontoTotal).toFixed(2)
        );
      });
    }
    return data;
  });

  const getQtdTotal = dataItem.reduce(
    (prevVal, elem) => prevVal + elem.Qtde,
    0
  );

  const getPointsTotal = dataItem.reduce(function (prevVal, elem) {
    return Number(prevVal + (elem.PontosResgate || 0));
  }, 0);

  localStorage.setItem("getPointsTotal", getPointsTotal);

  const orderValorBruto = dataItem.reduce((acc, cur) => {
    return acc + cur.PrecoUnitario * cur.Qtde;
  }, 0);

  const calcValorBruto = orderValorBruto.toFixed(2);

  const orderValorLiquido = dataItem.reduce((acc, cur) => {
    return acc + cur.PrecoUnitario * cur.Qtde;
  }, 0);

  const calcValorLiquido = orderValorLiquido.toFixed(2);
  const tipoPagamento = localStorage.getItem("paymentTransition");
  let tipoPagamentoNumber = "";
  switch (tipoPagamento) {
    case "TESTEQA":
      tipoPagamentoNumber = "7";
      break;
    case "DEBITO":
      tipoPagamentoNumber = "9";
      break;
    case "CREDITO":
      tipoPagamentoNumber = "3";
      break;
    case "QR LINX":
      tipoPagamentoNumber = "19";
      break;
    case "PEDE PRONTO":
      tipoPagamentoNumber = "20";
      break;
    case "SEM PARAR":
      tipoPagamentoNumber = "7";
      break;
    case "DINHEIRO":
      tipoPagamentoNumber = "1";
      break;
    case "VOUCHER":
      tipoPagamentoNumber = "12";
      break;
    case "PIX":
      tipoPagamentoNumber = "21";
      break;
    default:
      break;
  }

  const body = JSON.stringify({
    DocumentoFidelidade: localStorage.getItem("cpf"),
    CodigoLoja: getCodLoja(),
    DataHora: formatDate(),
    TipoVenda: 1,
    CodigoTerminal: `${configuration.terminalid}`,
    CategoriaVenda: "1",
    QtdeTotal: getQtdTotal,
    ValorBruto: Number(calcValorBruto),
    ValorDescontos: localStorage.getItem("descontoReshop"),
    ValorDescontosUnico: localStorage.getItem("descontoReshop"),
    ValorAcrescimos: 0,
    ValorLiquido: Number(calcValorLiquido),
    TransacaoAssociada: localStorage.getItem("NsuForConsult"),
    Promocode: "",
    NumeroOperacao: numeroOperacao,
    PontosResgateFidelidade: getPointsTotal,
    Itens: dataItem,
    Pagamentos: [
      {
        NumeroPagamento: "1",
        Tipo: tipoPagamentoNumber || "1",
        BinCartao: "",
        Valor: Number(calcValorLiquido),
      },
    ],
  });

  const response = fetch(url, {
    method: "POST",
    headers: defaultHeaders(),
    body: body,
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }
      return res.json();
    })
    .then((data) => {
      if (!data.Result) {
        localStorage.setItem("ContingenciaReshop", body);
      }
      return data;
    })
    .catch((e) => console.log("processesOperation ", e));

  return response;
};

export const confirmsOperation = (
  NSU,
  codLoja = sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME)
) => {
  const url = `${reshopBaseURL}/api/fidelidade/ConfirmaOperacao?codigoLoja=${codLoja}&nsu=${NSU}&confirma=True`;
  const response = fetch(url, {
    method: "GET",
    headers: defaultHeaders(),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e) => console.log("confirmsOperation ", e));

  return response;
};

export const cancelOperation = (
  NSU,
  codLoja = sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME)
) => {
  const url = `${reshopBaseURL}/api/fidelidade/CancelaOperacao?codigoLoja=${codLoja}&nsu=${NSU}&motivoCancelamento=Desistencia`;

  const response = fetch(url, {
    method: "GET",
    headers: defaultHeaders(),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(response.statusText);
      }

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e) => console.log("cancelOperation ", e));

  return response;
};
