import styled from 'styled-components';

export const PeriodWraper = styled.div`
  display: flex;
  flex: 0 0 370px;

  .form-control > div {
    display: flex;
    margin-right: 10px;
  }
`;

export const MessageWraper = styled.div`
  display: flex;
`;
