import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
`;

export const Items = styled.ul`
  display: grid;
  grid-template-columns: 2fr 2fr;
  padding: 0;
`;
export const SelectionFlag = styled.span`
  &::before {
    content: '|';
    color: #ababab;
    font-size: 2.1rem;
    transition: color 0.3s;
  }

  &.active::before {
    color: var(--color-background-primary);
    transaction: color 0.3s ease;
  }
`;

export const Item = styled.li`
  font-size: 1.3rem;
  list-style-type: none;
  letter-spacing: 1px;
  display: flex;
  height: 53px;
  text-align: center;
  align-items: center;
  margin: 4px 20px 0px 15px;
`;

export const Image = styled.img`
  width: 80px;
  &.no-active {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
`;

export const TitleItem = styled.p`
  color: #ababab;
  &.active {
    color: black;
  }
`;
