import styled from "styled-components";

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .button {
    cursor: pointer;

    &.button__logout {
      border-radius: 0;
      margin-top: 1rem;
      width: 100%;
    }
  }

  .content__wrapper {
    background-color: #fff;
    max-width: 450px;
    width: 100%;

    position: absolute;
    overflow: hidden;

    border-radius: 0.5rem;
    margin: 0 auto;

    .content {
      padding: 1rem;
    }

    .button__close {
      position: absolute;
      cursor: pointer;
      right: 1rem;
      top: 1rem;
    }

    .profile {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      width: 100%;

      .avatar {
        background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
        border: 1px solid rgb(235, 235, 235);

        border-radius: 50%;
        height: 80px;
        width: 80px;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        margin-bottom: 1rem;
      }

      .name {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }
    }

    .profile__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .profile__data,
      .wallet__data {
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 100%;
        flex: 1;

        & + .profile__wrapper,
        & + .wallet__data {
          margin-left: 0.5rem;
        }

        border: 1px solid #eee;
        padding: 0.5rem;
      }

      .profile__data {
        ul {
          list-style: none;

          .value {
            font-weight: 700;
          }

          li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
`;
