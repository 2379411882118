import React, { useState, useEffect } from "react";
import { addToCart } from "../../actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { ComboCardIndividual, ComboIndividual } from "../../shared/components/modules";
import { LayoutCustomizationItem } from "../../shared/utils/layoutCustomizationItem";
import { returnsLevelOrientedSale } from "../../shared/utils/returnsLevelOrientedSale"
import { getQuantityCombo } from "../../shared/utils/getQuantityCombo";
import { useLayoutAa } from "../../contexts/layout";
const ComboIndividualContainer = (props) => {
  const {
    backStepCategory,
    orientedSaleSteps,
    product,
    controlNotification,
    submitNextStep,
    promocode,
  } = props;
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const { isLayout } = useLayoutAa();

  const [listSteps, setListSteps] = useState([]);
  useEffect(() => {
      try {
      const list = [];
      /**
       * Only for creating new product by adding the operation type
       * @param sale
       * @returns {*&{operationType: *}}
       */
      const createNewProduct = (sale) => {
        return {
          ...sale.product,
          operationType: sale.operationType
        }
      };
      orientedSaleSteps.forEach((item, index) => {
        const obj = {
          groupCode: item.groupCode,
          description: item.orientedSaleGroup
            ? item.orientedSaleGroup.description
            : item.productWithoutGroup.description,
          listRadioButton: item.orientedSaleGroup
            ? item.orientedSaleGroup.orientedSaleGroupProduct.map(createNewProduct)
            : item.productWithoutGroup.nivel1 &&
              item.productWithoutGroup.nivel1.length > 0
            ? item.productWithoutGroup.nivel1
            : [item.productWithoutGroup],
          quantity: item.quantity > 1 ? item.quantity : null,
          productWithoutGroup: !!item.productWithoutGroup,
          index: index,
          required: item.required,
          composition: item.orientedSaleGroup?.composition ? item.orientedSaleGroup?.composition : false,
        };
        list.push(obj);
      });

      setListSteps(list);
      } catch (error) {
        alert('PRODUTO CADASTRADO INCORRETAMENTE!')
        backStepCategory()

    }
    }, [orientedSaleSteps]);


  const mountingCompositionItem = (items, seq) => {
    if (items.length) {
      items.map((item) => {
        if (item.compositions.length) {
          item.compositions.map((composition, index) => {
            if (composition.selectedAmount) {
              const positionToAddCart = {...composition.product};

              positionToAddCart.vit_numlancto = seq + items.length + (index + 1);
              positionToAddCart.vit_numlanctoprincipal = seq;
              positionToAddCart.quantity = composition.selectedAmount;
              positionToAddCart.qtd = composition.selectedAmount;
              positionToAddCart.isProductComposition = {
                type: 'ADDITIONAL'
              };

              if (composition.operationType === 'R') {
                positionToAddCart.qtd = composition.selectedAmount * -1;
                positionToAddCart.value = 0;
                positionToAddCart.isProductComposition = {
                  type: 'WITHDRAWN'
                };
              }

              items.push(positionToAddCart);
            }
          })
        }
      });
    }
    return items;
  };

  let last_vit_numlancto = 0;

  const onSubmit = (items, indexNumlancto = 0) => {
    const seq =
      cartItems.filter((item) => item.vit_numlancto).length < 1
        ? 1
        : cartItems.map((item) => item.vit_numlancto).sort((a, b) => b - a)[0] +
          1;

    const itemsProductsWithoutGroup = orientedSaleSteps.filter(
      (item) => item.productWithoutGroup
    );

    last_vit_numlancto = last_vit_numlancto + indexNumlancto;

    const currentSeq = seq > last_vit_numlancto ? seq : last_vit_numlancto + 1;

    product.vit_numlancto = currentSeq;

    items = mountingCompositionItem(items, seq);

    // product.value = getValue(items, itemsProductsWithoutGroup);
    const itemsProductValue = getValue(items, itemsProductsWithoutGroup);
    items = items.map((item, index) => {
      orientedSaleSteps.map((saleStep) => {
        const currentProduct =
          saleStep.orientedSaleGroup?.orientedSaleGroupProduct.find(
            (saleGroupProduct) => saleGroupProduct.product._id === item?._id
          );
        const currentValueSingle =
        saleStep.productWithoutGroup?.valueWithoutPromotion
        if(currentValueSingle) {
          // item.promotion = currentProduct.product?.promotion ?? {};
          item.originalValue = currentValueSingle ?? currentValue;
        }

        const currentValue = item.value ?? product.value ?? product.mixValue;

        if (currentProduct) {
          item.discount = currentProduct.product?.promotion?.discount ?? 0;
          // item.promotion = currentProduct.product?.promotion ?? {};
          item.originalValue =
            currentProduct.product.valueWithoutPromotion ?? currentValue;
        } else if (!item?.valueWithoutPromotion) {
          item.promotion = {};
          item.originalValue = currentValue;
        }

        return saleStep;
      });

      item.vit_numlancto = currentSeq + (index + 1);
      item.vit_numlanctoprincipal = currentSeq;

      last_vit_numlancto = item.vit_numlancto;

      return item;
    });

    items.value = itemsProductValue;

    itemsProductsWithoutGroup.forEach((item, index) => {
      if (itemsProductsWithoutGroup[0].quantity < 2) {
        item.productWithoutGroup.vit_numlancto =
          currentSeq + items.length + (index + 1);
        item.productWithoutGroup.vit_numlanctoprincipal = currentSeq;
        item.productWithoutGroup.quantity = item.quantity;
        item.productWithoutGroup.qtd = item.quantity;
        items.push(item.productWithoutGroup);
      }
    });

    addCart(product, items);
    items.forEach((item) => addCart(item));

    if (submitNextStep)
      submitNextStep();
    else
      backStepCategory();
  };

  const getValue = (items, itemsProductsWithoutGroup) => {
    const value = items.reduce(
      (acc, cur) => (acc = acc + (cur.qtd || 1) * +cur.value),
      0
    );
    if (
      itemsProductsWithoutGroup.length > 0 &&
      itemsProductsWithoutGroup[0].productWithoutGroup.nivel1.length > 0 &&
      itemsProductsWithoutGroup[0].quantity > 1 &&
      product.description !== "COMBO CASADINHA + REFR 2L"
    ) {
      return itemsProductsWithoutGroup.length >
        getQuantityCombo()
        ? itemsProductsWithoutGroup.reduce(
            (acc, cur, index) =>
              (acc =
                acc +
                +cur.productWithoutGroup.nivel1.find(
                  (nivel) =>
                    nivel.description === listSteps[index].item.description
                ).value *
                  cur.quantity),
            +value
          )
        : value;
    } else {
      return itemsProductsWithoutGroup.length > 0 &&
        itemsProductsWithoutGroup[0].quantity < 2
        ? itemsProductsWithoutGroup.reduce(
            (acc, cur) => (acc = acc + +cur.productWithoutGroup.value * cur.quantity),
            +value
          )
        : value;
    }
  };

  const addCart = (product, items = []) => {
    let productValue =
      product.value > "0" &&
      product.vit_numlanctoprincipal === undefined &&
      product.qtd === undefined
        ? product.value
        : product.value > "0"
        ? product.value
        : "0";

    const priceProduct = !product.vit_numlanctoprincipal
      ? Number(product.value) + (items?.value > 0 ? items.value : 0)
      : items.value !== undefined ? items.value : 0;

    dispatch(
      addToCart({
        id: product._id,
        name: `${product.description}`,
        customizations: LayoutCustomizationItem(items),
        price:
          product.value > 0
            ? product.value > 0
              ? priceProduct
              : productValue
            : product.value > "0" &&
              product.vit_numlanctoprincipal === undefined &&
              product.qtd === undefined
            ? product.value
            : items.value !== undefined
            ? items.value > 0 ? items.value : product.mixValue
            : 0,
        value: productValue,
        qtd: product.qtd || 1,
        image: product.image,
        vit_numlancto: product.vit_numlancto ? product.vit_numlancto : null,
        vit_numlanctoprincipal: product.vit_numlanctoprincipal
          ? product.vit_numlanctoprincipal
          : null,
        originalValue:
          product.originalValue ?? product.mixValue ?? product.value,
        code: product.productCode,
        classification: product.classification,
        quantityDouble: product.qtd || 1,
        promotion: product.promotion ?? {},
        currencyPoints: items.currencyPoints || false,
        valuePoints: items.currencyPoints ? items.valuePoints : 0,
        isProductComposition: product.isProductComposition,
        promocode: promocode,
        nuuidOne: product.nuuidOne
      })
    );

    if (items.length > 0)
      controlNotification(
        `${product.description} + ${items
          .map((item) => item.description)
          .join(" ")} `
      );
  };

  const getFlavorSelected = (_listSteps, itemSelected) =>
    _listSteps.find(
      (item) =>
        itemSelected.index === item.index &&
        itemSelected.item.description === item.description
    );
  const hasStep = (_listSteps, itemSelected) => {
    _listSteps.find((prop) => {
      if (!itemSelected || !prop || !prop.item) return;

      if (prop === itemSelected && prop.item === itemSelected.item)
        return getFlavorSelected(_listSteps, itemSelected);
    });
  };

  const newStep = (step, index) => {
    let list = [];
    orientedSaleSteps.forEach((item) => {
      const indexSale = index - 1;
      const description = item.orientedSaleGroup
        ? item.orientedSaleGroup.description
        : item.productWithoutGroup
        ? item.productWithoutGroup.description
        : "";
      const selectedValue = listSteps.find(
        (itemStep) =>
          itemStep.groupCode === item.groupCode &&
          itemStep.description === description &&
          ((itemStep.index > 0 && itemStep.index === indexSale) || itemStep.index === 0)
      );

      const subItem = item.orientedSaleGroup
        ? item.orientedSaleGroup.orientedSaleGroupProduct.map(
            (sale) => sale.product
          )
        : item.productWithoutGroup.nivel1 &&
          item.productWithoutGroup.nivel1.length > 0
        ? item.productWithoutGroup.nivel1
        : [item.productWithoutGroup];

      const obj = {
        groupCode: item.groupCode,
        description: item.orientedSaleGroup
          ? item.orientedSaleGroup.description
          : item.productWithoutGroup.description,
        listRadioButton: selectedValue
          ? selectedValue.listRadioButton
          : subItem,
        item: selectedValue ? selectedValue.item : null,
        quantity: item.quantity > 1 ? item.quantity : null,
        productWithoutGroup: !!item.productWithoutGroup,
        index: selectedValue ? selectedValue.index : 0,
        required: item.required,
        orientedSaleGroup: item.orientedSaleGroup,
      };
      list.push(obj);
    });
    list = list.filter((item) => (item.quantity ? item.index < 1 : true));
    listSteps.forEach((item, index) => {
      const getItemSelected = listSteps.find(
        (itemStep) =>
          itemStep.item &&
          itemStep.item.description === item.description &&
          itemStep.item.classification === item.classification
      );

      if (list[0].quantity && item.index < 2) {
        list.splice(index, 1, item);
      }
      if (list[1]) {
        if (list[1].quantity && item.index < 2) {
          list.splice(index, 1, item);
        }
      }
      if (
        item.subProduct &&
        getItemSelected &&
        step?.description &&
        step?.description &&
        !hasStep(list, getItemSelected)
      ) {
        list.splice(index, 0, item);
      }
    });

    if (!step || !step.nivel1 || step.nivel1.length === 0) {
      setListSteps(list);
      return;
    }

    //Criar dinamicamente nova aba para subprodutos, ex: Sabor de suco

    const obj = {
      description: step.description,
      listRadioButton: step.nivel1,
      subProduct: true,
      classification: step.classification,
      index: index,
      required: list[index - 1].required,
    };

    //Remover item de subproduto já existente para adicionar um novo
    if (
      list.find(
        (item) =>
          item.index === obj.index &&
          item.subProduct &&
          item.description !== obj.description
      )
    )
      list.splice(
        list.indexOf((item) => item.index === obj.index && item.subProduct),
        2
      );
    const hasObj = list.filter(
      (item) =>
        item.classification === obj.classification &&
        item.description === obj.description &&
        item.index === obj.index
    );
    if (hasObj.length === 0) list.splice(index, 3, obj);

    setListSteps(list);
  };

  /**
   *
   * @param clickedProduct {Object}
   * @param index {Number}
   */
  const newStepCard = (clickedProduct, index) => {
    let treatedList = [];
    listSteps.forEach((item) => {
      const selectedProduct = item.orientedSaleGroup
          ?.orientedSaleGroupProduct.find((product) => product.productCode === clickedProduct.productCode);

      let itemFound = listSteps.find((step) => {
        if (step.groupCode === item.groupCode && step.index === item.index)
          return step;
        else
          return undefined;
      });
      if (selectedProduct) {
        itemFound = {
          ...itemFound,
          item: clickedProduct
        };
      }
      treatedList.push(itemFound);
    });

    const handleNewStepAditional = (newLevel) => {
      treatedList = treatedList.map((step) => {
        if (step.index === index && !step?.dynamicallyGeneratedStep) {
         return { ...step, index: step.index + 1 };
        } else {
          return step;
        }
      });

      const firstStageSameGroup = treatedList[index - newLevel];
      const newStepAditional = {
        description: clickedProduct.description,
        listRadioButton: clickedProduct[`nivel${newLevel}`],
        subProduct: true,
        classification: clickedProduct.classification,
        index: index,
        required: firstStageSameGroup?.required ? firstStageSameGroup.required : true,
        groupCode: firstStageSameGroup?.groupCode,
        dynamicallyGeneratedStep: true
      };

      treatedList.splice(index, 0, newStepAditional);
      setListSteps(treatedList);
    }

    if (!clickedProduct) {
      setListSteps(treatedList);
      return;
    }

    const existingLevel = returnsLevelOrientedSale(clickedProduct);
    if (
      existingLevel &&
      (!clickedProduct[existingLevel] || !!clickedProduct[existingLevel].length === false)
    ) {
      setListSteps(treatedList);
    } else {
      const [numberLevel] = existingLevel
        .split('nivel')
        .reverse();

      handleNewStepAditional(numberLevel);
    }
  };

  const setValue = (list) => setListSteps(list);

  return (
    <>
      {!isLayout("BOBS") && (
        <ComboIndividual
          product={product}
          steps={orientedSaleSteps}
          onSubmit={onSubmit}
          listSteps={listSteps}
          newStep={(step, index) => newStep(step, index)}
          setValue={(newList) => setValue(newList)}
          backStepCategory={backStepCategory}
        />
      )}
      {isLayout("BOBS") && (
        <ComboCardIndividual
          product={product}
          steps={orientedSaleSteps}
          onSubmit={onSubmit}
          listSteps={listSteps}
          setListSteps={setListSteps}
          newStep={(step, index) => newStepCard(step, index)}
          setValue={(newList) => setValue(newList)}
          backStepCategory={backStepCategory}
        />
      )}
    </>
  );
};

export default ComboIndividualContainer;
