import { getSerieNFCe, isPDVOne } from "../aaone/configuration";
import {
  OBSERVATION_ORDER_KEY_NAME,
  PDV_ORDER_IS_TAKE_OUT,
  RESHOP_DISCOUNTS,
  MANUAL_DISCOUNTS,
} from "../pdv-one/constants/keyNamesSessionStorage";
import { getUserAuthDataHelper } from "../pdv-one/helpers/getUserAuthDataHelper";
import { isNumber } from "util";
import { apiProducts } from "./api";
import Decimal from "decimal.js";
import { DISCOUNT_TYPES } from "../pdv-one/constants/discountEnum";
import { getCurrentSystemDataInStorage } from "./getCurrentSystemVersion";

export const parseAASaleToDegustOneOrderService = async (
  retailerId,
  bodySale
) => {
  console.log(`parseAASaleToDegustOneOrderService | start`);

  try {
    const [currentPayment = null] = bodySale.payments;

    const receiptSeries = getSerieNFCe();

    const systemParams = getCurrentSystemDataInStorage();

    const config = window.mobileConfig
      ? JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {}
      : JSON.parse(window.sessionStorage.getItem("AA_Config"));

    let isTakeOut = JSON.parse(
      localStorage.getItem(PDV_ORDER_IS_TAKE_OUT) === "V"
    )
      ? true
      : false;

    let reshopCampaign = JSON.parse(sessionStorage.getItem(RESHOP_DISCOUNTS));
    let manualDiscounts =
      JSON.parse(sessionStorage.getItem(MANUAL_DISCOUNTS)) || [];

    const userInfo = getUserAuthDataHelper();

    const sequential = (await apiProducts.get("/api/generate-sequential")).data
      .sequential;

    const parseDMY = (dateString) => {
      try {
        let [d, M, y, h, m, s] = dateString.split(/\D/);

        if (!isNumber(y)) return new Date();

        // Month start from zero
        return new Date(y, M - 1, d, h, m, s);
      } catch (e) {
        return new Date();
      }
    };

    const body = {
      posId: bodySale.pdvCode,
      retailerId: retailerId,
      receiptSeries,
      documentNumber: bodySale.client.document,
      customerName: bodySale.client.name,
      cashDeskOpenDateId: bodySale.cashDeskOpenDateId,
      observation:
        localStorage.getItem("name") ||
        localStorage.getItem(OBSERVATION_ORDER_KEY_NAME),
      discount: 0, //bodySale.totalReshopDiscount,
      totalDiscount: 0,
      total: 0,
      addition: 0,
      items: [],
      payments: [],
      deliveryInfo: null,
      sequenceNumber: sequential,
      SaleTypeDescription: systemParams.vendaSiteBalcaoDescricao,
      SaleType: systemParams.vendaSiteBalcao,
      //fiscalType: config?.danfe?.fiscalType == 2 ? "SAT" : "NFCe",
      //fiscalTypeCode: config?.danfe?.fiscalType,
      isTakeOut,
      currentSellerId: userInfo.nuuidOne,
      currentSellerName: userInfo.login,
      currentSellerReferenceId: userInfo.employeeCode,
      typeSiteSaleId: bodySale.typeSiteSaleId,
      danfe56mm: config.printSize == "56mm" ?? true,
      danfeJpg: window?.Android != undefined ?? false,
    };

    const {
      data: { payments: paymentsDetailed },
    } = await apiProducts.get("/api/payments/?getall=true&stayungrouped=true", {
      headers: { pdvone: true },
    });

    bodySale.payments.forEach((p) => {
      let detailed;
      // let detailed = // Dinheiro
      //   paymentsDetailed.find((k) => k.paymentCode == 1);

      // #region 'Reconhecimento de Bandeira'
      if (p.dtefCode != null && p.dtefCode > 0)
        detailed = paymentsDetailed.find(
          (k) =>
            k.integrationCodeDtef === p.dtefCode &&
            k.paymentOneBaseCode ===
              getTransactionCodeFromType(p.paymentTransition, p)
        );
      else if (p.sitefCode != null && p.sitefCode > 0)
        detailed = paymentsDetailed.find(
          (k) =>
            k.integrationCodeSitef === p.sitefCode &&
            k.paymentOneBaseCode ===
              getTransactionCodeFromType(p.paymentTransition, p)
        );
      else if (p.paymentCode !== null && p.paymentCode > 0)
        detailed = paymentsDetailed.find((k) => k.paymentCode == p.paymentCode);

      if (
        detailed == null ||
        detailed == undefined ||
        p.transactionType === "QR LINX" ||
        p.transactionType === "QRLINX"
      ) {
        if (p.transactionType === "QR LINX" || p.transactionType === "QRLINX")
          detailed = paymentsDetailed.find((k) => k.walletLinx);
        else
          detailed = paymentsDetailed.find(
            (k) =>
              k.paymentOneBaseCode ===
              getTransactionCodeFromType(p.paymentTransition, p)
          );

        if (detailed == null || detailed == undefined) {
          const message = "Nenhum meio de pagamento compatível localizado";

          if (window.desktopApp) {
            window.desktopApp.publish("machine.devices.desktop.log", message);
          }

          console.log(message);

          detailed = paymentsDetailed[0];
        }
      }

      //Se nenhuma bandeira for localizada ele não gera a nota
      if (detailed == null || detailed === undefined) {
        const message = {
          message: "Bandeira não localizada",
          dtefCode: p.dtefCode,
          sitefCode: p.sitefCode,
          paymentTransition:
            p?.paymentTransition ?? p?.transactionType ?? p?.description,
          paymentOneBaseCode: getTransactionCodeFromType(
            p.paymentTransition,
            p
          ),
        };

        if (window.desktopApp) {
          window.desktopApp.publish("machine.devices.desktop.log", message);
        }

        console.log(JSON.stringify(message));

        throw message;
      }
      // #endregion

      let authCode = p.authorizationCode;

      if (authCode === null || authCode === undefined || authCode == 0)
        authCode = p.authorizationCodeRede;

      if (authCode === null || authCode === undefined || authCode == 0)
        authCode = p.authorizationCodeOnline;

      console.log(`-----> payment: ${JSON.stringify(p)}`);

      let payment = {
        paymentType: {
          id:
            p?.paymentOneDetails?.nuuidOne ??
            detailed?.paymentOneDetails?.nuuidOne, //nuuidOne
          name:
            p.paymentCode == detailed?.paymentCode
              ? detailed?.paymentOneDetails?.paymentBaseOne?.description ??
                p.paymentTransition
              : p.paymentTransition,
          enumValue:
            p.paymentCode == detailed?.paymentCode
              ? detailed?.paymentOneBaseCode
              : getTransactionCodeFromType(detailed.transactionType, p), //paymentOneBaseCode
          referenceId: p?.paymentCode ?? detailed?.paymentCode ?? 1, // ToDo: obter do reconhecimento de bandeira quando houver
        },
        change: p?.Change ? Number(p.Change) : 0,
        amount: Number((p.value ?? p.Amount).toFixed(2)),
        brand: p?.paymentOneDetails?.brandCode
          ? {
              Name:
                p?.paymentOneDetails?.name ?? p?.paymentOneDetails?.brandName,
              brandNet:
                p?.paymentOneDetails?.brand ??
                detailed?.paymentOneDetails?.brand,
              BrandName: p?.paymentOneDetails?.brandName,
              CNPJ:
                p?.paymentOneDetails?.cnpj ?? detailed?.paymentOneDetails?.cnpj,
              TBand: p?.paymentOneDetails?.brandCode,
              referenceId: p?.paymentCode ?? detailed?.paymentCode ?? 1,
              FiscalCode: p?.paymentOneDetails?.fiscalCode,
            }
          : null,
        installment: null,
        canceled: false,
        creationDate: p.dateTimeInsert
          ? parseDMY(p.dateTimeInsert)
          : new Date(),
        //IsReshopFinalizer

        //coupon: 0,
        //sellerNumber: "string",
        //administratorType: "string",
        //modeCode: "100",
        cash: 0,
      };

      // if (payment.paymentType.referenceId == "1"){
      //   payment.cash = p.amount;
      //   payment.amount = 0;
      // }

      if (!!authCode) {
        payment.brand = {
          name:
            p?.paymentOneDetails?.brandName ??
            detailed?.paymentOneDetails?.brand,
          brandNet:
            p?.paymentOneDetails?.brand ?? detailed?.paymentOneDetails?.brand,
          brandName:
            p?.paymentOneDetails?.brandName ??
            detailed?.paymentOneDetails?.brandName,
          administratorType: 0,
          tBand:
            p?.paymentOneDetails?.brandCode ??
            detailed?.paymentOneDetails?.brandCode,
          cnpj: p?.paymentOneDetails?.cnpj ?? detailed?.paymentOneDetails?.cnpj,
          referenceId: payment.paymentType.referenceId,
          fiscalCode:
            p?.paymentOneDetails?.fiscalCode ??
            detailed?.paymentOneDetails?.fiscalCode,
        };

        payment.installment = {
          referenceId: payment.paymentType.referenceId,
          number: 1, // Quantidade de parcelas
          value: payment.amount,
          interestRate: 0,
        };
        payment.receiptNumber = authCode;
        payment.authorizationCode = authCode;
        payment.controlCode = p.controlCode;
        payment.tefGateway = p.qrLinxTradingName;

        // Tef Code
        payment.brandCode = p?.paymentOneDetails?.brandCode ?? 99;
        if (p.dtefCode != null && p.dtefCode > 0)
          payment.brandCode = p.dtefCode;
        else if (p.sitefCode != null && p.sitefCode > 0)
          payment.brandCode = p.sitefCode;

        payment.networkCodeTef =
          p?.paymentOneDetails?.brandCode ?? payment?.brandCode;
      }

      body.payments.push(payment);
    });

    let totalProduto = 0;
    let totalDiscountPromo = 0;
    let valueTotalCombo = 0;
    let reshopParentItem = [];

    var valoresCombos = bodySale.items
      .filter((i) => i.vit_numlanctoprincipal == null)
      .map((i) => {
        return {
          numLanctoProdutoPrincipal: i.vit_numlancto,
          totalCombo:
            ((Number(i.value) || 0) * i.quantity > 0
              ? (Number(i.value) || 0) * i.quantity
              : (Number(i.subtotal) || 0) * i.quantity) +
            bodySale.items
              .filter((x) => x.vit_numlanctoprincipal === i.vit_numlancto)
              .reduce((acc, curr) => {
                return (
                  acc +
                  ((Number(curr.value) || 0) * curr.quantity > 0
                    ? (Number(curr.value) || 0) * curr.quantity
                    : (Number(curr.subtotal) || 0) * curr.quantity)
                );
              }, 0),
        };
      });

    bodySale.items.forEach((i) => {
      let principalCombo =
        i.vit_numlancto != null &&
        bodySale.items.some((p) => p.vit_numlanctoprincipal == i.vit_numlancto);
      let itemCombo = i.vit_numlanctoprincipal != null || principalCombo;

      valueTotalCombo =
        valoresCombos.find(
          (x) =>
            x.numLanctoProdutoPrincipal === i.vit_numlanctoprincipal ||
            x.numLanctoProdutoPrincipal === i.vit_numlancto
        )?.totalCombo ??
        ((Number(i.value) || 0) * i.quantity > 0
          ? (Number(i.value) || 0) * i.quantity
          : (Number(i.subtotal) || 0) * i.quantity);

      let valorUnitario = Number(i.value + (i.discount ?? 0));
      let valorItem = Number(i.quantity * (valorUnitario));
      let subtotalItem = Number(i.quantity * i.value);
      let valorDescontoItemReshop = 0;

      if (!isPDVOne) {
        valorUnitario = Number(i.originalValue || i.value);
        if (i.vit_numlanctoprincipal === null) // quando for produto de valor zerado, é o principal do MIX | ToDo: melhorar
          valorUnitario = i.value;
        valorItem = Number(i.quantity * valorUnitario);
        subtotalItem = valorItem - (i.discount ?? 0);
      }

      // Porcentagem do Item em cima do valor total do Combo
      let porcentagemItem =
        (((i.vit_numlanctoprincipal ? 0 : Number(i.value) * i.quantity) ||
        i.vit_numlanctoprincipal
          ? Number(i.subtotal)
          : 0) *
          100) /
        valueTotalCombo;

      totalProduto += Number(valorItem.toFixed(2));

      //Adiciona desconto do reshop no item
      if (reshopCampaign?.CampanhasAtivadas) {
        reshopCampaign.CampanhasAtivadas.forEach((campaign) => {
          campaign.Itens.forEach((campaignItem) => {
            // Se o pai for valor zerado deve adicionar o desconto aos filhos no cupom
            if (
              i.productCode === campaignItem.CodigoProduto &&
              i.vit_numlancto === Number(campaignItem.Item)
            ) {
              if (i.value === 0) {
                reshopParentItem.push({ ...i, campaignItem });
              } else if (i.value - campaignItem.ValorDescontoItem < 0) {
                // Se o desconto deixar o item negativo
                const minusValue = new Decimal(i.value)
                  .minus(
                    new Decimal(campaignItem.ValorDescontoItem).mul(
                      campaignItem.Qtde
                    )
                  )
                  .minus(new Decimal(0.01))
                  .mul(new Decimal(-1));

                valorDescontoItemReshop = new Decimal(
                  campaignItem.ValorDescontoItem
                )
                  .mul(new Decimal(campaignItem.Qtde))
                  .minus(minusValue)
                  .toNumber();

                reshopParentItem.push({
                  ...i,
                  campaignItem: {
                    ...campaignItem,
                    ValorDescontoItem: minusValue.toNumber(),
                  },
                });
              } else {
                valorDescontoItemReshop = Number(
                  (campaignItem.Qtde * campaignItem.ValorDescontoItem).toFixed(
                    2
                  )
                );
              }
            }
          });
        });
      }

      // Verifica se existem descontos nos filhos
      reshopParentItem.forEach((reshopItem) => {
        if (reshopItem.vit_numlancto === i.vit_numlanctoprincipal) {
          valorDescontoItemReshop = Number(
            (
              (reshopItem.campaignItem.ValorDescontoItem *
                (porcentagemItem <= 100 ? porcentagemItem : 100)) /
              100
            ).toFixed(2)
          );
        }
      });

      totalDiscountPromo = new Decimal(i.discount)
        .mul(new Decimal(i.quantity))
        .plus(new Decimal(totalDiscountPromo))
        .toNumber();

      let item = {
        product: {
          id: i.nuuidOne,
          referenceId: i.productCode,
          name: i.description,
          price: valorUnitario,
          isSeparatedItem: !itemCombo, // ToDo verificar
          sequentialId: i.vit_numlancto,
          message: "",
          parentSequentialId: i.vit_numlanctoprincipal ?? 0,
          isCombination: i.isCombination, // ToDo: opera��o de composi��o, n�o tem ainda no AA
        },
        productReferenceId: i.productCode,
        sellerId: userInfo.nuuidOne,
        currentSellerId: userInfo.nuuidOne,
        sellerReferenceId: userInfo.employeeCode,
        currentSellerName: userInfo.login,
        sequentialId: i.vit_numlancto,
        parentSequentialId: i.vit_numlanctoprincipal ?? 0,
        quantity: i.quantity,
        subTotal: valorItem,
        subTotalDiscount: Number(
          Number((subtotalItem - valorDescontoItemReshop).toFixed(2))
        ),
        applicableDiscount: null,
        appliedDiscount: [],
        deleted: false,
        discount: isPDVOne
          ? Number(Number((i.discount * i.quantity + valorDescontoItemReshop).toFixed(2)))
          : Number(Number(i.discount).toFixed(2)),
        addition: 0,
        levelType: 0, // ToDo: n�o achei informa��es explicando sobre o campo
        discountType: 0,
        // VALUE, PERCENTAGE, RESHOP_MANUAL, RESHOP_AUTOMATIC
        typedNote: i.observation,
        itemType: [],
        observation: "",
        isCombination: false, // ToDo: opera��o de composi��o, n�o tem ainda no AA
        combinationSequentialId: 0, // ToDo: opera��o de composi��o, n�o tem ainda no AA
        quantityOfItemsOnCombination: 0, // ToDo: opera��o de composi��o, n�o tem ainda no AA
        combinationPriceStrategy: 0, // ToDo: opera��o de composi��o, n�o tem ainda no AA
        typeSiteSaleId: bodySale.typeSiteSaleId, // Tipo de Venda
        // DEFAULT = 0,
        // BY_PRODUCT_VALUE = 1,
        // BY_PRODUCT_WITH_MAX_VALUE = 2
        operation: 0, // ToDo: opera��o de composi��o, n�o tem ainda no AA
        // Verificar com o mateus se est�o pegando os dados da opera��o de composi��o (tabela)
        compoundOperations: [
          // {
          //   referenceId: 0,
          //   descriptionPos: string,
          //   descriptionProduction: string,
          //   priority: 0,
          //   operationType: 0,
          //   isChangeValue: true,
          //   quantityMin: 0,
          //   quantityMax: 0,
          //   quantity: 0
          // }
        ],
        // subItems: [
        //   string
        // ],
        reshop: null,
        // reshop: {
        //   pointCost: 0,
        //   promocode: string,
        //   isBrinde: true,
        //   optionalCampaignId: string,
        //   reshopStage
        // }
      };

      if (valorDescontoItemReshop) {
        item.appliedDiscount.push({
          type: DISCOUNT_TYPES.RESHOP,
          isNegociatedBySalesperson: false,
          isOfferCoverage: true,
          percentageDiscountValue: 0,
          totalDiscountValue: valorDescontoItemReshop,
        });
      }

      if (i.discount ?? 0 * i.quantity) {
        item.appliedDiscount.push({
          type: DISCOUNT_TYPES.PROMOTION,
          isNegociatedBySalesperson: false,
          isOfferCoverage: true,
          percentageDiscountValue: 0,
          totalDiscountValue: isPDVOne
            ? Number(i.discount * i.quantity)
            : Number(i.discount),
        });
      }

      // #region 'Item Type'
      // LEGACY = 0,
      // PARENT_ROOT = 1,
      // COMBINATION_CHILD = 2,
      // COMBINATION_PARENT = 3,
      // COMPOSITION = 4,
      // ORIENTED_SALE_PARENT = 5,
      // ORIENTED_SALE_CHILD = 6,
      // CAN_COMBINE = 7,
      // IS_PARENT = 8,
      // COMPOSITION_PARENT = 9,
      // RESHOP_REDEEM = 10

      if (i.isCombination) {
        // Combina��o
        if (i.vit_numlanctoprincipal != null) item.itemType.push(3);

        if (item.parentSequentialId > 0) {
          item.itemType.push(2);
        }

        item.itemType.push(7);
      } else if (itemCombo) {
        // Venda Orientada
        if (principalCombo && i.vit_numlanctoprincipal == null)
          item.itemType.push(1);

        if (principalCombo) item.itemType.push(5);

        if (i.vit_numlanctoprincipal != null) item.itemType.push(6);
      }

      if (item.parentSequentialId == 0) item.itemType.push(8);
      // #endregion 'Item Type'

      body.items.push(item);
    });

    // Caso tenha valores sobrando do reshop deve redistribuir nos items
    if (reshopCampaign?.TotalDesconto) {
      const differenceDiscount = new Decimal(
        reshopCampaign.TotalDesconto
      ).minus(
        body.items.reduce(
          (acc, curr) =>
            acc.plus(
              curr.appliedDiscount.find(
                (typeDiscount) => typeDiscount.type === DISCOUNT_TYPES.RESHOP
              )?.totalDiscountValue ?? 0
            ),
          new Decimal(0)
        )
      );

      if (differenceDiscount.greaterThan(new Decimal(0))) {
        // Calcula a sobra de descontos
        let leftOvers = new Decimal(differenceDiscount);

        body.items
          .filter((item) =>
            item.appliedDiscount.find(
              (typeDiscount) => typeDiscount.type === DISCOUNT_TYPES.RESHOP
            )
          )
          .forEach((item) => {
            // Calcula o valor a ser adicionado no item já reservando o 0.01 do mesmo
            let discountToAdd = leftOvers;

            /**
             * Se o valor for negativo eu retiro o valor negativo
             */
            if (
              new Decimal(item.subTotalDiscount)
                .minus(discountToAdd)
                .lessThan(new Decimal(0.01))
            ) {
              const differenceBetween = discountToAdd
                .minus(new Decimal(item.subTotalDiscount))
                .mul(new Decimal(-1))
                .minus(new Decimal(0.01));

              /**
               * Atualizo o desconto a ser adicionado já reservando o 0.01 do item
               */
              discountToAdd = discountToAdd.minus(differenceBetween);
            }

            /**
             *  Verifica se o valor a ser adicionado no item é maior que zero,
             *  o item tem que ter pelo menos 0.01
             */
            if (
              discountToAdd.greaterThan(new Decimal(0)) &&
              new Decimal(item.subTotalDiscount)
                .minus(discountToAdd)
                .greaterThan(new Decimal(0.01))
            ) {
              leftOvers = leftOvers.minus(discountToAdd);

              // Atualiza os objetos dos itens
              item.discount = new Decimal(item.discount)
                .plus(discountToAdd)
                .toNumber();

              item.appliedDiscount.forEach((typeDiscount) => {
                if (typeDiscount.type === DISCOUNT_TYPES.RESHOP) {
                  typeDiscount.totalDiscountValue = new Decimal(
                    typeDiscount.totalDiscountValue
                  )
                    .plus(new Decimal(discountToAdd))
                    .toNumber();

                  item.subTotalDiscount = new Decimal(item.subTotal)
                    .minus(new Decimal(item.discount))
                    .toNumber();
                }
              });
            }
          });
      }
    }

    // Realiza o rateio do desconto manual
    if (manualDiscounts.length) {
      let discountLeft = 0;

      body.items.forEach((item) => {
        let discountItem =
          (Number(
            manualDiscounts.find((dI) => dI.vit_numlancto === item.sequentialId)
              ?.discountManual
          ) || 0) + discountLeft;

        if (discountItem <= 0) {
          return;
        }

        if (
          new Decimal(item.subTotalDiscount)
            .minus(new Decimal(discountItem))
            .toNumber() < 0.01
        ) {
          const restDiscount = new Decimal(item.subTotalDiscount)
            .minus(new Decimal(0.01))
            .minus(new Decimal(discountItem))
            .mul(new Decimal(-1));

          discountItem = restDiscount
            .minus(new Decimal(item.subTotalDiscount - 0.01))
            .toNumber();

          discountLeft = restDiscount.toNumber();

          if (item.subTotalDiscount - 0.01 - discountItem < 0.01) {
            return;
          }
        } else {
          discountLeft = 0;
        }

        item.subTotalDiscount = new Decimal(item.subTotalDiscount)
          .minus(new Decimal(discountItem))
          .toNumber();
        item.discount = new Decimal(item.discount)
          .plus(new Decimal(discountItem))
          .toNumber();

        if (discountItem > 0) {
          item.appliedDiscount.push({
            type: DISCOUNT_TYPES.TOTAL_DISCOUNT_ON_SALES,
            isNegociatedBySalesperson: false,
            isOfferCoverage: true,
            percentageDiscountValue: 0,
            totalDiscountValue: Number(discountItem) ?? 0,
          });
        }
      });
    }

    body.discount = body.items.reduce((acc, curr) => {
      return acc + curr.discount;
    }, 0);
    body.total = Number(totalProduto.toFixed(2));
    body.totalDiscount = body.items.reduce((acc, curr) => {
      return acc + curr.subTotalDiscount;
    }, 0);
    body.change =
      Number(
        body.payments
          ?.reduce((acc, curr) => {
            acc = acc + curr.change || 0;

            return acc;
          }, 0)
          .toFixed(2)
      ) || 0;

    return body;
  } catch (e) {
    console.error(e);
    console.log(
      `parseAASaleToDegustOneOrderService | error ${JSON.stringify(e)}`
    );
    throw e;
  }
};

const getTransactionCodeFromType = (transactionType, p) => {
  if (p?.paymentOneBaseCode) {
    return p.paymentOneBaseCode;
  }

  const enumValue = {
    Money: 1,
    Debit: 2,
    Credit: 3,
    CreditTef: 4,
    DebitTef: 5,
    CreditSale: 6,
    AgreementSale: 7, // Convenio
    Voucher: 8,
    VoucherTef: 9,
    PrivateLabel: 10,
    PrivateLabelTef: 11,
    QRCode: 12,
    Others: 15,
  };

  if (transactionType === "DEBITO") return enumValue.DebitTef;
  else if (transactionType === "CREDITO") return enumValue.CreditTef;
  else if (transactionType === "VOUCHER") return enumValue.VoucherTef;
  else if (transactionType === "QR LINX" || transactionType === "QRLINX")
    return enumValue.QRCode;

  return enumValue.Money;
};
