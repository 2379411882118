import React from "react";
import PrintTemplate from "../../components/print-template";
import { render } from "react-dom";
import { formatDateHelper } from "../formatDateHelper";
import { printerActionService } from "../../../services/actions/printerActionService";
import { printDefault } from "./printDefault";
import { currencyMask } from "../masks";
import { getUsers } from "../../../services/usersServices";
import { getSystemParamsHelper } from "../getSystemParamsHelper";

const isMobile = !!window.Android;

export const printCloseDiscountOperator = ({
  cashdesk,
  printDiscountVoucherOperatorValue,
}) => {
  let operator = undefined;
  const systemParams = getSystemParamsHelper();

  getUsers()
    .then((res) => {
      operator = res.users.find(
        (op) =>
          String(op.nuuidOne).toLowerCase() ===
          String(cashdesk.userId).toLowerCase()
      );

      if (!operator) {
        operator = {
          operatorName: cashdesk.userName,
        };
      }

      let html = document.createElement("html");
      render(
        <PrintTemplate>
          <br />
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "center" }}>
            <b>Loja: {systemParams.storeName}</b>
          </div>
          <br />
          <br />
          {isMobile ? "\n" : ""}
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "start" }}>
            <b>Vale: {currencyMask(printDiscountVoucherOperatorValue)}</b>
          </div>
          <br />
          <br />
          {isMobile ? "\n" : ""}
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "center" }}>
            Autorizo o desconto de{" "}
            {printDiscountVoucherOperatorValue < 1
              ? ExtensoCentavos(printDiscountVoucherOperatorValue * 100) +
                " centavos"
              : Extenso(printDiscountVoucherOperatorValue)}
            .
          </div>
          <br />
          <br />
          {isMobile ? "\n" : ""}
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "end" }}>
            {formatDateHelper(new Date(), "dd/MM/yyyy")}
          </div>
          <br />
          <br />
          {isMobile ? "\n" : ""}
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "start" }}>
            Nome Completo:{" "}
            {operator.operatorName ? operator.operatorName : operator.loginName}
          </div>
          <br />
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "start" }}>
            CPF:{" "}
            {operator.loginDocument
              ? operator.loginDocument
              : "___________________________"}
          </div>
          <br />
          <br />
          {isMobile ? "\n" : ""}
          {isMobile ? "\n" : ""}
          <div style={{ textAlign: "start" }}>
            Assinatura: ___________________________
          </div>
        </PrintTemplate>,
        html
      );

      setTimeout(() => {
        if (window.desktopApp) {
          printerActionService({
            body: html.outerHTML,
          });
        } else if (isMobile) {
          console.log(`printDefault | {}`);
          printDefault(html.outerHTML);
        }
      }, 300);
    })
    .catch((err) => {
      if (window.Android) {
        console.log(
          `Error on printCloseDiscountOperator => ${JSON.stringify(err)}`
        );
      } else {
        console.error(`Error on printCloseDiscountOperator => `, err);
      }
    });
};

// Imprime o valor por extenso
function Extenso(vlr) {
  var Num = parseFloat(vlr);

  // Verifica se o valor é menor que 1
  if (Num < 1) {
    var resto = Math.round(Num * 100);
    return "Autorizo o desconto de " + ExtensoCentavos(resto) + " centavos";
  }

  if (Num === 0) {
    return "zero";
  } else {
    var inteiro = parseInt(vlr); // parte inteira do valor
    if (inteiro < 1000000000000000) {
      var resto = Num.toFixed(2) - inteiro.toFixed(2); // parte fracionária do valor
      resto = resto.toFixed(2);
      var vlrS = inteiro.toString();

      var cont = vlrS.length;
      var extenso = "";
      var auxnumero;
      var auxnumero2;
      var auxnumero3;

      var unidade = [
        "",
        "um",
        "dois",
        "três",
        "quatro",
        "cinco",
        "seis",
        "sete",
        "oito",
        "nove",
        "dez",
        "onze",
        "doze",
        "treze",
        "quatorze",
        "quinze",
        "dezesseis",
        "dezessete",
        "dezoito",
        "dezenove",
      ];

      var centena = [
        "",
        "cento",
        "duzentos",
        "trezentos",
        "quatrocentos",
        "quinhentos",
        "seiscentos",
        "setecentos",
        "oitocentos",
        "novecentos",
      ];

      var dezena = [
        "",
        "",
        "vinte",
        "trinta",
        "quarenta",
        "cinquenta",
        "sessenta",
        "setenta",
        "oitenta",
        "noventa",
      ];

      var qualificaS = ["reais", "mil", "milhão", "bilhão", "trilhão"];
      var qualificaP = ["reais", "mil", "milhões", "bilhões", "trilhões"];

      for (var i = cont; i > 0; i--) {
        var verifica1 = "";
        var verifica2 = 0;
        var verifica3 = 0;
        auxnumero2 = "";
        auxnumero3 = "";
        auxnumero = 0;
        auxnumero2 = vlrS.substr(cont - i, 1);
        auxnumero = parseInt(auxnumero2);

        if (i == 14 || i == 11 || i == 8 || i == 5 || i == 2) {
          auxnumero2 = vlrS.substr(cont - i, 2);
          auxnumero = parseInt(auxnumero2);
        }

        if (i == 15 || i == 12 || i == 9 || i == 6 || i == 3) {
          extenso = extenso + centena[auxnumero];
          auxnumero2 = vlrS.substr(cont - i + 1, 1);
          auxnumero3 = vlrS.substr(cont - i + 2, 1);

          if (auxnumero2 != "0" || auxnumero3 != "0") extenso += " e ";
        } else if (auxnumero > 19) {
          auxnumero2 = vlrS.substr(cont - i, 1);
          auxnumero = parseInt(auxnumero2);
          extenso = extenso + dezena[auxnumero];
          auxnumero3 = vlrS.substr(cont - i + 1, 1);

          if (auxnumero3 != "0" && auxnumero2 != "1") extenso += " e ";
        } else if (
          auxnumero <= 19 &&
          auxnumero > 9 &&
          (i == 14 || i == 11 || i == 8 || i == 5 || i == 2)
        ) {
          extenso = extenso + unidade[auxnumero];
        } else if (
          auxnumero < 10 &&
          (i == 13 || i == 10 || i == 7 || i == 4 || i == 1)
        ) {
          auxnumero3 = vlrS.substr(cont - i - 1, 1);
          if (auxnumero3 != "1" && auxnumero3 != "")
            extenso = extenso + unidade[auxnumero];
        }

        if (i % 3 == 1) {
          verifica3 = cont - i;
          if (verifica3 == 0) verifica1 = vlrS.substr(cont - i, 1);

          if (verifica3 == 1) verifica1 = vlrS.substr(cont - i - 1, 2);

          if (verifica3 > 1) verifica1 = vlrS.substr(cont - i - 2, 3);

          verifica2 = parseInt(verifica1);

          if (i == 13) {
            if (verifica2 == 1) {
              extenso = extenso + " " + qualificaS[4];
            } else if (verifica2 != 0) {
              extenso = extenso + " " + qualificaP[4];
            }
          }
          if (i == 10) {
            if (verifica2 == 1) {
              extenso = extenso + " " + qualificaS[3];
            } else if (verifica2 != 0) {
              extenso = extenso + " " + qualificaP[3];
            }
          }
          if (i == 7) {
            if (verifica2 == 1) {
              extenso = extenso + " " + qualificaS[2];
            } else if (verifica2 != 0) {
              extenso = extenso + " " + qualificaP[2];
            }
          }
          if (i == 4) {
            if (verifica2 == 1) {
              extenso = extenso + " " + qualificaS[1];
            } else if (verifica2 != 0) {
              extenso = extenso + " " + qualificaP[1];
            }
          }
          if (i == 1) {
            if (verifica2 == 1) {
              extenso = extenso + " " + qualificaS[0];
            } else {
              extenso = extenso + " " + qualificaP[0];
            }
          }
        }
      }
      resto = Math.round(resto * 100);
      var aexCent = 0;
      if (resto <= 19 && resto > 0)
        extenso += " e " + unidade[resto] + " centavos";
      if (resto > 19) {
        aexCent = parseInt(resto / 10);

        extenso += " e " + dezena[aexCent];
        resto = resto - aexCent * 10;

        if (resto != 0) extenso += " e " + unidade[resto] + " centavos";
        else extenso += " centavos";
      }

      return extenso;
    } else {
      return vlr;
    }
  }
}

// Função auxiliar para tratar os centavos
function ExtensoCentavos(valor) {
  var unidade = [
    "",
    "um",
    "dois",
    "três",
    "quatro",
    "cinco",
    "seis",
    "sete",
    "oito",
    "nove",
    "dez",
    "onze",
    "doze",
    "treze",
    "quatorze",
    "quinze",
    "dezesseis",
    "dezessete",
    "dezoito",
    "dezenove",
  ];

  var dezena = [
    "",
    "",
    "vinte",
    "trinta",
    "quarenta",
    "cinquenta",
    "sessenta",
    "setenta",
    "oitenta",
    "noventa",
  ];

  var resto = valor;
  var extenso = "";

  if (resto <= 19 && resto > 0) {
    extenso += unidade[resto];
  }

  if (resto > 19) {
    var aexCent = parseInt(resto / 10);
    extenso += dezena[aexCent];
    resto = resto - aexCent * 10;

    if (resto != 0) extenso += " e " + unidade[resto];
  }

  return extenso;
}
