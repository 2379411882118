import { printerActionService } from "../../../services/actions/printerActionService";
import { htmlToTextHelper } from "../htmlToTextHelper";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const printQRCode = (qrcode) => {
  const config = JSON.parse(window.sessionStorage.getItem("AA_Config")) || {};
  const canPrintQRCode =
    JSON.parse(window.sessionStorage.getItem("printQRCode")) || false;

  var content = `<html>
  <head>
      <title>QR code</title>
  </head>
  <body>
  <img id='barcode'
          style="margin:5px 40px 5px 40px;"
          src="http://chart.apis.google.com/chart?cht=qr&chl=${qrcode}&chs=300x300"
          alt=""
          title="" />
  </body>
</html>`;

  if (canPrintQRCode) {
    if (window.desktopApp) {
      printerActionService({
        body: content,
      });
    } else if (isMobile) {
      if (config?.printSize === "80mm") {
        printerActionService({
          danfeUrl: `http://chart.apis.google.com/chart?cht=qr&chl=${qrcode}&chs=400x400`,
        });
        console.log("Imprimindo QRCode na Impressora de 80mm");

        return;
      }

      printerActionService({
        danfeUrl: `http://chart.apis.google.com/chart?cht=qr&chl=${qrcode}&chs=300x300`,
      });
      console.log("Imprimindo QRCode na Impressora de 56mm");
    }
  }
};
