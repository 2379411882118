import React from "react";
import { render } from "react-dom";
import PrintTemplate from "../../components/print-template";
import { printerActionService } from "../../../services/actions/printerActionService";
import { OBSERVATION_ORDER_KEY_NAME } from "../../constants/keyNamesSessionStorage";
import { htmlToTextHelper } from "../htmlToTextHelper";
import { escUndefinedInStringHelper } from "../escUndefinedInStringHelper";

const isMobile = !!window.Android;

export const productionPrinter = (order) => {
  console.log("Order de produção", order);

  if (isMobile || window.desktopApp) {
    try {
      const printerPath = String(order?.department?.hosts?.path ?? "");
      const contentToPrint = htmlToTextHelper(order.contentPrinter);

      const printerData = printerPath?.replace("\\\\")?.split(":");

      const [ip, port] = printerData;
      sessionStorage.removeItem(OBSERVATION_ORDER_KEY_NAME);

      try {
        if (!ip || !port) {
          throw new Error(
            "Ip e/ou porta da impressora de produção não configurada"
          );
        }

        console.log(
          "productionPrinter:",
          `Enviando para produção, no endereço: ws://${ip}:${port}`
        );
      } catch (error) {
        console.log(
          "productionPrinter:",
          "Falha ao enviar para produção, error: ",
          error
        );
      }

      if (contentToPrint?.length) {
        let html = "";

        if (printerData?.length > 0) {
          if (isMobile) {
            window.Android.printerComandas(
              contentToPrint,
              escUndefinedInStringHelper(ip),
              escUndefinedInStringHelper(port) || null
            );
          } else if (window.desktopApp) {
            printerActionService({
              printOverTCPSocket: true,
              ip,
              port,
              rawText: contentToPrint,
            });
          }
        } else {
          if (isMobile) {
            window.Android.printerComandasTotem(contentToPrint);
          } else if (window.desktopApp) {
            printerActionService({
              body: htmlToTextHelper(html?.outerHTML ?? ""),
            });
          }
        }
      }
    } catch (error) {
      console.log(
        `productionPrinter: Ocorreu um erro ao executar impressão: ${JSON.stringify(
          error,
          Object.getOwnPropertyNames(error)
        )}`
      );
    }
  }
};
