import styled from "styled-components";

export const Container = styled.button`
  background: transparent;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.purple};

  padding: 0.5rem 0 !important;
  position: relative;

  .budget {
    height: 20px;
    width: 22px;

    background: ${({ theme }) => theme.colors.purple};
    color: #fff;

    border-radius: 50%;
    font-size: 0.85rem;
    font-weight: 700;

    position: absolute;
    right: -0.625rem;
    top: -1px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
