import React from "react";
import classNames from "classnames";
import { MdOutlineModeEditOutline } from "react-icons/md";

import { maskCPFHelper } from "../../helpers/maskCPFHelper";
import { useAuth } from "../../hook/authHook";
import { useCart } from "../../hook/cartHook";
import { isSmartPos } from "../../../aaone/configuration";
import { LinxReshopLogo } from "../../../linx/reshop";
import { LinxReshopIconLogo } from "../../../linx/reshop/icon";
import { Container } from "./styles";

export const CostumerIdentifier = ({ handleReshop }) => {
  const { reshop } = useAuth();
  const { handleOpenModalFidelity, handleOpenModalFidelityData } = useCart();

  const cpf = reshop?.data?.Documento;
  const points = reshop?.data?.SaldoMonetario;

  const openModalFidelity = !reshop?.data
    ? handleOpenModalFidelity
    : handleOpenModalFidelityData;

  return (
    <>
      <Container className={classNames({ isSmartPos })}>
        <div className="row p-8 content__reshop">
          <div className="col p-8 logo">
            <button
              type="button"
              className="brand-container"
              onClick={handleReshop}
            >
              {isSmartPos ? (
                <LinxReshopIconLogo width="50px" height="auto" />
              ) : (
                <LinxReshopLogo height="50px" />
              )}
            </button>
          </div>
          <div
            className="col p-8 details"
            role="button"
            onClick={openModalFidelity}
          >
            <div className="row align-items-center">
              <label className="mr-10">CPF:</label>
              <span
                className={classNames("cpf-content mr-10", {
                  identified: !!cpf,
                })}
              >
                {cpf ? maskCPFHelper(cpf) : "Não Identificado"}
              </span>
              <span>
                <MdOutlineModeEditOutline size="1.5rem" />
              </span>
            </div>
            <div className="row align-items-center">
              <label className="mr-10">Pontos:</label>
              <span className="points">{points ? points : "-"}</span>
            </div>
          </div>
        </div>
        <hr />
      </Container>
    </>
  );
};
