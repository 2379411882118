import React from "react";
import Button from "../../Button";
import { ActionCellRender } from "./style";

export const ReportView = (props) => {
  return (
    <Button onClick={() => props.onClick(props.data)}>
      <i className="material-icons" style={{ color: `#FF6347` }}>
        cancel
      </i>
    </Button>
  );
};

export const ReportPrint = (props) => {
  return (
    <ActionCellRender>
        <Button
          onClick={() => props.onClickPrinter(props.data)}
          // style={{ minHeight: "40px", minWidth: "40px", marginLeft: "20%" }}
        >
          <i className="material-icons">
            print
          </i>
        </Button>
      <Button
        onClick={() => props.onClickView(props.data)}
        // style={{ minHeight: "40px", minWidth: "40px", marginLeft: "70%" }}
      >
        <i className="material-icons">
          visibility
        </i>
      </Button>
    </ActionCellRender>
  );
};
