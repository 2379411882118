import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content__wrapper {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;

    .widget__wrapper {
      max-width: 750px;
      margin: 2rem auto;
      width: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;

      .widget__content {
        display: flex;
        flex-direction: column;

        .widget__title {
          margin-bottom: 0.5rem;
          font-size: 1.25rem;
          font-weight: 700;
        }

        .input__widget-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        .input__group {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          margin-top: 2rem;

          input {
            padding: 0.5rem;
            font-size: 1rem;
            border: 0;

            margin-right: 1rem;

            width: 100%;

            &:last-child {
              width: 75px;
              margin-right: 0;
            }
          }
        }
      }

      button.button__submit {
        background-color: rgba(46, 0, 55, 1);
        text-transform: uppercase;
        text-align: center;
        margin: 1rem 0 0;
        color: #fff;
        width: 100%;
      }
    }
  }
`;
