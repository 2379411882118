export const TOKEN_USER_AUTH_KEY_NAME = "@PDVOne:user:token";

export const DATA_USER_AUTH_KEY_NAME = "@PDVOne:user:data";

export const SYSTEM_DATA_KEY_NAME = "@AAOne:system:data";

export const CASHDESK_OPEN_ID = "@PDVOne:cashdesk:openid";

export const CPF_INVOICE_KEY_NAME = "@PDVOne:cpfInInvoice";

export const PDV_COD_LOJA_KEY_NAME = "@PDVOne:cod_loja";

export const TOKEN_RESHOP_AUTH_KEY_NAME = "@PDVOne:token_reshop";

export const CPF_RESHOP_KEY_NAME = "@PDVOne:cpf_reshop";

export const RESHOP_AUTH_DATA_KEY_NAME = "@PDVOne:reshop_auth_data";

export const RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME = "@PDVOne:reshop_reedemable_products";

export const RESHOP_CUSTOMER_DATA_KEY_NAME = "@PDVOne:reshop_customer_data";

export const RESHOP_ACTIVE_OPTIONAL_CAMPAIGN = "@PDVOne:reshop_active_optional_campaign";

export const PDV_STORE_PARAMS = "@PDVOne:pdv_store_params";

export const CART_KEY_NAME = "@PDVOne:cart";

export const CART_COUPONS_KEY_NAME = "@PDVOne:cart:coupons";

export const RESHOP_NSU_KEY_NAME = "@PDVOne:reshop:nsu";

export const RESHOP_TOTAL_DISCOUNT_KEY_NAME = "@PDVOne:reshop:totalDiscount";

export const RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME =
  "@PDVOne:reshop:activatedCampaigns";

export const RESHOP_TRANSACTION_ID_KEY_NAME = "@PDVOne:reshop:transactionId";

export const RESHOP_DISCOUNTS = "@AAOne:discountsReshop";

export const MANUAL_DISCOUNTS = "@PDVONE:discountsManual";

export const RESHOP_CREDITED_POINTS = "@AAOne:reshop:creditedPoints";

export const PDV_ORDER_AUTHORIZATION_STATUS =
  "@PDVOne:order:authorizationStatus";

export const PDV_ORDER_IS_TAKE_OUT = "typeCode";

export const PAYMENT_METHOD_KEY_NAME = "@AAOne:payment_method";

export const PAYMENTS_KEY_NAME = "@AAOne:payments";

export const LINXPAY_POSID = "LINXPAY_POSID";

export const PRODUCTION_PRINTER_INFO_KEY_NAME = "@PDVOne:productionPrinter";

export const MACHINE_PRINTER_INFO_KEY_NAME = "@PDVOne:machinePrinter";

export const PAYMENTS_METHOD_KEY_NAME = "@PDVOne:paymentsMethod";

export const FISCAL_FLOW_ENDPOINT_KEY_NAME = "@PDVOne:FiscalFLowEndpoint";

export const FISCAL_FLOW_TYPE_KEY_NAME = "@PDVOne:FiscalFLowType";

export const NSU_TEF_LIST = "@PDVOne:nsuTefList";

export const OBSERVATION_ORDER_KEY_NAME = "@PDVOne:observationOrder";

export const NOTIFICATION_INFO = "@AAOne:notification:data";

export const TABLELIST_INFO = "@PDVOne:tableList:data";

export const TEF_CONFIG = "@PDVOne:tefconfig";

export const GET_ACCESS_TOKEN_KEY_NAME = (method) => {
  let STORAGE_KEY_NAME = "Access_Token";

  switch (method) {
    case "bff":
    case "products":
    case "apiSelfOrdering":
      STORAGE_KEY_NAME = "Access_Token";
      break;
    case "reshop":
      STORAGE_KEY_NAME = TOKEN_RESHOP_AUTH_KEY_NAME;
      break;
    default:
      STORAGE_KEY_NAME = "Access_Token";
      break;
  }

  return STORAGE_KEY_NAME;
};
