import React from "react";

import { Container } from "./styles";
import { MdArrowBack } from "react-icons/md";

export const HeaderPaymentMethod = ({
  children,
  onClickBackButton = () => {},
  disabledBackButton = false,
}) => {
  return (
    <Container>
      <div className="title">{children}</div>
      <button
        role="button"
        onClick={onClickBackButton ?? (() => {})}
        disabled={disabledBackButton ? "disabled" : ""}
        className="button__back"
      >
        <MdArrowBack size="1rem" />
        <span>Voltar</span>
      </button>
    </Container>
  );
};
