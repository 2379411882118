import { escOnlyNumbersHelper } from "../pdv-one/helpers/escOnlyNumbersHelper";
import { getCodLoja, getTerminalID } from "../aaone/configuration";
import { apiReshop } from "./api";
import { transactionData } from "../aaone/shared/utils/transactionNumber";
import {
  CPF_RESHOP_KEY_NAME,
  PAYMENT_METHOD_KEY_NAME,
  RESHOP_CREDITED_POINTS,
  RESHOP_NSU_KEY_NAME,
  RESHOP_TOTAL_DISCOUNT_KEY_NAME,
  RESHOP_TRANSACTION_ID_KEY_NAME,
} from "../pdv-one/constants/keyNamesSessionStorage";
import { nsuValueHelper } from "../pdv-one/helpers/nsuValueHelper";

const formatDate = () => {
  let data = new Date();
  let dataFormatada =
    data.getFullYear() +
    "-" +
    (data.getMonth() + 1) +
    "-" +
    data.getDate() +
    " " +
    data.getHours() +
    ":" +
    data.getMinutes() +
    ":" +
    data.getSeconds();
  return dataFormatada;
};

export const processesOperationService = async (options) => {
  try {
    const cpfFidelity = sessionStorage.getItem(CPF_RESHOP_KEY_NAME) || "";

    if (cpfFidelity) {
      const CodigoLoja = getCodLoja();
      const terminalid = getTerminalID();
      const operationId = `${terminalid}${transactionData(
        new Date(),
        "MM/dd/yyyy hh:mm:ss"
      )}`
        .replace(" ", "")
        .replace(/\//g, "")
        .replace(/:/g, "");

      const paymentMethod = sessionStorage.getItem(PAYMENT_METHOD_KEY_NAME);

      let typePaymentNumber = "1";
      switch (paymentMethod) {
        case "TESTEQA":
        case "DINHEIRO":
        case "SEM PARAR":
          typePaymentNumber = "1";
          break;
        case "DEBITO":
          typePaymentNumber = "9";
          break;
        case "CREDITO":
          typePaymentNumber = "3";
          break;
        case "QR LINX":
          typePaymentNumber = "19";
          break;
        case "PEDE PRONTO":
          typePaymentNumber = "20";
          break;
        case "VOUCHER":
          typePaymentNumber = "12";
          break;
        default:
          typePaymentNumber = "1";
          break;
      }

      const items = options.itemsCart.map((currentItem, index) => {
        const currentItemStep = currentItem.ClassSaleStep.getSteps();

        const price = currentItemStep?.value;
        return {
          Item: index + 1,
          CodigoVendedor: "0",
          CodigoProduto: String(currentItemStep.productCode),
          CodigoSku: String(currentItemStep.productCode),
          Qtde: currentItem.quantity,
          PrecoUnitario: price,
          ValorLiquido: price * currentItem.quantity,
          Desconto: 0,
          ValorDescontoUnico: 0,
          ProdutoResgate: false,
          PontosResgate: currentItemStep?.pointPrice,
        };
      });

      const hasItemFidelity = items.find(
        (item) => item.categoryIsFidelity === true
      );

      const orderValorLiquido = items.reduce((acc, cur) => {
        return (
          acc + cur.PrecoUnitario * cur.Qtde ||
          Number(options.cartTotalPriceWithoutQuantity.toFixed(2))
        );
      }, 0);

      const calcValorLiquido = (
        orderValorLiquido <= 0
          ? hasItemFidelity
            ? 0.1
            : orderValorLiquido
          : orderValorLiquido
      ).toFixed(2);

      const getPointsTotal = items.reduce(function (prevVal, elem) {
        return Number(prevVal + (elem.PontosResgate || 0));
      }, 0);

      const payload = {
        DocumentoFidelidade: escOnlyNumbersHelper(cpfFidelity || ""),
        CodigoLoja,
        DataHora: formatDate(),
        TipoVenda: 1,
        CodigoTerminal: String(terminalid),
        CategoriaVenda: "1",
        QtdeTotal: options?.totalInCart ?? options?.itemsCart?.length,
        ValorBruto: Number(options.cartTotalPriceWithoutQuantity.toFixed(2)),
        ValorDescontos: "0",
        ValorDescontosUnico: "0",
        ValorAcrescimos: 0,
        ValorLiquido:
          Number(calcValorLiquido) -
          Number(sessionStorage.getItem(RESHOP_TOTAL_DISCOUNT_KEY_NAME)),
        TransacaoAssociada: String(nsuValueHelper()),
        Promocode: "",
        NumeroOperacao: operationId,
        Itens: items,
        PontosResgateFidelidade: getPointsTotal,
        Pagamentos: [
          {
            NumeroPagamento: "1",
            Tipo: typePaymentNumber || "1",
            BinCartao: "",
            Valor: options.cartTotalPrice,
          },
        ],
      };

      const { data } = await apiReshop.post(
        "/api/fidelidade/ProcessaOperacao",
        payload
      );

      if (data) {
        sessionStorage.setItem(RESHOP_NSU_KEY_NAME, JSON.stringify(data.Nsu));
        sessionStorage.setItem(
          RESHOP_TRANSACTION_ID_KEY_NAME,
          JSON.stringify(data.IdTransacao)
        );
        sessionStorage.setItem(
          RESHOP_CREDITED_POINTS,
          JSON.stringify(data.PontosCreditados)
        );

        return data;
      }
    }

    return null;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
