import styled from "styled-components";

const MessageBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    letter-spacing: 0.5px;
    font-size: 20px;
    margin: 5%;
    white-space: pre-wrap;
    max-height: 70vh;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }

  .modal__footer {
    display: flex;

    & > *:not(:last-child) {
      margin-right: 20px;
    }

    .btn-outline {
      display: flex;
      align-items: center;
      height: 100%;
      border-color: #c7c7c7 !important;
    }

    .btn-cancel {
      display: flex;
      align-items: center;
      height: 100%;
      border-color: #c7c7c7 !important;
    }

    > div {
      margin: 0 10px;
    }

    &.isSmartPos {
      .btn-default {
        font-size: 13px !important;
        padding: 8px 16px !important;
      }
    }
  }
`;
export default MessageBoxWrapper;
