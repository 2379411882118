import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PizzaContainer, ComboIndividualContainer } from "../modules";
import {
  loadCategoryItems,
  loadCategoryProducts,
  loadSubProducts,
} from "../../../services/catalogService";
import { addToCart } from "../../actions/cart";
import { fidelity } from "../../../services/fidelityService";
import { toggleCart } from "../../actions/cart";
import { Sidenav } from "../../shared/components/modules";

import { currency } from "../../shared/utils/currency";
import { NextStepReshopCampaignAfterSubmittingCart } from "../../shared/utils/nextStepReshopCampaignAfterSubmittingCart";
import { isShowCustomCombo } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { PDV_COD_LOJA_KEY_NAME } from "../../../pdv-one/constants/keyNamesSessionStorage";

const SidenavContainer = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const { isLayout } = useLayoutAa();

  const [active, setActive] = useState("0");
  const [categories, setCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [categoryCode, setCategoryCode] = useState("0");
  const [filters, setFilters] = useState([]);
  const [productsFidelity, setProductsFidelity] = useState([]);
  const [modalProduct, setModalProduct] = useState(false);
  const [onCloseModalProduct, setOnCloseModalProduct] = useState(() => {});
  const [checkModalPoints, setModalPoints] = useState(false);
  const [modalCombo, setModalCombo] = useState(false);
  const [modalDrinks, setModalDrinks] = useState(false);
  const [backstep, setBackstep] = useState(false);
  const [styleGroupIsColumns, setStyleGroupIsColumns] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const promocodesInsert = [];
  const [backstepCategory, setBackstepCategory] = useState("");
  const [backstepTitle, setBackstepTitle] = useState("");
  const [backstepSubtitle, setBackstepSubtitle] = useState("");
  const [modalAddCupom, showModalAddCupom] = useState(false);
  const [orientedSaleList, setOrientedSaleList] = useState(null);
  const [modalCupomAdd, setModalCupomAdd,] = useState(false);
  const [productDescriptionModalCupom, setProductDescriptionModalCupom] =
    useState("");
  const [productDisplayValueModalCupom, setProductDisplayValueModalCupom] =
    useState(0);
  const [productImageModalCupom, setProductImageModalCupom] = useState("");

  // Modal Produto
  const [itemProduct, setItemProduct] = useState("");
  const [nuuidOne, setNUUIDOne] = useState("");
  const [itemProductValue, setItemProductValue] = useState(0);
  const [itemProductValuePoints, setItemProductValuePoints] = useState(0);
  const [itemProductQtd, setItemProductQtd] = useState(1);
  const [itemProductImage, setItemProductImage] = useState("");
  const [itemId, setItemId] = useState("");
  const [code, setItemProductCode] = useState("");
  const [itemProductAccelerator, setProductAccelerator] = useState(false);
  const [itemProductClassification, setItemProductClassification] =
    useState("");
  const [itemProductHasOrientedSale, setItemProductHasOrientedSale] =
    useState(false);
  const [itemProductDetailedDescription, setItemProductDetailedDescription] =
    useState(null);
  const [itemProductPromotion, setItemProductPromotion] = useState({});

  const [suggestions, setSuggestions] = useState(null);
  const [hasSuggestion, setHasSuggestion] = useState(false);
  const [contentPizza, setContentPizza] = useState(null);
  const [orientedSale, setOrientedSale] = useState(null);
  const [orientedSaleSteps, setOrientedSaleSteps] = useState(null);
  const [product, setProduct] = useState(null);
  const [notification, setNotification] = useState(false);
  const [isComboUnic, setisComboUnic] = useState(false);
  const [currencyPoints, setCurrencyPoints] = useState(false);
  const [productsAll, setProductsAll] = useState(
    JSON.parse(window.sessionStorage.getItem("productsAll"))
  );
  const [productsResgate, setProductsResgate] = useState(
    JSON.parse(window.localStorage.getItem("productsResgate"))
  );
  const [showKeyboard, setKeyboard] = useState();
  const [invalidProductCode, setInvalidProductCode] = useState(false);
  const [promocodeReapt, setPromocodeReapt] = useState(false);
  const [availableFidelityPoint, setAvailableFidelityPoint] = useState(false);
  const [imageFidelity, setImageFidelity] = useState(false);
  const [insertCupom, setInsertCupom] = useState(false);
  const [adultConsumptionAlert, setAdultConsumptionAlert] = useState(false);
  const [nextStepAfterSubmit, setNextStepAfterSubmit] = useState(null);

  const isBobsLayout = isLayout("BOBS");

  const changeCategory = (title, customMessage, code, id, indice) => {
    clearReshopCampaignData();
    setCategoryProducts([]);
    dispatch(toggleCart(false));
    setBackstepCategory(code);
    setBackstepTitle(title);
    setBackstepSubtitle(customMessage);
    setContentPizza(null);
    setOrientedSale(null);
    setKeyboard(true);
    setSelectedFilter([]);
    setTitle(title);
    setSubTitle(customMessage);
    setActive(id);
    setCategoryCode(code);
    setModalCombo(false);
    setModalProduct(false);
    setModalDrinks(false);
    setBackstep(false);
    setSuggestions([]);
    setHasSuggestion(false);
    setFilters(categories[indice].filters);
    setImageFidelity(false);
    if (categories[indice].description === "PIZZAS") {
      setStyleGroupIsColumns(categories[indice].displayType === "L");
    } else {
      setStyleGroupIsColumns(categories[indice].displayType === "C");
    }
    setInsertCupom(false);

    if (categories[indice].isFidelity === true && localStorage.getItem("cpf")) {
      setTimeout(() => {
        setCategoryProducts(productsAll);
      }, 1350);
      setImageFidelity(false);
      setAvailableFidelityPoint(true);
      return;
    }

    if (
      categories[indice].isFidelity === true &&
      localStorage.getItem("cpf") === null
    ) {
      setImageFidelity(true);
      return;
    }

    if (categories[indice].isFidelityCoupon === true) {
      setInsertCupom(true);
      setAvailableFidelityPoint(false);
      return;
    }

    setAvailableFidelityPoint(false);

    loadCategoryProducts(code).then((res) => {
      const products = res?.products;
      setCategoryProducts(products);
    });
  };

  const clearReshopCampaignData = () => {
    setNextStepAfterSubmit(null);
  }

  const handleFidelity = (products, productsFidelity) => {
    const filteredProducts = [];
    products.forEach((item) => {
      const fidelity = productsFidelity.find(
        (p) => p.ProductCode === item.productCode
      );
      if (fidelity) {
        filteredProducts.push({ ...item, ...fidelity });
      }
    });
    return filteredProducts;
  };

  const handleFilter = async (filterName, code) => {
    const alreadySelected = selectedFilter.findIndex(
      (filter) => filter === filterName
    );

    if (alreadySelected === -1) {
      setSelectedFilter(filterName.split());

      const res = await loadCategoryProducts(code);

      let products = res?.products;

      if (categories.find((c) => c.categoryCode === code).isFidelity === true) {
        products = handleFidelity(products, productsFidelity);
      }

      const newList = products
        .filter((item) => item.product.filters[0])
        .filter((i) => i.product.filters[0].filterName === filterName);
      setCategoryProducts(newList);
    } else {
      setSelectedFilter([]);
      if (categories.find((c) => c.categoryCode === code).isFidelity === true) {
        const res_1 = await loadCategoryProducts(code);
        const products_1 = await handleFidelity(
          res_1?.products,
          productsFidelity
        );
        setCategoryProducts(products_1);
      } else {
        const res_1 = await loadCategoryProducts(code);
        const products_1 = res_1?.products;
        setCategoryProducts(products_1);
      }
    }
  };

  const createNewCompositionStep = (products, saleSteps) => {
    const newSaleSteps = [];

    /**
     * only to create a unique group code for sale step
     * RECURSIVE FUNCTION
     * @returns {string}
     */
    const createNewUniqueGroupCode = () => {
      const newGroupCode = `${Math.random()}`.substr(-3, 3);
      const lookingExistingGroupCode = saleSteps.find((step) => step.groupCode === newGroupCode);
      if (lookingExistingGroupCode)
        createNewUniqueGroupCode();

      return newGroupCode;
    }

    saleSteps.forEach((step, index) => {
      if (index === 0) {
        const groupCode = createNewUniqueGroupCode();
        const description = 'Personalize seu lanche';
        const newStepInitial = {
          ...step,
          required: false,
          groupCode,
          orientedSaleGroup: {
            ...step.orientedSaleGroup,
            groupCode,
            description,
            composition: true,
            orientedSaleGroupProduct: products.compositions
          }
        };

        newSaleSteps.push(newStepInitial);
      }

      const existingStepsWithNewSequence = {
        ...step,
        sequence: step.sequence + 1,
        orientedSaleGroup: {
          ...step.orientedSaleGroup,
          composition: false,
        }
      };

      newSaleSteps.push(existingStepsWithNewSequence);
    });

    return newSaleSteps;
  }

  const getOrientedSaleAndSubProduct = (product) => {
    const newTitle = title;
    let orientedSaleSteps = null;
    let productValue = 0;
    //let productWithoutGroup = [];
    let orientedSaleGroup = null;
    let productWithoutGroup = null;
    let orientedSaleGroupProduct = [];
    let newProduct;

    const subProduct = [];

    if (product && product?.orientedSale.length > 0) {
      orientedSaleSteps = product.orientedSale[0].orientedSaleSteps;
      orientedSaleGroup = orientedSaleSteps.map(
        (item) => item.orientedSaleGroup
      );
      orientedSaleGroupProduct = orientedSaleGroup.filter(
        (item) => item && item.orientedSaleGroupProduct
      );
      productValue = orientedSaleGroup.value;

      if (orientedSaleSteps[0].productWithoutGroup) {
        productWithoutGroup = {
          ...orientedSaleSteps[0].productWithoutGroup,
          quantity: orientedSaleSteps[0].quantity,
        };
      }

      if (orientedSaleGroup[0]) {
        // newTitle = orientedSaleGroup[0].description;
        newProduct = orientedSaleGroupProduct
          .map((i) => i.orientedSaleGroupProduct)[0]
          .map((item) => item);
      }
    } else if (product.nivel1.length > 0) {
      // newTitle = product.description;

      product.nivel1.forEach((item) =>
        subProduct.push({ ...item, product: item })
      );
      newProduct = subProduct;
    }

    if (
      isBobsLayout &&
      orientedSaleSteps !== null &&
      !!product.compositions.length
    ) {
      orientedSaleSteps = createNewCompositionStep(product, orientedSaleSteps);
    }

    return {
      orientedSaleSteps,
      orientedSaleGroup,
      orientedSaleGroupProduct,
      productValue,
      newProduct,
      title: newTitle,
      productWithoutGroup,
    };
  };

  const handleNextStepAfterSubmit = (reshopCampaign) => {
    const backStep = () => {
      const back = backStepCategory();
      back.then(() => {
        setKeyboard(true);
      })
    };

    const nextStep = () => {
      const back = backStepCategory(false);
      back.then(() => {
        setKeyboard(false);
        showProduct(null,
          null,
          null,
          null,
          nextStepAfterSubmit.productCode,
          true,
          nextStepAfterSubmit.currentCampaigns,
          nextStepAfterSubmit
        );

        nextStepAfterSubmit.initialization();
      });
    };

    const nextStepAfterSubmit = new NextStepReshopCampaignAfterSubmittingCart(
      {
        back: backStep,
        next: nextStep,
      },
      reshopCampaign
    );

    return nextStepAfterSubmit;
  };

  const showProduct = (
    itemId,
    itemDescription,
    itemValue,
    itemImage,
    productCode,
    item,
    reshopCampaign = {status: false, data: [], promocode: ''},
    nextStepAfterSubmit
  ) => {
    setCurrencyPoints(false);

    loadSubProducts(productCode).then((res) => {
      dispatch(toggleCart(false));
      localStorage.removeItem("QuantityCombo");
      localStorage.removeItem("selectedTotalCombo");
      const product = res.product;

      if (!Object.keys(product).length)
        return;

      const hasFidelity = item.Points;

      setOrientedSaleList(product.orientedSale[0]);

      if (hasFidelity) {
        let pontosFidelidade = localStorage.getItem("saldoAtual");
        if (item.Points > pontosFidelidade) {
          setModalPoints(true);

          return;
        }

        itemValue = 0.01;

        setModalProduct(true);
        setBackstep(false);
        setHasSuggestion(false);
        setCurrencyPoints(true);

        let fidelityProps = {
          _id: product._id,
          description: product.description,
          accelerator: product.accelerator,
          classification: product.classification,
          promotion: product.promotion,
          displayPoints: item.Points,
        };
        setNUUIDOne(product.nuuidOne);
        setItemId(fidelityProps._id);
        setItemProduct(fidelityProps.description);
        setItemProductValue(itemValue || product.value);
        setItemProductValuePoints(fidelityProps.displayPoints);
        setItemProductImage(product.featuredImage);
        setItemProductCode(productCode);
        setProductAccelerator(fidelityProps.accelerator);
        setItemProductClassification(fidelityProps.classification);
        setItemProductQtd(1);
        setItemProductPromotion(fidelityProps.promotion);

        return;
      }

      if (
        !product?.orientedSale.length &&
        !product.nivel1.length &&
        !product.hasOrientedSale &&
        !item.Points
      ) {
        if(product.showModalPromotion) {
          setModalCombo(true);
        }
        if(reshopCampaign.status) {
          dispatch(
            addToCart({
              id: itemId || product._id,
              name: itemDescription || product.description,
              customizations: [],
              image: product.featuredImage,
              price: Number(itemValue || product.value),
              qtd: 1, //Feito por conta de produtos em dobro
              code: productCode,
              classification: product.classification,
              promotion: product.promotion,
              promocode: nextStepAfterSubmit?.campaigns?.promocode
            })
            );
          }else{
          setModalProduct(true);
        }
        setBackstep(false);
        setCurrencyPoints(false);
        setItemProductValuePoints(0);
        setItemId(itemId || product._id);
        setItemProduct(itemDescription || product.description);
        setItemProductValue(itemValue || product.value);
        setItemProductImage(product.featuredImage);
        setItemProductCode(productCode);
        setProductAccelerator(product.accelerator);
        setItemProductClassification(product.classification);
        setItemProductHasOrientedSale(product.hasOrientedSale);
        setItemProductDetailedDescription(product?.detailedDescription || null);
        setAdultConsumptionAlert(product.adultConsumptionAlert);
        setItemProductQtd(1);
        setItemProductPromotion(product.promotion);
        setNUUIDOne(product.nuuidOne);
        if (nextStepAfterSubmit) nextStepAfterSubmit.nextStep();

        return;
      }
      setProduct(item.product);
      setNUUIDOne(product.nuuidOne)
      const {
        orientedSaleSteps,
        orientedSaleGroup,
        productWithoutGroup,
        newProduct,
        title,
      } = getOrientedSaleAndSubProduct(product);

      setTitle(title);
      setBackstep(true);

      if (
        productWithoutGroup &&
        productWithoutGroup.nivel1.length === 0 &&
        orientedSaleSteps[0].groupCode !== null
      ) {
        setModalProduct(true);
        setBackstep(false);
        setCurrencyPoints(false);
        setItemProductValuePoints(0);

        setItemId(productWithoutGroup._id);
        setItemProduct(productWithoutGroup.description);
        setItemProductValue(productWithoutGroup.value);
        setItemProductImage(productWithoutGroup.featuredImage);
        setItemProductCode(productWithoutGroup.productCode);
        setProductAccelerator(productWithoutGroup.accelerator);
        setItemProductQtd(productWithoutGroup.quantity);
        setItemProductClassification(productWithoutGroup.classification);
        setAdultConsumptionAlert(productWithoutGroup.adultConsumptionAlert);
        setItemProductPromotion(productWithoutGroup.promotion);
        setSuggestions(orientedSaleSteps);
        setNUUIDOne(product.nuuidOne);

        return;
      }

      if (
        !product.productCombinationAutomatic &&
        !productWithoutGroup &&
        product.hasOrientedSale !== true
      ) {
        setCategoryProducts(newProduct);
        setCurrencyPoints(false);
        setItemProductValuePoints(0);
      }

      // Pizza
      if (
        product.classification === "PIZZA" ||
        product.description === "COMBO CASAD. TRADICIONAIS"
      ) {
        //if (product.subProductCombinatioAutomatic != true) {
        setCurrencyPoints(false);
        setFilters([]);
        setCurrencyPoints(false);
        setItemProductValuePoints(0);
        setStyleGroupIsColumns(false);
        setBackstep(false);
        setNUUIDOne(product.nuuidOne);
        setContentPizza(
          <PizzaContainer className="pizzaTablet"
            backStepCategory={() => backStepCategory()}
            productCode={productCode}
            flavor={orientedSaleGroup[0].orientedSaleGroupProduct}
            product={item.product}
            cartItems={cartItems}
            controlNotification={(description) =>
              controlNotification(description)
            }
          />
        );

        return;
      }

      if (
        product.hasOrientedSale === true &&
        product.showModalPromotion !== true
      ) {
        const isBobsLayout = isLayout("BOBS");

        if(!isShowCustomCombo && isBobsLayout){
          const backStep = () => {
            backStepCategory();
            setKeyboard(true);
          };

          setCurrencyPoints(false);
          setFilters([]);
          setItemProductValuePoints(0);
          setStyleGroupIsColumns(false);
          setBackstep(false);

          setOrientedSale(
            <ComboIndividualContainer
            backStepCategory={backStep}
            orientedSaleSteps={orientedSaleSteps}
            product={product}
            cartItems={cartItems}
            controlNotification={controlNotification}
            submitNextStep={nextStepAfterSubmit?.nextStep}
            promocode={nextStepAfterSubmit?.campaigns?.promocode}
            />
          );

          setInsertCupom(false);

        }

        setModalProduct(isShowCustomCombo && isBobsLayout || !isBobsLayout);
        setItemId(itemId || product._id);
        setItemProduct(itemDescription || product.description);
        setItemProductValue(itemValue || product.value);
        setItemProductImage(product.featuredImage);
        setisComboUnic(product.orientedSale[0].orientedSaleSteps.length == 1 && !product.orientedSale[0].orientedSaleSteps[0]?.orientedSaleGroup)
        setItemProductCode(productCode);
        setProductAccelerator(product.accelerator);
        setItemProductHasOrientedSale(product.hasOrientedSale);
        setItemProductDetailedDescription(product?.detailedDescription || null);
        setItemProductClassification(product.classification);
        setItemProductQtd(1);
        setItemProductPromotion(product.promotion);
        setOnCloseModalProduct(() => {
          return (productAdded) => {
            if (
              productAdded &&
              product.hasOrientedSale === true &&
              product.subProductCombinationAutomatic !== true &&
              localStorage.getItem("QuantityCombo")
            ) {
              setKeyboard(false);
              setFilters([]);
              setCurrencyPoints(false);
              setItemProductValuePoints(0);
              setOrientedSaleSteps(orientedSaleSteps);
              setStyleGroupIsColumns(false);
              setBackstep(false);
              setOrientedSale(
                <ComboIndividualContainer
                  backStepCategory={() => {
                    backStepCategory();
                    setKeyboard(true);
                  }}
                  orientedSaleSteps={orientedSaleSteps}
                  product={product}
                  cartItems={cartItems}
                  controlNotification={controlNotification}
                />
              );
            }
          };
        });
        return;
      }
      // Orientade Sale
      if (product.showModalPromotion) {
        setFilters([]);
        setOrientedSaleSteps(orientedSaleSteps);
        setBackstep(false);
        setCurrencyPoints(false);
        setItemProductValuePoints(0);
        setSuggestions(item.product.suggestion);
        setItemId(itemId || product._id);
        setItemProduct(itemDescription || product.description);
        setItemProductValue(itemValue || product.value);
        setItemProductImage(itemImage || product.image);
        setAdultConsumptionAlert(item.product.adultConsumptionAlert);
        setItemProductQtd(1);
        setItemProductPromotion(item.product.promotion);
        setModalCombo(true);
        setNUUIDOne(product.nuuidOne);

        return;
      }
    });
  };

  const updateStateModalProduct = (productAdded = false) => {
    const hasBackstep = !!categoryProducts[0]
      ? !!categoryProducts[0].nivel2
      : "";
    const hasSuggestion = suggestions
      ? suggestions.find((i) => i.showModal === true)
      : false;

    if (hasSuggestion && productAdded) {
      return (
        setItemId(hasSuggestion.productWithoutGroup._id),
        setItemProduct(hasSuggestion.productWithoutGroup.description),
        setItemProductValue(hasSuggestion.productWithoutGroup.value),
        setItemProductImage(hasSuggestion.productWithoutGroup.featuredImage),
        setItemProductCode(hasSuggestion.productWithoutGroup.productCode),
        setProductAccelerator(hasSuggestion.productWithoutGroup.accelerator),
        setItemProductClassification(
          hasSuggestion.productWithoutGroup.classification
        ),
        setAdultConsumptionAlert(
          hasSuggestion.productWithoutGroup.adultConsumptionAlert
        ),
        setHasSuggestion(true),
        setModalProduct(true),
        setSuggestions([]),
        setKeyboard(true),
        setNUUIDOne(product.nuuidOne)
      );
    }

    return (
      setModalProduct(false), setBackstep(hasBackstep), setHasSuggestion(false)
    );
  };

  const updateStateModalCombo = (selectedCombo) => {
     setModalCombo(false);

    if (selectedCombo) {
      setKeyboard(false);
      setFilters([]);
      setCurrencyPoints(false);
      setItemProductValuePoints(0);
      setOrientedSaleSteps(orientedSaleSteps);
      setStyleGroupIsColumns(false);
      setBackstep(false);
      setOrientedSale(
        <ComboIndividualContainer
          backStepCategory={() => {
            backStepCategory();
            setKeyboard(true);
          }}
          orientedSaleSteps={orientedSaleSteps}
          product={product}
          cartItems={cartItems}
          controlNotification={controlNotification}
        />
      );
    } else {
      setOrientedSaleSteps(null);
      setModalProduct(true);
      setBackstep(false);
    }
  };

  const backStepCategory = (clearDataReshop = true) => {
    if (clearDataReshop) clearReshopCampaignData();

    let categorySelected = categories.find(
      (item) => item.categoryCode === backstepCategory.toString()
    );
    if (!categorySelected) categorySelected = categories[0];

    return loadCategoryProducts(
      backstepCategory || categorySelected.categoryCode
    ).then((res) => {
      const products = res?.products;
      setCategoryProducts(products);
      setBackstep(false);
      setContentPizza(null);
      setOrientedSale(null);
      setSelectedFilter([]);
      setFilters(categorySelected.filters);
      if (categorySelected.description === "PIZZAS") {
        setStyleGroupIsColumns(categorySelected.displayType === "L");
      } else {
        setStyleGroupIsColumns(categorySelected.displayType === "C");
      }
      setTitle(backstepTitle || categorySelected.description);
      setSubTitle(backstepSubtitle || categorySelected.customMessage);
    });
  };

  useEffect(() => {
    window.localStorage.removeItem("prismakey");
    window.localStorage.removeItem("tellkey")
    setKeyboard(true);
    loadCategoryItems(window.localStorage.getItem("pdvCode")).then((res) => {
      if (!!res.categories.length === false)
        return;

      const items = res.categories;
      setCategories(items);
      setTitle(items[0].description);
      setSubTitle(items[0].customMessage);
      setActive(items[0]._id);
      setCategoryCode(items[0].categoryCode);
      setFilters(items[0].filters);
      //tratamento para quando as categorias de fidelidade e resgate foram, inativadas (não tem ID unico da categoria)
      items[0].isFidelity === true && !window.localStorage.getItem("cpf")
        ? setImageFidelity(true)
        : setImageFidelity(false);
      items[0].isFidelityCoupon || items[1].isFidelityCoupon === true
        ? setInsertCupom(true)
        : setInsertCupom(false);

      if (items[0].isFidelity === true && localStorage.getItem("cpf")) {
        setAvailableFidelityPoint(true);
        setInsertCupom(false);
        fidelity(
          window.localStorage.getItem("cpf"),
          sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME)
        ).then((res) => {
          setProductsFidelity(res.Products);
          const products = handleFidelity(productsAll, res.Products);
          setProductsAll(products);
          setCategoryProducts(products);
        });
      }

      if (items[0].isFidelity === false) {
        loadCategoryProducts(items[0].categoryCode).then((res) => {
          const products = res?.products;
          setCategoryProducts(products);
        });
      }

      return;
    });
    if (isLayout("RAGAZZO")) {
      loadCategoryProducts(39).then((res) => {
        let productsResgate = [];
        res.products.forEach((item) =>
          productsResgate.push({
            description: item.product.description,
            image: item.product.image,
            catalogImage: item.product.catalogImage,
            featuredImage: item.product.featuredImage,
            productCode: item.product.productCode,
            value: item.product.value || item.product.mixValue,
            id: item._id,
          })
        );
        setProductsResgate(productsResgate);
      });
    }
    loadCategoryProducts(36).then((res) => {
      let productsResgate = [];
      res.products.forEach((item) =>
        productsResgate.push({
          description: item.product.description,
          image: item.product.image,
          catalogImage: item.product.catalogImage,
          featuredImage: item.product.featuredImage,
          productCode: item.product.productCode,
          value: item.product.value || item.product.mixValue,
          id: item._id,
        })
      );
      setProductsResgate(productsResgate);
    });
  }, []);

  function getSelectedQuantity(itemId) {
    if (!cartItems?.length) return;

    const qtd = cartItems
      .filter((item) => item.id === itemId)
      .reduce((acc, cur) => acc + cur.qtd, 0);

    return qtd === 0 ? "" : qtd;
  }

  // Items mounted categories

  const categorieItems = categories.map((i, indice) => {
    if (i.categoryHide !== true) {
      return (
        <li
          className={`listCategory-item ${
            active === i._id ? "listCategory-item--active" : ""
          }`}
          key={i._id}
          onClick={() =>
            changeCategory(
              i.description,
              i.customMessage,
              i.categoryCode,
              i._id,
              indice
            )
          }
        >
          {i.image !== null ? (
            <img src={i.image} alt={i.description} />
          ) : (
            <div className="no-image" />
          )}
          <p className="listCategory-item-name">{i.description}</p>
        </li>
      );
    }
  });

  // Items mounted products
  const groupSalesSelected = categoryProducts.map((item, indice) => {
    return (
      <div key={`${item}-${indice}`} className={"badge-content"}>
        {item.product ? (
          <span>
            <figure
              className="groupSales-item"
              onClick={() =>
                showProduct(
                  item.product._id,
                  item.product.description,
                  item.product.displayValue,
                  item.product.image,
                  item.productCode,
                  item,
                  { status: false, data: [], promocode: '' },
                  nextStepAfterSubmit
                )
              }
            >
              {item.product.image !== null ? (
                <img
                  src={item.product.image}
                  alt={item.product.description}
                  className={
                    cartItems.some((product) => product.id === item.product._id)
                      ? "groupSales-item-selected"
                      : ""
                  }
                />
              ) : (
                <div
                  className={
                    cartItems.some((product) => product.id === item.product._id)
                      ? "no-image groupSales-item-selected"
                      : "no-image"
                  }
                />
              )}

              <figcaption>{item.product.description}</figcaption>

              {item.product.displayValue === null ? (
                <p className="groupSales-price">
                  {currency(item.product.value)}
                </p>
              ) : (
                <p className="groupSales-price">{
                  item.product.displayValue === 0 ? "" : item.Points
                    ? item.Points + " pontos"
                    : currency(item.product.displayValue)
                  }
                </p>
              )}
            </figure>
            <span
              className={
                cartItems.some((product) => product.id === item.product._id)
                  ? "badge badge-top-right badge-color-primary"
                  : "badge-invisible"
              }
            >
              {getSelectedQuantity(item.product._id)}
            </span>
          </span>
        ) : (
          <span>
            <figure
              className="groupSales-item"
              onClick={() =>
                showProduct(
                  item._id,
                  item.description,
                  item.Points,
                  item.image,
                  item.ProductCode,
                  item
                )
              }
            >
              {item.image !== null ? (
                <img
                  className={
                    cartItems.some((product) => product.id === item._id)
                      ? "groupSales-item-selected"
                      : ""
                  }
                  src={item.image}
                  alt={item.description}
                />
              ) : (
                <div
                  className={
                    cartItems.some((product) => product.id === item._id)
                      ? "no-image groupSales-item-selected"
                      : "no-image"
                  }
                />
              )}

              <figcaption>{item.description}</figcaption>

              <p className="groupSales-price">{item.Points} pontos</p>
            </figure>
          </span>
        )}
      </div>
    );
  });

  const productFilters = filters.map((i) => {
    return (
      <span
        className={`${
          selectedFilter.includes(i.filterName) ? "selected" : "tag"
        }`}
        key={i._id}
        onClick={() => handleFilter(i.filterName, categoryCode)}
      >
        {i.filterName}
      </span>
    );
  });

  //Função utilizada para ativar a notificação dentro do sidenav component, feito assim por falta de tempo ... necessário refatorar.
  const controlNotification = (description) => {
    setItemProduct(description);
    setKeyboard(true);
    setNotification(true);
  };

  const removeNotificationContainer =  () => setNotification(false);

  const loadProductResgate = async (productCode, confirm, resultPromoCode)  => {
    let promoCodeCampgn = []
    promoCodeCampgn = (localStorage.getItem("promoCode") || []);
    if (!!productCode) {
      const resgateProduct = productsResgate.find(

        (item) => item.productCode === productCode
      );
      if (resgateProduct) {
        if (confirm) {
          return showProduct(
            resgateProduct.id,
            resgateProduct.description,
            resgateProduct.value,
            resgateProduct.image,
            resgateProduct.productCode,
            resgateProduct,
          );
        }
        showModalAddCupom(true);
        setProductDescriptionModalCupom(resgateProduct.description);
        setProductDisplayValueModalCupom(resgateProduct.value);
        setProductImageModalCupom(resgateProduct.image);
        return;
      } else {
        const reshopCodeItem = resultPromoCode.Campanhas.find((i) => i.ProdutosRegra);

        if(reshopCodeItem && (promoCodeCampgn.includes(productCode) !== true || isBobsLayout)) {
          const handleNextStep = handleNextStepAfterSubmit({
            status: true,
            data: resultPromoCode.Campanhas,
            promocode: productCode
          });

          handleNextStep.nextStep();
          setNextStepAfterSubmit(handleNextStep);
        }
      }

      if(resultPromoCode.Result) {
        if(promoCodeCampgn.includes(productCode) !== true || isBobsLayout) {
          promocodesInsert.push(productCode)
          localStorage.setItem("promoCode", JSON.stringify(promocodesInsert))
          setModalCupomAdd(true);
          setTimeout(() => {
            setModalCupomAdd(false);
          }, 3000);
        }else {
          setInvalidProductCode(true);
          setPromocodeReapt(true);
        }

      }else {
        setInvalidProductCode(true);
      }
      return;
    }
    return;
  };

  const closeModalCoupon = () => {
    setInvalidProductCode(false);
    showModalAddCupom(false);
    setPromocodeReapt(false);
  };

  return (
    <Sidenav
      categoryCode={categoryCode}
      categories={categories}
      title={title}
      subTitle={subtitle}
      listItems={categorieItems}
      orientedSale={orientedSaleList}
      content={orientedSale || contentPizza || groupSalesSelected}
      filters={productFilters}
      modalProduct={modalProduct}
      modalCombo={modalCombo}
      modalCupomAdd={modalCupomAdd}
      modalDrinks={modalDrinks}
      setModalDrinks={setModalDrinks}
      itemProduct={itemProduct}
      itemProductValue={itemProductValue}
      itemProductValuePoints={itemProductValuePoints}
      itemProductImage={itemProductImage}
      itemId={itemId}
      itemProductCode={code}
      updateStateModalProduct={updateStateModalProduct}
      updateStateModalCombo={updateStateModalCombo}
      backstep={backstep}
      backStepCategory={backStepCategory}
      groupIsColumns={styleGroupIsColumns}
      addSuggestion={() => {}}
      suggestion={hasSuggestion}
      testSuggestion={suggestions}
      notificationContainer={notification}
      removeNotificationContainer={removeNotificationContainer}
      accelerator={itemProductAccelerator}
      itemProductClassification={itemProductClassification}
      itemProductHasOrientedSale={itemProductHasOrientedSale}
      itemProductDetailedDescription={itemProductDetailedDescription}
      setItemProductClassification={setItemProductClassification}
      itemProductQuantity={itemProductQtd}
      itemProductPromotion={itemProductPromotion}
      currencyPoints={currencyPoints}
      sendProduct={loadProductResgate}
      closeModalCoupon={closeModalCoupon}
      showKeyboard={showKeyboard}
      checkModalPoints={checkModalPoints}
      closeModalPoints={() => setModalPoints(false)}
      invalidProductCode={invalidProductCode}
      promocodeReapt={promocodeReapt}
      modalAddCupom={modalAddCupom}
      productDescriptionModalCupom={productDescriptionModalCupom}
      productDisplayValueModalCupom={productDisplayValueModalCupom}
      productImageModalCupom={productImageModalCupom}
      imageFidelity={imageFidelity}
      availableFidelityPoint={availableFidelityPoint}
      insertCupom={insertCupom}
      setInsertCupom={(value) => setInsertCupom(value)}
      onCloseModalProduct={onCloseModalProduct}
      adultConsumptionAlert={adultConsumptionAlert}
      setAdultConsumptionAlert={setAdultConsumptionAlert}
      isComboUnic={isComboUnic}
      nuuidOne={nuuidOne}
    />
  );
};

export default SidenavContainer;
