import styled from "styled-components";

export const Container = styled.div`

  .expanded-title {
    background: #2e0037;
    color: #fff;
    font-weight: 700;
  }
`;

export const AccordionTitle = styled.div`
  align-items: center;
  background: #efefef;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  color: black;
  display: flex;

  div {
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      color: orange;
    }

    svg {
      margin-left: 1.2rem;
    }
  }
`;

export const AccordionContent = styled.div`
  height: 420px;
  overflow: hidden;
`;

