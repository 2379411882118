import styled from "styled-components";

export const PeriodWrapper = styled.div`
  display:flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  .react-datepicker__aria-live {
  display: none;
  }

  .periodSelect {
    max-width: 200px;
  }

  #periodo {
    flex: auto !important;
  }

  input {
    height: 30px;
    padding-left: 10px;

    &:nth-child(2) {
      margin-left: 0px;
    }
  }

  .btn {
    display: flex;
    height: 100%;
    width: 80px;
    justify-content: center;
    align-items: end;
    padding-left: 8px;


      &:hover {
        cursor: pointer;
        color: #ffb201;
      }
      padding-bottom: 4px;
  }

  .btn-pronto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #411e5a;
    border-radius: 4px !important;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: 14px;
    padding: 7px 20px;
    margin-left: 45px;
    margin-top: 20px;
    outline: none;

    .material-icons {
      color: #fff;
      font-size: 22px;
      margin-right: 5px;
    }
  }
`;

export const ContainerTable = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar * {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 190px;
  bottom: 60px;
  flex-direction: column;
  position: absolute;
  right: 37px;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;
