import React, { useState, useEffect } from "react";
import { currency } from "../../utils/currency";
import {
  SectionGrid,
  ContentGridScroll,
  ContentFigure,
  FigureItem,
  ImageItem,
  ItemPrice,
} from "./style";
import { StepComposition, StepList, Step } from "../modules";
import { returnsLevelOrientedSale } from "../../utils/returnsLevelOrientedSale";
import { getQuantityCombo } from "../../utils/getQuantityCombo";

const ComboCardIndividual = (props) => {
  const { listSteps, setListSteps, newStep, onSubmit, backStepCategory } =
    props;
  const [steps, setSteps] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [listCombo, setListCombo] = useState([]);

  const quantityCombo = getQuantityCombo();
  const selectedTotalCombo = listCombo.length;

  useEffect(() => {
    localStorage.setItem("selectedTotalCombo", String(selectedTotalCombo));
  }, [selectedTotalCombo]);

  const resetOptionsInItens = () => {
    setSteps(listSteps);
    setCurrentPage(0);
    setSelectedItems([]);
  };

  const sendRequest = (list) => {
    list.forEach(async (currentListCombo, indexNumlancto) => {
      onSubmit(currentListCombo, indexNumlancto);
    });

    resetOptionsInItens();
  };

  useEffect(() => {
    setSteps(listSteps);
  }, [listSteps]);

  /**
   * @param item {{nivel1: String[], nivel2: String[]}}
   * @param nextIndex {number|undefined}
   * @param chosenComposition {Boolean}
   * @param hasNext {Boolean}
   */
  const handleSelectItem = (
    item,
    nextIndex = undefined,
    chosenComposition = false,
    hasNext = true
  ) => {
    const existingLevel = returnsLevelOrientedSale(item);
    if (existingLevel === undefined || !!item[existingLevel].length === false)
      addItemSelected(item);

    if (!chosenComposition) {
      if (
        !hasNext &&
        (existingLevel === undefined || !!item[existingLevel].length === false)
      ) {
        const allItemsSelected = [...selectedItems];
        allItemsSelected.push(item);

        if (selectedTotalCombo === quantityCombo) {
          const allListCombo =
            selectedTotalCombo === 0 ? [allItemsSelected] : listCombo;
          sendRequest(allListCombo);
        } else {
          setListCombo((restList) => {
            const allList =
              restList.length > 0
                ? [...restList, allItemsSelected]
                : [allItemsSelected];

            if (allList.length === quantityCombo) {
              sendRequest(allList);
              return restList;
            } else {
              resetOptionsInItens();
              handlePrevious(1);
              return allList;
            }
          });
        }
      } else {
        nextIndex = nextIndex + 1;
        setCurrentPage(nextIndex);
        newStep(item, nextIndex);
      }
    }
  };

  const addItemSelected = (product) => {
    const newSelectedItems = selectedItems.filter((item) => {
      if (
        (item.productCode !== product.productCode &&
          item.classification !== product.classification) ||
        (item.isProductComposition !== undefined &&
          item.productCode !== product.productCode)
      ) {
        return item;
      }
    });

    setSelectedItems([...newSelectedItems, product]);
  };

  const handleRemoveItem = (item) => {
    setSelectedItems((oldSelectedItems) => {
      const newSelectedItems = oldSelectedItems.map((currentSelectedItem) => {
        if (currentSelectedItem.productCode === item.productCode) {
          return item;
        }

        return currentSelectedItem;
      });

      return newSelectedItems;
    });
  };

  const handlePrevious = (index) => {
    if (index > 0) {
      const moficatorStep = steps;
      const lastStep = moficatorStep[moficatorStep.length - 1];
      const lastItem = selectedItems[selectedItems.length - 1];

      if (lastItem) {
        moficatorStep.forEach((step) => {
          if (lastStep.index === step.index) {
            step.listRadioButton.forEach((item) => {
              if (item.productCode === lastItem.productCode) {
                selectedItems.pop();
                setSelectedItems([...selectedItems]);
              }
            });
          }
        });

        if (lastStep?.dynamicallyGeneratedStep) {
          moficatorStep.pop();
          setListSteps(moficatorStep);
        }
      }

      setCurrentPage(index - 1);
    } else {
      backStepCategory();
    }
  };

  const handleJumpStep = (index) => {
    if (steps.length === index + 1) onSubmit(selectedItems);
    else setCurrentPage(index + 1);
  };

  const layoutItems = (item, index, hasNext) => {
    const foundItemSelected = selectedItems.find(
      (itemSelected) => itemSelected.productCode == item.productCode
    );
    const classItemSelected = foundItemSelected
      ? "groupSales-item-selected"
      : "";

    return (
      <ContentFigure className="">
        <FigureItem
          className=""
          onClick={() => handleSelectItem(item, index, false, hasNext)}
        >
          <ImageItem
            src={item.featuredImage}
            alt="&nbsp;"
            className={
              !item.featuredImage
                ? `no-image ${classItemSelected}`
                : classItemSelected
            }
          />
          <figcaption className="name-combo-card-item">
            {item.description}
          </figcaption>
          {!!item.lowerValue && (
            <ItemPrice>{currency(item.lowerValue)}</ItemPrice>
          )}
          {foundItemSelected && (
            <span className="badge badge-top-right badge-color-primary">✓</span>
          )}
        </FigureItem>
      </ContentFigure>
    );
  };

  const layoutCards = (step, index, hasNext) => {
    if (step.composition) {
      return (
        <StepComposition
          steps={steps}
          listProducts={step.listRadioButton}
          onSubmit={() => props.onSubmit(selectedItems)}
          listProductsSelected={selectedItems}
          onAddItem={handleSelectItem}
          handleRemoveItem={handleRemoveItem}
        />
      );
    }

    return (
      <ContentGridScroll>
        {step.listRadioButton.map((item) => layoutItems(item, index, hasNext))}
      </ContentGridScroll>
    );
  };
  const layoutCombo = (step, index, hasNext) => {
    return (
      <>
        {(!step.productWithoutGroup ||
          (step.index === 0 && step.quantity === null) ||
          step.quantity > 1 ||
          (step.productWithoutGroup && step.listRadioButton.length > 1)) && (
          <SectionGrid>{layoutCards(step, index, hasNext)}</SectionGrid>
        )}
      </>
    );
  };

  const layoutStep = (step, index, hasNext) => {
    const handleRequiredItem = (step) => {
      if (step.composition) return true;

      return step.required;
    };

    return (
      <Step
        key={index}
        index={index + 1}
        title={step.description}
        currentPage={currentPage}
        displayNext={step.composition === undefined ? false : step.composition}
        displayRequired={handleRequiredItem(step)}
        onPrevious={() => handlePrevious(index)}
        onJump={() => handleJumpStep(index)}
        onNext={() => handleJumpStep(index)}
      >
        {layoutCombo(step, index, hasNext)}
      </Step>
    );
  };

  return (
    <>
      <StepList>
        {steps.map((step) => {
          let hasNext = false;
          const nextStep = steps[step.index + 1];
          if (nextStep && nextStep.index !== step.index) hasNext = true;

          return layoutStep(step, parseInt(step.index), hasNext);
        })}
      </StepList>
    </>
  );
};

export default React.memo(ComboCardIndividual);
