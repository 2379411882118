import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MdClose,
  MdPayment,
  MdPrint,
  MdOutlineCancel,
  MdOpenInBrowser,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import { Container, ChangeTableIcon } from "./styles";
import { KeyboardProvider } from "../../../contexts/KeyboardContext";
import { useCart } from "../../../hook/cartHook";
import { useNavigate } from "react-router-dom";
import { currencyString } from "../../../helpers/masks";
import { PagesEnum } from "../../../constants/pagesEnum";
import { DATA_USER_AUTH_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import { useCashdesk } from "../../../hook/cashdeskHook";
import { useAuth } from "../../../hook/authHook";
import { useDialog } from "../../../hook/dialogHook";
import { saleInProgressPrint } from "../../../../services/printRoutesService";
import ModalQuantityClient from "../ModalQuantityClient";
import { printDefault } from "../../../helpers/printer/printDefault";
import {
  CheckboxRenderer,
  HeaderCheckboxComponent,
} from "../../cell-renderers-grid";
import { getSystemParamsHelper } from "../../../helpers/getSystemParamsHelper";
import { transactionData } from "../../../../aaone/shared/utils/transactionNumber";
import SaleUseCase from "../../../domain/useCases/sale/SaleUseCase";
import { useSallerTable } from "../../../hook/sallerTableHook";

export const ModalSeller = ({ onClose, isOpen, saleInProgressId }) => {
  const {
    tableStatus,
    setTableStatus,
    closedPositionsCount,
    setClosedPositionsCount,
    numbersCustomers,
    setnumbersCustomers,
    isTableOpen,
    setIsTableOpen,
    setPrint,
    valuePerPerson,
    setValuePerPerson,
    positionStatus,
    setPositionStatus,
    areAllPositionsSelectedActive,
    setAreAllPositionsSelectedActive,
    areAllPositionsSelectedClose,
    setAreAllPositionsSelectedClose,
    handleUpdatePositionStatus,
    handleUpdateTableStatus,
    updatePositionStatusAndHighlightRows,
    checkIfAllPositionsClosed
  } = useSallerTable();
  const systemParams = getSystemParamsHelper();

  const agGridRef = useRef();
  const selectAllRef = useRef();
  const selectPositionFilterRef = useRef();

  const operator = JSON.parse(sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME));

  const saleInProgressConfiguration =
    systemParams?.saleInProgressConfiguration?.workWithTableByPosition;

  const config = window.mobileConfig
    ? JSON.parse(window.localStorage.getItem("AA_ConfigDevice")) || {}
    : JSON.parse(window.sessionStorage.getItem("AA_Config"));

  const [isOpenModalQuantity, setIsOpenModalQuantity] = useState(false);
  const navigate = useNavigate();
  const { addProductToCart } = useCart();
  const { userAuthData } = useAuth();
  const { showAlert } = useDialog();

  const isMobile = !!window.Android;

  const defaultColDef = {
    sortable: true,
    resizable: true,
    width: 100,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
  };

  const statusBar = {
    statusPanels: [{ statusPanel: "agTotalRowCountComponent", align: "left" }],
  };

  const gridOptions = {
    getRowStyle: (params) => {
      if (params.node.group) {
        const allLeafChildren = params.node.allLeafChildren;

        if (allLeafChildren.length > 0) {
          const statusValues = new Set(
            allLeafChildren.map((childNode) => {
              const rowId = childNode?.parent.id;
              const position =
                childNode?.parent?.allLeafChildren[0]?.data.position;

              const element = document.querySelector(`[row-id='${rowId}']`);

              if (element && position) {
                element.classList.add(
                  "position-" + position + "-row",
                  "position-status-" +
                  String(childNode.data.status).toLowerCase()
                );
              }

              return childNode.data.status;
            })
          );

          if (statusValues.size === 1) {
            const status = statusValues.values().next().value;

            const styles = {
              "--position-row": allLeafChildren[0]?.data.position,
            };

            if (status === "CLOSING") {
              return { ...styles, "background-color": "#c7251b" };
            } else if (status === "ACTIVE") {
              return { ...styles, "background-color": "#4594f2" };
            }
          }
        }
      }
      return null;
    },
    groupDefaultExpanded: -1,
    groupUseEntireRow: true,
    autoGroupColumnDef: {
      headerName: "POSIÇÃO",
      flex: 0.7,
      cellRendererParams: {
        suppressCount: true,
      },
      hide: true,
    },
  };

  const columnDefs = useMemo(() => {
    const columns = [
      {
        field: "productCode",
        headerName: "CÓDIGO",
        flex: 0.4,
        cellStyle: { textAlign: "center", fontWeight: "bold" },
      },
      {
        field: "description",
        headerName: "PRODUTO",
        flex: 1,
      },
      {
        field: "quantity",
        headerName: "QTD",
        flex: 0.3,
        cellStyle: { textAlign: "center" },
      },
      {
        field: "value",
        headerName: "PREÇO",
        flex: 0.4,
        cellStyle: { textAlign: "center" },
      },
      {
        field: "total",
        headerName: "TOTAL",
        flex: 0.4,
        cellStyle: { textAlign: "center", fontWeight: "bold" },
      },
    ];

    // Em caso de posições estarem ativas exibo a opção de posições
    if (saleInProgressConfiguration && saleInProgressId?.positions) {
      gridOptions.autoGroupColumnDef.hide = false;
      columns.unshift({
        field: "positionFormatted",
        rowGroup: true,
        hide: true,
        headerComponent: "headerCheckboxComponent",
        cellRenderer: "checkboxRenderer",
      });
    }

    return columns;
  }, [gridOptions, saleInProgressId]);

  useEffect(() => {
    setValuePerPerson(
      saleInProgressId?.totalValue / numbersCustomers || valuePerPerson
    );
  }, [saleInProgressId?.totalValue, numbersCustomers]);

  useEffect(() => {
    setTableStatus(saleInProgressId?.status || "");
    setIsTableOpen(saleInProgressId?.status === "ACTIVE");
    setnumbersCustomers(saleInProgressId?.numberOfCustomers || "");
    setPositionStatus([]);
  }, [saleInProgressId]);

  useEffect(() => {
    if (saleInProgressId && saleInProgressId.positions) {
      const closingPositionsCount = saleInProgressId.positions.filter(
        (position) => position.status === "CLOSING"
      ).length;
      setClosedPositionsCount(closingPositionsCount);
    }
  }, [saleInProgressId]);

  useEffect(() => {
    checkIfAllPositionsClosed(closedPositionsCount);
  }, [closedPositionsCount]);

  useEffect(() => {
    // verifica se todas as posições selecionadas estão abertas ou fechadas
    const isAllSelectedClosing = !!positionStatus?.length && positionStatus.every((item) => item.status === "CLOSING");
    const isAllSelectedActive = !!positionStatus?.length && positionStatus.every((item) => item.status === "ACTIVE");
    setAreAllPositionsSelectedClose(isAllSelectedClosing)
    setAreAllPositionsSelectedActive(isAllSelectedActive)
  }, [positionStatus]);

  // Realiza o pagamento integral da mesa ou de uma determinada posição (adiciona os produtos da mesa no carrinho)
  const handlePayTable = (position) => {
    if (tableStatus === "ACTIVE") {
      showAlert({
        message: `É necessário fechar a mesa antes de realizar o pagamento!`,
      });
      return;
    }

    if (saleInProgressId?.positions) {
      saleInProgressId.positions.forEach((item) => {
        item.items.forEach((childrenItem) => {
          const { quantity, ...rest } = childrenItem;
          const itemsToAdd = Array.from({ length: quantity }, () => ({
            ...rest,
          }));

          itemsToAdd.forEach((itemToAdd) => {
            addProductToCart(itemToAdd);
          });
        });
      });
    }

    const newStatus = "CLOSING";
    handleUpdateTableStatus(newStatus);
    sessionStorage.setItem(
      "tableSelected",
      JSON.stringify({
        tableNumber: saleInProgressId.tableNumber,
        isPayment: true,
        saleInProgressId: saleInProgressId._id,
      })
    );
    sessionStorage.setItem(
      "customersSelected",
      JSON.stringify(numbersCustomers)
    );
    navigate(PagesEnum.HOME);
  };

  // Realiza a impressão da Pré-conta Mesa
  const printConferenceTable = async (updatedNumberOfCustomers) => {
    const Sale = new SaleUseCase();

    const selectedPositions = positionStatus.map((item) => item.position);

    const numberPositionsSelected = positionStatus
      .filter(
        (item, index, self) =>
          self.findIndex(
            (otherItem) => otherItem.position === item.position
          ) === index
      )
      .map((item) => item.position);

    if (saleInProgressConfiguration && selectedPositions.length === 0) {
      showAlert({
        message: `Nenhuma posição selecionada. Selecione pelo menos uma posição antes de imprimir a conta!`,
      });
      return;
    }

    // Filtrar as posições com base nas selecionadas no Ag-Grid
    const updatedSaleInProgress = { ...saleInProgressId };

    updatedSaleInProgress.numberOfCustomers = updatedNumberOfCustomers;
    updatedSaleInProgress.sellerReferenceId = operator.employeeCode;
    updatedSaleInProgress.sellerName = operator.login;
    updatedSaleInProgress.dateTimeStart = transactionData(
      new Date(),
      "dd/MM/yyyy hh:mm:ss"
    );

    if (saleInProgressConfiguration) {
      updatedSaleInProgress.positions = await Promise.all(
        saleInProgressId.positions
          .filter((position) => {
            if (saleInProgressId.positions.length > 1) {
              return selectedPositions.includes(position.position);
            }

            return true;
          })
          .map(async (position) => {
            Sale.emptyItems();

            position.items.forEach((childrenItem) => {
              const { quantity, ...rest } = childrenItem;
              const itemsToAdd = Array.from({ length: quantity }, () => ({
                ...rest,
              }));

              itemsToAdd.forEach((itemToAdd) => {
                Sale.addItem(itemToAdd);
              });
            });

            const items = await Sale.processItemsFinalizationSale();

            return { ...position, items: items.items };
          })
      );

      updatedSaleInProgress.numberOfCustomers = numberPositionsSelected?.length;
    }

    saleInProgressPrint({
      isText: isMobile,
      printerDimension: config.printSize === "56mm" ? "56mm" : "80mm",
      object: updatedSaleInProgress,
    })
      .then((response) => {
        setPrint(response);

        const contentToPrint = response?.data;
        if (window.desktopApp) {
          window.desktopApp.publish("machine.devices.printer", {
            body: contentToPrint,
          });
        } else if (isMobile) {
          console.log(`printDefault | {}`);
          printDefault(contentToPrint);
        } else {
          console.error("Erro: Não encontrado nenhum objeto para impressão");
        }
      })
      .catch((error) => {
        console.error("Erro na impressão:", error);
      });
  };

  const handleCloseTable = () => {
    if (saleInProgressConfiguration) {
      // Verifica se há alguma posição selecionada
      const isPositionSelected =
        Array.isArray(positionStatus) && positionStatus.length > 0;

      if (!isPositionSelected) {
        showAlert({
          message: `Nenhuma posição selecionada. Selecione pelo menos uma posição antes de fechar!`,
        });
      } else {
        // Verifica todos tem status "CLOSING" nas posições
        const hasClosingStatus =
          Array.isArray(positionStatus) &&
          positionStatus.every((item) => item.status === "CLOSING");

        if (hasClosingStatus) {
          showAlert({
            message: `Posição já se encontra fechada!`,
          });
        } else {
          sessionStorage.setItem(
            "customersSelected",
            JSON.stringify(numbersCustomers)
          );
          setIsOpenModalQuantity(true);
          handleModalQuantityConfirm();
        }
      }
    } else {
      // Se saleInProgressConfiguration for falso, executa o código diretamente
      sessionStorage.setItem(
        "customersSelected",
        JSON.stringify(numbersCustomers)
      );
      setIsOpenModalQuantity(true);
      handleModalQuantityConfirm();
    }
  };

  //Realiza o fechamento da mesa
  const handleModalQuantityConfirm = () => {
    setIsOpenModalQuantity(false);

    if (tableStatus === "CLOSING") {
      return;
    }

    const newStatus = "CLOSING";
    const updatedNumberOfCustomers = JSON.parse(
      sessionStorage.getItem("customersSelected")
    );

    if (!saleInProgressConfiguration) {
      handleUpdateTableStatus(newStatus, updatedNumberOfCustomers);
    } else {
      const positionsToClose = saleInProgressId.positions.map(
        (position) => position.position
      );

      handleUpdatePositionStatus(
        newStatus,
        updatedNumberOfCustomers,
        positionsToClose
      );

      const updatedPositionStatus = positionStatus.map((position) => {
        if (positionsToClose.includes(position.position)) {
          return {
            ...position,
            status: newStatus,
          };
        }
        return position;
      });

      setPositionStatus(updatedPositionStatus);

      updatedPositionStatus.forEach((currentPosition) => {
        if (currentPosition.status === "CLOSING") {
          const positionValue = currentPosition.position;

          document
            .querySelectorAll(".ag-row.ag-row-group")
            .forEach((element) => {
              const position = element.getAttribute("style");
              if (
                position &&
                position.includes(`--position-row: ${positionValue};`)
              ) {
                element.style["background-color"] = "#c7251b";
              }
            });
        }
      });
    }

    setIsTableOpen(false);
    printConferenceTable(updatedNumberOfCustomers);
  };

  // Realiza a reabertura da mesa
  const handleReopenTable = () => {
    const newStatus = "ACTIVE";
    if (saleInProgressConfiguration) {
      // Verifica se há alguma posição selecionada
      const isPositionSelected =
        Array.isArray(positionStatus) && positionStatus.length > 0;

      const positions = saleInProgressId.positions.map(
        (position) => position.position
      );

      sessionStorage.setItem(
        "customersSelected",
        JSON.stringify(numbersCustomers)
      );

      const updatedNumberOfCustomers = JSON.parse(
        sessionStorage.getItem("customersSelected")
      );

      if (!isPositionSelected) {
        showAlert({
          message: `Nenhuma posição selecionada. Selecione pelo menos uma posição antes de reabrir!`,
        });
      } else {
        // Verifica se todos tem status "CLOSING" nas posições
        const hasClosingStatus =
          Array.isArray(positionStatus) &&
          positionStatus.every((item) => item.status === "CLOSING");

        if (!hasClosingStatus) {
          showAlert({
            message: `Posição já se encontra aberta!`,
          });
        } else {
          if (userAuthData?.isManager) {
            handleUpdatePositionStatus(newStatus, updatedNumberOfCustomers);
            updatePositionStatusAndHighlightRows(newStatus, updatedNumberOfCustomers, positions)
          } else {
            navigate(PagesEnum.NEED_AUTH, {
              state: {
                redirectUrl: PagesEnum.SALLER_TABLE,
                redirectBackOnCancel: true,
                action: "reopenPosition",
                newStatus,
                updatedNumberOfCustomers,
                positions
              },
            });
          }
        }
      }
    } else {
      if (userAuthData?.isManager) {
        sessionStorage.setItem(
          "customersSelected",
          JSON.stringify(numbersCustomers)
        );
        const updatedNumberOfCustomers = JSON.parse(
          sessionStorage.getItem("customersSelected")
        );
        handleUpdateTableStatus(newStatus, updatedNumberOfCustomers);
        setIsTableOpen(true);
      } else {
        navigate(PagesEnum.NEED_AUTH, {
          state: {
            redirectUrl: PagesEnum.SALLER_TABLE,
            redirectBackOnCancel: true,
            action: "reopenTable",
            newStatus
          },
        });
      }
    }
  };

  const handleSelectAllPositions = () => {
    const totalGridRows = agGridRef.current.api.getDisplayedRowCount();
    const selectedRows = agGridRef.current.api.getSelectedNodes();

    if (totalGridRows === 0) return;

    const allRows = [];
    agGridRef.current.api.forEachNode((row) => allRows.push(row));

    if (selectedRows.length === allRows.length) {
      agGridRef.current.api.deselectAll();
      selectAllRef.current.checked = false;
    } else {
      agGridRef.current.api.selectAll();
      selectAllRef.current.checked = true;
    }

    agGridRef.current.api.refreshCells({
      force: true,
      columns: ["positionFormatted"],
    });
  };

  const handlePositionChange = (newPosition) => {
    if (newPosition > saleInProgressId?.numberOfCustomers) {
      newPosition = 0;
    } else if (newPosition < 0) {
      newPosition = saleInProgressId?.numberOfCustomers;
    }

    let allRows = [];
    agGridRef.current.api.forEachNode((node) => allRows.push(node));

    const nextPosition = allRows.find((node) => {
      return node?.allChildrenCount > 0
        ? newPosition > saleInProgressId?.numberOfCustomers
          ? false
          : node.allLeafChildren[0].data.position === newPosition
        : false;
    });

    agGridRef.current.api.collapseAll();

    if (nextPosition) {
      agGridRef.current.api.setRowNodeExpanded(nextPosition, true);
    } else {
      agGridRef.current.api.expandAll();
    }

    selectPositionFilterRef.current.value = String(newPosition);
  };

  //Realiza o filtro dos produtos para exibição no Ag-Grid
  const rowData = useMemo(() => {
    const rowData = [];

    if (saleInProgressId?.positions) {
      if (saleInProgressId.positions.length === 0) {
        const position = saleInProgressId.positions[0];
        if (position.items && position.items.length > 0) {
          position.items.forEach((item) => {
            if (!item.isOnlyLevel) {
              rowData.push({
                description: item.description,
                quantity: item.quantity,
                productCode: item.productCode,
                value: currencyString(item.value),
                total: currencyString(item.quantity * item.value),
              });
            }

            if (item.steps && item.steps.length > 0) {
              item.steps.forEach((step) => {
                if (step.selected && step.selected.length > 0) {
                  step.selected.forEach((selectedItem) => {
                    rowData.push({
                      description: ">" + selectedItem.description,
                      quantity: selectedItem.amount,
                      productCode: selectedItem.productCode,
                      value: currencyString(selectedItem.value),
                      total: currencyString(
                        selectedItem.amount * selectedItem.value
                      ),
                    });
                  });
                }
              });
            }
          });
        }
      } else {
        saleInProgressId.positions.forEach((position) => {
          const positionFormatted = position.name
            ? "POSIÇÃO " + position.position + " - " + position.name
            : "POSIÇÃO " + position.position;
          const status = position.status;

          position.items.forEach((item) => {
            rowData.push({
              position: position.position,
              positionFormatted,
              description: item.description,
              quantity: item.quantity,
              productCode: item.productCode,
              clientName: position.name,
              status,
              value: currencyString(item.value),
              total: currencyString(item.quantity * item.value),
            });

            if (item.steps && item.steps.length > 0) {
              item.steps.forEach((step) => {
                if (step.selected && step.selected.length > 0) {
                  step.selected.forEach((selectedItem) => {
                    rowData.push({
                      position: position.position,
                      positionFormatted,
                      description: "ㅤ>ㅤ" + selectedItem.description,
                      quantity: selectedItem.amount,
                      productCode: selectedItem.productCode,
                      status,
                      value: currencyString(selectedItem.value),
                      total: currencyString(
                        selectedItem.amount * selectedItem.value
                      ),
                    });
                  });
                }
              });
            }
          });
        });
      }
    }

    return rowData;
  }, [saleInProgressId]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <KeyboardProvider>
      <Container>
        <div className="content__wrapper">
          <div className="header__content">
            <header className="header">
              <div className="title">Conta</div>
              <button onClick={onClose} className="button__close">
                <MdClose size="1.5rem" />
              </button>
            </header>
          </div>
          <div className="header__content">
            <div className="header status">
              <div className="table-number">
                Mesa
                <div>
                  <strong>{saleInProgressId?.tableNumber}</strong>
                </div>
              </div>

              {saleInProgressId?.positions.length > 1 && (
                <div className="position-nav">
                  <span>Posição</span>

                  <div className="position-selector">
                    <select
                      ref={selectPositionFilterRef}
                      onChange={(e) =>
                        handlePositionChange(Number(e.target.value))
                      }
                    >
                      <option value={0}>Todas</option>
                      {saleInProgressId?.positions?.map((_, i) => (
                        <option value={i + 1}>{i + 1}</option>
                      ))}
                    </select>
                    <button
                      className="button__action"
                      onClick={() =>
                        handlePositionChange(
                          (Number(selectPositionFilterRef.current.value) ?? 0) -
                          1
                        )
                      }
                    >
                      <MdOutlineArrowBackIos />
                    </button>
                    <button
                      className="button__action"
                      onClick={() =>
                        handlePositionChange(
                          (Number(selectPositionFilterRef.current.value) ?? 0) +
                          1
                        )
                      }
                    >
                      <MdOutlineArrowForwardIos />
                    </button>
                  </div>
                </div>
              )}
              <div className="table-status">
                Status Mesa{" "}
                <div style={{ marginTop: "10px" }}>
                  <span
                    className={
                      tableStatus === "ACTIVE"
                        ? "active"
                        : tableStatus === "CLOSING"
                          ? "closing"
                          : ""
                    }
                  >
                    {tableStatus === "ACTIVE"
                      ? "Aberta"
                      : tableStatus === "CLOSING"
                        ? "Fechamento em andamento"
                        : tableStatus}
                  </span>
                </div>
              </div>
              {saleInProgressConfiguration && (
                <div className="position-nav">
                  <span>Legenda</span>
                  <div style={{ marginTop: "10px" }}>
                    <button className="subtitle active" disabled></button>
                    <span>Aberta</span>
                    <button className="subtitle closing" disabled></button>
                    <span>Fechada</span>
                    <button className="subtitle payment" disabled></button>
                    <span>Pago</span>
                  </div>
                </div>
              )}
              {!saleInProgressConfiguration && (
                <div className="payment-individualization">
                  <div>
                    <label>Valor Total</label>
                    <div>
                      <input
                        defaultValue={
                          saleInProgressId?.totalValue
                            ? currencyString(saleInProgressId.totalValue)
                            : ""
                        }
                        disabled
                      />
                      <span>÷</span>
                    </div>
                  </div>
                  <div>
                    <label>Quantidade de Pessoas</label>
                    <div>
                      <input value={numbersCustomers} disabled />
                      <span>=</span>
                    </div>
                  </div>
                  <div>
                    <label>Valor por Pessoa</label>
                    <div>
                      <input value={currencyString(valuePerPerson)} disabled />
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ag-grid">
            {saleInProgressConfiguration && (
              <div style={{ marginLeft: "10px" }}>
                <input
                  type="checkbox"
                  ref={selectAllRef}
                  id="selectAll"
                  onChange={handleSelectAllPositions}
                />
                <label for="selectAll">Selecionar todos</label>
              </div>
            )}
            <div className="content-aggrid">
              <div
                className="ag-theme-balham monitor"
                style={{ width: `calc(65% - 40px)` }}
              >
                <AgGridReact
                  rowData={rowData}
                  ref={agGridRef}
                  localeText={agGridLocalePtBr}
                  suppressContextMenu
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  groupRowRendererParams={{
                    checkbox: true,
                  }}
                  gridOptions={gridOptions}
                  rowBuffer={0}
                  statusBar={statusBar}
                  alwaysShowVerticalScroll={true}
                  rowSelection={"multiple"}
                  groupSelectsChildren={false}
                  groupSelectsFiltered={false}
                  suppressAggFuncInHeader={true}
                  suppressRowClickSelection={true}
                  onSelectionChanged={(params) => {
                    const selectedNodes = params.api.getSelectedNodes();
                    const selectedDataMap = new Map();
                    const selectedData = [];

                    selectedNodes.forEach((node) => {
                      if (node.group) {
                        if (node.allLeafChildren.length > 0) {
                          node.allLeafChildren.forEach((childNode) => {
                            const description = childNode.data.description;
                            if (!selectedDataMap.has(description)) {
                              selectedDataMap.set(description, []);
                            }
                            selectedDataMap.get(description).push(childNode.data);
                          });
                        } else {
                          const description = node.data.description;
                          if (!selectedDataMap.has(description)) {
                            selectedDataMap.set(description, []);
                          }
                          selectedDataMap.get(description).push(node.data);
                        }
                      }
                    });

                    selectedDataMap.forEach((values) => {
                      for (const value of values) {
                        selectedData.push(value);
                      }
                    });

                    setPositionStatus(selectedData);
                  }}
                  frameworkComponents={{
                    checkboxRenderer: CheckboxRenderer,
                    headerCheckboxComponent: HeaderCheckboxComponent,
                  }}
                ></AgGridReact>
              </div>
              <div className="info__text__container">
                <div className="info__text">
                  <div className="text-row">
                    <span>Subtotal</span>
                    <span>
                      {saleInProgressId?.totalValue
                        ? currencyString(saleInProgressId.totalValue)
                        : ""}
                    </span>
                  </div>
                  <div className="text-row">
                    <span>Taxas</span>
                    <span>R$0,00</span>
                  </div>
                  <div className="text-row">
                    <span>Desconto</span>
                    <span>R$0,00</span>
                  </div>
                  <div className="text-row">
                    <span>Pago</span>
                    <span>R$0,00</span>
                  </div>
                  <br />
                  <div className="text-row">
                    <h3>TOTAL</h3>
                    <h3>
                      {saleInProgressId?.totalValue
                        ? currencyString(saleInProgressId.totalValue)
                        : ""}
                    </h3>
                  </div>
                </div>
                <div className="content__container">
                  <div
                    role="button"
                    //onClick={''}
                    className="button button__white"
                  >
                    <MdOutlineCancel size="1.5rem" />
                    <div className="button__text">Cancelar Conta</div>
                  </div>
                  <div
                    role="button"
                    //onClick={''}
                    className="button button__white"
                  >
                    <ChangeTableIcon size="1.5rem" />
                    <div className="button__text">Transferir</div>
                  </div>
                </div>
                <div className="content__container">
                  <div
                    role="button"
                    onClick={() => printConferenceTable(numbersCustomers)}
                    className="button button__white"
                  >
                    <MdPrint size="1.5rem" />
                    <div className="button__text">Imprimir Conta</div>
                  </div>
                  <div
                    role="button"
                    onClick={
                      () => {
                        if (saleInProgressConfiguration) {
                          if (areAllPositionsSelectedClose) {
                            handleReopenTable();
                          } else if (areAllPositionsSelectedActive) {
                            handleCloseTable();
                          } else if (isTableOpen) {
                            handleCloseTable();
                          } else {
                            handleReopenTable();
                          }
                        } else if (isTableOpen) {
                          handleCloseTable();
                        } else {
                          handleReopenTable();
                        }
                      }
                    }
                    className="button button__close"
                  >
                    {saleInProgressConfiguration ?
                      areAllPositionsSelectedActive ? <MdPayment size="1.5rem" /> : areAllPositionsSelectedClose
                        ? <MdOpenInBrowser size="1.5rem" /> : isTableOpen
                          ? <MdPayment size="1.5rem" /> : <MdOpenInBrowser size="1.5rem" /> : isTableOpen
                        ? (
                          <MdPayment size="1.5rem" />
                        ) : (
                          <MdOpenInBrowser size="1.5rem" />
                        )}
                    <div className="button__text">
                      {saleInProgressConfiguration ?
                        areAllPositionsSelectedActive ? 'Fechar Posição' : areAllPositionsSelectedClose
                          ? 'Reabrir Posição' : isTableOpen
                            ? 'Fechar Mesa' : 'Reabrir Mesa' : isTableOpen
                          ? (
                            'Fechar Mesa'
                          ) : (
                            'Reabrir Mesa'
                          )}
                    </div>
                  </div>
                </div>
                <div className="content__container">
                  <div
                    role="button"
                    onClick={handlePayTable}
                    className="button button__payment"
                  >
                    <MdPayment size="1.5rem" />
                    <div className="button__text">Pagamento</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isOpenModalQuantity && (
        <ModalQuantityClient
          isOpen={isOpenModalQuantity}
          onClose={() => setIsOpenModalQuantity(false)}
          saleInProgressId={saleInProgressId}
          onConfirm={handleModalQuantityConfirm}
          isClosing={true}
        />
      )}
    </KeyboardProvider>
  );
};

export default ModalSeller;
