import SaleStepUseCase from "./SaleStepUseCase";
import { transactionData } from "../../../../aaone/shared/utils/transactionNumber";
import UtilsUseCase from "../UtilsUseCase";

const ENVIRONMENT_DESK = 1;
const ENVIRONMENT_AA = 2;
const ENVIRONMENT_DELIVERY = 3;
const ENVIRONMENT_MOBILE = 4;

export default class SaleUseCase {
  #items;
  #currentSaleId;
  #selectedEnvironment;
  #newSaleId;
  constructor(environment = ENVIRONMENT_DESK) {
    this.#items = new Set();
    this.#currentSaleId = null;
    this.#selectedEnvironment = environment;
    this.#newSaleId = 0;
  }

  getItems() {
    return [...this.#items];
  }

  addItem(item) {
    return this.handleItems(item);
  }

  duplicateProduct(product) {
    this.#items.forEach((item) => {
      if (item === product) {
        item.quantity += 1;
        item.ClassSaleStep.doubleProductQuantities();
      }
    });
  }

  removeItem(product) {
    product.ClassSaleStep.exitSteps();
    this.#items.delete(product);

    if(this.#items.size === 0) {
      this.#currentSaleId = null;
    }

  }

  emptyItems() {
    this.#items.clear();
    this.#currentSaleId = null;
    this.#newSaleId = 0;
  }

  getTotalInPointsValue() {
    let totalValuePoints = 0;

    this.getItems()
      .filter((item) => !!item.ClassSaleStep.getSteps()?.pointPrice)
      .forEach((item) => {
        const principalItem = item.ClassSaleStep.getSteps();
        totalValuePoints += item.quantity * principalItem.pointPrice;

        if (!!principalItem.steps.length) {
          principalItem.steps.forEach((step) => {
            if (step.selected !== null) {
              totalValuePoints += Array.from(step.selected).reduce(
                (accumulator, currentItem) =>
                  accumulator + currentItem.ClassSaleStep.getSteps().pointPrice,
                0
              );
            }
          });
        }
      });
    return totalValuePoints;
  }

  handleTotalValue(items) {
    let totalValue = 0;

    items
      .filter((item) => !!item.ClassSaleStep.getSteps()?.pointPrice === false)
      .forEach((item) => {
        const principalItem = item.ClassSaleStep.getSteps();
        totalValue += item.quantity * principalItem.value;

        if (!!principalItem.steps.length) {
          principalItem.steps.forEach((step) => {
            if (step.selected !== null) {
              totalValue += Array.from(step.selected).reduce(
                (amount, product) => {
                  let totalChildrenValue = product.amount * product.value;

                  if (product.hasOrientedSale) {
                    totalChildrenValue = this.handleTotalValue([
                      {
                        quantity: product.amount,
                        ClassSaleStep: product.ClassSaleStep,
                      },
                    ]);
                  }

                  return totalChildrenValue + amount;
                },
                0
              );
            } else if (step.composition) {
              step.products.forEach((composition) => {
                if (composition.operationType === "A") {
                  totalValue += composition.value * composition.amount;
                }
              });
            }
          });
        }
      });

    return totalValue;
  }
  getTotalValue() {
    return this.handleTotalValue(this.getItems());
  }

  goToStep(currentItem, sequence) {
    this.emptyCurrentStepItems();
    this.#items.forEach((item) => {
      if (
        currentItem.ClassSaleStep.getSteps().saleId ===
        item.ClassSaleStep.getSteps().saleId
      ) {
        item.ClassSaleStep.goToStep(sequence);
        this.#currentSaleId = item.id;
        this.chooseCurrentItem(item);
      } else {
        item.ClassSaleStep.removeSelectedStep();
      }
    });
  }

  /**
   *
   * @param currentItem Object
   * @return avoid
   * @example It must be called whenever the component wants the next step in the cart,
   * when the component does not know the next step in the cart
   */
  goToNextStep(currentItem) {
    const STATUS_INIT = 0;
    const STATUS_SWITCH_TO_NEXT_PRODUCT_STEP = 1;
    const STATUS_MOVED_TO_NEXT_PRODUCT_STAGE = 2;

    /**
     * current is 0, nothing found
     * after found the progressStepFound is 1
     * after next step the progressStepFound is 2, to have no more change
     * @type number
     */
    let progressStepFound = STATUS_INIT;

    const handleNextStep = (item) => {
      item.ClassSaleStep.nextStep();
      this.#currentSaleId = item.id;
      this.chooseCurrentItem(item);
    };

    currentItem.ClassSale.getItems().forEach((item) => {
      if (progressStepFound === STATUS_SWITCH_TO_NEXT_PRODUCT_STEP) {
        progressStepFound = STATUS_MOVED_TO_NEXT_PRODUCT_STAGE;
        this.emptyCurrentStepItems();
        handleNextStep(item);
        item.automaticSwitchToCurrentItem = true;
      }

      if (item.current && progressStepFound === 0) {
        if (item.ClassSaleStep.checkDoneSteps()) {
          progressStepFound = STATUS_SWITCH_TO_NEXT_PRODUCT_STEP;
          this.removeCurrentItem(item);
        } else {
          item.ClassSaleStep.nextStep();
        }
      } else if (!progressStepFound) {
        this.removeCurrentItem(item);
      }
    });

    if (
      progressStepFound === STATUS_SWITCH_TO_NEXT_PRODUCT_STEP &&
      currentItem.ClassSale.getItems().length === 1
    ) {
      handleNextStep(currentItem.ClassSale.getItems()[0]);
    }
  }

  /**
   *
   * @return avoid
   * @example remove selected step from all cart items
   */
  emptyCurrentStepItems() {
    this.getItems().forEach((item) => {
      item.ClassSaleStep.removeSelectedStep();
      this.removeCurrentItem(item);
      item.automaticSwitchToCurrentItem = false;
    });
  }

  getCurrentSaleStep() {
    const currentItem = [...this.#items].find((item) => item.current);

    if (currentItem) {
      let response = currentItem.ClassSaleStep.getCurrentStep();
      if (response === undefined) {
        currentItem.ClassSaleStep.nextStep();
        response = currentItem.ClassSaleStep.getCurrentStep();
      }

      return response;
    } else {
      return {};
    }
  }

  removeCurrentItem(item) {
    item.current = false;
  }

  chooseCurrentItem(item) {
    item.current = true;
  }

  getStepsItem(item) {
    return item.ClassSaleStep.getSteps();
  }

  readySale() {
    let ready = !!this.#items.size;

    this.#items.forEach((item) => {
      if (!item.ClassSaleStep.getSteps().doneSteps) {
        ready = false;
      }
    });

    return ready;
  }

  handleSelectFirstStepOfLastProduct() {
    this.#items.forEach((item) => {
      if (item.ClassSaleStep.getSteps().saleId === this.#currentSaleId) {
        item.ClassSaleStep.goToStep(1);
      } else {
        item.ClassSaleStep.removeSelectedStep();
      }
    });
  }

  async processItemsFinalizationSale(totalReshopValue = 0) {
    const { totalCartPrice, formattedItems, discount } =
      this.sumTotalValueItemsChosenByClient(this.#items);

    const sumTotalValueClientAndReshop = parseFloat(
      Number(totalCartPrice + totalReshopValue).toFixed(2)
    );

    return {
      totalProductValue: sumTotalValueClientAndReshop,
      totalOrderValue: sumTotalValueClientAndReshop,
      discount,
      items: formattedItems,
    };
  }

  sumTotalValueItemsChosenByClient(items = []) {
    const greaterThenZero = (...params) => {
      if (params.length === 0) return null;

      let i;

      for (i = 0; i < params.length; i++) {
        let value = params[i];

        if (value != null && !Number.isNaN(value) && value > 0) {
          return value;
        }
      }
      return params[i];
    };

    const mountObjectItem = (item, sequence, mainSequence = null, quantity) => {
      const promotion = item?.promotion ?? {};
      const discount = promotion?.discount || 0;
      const Utils = new UtilsUseCase();

      return {
        dateTimeInsert: transactionData(new Date(), "MM/dd/yyyy hh:mm:ss"),
        productCode: String(item.productCode),
        description: Utils.getDescription(
          item.allDescriptions,
          this.#selectedEnvironment,
        ),
        allDescriptions: item.allDescriptions,
        pointPrice: item?.pointPrice,
        categoryIsFidelity: !!item?.categoryIsFidelity,
        value: item.value,
        quantity: quantity === undefined ? 1 : quantity,
        discount,
        subtotal: Number(
          greaterThenZero(item.mixValue, item.subtotal, item.value)
        ).toFixed(2),
        vit_numlancto: sequence,
        vit_numlanctoprincipal: mainSequence,
        promotion,
        isCombination: false,
        reshopPontos: 0,
        reshopCampaigns: [],
        nuuidOne: item.nuuidOne,
        observation: item.observation,
      };
    };

    let totalCartPrice = 0;
    let discount = 0;
    let sequence = 0;
    const formattedItems = [];
    console.log(this.#items, "items do sale");

    items.forEach((itemCart) => {
      sequence += 1;
      const dataItemCart = itemCart.ClassSaleStep.getSteps();
      console.log(dataItemCart, "data item cart");

      let objectMounted = {};
      if (dataItemCart.isOnlyLevel === false) {
        objectMounted = mountObjectItem(
          dataItemCart,
          sequence,
          null,
          itemCart.quantity
        );
        discount += objectMounted.discount;
        formattedItems.push(objectMounted);
        totalCartPrice += itemCart.quantity * dataItemCart.value;
      }

      if (!!dataItemCart.steps.length) {
        let mainSequence = sequence;
        dataItemCart.steps.forEach((itemStep) => {
          if (itemStep.composition) {
            itemStep.products.forEach((itemComposition) => {
              if (
                (itemComposition.operationType === "A" &&
                  itemComposition.amount > 0) ||
                (itemComposition.operationType === "R" &&
                  itemComposition.amount === 0)
              ) {
                let totalValueComposition = 0;
                if (itemComposition.operationType === "A") {
                  totalValueComposition =
                    itemComposition.value * itemComposition.amount;
                }
                itemComposition.quantity =
                  itemComposition.amount === 0 ? -1 : itemComposition.amount;
                totalCartPrice += totalValueComposition;
                objectMounted.value = parseFloat(
                  (objectMounted.value + totalValueComposition).toFixed(2)
                );
                objectMounted.subtotal = (
                  parseFloat(objectMounted.subtotal) + totalValueComposition
                ).toFixed(2);
                itemComposition.value = 0;

                sequence += 1;

                formattedItems.push(
                  mountObjectItem(
                    itemComposition,
                    sequence,
                    mainSequence,
                    itemComposition.quantity
                  )
                );
              }
            });
          } else {
            if (itemStep.selected) {
              const selectedItems = itemStep.selected;
              selectedItems.forEach((item) => {
                const itemSubtotal = Number(item.value * item.amount);

                totalCartPrice += itemSubtotal;
                item.subtotal = itemSubtotal;

                let TRABEXCECAOPRODCOMBORESHOP = false; // ToDo: buscar a regra da configuração
                if (TRABEXCECAOPRODCOMBORESHOP && objectMounted) {
                  objectMounted.value = parseFloat(
                    (objectMounted.value + itemSubtotal).toFixed(2)
                  );
                  objectMounted.subtotal = (
                    parseFloat(objectMounted.subtotal) + itemSubtotal
                  ).toFixed(2);

                  item.value = 0;
                  item.subtotal = 0;
                }
                item.quantity = item.amount;
                sequence += 1;

                formattedItems.push(
                  mountObjectItem(item, sequence, mainSequence, item.quantity)
                );
                if (item?.ClassSaleStep) {
                  const childrenClassSaleStep = item.ClassSaleStep.getSteps();
                  if (childrenClassSaleStep && childrenClassSaleStep?.steps) {
                    const mainChildren = sequence;
                    childrenClassSaleStep.steps.forEach((childrenStep) => {
                      if (childrenStep?.selected.length) {
                        childrenStep.selected.forEach(
                          (childrenItemSelected) => {
                            if (childrenItemSelected) {
                              const itemSubtotalChildren = Number(
                                childrenItemSelected.value *
                                  childrenItemSelected.amount
                              );
                              totalCartPrice += itemSubtotalChildren;
                              childrenItemSelected.subtotal =
                                itemSubtotalChildren;
                              childrenItemSelected.quantity =
                                childrenItemSelected.amount;
                              sequence += 1;

                              formattedItems.push(
                                mountObjectItem(
                                  childrenItemSelected,
                                  sequence,
                                  mainChildren,
                                  childrenItemSelected.quantity
                                )
                              );
                            }
                          }
                        );
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });

    return {
      totalCartPrice,
      formattedItems,
      discount,
    };
  }

  handleItems(item) {
    let chosenQuantity = 1;
    if ("reload" !== item?.quantityToCart) {
      chosenQuantity = item?.quantityToCart ?? 1;
    }

    const Utils = new UtilsUseCase();
    let level = Utils.getLevelNameInProduct(item);

    if (item[level] === undefined) {
      level = "level";
    }

    let itemHasAlreadyBeenAddedCart = false;

    if (!item.fractionalQuantity) {
      this.#items.forEach((itemInCart) => {
        if (!!itemInCart.ClassSaleStep.getSteps().steps.length === false) {
          if (
            itemInCart.ClassSaleStep.getSteps().productCode ===
              item.productCode &&
            (item.tablePosition
              ? itemInCart.ClassSaleStep.getSteps().tablePosition ===
                item.tablePosition
              : true)
          ) {
            itemHasAlreadyBeenAddedCart = true;
            itemInCart.quantity += chosenQuantity;
          }
        }
      });
    }

    if (!itemHasAlreadyBeenAddedCart) {
      const SaleStep = new SaleStepUseCase(
        item,
        chosenQuantity,
        this.#selectedEnvironment
      );

      if (!item[level] || item[level]?.length === 0) {
        this.#newSaleId +=1;
        this.#currentSaleId = this.#newSaleId;
        this.emptyCurrentStepItems();

        this.#items.add({
          id: this.#newSaleId,
          ClassSale: this,
          ClassSaleStep: SaleStep,
          quantity: chosenQuantity,
          current: true,
          automaticSwitchToCurrentItem: false,
        });
      }
    }

    this.handleSelectFirstStepOfLastProduct();
  }

  getEnvironmentSelected() {
    return this.#selectedEnvironment;
  }
}
