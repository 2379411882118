import React, { useState, useEffect, useCallback } from "react";
import { authentication } from "../../../services/authServices";
import { getUsers } from "../../../services/usersServices";
import { Content, Button } from "../../shared/components/modules";
import { frontConfig } from "../../../services/frontConfigService";
import { configsPdv } from "../../../services/pdvConfigs";
import { useVersionAa } from "../../contexts/versionContext";
import Modal from "../../shared/components/modal";
import {
  isDesktopApp,
  isKiosk,
  getRetailerId,
  getTerminalID,
} from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { useViewDispatch } from "../../../context";
import {
  cashdeskOpenService,
  getLastCashdeskService,
} from "../../../services/cashdeskService";
import { useCashdesk } from "../../../pdv-one/hook/cashdeskHook";
import { LoadingStartApp } from "../../../linx/loading";
import { getCurrentSystemVersionService } from "../../../services/getCurrentSystemVersion";
import { CASHDESK_OPEN_ID, DATA_USER_AUTH_KEY_NAME } from "../../../pdv-one/constants/keyNamesSessionStorage";
import { activatePDVService } from "../../../services/linxPayServices";

const LoginContainer = () => {
  const viewDispatch = useViewDispatch();
  const versionAa = useVersionAa();
  const { isCashdeskOnCurrentDay } = useCashdesk();
  const { setVisibleAccessibility } = useLayoutAa();
  const [cashdesk, setCashdesk] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  let [password, setPassword] = useState("");
  const [userCode, setUserCode] = useState("");

  const [warn, setWarn] = useState("none");
  const [warnPassword, setWarnPassword] = useState("none");
  const [warnServer, setWarnServer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disableButtonLogin, setDisableButtonLogin] = useState(false);
  const [isOpenModalWarn, setIsOpenModalWarn] = useState(false);

  const numericKeypad = [1, 2, 3, 4, 5, 6, 7, 8, 9, "limpar", 0, "←"];
  const retailerId = getRetailerId();
  const terminalId = getTerminalID();
  const userInfoCashDesk = users.find((u) => u.login == user);
  const handleUser = (event) => {
    let LoginValue = event.target.value;
    let EmployValue = LoginValue.split("-");

    setUser(EmployValue[0]);
    setUserCode(EmployValue[1]);
    localStorage.setItem("UserCode", EmployValue[1]);
  };

  const handlePassword = useCallback(
    (key) => {
      if (key.length > 1) {
        return setPassword("");
      }

      if (key === "←") {
        return setPassword(password.slice(0, -1));
      }

      setPassword((pass) => `${pass}${key}`);
      setWarn("none");
      setWarnPassword("none");
    },
    [password]
  );
  const cashdeskOpen = useCallback(async () => {
    try {
      const loggedUser =
        JSON.parse(
          window.sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME)
        ) || {};

      const data = await cashdeskOpenService({
        posId: String(terminalId),
        retailerId: retailerId,
        userId: loggedUser.nuuidOne,
        user: loggedUser.login,
        userReferenceId: loggedUser.employeeCode,
        responsableId: loggedUser.nuuidOne,
        responsable: loggedUser.login,
        responsableReferenceId: loggedUser.employeeCode,
        openingAmount: 0,
        paymentType: {},
        isAAOne: true,
      });

      setCashdesk(data.cashDesk);
      sessionStorage.setItem(CASHDESK_OPEN_ID, data.cashDesk.cashDeskOpenDate.id);
      return data.cashDesk;
    } catch (err) {
      console.error(err);

      throw err;
    }
  }, [cashdesk, isCashdeskOnCurrentDay, userInfoCashDesk]);

  const getLastCashdesk = useCallback(async () => {
    try {
      const data = await getLastCashdeskService();
      setCashdesk(data);
      sessionStorage.setItem(CASHDESK_OPEN_ID, data.cashDeskOpenDate.id);

      const isCashdeskOpen = !data || data?.closingDate || data?.reducingDate;
      if (isCashdeskOpen) {
        cashdeskOpen({});
      }
    } catch (err) {
      console.error(err);
    }
  }, [cashdeskOpen]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setDisableButtonLogin(true)
        if (password === "") {
          setWarn("block");
          return;
        }
        const currentVersion = await getCurrentSystemVersionService();

        let userInfo = users.find((u) => u.login == user);
        window.sessionStorage.setItem(
          DATA_USER_AUTH_KEY_NAME,
          JSON.stringify(userInfo)
        );

        const res = await authentication(
          user,
          password,
          isDesktopApp ? "AAOne" : "AATablet",
          currentVersion?.shortcutIcon?.version || ''
        );
        if (res.token) {
          const isDayOpen =
            typeof res?.isDayOpen === "boolean" ? res.isDayOpen : true;
          window.sessionStorage.setItem(
            DATA_USER_AUTH_KEY_NAME,
            JSON.stringify(res.user)
          );
          getLastCashdesk();
          if (!isDayOpen && !isKiosk) {
            console.log(
              "%c Esse banco não está com o dia aberto! ",
              "background: #000; color: #fff; padding: 5px;"
            );
          }

          if (isDayOpen || !isKiosk) {
            setVisibleAccessibility();
            configsPdv();
            activatePDVService();
            getCurrentSystemVersionService();
            return viewDispatch("step.banner");
          }

          handleOpenWarnModal();
        }
      } catch (error) {
        setWarnPassword("block");
      } finally {
        setDisableButtonLogin(false);
      }
    },
    [password, user, userCode, viewDispatch, getLastCashdesk]
  );
  useEffect(() => {
    getUsers()
      .then((data) => {
        setUser(data.users[0].login);
        localStorage.setItem("UserCode", data.users[0].employeeCode);
        setUsers(data.users);
      })
      .catch(() => setWarnServer(true));

    frontConfig();
    if (warnServer === true && localStorage.getItem("storageContent") !== "1") {
      setTimeout(() => {
        document.location.reload(true);
      }, 5000);
    }
  }, [warnServer]);

  const userLogin = users.map((item) => (
    <option key={item._id} value={`${item.login}-${item.employeeCode}`}>
      {item.login}
    </option>
  ));

  const keys = numericKeypad.map((key) => {
    return (
      <button
        type="button"
        className="key"
        key={key}
        value={key}
        onClick={() => handlePassword(key)}
      >
        {key === "<" ? "🡐" : key}
      </button>
    );
  });

  const serverError = () => {
    window.localStorage.removeItem("AA_ConfigDevice");
    return (
      <div className="serverError">
        <h1>IMPOSSÍVEL COMUNICAR COM O SERVIDOR</h1>
        <h2>FAVOR VERIFICAR A CONEXÃO DE REDE E O STATUS DO SERVIDOR</h2>
        <button
          className="btn-serverError"
          onClick={() => document.location.reload(true)}
        >
          Recarregar AutoAtendimento
        </button>
      </div>
    );
  };

  const handleCloseWarnModal = () => {
    setIsOpenModalWarn(false);
  };

  const handleOpenWarnModal = () => {
    setIsOpenModalWarn(true);
  };

  useEffect(() => {
    window.addEventListener("storage", () => {
      try {
        const config = JSON.parse(window.sessionStorage.getItem("AA_Config"));
        setWarnServer(!config?.ConfigFilePath);
      } catch (error) {
        setWarnServer(true);
      }
    });
  }, [warnServer]);

  useEffect(() => {
    if (warnServer || (isLoading && !warnServer)) {
      versionAa.hide();
    }
  }, [warnServer, versionAa, isLoading]);

  const SEVEN_MILLISECONDS_TO_SEVEN_SECONDS = 7 * 1000;

  if (warnServer) {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        window.location.reload();
      }, SEVEN_MILLISECONDS_TO_SEVEN_SECONDS);
    }
  }

  if (isLoading) {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  if (warnServer || (isLoading && !warnServer)) {
    return <LoadingStartApp />;
  }

  return (
    <>
      {isOpenModalWarn && (
        <div className="blackout" onClick={handleCloseWarnModal}>
          <Modal
            className="btnnewragazzo"
            mTitle="Atenção!"
            mSubtitle="O dia não foi aberto, favor realizar o procedimento em um terminal PDV"
            mTextBtnPrimary="Ok"
            mTextBtnSecondary={false}
            mHandleClickBtnPrimary={handleCloseWarnModal}
          />
        </div>
      )}
      <Content
        title="Login"
        subtitle="Informe os dados abaixo para realizar o login"
        className="full fullbobs"
        bgColor="background-primary"
        contentChildren={true}
      >
        <div className="window-options">
          {!isDesktopApp && (
            <div
              className="logo"
              onClick={(e) => {
                window.localStorage.removeItem("AA_ConfigDevice");
                document.location.reload();
              }}
            >
              <img src="/gear-solid.svg" alt="linx" />
            </div>
          )}
          <div className="logo" onClick={versionAa.show}>
            <img src="/linx-icon.png" alt="linx" />
          </div>
          <div
            className="close"
            role="button"
            onClick={(e) => {
              e.preventDefault();
              window.close();
            }}
          >
            X
          </div>
        </div>
        {warnServer && serverError()}

        <form className="login" onSubmit={handleSubmit}>
          <label>Usuário</label>
          <select name="user" onChange={(e) => handleUser(e)} multiple={false}>
            {userLogin}
          </select>

          <label>Senha</label>
          <input
            name="password"
            type="password"
            placeholder="Senha"
            value={password}
            readOnly
          />
          <div className="warn-container">
            <span className="warn" style={{ display: warn }}>
              Senha não pode ficar em branco
            </span>
            <span className="warn" style={{ display: warnPassword }}>
              Senha incorreta ou problema no servidor
            </span>
          </div>

          <section className="keyboard-login">{keys}</section>

          <Button text="Entrar" buttonUIType="primary" buttonUI="highlight" disabled={disableButtonLogin} />
        </form>
      </Content>
    </>
  );
};

export default React.memo(LoginContainer);
