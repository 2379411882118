import React, { createContext, useCallback, useEffect, useState } from "react";

export const VerticalLayoutContext = createContext({});

export const VerticalLayoutProvider = ({ children }) => {
  const [isVerticalScreen, setIsVerticalScreen] = useState(
    window.innerHeight > window.innerWidth ||
      window.screen.availHeight > window.screen.availWidth
  );

  const onEventResize = useCallback(() => {
    setIsVerticalScreen(
      window.innerHeight > window.innerWidth ||
        window.screen.availHeight > window.screen.availWidth
    );
  }, []);

  useEffect(() => {
    onEventResize();

    window.addEventListener("resize", onEventResize);

    return () => {
      window.removeEventListener("resize", onEventResize);
    };
  }, [onEventResize]);

  return (
    <VerticalLayoutContext.Provider value={{ isVerticalScreen }}>
      {children}
    </VerticalLayoutContext.Provider>
  );
};
