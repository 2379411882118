import React from "react";
import { MessageDetail, ArrowTop, ArrowBottom, ModalContent } from "./style";
// import { Modal } from '../modal2';
import { Modal } from "./style";
// import parse from 'html-react-parser';
import Button from "../../Button";

export class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalMessage = React.createRef();
    this.state = { message: "" };
    this.field = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  closable = () => !!this.props.message.type;

  componentDidMount() {
    console.log(this.props);

    // if (this.props.visible) {
    //   this.modalMessage.current.open();
    //   document
    //     .querySelector(".overlay")
    //     .addEventListener("keydown", this.handleKeyDown);
    //   this.modalMessage.current.focus();
    // }
  }

  componentDidUpdate() {
    this.modalMessage.current.focus();
  }

  componentWillUnmount() {
    document
      .querySelector(".overlay")
      .removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (this.closable() && (e.key === "Escape" || e.key === "Enter")) {
      this.modalMessage.current.close();
    }
    e.preventDefault();
  }

  getIcon(type) {
    type = typeof type !== undefined ? type : 0;
    switch (type) {
      case 0: //Processing
      default:
        return "cached";
      case 1: //Warning
        return "warning";
      case 2: //ok
        return "done";
      case 3: //error
        return "error";
      case 99:
        return "help";
      case 7:
        return "";
    }
  }

  scrollTo(top) {
    const container = document.querySelector("#moreinfo");
    const scroll = top ? container.scrollTop - 30 : container.scrollTop + 30;

    container.scrollTo(0, scroll);
  }

  render() {
    // const {test} = this.props

    return (
      <Modal
        className="modal-monitor-notas"
        title={""}
        closable={false}
        ref={this.modalMessage}
        opened
        style={{ overflow: "hidden" }}
        handleClose={this.props.handleClose}
        closeOnOverlayClick={this.closable()}
      >
        <ModalContent>
          <MessageDetail>
            {this.props.message.type !== 7 && (
              <i
                className="material-icons"
                icon={this.getIcon(this.props.message.type)}
              >
                {this.getIcon(this.props.message.type)}
              </i>
            )}

            <h1 className="text message-title">{this.props.message.message}</h1>
            <h2 className="text submessage-title">
              {this.props.message.subMessage}
            </h2>

            {this.props.message.moreInfo &&
            this.props.message.moreInfo.length > 0 ? (
              <div className="moreinfo" id="moreinfo">
                <ArrowTop onClick={() => this.scrollTo(true)}>
                  <span className="material-icons">keyboard_arrow_up</span>
                </ArrowTop>

                {this.props.message.moreInfo}
                {/* {parse(this.props.message.moreInfo)} */}

                <ArrowBottom onClick={() => this.scrollTo(false)}>
                  <span className="material-icons">keyboard_arrow_down</span>
                </ArrowBottom>
              </div>
            ) : null}

            {this.closable() && (
              <div className="close">
                <Button className="btn-purple" onClick={this.props.handleClose}>
                  Fechar
                </Button>
              </div>
            )}
          </MessageDetail>
        </ModalContent>
      </Modal>
    );
  }
}
export default InfoModal;
