import styled from 'styled-components';

export const DivWallets = styled.div`
	display: flex;
	width: 95%;
	justify-content: center;
	margin: 0 auto;
	margin-top: 5px;
	align-items: center;
	flex-wrap: wrap
`
export const TefMessagesWrapper = styled.div`

	.list-itens {
		height: calc(100vh - 50%); // Viewport height mínimo
		width: 100%;
		display: grid;
		align-items: center;
		text-align: center;
		margin-bottom: 20px;
	}

	.list-itens p {
		margin-bottom: 20px;
	}

  .qrcode-wrapper {
    border: 40px;
    margin: auto;
		margin-top: 360px;
		height: 400px !important;
    width: 400px !important;
		position: relative;
    z-index: 1;
  }

	.qrcode {
    background: #fff;
    border: 20px solid #fff;
	}
`;
