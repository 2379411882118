import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginModal from "../../components/loginModal";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { NeedAuthWrapper } from "./style";
import { PagesEnum } from "../../constants/pagesEnum";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useCart } from "../../hook/cartHook";
import { undoTEFPayments } from "../../helpers/paymentHelper";
import { useLoading } from "../../hook/loadingHook";
import { useSallerTable } from "../../hook/sallerTableHook";

const NeedAuth = () => {
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { clearCart, removeProductFromCart, Sale } = useCart();
  const { updatePositionStatusAndHighlightRows, handleUpdateTableStatus, setIsTableOpen } = useSallerTable();
  const { setShowLoading } = useLoading();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setVisibleSidebarCart(false);

    return () => {
      setVisibleSidebarCart(true);
    };
  }, []);

  const handleCancel = () => {
    navigate(
      location?.state?.redirectBackOnCancel
        ? location.state.redirectUrl
        : PagesEnum.HOME
    );
  };

  const handleSubmit = (authData) => {
    if (authData) {
      if (location.state.action === "CANCEL_OPERATION") {
        setShowLoading(true, "Aguarde, verificando e desfazendo pagamento(s).");

        // Desfaz os pagamentos em TEF
        undoTEFPayments(
          () => {
            setShowLoading(false);
          },
          () => {
            setShowLoading(false);
          }
        );

        clearCart(authData.user);
      } else if (location.state.action === "removeItem") {
        const item = Sale.getItems();
        removeProductFromCart(
          item[location.state.ItemRemoveSelect],
          location.state.isFidelity,
          authData.user
        );
      } else if(location.state.action === "reopenPosition") {
        updatePositionStatusAndHighlightRows(
          location.state.newStatus,
          location.state.updatedNumberOfCustomers,
          location.state.positions
        );
      } else if(location.state.action === "reopenTable") {
        handleUpdateTableStatus(location.state.newStatus);
        setIsTableOpen(true);
      }

      navigate(location.state.redirectUrl, {
        state: { auth: authData, props: location?.state },
      });
    }
  };

  return (
    <KeyboardProvider>
      <NeedAuthWrapper>
        <LoginModal handleCancel={handleCancel} handleSubmit={handleSubmit} />
      </NeedAuthWrapper>
    </KeyboardProvider>
  );
};

export default NeedAuth;
