import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px;
  margin: -10px -20px 0;

  border-bottom: 1px solid #aaa;
  background: #ddd;

  span {
    font-size: 1.5rem;
  }

  button {
    padding: 10px 16px;
  }

  &.isSmartPos {
    display: flex;
    flex-direction: column;

    margin: -20px -20px 0;

    span {
      font-size: 18px !important;
      margin-bottom: 10px;
    }

    button {
      font-size: 12px !important;
      height: 30px;
      width: 100px;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;
  overflow: auto;
  padding-top: 20px;
  margin-right: 18.625rem;

  &.isSmartPos {
    margin-right: 0px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
  }

  .row {
    display: flex;
    margin-bottom: 15px;

    .detail {
      flex: 1 0 auto;
      overflow: hidden;
      word-break: break-word;
      padding-right: 10px;

      p {
        font-weight: bold;
        font-size: 0.9rem;
      }

      span {
        overflow: hidden;
        margin-top: 4px;
        font-size: 0.9rem;
      }
    }
  }

  .subtotal,
  .discount,
  .total,
  .payment,
  .payment-.total,
  .payment-forms,
  .payment-details {
    display: flex;
  }

  .note-details {
    margin-bottom: 15px;
  }

  .img-note {
    top: 7.25rem;
    position: absolute;
    right: 3.775rem;
    height: 80%;
    border-radius: 4px;
    box-shadow: -1px -1px 10px #b3b3b3, -1px 5px 15px #b3b3b3;
    -webkit-box-shadow: -1px -1px 10px #b3b3b3, -1px 5px 15px #b3b3b3;
    -moz-box-shadow: -1px -1px 10px #b3b3b3, -1px 5px 15px #b3b3b3;
  }

  .payment-details {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1.5rem;

    .label {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .value {
      width: 100px;
      display: flex;
      margin-left: 30px;
    }

    .payment-total {
      margin-top: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid;

      .subtotal {
      }

      .discount {
      }

      .total {
        font-weight: bold;
      }
    }

    .payment-forms {
      margin-top: 15px;
      padding-bottom: 40px;
      flex-direction: column;

      .payment:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
`;
