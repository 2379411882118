const verificaCPFInvalidos = ( cpf ) => {
    const cpfsInvalidos  = [
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
        '00000000000'
    ]

    return cpfsInvalidos.indexOf(cpf) > -1;
}

const verificaPrimeiroDigito = (cpf) => {
    const peso = 11;
    const totalDeDigitosPrimeiraParte = 9;
    const digitoDeVerificacao = parseInt(cpf.substring(9, 10));
    
    return verificaDigito(
      cpf,
      totalDeDigitosPrimeiraParte,
      peso,
      digitoDeVerificacao
    );
}

const verificaSegundoDigito = (cpf) => {
    const peso = 12;
    const totalDeDigitosSegundaParte = 10;
    const digitoDeVerificacao = parseInt(cpf.substring(10, 11));

    return verificaDigito(
        cpf,
        totalDeDigitosSegundaParte,
        peso,
        digitoDeVerificacao
    );
}
  
const verificaDigito = (cpf, totalDeDigitos, peso, digitoDeVerificacao) => {
    const soma = somaNumerosCPF(cpf, totalDeDigitos, peso);
    const resto = (soma * 10) % 11;

    return resto === digitoDeVerificacao;
}
  
const somaNumerosCPF = (cpf, totalDeDigitos, peso) => {
    let soma = 0;
    
    for (let indice = 1; indice <= totalDeDigitos; indice++) {
        soma += parseInt(cpf.substring(indice - 1, indice)) * (peso - indice);
    }
    
    return soma;
}

// const validaCPF = (cpf) => {
//     return (
//         verificaPrimeiroDigito(cpf) &&
//         verificaSegundoDigito(cpf) &&
//         verificaCPFInvalidos(cpf)
//     );
// }

const validaCPF = (strCPF) => {
    let soma;
    let resto;
    
    soma = 0;
    
    if (verificaCPFInvalidos(strCPF)) return false;

    for (let i=1; i<=9; i++) 
        soma = soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11))  
        resto = 0;

    if (resto !== parseInt(strCPF.substring(9, 10))) 
        return false;

    soma = 0;

    for (let i = 1; i <= 10; i++) 
        soma = soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11))  
        resto = 0;

    if (resto !== parseInt(strCPF.substring(10, 11))) 
        return false;
  
    return true;
};


export { validaCPF };