import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";
import { isSmartPos } from "../../../aaone/configuration";

export const CardWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme?.panel?.padding};
  margin: ${(props) => props.theme?.panelSize?.centralized};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme?.palette?.grayscale?.white};
  min-height: 55px;
  overflow: hidden;
  margin: 5px;

  @media only screen and (${(props) => props.theme?.minimumResolution?.media}) {
    margin: 5px;
    padding: 7px ${(props) => props.theme?.panel?.padding};
  }

  &.loading::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }

  &.body-transparent {
    align-items: center;
    padding-top: 0;
    margin-top: 0;
    box-shadow: none;
    background-color: transparent;

    header {
      text-transform: uppercase;
      width: 100%;
      margin-bottom: 35px;
      margin-top: 20px;
    }

    #btn-close {
      color: #010101;
      background-color: transparent;
    }
  }

  .btn-arrow {
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    z-index: 9;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    right: 10px;
    //top: 70px;
    transform: translateY(-15%);

    @media only screen and (${(props) =>
        props.theme?.minimumResolution?.media}) {
      top: 50px;
    }
  }

  .btn-pagination {
    position: absolute;
    right: 12px;
    border-radius: 50%;
    outline: none;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 2em;
    color: #fff;
    display: flex;
    align-items: center;
    z-index: 99;
    width: 40px;
    height: 40px;
    .material-icons {
      margin: 0 auto;
    }
    @media only screen and (${(props) =>
        props.theme?.minimumResolution?.media}) {
      right: 3px;
    }

    &--top {
      top: 80px;

      @media only screen and (${(props) =>
          props.theme?.minimumResolution?.media}) {
        top: 60px;
      }
    }

    &--bottom {
      bottom: 30px;

      @media only screen and (${(props) =>
          props.theme?.minimumResolution?.media}) {
        bottom: 15px;
      }
    }
  }

  .card-body,
  .table {
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0;
    }
  }

  &.collapsed-card {
    min-height: 50px;
    display: flex;
    overflow: hidden;
    position: relative;

    .btn-arrow {
      transform: translateY(-50%);
      z-index: 10;
      display: flex;
      justify-content: flex-end;
    }
  }

  &.expand-card {
    min-height: 50px;
    display: flex;
    justify-content: center;
    height: 0;
    transition: min-height 0.5s cubic-bezier(0, 1, 0.5, 1);
    overflow: hidden;
    position: relative;

    .btn-arrow {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      justify-content: flex-end;
    }

    .amount-list {
      top: 50%;
      transform: translateY(-50%);
    }

    #btn-close {
      top: 50% !important;
      transform: translateY(-50%);
    }
  }

  .amount-list {
    position: absolute;
    right: 45px;
    color: #babac0;
    top: 100px;
    transform: translateY(-15%);
    @media only screen and (${(props) =>
        props.theme?.minimumResolution?.media}) {
      top: 90px;
    }
  }

  .group-button {
    display: inline-flex;
    justify-content: flex-end;
    margin-right: 55px;

    .btn-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.5rem;
      background: var(--highlight-color);
      max-width: 39px;
      color: #fff;
      padding: 5px;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  .grid-list-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .card-list {
      font-size: 0.83rem;
      text-align: center;
      line-height: 1.6rem;

      #card {
        padding: 20px 15px;
      }

      h1 {
        font-size: 1.1rem;
        margin-bottom: 5px;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      section {
        display: flex;
        justify-content: center;

        .btn-card-list {
          display: inline-flex;
          align-items: center;
          flex-direction: row;
          max-width: inherit;
          font-size: 0.9rem;
          outline: none;
          border: none;
          margin-top: 10px;
          padding: 7px 15px;
          background: var(--highlight-color);
          color: #fff;
          margin-right: 3px;
          border-radius: 4px;

          @media only screen and (${(props) =>
              props.theme?.minimumResolution?.media}) {
            flex-direction: column;
            font-size: smaller;
          }

          &[disabled] {
            opacity: 0.35;
          }
        }
      }

      input:checked + .slider:before {
        transform: translateX(11px);
      }
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    margin: 0 0 1rem;
  `)}
`;

export const CardTitle = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 50px;
  align-items: center;
  position: relative;

  .title {
    color: #414141;
    font-family: Dosis, sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    opacity: 0.8;

    > div {
      display: flex;
      flex-direction: column;
    }

    @media only screen and (${(props) =>
        props.theme?.minimumResolution?.media}) {
      font-size: 1.1rem;
    }
  }

  #btn-close {
    font-size: 14px;
    /* position: absolute; */
    right: 30px;
  }

  .sub-title {
    font-size: 18px;
    text-transform: initial;
    color: #111;
    margin-left: 1px;
  }

  img {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  button {
    text-decoration: none;
    color: #bdbdbd;
    background-color: white;
    border: 0;
    outline: none;
    font-size: 10px;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme?.palette?.primary[0]};
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  &.close__container__card .title > div {
    word-break: break-all;
  }
`;

export const CardMain = styled.div`
  &.full-height {
    width: 100%;
    height: 99%;

    ${CardWrapper} {
      height: inherit;
    }
  }

  + .control-card-scroll > section {
    position: relative;
    min-height: 55px;
    max-height: 82vh;

    @media only screen and (${(props) =>
        props.theme?.minimumResolution?.media}) {
      max-height: 75vh;
    }

    > .card-body {
      padding-right: 45px;

      @media only screen and (${(props) =>
          props.theme?.minimumResolution?.media}) {
        padding-right: 30px;
      }
    }
  }
`;

export const CardContent = styled.div`
  margin-top: 0px;
  overflow: auto;
  background: none;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
