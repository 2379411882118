import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import classNames from "classnames";

import { useCart } from "../../hook/cartHook";

import { ItemProduct } from "../Products/Item";
import { ContentBackButton, BackButton, Content } from "./styles";
import { NextItemSlider, PreviousItemSlider } from "../ArrowsCarousel";
import { useCategoriesNavigate } from "../../hook/categoriesHook";
import { useVertical } from "../../hook/verticalHook";
import { Container } from "../Products/styles";
import { useAuth } from "../../hook/authHook";
import { isSmartPos } from "../../../aaone/configuration";
import { Slider } from "../Slider";
import { format } from "date-fns";
import { useDialog } from "../../hook/dialogHook";
import { verifyRestriction } from "../../helpers/productsHelper";

let currentProducts = [];
export const ProductsInCart = () => {
  const sliderRef = useRef();

  const [showButtonBack, setShowButtonBack] = useState(false);
  const {
    currentProductStep,
    action,
    quantityToCart,
    setCurrentProductStep,
    setSaleStartHour,
  } = useCart();
  const { userAuthData, reshop } = useAuth();

  const [showArrowBackButton, setShowArrowBackButton] = useState(false);

  const { isVerticalScreen } = useVertical();
  const { currentCategoryId } = useCategoriesNavigate();
  const { showAlert, setOpenLoginModal } = useDialog();

  const handleBackProduct = useCallback(() => {
    const exampleProductList = currentProductStep[0];

    exampleProductList.orientedSaleClass.back();
    const currentProducts =
      exampleProductList.orientedSaleClass.getCurrentProducts();
    const currentSequenceStep =
      exampleProductList.orientedSaleClass.getSequenceStep();
    exampleProductList.orientedSaleStepClass.goTo(currentSequenceStep);

    setCurrentProductStep(currentProducts);
  }, [currentProductStep]);

  const totalProducts = currentProductStep.length;

  const responsiveSerialize = (options) => {
    const { breakpoint, quantity, rows = 2 } = options;

    return {
      breakpoint,
      settings: {
        rows: isSmartPos
          ? totalProducts <= 2
            ? 1
            : rows
          : totalProducts <= 4
          ? 1
          : rows,
        slidesToShow: quantity,
        slidesToScroll: quantity,
      },
    };
  };

  const settingsCarousel = useMemo(() => {
    setShowArrowBackButton(false);
    return {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      rows: totalProducts <= 5 ? 2 : 2,
      slidesToShow: 4,
      slidesToScroll: 4,
      slidesPerRow: 1,
      prevArrow: <PreviousItemSlider disabled={currentCategoryId === null} />,
      nextArrow: <NextItemSlider disabled={currentCategoryId === null} />,
      responsive: [
        responsiveSerialize({
          breakpoint: 1920,
          quantity: 5,
          rows: isVerticalScreen ? 3 : 2,
        }),
        responsiveSerialize({ breakpoint: 1366, quantity: 4 }),
        responsiveSerialize({ breakpoint: 1080, quantity: 2 }),
        responsiveSerialize({ breakpoint: 480, quantity: 1 }),
        responsiveSerialize({ breakpoint: 360, quantity: 2, rows: 2 }),
      ],
    };
  }, [currentCategoryId, totalProducts]);

  useEffect(() => {
    const beforeProducts =
      currentProductStep[0].orientedSaleClass?.getBeforeProduct();

    setShowButtonBack(!!beforeProducts?.size);
  }, [currentProductStep]);

  useEffect(() => {
    if (currentProductStep !== currentProducts && sliderRef?.current) {
      currentProducts = currentProductStep;
      sliderRef.current.slickGoTo(0);
    }
  }, [reshop, currentProductStep, sliderRef?.current]);

  return (
    <Content hasArrowButton={showArrowBackButton} isLevel={showButtonBack}>
      <ContentBackButton
        hasArrowButton={showArrowBackButton}
        className={classNames({ isSmartPos })}
      >
        <BackButton
          showButtonBack={showButtonBack}
          onClick={() => handleBackProduct()}
        >
          <i className="material-icons">arrow_back</i>
          <span className="text">VOLTAR</span>
        </BackButton>
      </ContentBackButton>

      <div className="container-slide">
        <Container className="main__products">
          <div className="carousel__products">
            <Slider {...settingsCarousel} ref={sliderRef}>
              {currentProductStep.map((product, index) => {
                const data = {
                  ...product,
                  product: {
                    ...{ ...(product?.product ?? {}) },
                    image: product.catalogImage,
                    fractionalQuantity: product.fractionalQuantity,
                  },
                };
                if (product.composition) {
                  return (
                    <ItemProduct
                      data={data}
                      thumbnailContent={
                        <div className="buttons_wrapper">
                          <div className="total">{product.amount}</div>
                          <div className="buttons__container">
                            <button
                              style={{ padding: "0" }}
                              onClick={() => {
                                if (action?.nextProduct) {
                                  action.nextProduct(
                                    product.orientedSaleClass,
                                    product
                                  );
                                }

                                window.preventItemProductDoubleClick = false;
                              }}
                            >
                              +
                            </button>{" "}
                            |{" "}
                            <button
                              onClick={() => {
                                if (action?.deselectProduct) {
                                  action.deselectProduct(
                                    product.orientedSaleClass,
                                    product
                                  );
                                }
                              }}
                              style={{ padding: "0" }}
                            >
                              -
                            </button>
                          </div>
                        </div>
                      }
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                    >
                      {product.allDescriptions
                        ? product.allDescriptions.descriptionPDV
                        : product.description}
                    </ItemProduct>
                  );
                } else {
                  const chosenProduct = [
                    product.orientedSaleClass?.getSelectedProduct().productCode,
                    product.orientedSaleClass?.getSelectedProduct()
                      .mainProductCode,
                  ].includes(product.productCode)
                    ? "✔️"
                    : "";
                  return (
                    <ItemProduct
                      onClick={() => {
                        let startHour = null;

                        setSaleStartHour((val) => {
                          startHour = val;
                          return val;
                        });

                        if (!startHour) {
                          startHour = new Date();
                          setSaleStartHour(startHour);
                        }

                        if (data?.restriction) {
                          const weekDay = format(
                            new Date(),
                            "eeee"
                          ).toLowerCase();

                          let hasRestriction = product?.restriction
                            ? !product?.restriction[weekDay]
                                .reduce((acc, curr) => {
                                  const restriction = verifyRestriction({
                                    restriction: curr,
                                    startHour,
                                  });
                                  acc.push(restriction);

                                  return acc;
                                }, [])
                                .includes(false)
                            : false;

                          if (hasRestriction) {
                            showAlert({
                              handleConfirm: () => {
                                if (userAuthData?.isManager) {
                                  action.nextProduct(product, quantityToCart);
                                } else {
                                  setOpenLoginModal({
                                    handleSubmit: () => {
                                      action.nextProduct(
                                        product,
                                        quantityToCart
                                      );
                                    },
                                  });
                                }
                              },
                              handleCancel: () => {},
                              message: (
                                <>
                                  Este produto possui restrição de horário.
                                  <br /> Deseja prosseguir com o lançamento?"
                                </>
                              ),
                              onConfirmText: "Sim",
                              cancelButtonText: "Não",
                            });

                            return;
                          }
                        }

                        action.nextProduct(product, quantityToCart);
                      }}
                      data={data}
                      key={String(index)}
                    >
                      <span>
                        {product.allDescriptions
                          ? product.allDescriptions.descriptionPDV
                          : product.description}
                      </span>
                      {chosenProduct}
                    </ItemProduct>
                  );
                }
              })}
            </Slider>
          </div>
        </Container>
      </div>
    </Content>
  );
};
