import styled, { css } from "styled-components";
import { isSmartPos } from "../../../aaone/configuration";

export const Container = styled.section`
  overflow-x: hidden;
  overflow-y: auto;

  margin-top: 1rem;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 1rem;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(46, 0, 55, 0.4);
    border-radius: 20px;
    border: 0;
  }
`;
export const BackButton = styled.button`
  width: 60px !important;
  padding: 8px !important;
  border-radius: 7px !important;
  background: none;
  color: rgb(107, 107, 107);
  font-weight: bold;
  font-size: 0.7rem !important;
  margin-top: 16px;
  height: 64px;
  z-index: 1;
  font-weight: bold;

  ${(props) =>
    !props.showButtonBack &&
    css`
      display: none;
    `}
`;

export const ContentBackButton = styled.div`
  ${(props) =>
    props.hasArrowButton &&
    css`
      position: absolute;
      z-index: 1;
    `}

  ${(props) =>
    !props.hasArrowButton &&
    css`
      margin-right: 5px;
    `}

  &.isSmartPos {
    position: absolute;

    .text {
      display: none;
    }
  }
`;

export const Content = styled.div`
  ${(props) =>
    !props.hasArrowButton &&
    css`
      max-height: 100%;
      display: flex;
    `}

  .container-slide {
    width: 100%;
    margin-top: ${isSmartPos ? "15px" : null};
  }

  .slick-track {
    display: flex;
    justify-content: center;
  }
`;
