import React from "react";
import { BoxWrapper, ButtonContainer, InfoSuppleData, TextData } from "./style";
import Button from "../../Button";
import { currencyMask } from "../../../helpers/masks";
import { formatDateHelper } from "../../../helpers/formatDateHelper";

export const InfoArmouredCard = ({ items, handlePrinter, handleCancel }) => {
  return (
    <>
      {items.map((item) => (
        <BoxWrapper>
          <InfoSuppleData>
            <TextData>
              <label>Data/Hora:</label>
              <label>
                <strong>
                  {formatDateHelper(
                    new Date(item?.creationDate),
                    "dd/MM/yyyy HH:mm"
                  )}
                </strong>
              </label>
            </TextData>
            <TextData>
              <label>Observação:</label>
              <label>
                <strong>{item?.description}</strong>
              </label>
            </TextData>
            <TextData>
              <label>Identificação do Carro Forte:</label>
              <label>
                <strong>{item.numberEnvelop}</strong>
              </label>
            </TextData>
            <TextData>
              <label>Cancelado:</label>
              <label>
                <strong>{item.armoredCarCanceled ? " Sim" : " Não"}</strong>
              </label>
            </TextData>
            <TextData>
              <label>Controle:</label>
              <label>
                <strong>{item?.controlNumber}</strong>
              </label>
            </TextData>
          </InfoSuppleData>
          <ButtonContainer>
            <Button className="btn-cancel" onClick={() => handleCancel(item)}>
              <div>
                <i className="material-icons">cancel</i>
                CANCELAR
              </div>
            </Button>
            <Button className="btn-print" onClick={() => handlePrinter(item)}>
              <div>
                <i className="material-icons">print</i>
                IMPRIMIR
              </div>
            </Button>
          </ButtonContainer>
        </BoxWrapper>
      ))}
    </>
  );
};
