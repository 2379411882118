import React from "react";
import {
  ContentInfoNote,
  ContentKeyAccess,
  ItemsContentNote,
  ResumeContent,
  Sumary,
} from "./style";
import { currency } from "../../../../../aaone/shared/utils/currency";

export const DetailNoteSmartPos = ({ data }) => {
  return (
    <>
      <ContentInfoNote>
        <p>
          <strong>Nº</strong>
          <br />
          {data.receiptNumber}
        </p>
        <p>
          <strong>Serie</strong>
          <br />
          {data.receiptSeries}
        </p>
        <p>
          <strong>Status</strong>
          <br />
          {data.status}
        </p>
        <p>
          <strong>PDV</strong>
          <br />
          {data.posId}
        </p>
      </ContentInfoNote>
      <ContentKeyAccess>
        <p>
          <strong>Chave de acesso</strong>
          <br />
          {data.keyaccess}
        </p>
      </ContentKeyAccess>
      <ContentInfoNote>
        <p>
          <strong>Dt. Atendimento</strong> <br />
          {data.dtAtendimento}
        </p>
        <p>
          <strong>Dt. Autorização</strong> <br />
          {data.dtAutorizacao}
        </p>
      </ContentInfoNote>
      <ContentInfoNote>
        <p>
          <strong>Protocolo</strong> <br />
          {data.protocolo}
        </p>
      </ContentInfoNote>
      <ContentInfoNote>
        <p style={{ height: "auto" }}>
          <strong>Motivo</strong> <br />
          {data.motivo}
        </p>
      </ContentInfoNote>
      {data?.items?.map((item) => (
        <ItemsContentNote>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>
              <strong>Item:</strong> <br />
              {item?.sequentialId}
            </p>
            <p>
              <strong>Código:</strong> <br />
              {item?.product?.referenceId}
            </p>
            <p>
              <strong>Qtde:</strong> <br />
              {item?.quantity}
            </p>
          </div>
          <div>
            <strong>Produto:</strong> {item?.product?.name}
          </div>
          <div>
            <strong>Valor Unitário:</strong> {currency(item?.product?.price)}
          </div>
          <div>
            <strong>Valor Total:</strong> {currency(item?.subTotal)}
          </div>
        </ItemsContentNote>
      ))}
      <ResumeContent>
        <Sumary>
          <div>Subtotal</div>
          <div>{data?.totalDiscount}</div>
        </Sumary>
        <Sumary>
          <div>Desconto</div>
          <div>0.00</div>
        </Sumary>
        <Sumary>
          <div>
            <strong>Total</strong>
          </div>
          <div>
            <strong>{data?.total}</strong>
          </div>
        </Sumary>
        <Sumary>
          <hr />
        </Sumary>
        {data?.payments?.map((payment) => (
          <Sumary>
            <div>{payment?.paymentType?.name}</div>
            <div>{currency(payment?.amount)}</div>
          </Sumary>
        ))}
      </ResumeContent>
    </>
  );
};
