export const TransactionCode = {
  Money: 1,
  Debit: 2,
  Credit: 3,
  CreditTef: 4,
  DebitTef: 5,
  CreditSale: 6,
  AgreementSale: 7, // Convenio
  Voucher: 8,
  VoucherTef: 9,
  PrivateLabel: 10,
  PrivateLabelTef: 11,
  QRCode: 12,
  Others: 15,
};
