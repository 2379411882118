import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getPaymentsByCode } from "../../../helpers/paymentsHelper";

import { HeaderPaymentMethod } from "../../../components/Header/paymentMethod";
import { Container } from "../../paymentMethods/styles";
import { LoadingDots } from "../../../components/LoadingDots";
import { PagesEnum } from "../../../constants/pagesEnum";
import { printerActionService } from "../../../../services/actions/printerActionService";
import { cancelOperation } from "../../../../services/verifyRedeemValueService";
import { nsuValueHelper } from "../../../helpers/nsuValueHelper";
import { useCart } from "../../../hook/cartHook";
import {
  getLocalPayments,
  getPaymentsSerializedToOrder,
  getTotalAlreadyPaid,
  saveLocalPayment,
} from "../../../helpers/multiPaymentsHelper";
import { processesOperationService } from "../../../../services/processesOperationService";
import { sendSaleOrderService } from "../../../../services/sendSaleOrderService";
import { LoadingWhiteWithText } from "../../../components/LoadingWhiteWithText";
import { PAYMENT_METHOD_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import { useDialog } from "../../../hook/dialogHook";
import { useLoading } from "../../../hook/loadingHook";
import { currencyMask } from "../../../helpers/masks";
import { useCashdesk } from "../../../hook/cashdeskHook";
import { undoTEFPayment } from "../../../helpers/paymentHelper";

const TRANSMITTING_NOTE = "Aguarde transmitindo a nota...";
const TEXT_STATUS_DEFAULT = "Transação em andamento. Por favor, aguarde.";

let timeout;
let sendingPay = false;

export const PaymentPosPage = () => {
  const {
    reshopTotalPrice,
    totalPrice,
    totalPriceWDiscount,
    itemsInCart,
    Sale,
    addPaymentSuccess,
    manualDiscount,
  } = useCart();

  const location = useLocation();
  const navigate = useNavigate();
  const { setShowLoading } = useLoading();
  const { showAlert } = useDialog();
  const { cashdesk } = useCashdesk();

  const { totalPriceToPayNow, totalChangeMoney } = location.state || {};

  const qrcodeList = ["QRLINX", "QR LINX", "QRCODE", "QR CODE"];
  const isQrLinx = Boolean(
    location?.state?.data?.paymentOneBaseCode == "12"
      ? true
      : qrcodeList.includes(location?.state?.data?.description) ?? false
  );

  const [paymentsMethods, setPaymentsMethods] = useState([]);
  const [isLoadingValidate, setIsLoadingValidate] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [textStatus, setTextStatus] = useState(TEXT_STATUS_DEFAULT);

  const handleBackToHome = useCallback(() => {
    try {
      const paymentTEFData = location.state?.paymentTEFData;

      if (paymentTEFData) {
        setShowLoading(true, "Aguarde, verificando e desfazendo pagamento(s).");

        const allPayments = getLocalPayments();

        if (paymentTEFData) {
          console.log(
            `Desfazendo operação para o NSU ${paymentTEFData.controlCode}`
          );

          const payment = allPayments.find(
            (payment) =>
              payment.details.controlCode === paymentTEFData.controlCode
          );

          undoTEFPayment(payment).then((payments) => {
            showAlert({
              message: (
                <>
                  Os pagamentos abaixo foram cancelados.
                  <br />
                  NSU: {paymentTEFData.controlCode} | Valor:{" "}
                  {currencyMask(paymentTEFData.value)}
                </>
              ),
            });
          });
        }

        setTimeout(() => {
          setShowLoading(false);
        }, 2000);
      }

      navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const goToPaymentMethods = () => {
    navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
  };

  const sendPayment = (paymentData) => {
    if (!sendingPay) {
      sendingPay = true;

      let paymentTEFData = location.state?.paymentTEFData;

      if (paymentData.walletLinx) {
        navigate(PagesEnum.PAYMENT_QR_LINX_METHOD, {
          replace: true,
          state: {
            PAYMENT_METHOD: "QR LINX",
            totalPriceToPayNow,
            totalChangeMoney,
            ...(location?.state?.data && { data: location.state.data }),
          },
        });
        return;
      }

      const paymentTransition = (
        sessionStorage.getItem(PAYMENT_METHOD_KEY_NAME) || ""
      )
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      if (paymentTEFData) {
        console.log(
          `Selecionado um finalizador diferente para ${JSON.stringify(
            paymentTEFData
          )}\n irá assumir ${JSON.stringify(paymentData)}`
        );

        if (paymentTEFData.sitefCode > 0) {
          paymentTEFData.sitefCode = paymentData.integrationCodeSitef;
        } else if (paymentTEFData.dtefCode > 0) {
          paymentTEFData.dtefCode = paymentData.integrationCodeDtef;
        }

        saveLocalPayment(paymentTransition, {
          amountPaid: totalPriceToPayNow,
          changeMoney: totalChangeMoney,
          ...paymentTEFData,
          description: `${paymentData.description?.toUpperCase()} (${
            paymentData.transactionType
          })`,
        });
      } else {
        saveLocalPayment(paymentTransition, {
          amountPaid: totalPriceToPayNow,
          changeMoney: totalChangeMoney,
          ...paymentData,
          description: `${paymentData.description?.toUpperCase()} (${
            paymentData.transactionType
          })`,
        });
      }

      addPaymentSuccess();

      if (window.Android) {
        console.log(
          "Prices and Items: " +
            JSON.stringify({
              payment_method: paymentTransition,
              totalAlreadyPaid: getTotalAlreadyPaid(),
              totalPrice,
              totalManualDiscount: manualDiscount,
              reshopTotalPrice,
              totalPriceWDiscount,
              itemsInCart,
            })
        );
      }

      if (
        location.state?.isAllItensFidelity ||
        getTotalAlreadyPaid() >= totalPriceWDiscount
      ) {
        setIsSendingRequest(true);
        processesOperationService({
          cartTotalPrice: totalPrice,
          cartTotalPriceWithoutQuantity: totalPrice,
          itemsCart: itemsInCart,
          totalInCart: itemsInCart.length,
        }).then(async () => {
          try {
            sendingPay = false;

            const payments = getPaymentsSerializedToOrder();

            timeout = setTimeout(() => {
              setTextStatus(TRANSMITTING_NOTE);
            }, 3000);

            const orderResult = await sendSaleOrderService({
              payments,
              options: {
                hasTef: false,
                paymentTransition,
              },
              prices: {
                totalPrice,
                totalPriceWDiscount,
                reshopTotalPrice: reshopTotalPrice ? reshopTotalPrice : 0,
                totalManualDiscount: manualDiscount,
              },
              items: itemsInCart,
              Sale,
              cashDeskOpenDateId: cashdesk.cashDeskOpenDate.id,
            });

            const {
              errorWhenAuthorizing,
              pathDocFiscal,
              saleControl,
              danfeUrl,
              danfeBase64,
            } = orderResult?.payload?.orderSale;

            printerActionService({
              errorWhenAuthorizing,
              pathDocFiscal,
              saleControl,
              danfeUrl,
              danfeBase64,
            });

            setTimeout(() => {
              navigate(PagesEnum.PAYMENT_SUCCESSFUL, { replace: true });
            }, 250);
          } catch (error) {
            sendingPay = false;
            setIsSendingRequest(false);

            cancelOperation(nsuValueHelper()).then(() => {
              goToPaymentMethods();
            });
          }
        });
      } else {
        sendingPay = false;
        goToPaymentMethods();
      }
    }
  };

  useEffect(() => {
    sendingPay = false;

    if (location.state?.data) {
      (async () => {
        setIsLoadingValidate(true);
        const EnumCode = location.state?.data?.paymentOneBaseCode ?? "";
        const paymentsData = await getPaymentsByCode(EnumCode);

        setPaymentsMethods(paymentsData);
        setIsLoadingValidate(false);
      })();
    }

    if (timeout) {
      setTextStatus(TEXT_STATUS_DEFAULT);
      clearTimeout(timeout);
    }
  }, [location]);

  useEffect(() => {
    if (isQrLinx && paymentsMethods.length === 1) {
      const [paymentMethod = {}] = paymentsMethods;

      sendPayment(paymentMethod);
    }
  }, [paymentsMethods, location]);

  if (isSendingRequest || paymentsMethods.length <= 1) {
    return (
      <Container>
        <div className="loading__container">
          <LoadingWhiteWithText>{textStatus}</LoadingWhiteWithText>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="content__methods">
        <HeaderPaymentMethod
          onClickBackButton={handleBackToHome}
          disabledBackButton={isLoadingValidate}
        >
          {location.state?.data?.description ?? "Pagamento POS"}
        </HeaderPaymentMethod>
        {isLoadingValidate ? (
          <div className="loading__container">
            <LoadingDots />
          </div>
        ) : (
          <div className="content__container">
            <div className="methods__wrapper">
              {paymentsMethods?.length > 0 && (
                <ul className="methods__list">
                  {paymentsMethods.map((paymentMethod, index) => {
                    const methodName =
                      paymentMethod.paymentOneDetails.brandName;

                    return (
                      <li
                        key={String(`${methodName}-${index}`)}
                        onClick={() => {
                          sendPayment(paymentMethod);
                        }}
                      >
                        <div className="icon">
                          <img src={paymentMethod?.image} alt={methodName} />
                        </div>
                        <span>{methodName}</span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
