import styled from "styled-components";

export const InvisibleBlock = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TextContainer = styled.div`
  text-transform: uppercase;
  font-size: 1.1rem;
  margin: 16px 0;
`;
