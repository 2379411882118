import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content__wrapper {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;

    .form__container {
      max-width: 750px;
      margin: 2rem auto;
      width: 100%;

      .input-container {
        display: flex;
        padding-bottom: 20px;
      }

      .input__group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        padding-top: 10px;

        label {
          padding: 10px;
        }

        select {
          width: 50%;
          border-radius: 5px;
        }

        input {
          padding: 0.5rem;
          font-size: 1rem;
          border: 0;

          margin-right: 1rem;
          flex: 1;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      button.button__submit {
        background-color: rgba(46, 0, 55, 1);
        text-transform: uppercase;
        text-align: center;
        margin: 1rem 0 0;
        color: #fff;
        width: 100%;
      }
    }
  }
`;
