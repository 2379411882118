import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format, parse } from "date-fns";
import classNames from "classnames";

import { isSmartPos } from "../../../aaone/configuration";

import DatePicker from "../../components/date-picker";
import { HeaderPaymentMethod } from "../../components/Header/paymentMethod";
import { PagesEnum } from "../../constants/pagesEnum";

import { Container } from "./styles";
import {
  getStoreFranchiseId,
  getStoreId,
  getStoreServerId,
} from "../../../aaone/configuration";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";

export const SaveLogsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();
  const { setVisibleSidebarCart } = useWrapperRouters();

  const storeServerId = getStoreServerId();
  const franchiseId = getStoreFranchiseId();
  const storeId = getStoreId();

  const [startDate, setStartDate] = useState(new Date());

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, []);

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault();

    setShowLoading(true);

    try {
      const formData = new FormData(e.target);
      const pathName = formData.get("typeLog") || "";
      const dateSelect = formData.get("dateSelect") || "";
      const environment = formData.get("env") || "prod";

      const prefixClient = `${environment}-${storeServerId}-${franchiseId}-${storeId}`;

      if (window?.Android?.uploadLogFile && pathName) {
        const dateReceived = format(
          parse(dateSelect, "dd/MM/yyyy", new Date()),
          "dd-MM-yyyy"
        );

        window.Android.uploadLogFile(dateReceived, pathName, prefixClient);
      } else {
        let message = "Não é possível salvar nesse dispositivo.";

        if (window.Android) {
          message = "Não é possível salvar nesse APK.";
        }

        showAlert({ message });
      }
    } catch (error) {
      const messageError = "Erro handleOnSubmit on SaveLogsPage";

      if (window.Android) {
        console.log(`${messageError} ${JSON.stringify(error)}`);
      } else {
        console.log(messageError, error);
      }
      setShowLoading(false);
    }
  }, []);

  useEffect(() => {
    setVisibleSidebarCart(false);

    window.returnUploadLogFile = (data) => {
      console.log(`window.returnUploadLogFile | ${JSON.stringify(data)}`);

      const unknownError = "Ocorreu um erro desconhecido!";

      setShowLoading(false);

      try {
        const message = data?.success
          ? "Salvo com sucesso!"
          : data?.errorMessage ?? unknownError;

        showAlert({ message });
      } catch (error) {
        showAlert({ message: unknownError });
      }
    };

    return () => {
      window.returnUploadLogFile = undefined;
    };
  }, [location]);

  return (
    <Container>
      <HeaderPaymentMethod onClickBackButton={handleBackToHome}>
        Salvar Logs
      </HeaderPaymentMethod>
      <div className="content__wrapper">
        <form onSubmit={handleOnSubmit} className="form__container">
          <div className="input__group">
            <div className={classNames({ isSmartPos }, "flex-row")}>
              <div className="input__group">
                <label htmlFor="dateSelect">Data</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="dateSelect"
                  id="dateSelect"
                />
              </div>
              <div className="input__group">
                <label htmlFor="typeLog">Tipo</label>
                <select name="typeLog" id="typeLog">
                  <option value="LogsPDVOneAA">LogsPDVOneAA</option>
                  <option value="LogsAATablet">LogsAATablet</option>
                  <option value="DTEFMobile">DTEFMobile</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input__group">
            <label htmlFor="env">Ambiente</label>
            <select name="env" id="env">
              <option value="prod" selected>PROD</option>
              <option value="hml">HML</option>
              <option value="dev">DEV</option>
              <option value="local">LOCAL</option>
            </select>
          </div>
          <div className="buttons__container">
            <button className="button button__submit" type="submit">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
