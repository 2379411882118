import React, { createContext, useCallback, useMemo, useState } from "react";
import { useDialog } from "../../hook/dialogHook";
import { apiProducts } from "../../../services/api";
import { useLoading } from "../../hook/loadingHook";
import {
  getStoreFranchiseId,
  getStoreId,
  getStoreServerId,
  isPDVOne,
} from "../../../aaone/configuration";

export const MigrationContext = createContext({});

export const MigrationProvider = ({ children }) => {
  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();

  const [lastUpdateMigrationByUser, setLastUpdateMigrationByUser] =
    useState(null);

  const generateMigration = useCallback(
    async (isDisabledMessage = false) => {
      setShowLoading(true, "Atualizando cardápio, aguarde!");

      const storeServerID = getStoreServerId();
      const storeFranchiseID = getStoreFranchiseId();
      const storeID = getStoreId();

      try {
        await apiProducts.post("/api/webmenu/migration", {
          pdvOne: isPDVOne,
          storeServerID,
          storeFranchiseID,
          storeID,
          newStore: true,
        });

        setLastUpdateMigrationByUser(Date.now());

        if (!isDisabledMessage) {
          showAlert({
            message: "Carga atualizada com sucesso!",
          });
        }
      } catch (error) {
        setLastUpdateMigrationByUser(null);

        if (!isDisabledMessage) {
          showAlert({
            message: error?.message?.response?.data?.error ||  "Ocorreu um error ao atualizar a carga!",
          });
        } else {
          setShowLoading(false);
          throw new Error(error.message);
        }
      }

      setShowLoading(false);
    },
    [setShowLoading]
  );

  const value = useMemo(
    () => ({ lastUpdateMigrationByUser, generateMigration }),
    [lastUpdateMigrationByUser, generateMigration]
  );

  return (
    <MigrationContext.Provider value={value}>
      {children}
    </MigrationContext.Provider>
  );
};
