import React from "react";
import { PizzaContainer, Pizza, PizzaHalf } from "./style";

const PizzaFlavor = ({ halfToHalf, image, image1 }) => {
  return (
    <PizzaContainer className="pizzaTablet">
      {halfToHalf ? (
        <>
          <PizzaHalf>{image && <img className="imagePizzaTablet" src={image} alt={image} />}</PizzaHalf>
          <PizzaHalf>{image1 && <img className="imagePizzaTablet" src={image1} alt={image1} />}</PizzaHalf>
        </>
      ) : (
        <>
          <Pizza>{image && <img className="imagePizzaTablet" src={image} alt={image} />}</Pizza>
          <Pizza>{image && <img className="imagePizzaTablet" src={image} alt={image} />}</Pizza>
        </>
      )}
    </PizzaContainer>
  );
};

export default React.memo(PizzaFlavor);
