import styled, { css } from "styled-components";
import { isSmartPos } from "../../../aaone/configuration";

export const MonitorNotesWrapper = styled.div`
  margin: 10px 0 0;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  overflow-y: hidden;

  &.isSmartPos {
    overflow-y: auto;
    display: block;
    width: 100%;

    ::-webkit-scrollbar {
      width: 1px;
    }

    ::-webkit-scrollbar-thumb {
      border: 0;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  /* justify-content: flex-end;
  align-items: flex-end; */
  margin-bottom: 20px;

  ${isSmartPos
    ? css`
        display: flex;
        flex-direction: column;

        #status {
          width: 47%;
          margin-top: 5px;
          margin-right: 5px;
        }

        #tipos {
          width: 47%;
          margin-top: 5px;
          margin-left: 5px;
        }

        .react-datepicker-popper {
          max-width: 500px;
          width: 76%;
          z-index: 50;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          color: #000;
          display: inline-block;
          width: 1.7rem;
          line-height: 2.7rem;
          text-align: center;
        }
      `
    : ""}

  .react-datepicker__aria-live {
    display: none;
  }

  .control {
    padding: 0px 10px;
    width: 100%;
    height: 30px;
    border: 1px solid rgb(211, 209, 209);
    border-radius: 3px;
    font-size: 16px;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
  }

  input {
    height: 30px;
    padding-left: 10px;

    &:nth-child(2) {
      margin-left: 0px;
    }
  }

  .btn-purple {
    width: ${isSmartPos ? "100%" : ""};
    margin-top: ${isSmartPos ? "20px" : ""};
    margin-left: ${isSmartPos ? "" : "15px"};
    margin-right: ${isSmartPos ? "" : "50px"};
    margin-bottom: ${isSmartPos ? "-10px" : ""};

    height: ${isSmartPos ? "35px" : ""};
    font-size: ${isSmartPos ? "12px" : "14px"} !important;
    padding: 0 8px;

    i {
      color: #fff;
      font-size: ${isSmartPos ? "17px" : "19px"} !important;
      &:hover {
        cursor: pointer;
        color: #ffb201;
      }
      margin-right: 5px;
    }
  }

  &.isSmartPos {
    width: 100%;
  }
`;

export const OtherFilters = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${isSmartPos ? "space-between" : "flex-start"};
  flex-wrap: ${isSmartPos ? "wrap" : "no-wrap"};
  margin-bottom: ${isSmartPos ? "" : "10px"};
  width: ${isSmartPos ? "100%" : "calc(100% - 40px)"};

  button {
    height: ${isSmartPos ? "35px" : ""};
    width: ${isSmartPos ? "90%" : ""};
    font-size: ${isSmartPos ? "12px" : "14px"} !important;

    ${isSmartPos
      ? css`
          width: 100%;
          max-width: 120px;
          margin-bottom: 10px;
        `
      : ""}
    min-width: ${isSmartPos ? "120px" : "140px"};
    padding: 10px 16px;
    margin-right: ${isSmartPos ? "" : "10px"};

    &:hover {
      color: #f0462d;
      background-color: #fff;
    }
  }

  .right-buttons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    padding-right: 80px;
  }

  #btnInutilizar {
    height: ${isSmartPos ? "35px" : ""};
    background-color: #e5e5e5;
    border: 1px solid #d1d1d1 !important;
    border-radius: 4px !important;
    padding: 0.7em !important;
    color: #7c7c7c;
    font-size: ${isSmartPos ? "12px" : "14px"} !important;
  }
`;

export const ContainerTable = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: scroll;
  margin-right: 5px;

  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar * {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  .ag-theme-balham {
    &.monitor {
      .ag-header {
        background: #e7eaf1;
      }

      *::-webkit-scrollbar {
        width: 1px;
      }

      .ag-cell-label-container {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }

  .ag-floating-bottom-container .ag-row {
    border: none !important;
    background-color: #ede7f5;
  }

  .status {
    &.autorizada {
      background-color: #f6ffed !important;
      height: auto;
      margin: 15px 0 0;
      border-radius: 4px;
      border-color: #b7eb8f;
      color: #52c41a;
      justify-content: center;
      font-weight: 500;
    }

    &.cancelada,
    &.devolvida,
    &.inutilizada {
      background-color: #e3e3e3 !important;
      height: auto;
      margin: 15px 0 0;
      border-radius: 4px;
      border-color: #bbb;
      color: #747474;
      justify-content: center;
      font-weight: 500;
    }

    &.contingência-rejeitada,
    &.erro-na-emissão {
      background-color: #fff1f0 !important;
      height: auto;
      margin: 15px 0 0;
      border-radius: 4px;
      border-color: #ffa39e;
      color: #f5222d;
      justify-content: center;
      font-weight: 500;
    }

    &.pendente,
    &.contingência,
    &.processo-incompleto {
      background-color: #fff2d8 !important;
      height: auto;
      margin: 15px 0 0;
      border-radius: 4px;
      border-color: #ffa838;
      color: #e37e00;
      justify-content: center;
      font-weight: 500;
    }
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 130px;
  bottom: 10px;
  flex-direction: column;
  position: absolute;
  right: 0;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;
