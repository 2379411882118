import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr 1fr;

  border-radius: 1rem;
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;
  padding: 1rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 2rem;

    background-color: #F2F4F7;
    border-radius: 1rem;
    border: solid 1px rgba(189, 189, 189, 0.50) !important;

    &.button-clear-item {
      font-size: 1rem;
    }
  }

  &.keyboard-vertical-layout {
    grid-gap: 1.25rem 1.25rem;
    gap: 1.25rem 1.25rem;
    padding: 1.25rem;

    .button {
      padding: 1.5rem 0;

      &.button-clear-item {
        font-size: 1.5rem;
      }
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    grid-gap: 0.5rem 0.5rem;
    gap: 0.5rem 0.5rem;

    button {
      padding: 0.25em 0.8em !important;
      height: 2.5rem;
    }
  `)}
`;
