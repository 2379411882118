import React from "react";
import { ScrollContainer } from "../../shared/components/modules";
import { useViewScrolling } from "../../../context";

const ScrollContainerContainer = (props) => {
  const { children, forWaitingModal } = props;
  const { pinnedViewAtBottom, setPinnedViewAtBottom } = useViewScrolling();

  const scrollButtonClickDown = () => {
    setPinnedViewAtBottom(true);
  };

  const scrollButtonClickUp = () => {
    setPinnedViewAtBottom(false);
  };

  return (
    <ScrollContainer
      pinnedViewAtBottom={pinnedViewAtBottom}
      scrollButtonClickDown={scrollButtonClickDown}
      scrollButtonClickUp={scrollButtonClickUp}
      forWaitingModal={forWaitingModal}
    >
      {children}
    </ScrollContainer>
  );
};

export default ScrollContainerContainer;
