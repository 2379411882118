import React, { createContext, useEffect, useMemo, useState } from "react";
import { LoadingDots } from "../../components/LoadingDots";
import { LoadingWrapper } from "./style";
import classNames from "classnames";

export const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [textInLoading, setTextInLoading] = useState(false);

  useEffect(() => {
    if (!showLoading) {
      setTextInLoading(null);
    }
  }, [showLoading]);

  const value = useMemo(
    () => ({
      showLoading,
      setShowLoading: (visible = true, text = null) =>
        setShowLoading(() => {
          setTextInLoading(text);

          return visible;
        }),
    }),
    [showLoading, setShowLoading]
  );

  return (
    <>
      {showLoading && (
        <LoadingWrapper>
          <div
            className={classNames(
              { container__withText: !!textInLoading },
              "container__loading"
            )}
          >
            <LoadingDots />
            {textInLoading && (
              <div className="loading__text">{textInLoading}</div>
            )}
          </div>
        </LoadingWrapper>
      )}

      <LoadingContext.Provider value={value}>
        {children}
      </LoadingContext.Provider>
    </>
  );
};
