import React from "react";
import classNames from "classnames";
import { useCart } from "../../../../hook/cartHook";

export const QuantityDefault = ({
  quantity,
  totalQuantity,
  isLoadingCategories,
  handleChangeQuantity,
  openModalAddProductByCode,
}) => {
  const { quantityToCart } = useCart();

  return (
    <>
      {[...new Array(quantity)].map((_, index) => (
        <div
          role="button"
          onClick={() => {
            if (!isLoadingCategories) {
              handleChangeQuantity(index + 1);
            }
          }}
          className={classNames({
            item__button: true,
            item__num: true,
            item__active:
              index + 1 === totalQuantity.quantity &&
              totalQuantity.format === "number",
            skeleton: isLoadingCategories,
            [`item__quantity__${index + 1}`]: true,
          })}
          key={String(index)}
        >
          {isLoadingCategories ? (
            <div className="skeleton__text" />
          ) : (
            <>{index + 1}</>
          )}
        </div>
      ))}
      <div
        onClick={!isLoadingCategories ? openModalAddProductByCode : () => {}}
        role="button"
        className={classNames({
          item__button: true,
          item__code: true,
          skeleton: isLoadingCategories,
        })}
      >
        {isLoadingCategories ? <div className="skeleton__text" /> : "Código"}
      </div>
      <div
        role="button"
        onClick={() => {
          if (!isLoadingCategories) {
            handleChangeQuantity("qtd");
          }
        }}
        className={classNames({
          item__button: true,
          item__qtd: true,
          item__active: "other" === totalQuantity.format,
          skeleton: isLoadingCategories,
        })}
      >
        {isLoadingCategories ? (
          <div className="skeleton__text" />
        ) : (
          <>
            {"other" === totalQuantity.format && quantityToCart > quantity
              ? quantityToCart
              : "Qtd"}
          </>
        )}
      </div>
    </>
  );
};
