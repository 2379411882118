import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background: radial-gradient(circle, #613664 0%, #2e0037 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  height: 100%;
  flex: 1;

  img {
    filter: drop-shadow(2px 4px 6px #000);
    max-width: 90%;
    width: auto;
  }

  .message {
    margin: 3rem auto;
    color: #ccc;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-family: Arial, Helvetica, sans-serif;
      margin-bottom: 3rem;

      font-weight: 900;
      font-size: 2.5rem;
    }

    .desc {
      font-family: Arial, Helvetica, sans-serif;
      margin-top: 3rem;

      font-weight: 400;
      font-size: 2rem;
    }
  }
`;
