import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Content, Button, Keyboard } from "../../shared/components/modules";
import logoDefault from "../../assets/prisma-default.png";
import pagerDefault from "../../assets/pager.png";
import logo from "../../assets/prisma-rex.png";
import logo2 from "../../assets/prisma-habibs.svg";
import { isShowPagerPage } from "../../configuration";
import { getThemeSettings } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { useViewDispatch } from "../../../context";

const PrismaContainer = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const { getTranslateName, isLayout, isLayoutAAOne, getScreenTitleBySlug, getScreenImageBySlug } = useLayoutAa();
  const onTableText =
    getTranslateName(t, "on_table") ||
    getScreenTitleBySlug("RECEBA_NA_MESA") ||
    DEFAULT_LOCAL_TEXTS.on_table;

  const buttonBackText = getTranslateName(t, "buttons.back") || "Voltar";

  const pagerMessageText =
    getTranslateName(t, "pager_message") ||
    "Pegue o seu Pager ao Lado e Digite o numero aqui!";

  const tableOrderInstructionsText =
    getTranslateName(t, "table_order_instructions") ||
    "Para receber seu pedido na mesa pegue um prisma ao lado e digite o número dele";

  const tableRefText =
    getTranslateName(t, "table_ref") ||
    getThemeSettings?.text?.table_ref ||
    "Insira o número";

  const withdrawAtTheCounterText =
    getTranslateName(t, "withdraw_at_the_counter") ||
    getThemeSettings?.text?.withdraw_at_the_counter ||
    "Quero Retirar no Balcão";

  const [inputValue, setInputValue] = useState("");
  const keyboard = useRef();

  const onChange = (inputValue) => {
    keyboard.current.setInputValue(inputValue);
    setInputValue(inputValue);
  };

  const imagePrisma = () => {
    return isLayout(["RAGAZZOEXPRESS", "RAGAZZO"])
      ? logo
      : !isLayout("HABIBS")
      ? isShowPagerPage
        ? pagerDefault
        : logoDefault
      : isShowPagerPage
      ? pagerDefault
      : logo2;
  };

  const headerImageSrc = isLayoutAAOne ? getScreenImageBySlug("RECEBA_NA_MESA", "RECEBER") : imagePrisma();

  const next = (inputValue) => {
    inputValue
      ? isShowPagerPage
        ? localStorage.setItem("pagerNumber", inputValue)
        : localStorage.setItem("prismaNumber", inputValue)
      : localStorage.setItem("prismaNumber", "");
    viewDispatch("step.cpf");
    window.localStorage.removeItem("prismakey");
    window.localStorage.removeItem("tellkey");
  };

  useEffect(() => {
    localStorage.setItem("prismakey", true);
  }, []);
  return (
    <>
      <Content
        bgColor="background-primary"
        title={isShowPagerPage ? "Pager" : onTableText}
        subtitle={
          getThemeSettings?.text?.describe_on_table
            ? getThemeSettings.text.describe_on_table
            : isShowPagerPage
            ? pagerMessageText
            : tableOrderInstructionsText
        }
        contentChildren={true}
        className={isShowPagerPage ? "full pager" : "full prisma"}
        hasImg={true}
        srcImg={headerImageSrc}
        imgPosition="bottom"
      >
        <Keyboard
          classN="prisma"
          handleClick={next}
          onChange={onChange}
          value={inputValue}
          typeInput="numeric"
          textPlaceholder={tableRefText}
        />

        <footer className="footer">
          <Button
            text={buttonBackText}
            className={isShowPagerPage ? "pager-default" : ""}
            buttonUIType="outline-primary"
            buttonUI={"normal"}
            handleClick={() => viewDispatch("step.orderSummary")}
          />
          {!isShowPagerPage && (
            <Button
              className="btnragazzobalcao"
              text={withdrawAtTheCounterText}
              buttonUIType="outline-primary"
              buttonUI={"normal"}
              handleClick={() => next()}
            />
          )}
        </footer>
      </Content>
    </>
  );
};

export default PrismaContainer;
