import React from "react";
import Modal from "react-modal";
import { DetailOrderWrapper, TotalWrapper, InputWrapper } from "./styles";
import { Button } from "../../Button";
import Table from "../../table";
import { isSmartPos } from "../../../../aaone/configuration";

const customStyle = {
  content: {
    top: isSmartPos ? "30px" : 0,
    bottom: isSmartPos ? "auto" : 0,
    right: isSmartPos ? "auto" : 0,
    left: isSmartPos ? "30px" : 0,
    maxWidth: "600px",
    maxHeight: isSmartPos ? "450px" : "350px",
    background: "#ffffff",
    margin: "auto",
    padding: isSmartPos ? "20px" : "12px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.60)",
    display: "block",
  },
  iconCloseModal: {
    float: "right",
    cursor: "pointer",
  },
};

function ModalPrintCancelTef(props) {
  const { typeAction, showModal, tefOrder, handleClose, handleConfirm } = props;

  const onCloseModal = () => {
    handleClose();
  };

  const handleActionTef = (e) => {
    e.preventDefault();
    handleConfirm(typeAction);
  };

  const columns = [
    { title: "DESCRIÇÃO", cols: [{ label: { ref: "name" } }] },
    { title: "QUANTIDADE", cols: [{ label: { ref: "quantity" } }] },
    { title: "TOTAL", cols: [{ label: { ref: "subTotal" } }] },
  ];

  const textButton =
    typeAction == "print" ? "Reimprimir cupom" : "Confirmar cancelamento";

  return tefOrder ? (
    <Modal
      isOpen={showModal}
      closeOnOverlayClick={true}
      center
      style={customStyle}
      ariaHideApp={false}
    >
      <div>
        <div>
          <i
            className="material-icons close-modal"
            style={customStyle.iconCloseModal}
            onClick={onCloseModal}
          >
            cancel
          </i>
          <span>Nº CUPOM: </span>
          <strong>{tefOrder.couponNumber}</strong>
        </div>
        <DetailOrderWrapper>
          <Table columns={columns} items={tefOrder.itens} />
          <TotalWrapper>
            <label>TOTAL</label>
            <strong>{tefOrder.total}</strong>
          </TotalWrapper>
          <h3>Status:</h3>
          <strong>
            {tefOrder.fiscalDocument && tefOrder.fiscalDocument.reason}
          </strong>
          <InputWrapper>
            <Button
              tabIndex="0"
              autoFocus={true}
              id="btn-conf-cancel"
              type="button"
              className="btn-large btn-purple btn-confirm-pay"
              handleClick={(e) => handleActionTef(e)}
            >
              {textButton}
            </Button>
          </InputWrapper>
        </DetailOrderWrapper>
      </div>
    </Modal>
  ) : null;
}

export default ModalPrintCancelTef;
