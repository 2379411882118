import styled from "styled-components";

export const Content = styled.div`
  width:100%;
  margin:0 auto;
  input:checked ~ .content-top {
    height: 100px;
  }
`;

export const Section = styled.div`
  width:95%;
  margin-left: 3%;
  label {
    display:block;
    border:1px solid #ccc;
    background: var(--color-background-primary);;
    cursor:pointer;
    padding:5px 3px;
    color: white;
    border-radius: 20px;
    padding: 15px;
    font-size: 1.3rem;
    letter-spacing: 1px;
    margin-top: 0.6rem !important;
    i {
      float: right;
      font-size: 1.5em;
    }
  }
  input {
    display:none;
  }
  [id^=composition-input-]:checked ~ .composition-body {
      height: auto;
  }
`;

export const SectionBody = styled.div`
  height: 0px;
  overflow: hidden;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  -ms-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  &.active {
    height: auto !important;
  }
`;
