import React from "react";
import { render } from "react-dom";
import PrintTemplate from "../../components/print-template";
import { formatDateHelper } from "../formatDateHelper";
import { currencyMask } from "../masks";
import { printerActionService } from "../../../services/actions/printerActionService";
import { lineToPrintHelper } from "../lineToPrintHelper";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const handlePrintCoupon = (order, manager) => {
  var html = document.createElement("html");

  render(
    <PrintTemplate>
      <>
        {!isMobile
          ? "--------------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <div style={{ textAlign: "center" }}>
          <b>CANCELAMENTO DE VENDA</b>
        </div>
        {!isMobile
          ? "--------------------------------------------------------"
          : lineToPrintHelper}
        <br />
        PDV: {order.posId}
        {isMobile ? "\n" : ""}
        <br />
        <table>
          <tbody>
            <tr>
              <td className="shrink">Data:{isMobile ? " " : ""}</td>
              <td className="expand">
                {formatDateHelper(
                  new Date(),
                  "dd/MM/yyyy HH:mm:ss"
                )}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Nota:{isMobile ? " " : ""}</td>
              <td className="expand">
                {order.fiscalNumber}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Responsável:{isMobile ? " " : ""}</td>
              <td className="expand">
                {manager.login}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Valor Total:{isMobile ? " " : ""}</td>
              <td className="expand">
                <b>
                  {currencyMask(order.totalDiscount)}
                  {isMobile ? "\n" : ""}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
        {!isMobile
          ? "--------------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          {!isMobile
            ? "--------------------------------------------------------"
            : lineToPrintHelper}
          <b>
            {!isMobile
              ? "Assinatura/carimbo do responsável"
              : "         Assinatura/carimbo do responsável"}
          </b>
        </div>
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "*NÃO É DOCUMENTO FISCAL*"
              : "            *NÃO É DOCUMENTO FISCAL*"}
          </b>
        </div>
        {!isMobile
          ? "--------------------------------------------------------"
          : lineToPrintHelper}
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
      </>
    </PrintTemplate>,
    html
  );

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    console.log(`printDefault | {}`);
    printDefault(html.outerHTML);
  }
};
