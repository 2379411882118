import styled from "styled-components";

export const SwitchContainer = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: rgba(46, 0, 55, 1);
    }

    input:focus + .slider {
      box-shadow: 0 0 1px rgba(46, 0, 55, 1);
    }

    input:checked + .slider:before {
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  &.isSmartPos {
    .switch {
      height: 28px;

      .slider.round:before {
        height: 24px;
        width: 24px;
        bottom: 2px;
        right: 0px;
      }

      input:checked + .slider:before {
        transform: translateX(28px);
      }
    }
  }
`;
