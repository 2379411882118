import styled from "styled-components";

const CardWrapper = styled.section`
  max-width: 62%;
  padding: 3em 7em;
  margin: 5rem auto;

  &.horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 15px;
  }
`;

export default CardWrapper;
