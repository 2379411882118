import { DATA_USER_AUTH_KEY_NAME } from "../constants/keyNamesSessionStorage";

export const getUserAuthDataHelper = () => {
  const data = sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME);

  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  }

  return null;
};
