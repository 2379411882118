import { PAYMENTS_METHOD_KEY_NAME } from "../constants/keyNamesSessionStorage";
import { getPaymentsMethodService } from "../../services/getPaymentsMethodService";
import { PAYMENT_TYPE_ENUM } from "../constants/paymentTypeEnum";

export const getPaymentsMethodHelper = async () => {
  try {
    let payments = JSON.parse(
      sessionStorage.getItem(PAYMENTS_METHOD_KEY_NAME) || "[]"
    );

    if (!payments) {
      const paymentsResponse = await getPaymentsMethodService();

      if (!paymentsResponse) {
        return [];
      }

      payments = paymentsResponse;
    }

    return payments;
  } catch (error) {
    console.error("Ocorreu um erro para filtrar os pagamentos");
    console.error(JSON.stringify(error));
    return [];
  }
};

export const getPosPaymentMethodHelper = async () => {
  try {
    let payments = getPaymentsMethodHelper();

    if (payments) {
      payments = payments.filter((payment) => payment.isPos === true);
    }

    return payments;
  } catch (error) {
    console.error("Ocorreu um erro para filtrar pagamentos pos");
    console.error(JSON.stringify(error));
    return [];
  }
};

export const getPaymentsByCode = async (EnumCode) => {
  const payments = await getPaymentsMethodHelper();
  const payment = payments.find(
    (payment) => String(payment.paymentOneBaseCode) === String(EnumCode)
  );

  if (!payment) {
    return [];
  }

  return payment.payments;
};

/**
 * Retorna o ENUM da trasação baseada no tipo de transação
 *
 * @param {String} transactionType - Tipo de transação
 * @param {*} paymentObject - Objeto de pagamento retornado da API
 * @returns
 */
export const getTransactionCodeFromType = (transactionType, paymentObject) => {
  if (paymentObject?.paymentOneBaseCode) {
    return paymentObject.paymentOneBaseCode;
  }

  if (transactionType === "DEBITO") return PAYMENT_TYPE_ENUM.DebitTef;
  else if (transactionType === "CREDITO") return PAYMENT_TYPE_ENUM.CreditTef;
  else if (transactionType === "VOUCHER") return PAYMENT_TYPE_ENUM.VoucherTef;
  else if (transactionType === "QR LINX" || transactionType === "QRLINX")
    return PAYMENT_TYPE_ENUM.QRCode;

  return PAYMENT_TYPE_ENUM.Money;
};
