import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdAdd, MdHorizontalRule, MdSearch } from "react-icons/md";

import { Container } from "./styles";
import { useCart } from "../../../../hook/cartHook";
import { escOnlyNumbersHelper } from "../../../../helpers/escOnlyNumbersHelper";

export const QuantitySmall = ({
  isLoadingCategories,
  handleChangeQuantity,
  openModalAddProductByCode,
}) => {
  const inputRef = useRef();
  const [quantityInputValue, setQuantityInputValue] = useState(1);

  const handleAddQuantity = useCallback(() => {
    if (inputRef.current) {
      const value = Math.floor(
        Number(escOnlyNumbersHelper(inputRef.current.value))
      );
      if (Number.isNaN(value) || value <= 0) {
        inputRef.current.value = 1;
      } else {
        inputRef.current.value = value + 1;
      }

      setQuantityInputValue(inputRef.current.value);
    }
  }, [inputRef?.current]);

  const handleRemoveQuantity = useCallback(() => {
    if (inputRef.current) {
      const value = Math.floor(
        Number(escOnlyNumbersHelper(inputRef.current.value))
      );

      if (Number.isNaN(value) || value <= 1) {
        inputRef.current.value = 1;
      } else {
        inputRef.current.value = value - 1;
      }

      setQuantityInputValue(inputRef.current.value);
    }
  }, [inputRef?.current]);

  useEffect(() => {
    if (quantityInputValue?.length > 0) {
        handleChangeQuantity(Number(quantityInputValue));
    }
  }, [quantityInputValue, handleChangeQuantity]);

  return (
    <Container>
      <div className="quantity__wrapper">
        <button
          type="button"
          onClick={handleRemoveQuantity}
          disabled={isLoadingCategories}
          className="button"
        >
          <MdHorizontalRule size="1rem" />
        </button>
        <input
          type="number"
          inputMode="numeric"
          className="input__quantity"
          ref={inputRef}
          readOnly
          defaultValue="1"
          disabled={isLoadingCategories}
          onChange={(e) => {
            e.preventDefault();

            const value = Math.floor(
              Number(escOnlyNumbersHelper(e.target.value.trim()))
            );

            if (Number.isNaN(value) || value <= 0) {
              e.target.value = 1;
            } else {
              e.target.value = value;
            }

            if (!isLoadingCategories) {
              setQuantityInputValue(value);
            }

            return e;
          }}
        />
        <button
          type="button"
          onClick={handleAddQuantity}
          disabled={isLoadingCategories}
          className="button active"
        >
          <MdAdd size="1rem" />
        </button>
      </div>
      <button
        type="button"
        disabled={isLoadingCategories}
        onClick={openModalAddProductByCode}
        className="button__code"
      >
        <MdSearch />
        <span>Código</span>
      </button>
    </Container>
  );
};
