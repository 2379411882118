import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Content, Button, Card } from "../../shared/components/modules";
import { ScrollContainer } from "../../container/modules";
import { useSelector } from "react-redux";
import { cancelOperation } from "../../../services/verifyRedeemValueService";
import { frontConfig } from "../../../services/frontConfigService";
import logoBobs from "../../assets/logobobs.png";
import configuration, { getThemeSettings, isDesktopApp, isShowYourNameTabletConfig, showPrismaPage } from "../../configuration";

import { ModalPayments } from "../../shared/components/modules";
import { payament } from "../../../services/paymentService";
import { useLayoutAa } from "../../contexts/layout";
import { useViewDispatch } from "../../../context";

const PaymentContainer = () => {
  const { t } = useTranslation();
  const cartItems = useSelector((state) => state.cart.items);
  const viewDispatch = useViewDispatch();
  const {
    getTranslateName,
    getScreenTitleBySlug,
    getScreenIsActiveBySlug,
    getScreenImageBySlug,
    isLayout,
    isLayoutAAOne,
  } = useLayoutAa();
  const isShowYourName = isDesktopApp ? getScreenIsActiveBySlug("INFORME_SEU_NOME") ?? true : isShowYourNameTabletConfig;
  const isShowCPFPage = getScreenIsActiveBySlug("CPF_NA_NOTA") ?? true;
  const isShowCarIdentification =
    getScreenIsActiveBySlug("PLACA_VEICULO") ?? true;
  const isShowPrismaPage =
    getScreenIsActiveBySlug("RECEBA_NA_MESA") ?? showPrismaPage;
  const buttonBackText = getTranslateName(t, "buttons.back") || "Voltar";
  const pageTitle =
    getTranslateName(t, "payment.page_title") ||
    getScreenTitleBySlug("FORMA_PAGAMENTO") ||
    "Formas de pagamento";

  const [modal, setModal] = useState(false);
  const [modalQrWarn, setModalQrWarn] = useState(false);
  const [finalizadores, setFinalizadores] = useState([]);
  const [statusWindow, setStatusWindow] = useState(false);
  const [text, setText] = useState(() => pageTitle);

  useEffect(() => {
    window.localStorage.removeItem("payments");

    payament().then(response => {
      setFinalizadores(response.payments);
      contadorModal(response.payments);
    });
    window.localStorage.setItem("inRouteToPayment", "true");
  }, []);

  useEffect(() => {
    if (!!cartItems.length === false) viewDispatch("step.banner");
  }, [cartItems]);

  const cardImageList = {
    MERCADOPAGO: isLayoutAAOne ? getScreenImageBySlug("FORMA_PAGAMENTO", "MERCADOPAGO") : null,
    DEBITO: isLayoutAAOne ? getScreenImageBySlug("FORMA_PAGAMENTO", "CARTAOCREDITO") : null,
    CREDITO: isLayoutAAOne ? getScreenImageBySlug("FORMA_PAGAMENTO", "CARTAODEBITO") : null,
    VOUCHER: isLayoutAAOne ? getScreenImageBySlug("FORMA_PAGAMENTO", "VALEREFEICAO") : null,
    DINHEIRO: isLayoutAAOne ? getScreenImageBySlug("FORMA_PAGAMENTO", "DINHEIRO") : null,
    "QR LINX": isLayoutAAOne ? getScreenImageBySlug("FORMA_PAGAMENTO", "QRCODE") : null,
  }

  const paymentList = finalizadores.map((i) => {
    let cardImage = i.image;

    if(cardImageList[i.transactionType]) {
      cardImage = cardImageList[i.transactionType];
    } else if ((cardImage || '').startsWith('/')) {
      cardImage = `https://linxfood.blob.core.windows.net${i.transactionType === "QR LINX" ? "/images/qrcode.png" : cardImage}`;
    }

    return (
      <Card
        key={i.transactionType}
        cardDisplay="normal"
        imgURL={cardImage}
        cardTitle={i.transactionType}
        eventClick={() => startPayment(i.transactionType)}
      />
    );
  });
  const contadorModal = (paymentTypes) => {
    const hasMoneyOnPaymentType = paymentTypes.some(
      (i) => i.transactionType == "DINHEIRO"
    );
    if (localStorage.getItem("contador") == 3 && hasMoneyOnPaymentType) {
      setModal(true);
      window.localStorage.removeItem("contador");
    }
    if (
      localStorage.getItem("paymentTransition") == "QR LINX" &&
      localStorage.getItem("returnPayment") == 1
    ) {
      setModalQrWarn(true);
      localStorage.removeItem("returnPayment");
    }
    setTimeout(() => {
      setModalQrWarn(false);
    }, 30000);
  };

  const startPayment = (type) => {
    if (localStorage.getItem("contadorPayment") === 1) {
      setText("AGUARDE...");
      setStatusWindow(true);
      setTimeout(() => {
        startTransition(type);
      }, 3000);
    } else {
      startTransition(type);
    }
  };

  const startTransition = (paymentTransition) => {
    setStatusWindow(true);
    console.log(setStatusWindow());
    setText("Processando pagamento...");

    window.localStorage.setItem("paymentTransition", paymentTransition);
    viewDispatch("step.paymentTransition");
  };

  const isBobsLayout = isLayout("BOBS");

  const backStep = async () => {
    if (isLayoutAAOne) {
      if (isShowYourName) {
        viewDispatch("step.orderIdentification");
      } else {
        if (isShowCPFPage) {
          viewDispatch("step.cpf");
        } else {
          if (isShowCarIdentification) {
            viewDispatch("step.carIdentification");
          } else {
            if (isShowPrismaPage) {
              viewDispatch("step.prisma");
            } else {
              viewDispatch("step.orderSummary");
            }
          }
        }
      }

      return false;
    } else {
      if (!isShowYourName) {
        viewDispatch("step.cpf");
      } else {
        cancelOperation(JSON.parse(localStorage.getItem("Nsu"))).then((res) => {
          viewDispatch("step.orderIdentification");
          throw "Error in operation cancelOperation...";
        });
      }
    }
  };

  return (
    <>
      <Content
        hasImg={isBobsLayout}
        srcImg={logoBobs}
        bgColor={
          isBobsLayout ? "background-primary hasIMG" : "background-primary"
        }
        title={isBobsLayout ? "QUAL SERÁ A FORMA DE PAGAMENTO?" : text}
        subtitle={false}
        contentChildren={true}
        className="full"
      >
        {statusWindow ? (
          <div id="loader"></div>
        ) : (
          <div className="payment-page">
            <section className="payament-list fade">{paymentList}</section>
          </div>
        )}
        {modal && (
          <ScrollContainer>
            <div className="blackout" onClick={() => setModal(false)}>
              <ModalPayments
                onPrimaryClick={() => {
                  startTransition("DINHEIRO");
                }}
                mTitle="CONTINUE SUA COMPRA"
                mSubtitle={
                  getThemeSettings?.text?.change_payment
                    ? getThemeSettings.text.change_payment
                    : "Deseja trocar a forma de pagamento para dinheiro?"
                }
                mTextBtnPrimary="Sim"
                mTextBtnSecondary="Não"
                mBtnPrimarClassName="primary"
                mBtnSecondaryClassName="outline-primary"
              />
            </div>
          </ScrollContainer>
        )}
        {modalQrWarn && (
          <ScrollContainer>
            <div className="blackout" onClick={() => setModalQrWarn(false)}>
              <ModalPayments
                onPrimaryClick={() => {
                  setModalQrWarn(false);
                }}
                mTitle="Atenção"
                mSubtitle="O Pagamento via QRCode será cancelado!
                Caso o valor tenha sido debitado da conta, o mesmo será
                estornado em até 48h."
                mTextBtnPrimary="OK"
                mBtnPrimarClassName="primary"
                mBtnSecondaryClassName="btnsecondary-off"
              />
            </div>
          </ScrollContainer>
        )}

        {!statusWindow && (
          <footer
            className="template-columns-1"
            style={{ width: "75%", marginLeft: "12%", marginTop: "5%" }}
          >
            <Button
              handleClick={() => backStep()}
              text={buttonBackText}
              buttonUIType="outline-primary"
              buttonUI={"normal"}
            />
          </footer>
        )}
      </Content>
    </>
  );
};

export default PaymentContainer;
