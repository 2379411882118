import React, { useEffect, useState } from "react";

import { useCashdesk } from "../../../hook/cashdeskHook";

import { AiOutlineTrophy } from "react-icons/ai";
import { Container, DropDown } from "./styles";
import { getTicketSummaries } from "../../../../services/orderService";
import { getSystemParamsHelper } from "../../../helpers/getSystemParamsHelper";
import MessageBusService from "../../../../services/MessageBusService";
import { currencyMask } from "../../../helpers/masks";

let orderSummariesCashdesk = null;
let orderSummariesRetailer = null;

const GoalStatistics = () => {
  const { cashdesk } = useCashdesk();
  const systemParams = getSystemParamsHelper();

  const [goalsByUser, setGoalsByUser] = useState({
    tc: 0,
    tm: 0,
    total: 0,
  });

  const [goalsByRetailer, setGoalsByRetailer] = useState({
    tc: 0,
    tm: 0,
    total: 0,
  });

  useEffect(() => {
    try {
      if (cashdesk) {
        getTicketSummaries({
          cashDeskId: cashdesk.id,
          cashDeskOpenDateId: cashdesk.cashDeskOpenDate.id,
        }).then((res) => {
          setGoalsByRetailer({ ...res?.ticketSummariesByRetailer });
          setGoalsByUser({ ...res?.ticketSummariesByCashDesk });
        });
      }
    } catch (error) {
      if (window.Android) {
        console.log(`error on GoalStatistics -> ${JSON.stringify(error)}`);
      } else {
        console.log(`error on GoalStatistics -> ${error}`);
      }
    }
  }, [cashdesk]);

  useEffect(() => {
    const messageBus = MessageBusService.GetInstance();

    try {
      if (!orderSummariesCashdesk) {
        console.log("Subscribe order.ticket.summaries.cashdesk");
        orderSummariesCashdesk = messageBus.Subscribe(
          "order.ticket.summaries.cashdesk",
          (ch, payload) => {
            setGoalsByUser({ ...payload });
          }
        );
      }
    } catch (error) {
      if (window.Android) {
        console.log(`error on GoalStatistics -> ${JSON.stringify(error)}`);
      } else {
        console.log(`error on GoalStatistics -> ${error}`);
      }
    }

    return () => {
      if (orderSummariesCashdesk) {
        orderSummariesCashdesk = null;
        console.log("Unsubscribe order.ticket.summaries.cashdesk");
        messageBus.Unsubscribe("order.ticket.summaries.cashdesk");
      }
    };
  }, [goalsByUser]);

  useEffect(() => {
    const messageBus = MessageBusService.GetInstance();

    try {
      if (!orderSummariesRetailer) {
        console.log("Subscribe order.ticket.summaries.retailer");
        orderSummariesRetailer = messageBus.Subscribe(
          "order.ticket.summaries.retailer",
          (ch, payload) => {
            setGoalsByRetailer({ ...payload });
          }
        );
      }
    } catch (error) {
      if (window.Android) {
        console.log(`error on GoalStatistics -> ${JSON.stringify(error)}`);
      } else {
        console.log(`error on GoalStatistics -> ${error}`);
      }
    }

    return () => {
      if (orderSummariesRetailer) {
        orderSummariesRetailer = null;

        console.log("Unsubscribe order.ticket.summaries.retailer");
        messageBus.Unsubscribe("order.ticket.summaries.retailer");
      }
    };
  }, [goalsByRetailer]);

  return (
    <Container className="goal-statistics">
      <div className="trophy">
        <AiOutlineTrophy />
      </div>
      <DropDown>
        <div className="container">
          <div className="header">
            <span>Metas de Vendas Diárias</span>
          </div>
          <div className="content">
            <div className="column">
              <div className="row title">
                {/* TODO: Trocar nome do operador */}
                Operador - {cashdesk?.user}
              </div>
              <div className="column">
                <div className="row">
                  <span>Total de Cupons</span>
                  <span>{goalsByUser?.tc ?? ""}</span>
                </div>
                <div className="row">
                  <span>Ticket Médio</span>
                  <span>{currencyMask(goalsByUser?.tm ?? "")}</span>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="row title">
                {/* TODO: Trocar nome do operador */}
                {systemParams.storeName}
              </div>
              <div className="column">
                <div className="row">
                  <span>Total de Cupons</span>
                  <span>{goalsByRetailer?.tc ?? ""}</span>
                </div>
                <div className="row">
                  <span>Ticket Médio</span>
                  <span>{currencyMask(goalsByRetailer?.tm ?? "")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DropDown>
    </Container>
  );
};

export default GoalStatistics;
