import { removeFirstPositionArray } from "./removeFirstPositionArray";

import { getProductByCode } from '../../../services/productService';

export class NextStepReshopCampaignAfterSubmittingCart {
  #currentCampaigns;
  #productCode;
  #nextStep;

  /**
   * @param steps {{back: () => {}, next: () => {}}}
   * @param campaigns {{
   *  data: [{ProdutosRegra: [{CodigoProduto: String, ListaProdutos: [{String}], Qtde: Number}]}],
   *  status: boolean
   * }}
   */
  constructor(steps, campaigns) {
    this.steps = steps;
    this.campaigns = campaigns;
    this.initialization();
  }

  /**
   * @public
   * @return {Void}
   */
  initialization() {
    this._chooseTheNextStep();
  }

  /**
   * @param productRule {{CodigoProduto: String, ListaProdutos: [{String}], Qtde: Number}}
   * @private
   * @return {Void}
   */
  async _handleProductRules(productRule) {
    const firstCampaign = this.currentCampaigns.data[0];
    const removeRuleProduct = () => {
      this.currentCampaigns.data[0].ProdutosRegra = removeFirstPositionArray(firstCampaign.ProdutosRegra);
    }

    const rules = {
      uniqueProduct: () => {
        removeRuleProduct();
        this.productCode = +productRule.CodigoProduto;
      },
      multiProducts: async () => {
        const amount = productRule.Qtde;
        if (amount === 1)
          removeRuleProduct();
        else
          this.currentCampaigns.data[0].ProdutosRegra[0].Qtde = amount - 1;

        /**
         * @type {{product: [{mainProductCode: String}]}}
         */
        const responseProduct = await getProductByCode(productRule.ListaProdutos[0].toString());
        console.log(responseProduct, productRule.ListaProdutos, 'HERE');
        this.productCode = +responseProduct.product[0]?.mainProductCode;
      }
    };

    if (productRule.CodigoProduto !== null)
      rules.uniqueProduct();
    else
      await rules.multiProducts();
  }

  /**
   * @private
   * @return {() => {}}
   */
  _handleCampaign () {
    if (!!this.currentCampaigns.data.length === false || this.currentCampaigns.status === false)
      return this.steps.back;

    /**
     * @type {{ProdutosRegra: [{CodigoProduto: String, ListaProdutos: [{String}], Qtde: Number}]}}
     */
    const firstCampaign = this.currentCampaigns.data[0];

    if (!!firstCampaign.ProdutosRegra.length) {
      const firstProductRule = firstCampaign.ProdutosRegra[0];
      this._handleProductRules(firstProductRule);


      if (!!this.currentCampaigns.data[0].ProdutosRegra.length === false) {
        this.currentCampaigns = {
          ...this.currentCampaigns,
          data: removeFirstPositionArray(this.currentCampaigns.data)
        };
      }

      return this.steps.next
    } else
      return this.steps.back;
  }

  /**
   * @private
   * @return {Void}
   */
  _chooseTheNextStep() {
    this.currentCampaigns = this.campaigns;
    this.nextStep = this._handleCampaign();
  }

  /**
   * @public
   * @returns {() => {}}
   */
  get nextStep() {
    return this.#nextStep;
  }

  /**
   * @private
   * @param nextStep {() => {}}
   */
  set nextStep(nextStep) {
    this.#nextStep = nextStep;
  }

  /**
   * @public
   * @returns {{
   *  data: [{ProdutosRegra: [{CodigoProduto: String, ListaProdutos: [{String}], Qtde: Number}]}],
   *  status: boolean
   * }}
   */
  get currentCampaigns() {
    return this.#currentCampaigns;
  }

  /**
   * @private
   * @param currentCampaigns {{
   *  data: [{ProdutosRegra: [{CodigoProduto: String, ListaProdutos: [{String}], Qtde: Number}]}],
   *  status: boolean
   * }}
   */
  set currentCampaigns(currentCampaigns) {
    this.#currentCampaigns = currentCampaigns;
  }

  /**
   * @public
   * @returns {number}
   */
  get productCode () {
    return this.#productCode;
  }

  /**
   * @private
   * @param productCode {Number}
   */
  set productCode(productCode) {
    this.#productCode = productCode;
  }
}
