import React from "react";

class StepList extends React.Component {
  componentWillReceiveProps(props) {
    this.setState({ totalSteps: props.children.length - 1 }, () =>
      this.forceUpdate()
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      totalSteps: this.props.children.length - 1,
      progressBar: 0,
    };
  }

  goToStep = (step) => {
    this.setState({ currentStep: step });
  };

  goToPreviousStep = (callback) => {
    if (this.state.currentStep > 0)
      this.setState({ currentStep: this.state.currentStep - 1 });
    if (callback) callback();
  };

  goToNextStep = (callback) => {
    this.setState({ currentStep: this.state.currentStep + 1 });
    if (callback) callback();
  };

  goToJumpStep = (callback) => {
    if (callback) callback();
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  render() {
    const children = React.Children.map(this.props.children, (child, index) => {
      const { currentStep, totalSteps } = this.state;

      const onSubmit = !child.props.onSubmit ? () => {} : child.props.onSubmit;

      let selectedCurrentStep = currentStep;
      if (child.props.currentPage !== undefined)
        selectedCurrentStep = child.props.currentPage;

      const progressWidth = (100 / (totalSteps + 1)) * (selectedCurrentStep + 1);

      let displayNext = child.props.displayNext;
      if (displayNext === undefined)
        displayNext = currentStep < totalSteps;

      return React.cloneElement(child, {
        isActive: index === selectedCurrentStep,
        displayPrevious: selectedCurrentStep > 0 || child.props.onPrevious,
        displayNext,
        displaySubmit:
          currentStep === totalSteps ||
          (child.props?.displaySubmit ?? child.props.onSubmit),
        goToPreviousStep: () => this.goToPreviousStep(child.props.onPrevious),
        goToNextStep: () => this.goToNextStep(child.props.onNext),
        goToJumpStep: () => this.goToJumpStep(() => child.props.onJump({ goToStep: this.goToStep })),
        onSubmit: () => onSubmit({ goToStep: this.goToStep }),
        progressBar: progressWidth,
        currentPage: child.props.currentPage,
      });
    });

    return children;
  }
}

export default StepList;
