import React, { useEffect, useState } from "react";
import { Campaign } from "../../shared/components/modules";

const CampaignContainer = () => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const reshopCampaigns = window.localStorage.getItem("reshopCampanhas");

    if (reshopCampaigns) {
      setCampaigns(JSON.parse(reshopCampaigns));
    }
  }, []);

  return (
    <Campaign
      campaigns={campaigns}
      reshopUser={window.localStorage.getItem("cpf")}
    />
  );
};

export default React.memo(CampaignContainer);
