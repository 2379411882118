import { format } from "date-fns";

/**
 *
 * @param {Date|string} date
 * @param {string} formatDate
 * @return string
 */
export const formatDateHelper = (date, formatDate) => {
  return format(date, formatDate);
};
