import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import "./aaone/App.css";
import Page from "./aaone/pages/index";
import { Modal } from "./aaone/shared/components/modules";
import { ProviderContext } from "./context";
import { checkout } from "./aaone/actions/cart";
import { configQrCode } from "./services/qrCodeService";
import { ScrollContainer } from "./aaone/container/modules";
import { configSemParar } from "./services/semPararService";
import { useVersionAa } from "./aaone/contexts/versionContext";
import { cancelOperation } from "./services/verifyRedeemValueService";
import { useLayoutAa } from "./aaone/contexts/layout";
import { PdvOnePage } from "./pdv-one";
import { getHubEndpoint, isPDVOne } from "./aaone/configuration";
import { GlobalStyle } from "./pdv-one/styles/global";
import MessageBusService from "./services/MessageBusService";

const App = () => {
  const dispatch = useDispatch();
  const versionAa = useVersionAa();

  const [msgRef, setMsgRef] = useState(null);

  const [modalTimer, setModalTimer] = useState(false);
  let [timer, updateTimer] = useState(localStorage.getItem("timeModal"));
  const [activeModalReset, setActiveModalReset] = useState(false);
  const [callStartTimer, setCallStartTimer] = useState(false);

  const clearInfosInMemory = (param) => {
    param.forEach((item) => {
      window.localStorage.removeItem(item);
      window.sessionStorage.removeItem(item);
    });
  };

  const isActiveModalReset = (param) => {
    setActiveModalReset(param);
  };

  let timeout;
  let interval;

  const resetTimer = () => {
    if (callStartTimer === false) {
      setModalTimer(false);
      clearTimeout(timeout);
      clearInterval(interval);
      updateTimer(localStorage.getItem("timeModal"));
      startTimer(true);
      setCallStartTimer(true);
    }
  };
  const startTimer = () => {
    timeout = setTimeout(
      () => {
        setModalTimer(true);
        tick();
        let timerReset = localStorage.getItem("timeModal");
        timer = timerReset;
      },
      localStorage.getItem("paymentTransition") ? 300000 : 60000
    );
  };

  const tick = () => {
    interval = setInterval(() => {
      if (timer === 1) {
        cancelOperation(JSON.parse(localStorage.getItem("NsuForConsult")));
      }
      if (timer > 0) {
        updateTimer(timer--);
      } else {
        clearTimeout(timeout);
        clearInterval(interval);
        dispatch(checkout());
        setModalTimer(false);
        if (window.desktopApp) {
          window.desktopApp.publish("machine.devices.tef.undo", {});
        }
        window.localStorage.removeItem("inRouteToPayment");
      }
    }, 1000);

    return;
  };

  const modalStyleBlackout = {
    width: "100%",
    height: "100vh",
    position: "absolute",
    zIndex: "25",
    background: "rgba(0, 0, 0, 0.82)",
  };

  window.onload = function () {
    //FIXME - Ocorre um erro ao router-dom
    // if (!window.location.hash) {
    //   window.location = window.location + "#carregar";
    //   window.location.reload();
    // }
  };

  const refresh = () => {
    subscribeHandles();
    setMsgRef(msgRef);
    window.onload();
  };

  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 5000);
    clearInfosInMemory(["teste"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeModalReset) {
      document.addEventListener("click", resetTimer);
      resetTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModalReset, localStorage.getItem("paymentTransition")]);

  useEffect(() => {
    const wsConnectDelay = setTimeout(() => {
      MessageBusService.GetInstance().CreateConnection(
        getHubEndpoint() + "/poshub"
      );
    }, 1000);

    return () => {
      clearTimeout(wsConnectDelay);
    };
  }, []);

  const subscribeHandles = () => {
    if (window.desktopApp) {
      //Mandatory Start: Load

      window.desktopApp.publish("machine.devices.tef.getTypeTef", {});

      window.desktopApp.subscribe(
        "machine.devices.desktop.config",
        (_, data) => {
          window.sessionStorage.setItem("AA_Config", JSON.stringify(data));
        }
      );

      // Start QR Code
      window.desktopApp.subscribe(
        "machine.devices.qrconfig",
        (channel, data) => {
          configQrCode().then((res) => {
            if (res) {
              window.desktopApp.publish(
                "machine.devices.qrconfig.completed",
                res?.objectPDVQRLinx
              );
            } else {
              window.desktopApp.publish("machine.devices.qrconfig.completed", {
                Enabled: false,
              });
            }
          });
          console.log("machine.devices.qrconfig", data);

          configSemParar().then((res) => {
            if (res) {
              window.desktopApp.publish(
                "machine.devices.sempararconfig.completed",
                res.objectSemParar
              );
            } else {
              window.desktopApp.publish("machine.devices.qrconfig.completed", {
                Enabled: false,
              });
            }
          });
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.show.cancelButton",
        (channel, data) => {
          let output = { channel, ...data };
          console.log("machine.devices.show.cancelButton", output);
        }
      );

      //TEF Interact
      window.desktopApp.subscribe(
        "machine.devices.tef.menuoptions",
        (channel, data) => {
          let output = { channel, ...data };

          let selectedIndex = 0;
          window.desktopApp.publish(
            "machine.devices.tef.menuoptions.completed",
            {
              key: data.options[selectedIndex].key,
            }
          );

          console.log("machine.devices.tef.menuoptions", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.creditcard.completed",
        (channel, data) => {
          let output = { channel, ...data };

          console.log("machine.devices.tef.creditcard.completed", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.debitcard.completed",
        (channel, data) => {
          let output = { channel, ...data };

          console.log("machine.devices.tef.debitcard.completed", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.payment.error",
        (channel, data) => {
          let output = { channel, ...data };
          // setMsgRef(JSON.stringify(output.error, null, " ")+ "\n\n" + msgRef);

          console.log("machine.devices.payment.error", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.creditcard.getBrand.completed",
        (channel, data) => {
          let output = { channel, ...data };

          console.log(
            "machine.devices.tef.creditcard.getBrand.completed",
            output
          );
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.debitcard.getBrand.completed",
        (channel, data) => {
          let output = { channel, ...data };

          console.log(
            "machine.devices.tef.debitcard.getBrand.completed",
            output
          );
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.confirm.completed",
        (channel, data) => {
          let output = { channel, ...data };

          console.log("machine.devices.tef.confirm.completed", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.undo.completed",
        (channel, data) => {
          let output = { channel, ...data };

          console.log("machine.devices.tef.undo.completed", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.undo",
        (channel, data) => {
          let output = { channel, ...data };

          console.log("machine.devices.tef.undo", output);
        }
      );



      window.desktopApp.subscribe(
        "machine.devices.desktop.error",
        (channel, data) => {
          let output = { channel, ...data };

          console.log("machine.devices.desktop.error", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.printer.completed",
        (channel, data) => {
          var output = { channel, ...data };
          // msgRef.value = JSON.stringify(output, null, " ")+ "\n\n" + msgRef.value;

          console.log("machine.devices.printer.completed", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.printerpdf.completed",
        (channel, data) => {
          var output = { channel, ...data };

          console.log("machine.devices.printerpdf.completed", output);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.printOverTCPSocket.error",
        (channel, data) => console.log("machine.devices.printOverTCPSocket.error", { channel, ...data })
      );

      window.desktopApp.subscribe(
        "machine.devices.printOverTCPSocket.completed",
        (channel, data) => console.log("machine.devices.printOverTCPSocket.completed", { channel, ...data })
      );

      //Connect Devices
      window.desktopApp.publish("machine.devices.wsConnected", {});
    }

    localStorage.setItem("storageContent", 1);

    if (!window.desktopApp && window?.mobileConfig) {
      versionAa.hide();

      const mobileConfigData = window.mobileConfig;

      let newConfig = { danfe: {} };

      if (mobileConfigData.FISCAL_TYPE) {
        newConfig.danfe = {
          ...newConfig.danfe,
          fiscalType: mobileConfigData.FISCAL_TYPE,
        };
      }

      if (mobileConfigData.TP_AMBIENTE) {
        newConfig.danfe = {
          ...newConfig.danfe,
          tpAmb: mobileConfigData.TP_AMBIENTE,
        };
      }

      if (mobileConfigData.MIDE_URL) {
        newConfig.danfe = {
          ...newConfig.danfe,
          MIDeUrl: mobileConfigData.MIDE_URL,
        };
      }

      if (mobileConfigData.SAT_URL) {
        newConfig.danfe = {
          ...newConfig.danfe,
          sat: {
            roundCalculations: mobileConfigData.ROUNDCALCULATIONS,
            cnpjHomolog: mobileConfigData.CNPJHOMOLOG,
            url: mobileConfigData.SAT_URL,
          },
        };
      }

      if (mobileConfigData.PRINT_SIZE) {
        newConfig.printSize = mobileConfigData.PRINT_SIZE;
      }

      const configStringifyData = JSON.stringify({
        ...mobileConfigData,
        ...newConfig,
      });

      window.sessionStorage.setItem("AA_Config", configStringifyData);
      window.localStorage.setItem("AA_ConfigDevice", configStringifyData);

      window.localStorage.setItem("AAMobile", true);
    }
  };

  const timerModal = {
    timer,
  };

  console.log(timerModal);

  //TODO - Deletar
  // const handleSaveConfigTablet = useCallback((e) => {
  //   e.preventDefault();

  //   const formData = new FormData(e.target);

  //   const data = Object.fromEntries(formData.entries());

  //   const isDataEmpty = !Object.values(data).every(value => value !== null && value.trim() !== '');

  //   //TODO - Criar uma tratativa melhor para o mobile, pois essas informações irá descer das configurações do mobile.
  //   // if(!window?.mobileConfig && isDataEmpty){
  //   //   //NOTE - melhorar para um componente
  //   //   alert("Preencha os campos");

  //   //   return;
  //   // }

  //   const { hostname } = new URL(window.location.href);

  //   const DISABLED_RE_SHOP = data.reshop === "desativado";

  //   //TODO - Melhorar Config de acordo com o TODO anterior
  //   window.localStorage.setItem(
  //     "AA_ConfigDevice",
  //     JSON.stringify(window?.mobileConfig ?? {
  //       IP_SERVIDOR: hostname,
  //       TERMINAL_ID: data.terminalId,
  //       RESHOP: DISABLED_RE_SHOP ? null : data.reshop,
  //       DISABLED_RE_SHOP,
  //       SHOW_PRISMA_PAGE: data.prisma === "on",
  //       SHOW_PAGER_PAGE: data.pager === "on",
  //       SHOW_TELL_INFO: data.tellInfo === "on",
  //       SHOW_FULL_VALUE: data.fullValue === "on",
  //       IS_SHOW_YOUR_NAME: data.yourName === "on",
  //       SHOW_OPTIONTOEAT_PAGE: data.optionToEat === "ambos" ? true : String(data.optionToEat).toUpperCase(),
  //       SERIE_NFCE: data.serieNFCe
  //     })
  //   );

  //   window.localStorage.setItem("AAMobile", true);
  //   document.location.reload();
  // }, [])

  //console.log(window.mobileConfig);

  const ModalInactivity = () => {
    const { t } = useTranslation();
    const { getTranslateName } = useLayoutAa();

    const inactivityTitleText =
      getTranslateName(t, "inactivity.title") || "VOCÊ AINDA ESTÁ AI?";
    const inactivityDescriptionText =
      getTranslateName(t, "inactivity.description") ||
      "O pedido atual será cancelado.";
    const inactivityButtonConfirmText =
      getTranslateName(t, "inactivity.buttons.confirm") ||
      "continuar esse pedido";
    const inactivityButtonCancelText =
      getTranslateName(t, "inactivity.buttons.cancel") || "começar novo pedido";

    if (modalTimer && activeModalReset) {
      return (
        <ScrollContainer forWaitingModal>
          <div
            className="waiting-modal"
            style={modalStyleBlackout}
            onClick={() => setModalTimer(false)}
          >
            <Modal
              mClassName="automatic-reset"
              mTitle={inactivityTitleText}
              mSubtitle={inactivityDescriptionText}
              textAlternate={timer}
              mTextBtnPrimary={inactivityButtonConfirmText}
              mTextBtnSecondary={
                <div>
                  <span className="button-secondaryAAone ">{`${inactivityButtonCancelText} `}</span>
                  <span className="timerSecondsRagazzo">{`(${timer})`}</span>
                </div>
              }
              mBtnPrimarClassName="primary button-primaryModalRagazzo button-primaryBobs"
              mBtnSecondaryClassName="outline-primary"
            />
          </div>
        </ScrollContainer>
      );
    }

    return <></>;
  };

  return (
    <div className="App">
      <ProviderContext>
        {isPDVOne ? (
          <>
            <GlobalStyle />
            <PdvOnePage />
          </>
        ) : (
          <>
            <ModalInactivity />

            <Page msgRef={msgRef} isActiveModalReset={isActiveModalReset} />
          </>
        )}
      </ProviderContext>
    </div>
  );
};

export default App;
