import React, { Component } from "react";
import classNames from "classnames";

import Card from "../../Card";
import Table from "../../table";
import PropTypes from "prop-types";
import { Container, PanelStatusPdvWrapper } from "./style";
import { isSmartPos } from "../../../../aaone/configuration";
import { ItemsPanelStatusPdv } from "./mobile";

class PanelStatusPdv extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: "DATA CAIXA",
          cols: [{ label: { ref: "openingDateFormatted" } }],
          className: "list-format",
        },
        {
          title: "PDV",
          cols: [{ label: { ref: "formattedPosId" } }],
          className: "list-format",
        },
        {
          title: "ABERTURA",
          cols: [{ label: { ref: "openingCount" } }],
          className: "list-format",
        },
        {
          title: "DATA REDUÇÃO",
          cols: [{ label: { ref: "reducingDateFormatted" } }],
          className: "list-format",
        },
        {
          title: "STATUS",
          cols: [{ label: { ref: "status" } }],
          className: "list-format",
        },
      ],
    };
  }

  static propTypes = {
    title: PropTypes.string,
    handleClose: PropTypes.func,
    list: PropTypes.array,
  };

  static defaultProps = {
    title: null,
    handleClose: null,
    list: [],
  };

  handleLineClick = (line) => {
    this.props.onHandleSelectPdv(line.item);
  };

  render() {
    const { title, handleClose, list } = this.props;
    const columns = this.state.columns;

    const itemsSerializedSmartPos = list.map((item) => {
      const columnSerialized = columns.map((currentColumn) => ({
        label: currentColumn.title ?? null,
        key: currentColumn?.cols[0]?.label?.ref ?? null,
      }));

      const returnSerialized = columnSerialized.map((currentItemList) => ({
        label: currentItemList.label,
        children: item[currentItemList.key],
      }));

      return {
        onClick: () => {
          this.props.onHandleSelectPdv(item);
        },
        list: returnSerialized,
      };
    });

    return (
      <Container className={classNames({ isSmartPos })}>
        <Card
          id="card"
          title={title}
          closeEnable
          handleClose={handleClose}
          handleHidden={() => this.toggleCard(false)}
        >
          <PanelStatusPdvWrapper>
            {isSmartPos ? (
              <ItemsPanelStatusPdv items={itemsSerializedSmartPos} />
            ) : (
              <Table
                columns={columns}
                items={list}
                lineClick={this.handleLineClick}
                handleHeader={this.handleHeader}
                typeList="notes-list"
                hand
                scrollable
              />
            )}
          </PanelStatusPdvWrapper>
        </Card>
      </Container>
    );
  }
}

export default PanelStatusPdv;
