import styled, { css } from "styled-components";
import { isSmartPos } from "../../../aaone/configuration";

export const CancelOrderListWrapper = styled.div`
  margin: ${({ isSmartPos }) => (!isSmartPos ? "20px 10px" : "")};
  height: calc(100vh - 200px);

  .react-datepicker__aria-live {
    display: none;
  }
`;

export const TableContainer = styled.div`
  height: 85%;
  overflow: auto;
  padding: 5px;
`;

export const InputFilters = styled.div`
  display: flex;
  flex-direction: ${isSmartPos ? "column" : "row"};
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
  margin-bottom: ${isSmartPos ? "8px" : "30px"};

  label {
    margin-left: 10px;
  }

  ${({ isSmartPos }) =>
    !isSmartPos
      ? css`
          .react-datepicker-popper {
            max-width: 500px;
            width: 76%;
            z-index: 50;
          }

          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            color: #000;
            display: inline-block;
            width: 1.7rem;
            line-height: 2.7rem;
            text-align: center;
          }
        `
      : null};

  input {
    /* width: "100%"; */
    width: ${isSmartPos ? "140px" : "100%"};
    height: 30px;
    padding-left: 10px;
    margin-bottom: ${isSmartPos ? "5px" : ""} !important;

    &:nth-child(2) {
      margin-left: 10px;
    }
  }

  .button {
    width: ${isSmartPos ? "100%" : ""} !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #411e5a;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 3px 6px #00000033;
    color: white;
    font-size: ${isSmartPos ? "12px" : "14px"} !important;
    padding: 7px 20px;
    margin-left: 20px;
    margin-top: 20px;
    outline: none;

    .material-icons {
      color: #fff;
      font-size: 18px;
    }
  }

  .refresh {
    margin: -5px 0 0 10px;
    color: var(--highlight-color);
    font-size: 36px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    i {
      &:hover {
        cursor: pointer;
        color: #ffb201;
      }
      padding-bottom: 4px;
    }
  }

  &.isSmartPos {
    margin-bottom: 8px;
  }
`;
