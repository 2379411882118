import React, { useState, useEffect } from "react";
import { Content, Button, Keyboard } from "../../shared/components/modules";

import logo from "../../assets/ragazo-mais.svg";
import logo2 from "../../assets/habibers.png";
import logoDefault from "../../assets/habibers-default.png";
import logoBobs from "../../assets/logobobs.png";

import { loginfidelidade } from "../../../services/pointsService";
import { getThemeSettings } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { useTranslation } from "react-i18next";
import { useViewDispatch } from "../../../context";

const FidelidadeContainer = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const { isLayoutAAOne, getTranslateName, isLayout, getScreenTitleBySlug, getScreenImageBySlug } = useLayoutAa();

  const describeCpfLoyaltyText =
    getTranslateName(t, "describe_cpf_loyalty_text") ||
    getScreenTitleBySlug("FIDELIDADE_DIGITE_CPF") ||
    DEFAULT_LOCAL_TEXTS.describe_cpf_loyalty_text;

  const btnFidelity = getTranslateName(t, "btnFidelity") || "Acessar Fidelidade";
  const btnNoThanks = getTranslateName(t, "buttons.noThanks") || "Não, obrigado";

  const imageFid = () => {
    return isLayout("RAGAZZOEXPRESS")
      ? logo
      : isLayout("BOBS")
      ? logoBobs
      : !isLayout("HABIBS")
      ? logoDefault
      : logo2;
  };

  const headerImageSrc = isLayoutAAOne ? getScreenImageBySlug("FIDELIDADE_DIGITE_CPF", "LOGOCPF") : imageFid();

  useEffect(() => {
    loginfidelidade();
  });

  return (
    <>
      <Content
        bgColor="background-fidelity hasIMG"
        contentChildren={true}
        className="full"
        hasImg={true}
        srcImg={headerImageSrc}
      >
        <Keyboard
          classN="fidelidade"
          handleClick={() => viewDispatch("step.points")}
          textButton={
            getThemeSettings?.text?.button_access_loyalty_text
              ? getThemeSettings?.text?.button_access_loyalty_text
              : btnFidelity
          }
          textLabel={describeCpfLoyaltyText}
          isFidelity={true}
        />
        <footer
          className="buttonFidelityRecuse footer template-columns-1"
          style={{ width: "75%", height: "128px" }}
        >
          <Button
            text={
              getThemeSettings?.text?.button_no_thanks_loyalty_text
                ? getThemeSettings?.text?.button_no_thanks_loyalty_text
                : btnNoThanks
            }
            buttonUIType="outline-primary"
            buttonUI={"normal"}
            handleClick={() => {
              viewDispatch("step.home");
            }}
          />
        </footer>
      </Content>
    </>
  );
};

export default FidelidadeContainer;
