import React, { createContext, useCallback, useMemo, useState } from "react";
import axios from "axios";
import qs from "qs";
import {
  getClientEndpointReshop,
  getCodLoja,
  getConsultFidelityReshop,
  getProductsAll,
  getReshopEndpoint,
  getTerminalID,
} from "../../../aaone/configuration";
import { authentication } from "../../../services/authServices";
import { apiProducts, apiReshop } from "../../../services/api";
import { isLayoutUtil } from "../../../aaone/shared/utils/isLayout";
import {
  CART_COUPONS_KEY_NAME,
  CPF_INVOICE_KEY_NAME,
  CPF_RESHOP_KEY_NAME,
  DATA_USER_AUTH_KEY_NAME,
  GET_ACCESS_TOKEN_KEY_NAME,
  PDV_COD_LOJA_KEY_NAME,
  RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME,
  RESHOP_AUTH_DATA_KEY_NAME,
  RESHOP_CREDITED_POINTS,
  RESHOP_CUSTOMER_DATA_KEY_NAME,
  RESHOP_DISCOUNTS,
  RESHOP_NSU_KEY_NAME,
  RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME,
  RESHOP_TOTAL_DISCOUNT_KEY_NAME,
  RESHOP_TRANSACTION_ID_KEY_NAME,
  TOKEN_USER_AUTH_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { getUserAuthDataHelper } from "../../helpers/getUserAuthDataHelper";
import { hasCheckInternetConnectedHelper } from "../../helpers/hasCheckInternetConnectedHelper";
import { consultCampaign } from "../../../services/pointsService";
import { fidelity } from "../../../services/fidelityService";
import { getDiscountsReshopService } from "../../../services/reshop/getDiscountsReshopService";
import {
  getReshopAuthDataUnico,
  reshopAuthDefaultEmpty,
} from "../../helpers/getReshopAuthDataUnico";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [authProfile, setAuthProfile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = sessionStorage.getItem(TOKEN_USER_AUTH_KEY_NAME);
    const isLogged = !!token;

    if (isLogged) {
      const bearerToken = token.replace("Bearer", "").trim();
      sessionStorage.setItem(TOKEN_USER_AUTH_KEY_NAME, `Bearer ${bearerToken}`);

      apiProducts.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
    }

    return isLogged;
  });

  const [codLoja, setCodLoja] = useState("");
  const [cpfFidelity, setCpfFidelity] = useState(() => {
    const currentCpf = sessionStorage.getItem(CPF_RESHOP_KEY_NAME);

    return currentCpf;
  });
  const [clientReshop, setClientReshop] = useState(null);
  const [isAuthenticatedReshop, setIsAuthenticatedReshop] = useState(false);
  const [reshopAuth, setReshopAuth] = useState({
    username: "",
    password: "",
  });
  const [reshopOptionalCampaigns, setReshopOptionalCampaigns] = useState([]);
  const [reshopCampaigns, setReshopCampaigns] = useState([]);
  const [reshopProductsFidelity, setReshopProductsFidelity] = useState([]);

  const [reshopTotalPrice, setReshopTotalPrice] = useState(() => {
    const discountsReshop = getDiscountsReshopService();

    return discountsReshop?.TotalDesconto
      ? Number(discountsReshop.TotalDesconto)
      : 0;
  });

  const [userAuthData, setUserAuthData] = useState(getUserAuthDataHelper);
  const [reshopCostumerConsultant, setReshopCostumerConsultant] = useState(null);

  const profile = useMemo(() => authProfile, [authProfile]);
  const serializeBearerToken = (tokenValue) =>
    tokenValue?.replace("Bearer", "").trim();

  const fidelityToken = async () => {
    const reshopAuthData = getReshopAuthDataUnico();

    const endPointReshop = getReshopEndpoint();
    const endPointReshopClient = getClientEndpointReshop();
    const url = endPointReshopClient
      ? `${getProductsAll()}/api/fidelidade/token`
      : `${endPointReshop}/token`;

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      // Adicionar estes headers somente se for utilizar a API para acessar o client
      ...(endPointReshopClient && {
        reshopClientBaseURL: endPointReshopClient,
        reshopBaseURL: endPointReshop,
      }),
    };

    const response = await axios.post(
      url,
      qs.stringify({
        username: reshopAuthData.username,
        password: reshopAuthData.password,
        grant_type: "password",
      }),
      { headers }
    );

    const tokenReshop = serializeBearerToken(response.data.access_token);

    apiReshop.defaults.headers.common.Authorization = tokenReshop;

    return response;
  };

  const signIn = useCallback(async (credentials) => {
    try {
      const { username: login, password } = credentials;

      const { user, token } = await authentication(login, password);

      const bearerToken = serializeBearerToken(token);
      const terminalId = getTerminalID();

      setAuthProfile(user);
      setUserAuthData(user);
      setIsAuthenticated(() => {
        sessionStorage.setItem(
          TOKEN_USER_AUTH_KEY_NAME,
          `Bearer ${bearerToken}`
        );
        sessionStorage.setItem(DATA_USER_AUTH_KEY_NAME, JSON.stringify(user));

        apiProducts.defaults.headers.common.terminalid = terminalId;

        return true;
      });

      const { data: configsPdv } = await apiProducts.get("/api/systemParameters", {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          terminalid: terminalId,
        },
      });

      const storeId = String(configsPdv.objectSystemParameters.storeID || "");
      const authData = {
        username: configsPdv?.objectSystemParameters.defaultUserUnico,
        password: configsPdv?.objectSystemParameters.defaultPasswordUnico,
      };

      sessionStorage.setItem(
        PDV_COD_LOJA_KEY_NAME,
        !isLayoutUtil("RAGAZZOEXPRESS") ? storeId : Number(storeId) + 8000
      );
      sessionStorage.setItem(
        RESHOP_AUTH_DATA_KEY_NAME,
        JSON.stringify(authData)
      );

      setCodLoja(storeId);
      setReshopAuth(authData);
    } catch (error) {
      throw new Error(error?.message || error);
    }
  }, []);

  const signOut = useCallback(() => {
    sessionStorage.removeItem(CPF_RESHOP_KEY_NAME);
    sessionStorage.removeItem(TOKEN_USER_AUTH_KEY_NAME);
    sessionStorage.removeItem("@PDVOne:reshop_customer_data");

    setIsAuthenticated(false);
  }, []);

  const signInReshop = async (credentials) => {
    const INVALID_CPF_MESSAGE = "CPF inválido ou não cadastrado!";

    try {
      const reshopAuthData = getReshopAuthDataUnico();

      if (
        reshopAuthData.username === reshopAuthDefaultEmpty.username ||
        reshopAuthData.username === reshopAuthDefaultEmpty.username
      ) {
        throw new Error("Usuário e senha do reshop sem configuração!");
      }

      const fidelityResponseData = await fidelityToken();
      const codigoLoja = getCodLoja();
      // Passo 0 para retornar apenas as Campanhas Opcionais
      const documentoFidelidade = credentials?.cpf ?? 0;

      const baseUrl = getConsultFidelityReshop();
      const { data } = await axios.get(
        `${baseUrl.replace(
          "/api/fidelidade",
          ""
        )}/api/fidelidade/ConsultaCliente`,
        {
          headers: {
            Authorization: `Bearer ${fidelityResponseData.data.access_token}`,
          },
          params: {
            documentoFidelidade,
            codigoLoja,
            considerarTransacoesPendentesSaldo: true,
          },
        }
      );

      if (credentials?.isConsult) {
        if (!data?.ClienteCadastrado && !data?.Nome && documentoFidelidade !== 0) {
          throw new Error(INVALID_CPF_MESSAGE);
        }

        setReshopCostumerConsultant(data)
      } else {
        apiReshop.defaults.headers.common.Authorization =
          fidelityResponseData.data.access_token;

        setReshopOptionalCampaigns(data.CampanhasOpcionais ?? []);

        if (data?.ClienteCadastrado && data?.Nome?.length > 0) {
          setCpfFidelity(credentials.cpf);
          setClientReshop(data);
          setIsAuthenticatedReshop(true);

          sessionStorage.setItem(CPF_RESHOP_KEY_NAME, credentials.cpf);
          sessionStorage.setItem(
            RESHOP_CUSTOMER_DATA_KEY_NAME,
            JSON.stringify(data)
          );

          sessionStorage.setItem(
            GET_ACCESS_TOKEN_KEY_NAME("reshop"),
            `Bearer ${fidelityResponseData.data.access_token}`
          );

          const campaignsResponse = await consultCampaign(credentials.cpf);
          const fidelityResponse = await fidelity(credentials.cpf, codigoLoja);

          setReshopCampaigns(campaignsResponse);
          sessionStorage.setItem(
            RESHOP_REEDEMABLE_PRODUCTS_KEY_NAME,
            JSON.stringify(fidelityResponse?.Products)
          );
          setReshopProductsFidelity(fidelityResponse?.Products ?? []);

          return data;
        } else if (documentoFidelidade !== 0) {
          throw new Error(INVALID_CPF_MESSAGE);
        }
      }
    } catch (error) {
      !credentials?.isConsult && sessionStorage.removeItem(CPF_RESHOP_KEY_NAME);

      const hasConnection = await hasCheckInternetConnectedHelper();

      if (!hasConnection) {
        throw new Error(
          "Não foi possível consultar o CPF informado, ocorreu uma falha de conexão com o Reshop!"
        );
      } else {
        throw new Error(error?.message || INVALID_CPF_MESSAGE);
      }
    }
  };

  const signOutReshop = () => {
    sessionStorage.removeItem(CPF_RESHOP_KEY_NAME);
    sessionStorage.removeItem(CPF_INVOICE_KEY_NAME);
    sessionStorage.removeItem(CART_COUPONS_KEY_NAME);
    sessionStorage.removeItem(RESHOP_CUSTOMER_DATA_KEY_NAME);
    sessionStorage.removeItem(RESHOP_TOTAL_DISCOUNT_KEY_NAME);
    sessionStorage.removeItem(RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME);
    sessionStorage.removeItem(RESHOP_DISCOUNTS);
    sessionStorage.removeItem(RESHOP_NSU_KEY_NAME);
    sessionStorage.removeItem(RESHOP_CREDITED_POINTS);
    sessionStorage.removeItem(RESHOP_TRANSACTION_ID_KEY_NAME);

    setCpfFidelity(null);
    setClientReshop(null);
    setIsAuthenticatedReshop(false);
    setReshopTotalPrice(0);
    setReshopCampaigns([]);
    setReshopProductsFidelity([]);
    setReshopCostumerConsultant(null);
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      profile,
      signIn,
      signOut,
      fidelityToken,
      userAuthData,
      reshop: {
        config: {
          ...reshopAuth,
          codLoja,
        },
        totalPrice: reshopTotalPrice,
        setTotalPrice: setReshopTotalPrice,
        isAuthenticated: isAuthenticatedReshop,
        data: clientReshop,
        cpfFidelity,
        productsFidelity: reshopProductsFidelity,
        campaigns: reshopCampaigns,
        optionalCampaigns: reshopOptionalCampaigns,
        signIn: signInReshop,
        signOut: signOutReshop,
        costumerConsultant: reshopCostumerConsultant,
        setCostumerConsultant: setReshopCostumerConsultant,
      },
    }),
    [
      isAuthenticated,
      reshopTotalPrice,
      setReshopTotalPrice,
      fidelityToken,
      userAuthData,
      profile,
      reshopOptionalCampaigns,
      signIn,
      signOut,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
