import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

const CellEditorCustom = forwardRef((props, ref) => {
  const {
    keyPress = "",
    charPress = "",
    value: defaultValue = 0,
    data,
  } = props;

  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (keyPress === KEY_BACKSPACE || keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (charPress) {
      // if a letter was pressed, we start with the letter
      startValue = charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(defaultValue);

      if (keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();

  const [value, setValue] = useState(initialState.value);
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
    initialState.highlightAllOnFocus
  );
  const refInput = useRef(null);

  const cancelBeforeStart =
    props.charPress && "1234567890".indexOf(props.charPress) < 0;

  const isLeftOrRight = (event) => {
    return [37, 39].indexOf(event.keyCode) > -1;
  };

  const getCharCodeFromEvent = (event) => {
    event = event || window.event;
    return typeof event.which === "undefined" ? event.keyCode : event.which;
  };

  const isCharNumeric = (charStr) => {
    return !!/\d/.test(charStr);
  };

  const isKeyPressedNumeric = (event) => {
    const charCode = getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return isCharNumeric(charStr);
  };

  const deleteOrBackspace = (event) => {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  };

  const finishedEditingPressed = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return charCode === KEY_ENTER || charCode === KEY_TAB;
  };

  const expandRowGroup = (rowNode) => {
    return new Promise(function (resolve) {
      if (rowNode.group && !rowNode.expanded) {
        rowNode.setExpanded(true);
        props.api.ensureIndexVisible(rowNode.rowIndex);

        setTimeout(() => {
          resolve();
        }, 300);
      }
      resolve();
    });
  };

  const getNextRowNodeEditable = async (rowIndex) => {
    const nextRowNode = props.api.getDisplayedRowAtIndex(rowIndex);
    if (!nextRowNode) return null;

    if (
      nextRowNode.group &&
      nextRowNode.allLeafChildren.find(
        (node) => !node.group && !node.data.isReadOnly
      )
    ) {
      await expandRowGroup(nextRowNode);
    }

    if (nextRowNode.group || nextRowNode?.data?.isReadOnly) {
      return await getNextRowNodeEditable(nextRowNode.rowIndex + 1);
    }

    return nextRowNode;
  };

  const handleNextRowNodeEdit = async () => {
    var currentCell = props.api.getFocusedCell();
    let rowIndexToEditCell = currentCell.rowIndex + 1;

    const nextRowNode = await getNextRowNodeEditable(rowIndexToEditCell);

    if (!nextRowNode) {
      const cellEditorLastElementEvent = new CustomEvent(
        "lastPaymentsGridElement"
      );
      window.dispatchEvent(cellEditorLastElementEvent);

      return null;
    }

    rowIndexToEditCell = nextRowNode.rowIndex;

    props.api.stopEditing();
    props.api.clearFocusedCell();

    if (typeof rowIndexToEditCell === "number") {
      setTimeout(() => {
        props.api.ensureIndexVisible(rowIndexToEditCell);
        props.api.startEditingCell({
          rowIndex: rowIndexToEditCell,
          colKey: currentCell.column.colId,
        });
      }, 50);
    }

    return rowIndexToEditCell;
  };

  const onKeyDown = async (event) => {
    if (isLeftOrRight(event) || deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }

    if (finishedEditingPressed(event)) {
      await handleNextRowNodeEdit();
    }

    if (event.detail?.inputName === props.data.id) {
      setValue(
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(event.detail?.inputValue.replace(/\D/g, "") / 100)
      );
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyboardValueChanged", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyboardValueChanged", onKeyDown);
    };
  }, [onKeyDown]);

  useImperativeHandle(ref, () => {
    return {
      afterGuiAttached() {
        // get ref from React component
        const eInput = refInput.current;
        eInput.focus();
        if (highlightAllOnFocus) {
          eInput.select();

          setHighlightAllOnFocus(false);
        } else {
          // when we started editing, we want the carot at the end, not the start.
          // comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the carot
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = eInput.value ? eInput.value.length : 0;
          if (length > 0) {
            eInput.setSelectionRange(length, length);
          }
        }
      },

      getValue() {
        return value.replace(/\D/g, "") / 100;
      },

      isCancelBeforeStart() {
        return cancelBeforeStart;
      },

      // will reject the number if it greater than 1,000,000
      // not very practical, but demonstrates the method.
      isCancelAfterEnd() {
        return value > 1000000;
      },

      nextRowNodeToEdit() {
        return handleNextRowNodeEdit();
      },
    };
  });

  return (
    <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
      <input
        ref={refInput}
        value={value}
        name={data.id}
        inputMode="numeric"
        onChange={(event) => {
          const valueUpdated = event.target.value.replace(/\D/g, "") / 100;

          setValue(
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valueUpdated)
          );

          const cellEditorEvent = new CustomEvent(
            "cellEditorInputValueChanged",
            { detail: { inputName: data.id, inputValue: valueUpdated } }
          );
          window.dispatchEvent(cellEditorEvent);
        }}
        style={{ height: 26 }}
        className="ag-input-field-input ag-text-field-input"
      />
    </div>
  );
});

export default CellEditorCustom;
