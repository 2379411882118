import styled from "styled-components";

export const FlavorSelectListWrapper = styled.ul`
  list-style: none;
  position: relative;

  li {
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 4fr auto;
    align-items: center;
  }
`;

export const SpanPrice = styled.span`
  font-size: 1rem;
  color: var(--color-background-cart);
`;

export const DivSpace = styled.div`
  width: 62px;
`;

export const ContentButtons = styled.div`
  display: flex;
`;
