import React, { useEffect, useState } from "react";
import { authentication } from "../../../services/authServices";
import { getUsers } from "../../../services/usersServices";
import Button from "../Button";
import Selector from "../selector";
import {
  CardBody,
  CardFooter,
  CardFormRow,
  CardHeader,
  CardWrapper,
  Modal,
} from "./style";
import classNames from "classnames";
import { isSmartPos } from "../../../aaone/configuration";

const LoginModal = ({ handleCancel, handleSubmit, selectedOperator }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState(selectedOperator);
  const [inputPassword, setInputPassword] = useState("");
  const [hasAuthenticationError, setHasAuthenticationError] = useState(false);

  useEffect(() => {
    getUsers().then((res) => {
      let users = res.users.filter((user) => user.isManager);

      setIsLoading(false);
      setUserList(users);
    });
  }, []);

  const onSubmitData = () => {
    try {
      setHasAuthenticationError(false);

      authentication(user.login, inputPassword, "PDVOne", "", false)
        .then((response) => {
          if (handleSubmit) {
            handleSubmit(response);
          }
        })
        .catch(() => {
          setHasAuthenticationError(true);
        });
    } catch (err) {
      console.error(err);

      setHasAuthenticationError(true);
    }
  };

  const onCancelData = () => {
    if (handleCancel) {
      handleCancel();
    }
  };

  const errorClass = classNames(
    {
      displayError: hasAuthenticationError,
      font__warn: isSmartPos,
    },
    "error"
  );

  return (
    <Modal>
      <CardWrapper className={isLoading ? "loading" : ""}>
        <CardHeader className="spacing">
          {user?.isOperator
            ? "Essa ação requer autorização"
            : "Essa ação requer nível gerencial"}
        </CardHeader>

        <CardBody className="spacing">
          <CardFormRow className="form-itens">
            Usuário
            <Selector
              items={
                user?.isOperator
                  ? [user]
                  : userList
              }
              selectText="Escolha seu usuário"
              onSelect={(manager) => setUser(manager)}
              isLogin={true}
            />
            {userList.length === 0 && (
              <small style={{ color: "#a7a7a7", fontStyle: "italic" }}>
                Aguarde! Buscando Usuários...
              </small>
            )}
          </CardFormRow>
          <CardFormRow className="form-itens">
            Senha
            <div className="input__wrapper">
              <input
                data-use-touch-keyboard
                data-x-pos="right"
                data-y-pos="top"
                layout-name="numeric"
                inputMode="numeric"
                type="password"
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                className={hasAuthenticationError ? "has-error" : ""}
              />
              <button
                type="button"
                className="button__reset"
                onClick={() => setInputPassword("")}
              >
                X
              </button>
            </div>
            <span className={errorClass}>Usuário ou Senha Inválido</span>
          </CardFormRow>
        </CardBody>

        <CardFooter className="spacing">
          <Button className="btn btn-link" onClick={onCancelData}>
            Cancelar
          </Button>

          <Button
            className="btn btn-primary has-btn-link"
            onClick={onSubmitData}
            disabled={!user && !inputPassword}
          >
            Entrar
          </Button>
        </CardFooter>
      </CardWrapper>
    </Modal>
  );
};

export default LoginModal;
