import { printerActionService } from "../../../services/actions/printerActionService";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const printQRCodeCoupon = (report) => {
  var coupons = [];

  if (report) {
    report = report.replace(new RegExp('##', 'g'), '<br>');
    coupons.push(`RECEBIMENTO QRLINX (VIA CLIENTE)<br>${report}<br><br><br><br>`);
    coupons.push(`RECEBIMENTO QRLINX (VIA ESTAB.)<br>${report}<br><br><br><br>`);
  }

  coupons.forEach(content => {
    if (window.desktopApp) {
      printerActionService({
        body: content,
      });
    } else if (isMobile) {
      printDefault(content.replace(new RegExp(/\**<br>/, 'gm'), '\n'));
    }
  })
}
