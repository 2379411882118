import ptBr from "date-fns/locale/pt-BR";
import React from "react";
import Calendar, { registerLocale } from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { DatepickerWrapper, MainWrapper } from "./datepicker-style";
//import * as moment from "moment";

import "./react-datepicker.css";

registerLocale("pt-BR", ptBr);

export class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      invalidMessage: null,
    };

    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
    this.calendarBlur = this.calendarBlur.bind(this);
    this.focusInput = this.focusInput.bind(this);
    this.inputBlur = this.inputBlur.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isValidDate = this.isValidDate.bind(this);

    this.mouseOverIcon = false;
  }

  componentDidUpdate() {
    this.secondBlur = this.props.onBlur;
  }

  handleKeyEvent(e) {
    if (!this.state.open) {
      switch (e.code) {
        case "ArrowUp":
        case "ArrowDown":
        case "ArrowLeft":
        case "ArrowRight":
          e.stopPropagation();
          break;
        case "Space":
          e.stopPropagation();
          this.calendarRef.input.inputElement.value = new Date().format(
            "DD/MM/YYYY"
          );
          break;
        default:
          break;
      }
    }

    if (e.key === "Escape" && this.state.open) {
      e.stopPropagation();
      this.closeCalendar();
    } else if (this.state.invalidMessage) {
      this.setState({
        invalidMessage: null,
      });
    }
  }

  closeCalendar() {
    this.setState({ open: false });
  }

  calendarBlur() {
    if (this.secondBlur) {
      this.secondBlur();
    }
    if (!this.mouseOverIcon) {
      this.closeCalendar();
    }
  }

  componentDidMount() {
    this.calendarRef.input.inputElement.addEventListener(
      "keydown",
      this.handleKeyEvent
    );
    this.calendarRef.input.inputElement.addEventListener(
      "blur",
      this.inputBlur
    );

    const iconRef = this.wrapperRef?.childNodes[1];

    if (iconRef) {
      iconRef.onmouseover = () => {
        this.mouseOverIcon = true;
      };
      iconRef.onmouseout = () => {
        this.mouseOverIcon = false;
      };
    }
  }

  handleSelect() {
    if (this.state.invalidMessage) {
      this.setState({
        invalidMessage: null,
      });
    }
    if (this.state.open) {
      this.closeCalendar();
    }
  }

  componentWillUnmount() {
    this.calendarRef.input.inputElement.removeEventListener(
      "keydown",
      this.handleKeyEvent
    );
    this.calendarRef.input.inputElement.removeEventListener(
      "blur",
      this.inputBlur
    );
  }

  isValidDate() {
    const dateStr = this.calendarRef.input.inputElement.value;
    if (!dateStr || dateStr.indexOf("_") !== -1) {
      return false;
    }
    return true;
  }

  inputBlur() {
    let invalidMessage;
    const dateStr = this.calendarRef.input.inputElement.value;

    if (this.calendarRef.input.inputElement.value) {
      if (dateStr.indexOf("_") !== -1) {
        this.setState({
          invalidMessage: "Data inválida",
        });
        return;
      }
      const dayMonthYear = dateStr
        .toString()
        .split("/")
        .map((v) => parseFloat(v));
      const validDate = new Date();
      validDate.setFullYear(
        dayMonthYear[2],
        dayMonthYear[1] - 1,
        dayMonthYear[0]
      );

      if (
        //!moment(validDate).isValid() ||
        validDate.getDate() !== dayMonthYear[0] ||
        validDate.getMonth() + 1 !== dayMonthYear[1] ||
        validDate.getFullYear() !== dayMonthYear[2]
      ) {
        invalidMessage = "Data inválida";
      }
    }

    this.setState({
      invalidMessage: invalidMessage,
    });

    if (this.state.open) this.focusInput();
  }

  focusInput() {
    this.calendarRef.input.inputElement.focus();
  }

  toggleCalendar() {
    this.setState(
      (old) => {
        if (!old.open) this.focusInput();
        return {
          open: !old.open,
        };
      },
      () => {
        if (this.state.open) {
          const toggle = this.wrapperRef?.getElementsByClassName(
            "react-datepicker-popper"
          )[0];

          const container = this.wrapperRef
            ?.getElementsByClassName("react-datepicker__input-container")[0]
            .getBoundingClientRect();

          if (toggle) {
            toggle.style.top = container.top + "px";
            toggle.style.left = container.left + "px";
          }
        }
      }
    );
  }

  render() {
    return (
      <MainWrapper className="datepicker-main-wrapper">
        <DatepickerWrapper
          onClick={!this.state.open ? this.toggleCalendar : undefined}
          className="datepicker-wrapper"
          innerRef={(ref) => (this.wrapperRef = ref)}
        >
          <Calendar
            ref={(ref) => (this.calendarRef = ref)}
            locale="pt-BR"
            className="control"
            dateFormat="dd/MM/yyyy"
            placeholderText="__/__/____"
            open={this.state.open}
            onSelect={this.handleSelect}
            {...this.props}
            onBlur={this.calendarBlur}
            //isClearable
            //clearButtonTitle="Limpar"
            onClickOutside={() => this.closeCalendar()}
            customInput={
              <MaskedTextInput
                type="text"
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            }
          />
          <i className="material-icons">event</i>
        </DatepickerWrapper>
        <small className="invalidDate">{this.state.invalidMessage}</small>
      </MainWrapper>
    );
  }
}

export default DatePicker;
