import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../../helpers/mediaQueryHelper";


export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 8px;
  gap: 16px;

  min-height: 70px;
  margin-right: 1rem;

  border-radius: 16px !important;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  min-height: 70px;
  margin-right: 1rem;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  flex: 1;

  border-radius: 0.5rem;
  border: 2px solid #f1f3f6;
  overflow: hidden;

  &.isItemActive {
    border-color: #411e5a;
  }

  .description {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.7rem;

    text-transform: uppercase;
    word-break: break-word;

    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);

    flex: none;
    order: 0;
    flex-grow: 0;

    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    &.isSmartPos {
      font-size: 0.5rem;
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    min-height: 47px !important;
    height: 50px;
    margin-top: 8px;
    margin-bottom: 5px;
  `)}
`;
