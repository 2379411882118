import React, { useEffect, useRef } from "react";
import Button from "../Button";
import { CustomCheckboxContainer } from "./styles.js";

export const ShowDetailsRenderer = (props) => {
  return (
    <Button onClick={() => props.onClick(props.data)}>
      <i className="material-icons">visibility</i>
    </Button>
  );
};

export const CheckboxRenderer = (props) => {
  const inputRef = useRef();

  useEffect(() => {
    const isSelected = props.node.isSelected();
    inputRef.current.checked = isSelected;

    props.node.addEventListener();
  });

  const onClick = () => {
    if (props.node.allChildrenCount > 0) {
      props.node.allLeafChildren.forEach((children) => {
        children.setSelected(!children.isSelected());
      });
    }

    props.node.setSelected(!props.node.selected);

    props.api.refreshCells({
      force: true,
      // columns: ["selectAll", "position"],
    });
  };

  return (
    <CustomCheckboxContainer>
      <input
        ref={inputRef}
        type="checkbox"
        className="custom-checkbox"
        onClick={onClick}
      />
      {props.value ? <span className="title">{props.value}</span> : <></>}
    </CustomCheckboxContainer>
  );
};

export const HeaderCheckboxComponent = (props) => {
  const inputRef = useRef();
  const totalGridRows = props.api.getDisplayedRowCount();

  useEffect(() => {
    if (totalGridRows === 0) return;

    const selectedRows = props.api.getSelectedNodes();

    if (selectedRows.length === 0) {
      inputRef.current.indeterminate = false;
    } else {
      if (totalGridRows !== selectedRows.length) {
        inputRef.current.indeterminate = true;
        return;
      }

      inputRef.current.indeterminate = false;
    }
  });

  const onClick = () => {
    if (totalGridRows === 0) return;

    const allRows = [];
    props.api.forEachNode((row) => allRows.push(row));

    if (props.api.getSelectedNodes().length === allRows.length) {
      props.api.deselectAll();
    } else {
      props.api.selectAll();
    }

    props.api.refreshCells({
      force: true,
      // columns: ["selectAll", "position"],
    });
  };

  return (
    <CustomCheckboxContainer>
      <input
        ref={inputRef}
        type="checkbox"
        className="custom-checkbox"
        defaultChecked={props.api.getSelectedNodes().length > 0}
        onClick={onClick}
      />
      {props.displayName ? (
        <span className="title">{props.displayName}</span>
      ) : (
        <></>
      )}
    </CustomCheckboxContainer>
  );
};
