import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

const styles = {
  padding: "15px",
};

const DialogBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogBox = styled.div`
  width: 50%;
  height: 40%;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  color: #414141;

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    margin: 0 1rem;
    width: 100%;
  `)}
`;

const DialogHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: ${styles.padding};
  color: #414141;
  font-family: Dosis, sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
  opacity: 0.8;
  button {
    margin-left: auto;
  }
`;

const DialogBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${styles.padding};
`;

const DialogIcon = styled.div`
  display: ${(props) => (props.image ? "block" : "none")};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 20px;
  width: 100px;
  height: 100%;
  background-position-y: center;
`;

const DialogMessage = styled.div`
  .dialog-title {
    font-family: Dosis, sans-serif;
    font-size: 1.375rem;
    font-weight: 500;
    margin: 0 0 5px;
  }

  .dialog-message {
    font-size: 16px;
    margin: 0px;
  }
`;

const DialogFooter = styled.div`
  padding: ${styles.padding};
  display: flex;
  grid-gap: 10px;
  gap: 10px;

  justify-content: flex-end;

  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .btn {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    margin-bottom: 1rem;
    height: 45px;
  }

  .btn-text {
    margin-top: 5px;
  }
`;

export {
  DialogBackground,
  DialogBox,
  DialogHeader,
  DialogBody,
  DialogIcon,
  DialogMessage,
  DialogFooter,
};
