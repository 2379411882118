import React from "react";
import { TefMessagesWrapper, DivWallets } from "./style";

import QRCode from "qrcode.react";

import BoxWallet from "../box-wallet/index";

const QrCode = ({ value, wallets }) => {
  return (
    <TefMessagesWrapper>
      <div className="qrcode-wrapper">
        <QRCode value={value} size={700} className="qrcode" />
      </div>
      <DivWallets>
        {wallets &&
          wallets.map((item) => {
            return (
              item.logo_link && (
                <BoxWallet
                  className="qrWallets"
                  key={item.cnpj}
                  image={item.logo_link}
                />
              )
            );
          })}
      </DivWallets>
    </TefMessagesWrapper>
  );
};

export default React.memo(QrCode);
