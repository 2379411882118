import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../../helpers/mediaQueryHelper";

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;

  .content {
    display: flex;
    flex-direction: column;

    background-color: #fff;
    height: 480px;
    min-height: 330px;
    max-height: 480px;
    min-width: 620px;

    position: relative;

    border-radius: 0.5rem;
    margin: 0 auto;

    input[name="total"] {
      order: 0;
      width: 90%;

      outline: none;

      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      color: #000;

      border: 0;
      border: 2px solid #411e5a;
      margin-left: 2rem;
      border-radius: 5px;
    }

    .header__content {
      position: sticky;
      bottom: 0;
      padding: 10px;
      &.title {
        width: 55%;
      }
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid rgba(206, 206, 206, 0.47);

      .title {
        color: #2e0037;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0 1rem;
      }

      .button__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        cursor: pointer;
      }
    }

    .keyboard-numeric {
      button {
        font-size: 1rem;
      }
    }

    .info__text__container {
      position: absolute;
      right: 10px;
      display: flex;
      flex-direction: column-reverse;
      width: 50%;
      padding: 8px 0;

      > svg {
        position: absolute;
        top: 0.5rem;
      }

      .keyboard-numeric {
        //padding: 1.5rem;

        .button {
          font-size: 1.5rem;
          border-radius: 0.5rem;
          color: #411e5a;
        }

        .button-send-item {
          background: #09843a;
          color: #fff;
        }
      }
    }

    .product-detail {
      display: flex;
      padding: 1rem;
      width: 40%;
      margin-left: 2.5rem;
    }

    .input-quantity {
      margin-top: 35px;
      width: 45%;
    }

    .button-content {
      display: flex;
      width: 45%;
      padding: 10px;
      margin-left: 1.2rem;
    }

    .button__submit {
      background-color: #411e5a;
      color: #fff;
      font-weight: 600;
      border-radius: 5px;
      width: 100%;
      margin: 5px;
    }

    .button__cancel {
      background-color: #fff;
      border: 2px solid #2e0037;
      color: #2e0037;
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .info__text__container {
      display: none !important;
    }

    .content {
      min-width: 100%;
    }

    .form__container {
      display: flex;
      flex-direction: column;

      .input-quantity {
        width: 100%;
        padding: 0 1rem;

        input {
          width: 100%;
          margin: 0;
        }
      }
    }

    .product-detail {
      padding: 1rem 1rem 0 !important;
      width: 100% !important;
      margin-left: 0 !important;

      > section {
        margin-right: 0 !important;
        width: 100% !important;
      }
    }

    .button-content {
      width: 100% !important;
      margin-left: 0 !important;
    }
  `)}
`;
