import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useLayoutAa } from "../../../contexts/layout";
import { selectedTotalCombo } from "../../utils/getQuantityCombo";
import { Button } from "../modules";
import {
  ContainerProgress,
  Progress,
  ContainerTitle,
  CountPage,
  Title,
  Index,
} from "./style";

const LangTextButton = ({ keyLang, altText }) => {
  const { t } = useTranslation();
  const { getTranslateName } = useLayoutAa();
  const text = getTranslateName(t, keyLang) || altText;

  return text;
};

const Step = ({
  isActive,
  displayPrevious,
  displayNext,
  displaySubmit,
  displayRequired,
  component,
  children,
  progressBar,
  disabledNext,
  disabledSubmit,
  title,
  index,
  currentPage,
  disableButton,
  isSubmit,
  goToPreviousStep,
  goToNextStep,
  onSubmit,
  goToJumpStep,
  isDisabledButtonToAdd,
}) => {
  let visibleCountPage = false;

  const totalCombo = selectedTotalCombo();
  const isHighComboIndex = index > 1 && totalCombo > 0;

  const isShowButtonSubmit =
    (displaySubmit && !totalCombo) ||
    (displaySubmit && totalCombo >= 0) ||
    (displaySubmit && isSubmit && isHighComboIndex);

  const isShowButtonPrevious =
    (displayPrevious && !totalCombo) ||
    (displayPrevious && totalCombo === 0) ||
    (displayPrevious && isHighComboIndex);

  const isSmallButton =
    (displayNext && !totalCombo) ||
    (displayNext && totalCombo === 0) ||
    (displayNext && isHighComboIndex);

  const nextWithButton = isSmallButton ? "48%" : "100%";

  const submitWidthButton =
    !isShowButtonPrevious && index === 1 ? "100%" : "48%";

  useEffect(() => {
    const totalCombo = selectedTotalCombo();

    if (totalCombo && totalCombo >= 1) {
      window.preventMultipleSubmit = false;
    }

    return () => {
      window.preventMultipleSubmit = false;
    };
  }, [progressBar, index]);

  if (isActive === false) return null;

  return (
    <>
      <ContainerProgress className="progressTablet">
        <Progress style={{ width: `${progressBar}%` }}></Progress>
      </ContainerProgress>
      {title && (
        <ContainerTitle className="step-title containerTitleTablet">
          {visibleCountPage && <CountPage>{currentPage}</CountPage>}
          <Index>{index}</Index>
          <Title>{title}</Title>
        </ContainerTitle>
      )}
      {component ? React.createElement(component) : children}
      <div className="step-buttons">
        {isShowButtonPrevious && (
          <Button
            className="rounded button-secondaryBobs"
            text={<LangTextButton keyLang="buttons.back" altText="Voltar" />}
            buttonUIType="outline-backstep"
            buttonUI={"normal"}
            width={!displayNext && !isShowButtonSubmit ? "100%" : "48%"}
            handleClick={() => goToPreviousStep()}
          />
        )}

        {displayNext && (
          <Button
            className="buttonAddCar button-primaryBobs"
            text={<LangTextButton keyLang="buttons.toNext" altText="Próximo" />}
            buttonUIType="primary"
            buttonUI="normal"
            disabled={isDisabledButtonToAdd || disabledNext}
            width={nextWithButton}
            handleClick={() => goToNextStep()}
          />
        )}

        {isShowButtonSubmit && (
          <Button
            className="buttonAddCar button-primaryBobs"
            text={
              <LangTextButton keyLang="buttons.toAdd" altText={localStorage.getItem('layout') === "AAONE" ? "Continuar" : "Adicionar"} />
            }
            buttonUIType="primary"
            buttonUI="normal"
            disabled={isDisabledButtonToAdd || disabledSubmit || disableButton}
            width={submitWidthButton}
            handleClick={() => {
              if (!window.preventMultipleSubmit) {
                window.preventMultipleSubmit = true;
                onSubmit();
                setTimeout(() => {
                  window.preventMultipleSubmit = false;
                }, 500);
              }
            }}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          width: "95%",
          position: "absolute",
          bottom: "1vh",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            marginTop: "2em",
          }}
        >
          {!displayRequired && index && (
            <Button
              text={
                <LangTextButton
                  keyLang="buttons.noThanks"
                  altText="Não, obrigado!"
                />
              }
              buttonUIType="secondary"
              buttonUI="jump"
              width="100%"
              handleClick={() => {
                if (!window.preventMultipleSubmit) {
                  window.preventMultipleSubmit = true;
                  goToJumpStep();
                  setTimeout(() => {
                    window.preventMultipleSubmit = false;
                  }, 500);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step;
