import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import {
  getCurrentReceiptNumberFromDB,
  updateCurrentReceiptNumber,
  consultarUltimaNota,
} from "../../../services/orderService";
import { isSmartPos } from "../../../aaone/configuration";
import { useDialog } from "../../hook/dialogHook";
import { PagesEnum } from "../../constants/pagesEnum";
import { useLoading } from "../../hook/loadingHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { getRetailerId, getSerieNFCe } from "../../../aaone/configuration";
//import { getUltimaNota } from "../../../services/fiscalFlowService";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { Container } from "./styles";
import { HeaderPaymentMethod } from "../../components/Header/paymentMethod";

export const NFCESeriesSettings = () => {
  const navigate = useNavigate();
  const { setVisibleSidebarCart } = useWrapperRouters();
  const { setShowLoading } = useLoading();

  const inputSerieRef = useRef();
  const inputDocumentNumberRef = useRef();
  const { showAlert } = useDialog();

  const systemParams = getSystemParamsHelper();

  const cnpj = systemParams?.storeDetails?.cnpj;

  useEffect(() => {
    setVisibleSidebarCart(false);

    inputSerieRef.current.value = getSerieNFCe();

    handleGetReceipt();
  }, []);

  const handleSave = () => {
    setShowLoading();
    try {
      updateCurrentReceiptNumber({
        receiptNumber: Number(inputDocumentNumberRef.current.value), //ReceiptNumber
        receiptSeries: Number(inputSerieRef.current.value), //ReceiptSeries
      }).then((res) => {
        setShowLoading(false);

        if (res.errors) {
          showAlert({
            message: "Ocorreu um erro ao salvar os dados",
          });
        } else {
          showAlert({
            message: "Os dados foram atualizados com sucesso.",
          });

          handleCancel();
        }
      });
    } catch (err) {
      console.error(err);
      showAlert({
        message: "Não foi possível pesquisar a nota.",
      });
    }
  };

  const handleCancel = () => {
    navigate(PagesEnum.HOME);
    setVisibleSidebarCart();
  };

  const handleGetReceipt = () => {
    try {
      setShowLoading();
      getCurrentReceiptNumberFromDB().then((res) => {
        inputDocumentNumberRef.current.value = res.receiptNumber || 0;
        setShowLoading(false);
      });
    } catch (err) {
      console.error(err);
      showAlert({
        message: "Não foi possível pesquisar a nota.",
      });
    }
  };

  const handleGetLastReceipt = () => {
    try {
      setShowLoading();

      consultarUltimaNota(getRetailerId(), getSerieNFCe())
        .then((data) => {
          setShowLoading(false);
          const { proximoNumero = 0 } = data;

          if (proximoNumero === null) {
            showAlert({
              title: "Ocorreu um erro",
              message: "Verifique as configurações de emissão",
            });
            return;
          }

          inputDocumentNumberRef.current.value = Number(proximoNumero);
        })
        .catch((err) => {
          setShowLoading(false);
          showAlert({
            title: "Ocorreu um erro",
            message: `Verifique as configurações de emissão. ${
              err.message ?? ""
            }`,
          });
          console.error(err);
        });
    } catch (err) {
      setShowLoading(false);
      console.error(err);
      showAlert({
        message: "Não foi possível pesquisar a última nota.",
      });
    }
  };

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, []);

  return (
    <Container className={classNames({ isSmartPos })}>
      <HeaderPaymentMethod onClickBackButton={handleBackToHome}>
        Série e numeração NFCe
      </HeaderPaymentMethod>
      <div className="content__wrapper">
        <form className={classNames({ isSmartPos }, "form__container")}>
          <div className="input__group">
            <label className="title">Série</label>
            <input
              readOnly
              name="serie"
              type="text"
              ref={inputSerieRef}
              onChange={(e) => {
                inputSerieRef.current.value = e.target.value;

                return e;
              }}
            />
          </div>
          <div className="input__group">
            <label className="title">Número Sequencial</label>
            <input
              readOnly
              name="documentNumber"
              type="text"
              ref={inputDocumentNumberRef}
              onChange={(e) => {
                inputDocumentNumberRef.current.value = e.target.value;

                return e;
              }}
            />
          </div>
          <div className="input__group">
            <button onClick={handleGetLastReceipt} type="button">
              Verificar a última nota emitida
            </button>
          </div>
          <div className="buttons__container">
            <button
              onClick={handleCancel}
              className="button__default"
              type="button"
            >
              Cancelar
            </button>
            <button
              onClick={handleSave}
              className="button__default button__submit"
              type="button"
            >
              Ok
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
