import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import qs from "qs";
import { useVersionAa } from "../../../aaone/contexts/versionContext";
import { useAuth } from "../../hook/authHook";
import { useCashdesk } from "../../hook/cashdeskHook";
import { LoadingStartApp } from "../../../linx/loading";
import { frontConfig } from "../../../services/frontConfigService";
import { getUsers } from "../../../services/usersServices";
import { KeyboardNumeric } from "../../components/KeyboardNumeric";
import {
  getClientEndpointReshop,
  getProductsAll,
  getReshopEndpoint,
  isSmartPos,
} from "../../../aaone/configuration";
import { apiReshop } from "../../../services/api";
import { getReshopAuthDataUnico } from "../../helpers/getReshopAuthDataUnico";
import {
  PDV_STORE_PARAMS,
  TOKEN_RESHOP_AUTH_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import { LoadingDots } from "../../components/LoadingDots";
import { activatePDVService } from "../../../services/linxPayServices";
import { getSystemParametersService } from "../../../services/getSystemParametersService";
import { getCurrentSystemVersionService } from "../../../services/getCurrentSystemVersion";
import { useDialog } from "../../hook/dialogHook";
import { undoTEFPayments } from "../../helpers/paymentHelper";
import { getLocalPayments } from "../../helpers/multiPaymentsHelper";
import { currencyMask } from "../../helpers/masks";
import { escOnlyNumbersHelper } from "../../helpers/escOnlyNumbersHelper";

let warnServerTimeout;
let warnServerAndStorageContentTimeout;

export const LoginPage = React.memo(() => {
  const auth = useAuth();
  const { showAlert } = useDialog();
  const cashdesk = useCashdesk();
  const versionAa = useVersionAa();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const userSelectRef = useRef(null);

  const serializeBearerToken = (tokenValue) =>
    tokenValue.replace("Bearer", "").trim();
  const [keyboardActions, setKeyboardActions] = useState();
  const [users, setUsers] = useState([]);
  const [warnServer, setWarnServer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [disabledButtonEnter, setDisabledButtonEnter] = useState(false);
  const [messageCashDeskUser, setMessageCashDeskUser] = useState({ status: false, message: "" });

  const handleDisableButtonEnter = useCallback(disabled => {
    setDisabledButtonEnter(disabled);

    if (disabled) {
      setMessageCashDeskUser({
        status: disabled,
        message: `Existe um caixa aberto no dome do(a) "${cashdesk.cashdesk.user}".
                    Por gentileza, fechar o caixa com esse usuário antes de logar com outro usuário.`
      });
    } else {
      setMessageCashDeskUser({
        status: disabled,
        message: ""
      });
    }
  }, [cashdesk]);

  const handleChangeUser = useCallback(() => {
    if (cashdesk.isCashdeskOpen) {
      if (cashdesk.cashdesk && userSelectRef.current) {
        if (cashdesk.cashdesk?.user !== userSelectRef.current.value) {
          handleDisableButtonEnter(true);
        } else {
          handleDisableButtonEnter(false);
        }
      } else if(cashdesk.cashdesk && users.length && !userSelectRef.current) {
        if (cashdesk.cashdesk?.user !== users[0].login) {
          handleDisableButtonEnter(true);
        } else {
          handleDisableButtonEnter(false);
        }
      }
    }
  }, [cashdesk, userSelectRef, users]);

  useEffect(() => {
    handleChangeUser();
  }, [cashdesk, userSelectRef, users]);

  const handleSubmitLogin = useCallback(
    async (e) => {
      e.preventDefault();

      setIsRequestLoading(true);

      const data = new FormData(e.target);

      const username = data.get("login");
      const password = data.get("password");

      try {
        await auth.signIn({ username, password });
        await cashdesk.getLastCashdesk();

        const fidelityToken = async () => {
          const reshopAuthData = getReshopAuthDataUnico();

          const endPointReshop = getReshopEndpoint();
          const endPointReshopClient = getClientEndpointReshop();
          const url = endPointReshopClient
            ? `${getProductsAll()}/api/fidelidade/token`
            : `${endPointReshop}/token`;

          const headers = {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            // Adicionar estes headers somente se for utilizar a API para acessar o client
            ...(endPointReshopClient && {
              reshopClientBaseURL: endPointReshopClient,
              reshopBaseURL: endPointReshop,
            }),
          };

          const response = await axios.post(
            url,
            qs.stringify({
              username: reshopAuthData.username,
              password: reshopAuthData.password,
              grant_type: "password",
            }),
            { headers }
          );

          const tokenReshop = serializeBearerToken(response.data.access_token);

          apiReshop.defaults.headers.common.Authorization = tokenReshop;
          sessionStorage.setItem(TOKEN_RESHOP_AUTH_KEY_NAME, tokenReshop);

          return response;
        };
        fidelityToken();
        navigate(PagesEnum.HOME);
        getCurrentSystemVersionService();
        activatePDVService();
        const systemParametersData = await getSystemParametersService();
        sessionStorage.setItem(
          PDV_STORE_PARAMS,
          JSON.stringify(systemParametersData?.objectSystemParameters)
        );
      } catch (error) {
        console.log(`ERROR AUTH ${JSON.stringify(error)}`);
        showAlert({
          message: error?.message ?? "Falha no processo de login.",
        });
      }

      setIsRequestLoading(false);
    },
    [users, auth, cashdesk, navigate]
  );

  const handleClose = () => {
    window.desktopApp.close();
  };

  useEffect(() => {
    if (inputRef?.current) {
      if (keyboardActions?.value && keyboardActions?.value.length > 0) {
        const value = escOnlyNumbersHelper(keyboardActions.value);

        inputRef.current.value = value;
        keyboardActions.set(value);
      }
    }
  }, [keyboardActions]);

  useEffect(() => {
    const allPayments = getLocalPayments();

    undoTEFPayments(() => {
      const hasNsuInPayments = allPayments.find(
        (payment) => !!payment?.details?.controlCode
      );

      if (allPayments?.length > 0 && hasNsuInPayments) {
        showAlert({
          message: (
            <>
              O PDV foi fechado com alguns pagamentos não concluídos, os
              pagamentos abaixo foram cancelados.
              {allPayments?.length > 0 && (
                <>
                  {allPayments
                    .filter((payment) => payment.details.controlCode)
                    .map((payment) => (
                      <>
                        <br />
                        NSU: {payment.details.controlCode} | Valor:{" "}
                        {currencyMask(payment.details.amountPaid)}
                      </>
                    ))}
                </>
              )}
            </>
          ),
        });
      }
    });

    getUsers()
      .then((data) => {
        localStorage.setItem("UserCode", data.users[0].employeeCode);
        setUsers(data.users);
      })
      .catch(() => setWarnServer(true));

    frontConfig();

    if (warnServerAndStorageContentTimeout) {
      clearTimeout(warnServerAndStorageContentTimeout);
    }

    warnServerAndStorageContentTimeout = setTimeout(() => {
      if (
        warnServer === true &&
        localStorage.getItem("storageContent") !== "1"
      ) {
        document.location.reload(true);
      }
    }, 5000);
  }, [warnServer]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      try {
        const config = JSON.parse(window.sessionStorage.getItem("AA_Config"));
        setWarnServer(!config?.ConfigFilePath);
      } catch (error) {
        setWarnServer(true);
      }
    });
  }, [warnServer]);

  useEffect(() => {
    if (warnServer || (isLoading && !warnServer)) {
      versionAa.hide();
    }
  }, [warnServer, versionAa, isLoading]);

  const SEVEN_MILLISECONDS_TO_SEVEN_SECONDS = 7 * 1000;

  if (warnServerTimeout) {
    clearTimeout(warnServerTimeout);
  }

  warnServerTimeout = setTimeout(() => {
    if (warnServer) {
      if (typeof window !== "undefined") {
        window.location.reload();
      }
    }
  }, SEVEN_MILLISECONDS_TO_SEVEN_SECONDS);

  if (isLoading) {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  if (warnServer || (isLoading && !warnServer)) {
    return <LoadingStartApp />;
  }

  return (
    <Container>
      <div className="content-wrapper">
        <header className="header-container">
          <h1>Login</h1>
          <h2>Informe os dados abaixo para realizar o login</h2>
          {messageCashDeskUser.status && (
            <h4 className="alerta-cash-desk">
              { messageCashDeskUser.message }
            </h4>
          )}
        </header>
        <form onSubmit={handleSubmitLogin} className="login-container">
          <div className="inputs-container">
            <div className="form-group">
              <label htmlFor="login">Usuário</label>
              <select
                  name="login"
                  id="login"
                  ref={userSelectRef}
                  onChange={() => handleChangeUser()}
              >
                {users?.map((currentUser, index) => (
                  <option
                    value={currentUser.login}
                    key={index}
                    defaultValue={index === 0}
                  >
                    {currentUser.login}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="password">Senha</label>
              <input
                type={isSmartPos ? "number" : "password"}
                value={keyboardActions?.value}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                role="presentation"
                readOnly
                inputMode="numeric"
                onFocus={(e) => {
                  e.currentTarget.removeAttribute("readOnly");
                }}
                onChange={(e) => {
                  e.target.value = escOnlyNumbersHelper(e.target.value);

                  if (keyboardActions?.set) {
                    keyboardActions.set(e.target.value);
                  }

                  return e;
                }}
                ref={inputRef}
                name="password"
                id="password"
                placeholder="Digite sua senha"
              />
            </div>

            <button type="submit" disabled={disabledButtonEnter}>
              {isRequestLoading ? <LoadingDots /> : "Entrar"}
            </button>
            {window?.desktopApp && (
              <button
                type="button"
                className="button__submit"
                onClick={handleClose}
              >
                Sair
              </button>
            )}
          </div>

          {!isSmartPos && <KeyboardNumeric setActions={setKeyboardActions} />}
        </form>
      </div>
    </Container>
  );
});
