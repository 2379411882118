import styled from "styled-components";
import { isSmartPos } from "../../../../aaone/configuration";

export const DetailOrderWrapper = styled.div`
  margin-top: ${isSmartPos ? "20px" : "4px"};
`;

export const TotalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  strong {
    margin: 0 32px 0 10px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-top: 100px;

  button {
    width: ${isSmartPos ? "100%" : ""};
    margin: -1px 0 0 10px;
    padding: 0 16px;
    color: #fff;
    background-color: #26a69a;
    letter-spacing: 0.5px;
    height: 50px;
    min-width: 100px;
    font-size: ${isSmartPos ? "12px" : "14px"} !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 2px;
    cursor: pointer;
  }

  input {
    padding-left: 20px;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .value-area {
    font-size: 20px;
    height: 46px;
    width: 75%;
    border: transparent;
    outline: none;
    color: ${(props) => props.theme.palette.grayscale.ultraDark};

    &:focus {
      outline: none;
    }
  }

  .close-modal {
    float: right;
    top: 1px;
  }
`;
