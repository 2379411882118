import { createGlobalStyle } from "styled-components";
import styled from 'styled-components';

export const AccessibilityStyle = createGlobalStyle`
  @font-face {
    font-family: "Scout Black";
    src: local("Scout Black"), url("./assets/fonts/Scout-Black.otf") format("truetype");
    font-weight: normal;
  }

  :root {
    --no-image: url("./assets/no-image-default.png");
    --font-family-main: "Scout Black";

    --color-background-light: #fff !important;
    --color-text-menu: black !important;

    --color-background: black !important;
    --color-background-modal: #fff !important;
    --color-background-primary: black !important;
    --color-background-cart: black !important;
    --color-progress: black !important;

    --color-box-shadow-primary: #000000 !important;
    --color-box-shadow-secondary: var(--color-background) !important;
    --color-background-progress-bar: white !important;
    --color-background-number-progress-bar: black !important;

    --color-btn-primary:#ffcb05 !important;
    --color-title-category: white !important;
    --color-title-secondary: white !important;
    --color-subtitle: white !important;

    --color-background-card-primary: #211f1f !important;
    --color-background-card-secondary: #211f1f !important;

    --color-background-keyboard: rgba(168, 2, 14, 0.3) !important;
    --color-keyboard-background: #ffffff !important;
    --color-keyboard-input: #3a3a3a !important;

    --color-text-category: black !important;
    --color-title-progress: #000 !important;
    --color-title-modal: #000 !important;
    --color-text-flavor-list: black !important;

    --color-fidelity: black !important;
    --color-background-keyboard: #211f1f !important;
    --color-additem: black !important;

    --color--background-banner: #1e0080 !important;
    --color-button-secondary: #1e0080 !important;

    --color-trash: black !important;
    --color-button-resgate: #ffede1 !important;

    --color-button-banner: #fff !important;

    --color-title-points: white !important;
    --color-box-points: white !important;
    --product-background-color: black;
    --text-light: white;
  }

  .navTablet .listCategory-item-name {
    background-color: white;
    color: var(--color-text-menu);
  }

  .navTablet .listCategory-item--active, .contentTablet, .cart-summary-items {
    background-color: black !important;
  }

  .summary-item-details.true {
    color: var(--text-light);
  }

  .keyboard-login, .card.horizontal.withdraw-images, .keyboard.fidelidade .numericKeypad, .numericKeypad, .react-simple-keyboard, .card.normal {
    background-color: #1e1b1b !important;
  }

  .numericKeypad .key-add {
    color: black;
  }

  .habibs {
    background-color: #313131 !important;
  }

  .totalClassStyle {
    color: black !important;
  }

  .App.keyboard .hg-theme-default .hg-button {
    color: black !important;
  }

  .finalizarClass, .summary-item-details, .summary-action button, .highlight-add-item, .cart-summary-title {
    background-color: black !important;
  }

  .order-total {
    color: var(--text-light) !important;
  }

  .groupSales-price {
    color: white !important;
  }

  .step-title {
    color: #fff !important;
  }

  .cart-summary-title {
    background-color: white !important;
    /* border-radius: 1.5em 0em 0 0; */
  }

  .checked {
    background: white !important;
  }

  .button--clear {
    color: white !important; 
  }
  
  .button-secondary {
    color: black !important;
    background-color: white !important;
  }

  .button-buttonAddCar {
    background-color: black !important;
}
  
  .content-wrapper .subtitle {
    color: #fff;
  }

  .button-outline-primary {
    background-color: #211f1f !important;
    color: white !important;
  }

  .button-count {
    color: white !important;
  }

  button {
    text-transform: uppercase !important;
  }

  .button-primary {
    border: 6px black solid;
    background-color: white !important;
    color: black !important;
  }

  .radio-button-label {
    text-transform: uppercase !important;
  }

  .button-buttonAddCar {
    background-color: black;
    border-radius: 5% !important;
  }

  .keyboard input {
    color: black;
  }

  .keyboard.fidelidade .numericKeypad button {
    color: black;
  }

  .groupSales-item {
    color: white;
  }

  .cart-summary-items .summary button {
    color: white !important;
}

  .button--clear {
    color: white;
    border: 0.1em solid white;
  }

  .modal .subtotal strong {
    color: black !important;
  }

  .filters {
    background-color: #232325;
  }

  .background-summary {
    color: black;
  }

  .acessibilidadeStyle {
    background: white !important;
  }

  .hg-button{
    color: white !important;
  }

  .button-backstep {
    color: black !important;
  }
`;
export const Index = styled.span`
    color: white !important;
`;

