export const escOnlyNumbersHelper = (text) => text.replace(/[^0-9]+/g, "");

/**
 *
 * @param {string} cashText Ex R$ 29.899,99
 * @return number
 */
export const escCashOnlyNumbersHelper = (cashText) =>
  Number(
    String(cashText).replace("R$", "").replace(/\./g, "").replace(",", ".")
  );
