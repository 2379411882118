import styled from 'styled-components';

export const KeyboardWrapper = styled.div`
  display: ${props => (props.visible ? 'flex !important' : 'none')};
  position: fixed;
  z-index: 10000;
  right: 0;
  bottom: 0;

  .hg-theme-default {
    background-color: #c3c3c3;
  }

  .simple-keyboard.hg-theme-default {
    min-width: 480px;
    width: 80vw;
    max-width: 960px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .simple-keyboard.hg-layout-default .hg-button {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #414141;
    height: 50px;
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-primary {
    background: #fff;
    color: #414141;
    width: 100px;

    &:active {
      background: #666;
    }
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-close {
    background: #ff4c4c;
    color: white;
    width: 50px;

    &:active {
      background: red;
    }
  }
`;

export const KeyboardPositionControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  background-color: #c3c3c3;
  padding: 6px;
  gap: 6px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Button = styled.button`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 5px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #414141;
  height: 50px;
  line-height: 0;
`;
