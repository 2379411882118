import styled from "styled-components";
import { AnchorWrapper } from "../../Button/style";

export const Wrapper = styled.div`
  position: absolute;

  display: flex;
  flex: 2;

  z-index: 999;

  height: 100%;

  left: -100%;
  top: 0;

  transition: left 0.2s ease-in-out;

  &.isOpenMenu {
    left: 0;
  }
`;

export const Overlay = styled.div`
  display: none;

  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 998;

  &.isOpenMenu {
    display: block;
  }
`;

export const Container = styled.menu`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  padding: 0px;
  gap: 8px;

  width: 359px;
  height: 100%;

  background: #2e0037;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 1.5rem;
    grid-gap: 1rem;
    gap: 1rem;

    //height: 91px;
    width: 100%;

    align-self: stretch;
    flex-grow: 0;
    flex: none;
    order: 0;

    .logo__container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .version {
        margin-left: 0.5rem;
        margin-top: 0.5rem;

        font-size: 0.7rem;
        font-weight: bold;

        span {
          background: #fff;
          padding: 0.1rem 0.5rem 0.2rem;
          border-radius: 6px;
          color: ${({ theme }) => theme.colors.purple};
        }
      }
    }

    .close {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem 1rem 1rem 1.5rem;
    width: 100%;

    overflow: auto;

    ul {
      padding: 0;
      margin: 0;

      list-style: none;
    }

    &,
    a {
      font-weight: 400;
      font-size: 1.095rem;
      color: rgba(255, 255, 255, 0.6);
    }

    .links li {
      margin-bottom: 1rem;

      a.active {
        color: rgb(255, 255, 255);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .content-type-sale {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    flex: none;
  }

  .type-of-sale {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-transform: uppercase;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: 1rem;

    &.balcao {
      border-right: 1px solid;
    }
  }
`;

export const MenuSection = styled.div`
  // margin-bottom: 30px;
  margin-bottom: 10px;

  .title {
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    line-height: 40px;
  }

  .alert {
    text-align: center;
    display: table;
    color: #fff;
    line-height: 20px;
    padding: 3px;
    background-color: #c30036;
    border-radius: 5px;
    border: 2px solid;
    border-color: #ff7979;
  }

  a {
    text-align: left;
    display: block;
    text-transform: initial;
    color: #cfc6d6;

    &:hover {
      .material-icons {
        color: #cfc6d6;
      }
    }
  }

  .material-icons {
    color: #cfc6d6;
    font-size: 1.2rem;
    margin-right: 0.3em;
    margin-left: 0.4em;
  }

  .icon-alert {
    color: #fff;
    margin-left: 0;
  }
`;

export const EachSectionContainer = styled.div`
  ${AnchorWrapper} {
    line-height: 40px;

    &.active {
      color: #fff;
    }

    &:not(:first-child) {
      border-top: 1px solid #404040;
    }

    .loading__container {
      display: inline-block;
      margin-right: 0.5rem;
      margin-left: 0.5rem;

      svg {
        width: 0.75em;
      }
    }
  }
`;

export const ToggleMenu = styled.input`
  display: none;

  ~ div {
    transition: max-height 0.2s;

    overflow: hidden;
    max-height: 0;
  }

  &:checked {
    ~ div {
      max-height: 500px;
      transition: max-height 0.8s;
    }

    + label {
      .material-icons {
        transform: rotateX(180deg);
      }
    }
  }
`;
