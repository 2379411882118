import { mediaQueryConfig } from "../config/mediaQueryConfig";

const config = mediaQueryConfig;

export const mediaQueryHelper = (key) => {
  const isSizeNumber = typeof config[key] === "number";
  const size = isSizeNumber ? `${config[key]}px` : config[key];

  const lessThan = (style) =>
    `@media (max-width: ${size}) {
      ${style}
    }
  `;

  const greaterThan = (style) =>
    `@media (min-width: ${size}) {
      ${style}
    }
  `;

  return { lessThan, greaterThan };
};
