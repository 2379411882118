import React, {memo, useState, useEffect} from "react";
import { currency } from  "../../utils/currency";
import {
  ContentImg,
  ShowPrice,
  ContentQrCode,
  TitleStatusPayment
} from "./style.js";
import {Button, Keyboard} from "../modules";
import Timer from "../timer";

const PedePronto= (props) => {
  const {
    cpf,
    hasCpf,
    handleCpf,
    backStep,
    qrCode,
    orderResponse,
    paymentStatus,
    setPaymentStatus,
    subtitle,
    chosenSeconds,
    action,
    start,
    showButtonBack
  } = props;
  const [statusTranslation, setStatusTranslation] = useState("AGUARDANDO PAGAMENTO...");
  useEffect(() => {
    if(orderResponse.eventType && orderResponse.eventType !== paymentStatus) {
      setPaymentStatus(orderResponse.eventType);
    }
  }, [orderResponse]);

  useEffect(() => {
    const statusTranslation = (status = "RECEIVED") => {
      const allStatus = {
        PAYMENT_CONFIRMED: "PAGAMENTO CONFIRMADO!",
        CANCELLED: "PAGAMENTO CANCELADO!",
        ISSUING_INVOTE_FISCAL: "GERANDO NOTA FISCAL...",
        CONFIRMING_OPERATION_PEDE_PROTO: "CONFIRMANDO OPERAÇÃO...",
      };

      if (allStatus[status])
          return allStatus[status];
      else
        return "AGUARDANDO PAGAMENTO...";
    }

    setStatusTranslation(statusTranslation(paymentStatus));
  }, [paymentStatus]);

  return (
    <>
      {!hasCpf ? (
      <>
        <Keyboard handleClick={handleCpf} initialCpf={cpf} />
        <footer
          className="template-columns-1"
          style={{ width: "75%", marginLeft: "12%", marginTop: "5%" }}
        >
          <Button
            handleClick={() => backStep("Você está sendo redirecionado para escolher outra forma de pagamento.")}
            text="Voltar"
            buttonUIType="outline-primary"
            buttonUI={"normal"}
          />
        </footer>
      </>
    ) : (
      <ContentQrCode>
        <div>
          {!qrCode ? (
            <div id="wrapper-loader">
              <div id="loader"></div>
              <p>{subtitle}</p>
            </div>
          ) : (
            <>
              <ContentImg>
                <Timer
                  chosenSeconds={chosenSeconds}
                  action={action.action}
                  paramAction={action.message}
                  start={start}
                />
                <img src={qrCode} />
              </ContentImg>
              <TitleStatusPayment>{statusTranslation}</TitleStatusPayment>
              <ShowPrice>
                <p>
                  Total do pedido: <strong>{currency(orderResponse.total?.itemsPrice.value)}</strong>
                </p>
              </ShowPrice>
              <ShowPrice>
                <p>
                  Desconto: <strong>{currency(orderResponse.total?.discount.value)}</strong>
                </p>
              </ShowPrice>
              <ShowPrice>
                <p className="pay">
                  Total a pagar:&nbsp;
                  <strong>
                    {currency(orderResponse.total.itemsPrice.value - orderResponse.total.discount.value)}
                  </strong>
                </p>
              </ShowPrice>
              <footer
                className="template-columns-1"
                style={{ width: "75%", marginLeft: "12%", marginTop: "5%" }}
              >
                {showButtonBack && (
                  <Button
                    handleClick={() => backStep("Você está sendo redirecionado para escolher outra forma de pagamento.")}
                    text={`Escolha outra forma de pagamento`}
                    buttonUIType="outline-primary"
                    buttonUI={"normal"}
                  />
                )}
              </footer>
            </>
          )}
        </div>
      </ContentQrCode>
    )}
  </>
  );
}
export default memo(PedePronto)
