import React from "react";
import classNames from "classnames";
import { MdOutlineReceiptLong } from "react-icons/md";

import {
  CardTable,
  ElapsedTime as ElapsedTimeComponent,
  BottomComponent,
  TableNumber,
  WrapperTableInfo,
} from "./style";
import { currencyMask } from "../../../helpers/masks";
import { isSmartPos } from "../../../../aaone/configuration";

const CardsTable = ({
  number,
  value,
  elapsedTime,
  info,
  handleSelectTable,
  handleSelectSaller,
  delayTime,
  ...rest
}) => {
  const handleBottomClick = () => {
    if (value) {
      handleSelectSaller();
    }
  };

  return (
    <div {...rest}>
      <CardTable
        className={classNames({ isSmartPos })}
        value={value}
        info={info}
        delayTime={delayTime}
      >
        <WrapperTableInfo onClick={() => handleSelectTable(number)}>
          <ElapsedTimeComponent>
            {info === "ACTIVE" ? elapsedTime : ""}
          </ElapsedTimeComponent>
          <TableNumber>{number}</TableNumber>
        </WrapperTableInfo>

        <BottomComponent
          className={classNames({ isSmartPos })}
          value={value}
          onClick={handleBottomClick}
        >
          {value ? <MdOutlineReceiptLong size="1.3rem" /> : null}
          {value ? currencyMask(value) : "Disponível"}
        </BottomComponent>
      </CardTable>
    </div>
  );
};

export default CardsTable;
