import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Button  from '../Button';

const Tbody = (
  //{items, columns, lines = {}, styleLink, handleClick, typeList},
  props, lines = {}
) => (

    <tbody id="tbody-list" >
      {props.items.map((item, index) => {
        const lineClassName = `${props.styleLink ? 'table__tr--link' : ''}${lines.className}` ?
          (typeof lines.className === 'function' ? lines.className(item) : lines.className) : '';

        return (
          <tr key={v4()} className={lineClassName} tabIndex="0" id={item.couponNumber}>
            {props.columns.map(column => column.cols.map((col, indexCol) => {
              var fnName = col.label.evento
              const colRef = col.label.ref;
              const idRef = col.id ? item[col.id.ref] : null;
              const tdRef = colRef || `${column.title.replace(/( )/g, '_')}-${indexCol}`;

              let value = colRef ? item[colRef] ? item[colRef] : '' : col.label;

              value = col.type === 'checkbox' ? (<input type="checkbox" defaultChecked={item.isChecked} disabled onChange={props.handleClick(item, tdRef, index)} />) : value;
              value = col.type === 'image' ? (<img src={value} alt="" />) : value;
              value = col.type === 'button' ? (<Button tabIndex="0" autoFocus={true}
                id="btn-conf-cancel"
                type="button"
                className="btn-large btn-purple"
                handleClick={(e) => { props[fnName](item, tdRef) }}
              >
                {tdRef}
              </Button>) : value;

              const columnClass = column.className ? column.className : '';
              const colClass = col.className ? col.className : '';

              const rowActive = item.isChecked ? 'is-actived' : '';

              return (
                <td
                  id={idRef}
                  key={tdRef}
                  className={`${columnClass} ${colClass} ${rowActive}`}
                  onClick={props.handleClick(item, tdRef)}
                  align={col.align}
                  title={col.type === 'checkbox' ? '' : value}
                >
                  <Fragment>

                    {col.Component
                      && <col.Component item={item} value={value} colRef={tdRef} />
                    }
                    {!col.Component && value}
                  </Fragment>
                </td>
              );
            }))}
          </tr>);
      }).reverse()}
    </tbody>
  );

Tbody.propTypes = {
  //items: PropTypes.array,
  lines: PropTypes.shape({
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      cols: PropTypes.arrayOf(
        PropTypes.shape({
          span: PropTypes.bool,
          id: PropTypes.oneOfType([
            PropTypes.shape({
              ref: PropTypes.string,
            }).isRequired,
          ]),
          label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              ref: PropTypes.string,
            }),
          ]).isRequired,
          component: PropTypes.any,
        }),
      ).isRequired,
    }),
  ).isRequired,
  styleLink: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default Tbody;
