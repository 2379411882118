import React from "react";
import { render } from "react-dom";
import PrintTemplate from "../../components/print-template";
import { formatDateHelper } from "../formatDateHelper";
import { currencyMask } from "../masks";
import { printerActionService } from "../../../services/actions/printerActionService";
import { lineToPrintHelper } from "../lineToPrintHelper";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const handlePrintCoupon = (cashdesk, currSupply) => {
  var html = document.createElement("html");

  render(
    <PrintTemplate>
      <>
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "SUPRIMENTO DE CAIXA"
              : "                SUPRIMENTO DE CAIXA"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        PDV: {cashdesk.posId} DATA:{" "}
        {formatDateHelper(
          new Date(currSupply.creationDate),
          "dd/MM/yyyy HH:mm:ss"
        )}
        {isMobile ? "\n" : ""}
        <br />
        Operador: {currSupply.operatorName}
        {isMobile ? "\n" : ""}
        <br />
        Responsável: {currSupply.adminName}
        {isMobile ? "\n" : ""}
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <table>
          <tr>
            <td className="shrink">Controle: </td>
            <td className="expand">
              {currSupply.referenceSequence}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Valor: </td>
            <td className="expand">
              <b>
                {currencyMask(currSupply.amount)}
                {isMobile ? "\n" : ""}
              </b>
            </td>
          </tr>
          <tr>
            <td className="shrink">Histórico: </td>
            <td className="expand">
              {currSupply.description}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
        </table>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          {!isMobile
            ? "---------------------------------------------------"
            : lineToPrintHelper}
          <b>
            {!isMobile
              ? "Assinatura/carimbo do responsável"
              : "         Assinatura/carimbo do responsável"}
          </b>
        </div>
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "*NÃO É DOCUMENTO FISCAL*"
              : "               *NÃO É DOCUMENTO FISCAL*"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
      </>
    </PrintTemplate>,
    html
  );

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    console.log(`printDefault | {}`);
    printDefault(html.outerHTML);
  }
};

export const handlePrintChargeback = (cashdesk, currSupply) => {
  var html = document.createElement("html");

  render(
    <PrintTemplate>
      <>
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "COMPROVANTE DE ESTORNO"
              : "            COMPROVANTE DE ESTORNO"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        PDV: {cashdesk.posId} DATA:{" "}
        {formatDateHelper(
          new Date(currSupply.creationDate),
          "dd/MM/yyyy HH:mm:ss"
        )}
        {isMobile ? "\n" : ""}
        <br />
        Operador: {currSupply.operatorName}
        {isMobile ? "\n" : ""}
        <br />
        Responsável: {currSupply.adminName}
        {isMobile ? "\n" : ""}
        <br />
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <table>
          <tr>
            <td className="shrink">Controle: </td>
            <td className="expand">
              {currSupply.referenceSequence}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
          <tr>
            <td className="shrink">Valor: </td>
            <td className="expand">
              <b>
                {currencyMask(currSupply.amount)}
                {isMobile ? "\n" : ""}
              </b>
            </td>
          </tr>
          <tr>
            <td className="shrink">Histórico: </td>
            <td className="expand">
              {currSupply.description}
              {isMobile ? "\n" : ""}
            </td>
          </tr>
        </table>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          {!isMobile
            ? "---------------------------------------------------"
            : lineToPrintHelper}
          <b>
            {!isMobile
              ? "Assinatura/carimbo do responsável"
              : "         Assinatura/carimbo do responsável"}
          </b>
        </div>
        <br />
        <br />
        <br />
        {isMobile ? "\n\n\n" : ""}
        <div style={{ textAlign: "center" }}>
          <b>
            {!isMobile
              ? "*NÃO É DOCUMENTO FISCAL*"
              : "               *NÃO É DOCUMENTO FISCAL*"}
          </b>
        </div>
        {!isMobile
          ? "---------------------------------------------------"
          : lineToPrintHelper}
        <br />
        {isMobile ? "\n\n\n\n\n\n" : ""}
      </>
    </PrintTemplate>,
    html
  );

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    console.log(`printDefault | {}`);
    printDefault(html.outerHTML);
  }
};
