export const getTableSelectedHelper = () => {
  try {
    let storeParamsStorage = sessionStorage.getItem('tableSelected');

    if (!storeParamsStorage || storeParamsStorage === "undefined") {
      storeParamsStorage = undefined;
    }

    const params = JSON.parse(storeParamsStorage);

    return params;
  } catch (error) {
    return undefined;
  }
};
