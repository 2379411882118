import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 360px;
  width: 100%;

  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0.5rem;

  margin-bottom: 1rem;

  .label {
    text-transform: uppercase;
    font-weight: 700;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 0.5rem 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .value {
    text-transform: uppercase;
    text-align: right;
    font-weight: 400;
  }
`;
