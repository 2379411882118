import { layoutNameConfig } from "../aaone/configuration";
import { apiBff, apiProducts } from "./api";

export const frontConfig = async () => {
  try {
    const { data } = await apiProducts.get("/api/front-config");

    const layoutName = layoutNameConfig ? layoutNameConfig : data.APP_LAYOUT;

    localStorage.setItem("layout", layoutName);

    return data;
  } catch (error) {
    console.error(`Error in frontConfig :: `, error);
    return Promise.reject("Error in frontConfig :: ");
  }
};
