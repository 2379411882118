import React, { useEffect, useState } from "react";
import { currency } from "../../../../aaone/shared/utils/currency";
import { useAuth } from "../../../hook/authHook";
import { useCart } from "../../../hook/cartHook";
import { useLocation } from "react-router-dom";
import { PagesEnum } from "../../../constants/pagesEnum";
import { ButtonOutline, DiscountWrapper } from "./styles";
import ModalDiscount from "./ModalDiscount";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  MANUAL_DISCOUNTS,
  RESHOP_DISCOUNTS,
} from "../../../constants/keyNamesSessionStorage";
import { getConsultDiscountsService } from "../../../../services/reshop/getConsultDiscountsService";
import { useLoading } from "../../../hook/loadingHook";
import { useDialog } from "../../../hook/dialogHook";

const ACTIONS = {
  SHOW_DISCOUNT_MODAL: "SHOW_DISCOUNT_MODAL",
};

const Discount = () => {
  let reshopCampaign = JSON.parse(sessionStorage.getItem(RESHOP_DISCOUNTS));

  const { userAuthData, reshop } = useAuth();
  const {
    itemsInCart,
    manualDiscount,
    handleSetManualDiscount,
    totalPrice,
    addDiscountsReshop,
    setReshopTotalPrice,
  } = useCart();
  const { setShowLoading } = useLoading();
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useDialog();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    if (userAuthData.isManager) {
      if (reshopCampaign?.QtdeCampanhasAtivadas > 0) {
        showAlert({
          title: "Atenção",
          message:
            "Descontos adicionados anteriormente podem ser recalculados\nDeseja continuar?",
          handleConfirm: () => {
            setShowModal(true);
          },
          onConfirmText: "Sim",
          handleCancel: () => {},
        });

        return;
      }

      setShowModal(true);
    } else {
      navigate(PagesEnum.NEED_AUTH, {
        state: {
          redirectUrl: PagesEnum.PAYMENT_METHODS,
          action: ACTIONS.SHOW_DISCOUNT_MODAL,
          redirectBackOnCancel: true,
        },
      });
    }
  };

  const handleClear = async () => {
    await calcReshopDiscount();
    handleSetManualDiscount();
    sessionStorage.removeItem(MANUAL_DISCOUNTS);
  };

  const calcReshopDiscount = async (totalDiscount) => {
    try {
      // Validação de descontos com o reshop
      const cpf = reshop?.cpfFidelity ?? null;

      const discountsResultResponse = await getConsultDiscountsService(
        itemsInCart,
        { cpf, totalPrice, totalDiscount }
      );

      if (discountsResultResponse) {
        addDiscountsReshop(discountsResultResponse);
      }

      if (discountsResultResponse?.CampanhasAtivadas?.length > 0) {
        setReshopTotalPrice(
          Number(discountsResultResponse?.TotalDesconto ?? 0)
        );
      }
    } catch (err) {
      console.log(
        "error on Discount calcReshopDiscount ->",
        JSON.stringify(err)
      );
    }
  };

  const handleCancel = async () => {
    try {
      setShowLoading();
      await handleClear();
      setShowModal(false);
      setShowLoading(false);
    } catch (err) {
      setShowLoading(false);
      console.log("err on Discount -> ", JSON.stringify(err));
    }
  };

  useEffect(() => {
    if (location?.state?.props?.action === ACTIONS.SHOW_DISCOUNT_MODAL) {
      if (reshopCampaign?.QtdeCampanhasAtivadas > 0) {
        showAlert({
          title: "Atenção",
          message:
            "Descontos adicionados anteriormente podem ser recalculados, deseja continuar?",
          handleConfirm: () => {
            setShowModal(true);
          },
          onConfirmText: "Sim",
          handleCancel: () => {},
        });

        return;
      }

      setShowModal(true);
    }
  }, [location]);

  return (
    <>
      <li>
        {(manualDiscount ?? 0) > 0 && (
          <>
            <strong>Desconto Manual:</strong>

            {manualDiscount > 0 && (
              <DiscountWrapper>
                <span>{currency(manualDiscount)}</span>
                {location.pathname === PagesEnum.PAYMENT_METHODS && (
                  <ButtonOutline
                    className="btn-outline remove btn-action-cart"
                    onClick={handleCancel}
                  >
                    <MdDeleteForever size="2rem" />
                  </ButtonOutline>
                )}
              </DiscountWrapper>
            )}
          </>
        )}
        {Number(manualDiscount ?? 0) === 0 &&
          location.pathname === PagesEnum.PAYMENT_METHODS && (
            <>
              <strong>Desconto Manual:</strong>

              <ButtonOutline
                className="btn-outline"
                onClick={() => handleShowModal()}
              >
                Aplicar Desconto
              </ButtonOutline>
            </>
          )}
      </li>
      <ModalDiscount
        showModal={showModal}
        toggleModal={() => setShowModal((val) => !val)}
        calcReshopDiscount={calcReshopDiscount}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default Discount;
