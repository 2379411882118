import React, { useCallback, useEffect, useRef, useState } from "react";
import KeyboardComponent from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useKeyboard } from "../../contexts/KeyboardContext";
import {
  KeyboardWrapper,
  KeyboardPositionControlWrapper,
  Button,
} from "./style";

const Keyboard = (props) => {
  const { keyboardLayout } = props;
  const {
    input,
    keyboardIsVisible,
    HandleOnChangeInputElement,
    DefocusOnInputElement,
    onScreenKeyboardPosition,
    layoutName,
  } = useKeyboard();
  const [layout, setLayout] = useState("default");
  const keyboardRef = useRef(null);
  const keyboardWrapperRef = useRef(null);
  const [keyboardPosition, setKeyboardPosition] = useState({
    y: "bottom",
    x: "right",
  }); // y: top | bottom; x: left | right;

  const HandlePosition = useCallback(
    (positions) => {
      if (keyboardWrapperRef.current && typeof positions === "object") {
        const axisPosition = Object.values(positions); // tranform {x: left, y: top} in [ left, top ];

        axisPosition.forEach((position) => {
          switch (position) {
            case "top":
              keyboardWrapperRef.current.style.top = "0";
              keyboardWrapperRef.current.style.bottom = "auto";
              break;
            case "bottom":
              keyboardWrapperRef.current.style.top = "auto";
              keyboardWrapperRef.current.style.bottom = "0";
              break;
            case "left":
              keyboardWrapperRef.current.style.left = "0";
              keyboardWrapperRef.current.style.right = "auto";
              break;
            case "right":
              keyboardWrapperRef.current.style.left = "auto";
              keyboardWrapperRef.current.style.right = "0";
              break;
            default:
              console.log("Error");
              break;
          }
        });
      }
    },
    [keyboardWrapperRef]
  );

  useEffect(() => {
    HandlePosition(keyboardPosition);
  }, [keyboardPosition]);

  useEffect(() => {
    setKeyboardPosition(onScreenKeyboardPosition);
  }, [onScreenKeyboardPosition]);

  useEffect(() => {
    if (input && keyboardRef) {
      if (keyboardRef.current) {
        keyboardRef.current.setInput(input.value);
      }

      const HandleChangeInput = (e) => {
        const value = e.target.value;

        if (keyboardRef.current) {
          keyboardRef.current.setInput(value);
        }
      };

      input.addEventListener("input", HandleChangeInput);
      return () => {
        input.removeEventListener("input", HandleChangeInput);
      };
    }

    if (!input) {
      keyboardRef.current.clearInput();
    }
  }, [input, keyboardRef]);

  useEffect(() => {
    if (keyboardLayout) {
      setLayout(keyboardLayout);
    }
  }, []);

  useEffect(() => {
    setLayout(layoutName);
  }, [layoutName]);

  const HandleOnChange = (inputValue) => {
    HandleOnChangeInputElement(inputValue);
  };

  const HandleOnKeyPress = (button) => {
    if (button === "{close}") {
      DefocusOnInputElement();
      if (keyboardRef) {
        if (keyboardRef.current) {
          keyboardRef.current.clearInput();
        }
      }
    }

    if (button === "{lock}") {
      setLayout((prevLayout) =>
        prevLayout === "default" ? "shift" : "default"
      );
    }

    if (button === "{enter}") {
      DefocusOnInputElement();
      if (keyboardRef) {
        if (keyboardRef.current) {
          keyboardRef.current.clearInput();
        }
      }
    }
  };

  return (
    <KeyboardWrapper visible={keyboardIsVisible} ref={keyboardWrapperRef}>
      <KeyboardComponent
        keyboardRef={(r) => {
          keyboardRef.current = r;
        }}
        layoutName={layout}
        layout={{
          default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp} {close}",
            "{tab} q w e r t y u i o p [ ] \\",
            "{lock} a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {clear}",
            ".com @ {space}",
          ],
          shift: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp} {close}",
            "{tab} Q W E R T Y U I O P { } |",
            '{lock} A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M < > ? {clear}",
            ".com @ {space}",
          ],
          numeric: ["1 2 3", "4 5 6", "7 8 9", "{clear} 0 {bksp}", "{enter}"],
        }}
        display={{
          "{bksp}": layout === "numeric" ? "⌫" : "back ⌫",
          "{close}": "X",
          "{tab}": "tab ⇥",
          "{lock}": "caps lock ⇪",
          "{enter}": layout === "numeric" ? "PRONTO" : "Enter ↵",
          "{shift}": "shift ⇧",
          "{space}": " ",
          "{clear}": "C",
        }}
        theme={
          layout === "numeric"
            ? "hg-theme-default hg-layout-default myThemeNumeric"
            : "hg-theme-default hg-layout-default myTheme"
        }
        buttonTheme={[
          {
            class: "hg-primary",
            buttons: "{enter}",
          },
          {
            class: "hg-close",
            buttons: "{close}",
          },
        ]}
        preventMouseDownDefault
        disableCaretPositioning
        onChangeAll={(event) => HandleOnChange(event.default)}
        onKeyPress={HandleOnKeyPress}
      />
      <KeyboardPositionControlWrapper>
        <span>Mover Teclado</span>
        <Button
          onClick={() => HandlePosition({ ...keyboardPosition, y: "top" })}
          onMouseDown={(event) => event.preventDefault()}
        >
          <span className="material-icons-outlined">expand_less</span>
        </Button>
        <Button
          onClick={() => HandlePosition({ ...keyboardPosition, y: "bottom" })}
          onMouseDown={(event) => event.preventDefault()}
        >
          <span className="material-icons-outlined">expand_more</span>
        </Button>
        <Button
          onClick={() => HandlePosition({ ...keyboardPosition, x: "right" })}
          onMouseDown={(event) => event.preventDefault()}
        >
          <span className="material-icons-outlined">chevron_right</span>
        </Button>
        <Button
          onClick={() => HandlePosition({ ...keyboardPosition, x: "left" })}
          onMouseDown={(event) => event.preventDefault()}
        >
          <span className="material-icons-outlined">chevron_left</span>
        </Button>
      </KeyboardPositionControlWrapper>
    </KeyboardWrapper>
  );
};

export default Keyboard;
