import React, { useEffect, useMemo, useRef } from "react";
import {
  AgGrid,
  AgGridActions,
  DirectionalArrowsContainer,
  DirectionalArrow,
} from "./style";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import { currencyMask } from "../../helpers/masks";
import { isSmartPos } from "../../../aaone/configuration";
import { SimpleItemTableSmartPos } from "../SimpleItemTableSmartPos";
import classNames from "classnames";

const AmountCell = (row) => <span>{currencyMask(row.value)}</span>;

const ReversedCell = (row) => <span>{row.value ? "Sim" : "Não"}</span>;

const Actions = (row, handlePrinter, handleChargeback) => (
  <AgGridActions>
    <button
      className="btn-card-list"
      disabled={row.data.isReversed || row.data.armoredCarId}
      onClick={() => handleChargeback(row.data)}
    >
      <i className="material-icons">money_off</i>
      <span>Estornar</span>
    </button>
    <button
      className="btn-card-list"
      onClick={() => handlePrinter(row.data.id)}
    >
      <i className="material-icons">print</i>
      <span>Imprimir</span>
    </button>
  </AgGridActions>
);

const TableList = (props) => {
  const ROW_HEIGHT = 65;
  const agGridRef = useRef();

  const { listWithdraw, handlePrinter, handleChargeback } = props;

  const columns = [
    { title: "Nº Controle", propName: "referenceSequence" },
    { title: "Nº Abertura", propName: "openingCount" },
    { title: "Histórico", propName: "description" },
    { title: "Nº Envelope", propName: "numberEnvelop" },
    { title: "Cancelado", propName: "isReversed" },
    { title: "Valor", propName: "amount" },
    { title: "Ações", propName: "action" },
  ];

  useEffect(() => {
    if (agGridRef.current && listWithdraw.length > 0) {
      agGridRef.current.api.setRowData([]);
      agGridRef.current.api.setRowData(listWithdraw);
      agGridRef.current.api.sizeColumnsToFit();
    }
  }, [agGridRef, listWithdraw]);

  const scrollGrid = (direction) => {
    if (agGridRef.current.api?.getDisplayedRowCount() === 0) return;

    let nodeIndex = 0;
    const lastRowIndex = agGridRef.current.api?.getDisplayedRowCount() - 1;

    const displayedRows =
      Math.floor(
        agGridRef.current.api?.["gridBodyCon"].eBodyViewport.clientHeight /
          ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = agGridRef.current.api?.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = agGridRef.current.api?.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    if (agGridRef.current.api) {
      agGridRef.current.api.ensureIndexVisible(nodeIndex);
    }
  };

  const itemsSerializedSmartPos = useMemo(
    () =>
      listWithdraw.map((item) => {
        const columnSerialized = columns.map((currentColumn) => ({
          label: currentColumn.title,
          field: currentColumn.propName,
        }));

        const returnSerialized = columnSerialized.map((currentColumn) => {
          const hasElement = currentColumn.field === "action";

          const Element = hasElement
            ? () => Actions({ data: item }, handlePrinter, handleChargeback)
            : React.Fragment;

          const value = item[currentColumn.field] ?? "";

          return {
            label: currentColumn.label,
            children: hasElement ? <Element /> : value || null,
          };
        });

        return returnSerialized;
      }),
    [listWithdraw, handlePrinter, handleChargeback]
  );

  return (
    <>
      <AgGrid>
        <div className={classNames("ag-theme-balham monitor", { isSmartPos })}>
          {isSmartPos ? (
            <>
              {itemsSerializedSmartPos.map((item, index) => (
                <SimpleItemTableSmartPos
                  key={`simple-item-${index}-table-smart-pos`}
                  items={item}
                />
              ))}
            </>
          ) : (
            <AgGridReact
              ref={agGridRef}
              localeText={agGridLocalePtBr}
              headerHeight={40}
              rowBuffer={0}
              immutableData={true}
              rowHeight={ROW_HEIGHT}
              getRowNodeId={(data) => data.openingCount}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
              onRowDataUpdated={(params) => {
                params.api.sizeColumnsToFit();
              }}
              frameworkComponents={{
                Actions: (props) =>
                  Actions(props, handlePrinter, handleChargeback),
                AmountCell,
                ReversedCell,
              }}
            >
              {columns.map((column) => {
                const cellStyle = {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                  lineHeight: "15px",
                  wordBreak: "break-word",
                };

                const additionalProps = {
                  ...(column.propName === "referenceSequence" && {
                    width: 80,
                  }),
                  ...(column.propName === "openingCount" && {
                    width: 80,
                  }),
                  ...(column.propName === "description" && {
                    width: 90,
                    wrapText: true,
                  }),
                  ...(column.propName === "numberEnvelop" && {
                    width: 80,
                    wrapText: true,
                  }),
                  ...(column.propName === "isReversed" && {
                    width: 80,
                    cellRenderer: "ReversedCell",
                  }),
                  ...(column.propName === "amount" && {
                    width: 60,
                    cellRenderer: "AmountCell",
                    cellStyle: {
                      ...cellStyle,
                      fontWeight: "bold",
                    },
                  }),
                  ...(column.propName === "action" && {
                    cellRenderer: "Actions",
                  }),
                };

                return (
                  <AgGridColumn
                    key={column.propName}
                    colId={column.colId}
                    cellStyle={cellStyle}
                    field={column.propName}
                    {...additionalProps}
                    headerName={column.title}
                  />
                );
              })}
            </AgGridReact>
          )}
        </div>
        {!isSmartPos && (
          <DirectionalArrowsContainer>
            <DirectionalArrow className="top" onClick={() => scrollGrid("top")}>
              <span className="material-icons">keyboard_arrow_up</span>
            </DirectionalArrow>

            <DirectionalArrow
              className="bottom"
              onClick={() => scrollGrid("bottom")}
            >
              <span className="material-icons">keyboard_arrow_down</span>
            </DirectionalArrow>
          </DirectionalArrowsContainer>
        )}
      </AgGrid>
    </>
  );
};

export default TableList;
