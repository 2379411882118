import styled from 'styled-components';

export const Amount = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 3rem;
        font-weight: 700;
    }

    strong {
        font-size: 3.4rem;
        margin-left: 10px;
        color: red
    }
`;