import React, { useState, useEffect } from "react";

import { Content, Button } from "../../shared/components/modules";
import { useTranslation } from "react-i18next";

import logo from "../../assets/ragazo-mais.svg";
import logo2 from "../../assets/banner-habibers.png";
import logoDefault from "../../assets/banner.png";
import { isDisableLoyaltPoints } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { useViewDispatch } from "../../../context";

const PointsContainer = () => {
  const viewDispatch = useViewDispatch();
  const { isLayout } = useLayoutAa();
  const { t } = useTranslation();
  const { getTranslateName } = useLayoutAa();
  const fidelityPage = getTranslateName(t, "fidelityPage.intro") || "Olá";
  const yourPoints = getTranslateName(t, "fidelityPage.your_points") || "Você possui";
  const pointsTotal = getTranslateName(t, "fidelityPage.points_total") || "PONTOS";
  const buttons = getTranslateName(t, "buttons.toContinue") || "Continuar";

  const [name, setName] = useState("");
  const [points, setPoints] = useState(0);

  const layouts = "RAGAZZOEXPRESS";
  const hasLayout = isLayout(layouts);
  const isNotHabibsLayout = !isLayout("HABIBS");

  const imageFid = () => {
    return hasLayout ? logo : isNotHabibsLayout ? logoDefault : logo2;
  };

  const textFid = () => {
    return isNotHabibsLayout ?  "Fidelidade" : "Habibers";
  };

  const nextStep = () => {
    viewDispatch("step.home");
  };

  useEffect(() => {
    setName(window.localStorage.getItem("nome"));
    setPoints(window.localStorage.getItem("saldoAtual"));
  }, []);

  return (
    <>
      <Content
        bgColor="background-fidelity hasIMG"
        contentChildren={true}
        className="full"
        hasImg={true}
        srcImg={imageFid()}
      >
        <div className="box-points">
          <h1 className="title-points">{fidelityPage} {name}</h1>
          {!isDisableLoyaltPoints && (
            <>
              <p className="text-point">{yourPoints}</p>
              <p className="points-value">
                <strong>{points}</strong>
              </p>
              <p className="points-value">
                <strong>{pointsTotal}</strong>
              </p>
              <p className="text-point">{textFid()}</p>
            </>
          )}
        </div>

        <footer className="footer template-columns-1" style={{ width: "75%", height: "128px"}}>
          <Button
            text={buttons}
            buttonUIType="primary"
            buttonUI={"normal"}
            handleClick={() => nextStep()}
          />
        </footer>
      </Content>
    </>
  );
};

export default PointsContainer;
