import { apiProducts } from "./api";

export const withdrawn = async () => {
  try {
    const token = sessionStorage.getItem("@AAOne:token");

    const { data } = await apiProducts.get("/api/locationorder", {
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      }
    });

    return data;
  } catch (error) {
    console.error(`Error in withdrawn :: `, error);
    return Promise.reject("Error in withdrawn ::");
  }
};
