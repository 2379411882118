import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import { getSetting } from "../../helpers/userSettings";
import { InputGroup, CardCheck, InputTotal, Container } from "./style";

const CashCheckTotalMoney = (props) => {
  const { initSystemCount, initManualCount, useTouchKeyboard } = props;

  const [cartPosition, setCartPosition] = useState("");
  const [systemCount, setSystemCount] = useState("R$ 0,00");
  const [manualCount, setManualCount] = useState("R$ 0,00");
  const [colorDifference, setColorDifference] = useState("");

  const handleHighlightColorDifference = () => {
    let valueDifference = initSystemCount - initManualCount;

    if (valueDifference > 0) {
      setColorDifference("rgb(255 23 23)");
    }

    if (valueDifference === 0) {
      setColorDifference("rgb(27, 79, 27)");
    }

    if (valueDifference < 0) {
      setColorDifference("rgb(22 193 22)");
    }
  };

  useEffect(() => {
    setCartPosition(getSetting("cartPosition"));
  }, []);

  useEffect(() => {
    if (
      typeof initSystemCount !== "undefined" &&
      typeof initManualCount !== "undefined"
    ) {
      setSystemCount(initSystemCount);
      setManualCount(initManualCount);

      handleHighlightColorDifference();
    }
  }, [initSystemCount, initManualCount, handleHighlightColorDifference]);

  const amountFormatted = (value) => {
    return Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  return (
    <Container
      visible={true}
      layout={useTouchKeyboard ? "numeric" : ""}
      cartPosition={cartPosition}
    >
      <CardCheck colorBackground={"white"}>
        <InputGroup>
          <label>Contagem Sistemas</label>
          <InputTotal value={amountFormatted(systemCount)} readOnly disabled />
        </InputGroup>
        <InputGroup>
          <label>Contagem Manual</label>
          <InputTotal value={amountFormatted(manualCount)} readOnly disabled />
        </InputGroup>
        <InputGroup>
          <label>Diferença</label>
          <InputTotal
            value={amountFormatted(manualCount - systemCount)}
            readOnly
            disabled
            colorBackground={colorDifference}
          />
        </InputGroup>
      </CardCheck>
    </Container>
  );
};

export default CashCheckTotalMoney;
