import OrientedSaleStepUseCase from "./OrientedSaleStepUseCase";
import UtilsUseCase from "../UtilsUseCase";

export default class SaleStepUseCase {
  #rawStep;
  #step;
  #saleId;
  #OrientedSaleStep;
  #OrientedSale;
  #chosenQuantity;
  #selectedEnvironment;

  constructor(rawStep, chosenQuantity, selectedEnvironment) {
    rawStep.classSale = this;
    this.#rawStep = rawStep;
    this.#saleId = new Date().valueOf();
    this.#OrientedSaleStep = null;
    this.#OrientedSale = null;
    this.#chosenQuantity = chosenQuantity;
    this.#selectedEnvironment = selectedEnvironment;

    this.initialization();
  }

  initialization() {
    this.initializationOrientedSaleSteps();
    this.handleProduct();
  }

  getSteps() {
    return { ...this.#step };
  }

  nextStep() {
    return this.#OrientedSaleStep.next();
  }

  backStep() {
    return this.#OrientedSaleStep.back();
  }

  goToStep(currentSequence) {
    return this.#OrientedSaleStep.goTo(currentSequence);
  }

  removeSelectedStep() {
    return this.#OrientedSaleStep.removeSelectedStep();
  }

  exitSteps() {
    return this.#OrientedSaleStep.exitSteps();
  }

  deselectProductStep(sequence, product, removeAllSameProductCode = true) {
    if (removeAllSameProductCode && product.amount > 1) {
      do {
        this.#OrientedSaleStep.deselectProductStep(sequence, product);
      } while (product.amount > 0);
    } else{
      this.#OrientedSaleStep.deselectProductStep(sequence, product)
    }

    this.checkDoneSteps();
  }

  selectedProductCurrentStep(product, amount = 1) {
    this.#OrientedSaleStep.selectedProductCurrentStep(product, amount);
    this.checkDoneSteps();
  }

  checkDoneSteps() {
    this.#step.doneSteps = this.#OrientedSaleStep.checkDoneSteps();
    return this.#OrientedSaleStep.checkDoneSteps();
  }

  getCurrentStep() {
    return this.#OrientedSaleStep.getCurrentStep();
  }

  setObservation(observation) {
    this.#step.observation = observation;
  }

  doubleProductQuantities() {
    this.#OrientedSaleStep.doubleProductQuantities();
    this.#OrientedSaleStep.exitSteps();
    this.#step.doneSteps = this.#OrientedSaleStep.checkDoneSteps();
  }

  handleProduct() {
    let currentValue = this.#rawStep.value;
    if (this.#rawStep?.categoryIsFidelity) {
      currentValue = 0.01;
    }
    const Utils = new UtilsUseCase();

    this.#step = {
      _id: this.#rawStep._id,
      saleId: this.#saleId,
      description: Utils.getDescription(
        this.#rawStep.allDescriptions,
        this.#selectedEnvironment,
      ),
      allDescriptions: this.#rawStep.allDescriptions,
      observation: "",
      fractionalQuantity: this.#rawStep.fractionalQuantity ?? false,
      categoryId: this.#rawStep.categoryId,
      value: currentValue,
      mixValue: this.#rawStep.mixValue,
      productCode: this.#rawStep.productCode,
      doneSteps: this.#OrientedSaleStep.checkDoneSteps(),
      steps: this.#OrientedSaleStep.getSteps(),
      isOnlyLevel: this.#OrientedSaleStep.getIsOnlyLevel(),
      nuuidOne: this.#rawStep.nuuidOne,
      promotion: this.#rawStep.promotion,
      categoryIsFidelity: !!this.#rawStep.categoryIsFidelity,
      promoCode: this.#rawStep.promoCode ? this.#rawStep.promoCode : "",
      tablePosition: this.#rawStep.tablePosition ?? null, // Utilizado para identificar qual a posição na mesa
      ...(this.#rawStep?.pointPrice && {
        pointPrice: this.#rawStep.pointPrice,
      }),
    };
  }

  initializationOrientedSaleSteps() {
    this.#OrientedSaleStep = new OrientedSaleStepUseCase(
      this.#rawStep,
      this.#chosenQuantity,
      this,
      this.#selectedEnvironment,
    );
  }
}
