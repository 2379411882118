import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Content, Button } from "../../shared/components/modules";

import logo from "../../assets/ragazo-mais.svg";

import leitoracartao from "../../assets/leitora-cartao.png";
import { getThemeSettings } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";
import { useViewDispatch } from "../../../context";

const FidelityConfirmation = () => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const { getTranslateName } = useLayoutAa();

  const [hasError, setHasError] = useState(false);
  const [questions, setQuestions] = useState([]);

  const buttonBackText =
    getTranslateName(t, "buttons.back") ||
    getThemeSettings?.text?.button_back_fidelity_confirmation ||
    "Voltar";
  const buttonCancelText =
    getTranslateName(t, "buttons.cancel") ||
    getThemeSettings?.text?.button_cancel_fidelity_confirmation ||
    "Cancelar";
  const retryOptionText =
    getTranslateName(t, "retry_option") ||
    getThemeSettings?.text?.button_try_again_fidelity_confirmation ||
    "Tentar Novamente";

  useEffect(() => {
    // setTextConfirm(window.localStorage.getItem('VARIAVEL DA PERGUNTA'))
    setQuestions([
      getThemeSettings?.text?.ask_the_day_of_birth
        ? getThemeSettings.text.ask_the_day_of_birth
        : "Qual o dia do seu nascimento?",
      getThemeSettings?.text?.ask_the_month_of_birth
        ? getThemeSettings.text.ask_the_month_of_birth
        : "Qual o mês do seu nascimento?",
      getThemeSettings?.text?.ask_the_year_of_birth
        ? getThemeSettings.text.ask_the_year_of_birth
        : "Qual o ano do seu nascimento?",
    ]);
  }, []);

  let indice = 0;

  const ImageAndGoBack = () => {
    return (
      <>
        <img
          src={leitoracartao}
          alt={leitoracartao}
          className="leitora-confirm"
        />
        <footer
          className="footer template-columns-1"
          style={{ width: "75%", height: "128px" }}
        >
          <Button
            text={buttonBackText}
            buttonUIType="outline-primary"
            buttonUI={"normal"}
            handleClick={() => {
              console.log("OLÁ, FOI AQUI 2");
              viewDispatch("step.fidelity");
            }}
          />
        </footer>
      </>
    );
  };

  const NoImageAndTry = () => {
    return (
      <footer className="footer">
        <Button
          text={buttonCancelText}
          buttonUIType="outline-primary"
          buttonUI={"normal"}
          handleClick={() => {
            viewDispatch("step.home");
          }}
        />

        <Button
          text={retryOptionText}
          buttonUIType="primary"
          buttonUI={"normal"}
          handleClick={() => {
            OtherTry();
          }}
        />
      </footer>
    );
  };

  const changeStatus = () => {
    setHasError(!hasError);
  };

  const OtherTry = () => {
    viewDispatch("step.fidconfirm");
    questions[indice]++;
  };

  return (
    <>
      <Content
        bgColor="background-primary hasIMG"
        contentChildren={true}
        className="full fidconfirm"
        hasImg={true}
        srcImg={logo}
      >
        <h1 className="titulo" onClick={changeStatus}>
          Confirme seus dados para continuar
        </h1>

        <div className="box">
          <p>
            <strong> {questions[indice]} </strong>
            <br />
            (Digite os números na leitora de cartão)
          </p>
        </div>

        {!hasError ? <ImageAndGoBack /> : <NoImageAndTry />}
      </Content>
    </>
  );
};

export default FidelityConfirmation;
