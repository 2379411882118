import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import Slider from "react-slick";
import {
  NextItemSlider,
  PreviousItemSlider,
} from "../../../components/ArrowsCarousel";
import { ItemProduct } from "../../../components/Products/Item";
import { useAuth } from "../../../hook/authHook";
import { useVertical } from "../../../hook/verticalHook";
import { useCart } from "../../../hook/cartHook";
import { getProductWithAllPossibilities } from "../../../../services/catalogService";
import { useLoading } from "../../../hook/loadingHook";
import { FidelityProductsContent } from "./styles";
import Button from "../../Button";
import { MdDeleteForever } from "react-icons/md";
import classNames from "classnames";

const FidelitySelectProducts = ({
  selectedCampaign,
  setSelectedCampaign,
  breadcrumbName,
}) => {
  const { reshop } = useAuth();
  const { setShowLoading } = useLoading();
  const { addProductToCart } = useCart();
  const { isVerticalScreen } = useVertical();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const sliderRef = useRef();

  useEffect(() => {
    if (sliderRef) {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 200);
    }
  }, [selectedCampaign, currentStep]);

  const responsiveSerialize = useCallback(
    (options) => {
      const { breakpoint, quantity, rows = 2 } = options;

      return {
        breakpoint,
        settings: {
          rows: rows,
          slidesToShow: quantity,
          slidesToScroll: quantity,
        },
      };
    },
    [reshop.optionalCampaigns.length]
  );

  const settingsCarouselProducts = useMemo(() => {
    return {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      rows:
        selectedCampaign?.rules?.[currentStep]?.products.length <= 3 ? 1 : 4,
      slidesToShow: 7,
      slidesToScroll: 7,
      prevArrow: <PreviousItemSlider />,
      nextArrow: <NextItemSlider />,
      responsive: [
        responsiveSerialize({
          breakpoint: 1920,
          quantity: 7,
          rows:
            selectedCampaign?.rules?.[currentStep]?.products.length <= 3
              ? 1
              : isVerticalScreen
              ? 4
              : 3,
        }),
        responsiveSerialize({
          breakpoint: 1600,
          quantity: 6,
          rows:
            selectedCampaign?.rules?.[currentStep]?.products.length <= 3
              ? 1
              : 3,
        }),
        responsiveSerialize({
          breakpoint: 1366,
          quantity: 5,
          rows:
            selectedCampaign?.rules?.[currentStep]?.products.length <= 3
              ? 1
              : 2,
        }),
        responsiveSerialize({
          breakpoint: 1080,
          quantity: 3,
          rows:
            selectedCampaign?.rules?.[currentStep]?.products.length <= 3
              ? 1
              : 3,
        }),
        responsiveSerialize({
          breakpoint: 480,
          quantity: 3,
          rows:
            selectedCampaign?.rules?.[currentStep]?.products.length <= 3
              ? 1
              : 2,
        }),
      ],
    };
  }, [isVerticalScreen, responsiveSerialize, selectedCampaign, currentStep]);

  // Verifica se todos os itens foram selecionados
  const hasItemsToSelect = useMemo(() => {
    const currQuantity = selectedProducts[currentStep]?.length ?? 0;

    return currQuantity < selectedCampaign?.rules[currentStep].quantity;
  }, [currentStep, selectedCampaign, selectedProducts]);

  const handleSelectProduct = useCallback(
    (productCode) => {
      if (hasItemsToSelect) {
        let _selectedProducts = [...selectedProducts];

        if (_selectedProducts[currentStep]) {
          _selectedProducts[currentStep].push(productCode);
        } else {
          _selectedProducts.push([productCode]);
        }

        setSelectedProducts(_selectedProducts);
      }
    },
    [hasItemsToSelect, currentStep, selectedProducts]
  );

  const handleRemoveProduct = useCallback(
    (productCode) => {
      let _selectedProducts = [...selectedProducts];

      const index = _selectedProducts[currentStep]?.findIndex(
        (code) => code === productCode
      );

      if (index >= 0) {
        _selectedProducts[currentStep].splice(index, 1);

        setSelectedProducts([..._selectedProducts]);
      }
    },
    [currentStep, selectedProducts]
  );

  const reshopProducts = useCallback(() => {
    return (
      selectedCampaign?.rules?.[currentStep].products?.map(
        ({ product }, index) => {
          // Quantas vezes o produto foi escolhido
          const productQuantity =
            selectedProducts?.[currentStep]?.filter(
              (productCode) => productCode === product.productCode
            ).length ?? 0;

          return (
            <div className="item-product">
              <div
                className={classNames(
                  {
                    hide: productQuantity < 1,
                  },
                  "product-actions"
                )}
              >
                <div
                  className="remove-product"
                  role="button"
                  onClick={() => handleRemoveProduct(product.productCode)}
                >
                  <MdDeleteForever className="material-icons icon" />
                </div>

                <div className="quantity">{productQuantity} UN</div>
              </div>

              <ItemProduct
                showValue={false}
                data={{
                  ...product,
                  product: {
                    ...product,
                    displayValue: false,
                  },
                  pointPrice: product?.pointPrice,
                  code: String(product.productCode),
                }}
                onClick={() => handleSelectProduct(product.productCode)}
                key={String(product._id + "-" + index)}
              >
                {
                  product.allDescriptions ?
                    product.allDescriptions.descriptionPDV :
                    product.description
                }
              </ItemProduct>
            </div>
          );
        }
      ) ?? <></>
    );
  }, [
    selectedCampaign.rules,
    currentStep,
    selectedProducts,
    handleRemoveProduct,
    handleSelectProduct,
  ]);

  const handleNextStep = useCallback(
    (newStep) => {
      if (selectedCampaign.rules.length > newStep) {
        setCurrentStep(newStep);
      } else {
        try {
          setShowLoading();
          const reqsProducts = [];

          selectedProducts.forEach((steps) =>
            steps.forEach((code) =>
              reqsProducts.push(getProductWithAllPossibilities(code))
            )
          );

          Promise.all(reqsProducts).then((products) => {
            products.forEach(({ product }) => {
              product.promoCode = selectedCampaign?.promoCode;
              addProductToCart({
                ...product,
                quantityToCart: 1,
              });
              setShowLoading(false);
            });
          });

          setSelectedCampaign({});
        } catch (err) {
          setShowLoading(false);
          if (window.Android) {
            console.log(`error on handleNextStep => ${JSON.stringify(err)}`);
          } else {
            console.error(err);
          }
        }
      }
    },
    [
      addProductToCart,
      selectedCampaign.rules,
      selectedProducts,
      setSelectedCampaign,
      setShowLoading,
    ]
  );

  useEffect(() => {
    // Se existir apenas um produto naquela etapa ele adiciona automaticamente ao carrinho
    if (
      selectedCampaign.rules[currentStep].products.length === 1 &&
      hasItemsToSelect
    ) {
      handleSelectProduct(
        selectedCampaign.rules[currentStep].products[0].product.productCode
      );
    } else if (
      // Se existir produto simples ele adiciona ele automaticamente ao carrinho
      selectedCampaign.rules[currentStep].products.length === 1 &&
      selectedProducts?.[currentStep].length === 1 &&
      currentStep === 0 &&
      !hasItemsToSelect
    ) {
      handleNextStep(currentStep + 1);
    }
  }, [
    selectedCampaign,
    currentStep,
    handleSelectProduct,
    hasItemsToSelect,
    handleNextStep,
    selectedProducts,
  ]);

  return (
    <FidelityProductsContent>
      <div className="carousel__optionalCampaign">
        <div className="breadcrumb">
          <span role="button" onClick={() => setSelectedCampaign({})}>
            {breadcrumbName}
          </span>{" "}
          {">"} {selectedCampaign.campaignName}
        </div>
        <Slider {...settingsCarouselProducts}>{reshopProducts()}</Slider>
        <div className="actions">
          <Button
            className="btn-default btn-primary products-back"
            onClick={() =>
              currentStep + 1 > 1
                ? handleNextStep(currentStep - 1)
                : setSelectedCampaign({})
            }
          >
            Voltar
          </Button>
          <Button
            className="products-next btn-primary"
            onClick={() => handleNextStep(currentStep + 1)}
            disabled={hasItemsToSelect}
          >
            Avançar{" "}
            {`(${selectedProducts?.[currentStep]?.length ?? 0} / ${
              selectedCampaign?.rules[currentStep].quantity
            })`}
          </Button>
        </div>
      </div>
    </FidelityProductsContent>
  );
};

export default FidelitySelectProducts;
