import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";

import { CartProvider } from "../cartContext";
import { CategoriesNavigateProvider } from "../categoriesNavigateContext";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import { TABLELIST_INFO } from "../../constants/keyNamesSessionStorage";
import { SallerTableContainerProvider } from "../sallerTableContext";

export const WrapperRoutersContext = createContext({});

export const WrapperRoutersProvider = ({ children }) => {
  const { showAlert } = useDialog();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isSidebarCartHide, setIsSidebarCartHide] = useState(false);
  const [isSidebarRight, setIsSidebarRight] = useState(false);
  const [infoTable, setInfoTable] = useState(() => {
    try {
      return JSON.parse(sessionStorage.getItem(TABLELIST_INFO)) || {};
    } catch (error) {
      return {};
    }
  });
  const { setShowLoading } = useLoading(false);
  const [infoTef, setInfoTef] = useState(null);

  const setRightSidebarCart = useCallback((isRight = true) => {
    setIsSidebarRight(isRight);
  }, []);

  const togglePositionSidebarCart = useCallback(() => {
    setIsSidebarRight((isRight) => !isRight);
  }, []);

  const setVisibleSidebarCart = useCallback((isVisible = true) => {
    setIsSidebarCartHide(!isVisible);
  }, []);

  const toggleVisibleSidebarCart = useCallback(() => {
    setIsSidebarCartHide((isVisible) => !isVisible);
  }, []);

  const updateTablesTef = useCallback(() => {
    try {
      if (window.Android) {
        setShowLoading(true, "Atualizando tabelas, aguarde!");
        window.Android.clearTable();
        showAlert({
          message:
            "A carga de tabela será realizada na próxima venda com o TEF.",
        });
        setShowLoading(false);
      }
    } catch (err) {
      showAlert({
        message: "Não foi possível realizar a atualização de tabelas!",
      });
      setShowLoading(false);
      console.log(`error on clearTable: ${JSON.stringify(err)}`);
    }
  }, [setShowLoading, showAlert]);

  const getInfoTef = useCallback(() => {
    try {
      if (window.Android) {
        window.processInfoTef = (data) => {
          try {
            setInfoTef(JSON.parse(data));

            console.log(JSON.parse(data));
          } catch (err) {
            console.log(`error on processInfoTef: ${JSON.stringify(err)}`);
          }
        };

        window.Android.getInfoTef();
      }
    } catch (err) {
      setShowLoading(false);
      console.log(`error on getInfoTef: ${JSON.stringify(err)}`);
    }
  }, [setShowLoading]);

  const value = useMemo(
    () => ({
      isSidebarRight,
      isSidebarCartHide,
      setRightSidebarCart,
      setVisibleSidebarCart,
      toggleVisibleSidebarCart,
      togglePositionSidebarCart,
      isOpenMenu,
      setIsOpenMenu,
      updateTablesTef,
      getInfoTef,
      infoTable,
      setInfoTable,
      infoTef,
    }),
    [
      isSidebarRight,
      isSidebarCartHide,
      setRightSidebarCart,
      setVisibleSidebarCart,
      toggleVisibleSidebarCart,
      togglePositionSidebarCart,
      isOpenMenu,
      setIsOpenMenu,
      updateTablesTef,
      getInfoTef,
      infoTable,
      setInfoTable,
      infoTef,
    ]
  );

  return (
    <WrapperRoutersContext.Provider value={value}>
      <CategoriesNavigateProvider>
        <CartProvider>
          <SallerTableContainerProvider>
            {children}
          </SallerTableContainerProvider>
        </CartProvider>
      </CategoriesNavigateProvider>
    </WrapperRoutersContext.Provider>
  );
};
