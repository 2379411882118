import React from 'react';
import { useViewState } from '../../context';
import { ErrorContainer } from '../container/Error';

import {
  ServiceStatusProxyContainer,
  BannerContainer,
  HomeContainer,
  OrderSummaryContainer,
  WithdrawnContainer,
  CPFContainer,
  LoginContainer,
  CarIdentificationContainer,
  PhoneIdentificationContainer,
  OrderIdentificationContainer,
  PaymentContainer,
  CupomContainer,
  PrismaContainer,
  FidelidadeContainer,
  PointsContainer,
  FidelityConfirmation,
  ScrollContainer,
  PedeProntoContainer,
  PaymentTransitionContainer,
} from '../container/modules';

import { ErrorBoundaries } from '../shared/components/error-boundary';
import { ErrorBoundary } from '../shared/components/error-boundary/boundary';

const Page = props => {
    const state = useViewState();

    const viewsMap = viewId => {
        const views = {
            'error': <ErrorContainer />,
            'step.serviceStatusProxy': <ServiceStatusProxyContainer modalTimer={props.isActiveModalReset} />,
            'step.login': <LoginContainer />,
            'step.banner': <BannerContainer modalTimer={props.isActiveModalReset} />,
            'step.home':  <ScrollContainer><HomeContainer /></ScrollContainer>,
            'step.orderSummary': <ScrollContainer><OrderSummaryContainer /></ScrollContainer>,
            'step.withdrawn': <ScrollContainer><WithdrawnContainer /></ScrollContainer>,
            'step.prisma': <PrismaContainer />,
            'step.cpf': <ScrollContainer><CPFContainer /></ScrollContainer>,
            'step.carIdentification': <ScrollContainer><CarIdentificationContainer/></ScrollContainer>,
            'step.tellIdentification': <ScrollContainer><PhoneIdentificationContainer/></ScrollContainer>,
            'step.orderIdentification': <ScrollContainer><OrderIdentificationContainer /></ScrollContainer>,
            'step.payment': <ScrollContainer><PaymentContainer /></ScrollContainer>,
            'step.cupom': <CupomContainer modalTimer={props.isActiveModalReset} />,
            'step.fidelity': <ScrollContainer><FidelidadeContainer /></ScrollContainer>,
            'step.fidconfirm': <FidelityConfirmation/>,
            'step.points': <ScrollContainer><PointsContainer /></ScrollContainer>,
            'step.pedePronto': <ScrollContainer><PedeProntoContainer modalTimer={props.isActiveModalReset} /></ScrollContainer>,
            'step.paymentTransition': <PaymentTransitionContainer modalTimer={props.isActiveModalReset} msg={ props.msgRef } />
        }

        return <ErrorBoundaries><ErrorBoundary views={views} viewId={viewId} /></ErrorBoundaries>;
    }

    return(
        <>
            { viewsMap(state.view) }
        </>
    )
}

export default Page;
