import Configuration, {getStoreServerId, getStoreFranchiseId, getStoreId, getRetailerId } from "../aaone/configuration";
import { openDrawer } from "../pdv-one/helpers/openDrawer";
import { apiProducts } from "./api";

function HandleError (message) {
  this.name = 'Error auth service';
  this.message = message || 'Falha no processo de login.';
  this.stack = (new Error()).stack;
}

function HandleRequest (requests) {
  let status = true;
  let message = "Os campos estão todos como esperado.";
  const rules = {
    login: {
      type: "string",
      required: true,
      message: "O campo de login é obrigatório",
    },
    password: {
      type: "string",
      required: true,
      message: "O campo de senha é obrigatório",
    },
  }

  Object.keys(requests).forEach(request => {
    const value = requests[request];

    if (status) {
      const requestValidate = rules[request];

      if (requestValidate) {
        if (requestValidate.required) {
          if (!value) {
            status = false;
            message = requestValidate.message;
          }
        }

        if (!typeof value === requestValidate.type) {
          status = false;
          message = requestValidate.message;
        }
      }
    }
  });

  return { status, message };
}

export const authentication = async (login, password, clientType, clientVersion, generateToken = true) => {
  const { terminalid } = Configuration;
  try {
    const requestValidate = HandleRequest({
      login,
      password,
    });

    if (!requestValidate.status) {
      throw new HandleError(requestValidate.message);
    }

    const response = await apiProducts.post("/api/authenticate", {
      storeServerId: getStoreServerId(),
      storeFranchiseId: getStoreFranchiseId(),
      storeId: getStoreId(),
      retailerId: getRetailerId(),
      login,
      password,
      terminalid,
      clientType,
      clientVersion,
      generateToken
    });
    const data = response?.data;

    if (response.status !== 200) {
      throw new HandleError(response?.data?.message);
    }

    if(data && data?.token){
      if (window?.Android && window?.Android?.tokenStoragePDV) {
        window.Android.tokenStoragePDV(`${data.token}`);
      }
      window.sessionStorage.setItem("Access_Token", `Bearer ${data.token}`);
    }
    if(window.sessionStorage.getItem("draweropen")){
      openDrawer();
    }

    return data;
  } catch (error) {
    return Promise.reject(error?.message);
  }
};
