import { getLayoutName } from "../../configuration";

export const currentLayoutName = getLayoutName();

export const isLayoutUtil = (layoutName) => {
  const escLayoutName = String(layoutName).toLowerCase().trim();
  const getTheCurrentLayout = currentLayoutName?.toLowerCase().trim();

  if (Array.isArray(layoutName)) {
    return layoutName
      .map((layout) => String(layout).toLowerCase())
      .includes(escLayoutName);
  }

  const isCurrentLayout = getTheCurrentLayout === escLayoutName;

  return isCurrentLayout;
};
