import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SelectBasic extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    propToShow: PropTypes.string,
    propToValue: PropTypes.string,
  }

  static defaultProps = {
    children: undefined,
    propToShow: null,
    propToValue: null,
  }

  getDescription = item => {
    if (!item) {
      return '';
    } else if (item && this.props.propToShow) {
      return item[this.props.propToShow];
    }

    return item.toString();
  }

  getValueOption = item => {
    if (item && this.props.propToValue) {
      return item[this.props.propToValue];
    }

    return JSON.stringify(item);
  }

  renderItem = (item, index) => {
    const value = this.getValueOption(item);

    return (
      <option key={index} value={value}>
        {this.getDescription(item)}
      </option>
    );
  };

  handleChange = event => {
    if (this.props.onChange) {
      let toReturn = event.target.value;
      if (toReturn && !this.props.propToValue) {
        toReturn = JSON.parse(toReturn);
      }
      this.props.onChange(toReturn);
    }
  }

  render() {
    const { children, options } = this.props;

    var value = this.props.value;
   
    if (!this.props.propToValue) {
      value = JSON.stringify(value);
    }
    return (
      <select value={value} onChange={this.handleChange} className="control" disabled={this.props.disabled ? true : null}>
        {/* {!this.value && <option key="unset" value={null} />} */}
        {options && options.map(this.renderItem)}
        {children && children}
      </select>
    );
  }
}

export default SelectBasic