import React from "react";

import ButtonWrapper from "./style";

const Button = (props) => (
  <>
    <ButtonWrapper
      disabled={props.disabled}
      style={{ width: props.width }}
      className={`button-${props.buttonUIType} button--${props.buttonUI} ${props.className}`}
      onClick={props.handleClick}
    >
      {props.text}
    </ButtonWrapper>
  </>
);

export default React.memo(Button);
