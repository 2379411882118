import axios from "axios";
import { getProductsAll, getStoreServerId, getStoreFranchiseId, getStoreId, getRetailerId } from "../aaone/configuration";
import { TOKEN_USER_AUTH_KEY_NAME } from "../pdv-one/constants/keyNamesSessionStorage";

export const getUsers = async () => {
  try {
    const token = sessionStorage.getItem(TOKEN_USER_AUTH_KEY_NAME);
    const baseUrl = getProductsAll();
    const resp = await axios.get(baseUrl + "/api/users", {headers: {
      Authorization: token,
      storeServerId: getStoreServerId(), storeFranchiseId: getStoreFranchiseId(), storeId: getStoreId(), retailerId: getRetailerId()
    }});

    const { data, statusText, status } = resp;

    if (status !== 200) {
      throw Error(statusText);
    }

    return data;
  } catch (error) {
    console.error("Error in getUsers :: ", error);
    return Promise.reject("Error in getUsers ::");
  }
};
