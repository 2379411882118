import { css } from "styled-components";

export const PrintCSS = css`
  .content-printer {
    font-family: Arial;
    max-width: 300px;
    display: block;
    box-sizing: border-box;
  }

  table td.shrink {
    white-space: nowrap;
    /* width: 200px; */
  }

  table td.expand {
    width: 99%;
    text-align: right;
  }
`;
