import { LINXPAY_POSID } from "../pdv-one/constants/keyNamesSessionStorage";
import { QRLINX_STATUS } from "../pdv-one/constants/qrLinxStatus";
import { formatDateHelper } from "../pdv-one/helpers/formatDateHelper";
import { printQRCodeCoupon } from "../pdv-one/helpers/printer/printQRCodeCoupon";
import { printQRcodeCancelCoupon } from "../pdv-one/helpers/printer/printQRcodeCancelCoupon";
import { apiProducts } from "./api";

export const activatePDVService = (params) => {
  try {
    apiProducts.patch("api/qrcode/activate").then((res) => {
      const { data } = res || {};

      if (data?.data) {
        sessionStorage.setItem(LINXPAY_POSID, data?.data?.pos_id);
      }
    });
  } catch (err) {
    console.error(err);
  }
};

/**
 * @deprecated
 *
 * Cria o pagamento QRLinx
 *
 * @param {Object} params
 * @param {Number} params.amount // Valor total da venda
 */
export const createPaymentService = async (params, cbSuccess, cbError) => {
  try {
    const payHubPosId = sessionStorage.getItem(LINXPAY_POSID);

    return apiProducts
      .post("api/qrcode/payment", {
        order_numberext: formatDateHelper(new Date(), "yyyyMMddHHss"),
        pos_id: payHubPosId,
        amount: params.amount,
        subtotal: params.amount,
        payments: [
          {
            value: params.amount,
          },
        ],
      })
      .then((res) => {
        if (res?.data?.data?.payment_id) {
          checkAndConfirmPayment(
            {
              payment_id: res.data.data.payment_id,
              amount: params.amount,
            },
            cbSuccess
          );
        } else {
          cbError();
        }

        return res;
      });
  } catch (err) {
    console.error(err);
    cbError();
  }
};

/**
 * Cria o pagamento QRLinx
 *
 * @param {Object} params
 * @param {Number} params.amount // Valor total da venda
 */
export const createPaymentLinxPayService = async (params, cbError) => {
  try {
    const payHubPosId = sessionStorage.getItem(LINXPAY_POSID);

    return apiProducts
      .post("api/qrcode/payment", {
        order_numberext: formatDateHelper(new Date(), "yyyyMMddHHss"),
        pos_id: payHubPosId,
        amount: params.amount,
        subtotal: params.amount,
        payments: [
          {
            value: params.amount,
          },
        ],
      })
      .then((res) => {
        if (!res?.data?.data?.payment_id) {
          cbError();
        }

        return res;
      });
  } catch (err) {
    console.error(err);
    cbError();
  }
};

/**
 * Verifica o status do pagamento QRLinx
 *
 * @param {String} payment_id
 */
const checkPaymentService = async (payment_id) => {
  try {
    return apiProducts.get(`api/qrcode/check/${payment_id}`);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Confirma o pagamento QRLinx
 *
 * @param {Object} params
 * @param {Number} params.payment_id // id do pagamento
 * @param {Number} params.amount // Valor total da venda
 */
export const confirmPaymentService = async (params) => {
  const payHubPosId = sessionStorage.getItem(LINXPAY_POSID);

  return apiProducts.post(`api/qrcode/order/confirm`, {
    pos_id: payHubPosId,
    payments: [
      {
        payment_id: params.payment_id,
        value: params.amount,
      },
    ],
  });
};

/**
 * Confirma o pagamento QRLinx
 *
 * @param {Object} params
 * @param {Number} params.payment_id // id do pagamento
 * @param {Number} params.amount // Valor total da venda
 */
export const cancelPaymentService = async (params) => {
  try {
    const payHubPosId = sessionStorage.getItem(LINXPAY_POSID);

    return await apiProducts
      .patch(`api/qrcode/cancel`, {
        payment_id: params.payment_id,
        pos_id: payHubPosId,
        value: params.amount,
        cancel_numberext: formatDateHelper(new Date(), "yyyyMMddHHss"),
        reason_message: "Realizado estorno venda",
      })
      .then(({ data }) => {
        printQRcodeCancelCoupon(data.data);

        return data;
      });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Verifica se um pagamento LinxPay foi realizado
 *
 * @param {Object} params
 * @param {Number} params.payment_id // id do pagamento
 * @param {Number} params.amount // Valor total da venda
 */
export const checkPayment = async (params, cbSuccess) => {
  return await checkPaymentService(params.payment_id).then(
    async ({ data: dataCheck }) => {
      console.log(dataCheck);

      if (
        dataCheck.data.payment_status.id === QRLINX_STATUS.PAGAMENTO_REALIZADO
      ) {
        if (cbSuccess) {
          cbSuccess(dataCheck);
        }

        return dataCheck;
      } else {
        setTimeout(() => {
          if (
            dataCheck.data.payment_status.id !== QRLINX_STATUS.FINALIZADO &&
            dataCheck.data.payment_status.id !== QRLINX_STATUS.DESFEITO
          ) {
            checkPayment(params, cbSuccess);
          }
        }, 3000);

        return dataCheck;
      }
    }
  );
};

/**
 * Confirma um pagamento QRLinx e imprime o comprovante
 *
 * @param {Object} params
 * @param {Number} params.payment_id // id do pagamento
 * @param {Number} params.amount // Valor total da venda
 */
export const confirmPayment = async (params) => {
  return await confirmPaymentService(params).then(({ data: dataConfirm }) => {
    printQRCodeCoupon(dataConfirm.data.payments[0].report);

    return dataConfirm;
  });
};

/**
 * @deprecated
 *
 * @param {Object} params
 * @param {Number} params.payment_id // id do pagamento
 * @param {Number} params.amount // Valor total da venda
 */
export const checkAndConfirmPayment = async (params, cbSuccess) => {
  return await checkPaymentService(params.payment_id).then(
    async ({ data: dataCheck }) => {
      if (
        dataCheck.data.payment_status.id === QRLINX_STATUS.PAGAMENTO_REALIZADO
      ) {
        return await confirmPaymentService(params).then(
          ({ data: dataConfirm }) => {
            printQRCodeCoupon(dataConfirm.data.payments[0].report);

            if (cbSuccess) {
              cbSuccess({ dataCheck, dataConfirm });
            }
            return { dataCheck, dataConfirm };
          }
        );
      } else {
        setTimeout(() => {
          if (
            dataCheck.data.payment_status.id !== QRLINX_STATUS.FINALIZADO &&
            dataCheck.data.payment_status.id !== QRLINX_STATUS.DESFEITO
          ) {
            checkAndConfirmPayment(params, cbSuccess);
          }
        }, 3000);

        return { dataCheck };
      }
    }
  );
};
