import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 280px;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const BoxWrapper = styled.div`
  display: block;

  padding: 10px;

  border: 1px solid #e6e6e6;
  border-radius: 8px;

  margin-top: 16px;
  margin-bottom: 16px;

  font-size: 14px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 5px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  line-height: 24px !important;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;

  margin-bottom: 5px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusWrapper = styled.div`
  width: 100px;
  text-align: center;
  font-size: 13px;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;

  color: ${(props) => {
    if (props.status === "Autorizada") {
      return "green";
    } else if (props.status === "Não Autorizada" || "Erro na emissão") {
      return "red";
    } else {
      return "gray";
    }
  }};

  border-color: ${(props) => {
    if (props.status === "Autorizada") {
      return "green";
    } else if (props.status === "Não Autorizada" || "Erro na emissão") {
      return "red";
    } else {
      return "gray";
    }
  }};
`;

export const CheckboxWrapper = styled.label`
  margin-top: 10px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const StyledCheckbox = styled.span`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    background-color: #411e5a;
    border-radius: 2px;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    transition: opacity 0.2s;
  }
`;

export const RadioButtonWrapper = styled.label`
  margin-top: 10px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
`;

export const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  display: none;
`;

export const StyledRadioButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: #411e5a;
  border-radius: 50%;
  opacity: ${(props) => (props.checked ? 1 : 0)};
  transition: opacity 0.2s;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    opacity: ${(props) => (props.checked ? 0 : 1)};
  }
`;

export const DetailButtom = styled.button`
  width: 35px;
  height: 35px;
  left: 65%;
  margin-top: 5px;
  background-color: #411e5a;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
