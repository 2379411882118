import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { isShowPagerPage } from "../../../configuration";
import { cpfMask } from "../../utils/masks/cpf";
import { tellMask } from "../../utils/masks/tell";
import { cpfClearMask } from "../../utils/cpfClearMask";
import { validaCPF } from "../../utils/checkCPF";
import {
  consultCampaign,
  consultPoints,
} from "../../../../services/pointsService";
import { useLayoutAa } from "../../../contexts/layout";
import { PDV_COD_LOJA_KEY_NAME } from "../../../../pdv-one/constants/keyNamesSessionStorage";

const Keyboard = forwardRef(
  (
    {
      handleClick,
      textButton,
      textPlaceholder,
      textLabel,
      isFidelity,
      classN,
      typeInput,
      doneButtonIsDisabled = false,
      initialCpf = ""
    },
    ref
  ) => {
    let [stateCPF, setStateCPF] = useState("hidden");
    let [textStateCPF, textSetStateCPF] = useState("");
    let [inputValue, setInputValue] = useState("");
    let [disableFidelityBtn, setDisableFidelityBtn] = useState(false)

    const { t } = useTranslation();
    const { getTranslateName, isLayout } = useLayoutAa();

    const cpfInputText = getTranslateName(t, "cpf_input") || "Digite seu CPF";
    const buttonToAddText = getTranslateName(t, "buttons.toAdd") || "Adicionar";
    const buttonClearText = getTranslateName(t, "buttons.clear") || "Limpar";

    const numericKeypad = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, buttonClearText, "<"], [buttonClearText]);

    useImperativeHandle(ref, () => ({
      handleDoneOnClick: handleFunction,
    }));

    const handleChange = (event) => {
      if (typeInput === "tel" && inputValue.length < 15) {
        setInputValue((inputValue = tellMask(inputValue + event.target.value)));
      }
      if (typeInput !== "numeric" && typeInput !== "tel") {
        setInputValue((inputValue = cpfMask(inputValue + event.target.value)));
      }
      if (event.target.value.length > 1) {
        setInputValue("");
        textSetStateCPF("");
        setStateCPF("hidden");
        return;
      }

      if (event.target.value === "<") {
        return setInputValue(inputValue.slice(0, -1));
      }

      if (typeInput === "numeric" && localStorage.getItem("prismakey")) {
        if(isShowPagerPage) {
          return setInputValue((inputValue = inputValue + event.target.value));
        }
        if (inputValue.length < 3 && !isShowPagerPage) {
          return setInputValue((inputValue = inputValue + event.target.value));
        }
      }

      if (typeInput === "numeric" && !localStorage.getItem("prismakey")) {
        return setInputValue((inputValue = inputValue + event.target.value));
      }
    };

    const handleFunction = () => {

      if(isShowPagerPage && inputValue.length < 1 && localStorage.getItem("prismakey")) {
        return setStateCPF("visible")
      }
      if (inputValue.length < 11 && typeInput !== "numeric") {
        textSetStateCPF("");
        return setStateCPF("visible");
      }

      if (typeInput !== "numeric" && typeInput !== "tel") {
        let valid = validaCPF(cpfClearMask(inputValue));

        if (!valid) {
          textSetStateCPF("");
          return setStateCPF("visible");
        }

        window.localStorage.setItem("cpfDocument", cpfClearMask(inputValue));
      }

      if (isFidelity) {
        window.localStorage.setItem("cpf", cpfClearMask(inputValue));
        return checkUser();
      }

      if (typeInput === "numeric" && inputValue.length > 0) {
        setInputValue("");
        return handleClick(cpfClearMask(inputValue));
      }

      return handleClick(cpfClearMask(inputValue));
    };

    const clearClientData = () => {
      window.localStorage.removeItem("cpf");
      // eslint-disable-next-line no-throw-literal
      throw "Cliente não cadastrado...";
    };

    const getCampaigns = async () => {
      const response = await consultCampaign(cpfClearMask(inputValue));

      if (!response) {
        clearClientData();
      }

      return response;
    };

    const getPoints = async () => {
      const response = await consultPoints(
        cpfClearMask(inputValue),
        sessionStorage.getItem(PDV_COD_LOJA_KEY_NAME)
      );

      if (!response) {
        clearClientData();
      }

      return response;
    };

    function showInputCpf() {
      let senha = document.getElementById("senha");
      let icon = document.getElementById("icon");
      if (senha.type === "password") {
        icon.className = "fas fa-eye lnr-eye";
        senha.type = "text";
      } else {
        senha.type = "password";
        icon.className = "fas fa-eye-slash lnr-eyeclose";
      }
    }

    const checkUser = async () => {
      let valid = true;
      setDisableFidelityBtn(true)

      try {
        const response = await getPoints();
        const responseCampaign = await getCampaigns();
        window.localStorage.setItem("cpf", cpfClearMask(inputValue));
        window.localStorage.setItem("saldoAtual", response.SaldoAtual);
        console.log(
          "responseCampaign.AvailableCampaigns",
          responseCampaign.AvailableCampaigns
        );
        window.localStorage.setItem(
          "reshopCampanhas",
          JSON.stringify(responseCampaign.AvailableCampaigns)
        );
        handleClick();
        setDisableFidelityBtn(false)
      } catch (err) {
        console.error(err);
        valid = false;
        textSetStateCPF(err);
        setStateCPF("visible");
        setDisableFidelityBtn(false)
      }

      return valid;
    };

    const keys = numericKeypad.map((key) => {
      return (
        <button
          className={`key-${key}`}
          key={key}
          value={key}
          onClick={handleChange}
        >
          {key === "<" ? "🡐" : key}
        </button>
      );
    });

    const isNotBobsLayout = !isLayout("BOBS");

    useEffect(() => {
      if (localStorage.getItem("cpf") && typeInput !== "numeric"  && typeInput !== "tel") {
          setInputValue(cpfMask(localStorage.getItem("cpf")));
      }
    }, [typeInput]);

    useEffect(() => {
      if (initialCpf !== "" && validaCPF(cpfClearMask(initialCpf)))
        setInputValue(cpfMask(initialCpf));
    }, [initialCpf]);

    return (
      <section className={`keyboard ${classN}`}>
        {textLabel && <p className="label">{textLabel}</p>}
        <div className="divInput">
          <input
            className="input"
            value={inputValue}
            readOnly
            pattern="[0-9]{11}"
            id="senha"
            type={textPlaceholder || isNotBobsLayout ? textPlaceholder || "password" : "text"}
            placeholder={textPlaceholder || cpfInputText}
          />
          {!textPlaceholder ? (
            <i
              className={isNotBobsLayout ? "fas fa-eye-slash lnr-eyeclose" : "fas fa-eye lnr-eye"}
              id="icon"
              onClick={showInputCpf}
            ></i>
          ) : (
            <div />
          )}
        </div>
        <span className="warn" style={{ visibility: stateCPF }}>
          {localStorage.getItem("prismakey") ? "Digite seu Pager" : (localStorage.getItem("tellkey") ? "Telefone inválido" : textStateCPF || "CPF inválido")}
        </span>

        <section className="numericKeypad">
          {keys}
          {!doneButtonIsDisabled && (
            <button
              className="buttonAddStep key-add fidbtnRagazzo"
              onClick={handleFunction}
              disabled={disableFidelityBtn}
            >
              {textButton || buttonToAddText}
            </button>
          )}
        </section>
      </section>
    );
  }
);

export default React.memo(Keyboard);
