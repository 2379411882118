import React from "react";

import { WrapperRoutersProvider } from "../../contexts/wrapperRoutersContext";

import { Header } from "../Header";
import { ContainerContentWrapper } from "./Container";
import { SidebarCart } from "../SidebarCart";
import { isSmartPos } from "../../../aaone/configuration";

export const WrapperRouters = () => {
  return (
    <WrapperRoutersProvider>
      {isSmartPos && <SidebarCart />}
      <Header />
      <ContainerContentWrapper />
    </WrapperRoutersProvider>
  );
};
