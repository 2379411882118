const defaultSettings = {
  cartPosition: 'left',
  itemsPerPage: 10,
  timer: 4000,
  usarKeyboardTouch: true
}

export const loadSettings = () => {
  const previousSettings = window.localStorage.getItem('userSettings');

  return previousSettings ? JSON.parse(previousSettings) : defaultSettings
}

export const saveSettings = data => {
  window.localStorage.setItem('userSettings', JSON.stringify(data))
}

export const getSetting = key => {
  const settings = window.localStorage.getItem('userSettings') || JSON.stringify(defaultSettings)
  const settingsObject = JSON.parse(settings)

  return settingsObject[key]
}
