import React from "react";

import { Container } from "./styles";

export const ToggleInput = ({ name, ...props }) => {
  return (
    <Container className="switch" htmlFor={name}>
      <input type="checkbox" name={name} id={name} {...props} />
      <span className="slider round" />
    </Container>
  );
};
