import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MdDeleteForever } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { currency } from "../../../aaone/shared/utils/currency";
import { PagesEnum } from "../../constants/pagesEnum";
import { useCart } from "../../hook/cartHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { ItemInCart } from "./ItemCart";
import { Loading3Quarters } from "../Loading3Quarters";
import { Container, ContainerSmartPos, ContentCart } from "./styles";
import { useAuth } from "../../hook/authHook";
import { getConsultDiscountsService } from "../../../services/reshop/getConsultDiscountsService";
import { activatedCampaignsService } from "../../../services/reshop/activatedCampaignsService";
import { useDialog } from "../../hook/dialogHook";
import { getActiveCampaignsReshopService } from "../../../services/reshop/getCampaignsReshopService";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import {
  DATA_USER_AUTH_KEY_NAME,
  MANUAL_DISCOUNTS,
  RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME,
  RESHOP_DISCOUNTS,
  RESHOP_NSU_KEY_NAME,
  RESHOP_TOTAL_DISCOUNT_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { saleInProgressService } from "../../../services/saleInProgress";
import {
  getStoreId,
  getTerminalID,
  isSmartPos,
} from "../../../aaone/configuration";
import { useCashdesk } from "../../hook/cashdeskHook";
import Discount from "./Discount";
import { ModalWaiterCommission } from "../modals/ModalWaiterCommission";
import { insertProductsInKds } from "../../../services/routingService";
import { TableCart } from "./TableCart";
import { useLoading } from "../../hook/loadingHook";
import { CostumerIdentifier } from "../CostumerIdentifier";

let isFirstLoad = true;
let timeout;

const Element = React.memo(({ isCartVisible, className, children }) => {
  if (isCartVisible) {
    isFirstLoad = false;
  }

  return isSmartPos ? (
    <ContainerSmartPos
      className={classNames(className, {
        isCartVisible,
        isHide: !isFirstLoad && !isCartVisible,
      })}
    >
      {children}
    </ContainerSmartPos>
  ) : (
    <>{children}</>
  );
});

export const SidebarCart = ({ className }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setShowLoading } = useLoading();
  const containerCartRef = useRef(null);
  const contentCartRef = useRef(null);
  const systemParams = getSystemParamsHelper();

  const saleInProgressConfiguration =
    systemParams?.saleInProgressConfiguration?.workWithTableByPosition;
  const onCashierReductionOpenWithdrawingScreen =
    systemParams?.onCashierReductionOpenWithdrawingScreen || false;
  const acceptManualDiscount = systemParams?.acceptManualDiscount || false;

  const [isCouponVisible, setIsCouponVisible] = useState(false);
  const [isOpenModalWaiter, setIsOpenModalWaiter] = useState(false);
  const [isWarnCoupon, setIsWarnCoupon] = useState(false);
  const [input, setInput] = useState("1");
  const [tableNumber, setTableNumber] = useState("");
  const [customersNumber, setCustomersNumber] = useState("");
  const [isDangerCoupon, setIsDangerCoupon] = useState(false);
  const [selectedWaiter, setSelectedWaiter] = useState(null);
  const [isLoadingDiscountsReshop, setIsLoadingDiscountsReshop] =
    useState(false);

  const {
    isCartVisible,
    currentProductStep,
    oldCurrentProductStepContainer,
    setOldCurrentProductStepContainer,
    coupons,
    reshopTotalPrice,
    hasDiscount,
    setReshopTotalPrice,
    removeCouponInCart,
    addDiscountsReshop,
    handleCloseModalFidelity,
    handleCloseModalFidelityData,
    Sale,
    paymentSuccess,
    itemsInCart,
    totalPrice,
    isReadyToPay,
    handleOpenModalToEat,
    totalPriceWDiscount,
    clearCartTable,
    undoPayment,
    setIsCartVisible,
  } = useCart();
  const { reshop } = useAuth();
  const cashdesk = useCashdesk();
  const { showAlert, setOpenDialog } = useDialog();
  const { isSidebarCartHide, isSidebarRight } = useWrapperRouters();

  const dialogCashdeskClosedSettings = {
    title: "Abertura do Caixa",
    subTitle: "O caixa não está aberto",
    message: "Deseja realizar a abertura do caixa neste momento?",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Abrir o caixa",
    onConfirmClick: async () => {
      setShowLoading();
      await cashdesk.getLastCashdesk();
      setShowLoading(false);
      navigate(PagesEnum.CASH_OPEN);
    },
  };

  const dialogCashdeskCurrentDaySettings = {
    title: "Fechamento de Caixa",
    subTitle: "O caixa está aberto",
    message:
      "Para continuar vendendo, realize o fechamento do caixa e encerramento do dia anterior.",
    icon: "pdv-one/aberturadecaixa.png",
    onConfirmText: "Fechar o caixa",
    onConfirmClick: () => {
      navigate(
        onCashierReductionOpenWithdrawingScreen
          ? PagesEnum.CASH_CLOSE_WITHDRAW
          : PagesEnum.CASH_CLOSE
      );
    },
  };

  const state = location?.state ?? { coupon: null };

  const handleGoToPaymentMethods = handleOpenModalToEat;

  const goToStep = (product, sequence) => Sale.goToStep(product, sequence);

  const getCurrentStep = () => Sale.getCurrentSaleStep();

  const handleModalWaiterClose = () => {
    setIsOpenModalWaiter(false);
  };

  const handleWaiterSelection = (waiter) => {
    setSelectedWaiter(waiter);
  };

  const onChangeInput = useCallback((event) => {
    const input = event.target.value;
    setInput(input);
  }, []);

  const handleGoToFidelityCouponPage = useCallback(async () => {
    try {
      let blockSales = false;
      const tableSelectedInHome = JSON.parse(
        sessionStorage.getItem("tableSelected")
      );
      if (cashdesk.isCashdeskOpen) {
        blockSales = await cashdesk.showAlertWithdrawCanSale(() => {
          navigate(PagesEnum.CASH_WITHDRAW);
        });
      }

      let validateSale = true;

      /**
       *  Se a loja for 24h e estiver com uma venda em andamento,
       *  não trava o carrinho.
       */
      try {
        if (
          itemsInCart.length > 0 &&
          systemParams.store24Hours &&
          !cashdesk.isCashdeskOnCurrentDay &&
          !tableSelectedInHome
        ) {
          validateSale = false;
        }
      } catch (e) {
        if (window.Android) {
          console.log(
            "Error on Categories handleChangeQuantity validateSale => " +
              JSON.stringify(e)
          );
        } else {
          console.error(
            "Error on Categories handleChangeQuantity validateSale => " + e
          );
        }
      }

      if (validateSale) {
        if (!cashdesk.isCashdeskOpen) {
          setOpenDialog(dialogCashdeskClosedSettings);

          return;
        } else if (!cashdesk.isCashdeskOnCurrentDay) {
          setOpenDialog(dialogCashdeskCurrentDaySettings);

          return;
        } else if (blockSales) {
          console.log("Venda bloqueada por Limite/Notificação de Sangria");

          return;
        }
      }

      handleCloseModalFidelity();
      handleCloseModalFidelityData();

      if (isSmartPos) {
        setIsCartVisible(false);
      }

      navigate(PagesEnum.FIDELITY_COUPON);
    } catch (err) {
      if (window.Android) {
        console.log(
          `Error on handleGoToFidelityCouponPage => ${JSON.stringify(err)}`
        );
      } else {
        console.error(`Error on handleGoToFidelityCouponPage => ${err}`);
      }
    }
  }, [
    cashdesk,
    navigate,
    setOpenDialog,
    setIsCartVisible,
    dialogCashdeskClosedSettings,
    dialogCashdeskCurrentDaySettings,
    handleCloseModalFidelity,
    handleCloseModalFidelityData,
  ]);

  const hasPayments = paymentSuccess && paymentSuccess?.length > 0;

  const everyItensReshop = itemsInCart.every(
    (item) => item?.ClassSaleStep.getSteps().categoryIsFidelity === true
  );

  const totalPago = paymentSuccess.reduce((sum, payment) => {
    if (payment.details && payment.details.amountPaid) {
      return sum + payment.details.amountPaid;
    }

    return sum;
  }, 0);

  const faltaPagar = totalPriceWDiscount - totalPago;

  const setInvisible = () => {
    setIsCouponVisible(false);
  };

  const handleGoToPayment = useCallback(async () => {
    const cpf = reshop?.cpfFidelity ?? null;

    try {
      const reshopAuthData =
        JSON.parse(sessionStorage.getItem("@PDVOne:reshop_auth_data")) || {};

      if (reshopAuthData?.username) {
        setIsLoadingDiscountsReshop(true);

        const discountsResultResponse = await getConsultDiscountsService(
          itemsInCart,
          { cpf, totalPrice }
        );

        if (discountsResultResponse) {
          addDiscountsReshop(discountsResultResponse);

          if (discountsResultResponse?.CampanhasAtivadas?.length > 0) {
            setIsWarnCoupon(false);
          }
        }

        if (
          !discountsResultResponse ||
          discountsResultResponse?.CampanhasAtivadas?.length === 0
        ) {
          //TODO - Ativar quando analisar melhor ProdutosRegra
          // setIsWarnCoupon(true);
        }

        const campings = activatedCampaignsService();

        const couponAlreadyExists = campings.findIndex(
          (camping) =>
            camping.PromocodesAtivados.length > 1 &&
            camping.PromocodesAtivados.includes(state?.coupon ?? "")
        );

        if (couponAlreadyExists > -1) {
          removeCouponInCart(state?.coupon);
          setIsDangerCoupon(true);
        }

        setIsLoadingDiscountsReshop(false);

        const campaigns = getActiveCampaignsReshopService();

        if (campaigns?.length > 0) {
          setReshopTotalPrice(
            Number(discountsResultResponse?.TotalDesconto ?? 0)
          );
          showAlert({
            title: "Benefícios Ativados",
            onConfirmText: "Prosseguir",
            disabledParagraph: true,
            message: (
              <>
                {campaigns?.length > 0 && (
                  <>
                    <div className="list__campaigns__reshop">
                      Campanhas ativas
                    </div>
                    <div className="list__campaigns__reshop">
                      {campaigns.map((campaign, campaignIndex) => (
                        <div key={String(campaignIndex)}>
                          <span>{campaign.NomeCampanha}</span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ),
            handleConfirm: handleGoToPaymentMethods,
          });
        } else if (
          discountsResultResponse ||
          discountsResultResponse === null
        ) {
          handleGoToPaymentMethods();
        }
      } else {
        handleGoToPaymentMethods();
      }
    } catch (error) {
      setIsLoadingDiscountsReshop(false);
      handleGoToPaymentMethods();
    }
  }, [reshop, state?.coupon, totalPrice]);

  const handleSubmitTable = () => {
    if (tableNumber && !tableNumber?.isPayment) {
      const dataRequest = {
        storeId: getStoreId(),
        pdvCode: getTerminalID(),
        cashDeskOpenDateId: cashdesk.cashdesk.cashDeskOpenDate.id,
        tableNumber: tableNumber,
        cashDeskId: cashdesk.cashdesk.id,
        dateTimeStart: new Date(),
        totalValue: Sale.getTotalValue(),
        sellerReferenceId: operator.employeeCode,
        sellerName: operator.login,
        numberOfCustomers: customersNumber,
        items: itemsInCart.map((item) => ({
          ...item.ClassSaleStep.getSteps(),
          categoryId: item.ClassSaleStep.getSteps().categoryId ?? 0,
          tablePosition: saleInProgressConfiguration ? input : "",
          isNewItem: true,
          quantity: item.quantity,
          sentToProductionAt: new Date(),
          pdvCode: getTerminalID(),
          sellerReferenceId: operator.employeeCode,
          dateTimeInsert: new Date(),
          waiterReferenceId: selectedWaiter.employeeCode,
          waiterName: selectedWaiter.loginName,
        })),
      };

      saleInProgressService(dataRequest).then(async (response) => {
        try {
          const { data } = response;
          setIsOpenModalWaiter(false);

          if (data?.error) {
            showAlert({ message: data.error });
          } else {
            const requestProductsKds = {
              ...dataRequest,
              items: itemsInCart,
            };

            await insertProductsInKds(requestProductsKds, Sale);
            clearCartTable();
            showAlert({
              message: "Lançamento Enviado para produção!",
              onConfirmText: "Mapa de Mesa",
              cancelButtonText: "Continuar",
              handleCancel: () => {},
              handleConfirm: () => {
                navigate(PagesEnum.SALLER_TABLE);
                sessionStorage.removeItem("tableSelected");
                sessionStorage.removeItem("customersSelected");
              },
            });
          }
        } catch (error) {
          showAlert({ message: "Ocorreu um erro desconhecido!" });
        }
      });
    } else if (location.pathname === PagesEnum.HOME) {
      handleGoToPayment();
    }
  };

  useEffect(() => {
    if (isCouponVisible) {
      Object.assign(state, { coupon: undefined });

      timeout = setTimeout(setInvisible, 6000);
    } else if (!!state?.coupon && isCouponVisible === false) {
      // setIsWarnCoupon(itemsInCart.length === 0); //TODO - Exibir aviso, porém tem que ver retorno de ProdutosRegra
      setIsCouponVisible(true);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCouponVisible, state?.coupon]);

  useEffect(() => {
    if (contentCartRef.current) {
      setTimeout(() => {
        if (contentCartRef.current) {
          contentCartRef.current.scrollTop =
            contentCartRef.current?.scrollHeight;
        }
      }, 200);
    }
  }, [
    reshop,
    state?.coupon,
    coupons,
    removeCouponInCart,
    contentCartRef,
    itemsInCart,
    isReadyToPay,
  ]);

  const HAS_RESHOP_DATA_KEY_NAME = "HAS_RESHOP_DATA";
  const operator = JSON.parse(sessionStorage.getItem(DATA_USER_AUTH_KEY_NAME));

  useEffect(() => {
    const tableSelectedInHome = JSON.parse(
      sessionStorage.getItem("tableSelected")
    );
    setTableNumber(tableSelectedInHome);

    const quantityCustomersSelectedInHome =
      sessionStorage.getItem("customersSelected");

    setCustomersNumber(quantityCustomersSelectedInHome);

    if (itemsInCart.length === 0 && reshopTotalPrice > 0) {
      setReshopTotalPrice(0);
    }

    if (
      location.pathname === PagesEnum.HOME &&
      reshopTotalPrice > 0 &&
      sessionStorage.getItem(HAS_RESHOP_DATA_KEY_NAME)
    ) {
      sessionStorage.removeItem(RESHOP_TOTAL_DISCOUNT_KEY_NAME);
      sessionStorage.removeItem(RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME);
      sessionStorage.removeItem(RESHOP_DISCOUNTS);
      sessionStorage.removeItem(MANUAL_DISCOUNTS);

      sessionStorage.removeItem(HAS_RESHOP_DATA_KEY_NAME);
      setReshopTotalPrice(0);

      if (sessionStorage.getItem(RESHOP_NSU_KEY_NAME)) {
        sessionStorage.removeItem(RESHOP_NSU_KEY_NAME);
      }
    } else if (
      location.pathname === PagesEnum.PAYMENT_METHODS &&
      reshopTotalPrice > 0
    ) {
      sessionStorage.setItem(HAS_RESHOP_DATA_KEY_NAME, "OK");
    }
  }, [reshopTotalPrice, itemsInCart, reshop, location]);

  useEffect(() => {
    if (containerCartRef?.current) {
      const menuHeight = `${
        document.querySelector(".header-main-top")?.clientHeight ?? 0
      }px`;

      if (!(tableNumber && saleInProgressConfiguration)) {
        containerCartRef.current.setAttribute(
          "style",
          `height: calc( 100vh - ${menuHeight} ) !important;`
        );
      }

      document
        .querySelector("body")
        .style.setProperty("--menu-height", menuHeight);
    }
  }, [
    containerCartRef,
    tableNumber,
    currentProductStep,
    isCartVisible,
    location,
  ]);

  const isSidebarHideInSmartPos =
    (currentProductStep?.length >= 0 && !isCartVisible && isSmartPos) ||
    (currentProductStep?.length === 0 &&
      isCartVisible &&
      itemsInCart.length === 0 &&
      isSmartPos) ||
    (currentProductStep?.length === 0 &&
      oldCurrentProductStepContainer === null &&
      isCartVisible &&
      itemsInCart.length === 0 &&
      isSmartPos);

  if (!isSmartPos && isSidebarCartHide) {
    return <></>;
  }

  if (tableNumber && saleInProgressConfiguration) {
    return (
      <Element className={className} isCartVisible={isCartVisible}>
        <Container
          ref={containerCartRef}
          className={classNames({ isSidebarRight }, "productsCart")}
        >
          <TableCart />
        </Container>
      </Element>
    );
  }

  return (
    <Element className={className} isCartVisible={isCartVisible}>
      {state.coupon && isCouponVisible && (
        <div
          className={classNames("coupon", {
            "coupon-has-warn": isWarnCoupon,
            "coupon-has-danger": isDangerCoupon,
          })}
        >
          {isDangerCoupon ? (
            "Já foi adicionado um cupom referente a essa campanha."
          ) : (
            <>
              {isWarnCoupon ? (
                "Acessou programa de fidelidade? Item da promoção no carrinho?"
              ) : (
                <>
                  Cupom <span>{state.coupon.toUpperCase()}</span> foi adicionado
                  com sucesso!
                </>
              )}
            </>
          )}
        </div>
      )}
      <Container
        ref={containerCartRef}
        className={classNames({ isSidebarRight }, "productsCart")}
      >
        {tableNumber && (
          <div className="tableStyle">
            {saleInProgressConfiguration ? (
              <span className="tableStyle">
                <span className="font-table">MESA : </span>
                <strong className="font-tableNumber">
                  {tableNumber?.tableNumber || tableNumber}
                </strong>
                <span className="font-table font-tableNumber">POSIÇÃO : </span>
              </span>
            ) : (
              <span className="tableStyle">
                <span className="font-table">MESA : </span>
                <strong className="font-tableNumber">
                  {tableNumber?.tableNumber || tableNumber}
                </strong>
                <span className="font-table font-tableNumber">PESSOAS : </span>
                <strong className="font-tableNumber">{customersNumber}</strong>
              </span>
            )}
            {saleInProgressConfiguration && (
              <input
                value={input}
                placeholder={""}
                onChange={onChangeInput}
                className="input-position"
              />
            )}
          </div>
        )}
        <CostumerIdentifier handleReshop={handleGoToFidelityCouponPage} />
        <ContentCart ref={contentCartRef}>
          {!!itemsInCart.length && (
            <>
              <div className="pdvcart-summary">
                <span>PRODUTO</span>
                <span>VALOR</span>
              </div>
              <div className="container-sidebar">
                {itemsInCart.map((item, index) => {
                  return (
                    <ItemInCart
                      item={item}
                      index={index}
                      totalItemsInCart={itemsInCart.length}
                      contentCartRef={contentCartRef}
                      key={String(`${item.id}${index}`)}
                      handleGoToStep={(item, sequence) => {
                        if (!isSidebarHideInSmartPos && isCartVisible) {
                          setOldCurrentProductStepContainer(null);
                        }

                        goToStep(item, sequence);
                      }}
                      currentStep={() => getCurrentStep()}
                    />
                  );
                })}
              </div>
            </>
          )}
        </ContentCart>
        {hasPayments && !everyItensReshop && (
          <ul className="payments">
            <li>
              <strong>Pagamento:</strong>
            </li>
            {paymentSuccess.map((payment, payIndex) => {
              const isToUseTheNameBrandName = [
                "QR LINX",
                "QRLINX",
                "QRCODE",
                "OUTROS",
              ].includes(payment.methodName);

              const paymentName = isToUseTheNameBrandName
                ? payment?.details?.paymentOneDetails?.brandName ??
                  payment.methodName
                : payment.methodName;

              return (
                <li key={String(payIndex)}>
                  <strong>{paymentName}</strong>{" "}
                  <span className="price-with-button">
                    {currency(payment.details.amountPaid)}

                    {location.pathname === PagesEnum.PAYMENT_METHODS && (
                      <button
                        onClick={() => {
                          undoPayment(payment);
                        }}
                        className="btn-action-cart"
                      >
                        <MdDeleteForever size="1.5rem" />
                      </button>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
        {(hasDiscount || location.pathname === PagesEnum.PAYMENT_METHODS) && (
          <ul className="descontos">
            {acceptManualDiscount && <Discount />}
            {reshopTotalPrice > 0 && (
              <li>
                <strong>Desconto Fidelidade:</strong>{" "}
                <span>{currency(reshopTotalPrice)}</span>
              </li>
            )}
          </ul>
        )}

        <div className="button__container-footer">
          <button
            className={classNames({
              button__payment: true,
              button__active:
                (isReadyToPay ||
                  location.pathname === PagesEnum.PAYMENT_METHODS) &&
                (everyItensReshop || Sale?.getTotalValue() > 0),
            })}
            onClick={(e) => {
              e.preventDefault();

              setIsCartVisible(false);

              if (Sale?.getTotalValue() === 0 && !everyItensReshop) {
                setIsOpenModalWaiter(false);
              } else if (tableNumber && !tableNumber?.isPayment) {
                setIsOpenModalWaiter(true);
              } else if (location.pathname === PagesEnum.HOME) {
                handleGoToPayment();
              } else if (location.pathname === PagesEnum.FIDELITY_COUPON) {
                handleGoToPayment();
              }
            }}
            disabled={!isReadyToPay && location.pathname === PagesEnum.HOME}
            type="button"
          >
            {isLoadingDiscountsReshop ? (
              <span className="loading__text">
                <Loading3Quarters />
                <span>Carregando...</span>
              </span>
            ) : (
              <>
                {`${
                  location.pathname === PagesEnum.HOME
                    ? tableNumber && !tableNumber?.isPayment
                      ? "ENVIAR PRODUÇÃO"
                      : "PAGAR"
                    : hasPayments
                    ? "Total restante"
                    : "Total"
                } ${currency(
                  everyItensReshop
                    ? 0
                    : hasPayments
                    ? faltaPagar
                    : totalPriceWDiscount
                )}`}
              </>
            )}
          </button>
        </div>
        <ModalWaiterCommission
          isOpen={isOpenModalWaiter}
          onClose={handleModalWaiterClose}
          onSubmit={handleSubmitTable}
          onWaiterSelect={handleWaiterSelection}
        />
      </Container>
    </Element>
  );
};
