import axios from "axios";
import { getCodLoja, getTerminalID } from "../../aaone/configuration";
import {
  CART_COUPONS_KEY_NAME,
  MANUAL_DISCOUNTS,
  RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME,
  RESHOP_ACTIVE_OPTIONAL_CAMPAIGN,
  RESHOP_NSU_KEY_NAME,
  RESHOP_TOTAL_DISCOUNT_KEY_NAME,
} from "../../pdv-one/constants/keyNamesSessionStorage";
import { escOnlyNumbersHelper } from "../../pdv-one/helpers/escOnlyNumbersHelper";
import { apiReshop } from "../api";
import Decimal from "decimal.js";

let previousCancelToken;

export const getConsultDiscountsService = async (items, options) => {
  try {
    let manualDiscounts =
      JSON.parse(sessionStorage.getItem(MANUAL_DISCOUNTS)) || [];
    let activeOptionalCampaign =
      JSON.parse(sessionStorage.getItem(RESHOP_ACTIVE_OPTIONAL_CAMPAIGN)) || [];

    if (previousCancelToken) {
      previousCancelToken.cancel("Cancelando a solicitação anterior.");
    }

    const cancelTokenSource = axios.CancelToken.source();
    previousCancelToken = cancelTokenSource;

    const codigoLoja = getCodLoja();
    const terminalid = getTerminalID();

    const total = options.totalPrice;
    const totalDiscount = options.totalDiscount ?? 0;

    let itemsAdjustments = [];

    const prepareAdjustmentItem = (item, index) => ({
      Item: index,
      CodigoProduto: item.productCode,
      Qtde: item?.quantity ?? 1,
      PrecoUnitario: item.priceUnique,
      Desconto: item.Desconto ?? 0,
      ValorLiquido: item.ValorLiquido,
      ProdutoResgate: false,
      Promocode: item.Promocode ? item.Promocode : undefined,
    });

    let newIndexAdjust = 0;
    items.map((item) => {
      let currentItemIndex = newIndexAdjust + 1;
      newIndexAdjust++;

      const currentAdjustItem = item.ClassSaleStep.getSteps();

      // Valor produto pai com os filhos
      let priceUniqueParent = Number(currentAdjustItem.value);

      const itensInSteps = currentAdjustItem.steps;

      //Encontro o desconto aplicado no itemPai
      const productDiscount = manualDiscounts.find(
        (dItem) => dItem.productCode === currentAdjustItem.productCode
      );
      const productDiscountIndex = manualDiscounts.findIndex(
        (dItem) => dItem.productCode === currentAdjustItem.productCode
      );

      //Acumulado de desconto para aplicar no pai
      let totalDiscount = itensInSteps?.length
        ? Number(productDiscount?.discountManual ?? 0)
        : 0;

      if (itensInSteps?.length > 0) {
        itensInSteps.forEach((step) => {
          if (step.selected !== null) {
            Array.from(step.selected).forEach((itemSelected) => {
              newIndexAdjust++;

              const priceUnique = itemSelected.value;
              const priceRealUnique =
                priceUnique * (itemSelected.amount / item.quantity);
              priceUniqueParent += priceRealUnique;

              //Desconto do produto filho
              const productStepDiscount = manualDiscounts.find(
                (dItem) => dItem.productCode === itemSelected.productCode
              );
              const productDiscountIndex = manualDiscounts.findIndex(
                (dItem) => dItem.productCode === itemSelected.productCode
              );

              totalDiscount += productStepDiscount?.discountManual
                ? Number(productStepDiscount?.discountManual)
                : 0;
              const discount = currentAdjustItem.isOnlyLevel
                ? productStepDiscount?.discountManual ?? 0
                : 0;

              itemsAdjustments.push(
                prepareAdjustmentItem(
                  {
                    productCode: itemSelected.productCode,
                    quantity: itemSelected?.amount ?? 1,
                    priceUnique: currentAdjustItem.isOnlyLevel
                      ? priceUnique
                      : 0,
                    ValorLiquido: currentAdjustItem.isOnlyLevel
                      ? Number(Number(priceUnique) - totalDiscount).toFixed(2)
                      : 0,
                    Desconto: discount,
                    Promocode:
                      itemSelected.promoCode ?
                      itemSelected.promoCode :
                      undefined,
                  },
                  newIndexAdjust
                )
              );

              if (manualDiscounts.length) {
                manualDiscounts.splice(productDiscountIndex, 1);
              }
            });
          } else if (step.composition) {
            step.products.forEach((composition) => {
              if (composition.operationType === "A") {
                newIndexAdjust++;

                const priceUnique = composition.value;
                const priceRealUnique =
                  priceUnique * (composition.amount / item.quantity);
                priceUniqueParent += priceRealUnique;

                itemsAdjustments.push(
                  prepareAdjustmentItem(
                    {
                      productCode: composition.productCode,
                      quantity: composition?.amount ?? 1,
                      priceUnique,
                      ValorLiquido: Number(priceRealUnique).toFixed(2),
                      Promocode:
                          composition.promoCode ?
                          composition.promoCode :
                          undefined,
                    },
                    newIndexAdjust
                  )
                );
              }
            });
          }
        });
      }

      // se for produto de nivel ele não pode passar valor ao reshop
      if (currentAdjustItem.isOnlyLevel) {
        priceUniqueParent = 0;
        totalDiscount = 0;
      }

      totalDiscount = totalDiscount ?? 0;

      itemsAdjustments.splice(
        newIndexAdjust - 1,
        0,
        prepareAdjustmentItem(
          {
            productCode: currentAdjustItem.productCode,
            quantity: item?.quantity ?? 1,
            priceUnique: currentAdjustItem.isOnlyLevel ? 0 : priceUniqueParent,
            ValorLiquido: new Decimal(Number(priceUniqueParent))
              .minus(Number(totalDiscount))
              .toFixed(2),
            Desconto: totalDiscount,
            Promocode:
                currentAdjustItem.promoCode ?
                currentAdjustItem.promoCode :
                undefined,
          },
          currentItemIndex
        )
      );

      if (manualDiscounts.length) {
        manualDiscounts.splice(productDiscountIndex, 1);
      }

      return item;
    });

    const coupons = JSON.parse(
      sessionStorage.getItem(CART_COUPONS_KEY_NAME) ?? "[]"
    ).map((coupon) => coupon?.value);

    const totalQuantity = items.reduce(
      (acc, currentItem) => acc + currentItem.quantity,
      0
    );

    manualDiscounts =
      JSON.parse(sessionStorage.getItem(MANUAL_DISCOUNTS)) || [];

    const payload = {
      DocumentoFidelidade: escOnlyNumbersHelper(options?.cpf || ""),
      Itens: itemsAdjustments,
      CodigoLoja: codigoLoja,
      CodigoTerminal: terminalid,
      DataHora: new Date(),
      TransacaoAssociada: "",
      Promocodes: coupons,
      CategoriaVenda: "2",
      QtdeTotal: totalQuantity,
      PontosResgate: "",
      Nsu: "",
      ValorBruto: total,
      ValorLiquido: total - totalDiscount,
      ValorDescontos: totalDiscount,
      // ValorLiquido: total,
      Pagamentos: undefined,
      ValidaCampanha: true,
      campanhasOpcionais: activeOptionalCampaign.map((campaign) => ({
        IdCampanha: campaign.IdCampanha,
      })),
    };

    const { data } = await apiReshop.post(
      "/api/Fidelidade/ConsultaCampanha",
      payload,
      {
        cancelToken: cancelTokenSource.token,
      }
    );

    sessionStorage.setItem(RESHOP_NSU_KEY_NAME, data.Nsu);
    sessionStorage.setItem(
      RESHOP_TOTAL_DISCOUNT_KEY_NAME,
      String(data.TotalDesconto)
    );
    sessionStorage.setItem(
      RESHOP_ACTIVATED_CAMPAIGNS_KEY_NAME,
      JSON.stringify(data.CampanhasAtivadas)
    );

    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(
        `A solicitação da requisição foi cancelada: ${JSON.stringify(
          error.message
        )}`
      );
    }

    return null;
  }
};
