import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import FISCAL_TYPES from "../../constants/fiscalTypes";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants/pagesEnum";
import {
  CheckboxRenderer,
  HeaderCheckboxComponent,
  ShowDetailsRenderer,
} from "../cell-renderers-grid";
import { FormControl } from "../form-control";
import { Button, Card, SelectBasic as Select } from "../modules";
import { Period } from "../period/period";
import {
  ActionsButtons,
  ContainerTable,
  DirectionalArrow,
  DirectionalArrowsContainer,
  Filters,
  MonitorNotesWrapper,
  OtherFilters,
} from "./style";
import { isSmartPos } from "../../../aaone/configuration";
import { CardMonitorNotes } from "../CardMonitorNotes";
import classNames from "classnames";

const columns = [
  { title: "", propName: "selectAll" },
  {
    title: "Nº",
    propName: "receiptNumber",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Série",
    propName: "receiptSeries",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "PDV",
    propName: "posId",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Status",
    propName: "status",
    showFilter: true,
    typeFilter: "agSetColumnFilter",
  },
  {
    title: "Data Atendimento",
    propName: "dtAtendimento",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Data Autorização",
    propName: "dtAutorizacao",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Valor",
    propName: "totalDiscount",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  {
    title: "Motivo",
    propName: "motivo",
    showFilter: true,
    typeFilter: "agTextColumnFilter",
  },
  { title: "Ações", propName: "viewDetails" },
];

const filters = {
  initialDate: null,
  finalDate: null,
  status: null,
  type: null,
};

const itemsStatusNfce = [
  { id: 0, name: "Todos" },
  { id: 1, name: "Autorizada" },
  { id: 2, name: "Contingência" },
  { id: 3, name: "Contingência rejeitada" },
  { id: 4, name: "Cancelada" },
  { id: 7, name: "Inutilizada" },
  { id: 8, name: "Erro na Emissão" },
  { id: 10, name: "Pendente" },
  { id: 11, name: "Devolvida" },
];

const itemsStatusSat = [
  { id: 0, name: "Todos" },
  { id: 1, name: "Autorizada" },
  { id: 4, name: "Cancelada" },
];

const itemsTypes = [
  { id: 0, name: "Não Homologado" },
  { id: 1, name: "NFC-e" },
  { id: 2, name: "CF-e(SAT)" },
];

const defaultColDef = {
  sortable: true,
  resizable: true,
  width: 100,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
};

const sideBar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
  ],
};

const ROW_HEIGHT = 55;

/**
 *
 * @param {*} props
 * @param {Number} props.fiscalType - Tipo de nota fiscal (0: Não Homologado, 1: NFCe, 2: SAT)
 */
const MonitorNotes = (props) => {
  const title = "Monitor de Notas";
  const {
    listItens,
    fiscalType,
    setOpenNoteDetails,
    handleCancelNote,
    handleReprintNote,
    handleReauthorizeNote,
    setOpenInutilizacao,
  } = props;
  const { setVisibleSidebarCart } = useWrapperRouters();
  const navigate = useNavigate();

  const periodRef = useRef();
  const [selectedItems, setSelectedItems] = useState([]);
  const [shouldShowCancelButton, setShouldShowCancelButton] = useState(false);

  const [api, setApi] = useState({
    gridApi: null,
    gridColumnApi: null,
  });

  useEffect(() => {
    setVisibleSidebarCart(false);
  });

  useEffect(() => {
    props.handleClick({ ...filters, ...props.filters });
  }, []);

  useEffect(() => {
    let activeFilter = filters;

    if (activeFilter.status?.id === 1 || activeFilter.status?.id === 0) {
      setShouldShowCancelButton(true);
    } else {
      setShouldShowCancelButton(false);
    }
  });

  useEffect(() => {
    return () => {
      //api.gridApi?.deselectAll();
      setSelectedItems([]);
      setShouldShowCancelButton(false);
      setApi({
        gridApi: null,
        gridColumnApi: null,
      });

      filters.initialDate = null;
      filters.finalDate = null;
      //filters.status = null;
      //filters.type = null;
    };
  }, []);

  const handleSearch = () => {
    if (periodRef.current.validate()) {
      setSelectedItems([]);
      props.handleClick(filters);

      props.setMonitorNotesFilters({
        initialDate: filters.initialDate,
        finalDate: filters.finalDate,
        status: filters.status,
        type: filters.type,
      });
    }
  };

  const scrollGrid = (direction) => {
    if (!api.gridApi) {
      return;
    }

    const displayedRowCount = api.gridApi.getDisplayedRowCount();
    if (displayedRowCount === 0) {
      return;
    }

    let nodeIndex = 0;
    const lastRowIndex = displayedRowCount - 1;
    const displayedRows =
      Math.floor(
        api.gridApi?.["gridBodyCon"].eBodyViewport.clientHeight / ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = api.gridApi.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = api.gridApi.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    api.gridApi.ensureIndexVisible(nodeIndex);
  };

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  const setListStatus = (type = FISCAL_TYPES.NAO_HOMOLOGADO) => {
    if (type === FISCAL_TYPES.NFCE || type === FISCAL_TYPES.NAO_HOMOLOGADO)
      return itemsStatusNfce;

    return itemsStatusSat;
  };

  const setInitialStatus = (type = FISCAL_TYPES.NAO_HOMOLOGADO) => {
    const list = setListStatus(type);

    if (props?.filters?.status) {
      return props.filters.status;
    }

    return type === 1 ? list[0] : list[0];
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prevItems) => {
      if (prevItems.includes(item)) {
        return prevItems.filter((prevItem) => prevItem !== item);
      } else {
        return [...prevItems, item];
      }
    });
  };

  const handleSelection = useCallback((item) => {
    setOpenNoteDetails(item);
  }, []);

  /**
   * Configura o tipo de nota fiscal a ser emitida
   */
  const setType = () => {
    return itemsTypes[fiscalType];
  };

  return (
    <Card
      title={title}
      handleClose={handleClose}
      closeEnable
      fullHeight
      className=""
      id="cardList"
    >
      <MonitorNotesWrapper className={classNames({ isSmartPos })}>
        <Filters className={classNames({ isSmartPos })}>
          <div>
            <Period
              ref={periodRef}
              object={filters}
              fieldStart="initialDate"
              fieldEnd="finalDate"
              initialValueStart={props.filters?.initialDate || new Date()}
              initialValueEnd={new Date()}
            />
          </div>
          <OtherFilters>
            <FormControl
              required
              label="Status"
              component={Select}
              object={filters}
              field="status"
              // flex="1"
              propToShow="name"
              options={setListStatus(fiscalType)}
              initialvalue={
                props.filters?.status || setInitialStatus(fiscalType)
              }
            />
            {/* <div style={{ marginLeft: "10px" }}> */}
            <FormControl
              required
              label="Tipos"
              component={Select}
              object={filters}
              field="type"
              // flex="0.7"
              propToShow="name"
              options={itemsTypes}
              initialvalue={setType()}
              // disabled={true}
            />
            {/* </div> */}
          </OtherFilters>
          <Button className="btn-purple" onClick={handleSearch}>
            <i className="material-icons">search</i>
            Pesquisar
          </Button>
        </Filters>

        <ActionsButtons>
          {/* {fiscalType !== 2 && (
            <Button
              id="btnReenviar"
              className="btn-purple"
              type="button"
              handleClick={() => handleReauthorizeNote(selectedItems)}
              disabled={selectedItems.length === 0}
            >
              Reautorizar
            </Button>
          )} */}

          {shouldShowCancelButton && (
            <Button
              id="btnCancelar"
              className="btn-purple"
              type="button"
              handleClick={() => handleCancelNote(selectedItems)}
              // disabled={selectedItems.length !== 1}
              disabled={selectedItems.length === 0}
            >
              Cancelar
            </Button>
          )}

          <Button
            id="btnImprimir"
            className="btn-purple"
            type="button"
            handleClick={() => handleReprintNote(selectedItems)}
            // disabled={selectedItems.length !== 1}
            disabled={selectedItems.length === 0}
          >
            Reimprimir
          </Button>

          {fiscalType !== 2 && (
            <Button
              id="btnInutilizar"
              className="btn-outline"
              type="button"
              handleClick={() => setOpenInutilizacao(true)}
            >
              Inutilizar
            </Button>
          )}
        </ActionsButtons>
        {isSmartPos ? (
          <CardMonitorNotes
            onSelectItem={handleSelectItem}
            items={listItens}
            onItemNoteDetail={handleSelection}
          />
        ) : (
          <ContainerTable id="containerTable">
            <DirectionalArrowsContainer>
              <DirectionalArrow
                className="top"
                onClick={() => scrollGrid("top")}
              >
                <span className="material-icons">keyboard_arrow_up</span>
              </DirectionalArrow>

              <DirectionalArrow
                className="bottom"
                onClick={() => scrollGrid("bottom")}
              >
                <span className="material-icons">keyboard_arrow_down</span>
              </DirectionalArrow>
            </DirectionalArrowsContainer>

            <div
              className="ag-theme-balham monitor"
              style={{ width: `calc(100% - 40px)` }}
            >
              <AgGridReact
                rowData={listItens}
                localeText={agGridLocalePtBr}
                getRowNodeId={(data) => data.id + data?.receiptNumber}
                rowSelection="multiple"
                rowHeight={ROW_HEIGHT}
                animateRows={true}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                singleClickEdit
                headerHeight={40}
                suppressContextMenu
                suppressRowClickSelection
                rowBuffer={0}
                onSelectionChanged={(params) => {
                  setSelectedItems(params.api.getSelectedRows());
                }}
                frameworkComponents={{
                  showDetailsRenderer: ShowDetailsRenderer,
                  checkboxRenderer: CheckboxRenderer,
                  headerCheckboxComponent: HeaderCheckboxComponent,
                }}
                onGridReady={(params) => {
                  const { api, columnApi } = params;

                  setApi({
                    gridApi: api,
                    gridColumnApi: columnApi,
                  });
                }}
              >
                {columns.map((column) => {
                  const cellStyle = {
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    wordBreak: "break-word",
                  };

                  const additionalProps = {
                    suppressMenu: true,

                    ...(column.propName === "selectAll" && {
                      lockPosition: true,
                      width: 45,
                      cellRenderer: "checkboxRenderer",
                      headerComponent: "headerCheckboxComponent",
                    }),

                    ...(column.propName === "receiptNumber" && {
                      flex: 0.55,
                      wrapText: true,
                    }),

                    ...(column.propName === "receiptSeries" && {
                      flex: 0.55,
                    }),

                    ...(column.propName === "posId" && {
                      flex: 0.55,
                    }),

                    ...(column.propName === "status" && {
                      flex: 0.97,
                      wrapText: true,
                      cellClass: (params) => [
                        "status",
                        String(params?.data?.status || "")
                          .toLowerCase()
                          .replace(/\s/g, "-"),
                      ],
                    }),

                    ...((column.propName === "dtAtendimento" ||
                      column.propName === "dtAutorizacao") && {
                      flex: 0.82,
                    }),

                    ...(column.propName === "total" && {
                      flex: 0.55,
                      cellStyle: {
                        ...cellStyle,
                        fontWeight: "bold",
                      },
                    }),

                    ...(column.propName === "viewDetails" && {
                      width: 60,
                      propName: "",
                      cellRenderer: "showDetailsRenderer",
                      cellRendererParams: {
                        onClick: (data) => {
                          setOpenNoteDetails(data);
                        },
                      },
                    }),

                    ...(column.propName === "motivo" && {
                      flex: 1,
                      cellStyle: {
                        "line-height": "400%",
                      },
                    }),
                  };

                  return (
                    <AgGridColumn
                      key={column.propName}
                      cellStyle={cellStyle}
                      field={column.propName}
                      {...additionalProps}
                      headerName={column.title}
                      floatingFilter={column.showFilter}
                      filter={column.typeFilter}
                    />
                  );
                })}
              </AgGridReact>
            </div>
          </ContainerTable>
        )}
      </MonitorNotesWrapper>
    </Card>
  );
};

export default MonitorNotes;
