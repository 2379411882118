import { createGlobalStyle, keyframes, css } from "styled-components";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "material-icons/iconfont/filled.css";
import "material-icons/iconfont/outlined.css";
import { mediaQueryHelper } from "../helpers/mediaQueryHelper";

const skeletonLoadingKeyframe = keyframes`
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;

  }

  .keyboardInput{
    margin-top: 5px !important;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    user-select: none;
  }

  body {
    background-color: #F1F3F6;
    min-height: 100vh;
    padding: 0;
    font-family: 'Dosis', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;

    #root,
    .App {
      min-width: 100vw;
      min-height: 100vh;
      height: 100%;
      flex: 1;

      display: flex;
      flex-direction: column;
    }
  }

  *,
  *:after,
  *:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  input, select {
    font-family: 'Roboto', sans-serif;
    padding: 8px;
  }

  button {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    font-size: 1rem;

    border-radius: 0.5rem;
    border: 0;
  }

  @font-face {
    font-family: 'Dosis';
    src: local('Dosis Regular'), local('Dosis-Regular'),
        url('/static/Dosis/Dosis-Regular.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Dosis';
    src: local('Dosis Bold'), local('Dosis-Bold'),
        url('/static/Dosis/Dosis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
        url('/static/internal-fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter Medium'), local('Inter-Medium'),
        url('/static/internal-fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Dosis';
    src: local('Dosis Regular'), local('Dosis-Regular'),
        url('/static/Dosis/Dosis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
        url('/static/internal-fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Dosis';
    src: local('Dosis Light'), local('Dosis-Light'),
        url('/static/Dosis/Dosis-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Dosis';
    src: local('Dosis SemiBold'), local('Dosis-SemiBold'),
        url('/static/Dosis/Dosis-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter Thin'), local('Inter-Thin'),
        url('/static/internal-fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  .display__hide {
    display: none;
  }

  .skeleton {
  animation: ${skeletonLoadingKeyframe} 1s linear infinite alternate;

    .skeleton__text {
      width: 100%;
      height: 0.7rem;
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;

      animation: ${skeletonLoadingKeyframe} 1.025s linear infinite alternate !important;
    }

    .skeleton__text__body {
      width: 75%;
    }

    .skeleton-footer {
      width: 30%;
    }
  }

  .ag-theme-balham {

    &.pdv {
      .ag-header {
        background: #e7eaf1;
      }

      .ag-cell-label-container {
        font-weight: 700;
        font-size: 14px;
      }

      .ag-root-wrapper {
        border: none;
      }

      .ag-row {
        font-weight: 500;
      }

      .ag-row-odd {
        background-color: #FAFAFD;
      }

      .ag-floating-bottom-container .ag-row {
        border: none !important;
        background-color: #EDE7F5;
      }

      *::-webkit-scrollbar {
        width: 1px;
      }
    }

    /* .ag-icon {
      font-family: "Material Icons" !important;
    } */

    // Icons
    /* .ag-icon-tree-closed {
      background: transparent url() center/contain no-repeat;
      color: transparent;
    } */

  }

  .ag-input-field-input  {
    height: 22px;
    border-radius: 4px;
  }

  .customCell {
    display: grid;
    align-items: center;
    font-size: 12px;
    word-break: break-word;
  }

  .coupon {
    background-color: #66BB6A;
    box-shadow: 0px 3px 4px rgba(0,0,0,.2);
    color: #fff;
    width: 100%;

    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    top: 0;

    padding: 1rem;
    text-align: center;

    font-weight: 500;
    font-size: 1rem;

    span {
      font-weight: 700;
    }

    &.coupon-has-warn {
      background-color: #ff890d;
      color: #fff;
    }

    &.coupon-has-danger {
      background-color: #da482c;
      color: #fff;
    }
  }

  .list__campaigns__reshop {
    width: 100%;
    overflow-x: auto;
    text-align: left;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(46, 0, 55, 0.4);
      border-radius: 20px;
      border: 0;
    }
  }

  .ReactModalPortal .ReactModal__Overlay--after-open{
    z-index: 2;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .hg-layout-numeric {
      max-width: 80vw !important;
      min-width: auto !important;

      .hg-button {
        height: auto !important;
        width: auto !important;

        &.hg-button-bksp span:after {
          font-size: 1rem !important;
        }
      }
    }
  `)}
`;
