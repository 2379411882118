import React from "react";
import icCupom from "../../../assets/ic-cupom.svg";
import PropTypes from "prop-types";
import { getThemeSettings } from "../../../configuration";

const Campaign = (props) => {
  const { campaigns, reshopUser } = props;

  if (!reshopUser) {
    return null;
  }

  return (
    <div className="box-campaign">
      <h1 className="text-campaign">
        {getThemeSettings?.text?.campaign
          ? getThemeSettings.text.campaign
          : "Seu cupom exclusivo ativo pelo app"}
      </h1>
      <div className="content-campaign gridscroll">
        {campaigns.map((campaign) => (
          <div className="item-campaign">
            <div className="icon">
              <img src={icCupom} className="" />
            </div>
            <h1 className="item-text-campaign">{campaign.CampaignName}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Campaign;
