import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../../hook/cartHook";
import { sendSaleOrderService } from "../../../../services/sendSaleOrderService";
import { processesOperationService } from "../../../../services/processesOperationService";

import { Container as ContainerPayment } from "../../paymentMethods/styles";
import { PagesEnum } from "../../../constants/pagesEnum";
import { printerActionService } from "../../../../services/actions/printerActionService";
import { LoadingWhiteWithText } from "../../../components/LoadingWhiteWithText";
import { cancelOperation } from "../../../../services/verifyRedeemValueService";
import { PAYMENT_METHOD_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import {
  getPaymentsSerializedToOrder,
  getTotalAlreadyPaid,
  saveLocalPayment,
} from "../../../helpers/multiPaymentsHelper";
import { nsuValueHelper } from "../../../helpers/nsuValueHelper";
import { useCashdesk } from "../../../hook/cashdeskHook";

const TEXT_STATUS_DEFAULT = "Transação em andamento. Por favor, aguarde.";
const TRANSMITTING_NOTE = "Aguarde transmitindo a nota...";

let timeout;
let sendingPay = false;

export const PaymentCashPage = () => {
  const {
    reshopTotalPrice,
    totalPrice,
    itemsInCart,
    Sale,
    addPaymentSuccess,
    totalPriceWDiscount,
    manualDiscount,
  } = useCart();

  const location = useLocation();
  const navigate = useNavigate();
  const { cashdesk } = useCashdesk();

  const [textStatus, setTextStatus] = useState(TEXT_STATUS_DEFAULT);

  const goToPaymentMethods = () => {
    if (window.desktopApp) {
      // window.desktopApp.publish("machine.devices.tef.undo", {});
    }

    navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
  };

  useEffect(() => {
    // const paymentTransition = "DINHEIRO";
    const {
      totalPriceToPayNow,
      totalChangeMoney,
      PAYMENT_METHOD: paymentTransition = "DINHEIRO",
      resendPay = false,
    } = location.state || {};

    if (resendPay) {
      sendingPay = false;
    }

    if (!sendingPay) {
      sendingPay = true;

      if (totalPriceToPayNow !== undefined) {
        saveLocalPayment(paymentTransition, {
          amountPaid: totalPriceToPayNow,
          changeMoney: totalChangeMoney,
        });
      }

      if (window.Android) {
        console.log(
          "Prices and Items: " +
            JSON.stringify({
              payment_method: paymentTransition,
              totalAlreadyPaid: getTotalAlreadyPaid(),
              totalPrice,
              totalManualDiscount: manualDiscount,
              reshopTotalPrice,
              totalPriceWDiscount,
              itemsInCart,
            })
        );
      }

      if (
        location.state?.isAllItensFidelity ||
        getTotalAlreadyPaid().toFixed(2) >=
          totalPriceWDiscount
      ) {
        processesOperationService({
          cartTotalPrice: totalPrice,
          cartTotalPriceWithoutQuantity: totalPrice,
          itemsCart: itemsInCart,
          totalInCart: itemsInCart.length,
        }).then(async () => {
          try {
            sendingPay = false;

            const payments = getPaymentsSerializedToOrder();

            timeout = setTimeout(() => {
              setTextStatus(TRANSMITTING_NOTE);
            }, 3000);

            const orderResult = await sendSaleOrderService({
              payments,
              options: {
                hasTef: false,
                paymentTransition,
              },
              prices: {
                totalPrice,
                totalPriceWDiscount,
                reshopTotalPrice: reshopTotalPrice ? reshopTotalPrice : 0,
                totalManualDiscount: manualDiscount,
              },
              items: itemsInCart,
              Sale,
              cashDeskOpenDateId: cashdesk.cashDeskOpenDate.id,
            });

            // if (window.desktopApp) {
            //   window.desktopApp.publish("machine.devices.tef.confirm", {});
            //   window.desktopApp.publish(
            //     "machine.devices.tef.pbm.finishTefTransactions",
            //     {}
            //   );
            // }

            const {
              errorWhenAuthorizing,
              pathDocFiscal,
              saleControl,
              danfeUrl,
              danfeBase64,
            } = orderResult?.payload?.orderSale;

            printerActionService({
              errorWhenAuthorizing,
              pathDocFiscal,
              saleControl,
              danfeUrl,
              danfeBase64,
            });

            setTimeout(() => {
              navigate(PagesEnum.PAYMENT_SUCCESSFUL, { replace: true });
            }, 250);
          } catch (error) {
            sendingPay = false;
            cancelOperation(nsuValueHelper()).then(() => {
              goToPaymentMethods();
            });
          }
        });
      } else {
        sendingPay = false;
        goToPaymentMethods();
      }
    }
  }, [location, reshopTotalPrice, totalPrice]);

  useEffect(() => {
    if (typeof addPaymentSuccess !== "undefined") {
      setTimeout(() => {
        addPaymentSuccess();
      }, 200);
    }
  }, [addPaymentSuccess]);

  useEffect(() => {
    const payment_method = sessionStorage.getItem(PAYMENT_METHOD_KEY_NAME);

    if (timeout) {
      setTextStatus(TEXT_STATUS_DEFAULT);
      clearTimeout(timeout);
    }

    if (payment_method !== "DINHEIRO") {
      goToPaymentMethods();
    }
  }, [location]);

  return (
    <ContainerPayment>
      <div className="loading__container">
        <LoadingWhiteWithText>{textStatus}</LoadingWhiteWithText>
      </div>
    </ContainerPayment>
  );
};
