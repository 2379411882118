import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";
import { isSmartPos } from "../../../aaone/configuration";

export const Container = styled.div`
  &.button__navigation {
    background-color: #2e0037;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    svg {
      width: 3rem;
      height: 3rem;
    }

    &.button__left {
      margin-right: 1rem;
    }

    &.button__right {
      margin-left: 1rem;
    }

    &.button__disabled {
      cursor: not-allowed;
      background-color: #ccc;
      color: #797979;
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    &.button__navigation svg {
      width: 2rem;
      height: 2rem;
    }
  `)}

  &.isSmartPos {
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &.button__left {
      margin-right: 0.2rem;
    }

    &.button__right {
      margin-left: 0.2rem;
    }
  }
`;
