import React, { useCallback, useEffect, useState } from "react";
import { NoteDetails } from "../../components/monitor-notes/detalhes";
import { currencyString } from "../../helpers/masks";
import {
  getListSalesReturn,
  salesReturn,
} from "../../../services/orderService";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import {
  getFiscalType,
  getRetailerId,
  getTerminalID,
} from "../../../aaone/configuration";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hook/authHook";
import { useDialog } from "../../hook/dialogHook";
import SalesReturn from "../../components/sales-return";
import { MessageBox } from "../../components/modules";
import { handlePrintCoupon } from "../../helpers/printer/salesReturn";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { useCashdesk } from "../../hook/cashdeskHook";

export const SalesReturnContainer = (props) => {
  const { showAlert } = useDialog();
  const systemParams = getSystemParamsHelper();

  const [documentNumber] = useState(systemParams?.storeDetails?.cnpj);
  const fiscalType = getFiscalType();

  const [loggedUser, setLoggedUser] = useState();
  const [openNoteDetails, setOpenNoteDetails] = React.useState(null);
  const [itemsOrders, setItemsOrders] = useState([]);
  const [filters, setFilters] = useState({});
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { cashdesk } = useCashdesk();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    const propsLocation = location?.state;

    let manager = propsLocation?.auth
      ? propsLocation.auth.user
      : auth.userAuthData;

    setLoggedUser(manager);
  }, []);

  const getStatusGrid = (id) => {
    const itemsStatus = {
      1: "Autorizada",
    };
    return itemsStatus[id];
  };

  const listItems = useCallback(() => {
    return itemsOrders.map((item) => ({
      ...item,
      protocolo: item.fiscalCanceledDocument?.protocol
        ? item.fiscalCanceledDocument.protocol
        : item.fiscalDocument.protocol,
      dtAtendimento: formatDateHelper(
        new Date(item.createdAt),
        "dd/MM/yyyy HH:mm:ss"
      ),
      dtAutorizacao: formatDateHelper(
        new Date(
          item.fiscalCanceledDocument?.returnDate
            ? item.fiscalCanceledDocument.returnDate
            : item.fiscalDocument.returnDate
        ),
        "dd/MM/yyyy HH:mm:ss"
      ),
      status: getStatusGrid(item.fiscalStatus),
      motivo: item.fiscalCanceledDocument?.reason
        ? item?.fiscalCanceledDocument?.reason
        : item?.fiscalDocument?.reason?.replace(/^\[.*]: /g, ""),
      keyaccess: item.fiscalCanceledDocument?.accessKey
        ? item.fiscalCanceledDocument?.accessKey
        : item.fiscalDocument.accessKey ?? "----",
      urlDanfe: item.fiscalDocument.danfeUrl ?? "----",
      isChecked: false,
      total: item.totalDiscount && currencyString(item.totalDiscount),
      statusNumber: item.status,
      cnpj: documentNumber,
      receiptNumber: item.receiptNumber,
      receiptSeries: item.receiptSeries,
    }));
  }, [documentNumber, itemsOrders]);

  const onHandleFilters = (
    data = {
      initialDate: new Date(),
      finalDate: new Date(),
      status: { id: 0 },
      type: { id: fiscalType },
    }
  ) => {
    const objFilter = {
      retailerId: getRetailerId(),
      posId: getTerminalID().toString(),
    };

    getListSalesReturn(objFilter)
      .then((response) => {
        const orders = response.sort((a, b) => {
          if (a.receiptNumber > b.receiptNumber) {
            return 1;
          }

          if (
            Date(a.fiscalDocument?.returnDate) >
            new Date(b.fiscalDocument?.returnDate)
          ) {
            return 1;
          }

          return -1;
        });

        setItemsOrders(orders);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = (order) => {
    setSelectedOrder(order);
    setShowMessageBox(true);
  };

  const closeModal = () => {
    setShowMessageBox(false);
  };

  const handleConfirm = () => {
    setShowMessageBox(false);

    if (cashdesk.closingDate) {
      showAlert({ message: "Não é possível cancelar uma nota de um caixa fechado." });

      return;
    } else if (selectedOrder.cashDeskId !== cashdesk.id) {
      showAlert({ message: "Não é possível cancelar uma nota de um caixa anterior." });

      return;
    }

    salesReturn({
      retailerId: getRetailerId(),
      posId: getTerminalID().toString(),
      orderId: selectedOrder.id,
    })
      .then((res) => {
        onHandleFilters();
        showAlert({
          message: "Devolução de venda realizada com sucesso!",
        });

        handlePrintCoupon(res, auth.userAuthData);
      })

      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <SalesReturn
        listItens={listItems()}
        handleClick={(data) => onHandleFilters(data)}
        handleCancel={handleCancel}
        fiscalType={fiscalType}
        setOpenNoteDetails={setOpenNoteDetails}
        filters={filters}
        setMonitorNotesFilters={setFilters}
      />

      <NoteDetails
        noteDetails={openNoteDetails}
        showModal={!!openNoteDetails}
        setOpenNoteDetails={setOpenNoteDetails}
      />

      {showMessageBox && (
        <MessageBox
          message="Deseja realizar a devolução da venda?"
          handleCancel={closeModal}
          handleConfirm={handleConfirm}
          okText="Sim"
        />
      )}
    </>
  );
};

export default SalesReturnContainer;
