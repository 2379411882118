import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import classNames from "classnames";

import { sendSaleOrderService } from "../../../../services/sendSaleOrderService";
import { processesOperationService } from "../../../../services/processesOperationService";

import { useCart } from "../../../hook/cartHook";
import { PagesEnum } from "../../../constants/pagesEnum";
import { formatNumberHelper } from "../../../helpers/formatNumberHelper";
import { transactionData } from "../../../../aaone/shared/utils/transactionNumber";

import { LoadingDots } from "../../../components/LoadingDots";
import { Container } from "../../paymentMethods/styles";
import { cancelOperation } from "../../../../services/verifyRedeemValueService";
import { printerActionService } from "../../../../services/actions/printerActionService";
import { IconCardPayment } from "../../../components/icons/CardPayment";
import {
  getPaymentsSerializedToOrder,
  getTotalAlreadyPaid,
  saveLocalPayment,
} from "../../../helpers/multiPaymentsHelper";
import { HeaderPaymentMethod } from "../../../components/Header/paymentMethod";
import { useDialog } from "../../../hook/dialogHook";
import { InvisibleBlock, TextContainer } from "./styles";
import {
  getPaymentsMethodHelper,
  getTransactionCodeFromType,
} from "../../../helpers/paymentsHelper";
import { useCashdesk } from "../../../hook/cashdeskHook";

let timeoutGoToPaymentSuccess;
let timeoutFallback;
let waitMessage = false;
let isDisableSetText = false;
let isStartedMethod = false;
let nextMessages = [];

const TRY_AGAIN_MESSAGE =
  "Ops... Ocorreu um problema com o pagamento! Por favor tente novamente.";

export const PaymentWithCardPage = () => {
  const {
    reshopTotalPrice,
    nsuValue,
    totalPrice,
    totalPriceWDiscount,
    itemsInCart,
    Sale,
    addPaymentSuccess,
    manualDiscount,
  } = useCart();

  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useDialog();
  const { cashdesk } = useCashdesk();

  const [textStatus, setTextStatus] = useState(null);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isDisableBackButton, setIsDisableBackButton] = useState(false);
  const [qrcode, setQrCode] = useState(null);

  const METHODS = {
    CREDITO: "machine.devices.tef.creditcard.getBrand",
    DEBITO: "machine.devices.tef.debitcard.getBrand",
    VOUCHER: "machine.devices.tef.debitcard.vouchercard.getBrand",
    PIX_SITEF: "machine.devices.tef.digitalWallet.getBrand",
  };

  const { PAYMENT_METHOD = "DEBITO" } = location.state || {};

  const navigateToPaymentsMethod = () => {
    navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
  };

  const handleGoToPayments = () => {
    isDisableSetText = true;
    setTextStatus("Aguarde, voltando para os métodos de pagamentos...");
    setIsLoadingInvoice(true);
    setIsDisableBackButton(true);

    if (window.desktopApp) {
      window.desktopApp.publish(
        "machine.devices.tef.cancel.pending.operation",
        ""
      );
    }

    if (timeoutFallback) {
      clearTimeout(timeoutFallback);
    }

    timeoutFallback = setTimeout(() => {
      navigateToPaymentsMethod();
    }, 6000);
  };

  const goToPaymentSuccessful = () => {
    const isFinish = !!sessionStorage.getItem("IS_FINISH_CARD");

    if (isFinish) {
      sessionStorage.removeItem("IS_FINISH_CARD");
    }

    navigate(
      isFinish ? PagesEnum.PAYMENT_SUCCESSFUL : PagesEnum.PAYMENT_METHODS,
      {
        replace: true,
      }
    );
  };

  const goToPaymentMethods = () => {
    handleGoToPayments();

    setTimeout(() => {
      if (timeoutFallback) {
        clearTimeout(timeoutFallback);
      }

      navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
    }, 4000);
  };

  const { totalPriceToPayNow, totalChangeMoney } = location.state || {};
  const totalPriceToPayNowFloat = Number(totalPriceToPayNow)
    .toFixed(2)
    .replace(",", "")
    .replace(".", ",");

  const sendPayment = async (data) => {
    console.log(`sendPayment | ${JSON.stringify(data)}`);

    try {
      const paymentTransition = PAYMENT_METHOD;

      const isWindowDesktopApp =
        data.platform !== "android" && !!window?.desktopApp;

      if (isWindowDesktopApp) {
        Object.assign(data, {
          platform: "windows",
        });
      }

      let transactionDatetime = new Date();
      try {
        if (data?.creationDate)
          transactionDatetime = transactionData(
            Date.parse(data?.creationDate),
            "dd/MM/yyyy hh:mm:ss"
          );
      } catch (e) {}

      const payment = {
        dateTimeInsert: transactionDatetime,
        dtefCode: data.tefGateway !== "SITEF" ? data?.brandCode ?? 0 : 0,
        sitefCode: data.tefGateway === "SITEF" ? data?.brandCode ?? 0 : 0,
        transactionType: paymentTransition,
        description:
          data.brandName?.toUpperCase() + " " + "(" + paymentTransition + ")",
        value: Number(totalPriceToPayNow),
        controlCode: data.controlCode,
        authorizationCode: data?.authorizationCode,
        authorizationCodeRede: data?.authorizationCodeRede,
        //authorizationCodeRede: data?.receiptNumber,
        paymentTransition: paymentTransition,
        // paymentCode: 0,
        authorizationCodeOnline: data?.authorizationCodeOnline,
        qrLinxTransactionId: null,
        qrLinxTradingName:
          data.tefGateway !== undefined
            ? data.tradingName ??
              "" + "" + data.tefGateway.replace("Payment Method:", "")
            : null,
        sticker: data?.sticker ?? null,
        carPlate: data?.carPlate ?? null,
        semPararLane: null,
        transactionDatetime: transactionDatetime,
      };
      let paymentIn = await getPaymentsMethodHelper().then((paymentsMethods) =>
        paymentsMethods?.reduce((acc, curr) => {
          const found = curr.payments.filter((currPayment) => {
            if (payment.dtefCode != null && payment.dtefCode > 0)
              return (
                currPayment.integrationCodeDtef === payment.dtefCode &&
                currPayment.paymentOneBaseCode ===
                  getTransactionCodeFromType(payment.paymentTransition)
              );
            else if (payment.sitefCode != null && payment.sitefCode > 0)
              return (
                currPayment.integrationCodeSitef === payment.sitefCode &&
                currPayment.paymentOneBaseCode ===
                  getTransactionCodeFromType(payment.paymentTransition)
              );

            return null;
          });

          if (found.length) {
            acc.push(found);
          }

          return acc;
        }, [])
      );
      saveLocalPayment(paymentTransition, {
        amountPaid: totalPriceToPayNow,
        changeMoney: totalChangeMoney,
        ...payment,
      });

      if (!paymentIn.length) {
        navigate(PagesEnum.PAYMENT_POS_METHOD, {
          replace: true,
          state: {
            PAYMENT_METHOD: payment.paymentTransition,
            totalPriceToPayNow,
            totalChangeMoney,
            paymentTEFData: payment,
            data: {
              description: payment.paymentTransition,
            },
            ...(location?.state?.data && { data: location.state.data }),
          },
        });

        return;
      }

      addPaymentSuccess();

      if (window.Android) {
        console.log(
          "Prices and Items: " +
            JSON.stringify({
              payment_method: paymentTransition,
              totalAlreadyPaid: getTotalAlreadyPaid(),
              totalPrice,
              totalManualDiscount: manualDiscount,
              reshopTotalPrice,
              totalPriceWDiscount,
              itemsInCart,
            })
        );
      }

      if (getTotalAlreadyPaid() >= totalPriceWDiscount) {
        processesOperationService({
          cartTotalPrice: totalPrice,
          cartTotalPriceWithoutQuantity: totalPrice,
          itemsCart: itemsInCart,
          totalInCart: itemsInCart.length,
        }).then(async () => {
          const payments = getPaymentsSerializedToOrder();

          setTextStatus("Transação em andamento, aguarde a impressão da nota.");

          sendSaleOrderService({
            payments,
            options: {
              hasTef: true,
              paymentTransition,
            },
            prices: {
              totalPrice,
              totalPriceWDiscount,
              reshopTotalPrice: reshopTotalPrice ? reshopTotalPrice : 0,
              totalManualDiscount: manualDiscount,
            },
            items: itemsInCart,
            Sale,
            cashDeskOpenDateId: cashdesk.cashDeskOpenDate.id,
          })
            .then(async (orderResult) => {
              sessionStorage.setItem("IS_FINISH_CARD", "ok");
              setTextStatus("Nota impressa, finalizando operação.");

              if (orderResult?.payload?.orderSale) {
                const {
                  errorWhenAuthorizing,
                  pathDocFiscal,
                  saleControl,
                  danfeUrl = null,
                  danfeBase64 = null,
                } = orderResult?.payload?.orderSale || {};

                printerActionService({
                  errorWhenAuthorizing,
                  pathDocFiscal,
                  saleControl,
                  danfeUrl: danfeUrl ? danfeUrl : null,
                  danfeBase64: danfeBase64 ? danfeBase64 : null,
                });

                goToPaymentSuccessful();
              }
            })
            .catch(() => {
              setTextStatus("Ocorreu um erro para ao transmitir nota...");

              //TODO - Adicionar método de cancelamento do mobile

              setTimeout(() => {
                navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
              }, 2000);
            });
        });
      } else {
        goToPaymentSuccessful();
      }
    } catch (e) {
      console.log(`sendPayment | error ${JSON.stringify(e)}`);
      console.error(`sendPayment | error ${e}`);
      throw e;
    }
  };

  useEffect(() => {
    if (window.Android) {
      window.returnPayments = (data) => {
        console.log(`window.returnPayments | ${JSON.stringify(data)}`);
        try {
          if (!data.success) {
            setTextStatus("Desfazendo operação...");

            setTimeout(() => {
              console.log(`window.Android.cancelOneTransaction | {}`);
              window.Android.cancelOneTransaction(data.nsu);
            }, 500);
            setIsDisableBackButton(false);
            goToPaymentMethods();
          } else {
            const payloadToSendPayment = {
              platform: "android",
              transactionType: PAYMENT_METHOD,
              description: PAYMENT_METHOD,
              amount: data.transactionAmount,
              controlCode: String(data.nsu), //REVIEW - Validar se é nsu ou authorizerNsu
              authorizationCode: String(data.authorizationCode), //REVIEW - Validar se é authorizationCode ou acquirerCode
              paymentTransition: PAYMENT_METHOD,
              tefGateway: "D-TEF",
              brandCode: data.brandCode,
              brandName: data.brand,
              acquirerName: data.acquirerName,
              dtefCode: data.brandCode,
            };

            sendPayment(payloadToSendPayment);
          }
        } catch (e) {
          console.log(`WithCard returnPayments | error ${JSON.stringify(e)}`);
        }
      };
    }

    return () => {
      window.returnPayments = undefined;
    };
  }, [location]);

  useEffect(() => {
    nextMessages = [];

    isStartedMethod = false;
    isDisableSetText = false;

    if (timeoutGoToPaymentSuccess) {
      clearTimeout(timeoutGoToPaymentSuccess);
    }

    if (timeoutFallback) {
      clearTimeout(timeoutFallback);
    }
  }, [location]);

  useEffect(() => {
    if (window.desktopApp) {
      const PUBLISH_METHOD = METHODS[PAYMENT_METHOD] ?? METHODS["DEBITO"];

      if (!isStartedMethod) {
        isStartedMethod = true;

        window.desktopApp.publish(PUBLISH_METHOD, {
          amount: totalPriceToPayNowFloat,
          installmentAmount: totalPriceToPayNowFloat,
          type: 0,
          paymentForm: 1,
          installments: 1,
        });
      } else {
        navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
      }

      window.desktopApp.subscribe(
        "machine.devices.tef.warnings",
        (channel, payload) => {
          console.log("machine.devices.tef.warnings", payload);

          if (payload.message) {
            if (payload.message.message.search("CARTAO COM ERRO OU MAL INSERIDO") >= 0) {
              setTextStatus("Cartão com erro ou mal inserido.");

              return;
            } else if (payload.message.message.search("CARTAO REMOVIDO") >= 0) {
              window.desktopApp.unsubscribe("machine.devices.tef.warnings");
            }

            showAlert({
              title: payload.message.title ? payload.message.title : undefined,
              message: payload.message.message,
            });
          } else {
            showAlert({
              message: TRY_AGAIN_MESSAGE,
            });
          }

          cancelOperation(nsuValue()).then(() => {
            goToPaymentMethods();
          });

          const description =
            (payload?.message?.message?.message ??
              payload?.message?.message ??
              null) ||
            "Error ao comunicar com o sitef";

          if (description.indexOf("cancelada pelo operador") === -1) {
            sessionStorage.setItem("@AAOne:modalWarnPayment", description);

            cancelOperation(nsuValue()).then(() => {
              handleGoToPayments();
            });
          }
        }
      );
      console.log(window.desktopApp.subscribe);
      window.desktopApp.subscribe(
        "machine.devices.desktop.error",
        (channel, data) => {
          if (data.errors.indexOf("ERRO PINPAD") > -1) {
            cancelOperation(nsuValue()).then(() => {
              goToPaymentMethods();
            });
          }
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.payment.error",
        (channel, data) => {
          // window.desktopApp.publish("machine.devices.tef.undo", {});

          cancelOperation(nsuValue()).then(() => {
            goToPaymentMethods();
          });
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.undo.completed",
        (channel, data) => {
          console.log("machine.devices.tef.undo.completed", data);

          cancelOperation(nsuValue()).then(() => {
            goToPaymentMethods();
          });
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.cancel.pending.operation.completed",
        (channel, data) => {
          console.log(
            "machine.devices.tef.cancel.pending.operation.completed",
            data
          );

          cancelOperation(nsuValue()).then(() => {
            goToPaymentMethods();
          });
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.debitcard.completed",
        (channel, data) => {
          console.log("machine.devices.tef.debitcard.completed", data);

          setIsLoadingInvoice(true);
          setIsDisableBackButton(true);
          sendPayment(data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.creditcard.completed",
        (channel, data) => {
          console.log("machine.devices.tef.creditcard.completed", data);

          setIsLoadingInvoice(true);
          setIsDisableBackButton(true);
          sendPayment(data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.showQrCode",
        (channel, data) => {
          console.log("machine.devices.tef.showQrCode", data);
          setQrCode(data.qrCode);
          setIsDisableBackButton(true);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.tef.digitalWallet.completed",
        (channel, data) => {
          console.log("machine.devices.tef.digitalWallet.completed", data);

          setQrCode(null);
          setIsLoadingInvoice(true);
          setIsDisableBackButton(true);
          sendPayment(data);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.qrcode.wallets",
        (channel, payload) => {
          console.log("machine.devices.qrcode.wallets", payload);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.semparar.started",
        (channel, payload) => {
          console.log("machine.devices.semparar.started", payload);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.semparar.completed",
        (channel, payload) => {
          console.log("machine.devices.semparar.completed", payload);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.semparar.failed",
        (channel, payload) => {
          console.log("machine.devices.semparar.failed", payload);
        }
      );

      window.desktopApp.subscribe(
        "machine.devices.message",
        (channel, payload) => {
          const data = payload.qrcode ? payload.value : payload;
          const messageTef = String(
            sessionStorage.getItem("@AAOne:messageTef") ?? data ?? ""
          );

          console.log("machine.devices.message", data);

          const hasErrorInMessage = messageTef
            .split(" - ")
            .find(
              (text, index) => index === 0 && !Number.isNaN(Number(text.trim()))
            )?.length;

          if (hasErrorInMessage) {
            waitMessage = true;

            if (!isDisableSetText) {
              sessionStorage.setItem("@AAOne:modalWarnPayment", messageTef);

              handleGoToPayments();
            }

            setTimeout(() => {
              waitMessage = false;

              nextMessages.forEach((oldMessage, indexMessage) => {
                setTimeout(() => {
                  if (!isDisableSetText) {
                    setTextStatus(oldMessage);
                  }
                }, 2000);

                if (nextMessages.length - 1 === indexMessage) {
                  nextMessages = [];
                }
              });
            }, 3000);
          }

          if (messageTef === "SOLICITANDO AUTORIZACAO, AGUARDE...") {
            setIsDisableBackButton(true);
          }

          if (!waitMessage && !isDisableSetText) {
            setTextStatus(messageTef);
          } else {
            nextMessages.push(messageTef);
          }

          if (
            messageTef
              .toLowerCase()
              .indexOf("transacao nao aceita, retire o cartao") > -1
          ) {
            handleGoToPayments();
          }
        }
      );

      // window.desktopApp.subscribe(
      //   "machine.devices.printerpdf.completed",
      //   () => {
      //     setSuccessfulPrint(true);
      //   }
      // );
    } else if (window.Android) {
      setIsDisableBackButton(true);

      const multiplePayments =
        totalPriceWDiscount -
          Number(totalPriceToPayNowFloat.replace(",", ".")) >
        0
          ? "000"
          : "001";

      console.log(
        `window.Android.initTransaction | {payment: ${String(
          PAYMENT_METHOD
        ).toUpperCase()}, total: ${Number(
          totalPriceToPayNowFloat.replace(",", ".")
        )}}, multiple: ${multiplePayments === "000"}`
      );
      window.Android.initTransaction(
        String(PAYMENT_METHOD).toUpperCase(),
        Number(totalPriceToPayNowFloat.replace(",", ".")),
        multiplePayments
      );
    }

    return () => {
      if (window.desktopApp) {
        window.desktopApp.unsubscribe(
          "machine.devices.tef.creditcard.completed"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.debitcard.completed"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.cancel.pending.operation.completed"
        );
        window.desktopApp.unsubscribe(
          "machine.devices.tef.debitcard.qrcode.getBrand"
        );
        window.desktopApp.unsubscribe("machine.devices.qrcode.wallets");
        sessionStorage.removeItem("@AAOne:messageTef");
        window.desktopApp.unsubscribe("machine.devices.message");
        window.desktopApp.unsubscribe("machine.devices.tef.undo.completed");
        window.desktopApp.unsubscribe("machine.devices.payment.error");
        window.desktopApp.unsubscribe("machine.devices.desktop.error");
        window.desktopApp.unsubscribe("machine.devices.printerpdf.completed");
        window.desktopApp.unsubscribe("machine.devices.tef.showQrCode");
        window.desktopApp.unsubscribe(
          "machine.devices.tef.digitalWallet.completed"
        );
        window.desktopApp.unsubscribe("machine.devices.tef.warnings");
        window.desktopApp.unsubscribe("machine.devices.semparar.started");
        window.desktopApp.unsubscribe("machine.devices.semparar.completed");
        window.desktopApp.unsubscribe("machine.devices.semparar.failed");
      }
    };
  }, []);

  if (!PAYMENT_METHOD) {
    return <Navigate to={PagesEnum.PAYMENT_METHODS} replace />;
  }

  return (
    <>
      {isDisableBackButton && <InvisibleBlock />}
      <Container>
        <div className="content__methods">
          <HeaderPaymentMethod
            onClickBackButton={handleGoToPayments}
            disabledBackButton={isDisableBackButton}
          >
            Pagamento
          </HeaderPaymentMethod>
          <div className="container payment__with__card">
            {PAYMENT_METHOD === "PIX_SITEF" && (
              <>
                {qrcode && (
                  <div className="total">
                    <span>Total: </span>{" "}
                    {formatNumberHelper(totalPriceWDiscount)}
                  </div>
                )}
                <div
                  className={classNames({
                    qrcode__container: true,
                    qrcode__active: qrcode,
                  })}
                >
                  {qrcode && <QRCodeSVG value={qrcode} />}
                </div>
              </>
            )}
            {!qrcode && (
              <>
                <div className="icon__payment__container">
                  {!isLoadingInvoice ? <IconCardPayment /> : <LoadingDots />}
                </div>
              </>
            )}
            <TextContainer>
              {!textStatus ? "Validando..." : textStatus}
            </TextContainer>
          </div>
        </div>
      </Container>
    </>
  );
};
