import styled from "styled-components";

export const BottomComponent = styled.div`
  position: absolute;
  background: #fff;
  font-size: 0.938rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 2.788rem;
  width: 100%;
  bottom: 0;

  color: ${(props) => !props.value && "#77CE99"};
  font-weight: ${(props) => !props.value && "bold"};

  svg {
    color: #868686;
  }

  &.isSmartPos {
    padding: 0.25rem;
    height: auto;
  }
`;

export const CardTable = styled.div`
  cursor: pointer;
  margin-top: 5px;
  width: 7.625rem;
  height: 7.625rem;
  border-radius: 0.375rem;
  display: flex;
  position: relative;
  background-color: ${(props) =>
    props.value && props.info === "ACTIVE"
      ? "#0574E9"
      : props.info === "CLOSING"
      ? "#FF554A"
      : "#77CE99"};
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.isSmartPos {
    height: auto;
    width: auto;

    margin-right: 0.5rem;
  }
`;

export const ElapsedTime = styled.span`
  position: absolute;
  top: 0.2rem;
  left: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
`;

export const TableNumber = styled.span`
  color: #fff;
  font-size: 2rem;
  margin-bottom: 2.2rem;
`;

export const WrapperTableInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;
