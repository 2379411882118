import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content__products {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;
  }

  .button__open-cart {
    border-radius: 0 !important;
    padding: 1rem !important;
    background: #2e0037;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    color: #fff;
    left: 0;
    right: 0;
  }
`;

export const TotalContainer = styled.section`
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  align-items: center;

  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.25em auto;
  margin-top: -5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px;

  width: 100%;

  .item__button {
    background-color: #ffffff;
    border: 1px solid #efefef;

    padding: 0.3rem;

    display: flex;
    justify-content: center;
    align-self: stretch;
    align-items: center;

    font-weight: 500;
    font-size: 2rem;

    flex: 1;

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &.item__code,
    &.item__qtd {
      text-transform: uppercase;
      font-size: 1.5rem;
    }

    &.item__qtd {
      background-color: #ccc;
    }

    &.item__active {
      background-color: #411e5a;
      color: #f2f2f2;
      outline: none;
      border: 1px solid rgb(216, 216, 216);
      border-radius: 4px;
      box-shadow: none;
      font-weight: 600;
    }

    &.skeleton {
      color: transparent;

      border-color: rgba(0, 0, 0, 0.05);

      .skeleton__text {
        height: 1.813rem;
      }
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .item__button {
      font-size: 1.25rem;
      padding: 0.2rem;

      .item__qtd {
        font-size: 1.25rem;
      }
    }
  `)}

  &.isSmartPos {
    box-shadow: none;
  }
`;
