import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalWrapper, { ContentFigure, NivelProductWrapper } from "./style";
import Button from "../button";

import { currency } from "../../utils/currency";

import { addToCart } from "../../../actions/cart";

import { Modal } from "../../components/modules";
import { loadSubProducts } from "../../../../services/catalogService";
import { isShowCustomCombo } from "../../../configuration";
import { getNivelInItem } from "../../utils/returnsLevelOrientedSale";
import {
  FigureItem,
  ImageItem,
  ItemPrice,
} from "../combo-card-individual/style";
import { ContainerTitle, Title } from "../step/style";
import { useLayoutAa } from "../../../contexts/layout";

let productPayload = null;
let productsChildren = [];

const ModalProduct = (props) => {
  const [count, setCount] = useState(1);
  const [accelerators, setAccelerators] = useState({});
  const [modalBlock, setModalBlock] = useState(false);
  const [stepsAutomatic, setStepsAutomatic] = useState([]);
  const [currentNivelProduct, setCurrentNivelProduct] = useState([]);
  const [newDescription, setNewDescription] = useState(
    props.detailedDescription
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const { getTranslateName, isLayout } = useLayoutAa();

  const {
    title,
    value,
    valuePoints,
    image,
    code,
    id,
    modalClose,
    notification,
    suggestion = false,
    removeCount = false,
    accelerator,
    classification,
    quantity,
    promotion,
    currencyPoints,
    backStepCategory,
    hasOrientedSale,
    detailedDescription,
    orientedSale,
    nuuidOne
  } = props;

  const clearPayloadInMemory = () => {
    productPayload = null;
    productsChildren = [];
  };

  const modalCloseCombo = () => {
    clearPayloadInMemory();

    if (classification === "COMBOS") {
      backStepCategory(true);
    }
    modalClose();
  };

  useEffect(() => {
    if (code && stepsAutomatic.length === 0) {
      clearPayloadInMemory();
    }
  }, [code, stepsAutomatic]);

  const handleBackButton = () => {
    if (currentNivelProduct.length >= 1) {
      setCurrentNivelProduct((levels) => {
        const [_, ...oldLevels] = levels;

        return oldLevels;
      });

      return;
    }

    setStepsAutomatic([]);
  };

  const add = () => {
    if (hasOrientedSale === true && !currencyPoints) {
      setCount(1);
      localStorage.setItem("QuantityCombo", count);
    } else {
      let last_vit_numlancto = 0;

      const seq =
        cartItems.filter((item) => item.vit_numlancto).length < 1
          ? 1
          : cartItems.map((item) => item.vit_numlancto).sort((a, b) => b - a)[0] +
            1;
      let getLocalPoints = parseInt(localStorage.getItem("saldoAtual"));
      if (currencyPoints && count * valuePoints > getLocalPoints) {
        setModalBlock(true);
        return;
      }
      const currentSeq =
      seq > last_vit_numlancto ? seq : last_vit_numlancto + 1;

      dispatch(
        addToCart({
          id: id,
          name: title,
          customizations: [],
          image: image,
          price: Number(value),
          qtd: count * quantity, //Feito por conta de produtos em dobro
          code: code,
          classification: classification,
          vit_numlancto: currentSeq,
          quantityDouble: quantity,
          promotion: promotion,
          currencyPoints: currencyPoints || false,
          valuePoints: currencyPoints ? valuePoints : 0,
          nuuidOne: nuuidOne
        })
      );
    }

    if (currencyPoints) {
      atualizarSaldoPontosFidelidade();
    }

    modalClose(true);
    notification();
    setCount(1);
  };

  const addProductInCart = (product, currentMainProductPayload) => {
    const levels = getNivelInItem(product);

    if (levels) {
      const serializedLevels = levels.map((level) => ({
        ...level,
        id: level._id,
        name: `${level?.name ?? level.description}`,
        description: `${level?.name ?? level.description}`,
        customizations: [],
        price: level.value,
        value: level.value,
        qtd: product.qtd,
        featuredImage: level.featuredImage,
        image: level.image,
        lowerValue: level.lowerValue,
        last_vit_numlancto: product.last_vit_numlancto,
        current_vit_numlanctoprincipal: product.current_vit_numlanctoprincipal,
        originalValue:
          level.mixValue ?? product.originalValue ?? product.mixValue,
        code: level.productCode,
        productCode: level.productCode,
        classification: level.classification,
        quantityDouble: product.qtd,
        promotion: {},
        currencyPoints: false,
        valuePoints: 0,
        isProductComposition: product.isProductComposition,
        nuuidOne: level.nuuidOne
      }));

      setCurrentNivelProduct((oldNivel) => [serializedLevels, ...oldNivel]);
    } else {
      const payloadAddToCart = {
        id: product?.id ?? product._id,
        description: `${product?.name ?? product.description}`,
        customizations: [],
        price: 0,
        value: product.value,
        qtd: product.qtd,
        image: product.image,
        vit_numlancto:
          product?.vit_numlancto ??
          product?.last_vit_numlancto ??
          product?.current_vit_numlancto ??
          null,
        vit_numlanctoprincipal:
          product?.vit_numlanctoprincipal ??
          product?.current_vit_numlanctoprincipal ??
          null,
        originalValue: product?.mixValue ?? product.originalValue,
        code: product.productCode,
        classification: product.classification,
        quantityDouble: product.qtd || 1,
        promotion: product.promotion ?? {},
        currencyPoints: false,
        valuePoints: 0,
        isProductComposition: product?.isProductComposition ?? true,
        nuuidOne: product.nuuidOne
      };

      dispatch(addToCart(payloadAddToCart));
      if (currentMainProductPayload) {
        const finalCustomizations = productsChildren.map((currentProduct) =>
          String(currentProduct.name)
        );
        finalCustomizations.push(`${product?.name ?? product.description}`);
        const totalValue = productsChildren.reduce(
          (p, current) => p + Number(current.value),
          Number(payloadAddToCart.value) +
            Number(currentMainProductPayload.price)
        );

        dispatch(
          addToCart({
            ...currentMainProductPayload,
            customizations: finalCustomizations,
            price: totalValue,
            value: Number(currentMainProductPayload.price),
          })
        );
        productsChildren.forEach((currentProduct) => {
          dispatch(addToCart(currentProduct));
        });
        productPayload = null;
        productsChildren = [];
      }

      setStepsAutomatic((oldSteps) => {
        const [_, ...restSteps] = oldSteps;

        if (restSteps.length === 0) {
          setCurrentNivelProduct([]);
          modalClose(true);
          notification();
          setCount(1);
        }

        return restSteps;
      });
    }
  };

  const addAutomatic = () => {
    let getLocalPoints = parseInt(localStorage.getItem("saldoAtual"));

    if (currencyPoints && count * valuePoints > getLocalPoints) {
      setModalBlock(true);
      return;
    }

    const QuantityCombo = count;
    let last_vit_numlancto = 0;

    const seq =
      cartItems.filter((item) => item.vit_numlancto).length < 1
        ? 1
        : cartItems.map((item) => item.vit_numlancto).sort((a, b) => b - a)[0] +
          1;

    loadSubProducts(code)
      .then((res) => {
        clearPayloadInMemory();

        try {
          const { product } = res.payload;

          const currentSeq =
            seq > last_vit_numlancto ? seq : last_vit_numlancto + 1;

          Object.assign(product, {
            vit_numlancto: currentSeq,
          });

          productPayload = {
            id: product._id,
            name: `${product.description}`,
            customizations: [],
            price: product.value,
            value: product.value,
            qtd: QuantityCombo,
            image: product.image,
            vit_numlancto: currentSeq,
            vit_numlanctoprincipal: null,
            originalValue: product.mixValue,
            code: product.productCode,
            classification: product.classification,
            quantityDouble: QuantityCombo,
            promotion: promotion ?? {},
            currencyPoints: false,
            valuePoints: 0,
            isProductComposition: product.isProductComposition,
            nuuidOne: product.nuuidOne
          };

          product.orientedSale[0].orientedSaleSteps.map(
            (currentOrientedSaleStep, indexCurrentOrientedSaleStep) => {
              const hasProductGroupRef =
                currentOrientedSaleStep.referenceProductCodeForGroup !== null;
              const products =
                currentOrientedSaleStep.orientedSaleGroup
                  .orientedSaleGroupProduct;

              const current_vit_numlancto =
                currentSeq + (indexCurrentOrientedSaleStep + 1);
              const current_vit_numlanctoprincipal = currentSeq;

              last_vit_numlancto = current_vit_numlancto;

              const addOrientedProduct = (orientedProduct) => {
                const level = getNivelInItem(orientedProduct.product);

                if (level) {
                  setStepsAutomatic((items) => {
                    const hasIndexItem = items.find(
                      (currentItemInStep) =>
                        currentItemInStep.index === indexCurrentOrientedSaleStep
                    );

                    if (hasIndexItem) {
                      return items;
                    }

                    const orientedPayload = {
                      index: indexCurrentOrientedSaleStep,
                      title:
                        currentOrientedSaleStep.orientedSaleGroup.description,
                      products: level.map((item) => ({
                        ...item,
                        qtd: QuantityCombo,
                        last_vit_numlancto,
                        current_vit_numlanctoprincipal,
                        originalValue: orientedProduct.product.value,
                        mixValue: orientedProduct.product.value,
                        price: item.value,
                        value: item.value,
                      })),
                    };

                    let newItems = items;

                    if (newItems[indexCurrentOrientedSaleStep]) {
                      newItems[indexCurrentOrientedSaleStep] = orientedPayload;
                    } else {
                      newItems.push(orientedPayload);
                    }

                    return [...newItems];
                  });
                } else {
                  const payloadAddToCart = {
                    id: orientedProduct.product._id,
                    name: `${orientedProduct.product.description}`,
                    customizations: [],
                    price: 0,
                    value: orientedProduct.product.value,
                    qtd: QuantityCombo,
                    image: orientedProduct.product.image,
                    vit_numlancto: current_vit_numlancto,
                    vit_numlanctoprincipal: current_vit_numlanctoprincipal,
                    originalValue: orientedProduct.product.value,
                    mixValue: orientedProduct.product.value,
                    code: orientedProduct.product.productCode,
                    classification: orientedProduct.product.classification,
                    quantityDouble: QuantityCombo,
                    promotion: promotion ?? {},
                    currencyPoints: false,
                    valuePoints: 0,
                    isProductComposition: false,
                    nuuidOne: orientedProduct.product.nuuidOne
                  };

                  productsChildren.push(payloadAddToCart);
                }
              };

              if (hasProductGroupRef) {
                const productOriented = products.find(
                  (currentItem) =>
                    currentItem.product.productCode ===
                    currentOrientedSaleStep.referenceProductCodeForGroup
                );

                addOrientedProduct(productOriented);
              } else {
                const productMinorPrice = products.reduce(
                  (prevPrice, currentPrice) =>
                    prevPrice.product.value > currentPrice.product.value ||
                    prevPrice.product.value === null
                      ? currentPrice
                      : prevPrice,
                  { product: { value: null } }
                );

                addOrientedProduct(productMinorPrice);
              }
            }
          );

          if (productsChildren.length === 0) {
            dispatch(addToCart(productPayload));
          }
        } catch (error) {}
      })
      .catch(() => {
        clearPayloadInMemory();
      });

    if (currencyPoints) {
      atualizarSaldoPontosFidelidade();
    }
  };

  const subtotal = count * (+value * quantity);
  const subtotalPoints = count * (+valuePoints * quantity);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAccelerators(), [accelerator]);

  function getAccelerators() {
    const accelerators = {
      enabled: accelerator,
      data: [
        { key: 1, title: "+5", value: 5 },
        { key: 2, title: "+10", value: 10 },
        { key: 3, title: "+20", value: 20 },
      ],
    };

    setAccelerators(accelerators);
  }

  const atualizarSaldoPontosFidelidade = () => {
    let getPoints = localStorage.getItem("saldoAtual");
    let atualizaPoints = getPoints - count * (+valuePoints * quantity);
    localStorage.setItem("saldoAtual", atualizaPoints);
  };

  const modalStyleBlackout = {
    width: "100%",
    height: "100vh",
    marginTop: "-32%",
    position: "absolute",
    zIndex: "20",
    background: "rgba(0, 0, 0, 0.82)",
  };

  const closeModalPoints = () => setModalBlock(false);

  const isBobsLayout = isLayout("BOBS");

  const totalText = getTranslateName(t, "total") || "Total";

  const itOnlyCostsText = getTranslateName(t, "it_only_costs") || "Por apenas";

  const coinPointsText = getTranslateName(t, "total") || "PONTOS";

  const suggestionTitleText =
    getTranslateName(t, "suggestion.title") || "SUGESTÃO";

  const suggestionDescriptionText =
    getTranslateName(t, "suggestion.description") ||
    "Preço especial para completar seu pedido!";

  const buttonBackText = getTranslateName(t, "buttons.back") || "Voltar";

  const buttonYesText = getTranslateName(t, "buttons.yes") || "SIM";

  const buttonNoText = getTranslateName(t, "buttons.no") || "NÃO";

  const buttonNoThanksText =
    getTranslateName(t, "buttons.noThanks") || "Não, obrigado!";

  const buttonToAddText = getTranslateName(t, "buttons.toAdd") || "Adicionar";

  const buttonPersonalizeText =
    getTranslateName(t, "personalize") || "Personalizar";

  const noPointsTitleText =
    getTranslateName(t, "noPoints.buttons.confirm") || "ENTENDI";

  const noPointsDescriptionText =
    getTranslateName(t, "noPoints.description") ||
    "O seu saldo de pontos fidelidade é insuficiente para adicionar esse produto.";

  const noPointsButtonConfirmText =
    getTranslateName(t, "noPoints.buttons.confirm") || "ENTENDI";

  useEffect(() => {
    if (isBobsLayout && isShowCustomCombo && orientedSale) {
      try {
        setNewDescription(() => {
          let currentNewDescription = "";

          orientedSale.orientedSaleSteps.map((currentOrientedSaleStep) => {
            const hasProductGroupRef =
              currentOrientedSaleStep.referenceProductCodeForGroup !== null;
            const products =
              currentOrientedSaleStep.orientedSaleGroup
                .orientedSaleGroupProduct;

            if (hasProductGroupRef) {
              const productOriented = products.find(
                (currentItem) =>
                  currentItem.product.productCode ===
                  currentOrientedSaleStep.referenceProductCodeForGroup
              );

              if (currentNewDescription.length > 0) {
                currentNewDescription = `${currentNewDescription.trim()} + `;
              }

              currentNewDescription += `${productOriented.product.description} `;
            }
          });

          if (currentNewDescription.trim().length > 0) {
            return `${title} + ${currentNewDescription.trim()}`;
          }

          return detailedDescription;
        });
      } catch (e) {
        setNewDescription(detailedDescription);
      }
    } else {
      setNewDescription(detailedDescription);
    }
  }, [orientedSale, detailedDescription]);

  if (stepsAutomatic.length > 0 && stepsAutomatic[0]?.products) {
    return (
      <NivelProductWrapper>
        <div className="modal">
          <ContainerTitle className="step-title containerTitleTablet">
            <Title className="big-text">{stepsAutomatic[0].title}</Title>
          </ContainerTitle>
          <ContentFigure>
            {[...(currentNivelProduct[0] ?? stepsAutomatic[0]?.products)].map(
              (currentProduct) => (
                <FigureItem
                  className="figure-item"
                  onClick={() => {
                    addProductInCart(currentProduct, productPayload);
                  }}
                >
                  <ImageItem
                    src={currentProduct.featuredImage}
                    alt="&nbsp;"
                    className={!currentProduct.featuredImage ? "no-image" : ""}
                  />
                  <figcaption className="name-combo-card-item">
                    {currentProduct.description}
                  </figcaption>
                  {!!currentProduct.lowerValue && (
                    <ItemPrice>{currency(currentProduct.lowerValue)}</ItemPrice>
                  )}
                </FigureItem>
              )
            )}
          </ContentFigure>
          <div className="button-container">
            <Button
              className="rounded button-secondaryBobs"
              text={buttonBackText}
              buttonUIType="outline-backstep"
              buttonUI={"normal"}
              width="100%"
              handleClick={handleBackButton}
            />
          </div>
        </div>
      </NivelProductWrapper>
    );
  }

  return (
    <>
      {modalBlock && (
        <div style={modalStyleBlackout} onClick={closeModalPoints}>
          <Modal
            mClassName="modal-points"
            mTitle={noPointsTitleText}
            mSubtitle={noPointsDescriptionText}
            mTextBtnPrimary={noPointsButtonConfirmText}
            mBtnPrimarClassName="primary"
            mTextBtnSecondary={false}
            mBtnSecondaryClassName={false}
          />
        </div>
      )}

      <ModalWrapper>
        <div className="modal">
          {suggestion && (
            <hgroup className="suggestion">
              <h1>{suggestionTitleText}</h1>
              <h2>{isBobsLayout ? "" : suggestionDescriptionText}</h2>
            </hgroup>
          )}

          <h1 className="title"> {title} </h1>
          {!suggestion && !isShowCustomCombo && (
            <h2 className="subtitle">
              {!currencyPoints
                ? currency(value * quantity)
                : `${valuePoints} ${coinPointsText}`}
            </h2>
          )}

          {image === null ? (
            <div className="no-image" />
          ) : (
            <img src={image} alt={image} />
          )}
          {newDescription && <p className="description">{newDescription}</p>}
          {!removeCount && (
            <>
              <div className="counter">
                <button
                  className="button-count"
                  onClick={() => setCount(count - 1)}
                  disabled={count <= 1}
                >
                  &#8722;
                </button>
                <span className="quantity"> {count} </span>
                <button
                  className="button-count"
                  onClick={() => setCount(count + 1)}
                >
                  &#43;
                </button>

                {accelerators.enabled && (
                  <div className="accelerators">
                    {accelerators.data.map((accelerator) => (
                      <button
                        key={accelerator.key}
                        className="button-accelerators"
                        onClick={() => {
                          setCount(count + accelerator.value);
                        }}
                      >
                        {accelerator.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              {!currencyPoints ? (
                <p className="subtotal">
                  {quantity > 1 && <span>{count * quantity} unidades -</span>}
                  {suggestion && isBobsLayout ? itOnlyCostsText : totalText}{": "}
                  <strong>{currency(subtotal)}</strong>
                </p>
              ) : (
                <p className="subtotal">
                  {`${totalText}: `}
                  <strong>{`${subtotalPoints} ${coinPointsText}`}</strong>
                </p>
              )}
            </>
          )}

          {isBobsLayout && hasOrientedSale && isShowCustomCombo && (
            <Button
              className="button-addPrimaryBobs button-primaryBobs"
              text={buttonToAddText}
              buttonUIType="primary"
              buttonUI="highlight"
              handleClick={addAutomatic}
            />
          )}

          <div className="buttons-container">
            <Button
              className="button-secondaryAAone rounded button-secondaryBobs"
              text={
                suggestion
                  ? isBobsLayout
                    ? buttonNoText
                    : buttonNoThanksText
                  : buttonBackText
              }
              buttonUIType="outline-primary"
              buttonUI="highlight"
              handleClick={() => modalCloseCombo()}
            />

            <Button
              className={
                isBobsLayout && hasOrientedSale && isShowCustomCombo
                  ? "rounded buttonAddCar button-primaryBobsComp"
                  : "rounded buttonAddCar button-primaryBobs"
              }
              text={
                suggestion && isBobsLayout
                  ? buttonYesText
                  : isBobsLayout && hasOrientedSale && isShowCustomCombo
                  ? buttonPersonalizeText
                  : buttonToAddText
              }
              buttonUIType="primary"
              buttonUI="highlight"
              handleClick={add}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default ModalProduct;
