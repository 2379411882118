import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalWrapper from "./style";
import Button from "../button";

import { checkout } from "../../../actions/cart";

import {
  cancelOperation,
} from "../../../../services/verifyRedeemValueService";
import { useLayoutAa } from "../../../contexts/layout";
import { useViewDispatch } from "../../../../context";

const Modal = ({
  mTitle,
  mSubtitle,
  mTextBtnPrimary,
  mTextBtnSecondary,
  mBtnPrimarClassName,
  mBtnSecondaryClassName,
  mClassName,
  textAlternate,
  mHandleClickBtnPrimary,
}) => {
  const { t } = useTranslation();
  const { getTranslateName } = useLayoutAa();
  const viewDispatch = useViewDispatch();
  const dispatch = useDispatch();

  const inactivityDescriptionText = getTranslateName(t, "inactivity.description") || "O pedido atual será cancelado.";

  const handleCheckout = () => {
    cancelOperation(JSON.parse(localStorage.getItem("NsuForConsult")));
    dispatch(checkout());
    viewDispatch("step.banner");
  };

  const checkTimer = (t) => {
    if (t.textAlternate === 1) {
      viewDispatch("step.banner");
    }
  };

  useEffect(() => {
    checkTimer({ textAlternate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAlternate]);

  return (
    <ModalWrapper>
      <div className={`modal ${mClassName}`}>
        <h1 className="title">{mTitle}</h1>

        <h2 className="subtitle">
          {mSubtitle || inactivityDescriptionText}
        </h2>

        {mTextBtnSecondary !== false && (
          <Button
            text={mTextBtnSecondary}
            buttonUIType={mBtnSecondaryClassName}
            buttonUI="highlight"
            handleClick={() => handleCheckout()}
          />
        )}

        <Button
          text={mTextBtnPrimary}
          buttonUIType={mBtnPrimarClassName}
          buttonUI="highlight"
          handleClick={mHandleClickBtnPrimary ? mHandleClickBtnPrimary : () => {}}
        />
      </div>
    </ModalWrapper>
  );
};

export default React.memo(Modal);
