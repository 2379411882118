import React from "react";
import Button from "../Button";


export const TefActionsButtons = props => {
  return (
    <>
      <Button onClick={() => props.onClickPrinter(props.data)} style={{ marginRight: "15px" }}>
        <i className="material-icons">print</i>
      </Button>
      {props.data.canceled !== "Cancelado" && (
        <Button onClick={() => props.onClickCancel(props.data)}>
          <i className="material-icons" style={{ color: `#FF6347` }} >cancel</i>
        </Button>
      )}
    </>
  )
};
