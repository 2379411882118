import styled from "styled-components";

export const DropDown = styled.div`
  width: 300px;
  height: 0;
  position: absolute;
  top: 40px;
  right: 0;
  /* display: none; */
  background: #fff;
  overflow: hidden;

  &:hover {
    display: block;
    height: auto;
  }

  .header {
    display: block;
    margin: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .column {
      width: 100%;

      .title {
        background: #382049;
        color #fff;
        font-weight: bold;
      }
      .row {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        min-height: 25px;
      }
    }
  }
`;

export const DropDownContent = styled.div``;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    display: block;
    width: 200%;
    height: 52px;
    position: absolute;
  }

  svg {
    font-size: 20px;
  }

  &:hover {
    ${DropDown} {
      height: auto;
      transition: 1s;
    }
  }

  position: relative;
`;
