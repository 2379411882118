import React, { Component, createRef } from "react";
import CurrencyInput from "react-currency-input";
import PropTypes from "prop-types";
import CashCheckTotalMoney from "../cash-check-total-money";
import Card from "../Card";
import Button from "../Button";

import { getSetting } from "../../helpers/userSettings";
import KeyboardPlugin from "../keyboard-plugin";
import CashCheck from "./cash-check";

import {
  InputGroup,
  CashCloseGrid,
  CashCloseFormActions,
  GridCellEmpty,
  CashCheckWrapper,
  DirectionalArrowsContainer,
  DirectionalArrow,
  CashGridWrapper,
} from "./style";
import { isSmartPos } from "../../../aaone/configuration";

const ROW_HEIGHT = 27;
class CashClose extends Component {
  static propTypes = {
    disableConfirm: PropTypes.bool,
    title: PropTypes.string,
    posId: PropTypes.string,
    handleClose: PropTypes.func,
    paymentTypeList: PropTypes.arrayOf(PropTypes.any),
    handleClick: PropTypes.func,
  };

  static defaultProps = {
    disableConfirm: null,
    title: null,
    posId: null,
    handleClose: null,
    paymentTypeList: null,
    handleClick: null,
  };

  constructor(props) {
    super(props);

    this.gridRef = createRef();
    this.handleLastPaymentsGridElement =
      this.handleLastPaymentsGridElement.bind(this);

    this.state = {
      manualCountingTotal: 0,
      explicacaoValue: "",
      validateTotalValue: false,
      keyboardVisible: false,
      layoutName: "default",
      inputName: "",
      useTouchKeyboard: getSetting("usarKeyboardTouch"),
      paymentTypesLoad: null,
    };
  }

  handlePaymentsList(payments) {
    if (payments && payments.length) {
      const paymentsList = payments.map((payment) => [
        ...payment.brands.map((brand) => ({
          id: brand.id,
          paymentName: brand.name,
          paymentTypeId: brand.paymentTypeId,
          paymentTypeName: payment.paymentType.name,
          systemCounting: brand.systemAmount,
          manualCounting: brand.amount,
          referenceId: brand.referenceId,
          isReadOnly: brand.isReadOnly,
        })),
      ]);

      return [].concat.apply([], paymentsList);
    }

    return [];
  }

  clearForm = () => {
    this.formEl.reset();

    this.setState({
      manualCountingTotal: 0,
      explicacaoValue: "",
    });

    this.keyboard.clearInput("input_description");

    window.dispatchEvent(new CustomEvent("cellEditorInputValueClear"));

    if (this.gridRef.current) {
      this.gridRef.current.api.setRowData(
        this.handlePaymentsList(this.state.paymentTypesLoad)
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.paymentTypesLoad) !==
      JSON.stringify(this.props.paymentTypesLoad)
    ) {
      setTimeout(() => {
        this.setState({
          manualCountingTotal: this.handleManualCountTotal(),
        });
      }, 500);
    }

    if (this.state.inputName === "input_description") {
      this.changeScrollToBottom();
    }

    if (
      this.state.paymentTypesLoad === null &&
      this.props.paymentTypesLoad?.length > 0
    )
      this.setState({
        ...this.state,
        paymentTypesLoad: [...this.props.paymentTypesLoad],
      });
  }

  handleLastPaymentsGridElement() {
    const inputDescription = document.getElementsByName("input_description")[0];

    if (inputDescription) {
      setTimeout(() => {
        inputDescription.focus();
      }, 150);
    }
  }

  componentDidMount() {
    this.props.setVisibleBottomKeyboardContainer(false);
    setTimeout(() => this.props.setVisibleSideKeyboardContainer(true), 10);

    window.addEventListener(
      "lastPaymentsGridElement",
      this.handleLastPaymentsGridElement
    );
  }

  componentWillUnmount() {
    // if (this.dialogSub) {
    //   MessageBusService.GetInstance().Unsubscribe(this.dialogSub);
    // }

    if (this.keyboard) {
      this.keyboard.destroy();
    }

    if (this.keyboardNumber) {
      this.keyboardNumber.destroy();
    }

    this.props.setVisibleBottomKeyboardContainer(false);
    this.props.setVisibleSideKeyboardContainer(false);

    window.removeEventListener(
      "lastPaymentsGridElement",
      this.handleLastPaymentsGridElement
    );
  }

  // handleChange (e) {
  // 	console.log('handle change called');

  // 	this.setState({ manualAmountSummarized: e});
  // }

  getPropsBrand = (brand) => {
    return {
      id: brand.id,
      name: brand.paymentName,
      referenceId: brand.referenceId,
      systemAmount: brand.systemCounting,
      amount: brand.manualCounting,
      paymentTypeId: brand.paymentTypeId,
    };
  };

  /**
   * Realiza o envio dos dados para o backend
   */
  submitHandler = (event) => {
    event.preventDefault();
    this.gridRef.current.api.stopEditing();
    const { isBlindCashDeskClosing, openingAmount, userName } = this.props;

    this.toggleCard(false);

    const closingValues = [];

    let userValue = 0;
    let systemValue = 0;

    const payments = [];

    this.gridRef.current.api.forEachNode((node) => {
      if (!node.group && node.data) payments.push(node.data);
    });

    if (this.state.paymentTypesLoad) {
      this.state.paymentTypesLoad.forEach((paymentTypeDetails) => {
        const brands = payments
          .filter(
            (paymentItem) =>
              paymentItem.referenceId ===
              paymentTypeDetails.paymentType.referenceId
          )
          .map((brand) => this.getPropsBrand(brand));

        const paymentData = {
          paymentType: {
            id: paymentTypeDetails.paymentType.id,
            name: paymentTypeDetails.paymentType.name,
            referenceId: paymentTypeDetails.paymentType.referenceId,
          },
          brands,
          systemAmount: paymentTypeDetails.systemAmount,
          amount: paymentTypeDetails.amount,
        };

        if (
          !paymentTypeDetails.brands ||
          paymentTypeDetails.brands.length === 0
        ) {
          Object.assign(paymentData, {
            systemAmount: paymentTypeDetails.systemAmount,
            amount: paymentTypeDetails.amount,
          });

          userValue += paymentTypeDetails.amount;
          systemValue += paymentTypeDetails.systemAmount;
        } else {
          const brandsValue = paymentData.brands.reduce(
            (accBrandAmount, currentBrand) =>
              (accBrandAmount += currentBrand.amount || 0),
            0
          );
          const systemBrandsValue = paymentData.brands.reduce(
            (accBrandSystemAmount, currentBrand) =>
              (accBrandSystemAmount += currentBrand.systemAmount || 0),
            0
          );

          userValue += brandsValue;
          systemValue += systemBrandsValue;
        }

        closingValues.push(paymentData);
      });
    }

    const { value: description } = event.target.input_description;

    const formData = {
      closingValues,
      amount: openingAmount,
      userName,
      description,
    };

    if (
      !isBlindCashDeskClosing &&
      userValue !== systemValue &&
      this.state.explicacaoValue === ""
    ) {
      this.toggleCard(true);
      this.setState({ ...this.state, validateTotalValue: true });
      const element = document.querySelector("#cardList .card-body");
      setTimeout(() => {
        element.scrollTop = element?.scrollHeight;
      }, 100);

      return;
    }

    this.props.handleClick(formData);
  };

  onExplicacaoChange = (event) => {
    this.setState({
      explicacaoValue: event.target.value,
    });
  };

  changeScrollToBottom = () => {
    var mainEl = document.getElementById("main-component-reference-id");
    if (mainEl != null) {
      mainEl.scrollTop = mainEl.scrollHeight;
    }
  };

  keyboardOnChangeAll = (maskedValue, value) => {
    const { inputName } = this.state;

    if (inputName === "input_description") {
      this.setState({
        ...this.state,
        explicacaoValue: maskedValue,
      });
    }

    const cellEditorEvent = new CustomEvent("keyboardValueChanged", {
      detail: { inputName: inputName, inputValue: value },
    });
    window.dispatchEvent(cellEditorEvent);
  };

  /**
   * @param {object} elementInputFocus
   * @returns object
   * @todo return object for focus
   */
  findNextTabStop = (elementInputFocus) => {
    const allInputsForSelection = document.getElementsByClassName(
      "class-input-auto-focus"
    );

    let inputToFocus = elementInputFocus;

    let foundInputSelectedInArray = false;

    Object.keys(allInputsForSelection).forEach((index) => {
      let input = allInputsForSelection[index];
      let fieldset = input.closest(`fieldset`);

      if (
        foundInputSelectedInArray === true &&
        inputToFocus === elementInputFocus &&
        input.disabled === false
      ) {
        if (fieldset !== null) {
          if (fieldset.classList.contains(`hidden`) === false) {
            inputToFocus = input;
            return false;
          }
        } else {
          inputToFocus = input;
          return false;
        }
      }

      if (input === elementInputFocus) foundInputSelectedInArray = true;
    });

    return inputToFocus;
  };

  keyboardOnKeyPress = (button) => {
    if (button === "{enter}" && this.gridRef?.current) {
      const rowNodeEditigInstace =
        this.gridRef.current.api.getCellEditorInstances()[0];

      if (
        rowNodeEditigInstace &&
        rowNodeEditigInstace.getFrameworkComponentInstance &&
        rowNodeEditigInstace.getFrameworkComponentInstance().nextRowNodeToEdit
      ) {
        rowNodeEditigInstace
          .getFrameworkComponentInstance()
          .nextRowNodeToEdit();
      }
    }

    if (button === "{close}") {
      this.setState({
        keyboardVisible: false,
      });
      this.props.setVisibleBottomKeyboardContainer(false);
    }

    if (button === "{shift}" || button === "{lock}") {
      let activeLayoutName = this.state.layoutName;

      this.setState({
        layoutName: activeLayoutName === "default" ? "shift" : "default",
      });
    }

    if (button === "{clear}") {
      if (this.state.inputName === "input_description") {
        this.setState({
          ...this.state,
          explicacaoValue: "",
        });
      }

      const cellEditorEvent = new CustomEvent("keyboardValueChanged", {
        detail: { inputName: this.state.inputName, inputValue: "" },
      });
      window.dispatchEvent(cellEditorEvent);
    }
  };

  closeKeyboard() {
    this.setState({ keyboardVisible: false });
  }

  keyboardSetActiveInput = (inputName) => {
    switch (inputName) {
      case "input_description":
        this.setState({
          layoutName: "default",
          keyboardVisible: true,
          disabledKeyboard: true,
        });
        this.props.setVisibleBottomKeyboardContainer(true);
        break;
      default:
        this.setState({
          keyboardVisible: false,
          disabledKeyboard: false,
        });
        this.props.setVisibleBottomKeyboardContainer(false);
        break;
    }

    this.setState({
      inputName: inputName,
    });
  };

  toggleCard = (expandCardList) => {
    this.props.handleHidden(expandCardList);
  };

  toggleBrand = (paymentId) => {
    const paymentTypesLoad = this.state.paymentTypesLoad;

    paymentTypesLoad.forEach((item) => {
      if (item.paymentType.id === paymentId)
        item.expandBrand = !item.expandBrand;
    });

    this.setState({ ...this.state, paymentTypesLoad });
  };

  handleManualCountTotal = () => {
    const payments = [];

    this.gridRef.current.api.forEachNode((node) => {
      if (!node.group && node.data) payments.push(node.data);
    });

    return payments.reduce(
      (accBrandAmount, currentBrand) =>
        (accBrandAmount += currentBrand.manualCounting || 0),
      0
    );
  };

  scrollGrid = (direction) => {
    if (this.gridRef.current) {
      const gridApi = this.gridRef.current.api;

      if (gridApi?.getDisplayedRowCount() === 0) return;

      let nodeIndex = 0;
      const lastRowIndex = gridApi?.getDisplayedRowCount() - 1;

      const displayedRows =
        Math.floor(
          gridApi?.["gridBodyCon"].eBodyViewport.clientHeight / ROW_HEIGHT
        ) - 1;

      if (direction === "top") {
        nodeIndex = gridApi?.getFirstDisplayedRow() - displayedRows;
        if (nodeIndex < 0) nodeIndex = 0;
      }

      if (direction === "bottom") {
        nodeIndex = gridApi?.getLastDisplayedRow() + displayedRows;
        if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
      }

      gridApi.ensureIndexVisible(nodeIndex);
    }
  };

  render() {
    const {
      title,
      posId,
      handleClose,
      paymentTypesLoad,
      closingResponsableName,
      closingResponsableId,
      operatorId,
      openingAmount,
      controlNumber,
      userName,
      isBlindCashDeskClosing,
      cashDateOpening,
    } = this.props;

    return (
      <CashCheckWrapper useTouchKeyboard={this.state.useTouchKeyboard}>
        <div>
          <CashCheckTotalMoney
            initSystemCount={paymentTypesLoad?.reduce(
              (acc, current) => (acc += current.systemAmount),
              0
            )}
            initManualCount={this.state.manualCountingTotal}
            isBlindCashDeskClosing={isBlindCashDeskClosing}
            useTouchKeyboard={this.state.useTouchKeyboard}
          />

          <KeyboardPlugin
            visible={true}
            customClassName="cashclose-keyboard"
            baseClass={"id-keyboard-cash-close-numeric"}
            titleDescription="Valor"
            disabledKeyboard={this.state.disabledKeyboard}
            keyboardRef={(r) => {
              this.keyboardNumber = r;
            }}
            inputName={this.state.inputName}
            layoutName={"numeric"}
            numericType={"currency"}
            onKeyPress={(button) => this.keyboardOnKeyPress(button)}
            onChangeAll={(maskedValue, value) => {
              this.keyboardOnChangeAll(maskedValue, value);
            }}
          />
        </div>
        <form
          onSubmit={(e) => this.submitHandler(e)}
          ref={(form) => {
            this.formEl = form;
          }}
        >
          <Card
            id="card"
            title={`${title}`}
            className={
              !this.props.expandCard ? "expand-card" : "collapsed-card"
            }
            closeEnable
            handleClose={handleClose}
            handleHidden={() => this.toggleCard(false)}
          >
            <CashCloseGrid>
              <InputGroup>
                <label>PDV</label>
                <input value={`PDV ${posId}`} readOnly disabled />
              </InputGroup>

              <InputGroup>
                <label>Número Abertura</label>
                <input value={controlNumber} readOnly disabled />
              </InputGroup>

              <InputGroup>
                <label>Responsável</label>
                <input
                  value={closingResponsableName}
                  readOnly
                  disabled
                  custom-attribute={closingResponsableId}
                />
              </InputGroup>

              <InputGroup>
                <label>Operador</label>
                <input
                  value={userName}
                  name="closingUserId"
                  readOnly
                  disabled
                  custom-attribute={operatorId}
                />
              </InputGroup>

              <InputGroup>
                <label>Fundo inicial de troco</label>
                <CurrencyInput
                  value={openingAmount}
                  name="openingAmount"
                  decimalSeparator=","
                  thousandSeparator="."
                  readOnly
                  disabled
                />
              </InputGroup>

              <InputGroup>
                <label>Data movimento</label>
                <input value={cashDateOpening} readOnly disabled />
              </InputGroup>

              <GridCellEmpty />
            </CashCloseGrid>
          </Card>
          <Card
            id="cardList"
            title="CONFERÊNCIA DE CAIXA"
            className={
              !this.props.expandCardList ? "expand-card" : "collapsed-card"
            }
            handleHidden={() => this.toggleCard(true)}
            controlScroll={false}
          >
            <CashGridWrapper>
              <CashCheck
                gridRef={this.gridRef}
                rowHeight={ROW_HEIGHT}
                payments={this.handlePaymentsList(paymentTypesLoad)}
                isBlindCashDeskClosing={isBlindCashDeskClosing}
                handleEditingStartedCellvalue={(value, input) => {
                  // keyboard-plugin contains event listener
                  const cellEditorEvent = new CustomEvent(
                    "cellEditorInputValueChanged",
                    { detail: { inputName: input, inputValue: value } }
                  );
                  window.dispatchEvent(cellEditorEvent);

                  this.keyboardSetActiveInput(input);
                }}
                handleEditingStoppedCellvalue={(value, input) => {
                  // keyboard-plugin contains event listener
                  // const cellEditorEvent = new CustomEvent('cellEditorInputValueChanged', { detail: { inputName: input, inputValue: value } });
                  // window.dispatchEvent(cellEditorEvent);

                  this.keyboardSetActiveInput("");

                  this.setState({
                    manualCountingTotal: this.handleManualCountTotal(),
                  });
                }}
              />
              {!isSmartPos && (
                <DirectionalArrowsContainer>
                  <DirectionalArrow
                    className="top"
                    onClick={() => this.scrollGrid("top")}
                  >
                    <span className="material-icons">keyboard_arrow_up</span>
                  </DirectionalArrow>

                  <DirectionalArrow
                    className="bottom"
                    onClick={() => this.scrollGrid("bottom")}
                  >
                    <span className="material-icons">keyboard_arrow_down</span>
                  </DirectionalArrow>
                </DirectionalArrowsContainer>
              )}
            </CashGridWrapper>
            <CashCloseGrid>
              <InputGroup>
                <label>Explicação</label>
                <textarea
                  name="input_description"
                  className={"class-input-auto-focus"}
                  cols="60"
                  rows="20"
                  onChange={(e) => this.onExplicacaoChange(e)}
                  data-y-pos="top"
                  value={this.state.explicacaoValue}
                  data-use-touch-keyboard
                />
                {this.state.validateTotalValue && (
                  <span>
                    Obrigatório uma explicação para a diferença de caixa
                  </span>
                )}
              </InputGroup>

              <CashCloseFormActions>
                <Button
                  id="btnClearFields"
                  className="btn-large btn-purple btn-custom"
                  type="button"
                  handleClick={this.clearForm}
                  disabled={this.props.disableConfirm}
                >
                  LIMPAR
                </Button>

                <Button
                  id="btnDone"
                  className="btn-large btn-purple btn-custom"
                  type="submit"
                  disabled={this.props.disableConfirm}
                >
                  CONFIRMAR
                </Button>
              </CashCloseFormActions>
            </CashCloseGrid>
          </Card>
        </form>
      </CashCheckWrapper>
    );
  }
}

export default CashClose;
