import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Thead from './Thead';
import Tbody from './Tbody';
import Tfoot from './Tfoot';
import { TableWrapper } from './style';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order === 'desc' ? props.order : 'asc',
    };
  }

  render() {
    const {
      items, columns, lines, footer, lineClick, className, typeList
    } = this.props;

    if (columns.length === 0) {
      return false;
    }

    return (
      <TableWrapper>
        <table className={`table ${className}`}>
          <Thead
            columns={columns}
            items={items}
            order={this.state.order}
            handleClick={() => {}}
          />

          {items
            && (
              <Tbody
                items={items}
                columns={columns}
                lines={lines}
                styleLink={!!lineClick}
                handleClick={(item, colRef) => () => (lineClick ? lineClick({ item, colRef }) : null)}
                typeList={typeList}
                {...this.props}
              />
            )
          }

          {footer
            && <Tfoot items={items} {...footer} />
          }
        </table>
      </TableWrapper>
    );
  }
}

Table.propTypes = {
  items: PropTypes.array,
  lines: PropTypes.object,
  columns: PropTypes.array.isRequired,
  footer: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    component: PropTypes.any,
  }),
  order: PropTypes.string,
  lineClick: PropTypes.func,
  headerClick: PropTypes.func,
  children: PropTypes.any,
};

export default Table;
