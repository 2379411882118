import styled from "styled-components";
import { isSmartPos } from "../../../aaone/configuration";

export const ArmouredCarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;

  .form-pdv {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .form-smartpos {
    display: flex;
    flex-direction: column;
  }

  #card,
  #cardList {
    position: relative;
    min-height: 55px;
  }

  .amount-list {
    position: absolute;
  }

  .smart-pos {
    left: 20px;
    top: 65px !important;
  }

  .pdv-windows {
    top: 24px;
  }
`;

export const InputGroup = styled.div`
  padding: 10px 40px 0 10px;
  margin: 11px 0 3px 0;

  @media only screen and (${(props) => props.theme.minimumResolution.media}) {
    padding: 10px 10px 0;
    margin: 1px;
  }

  span {
    color: #fe2e2e;
    height: 25px;
  }

  small {
    color: #888484;
  }

  label {
    display: inline-block;
    margin-bottom: 10px;
  }
  input {
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    border: 1px solid rgb(200, 203, 208);
    border-radius: 4px;
  }
`;

export const AgGrid = styled.div`
  width: 100%;
  height: 475px;
  display: flex;

  .ag-theme-balham {
    .ag-header {
      background: #e7eaf1;
    }
    .ag-floating-bottom-container .ag-row {
      border: none !important;
      background-color: #ede7f5;
    }
    .ag-cell-label-container {
      font-weight: 700;
      font-size: 12px;
    }
    margin-top: 10px;
    height: 365px;
    min-height: 365px;

    padding-right: 15px;
    width: 100%;
  }
`;

export const AgGridActions = styled.section`
  display: flex;

  .btn-card-list {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    max-width: inherit;
    font-size: 0.9rem !important;
    outline: none;
    border: none;
    padding: 7px 15px !important;
    background: #411e5a;
    color: #fff;
    margin-right: 3px !important;
    border-radius: 4px !important;

    @media only screen and (${(props) => props.theme.minimumResolution.media}) {
      flex-direction: column;
      font-size: smaller;
    }

    &[disabled] {
      opacity: 0.35;
    }
  }
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  flex-direction: column;
  //position: absolute;
  right: 0;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  color: #fff;
  z-index: 3;
  outline: none;
  cursor: pointer;
`;
