import React from "react";
import { isActiveBannerHeaderAndBottom } from "../../../configuration";
import { useLayoutAa } from "../../../contexts/layout";
import { Button } from "../modules";
import { ScrollContainerWrapper, ButtonWrapper } from "./styles";

const ScrollContainer = ({
  children,
  pinnedViewAtBottom,
  scrollButtonClickDown,
  scrollButtonClickUp,
  forWaitingModal,
}) => {
  const { isLayout } = useLayoutAa();

  const styleAA = localStorage.getItem("styleAA");
  const styles = ["DRIVE", "DRIVEIN"];
  const hasStyle = styles.includes(styleAA);

  const isRagazzoLayout = isLayout(["RAGAZZOEXPRESS", "RAGAZZO"]);
  const isNotHabibsLayout = isLayout("HABIBS");

  return (
    <ScrollContainerWrapper
      $pinnedViewAtBottom={pinnedViewAtBottom}
      $driverLayout={
        !localStorage.getItem("paymentTransition") &&
        hasStyle &&
        isActiveBannerHeaderAndBottom
      }
      $reshopUser={!!localStorage.getItem("cpf")}
    >
      {isActiveBannerHeaderAndBottom && (
        <div
          className={
            isRagazzoLayout
              ? "bannerTelaReduzida"
              : isNotHabibsLayout
              ? "bannerTelaReduzidaDefault"
              : "bannerTelaReduzida"
          }
        >
          {localStorage.getItem("styleAA") && !forWaitingModal && (
            <ButtonWrapper
              $hidden={!pinnedViewAtBottom}
              $position="top"
              onClick={() => scrollButtonClickUp()}
            >
              <Button
                className="wide"
                text="SUBIR A TELA ⮝"
                buttonUIType="outline-backstep"
                buttonUI={"normal"}
              />
            </ButtonWrapper>
          )}
        </div>
      )}
      {children}
      {isActiveBannerHeaderAndBottom && (
        <div
          className={
            isRagazzoLayout
              ? "bannerTelaReduzida"
              : isNotHabibsLayout
              ? "bannerTelaReduzidaDefault"
              : "bannerTelaReduzida"
          }
        >
          {localStorage.getItem("styleAA") && !forWaitingModal && (
            <ButtonWrapper
              $hidden={pinnedViewAtBottom}
              $position="bottom"
              onClick={() => scrollButtonClickDown()}
            >
              <Button
                className="wide"
                text="DESCER A TELA ⮟"
                buttonUIType="outline-backstep"
                buttonUI={"normal"}
              />
            </ButtonWrapper>
          )}
        </div>
      )}
    </ScrollContainerWrapper>
  );
};

export default React.memo(ScrollContainer);
