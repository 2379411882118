import styled from 'styled-components';

const BoxWrapper = styled.section`

  figure {
    font-family: Roboto, sans-serif;
    font-size: 0.625rem;
    font-weight: 400;
    text-transform: uppercase;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 100%;
    margin: 0;
    display:flex;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    position: relative;
    width: 160px;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 60px;
  }

  img {
    pointer-events: none;
    touch-action: none;
    width: 100%;
    max-height: 100%;
    // margin-top: auto;
    object-fit: cover;
    //height: 100%;
    // max-height: 70%;
  }  

  @media screen and (max-width:800px) {
    figure {
      height: auto;

      img { display: none; }

      figcaption {
        line-height: 50px;
        height: 50px;
      }
    }
  }  
`;


export default BoxWrapper;
