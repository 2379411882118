export const getQuantityCombo = () => localStorage.getItem("QuantityCombo")
? Number(localStorage.getItem("QuantityCombo"))
: 1

export const selectedTotalCombo = () => {
  const value = localStorage.getItem("selectedTotalCombo");

  if(value){
    return Number(value);
  }

  return null;
}
