import React from "react";
import { SwitchContainer } from "./styles";
import classNames from "classnames";
import { isSmartPos } from "../../../aaone/configuration";

export const Switch = ({ active, onChange, ref }) => {
  return (
    <SwitchContainer className={classNames({ isSmartPos })}>
      <label className="switch">
        <input type="checkbox" ref={ref} checked={active} onChange={onChange} />
        <span className="slider round"></span>
      </label>
    </SwitchContainer>
  );
};
