import React, { useEffect, useState, useCallback, useRef } from "react";
import classNames from "classnames";
import {
  CampaignDescription,
  DirectionalArrow,
  DirectionalArrowsContainer,
  GridContainer,
  OptionalCampaignContent,
} from "./styles";
import { useAuth } from "../../../hook/authHook";
import { useCart } from "../../../hook/cartHook";
import { loadProductsFidelity } from "../../../../services/catalogService";
import FidelitySelectProducts from "../../../components/fidelity/selectProducts";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../../constants/pagesEnum";
import { HeaderPaymentMethod } from "../../../components/Header/paymentMethod";
import { useDialog } from "../../../hook/dialogHook";
import { AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import { useLoading } from "../../../hook/loadingHook";
import { isSmartPos } from "../../../../aaone/configuration";

const OptionalCampaign = () => {
  const agGridRef = useRef();
  const { reshop } = useAuth();
  const { setShowLoading } = useLoading();
  const { handleSetActiveOptionalCampaign } = useCart();
  const navigate = useNavigate();
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const sliderRef = useRef();
  const { showAlert } = useDialog();

  const ROW_HEIGHT = 55;
  const WIDTH_COLUMN = isSmartPos ? 60 : 100;

  const rowsData = reshop?.optionalCampaigns;

  const columnDefs = [
    {
      field: "IdCampanha",
      headerName: "ID",
      width: WIDTH_COLUMN,
      minWidth: WIDTH_COLUMN,
      maxWidth: WIDTH_COLUMN,
    },
    {
      field: "Nome",
      headerName: "Campanha",
      cellRenderer: isSmartPos ? undefined : "fullWidthCellRenderer",
      ...(isSmartPos && {
        autoHeight: true,
        cellStyle: {
          whiteSpace: "normal",
        },
      }),
    },
  ];

  useEffect(() => {
    if (reshop.optionalCampaigns.length === 0) {
      try {
        setShowLoading();
        reshop.signIn().then(() => {
          setShowLoading(false);
        });
      } catch (error) {
        setShowLoading(false);
      }

      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 200);
    }
  }, []);

  // Força o slider a iniciar sempre na posição 0
  useEffect(() => {
    if (sliderRef) {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 200);
    }
  }, [reshop]);

  const handleSelectCampaign = useCallback(
    async (campaign) => {
      try {
        if (campaign) {
          handleSetActiveOptionalCampaign(campaign);
        }

        if (campaign.ProdutosRegra) {
          setShowLoading();

          const campaignRule = campaign.ProdutosRegra.map((rule) => {
            if (rule.CodigoProduto) {
              return [
                {
                  ProductCode: rule.CodigoProduto,
                  sku: "",
                  points: 0,
                },
              ];
            } else {
              const products = [];

              rule.ListaProdutos.forEach((ProductCode) =>
                products.push({ ProductCode, sku: "", points: 0 })
              );

              return products;
            }
          });

          let productsPromisse = [];

          await campaignRule.forEach((rule, i) => {
            productsPromisse.push(
              loadProductsFidelity(undefined, rule).then((res) => {
                return {
                  quantity: campaign.ProdutosRegra[i]?.Qtde ?? 1,
                  products: res.products,
                  selected: [],
                };
              })
            );
          });

          await Promise.all(productsPromisse).then((res) => {
            const rules = [];

            res.forEach((rule) => {
              rules.push(rule);
            });

            setSelectedCampaign({
              campaignName: campaign.Nome,
              promoCode: "",
              rules,
            });
          });
        } else {
          showAlert({
            message: "A campanha foi ativada com sucesso.",
          });
        }

        setShowLoading(false);
      } catch (error) {
        console.error(error);
        setShowLoading(false);
      }
    },
    [handleSetActiveOptionalCampaign]
  );

  const scrollGrid = (direction) => {
    if (agGridRef.current) {
      const gridApi = agGridRef.current.api;

      if (gridApi?.getDisplayedRowCount() === 0) return;

      let nodeIndex = 0;
      const lastRowIndex = gridApi?.getDisplayedRowCount() - 1;

      const displayedRows =
        Math.floor(
          gridApi?.["gridBodyCon"].eBodyViewport.clientHeight / ROW_HEIGHT
        ) - 1;

      if (direction === "top") {
        nodeIndex = gridApi?.getFirstDisplayedRow() - displayedRows;
        if (nodeIndex < 0) nodeIndex = 0;
      }

      if (direction === "bottom") {
        nodeIndex = gridApi?.getLastDisplayedRow() + displayedRows;
        if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
      }

      gridApi.ensureIndexVisible(nodeIndex);
    }
  };

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, [navigate]);

  return (
    <OptionalCampaignContent>
      {(selectedCampaign?.rules?.length ?? 0) === 0 ? (
        <div style={{ width: "100%", height: "100%" }}>
          <HeaderPaymentMethod onClickBackButton={handleBackToHome} />

          <GridContainer>
            <div
              className="ag-theme-balham pdv"
              style={{ width: "95%", height: "100%" }}
            >
              <AgGridReact
                ref={agGridRef}
                getRowNodeId={(data) => data.IdCampanha}
                columnDefs={columnDefs}
                rowData={rowsData}
                localeText={agGridLocalePtBr}
                headerHeight={40}
                rowHeight={ROW_HEIGHT}
                rowBuffer={0}
                gridOptions={{ suppressHorizontalScroll: true }}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
                frameworkComponents={{
                  fullWidthCellRenderer: FullWidthCellRenderer,
                  fullHeightCellRenderer: FullHeightCellRenderer,
                }}
                onCellClicked={(e) => {
                  handleSelectCampaign(e.data);
                  setShowLoading();
                }}
              ></AgGridReact>
            </div>

            <DirectionalArrowsContainer className={classNames({ isSmartPos })}>
              <DirectionalArrow
                className="top"
                onClick={() => scrollGrid("top")}
              >
                <span className="material-icons">keyboard_arrow_up</span>
              </DirectionalArrow>

              <DirectionalArrow
                className="bottom"
                onClick={() => scrollGrid("bottom")}
              >
                <span className="material-icons">keyboard_arrow_down</span>
              </DirectionalArrow>
            </DirectionalArrowsContainer>
          </GridContainer>
        </div>
      ) : (
        <FidelitySelectProducts
          breadcrumbName={"Campanhas Opcionais"}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
        />
      )}
    </OptionalCampaignContent>
  );
};

const FullWidthCellRenderer = (props) => {
  const { data } = props;

  return (
    <CampaignDescription>
      <span className="name">{data.Nome}</span>
      <br />
      <span className="description">{data.Descricao}</span>
    </CampaignDescription>
  );
};

const FullHeightCellRenderer = ({ data }) => `${data.Nome} | ${data.Descricao}`;

export default OptionalCampaign;
