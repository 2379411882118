import React from "react";
import { SimpleItemTableSmartPos } from "../../../SimpleItemTableSmartPos";

// import { Container } from './styles';

export const ItemsPanelStatusPdv = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <div key={`simple-item-${index}-table-smart-pos`}>
          <SimpleItemTableSmartPos onClick={item.onClick} items={item.list} />
          <button onClick={item.onClick} className="button">CONFIRMAR</button>
        </div>
      ))}
    </>
  );
};
