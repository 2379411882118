import React, { Component } from "react";
import CashSupply from "../../components/CashSupply";
import { PagesEnum } from "../../constants/pagesEnum";
import { useNavigate, useLocation } from "react-router-dom";
import { useCashdesk } from "../../hook/cashdeskHook";
import { useAuth } from "../../hook/authHook";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { listMovementsService } from "../../../services/cashdeskService";
import { getRetailerId } from "../../../aaone/configuration";
import { MessageBox } from "../../components/modules";
import { formatDateHelper } from "../../helpers/formatDateHelper";
import { getPaymentsMethodService } from "../../../services/getPaymentsMethodService";
import { movementsEnum } from "../../constants/movementsEnum";
import { useDialog } from "../../hook/dialogHook";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useLoading } from "../../hook/loadingHook";
import {
  handlePrintChargeback,
  handlePrintCoupon,
} from "../../helpers/printer/supply";
import { openDrawer } from "../../helpers/openDrawer";

const withHooks = (Component) => {
  return (props) => {
    const { setVisibleSidebarCart } = useWrapperRouters();
    const navigate = useNavigate();
    const location = useLocation();
    const cashdesk = useCashdesk();
    const dialog = useDialog();
    const loading = useLoading();
    const auth = useAuth();

    return (
      <Component
        {...props}
        setVisibleSidebarCart={setVisibleSidebarCart}
        navigate={navigate}
        location={location}
        cashdesk={cashdesk}
        dialog={dialog}
        loading={loading}
        auth={auth}
      />
    );
  };
};

export class CashSupplyContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controlNumber: 0,
      openCard: true,
      openCardList: false,
      listSupply: [],
      movements: {},
      manager: {},
      operator: {},
      message: false,
      paymentType: {},
    };
  }

  componentDidMount() {
    if (!this.props.cashdesk.isCashdeskOnCurrentDay) {
      // TODO: Customizar alerta
      this.props.dialog.showAlert({
        message:
          "Para realizar essa operação, realize o fechamento do caixa e encerramento do dia anterior.",
      });

      // Ele não transferia a página, tive que colocar um timeout
      setTimeout(() => {
        this.props.navigate(PagesEnum.HOME);
      }, 300);

      return;
    }

    try {
      this.props.loading.setShowLoading(true);

      const { state } = this.props.location;

      const manager = state?.auth
        ? state.auth.user
        : this.props.auth.userAuthData;
      const operator = this.props.auth.userAuthData;

      this.setState({
        manager,
        operator,
      });

      const promises = [];

      promises.push(
        listMovementsService({
          retailerId: getRetailerId(),
          movementType: movementsEnum.SUPPLY,
          initialDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
          finalDate: formatDateHelper(new Date(), "yyyy-MM-dd"),
        }).then((res) => {
          const supplys = res.filter(
            (movement) =>
              movement.openingCount ===
              this.props.cashdesk.cashdesk.openingCount
          );

          this.setState({
            controlNumber: res.length
              ? res[res.length - 1].referenceSequence + 1
              : 1,
            listSupply: supplys,
          });
        })
      );

      promises.push(
        promises.push(
          getPaymentsMethodService().then((res) => {
            this.setState({
              paymentType: res[0],
            });
          })
        )
      );

      Promise.all(promises).then(() => {
        this.props.loading.setShowLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.props.loading.setShowLoading(false);
    }
  }

  handleClick(amount, description) {
    if (!amount && !description) return;
    this.props.loading.setShowLoading(true);

    const req = {
      description,
      amount,
      paymentType: this.state.paymentType,
      responsableId: this.state.manager.nuuidOne,
      userId: this.state.operator.nuuidOne,
      operatorName: this.state.operator.login,
      adminName: this.state.manager.login,
      responsableReferenceId: this.state.manager.employeeCode,
      userReferenceId: this.state.operator.employeeCode,
    };

    this.props.cashdesk
      .cashSupply(req)
      .then((res) => {
        this.props.dialog.showAlert({
          message: "Suprimento realizado com sucesso!",
        });
        this.handlePrinter(res);

        //Abre a gaveta
        openDrawer();

        this.handleClose();
        this.setState({
          disableConfirm: false,
        });
        this.props.loading.setShowLoading(false);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          disableConfirm: false,
        });
        this.props.loading.setShowLoading(false);
      });
  }
  catch(err) {
    console.error(err);
    this.setState({
      disableConfirm: false,
    });
    this.props.loading.setShowLoading(false);
  }

  handleChargeback(itemMovement) {
    try {
      this.props.loading.setShowLoading(true);
      this.props.cashdesk
        .chargeback({
          movementId: itemMovement.id,
        })
        .then(({ cashDesk, movementId }) => {
          this.props.dialog.showAlert({
            message: "Estorno realizado com sucesso!",
          });
          this.props.loading.setShowLoading(false);
          this.handlePrinter(cashDesk, movementId);
          this.handleClose();
        });
    } catch (err) {
      console.error(err);
      this.props.loading.setShowLoading(false);
    }
  }

  handleClose = () => {
    this.props.navigate(PagesEnum.HOME);
    this.props.setVisibleSidebarCart();
  };

  handleHidden(expandCardList) {
    const objState = {
      openCardList: expandCardList,
      openCard: !expandCardList,
    };
    this.setState(objState);
  }

  confirmChargeback = (itemMovement) => {
    this.setState({
      ...this.state,
      message: true,
      itemMovement: itemMovement,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      message: false,
    });
  };

  handlePrinter(cashdesk, currMovementId) {
    const currSupply = currMovementId
      ? cashdesk.movements.filter(
          (movement) => movement.id === currMovementId
        )[0]
      : cashdesk.movements[cashdesk.movements.length - 1];

    if (!currSupply.isReversed) {
      handlePrintCoupon(cashdesk, currSupply);
    } else {
      handlePrintChargeback(cashdesk, currSupply);
    }
  }

  render() {
    // Se o caixa estiver no dia anterior
    if (!this.props.cashdesk.isCashdeskOnCurrentDay) {
      return <></>;
    }

    const { openCard, openCardList } = this.state;
    //const { tableCard } = this.props.table;

    const messageBox = this.state.message && (
      <MessageBox
        message="Deseja realmente estornar suprimento?"
        handleCancel={() => this.closeModal()}
        handleConfirm={() => this.handleChargeback(this.state.itemMovement)}
        okText="Sim"
      />
    );

    return (
      <KeyboardProvider>
        <CashSupply
          title="SUPRIMENTO"
          handleClick={(amount, description) =>
            this.handleClick(amount, description)
          }
          handleClose={() => this.handleClose()}
          handleHidden={(isCardList) => this.handleHidden(isCardList)}
          handlePrinter={(movementId) =>
            this.handlePrinter(this.props.cashdesk.cashdesk, movementId)
          }
          handleChargeback={(itemMovement) =>
            this.confirmChargeback(itemMovement)
          }
          expandCard={openCard}
          expandCardList={openCardList}
          //tableCardList={tableCard}
          listSupply={this.state.listSupply}
          manager={this.state.manager}
          controlNumber={this.state.controlNumber}
          onCartHide={() => {}}
          setVisibleSideKeyboardContainer={(visible) => {}}
        />
        {messageBox}
      </KeyboardProvider>
    );
  }
}

export default withHooks(CashSupplyContainer);
