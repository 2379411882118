import styled from 'styled-components';

export const PizzaContainer = styled.div`
    display: flex;
    width: 300px;
    height: 300px;
    box-shadow: 2px -1.5px 24px -1px #000;
    border-radius: 50%;
    background: repeating-linear-gradient(-90deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7) 1px,transparent 3px);
    margin: auto;
`;

export const Title = styled.span`
    font-size: 2em; 
    margin-left: 10px; 
`;

export const PizzaHalf = styled.div`
    width:50%;
    height: 98%;
    margin: auto;
    overflow: hidden;

    img {
        width: 150px;
    }

    &:first-child {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-left: -1px;

        img {
            margin-left: -3px;
            margin-top: 1px;
        }
    }

    &:last-child {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        margin-right: -4px;

        img {
            margin-left: -3px;
            margin-top: 1px;
            transform: rotateY(180deg);
        }
    }
`;

export const Pizza = styled.div`
    width:50%;
    height: 98%;
    margin: auto;
    overflow: hidden;

    img {
        width: 150px;
    }

    &:first-child {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-left: 7px;

        img {
            margin-left: -3px;
            margin-top: 1px;
        }
    }

    &:last-child {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        margin-right: 8px;

        img {
            margin-left: -3px;
            margin-top: 1px;
            transform: rotateY(180deg);
        }
    }
`;