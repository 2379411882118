import styled from "styled-components";
import Button from "../../Button";

export const DiscountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonOutline = styled(Button)`
  &.btn-outline {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem !important;

    &.remove {
      padding: 0 !important;
      width: 1.6rem;
      color: ${(props) => props.theme.colors.error};

      div {
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
