import { apiBff } from "./api";

export const cupomService = async () => {
  try {
    const { data } = await apiBff.post("/process", {
      step: "saleFlow.send_sale_order",
    });

    return data;
  } catch (error) {
    console.error("Error in cupomService :: ", error);
    return Promise.reject("Error in cupomService ::");
  }
};
