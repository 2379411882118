import { OBSERVATION_ORDER_KEY_NAME } from "../pdv-one/constants/keyNamesSessionStorage";
import { productionPrinter } from "../pdv-one/helpers/printer/productionPrinter";
import { getProductionPrinterData } from "./printerService";

export const sendToProductionPrinter = (orderId) => {
  getProductionPrinterData({
    orderId,
  })
    .then((orders) => {
      console.log(`Chegou na impressão | Carga do Routing`);

      if (orders?.length > 0) {
        orders.forEach((order, index) => {
          console.log(`Executando impressão de produção #${index}`);

          productionPrinter(order);

          console.log(`Impressão de produção executada #${index}`);
        });
      }
    })
    .catch((err) => {
      sessionStorage.removeItem(OBSERVATION_ORDER_KEY_NAME);

      console.error(
        `Não foi possível enviar para a impressora de produção. | ${JSON.stringify(
          err
        )}`
      );
    });
};
