import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  max-height: -webkit-fill-available;

  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;

  .content__wrapper {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    flex: 1;

    .form__container {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 2rem 0 0;

      flex: 1;

      input {
        outline: none;
        border: 0;

        text-align: center;
        font-size: 1.5rem;

        border: 2px dashed #ccc;
        width: 90%;
      }

      .button.button__submit {
        background-color: ${({ theme }) => theme.colors.purple};
        color: #fff;

        text-transform: uppercase;
        margin-top: 1rem;
        width: 100%;

        transition: background-color 0.2s ease-in-out;

        &::disabled {
          background-color: ${({ theme }) => theme.colors.purpleDisabled};
        }
      }
    }
  }
`;
