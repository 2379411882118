import { newDate } from "../aaone/shared/utils/getDate";
import { apiReshop, endPointFidelityReshop } from "./api";

export const fidelity = async (cpf, storeCode) => {
  const endpoint = `${endPointFidelityReshop}/ConsultaProdutosResgataveis`;

  try {
    const { data } = await apiReshop.get(endpoint, {
      params: {
        document: cpf,
        storeCode,
        saleCategory: 1,
        date: newDate(),
      },
    });

    return data;
  } catch (error) {
    console.error(`Error in fidelity(params: ${cpf}, ${storeCode}) :: `, error);
    return Promise.reject(`Error in fidelity(params: ${cpf}, ${storeCode}) :: `);
  }
};
