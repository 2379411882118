import React, { useCallback, useEffect } from "react";

import { Container } from "./styles";
import { useAuth } from "../../../hook/authHook";
import { MdClose, MdPerson } from "react-icons/md";
import { maskCPFHelper } from "../../../helpers/maskCPFHelper";
import { useCart } from "../../../hook/cartHook";
import { useLocation } from "react-router-dom";

export const ModalFidelityData = ({ handleClose }) => {
  const { reshop } = useAuth();
  const { clearCart } = useCart();
  const location = useLocation();

  const signOut = useCallback(() => {
    reshop.signOut();
    handleClose();
    clearCart();
  }, [reshop, handleClose]);

  const onEventKeyUp = useCallback(
    (e) => {
      const shortcuts = ["Escape"];

      if (shortcuts.includes(e.key)) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    window.addEventListener("keyup", onEventKeyUp);

    return () => {
      window.removeEventListener("keyup", onEventKeyUp);
    };
  }, [onEventKeyUp]);

  return (
    <Container>
      <div className="content__wrapper">
        <div className="content">
          <button onClick={handleClose} className="button button__close">
            <MdClose size="2rem" />
          </button>

          <div className="profile">
            <div className="avatar">
              <MdPerson size="1.5rem" />
            </div>
            <div className="name">{reshop?.data?.Nome}</div>
          </div>
          <div className="profile__wrapper">
            <div className="profile__data">
              <ul>
                <li>
                  <div className="name">Nome</div>
                  <div className="value">{reshop?.data?.Nome}</div>
                </li>
                <li>
                  <div className="name">CPF</div>
                  <div className="value">
                    {maskCPFHelper(reshop?.data?.Documento ?? "")}
                  </div>
                </li>
              </ul>
            </div>
            <div className="wallet__data">
              <div className="wallet__data">
                <div className="name">Saldo Reshop</div>
                <div className="value">
                  {`${reshop?.data?.SaldoMonetario}  PTS`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button__container">
          {!location.pathname.startsWith("/payment-") && (
            <button
              role="button"
              className="button button__logout"
              title="Clique para desconectar do reshop"
              onClick={signOut}
            >
              Desconectar
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};
