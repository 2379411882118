import styled from 'styled-components';

export const ContainerProgress = styled.div`
    width: 100%;
    border-radius: 14px;
    background: var(--color-background-progress-bar);
    height: 20px;
    margin-top: 0;
    box-shadow: 2px 1px 5px #000;
`;

export const Progress = styled.div`
    border-radius: 14px;
    background: var(--color-progress);
    height: 100%;
`;

export const ContainerTitle = styled.section`
    display: flex;
    align-items: center;
    margin-bottom: 2em;
`;

export const CountPage = styled.strong`
    font-size: 3.4em;
    color: var(--color-title-category);
`;

export const Title = styled.span`
    font-size: 2em;
    margin-left: 10px;

    &.big-text {
      color: var(--color-progress);
      text-transform: uppercase;
      font-size: 3rem;
      font-weight: 700;
    }
`;

export const Index = styled.span`
    font-size: 4em;
    font-weight: 800;
    margin-left: 0px;
`;
