import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { getUsers } from "../../../../services/usersServices";
import {
  Container,
  InputContent,
  DirectionalArrow,
  DirectionalArrowsContainer,
  RadioGroup,
  RadioWrapper,
  RadioLabel,
  RadioInput,
  ButtonContent,
  ContentWrapper,
  HeaderContent,
  Header,
  Button
} from "./styles";

export const ModalWaiterCommission = ({ onClose, isOpen, onSubmit, onWaiterSelect }) => {

  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const getUsersData = async () => {
    try {
      const response = await getUsers();
      const waiterUsers = response.users.filter((user) => user.isWaiter);
      setUsers(waiterUsers);

      if (!selectedUser && waiterUsers.length > 0) {
        setSelectedUser(waiterUsers[0]);
        onWaiterSelect(waiterUsers[0]);
      }
    } catch (error) {
      console.log("Erro ao obter os usuários:", error);
    }
  };

  useEffect(() => {
    if (isOpen && users.length === 0) {
      getUsersData();
    }
  }, [isOpen, users.length, onWaiterSelect, selectedUser]);

  const handleUserSelection = (event) => {
    const userId = event.target.value;
    const selectedUser = users.find((user) => user.loginName === userId);
    setSelectedUser(selectedUser);
    onWaiterSelect(selectedUser);
  };

  const scrollGrid = (direction) => {
    const container = document.getElementById("radio-container");
    const scrollAmount = 200;
    if (direction === "top") {
      setScrollPosition((prev) => Math.max(prev - scrollAmount, 0));
    } else if (direction === "bottom") {
      setScrollPosition((prev) => Math.min(prev + scrollAmount, container.scrollHeight - container.clientHeight));
    }
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <Container>
      <ContentWrapper>
        <HeaderContent>
          <Header>
            <div className="title">Garçom</div>
            <button onClick={onClose} className="button__close">
              <MdClose size="1.5rem" />
            </button>
          </Header>
        </HeaderContent>
        <HeaderContent className="title">
          <Header className="status">
            Selecione o garçom que realizou o lançamento
          </Header>
        </HeaderContent>
        <InputContent >
          <RadioGroup id="radio-container">
            {users.map((user, index) => (
              <RadioWrapper
                key={user.loginName}
                style={{ transform: `translateY(-${scrollPosition}px)` }}
              >
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    defaultChecked={index === 0}
                    name="waiterSelection"
                    value={user.loginName}
                    onChange={handleUserSelection}
                  />
                  {user.loginName.toUpperCase()}
                </RadioLabel>
              </RadioWrapper>
            ))}
          </RadioGroup>
          <DirectionalArrowsContainer>
            <DirectionalArrow className="top" onClick={() => scrollGrid("top")}>
              <span className="material-icons">keyboard_arrow_up</span>
            </DirectionalArrow>
            <DirectionalArrow className="bottom" onClick={() => scrollGrid("bottom")}>
              <span className="material-icons">keyboard_arrow_down</span>
            </DirectionalArrow>
          </DirectionalArrowsContainer>
        </InputContent>
        <ButtonContent>
          <Button className="button__white" onClick={onClose}>Cancelar</Button>
          <Button className="button__white send" onClick={onSubmit}>Enviar</Button>
        </ButtonContent>
      </ContentWrapper>
    </Container>
  );
};

export default ModalWaiterCommission;
