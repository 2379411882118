import * as signalR from "@aspnet/signalr";

export default class WSConnection {
  _connection = null;
  stoping = false;
  connectionString = null;

  constructor(connectionString) {
    this.connectionString = connectionString;
  }

  async Connect() {
    try {
      await this._connection.start();
    } catch (err) {
      console.info("Retry connect in 3 sec.");
      await new Promise((resolve) => setTimeout(resolve, 3000));
      console.info("Retring connect.");
      await this.Connect();
    }
  }

  Stop() {
    this.stoping = true;
    this._connection.stop();
  }

  async OnClose() {
    if (!this.stoping) {
      await this.Connect();
    }
  }

  BuildConection() {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.connectionString)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    connection.serverTimeoutInMilliseconds = 120 * 1000;
    connection.keepAliveIntervalInMilliseconds = 60 * 1000;
    return connection;
  }

  async Start(Handle) {
    this._connection = this.BuildConection();
    this._connection.onclose(this.OnClose.bind(this));
    this._connection.on("Handle", Handle);
    await this.Connect();
    return this._connection;
  }
}
