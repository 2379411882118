import React, { useEffect, useMemo } from "react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import classNames from "classnames";

import Card from "../Card";
import { DropdownSet } from "./DropdownSet";
import { ProductLevel } from "./ProductLevel";
import {
  Container,
  DirectionalArrow,
  DirectionalArrowsContainer,
  ProductsUnavailableWrapper,
} from "./styles";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import keyboardInput from "../agGrid/keyboardInput/keyboardInput";
import { isSmartPos } from "../../../aaone/configuration";
import { ItemsProductsUnavailableSmartPos } from "./mobile";

const ROW_HEIGHT = 75;

const ProductsUnavailable = (props) => {
  const { rowsData, handleClose, agGridRef } = props;

  useEffect(() => {
    if (agGridRef.current) {
      agGridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const autoGroupColumnDef = {
    onfocus: true,
    headerName: "Código",
    floatingFilter: true,
    floatingFilterComponent: "keyboardInput",
    filter: "agTextColumnFilter",
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      width: 100,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
    };
  }, []);

  const columns = [
    {
      headerName: "Nível",
      field: "prodHierarchy",
      cellRenderer: "productLevel",
      cellClass: "customCell",
      floatingFilter: true,
      floatingFilterComponent: "keyboardInput",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Nome",
      field: "description",
      cellClass: "customCell",
      floatingFilter: true,
      floatingFilterComponent: "keyboardInput",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Grupo",
      field: "classification",
      cellClass: "customCell",
      floatingFilter: true,
      floatingFilterComponent: "keyboardInput",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Disponível BALCAO",
      field: "unavailable",
      cellRenderer: "dropdownSet",
      cellClass: "customCell",
      unavailableAt: "BALCAO",
    },
    {
      headerName: "Disponível DELIVERY",
      field: "unavailable",
      cellRenderer: "dropdownSet",
      cellClass: "customCell",
      unavailableAt: "DELIVERY",
    },
    {
      headerName: "Disponível AA",
      field: "unavailable",
      cellRenderer: "dropdownSet",
      cellClass: "customCell",
      unavailableAt: "AA",
    },
  ];

  const scrollGrid = (direction) => {
    if (!agGridRef.current) {
      return;
    }

    const displayedRowCount = agGridRef.current.api.getDisplayedRowCount();
    if (displayedRowCount === 0) {
      return;
    }

    let nodeIndex = 0;
    const lastRowIndex = displayedRowCount - 1;
    const displayedRows =
      Math.floor(
        agGridRef.current.api?.["gridBodyCon"].eBodyViewport.clientHeight /
          ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = agGridRef.current.api.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = agGridRef.current.api.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    agGridRef.current.api.ensureIndexVisible(nodeIndex);
  };

  const frameworkComponents = {
    productLevel: ProductLevel,
    dropdownSet: DropdownSet,
    keyboardInput: keyboardInput,
  };

  const itemsSerializedSmartPos = rowsData.map((item) => {
    const columnSerialized = [
      { headerName: autoGroupColumnDef.headerName, field: item.productCode },
      ...columns,
    ].map((currentColumn) => ({
      label: currentColumn.headerName,
      field: currentColumn.field,
      key: currentColumn?.cellRenderer ?? null,
    }));

    const returnSerialized = columnSerialized.map((currentColumn) => {
      const hasElement =
        currentColumn.key && frameworkComponents[currentColumn.key];

      const Element = hasElement
        ? frameworkComponents[currentColumn.key]
        : React.Fragment;

      const value = item[currentColumn.field] ?? currentColumn?.field ?? "";

      return {
        label: currentColumn.label,
        children: hasElement ? (
          <>
            <Element data={item} value={value} colDef={currentColumn} />
          </>
        ) : (
          value || null
        ),
      };
    });

    return {
      list: returnSerialized,
    };
  });

  if (isSmartPos) {
    return (
      <Container>
        <p>Página indisponível na Smart POS temporariamente</p>
      </Container>
    );
  }

  return (
    <KeyboardProvider>
      <Container>
        <Card
          title="Produtos Indisponíveis"
          handleClose={() => {
            handleClose();
          }}
          closeEnable
          fullHeight
        >
          <ProductsUnavailableWrapper className={classNames({ isSmartPos })}>
            {isSmartPos ? (
              <ItemsProductsUnavailableSmartPos
                items={itemsSerializedSmartPos}
              />
            ) : (
              <>
                <div
                  className="ag-theme-balham pdv"
                  style={{ width: `calc(100% - 40px)` }}
                >
                  <AgGridReact
                    ref={agGridRef}
                    getRowNodeId={(data) => data.productCode}
                    localeText={agGridLocalePtBr}
                    headerHeight={40}
                    rowHeight={ROW_HEIGHT}
                    rowData={rowsData}
                    autoGroupColumnDef={autoGroupColumnDef}
                    frameworkComponents={frameworkComponents}
                    rowSelection="multiple"
                    isRowSelectable={false}
                    treeData
                    getDataPath={(data) => data.prodHierarchy}
                    defaultColDef={defaultColDef}
                    columnDefs={columns}
                    suppressContextMenu
                    suppressRowClickSelection
                    rowStyle={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      lineHeight: "15px",
                      wordBreak: "break-word",
                    }}
                    onGridReady={({ api }) => {
                      api.sizeColumnsToFit();
                    }}
                  >
                    {columns.map((column) => {
                      const cellStyle = {
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        lineHeight: "15px",
                        wordBreak: "break-word",
                      };

                      return (
                        <AgGridColumn
                          key={column.field}
                          cellStyle={cellStyle}
                          field={column.field}
                          headerName={column.headerName}
                        />
                      );
                    })}
                  </AgGridReact>
                </div>
                <DirectionalArrowsContainer>
                  <DirectionalArrow
                    className="top"
                    onClick={() => scrollGrid("top")}
                  >
                    <span className="material-icons">keyboard_arrow_up</span>
                  </DirectionalArrow>

                  <DirectionalArrow
                    className="bottom"
                    onClick={() => scrollGrid("bottom")}
                  >
                    <span className="material-icons">keyboard_arrow_down</span>
                  </DirectionalArrow>
                </DirectionalArrowsContainer>
              </>
            )}
          </ProductsUnavailableWrapper>
        </Card>
      </Container>
    </KeyboardProvider>
  );
};

export default ProductsUnavailable;
