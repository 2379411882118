import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Container } from "./styles";
import { HeaderPaymentMethod } from "../../../components/Header/paymentMethod";
import { MdClose } from "react-icons/md";
import { KeyboardNumeric } from "../../../components/KeyboardNumeric";
import { useCart } from "../../../hook/cartHook";
import {
  escMaskMoneyHelper,
  maskMoneyHelper,
} from "../../../helpers/maskMoneyHelper";

import { PagesEnum } from "../../../constants/pagesEnum";
import { getPaymentMethodName } from "../../../helpers/getPaymentMethodName";
import { escCashOnlyNumbersHelper } from "../../../helpers/escOnlyNumbersHelper";
import { getTotalAlreadyPaid } from "../../../helpers/multiPaymentsHelper";
import { PAYMENT_TYPE_ENUM } from "../../../constants/paymentTypeEnum";
import { PAYMENT_METHOD_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import { isSmartPos } from "../../../../aaone/configuration";
import classNames from "classnames";

export const PaymentHowMuchWillYouPayPage = () => {
  const { totalPriceWDiscount } = useCart();

  const location = useLocation();
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const [starting, setStarting] = useState(true);
  const [isLoadingValidate, setIsLoadingValidate] = useState(false);

  const [keyboardActions, setKeyboardActions] = useState();
  const [restante, setRestante] = useState(0);
  const [totalChangeMoney, setTotalChangeMoney] = useState(0);

  let paymentMethodName = getPaymentMethodName();

  const goToPaymentMethods = () => {
    if (window.desktopApp) {
      // window.desktopApp.publish("machine.devices.tef.undo", {});
    }

    navigate(PagesEnum.PAYMENT_METHODS, { replace: true });
  };

  const handleResetValue = useCallback(() => {
    if (inputRef?.current && keyboardActions?.value) {
      const currentPrice = maskMoneyHelper("R$0,00");

      inputRef.current.value = currentPrice;
      keyboardActions.set(currentPrice);
    }
  }, [keyboardActions?.value, inputRef]);

  const goToPayment = (totalPriceToPayNow) => {
    let routeNameTo = PagesEnum.PAYMENT_WITH_CARD_METHOD;
    const isPos = Boolean(location?.state?.isPos ?? false);

    switch (paymentMethodName) {
      case "DINHEIRO":
      case "FIDELIDADE":
        routeNameTo = PagesEnum.PAYMENT_CASH_METHOD;
        break;
      case "QRLINX":
      case "QR LINX":
        routeNameTo = isPos
          ? PagesEnum.PAYMENT_POS_METHOD
          : PagesEnum.PAYMENT_QR_LINX_METHOD;
        break;
      case "CREDITO":
      case "CRÉDITO":
      case "DEBITO":
      case "DÉBITO":
      case "VOUCHER":
      case "PIX":
        routeNameTo = isPos
          ? PagesEnum.PAYMENT_POS_METHOD
          : PagesEnum.PAYMENT_WITH_CARD_METHOD;
        break;
      case "OUTROS":
      case "QRCODE":
        routeNameTo = PagesEnum.PAYMENT_POS_METHOD;
        break;
      default:
        routeNameTo = PagesEnum.PAYMENT_WITH_CARD_METHOD;
    }

    if (location?.state?.data?.paymentOneBaseCode) {
      let paymentMethodNameRenamed = null;
      const paymentOneBaseCode = location?.state?.data?.paymentOneBaseCode;
      const tefNames = {
        [PAYMENT_TYPE_ENUM.Debit]: "DEBITO",
        [PAYMENT_TYPE_ENUM.DebitTef]: "DEBITO",
        [PAYMENT_TYPE_ENUM.Credit]: "CREDITO",
        [PAYMENT_TYPE_ENUM.CreditTef]: "CREDITO",
        [PAYMENT_TYPE_ENUM.Voucher]: "VOUCHER",
        [PAYMENT_TYPE_ENUM.VoucherTef]: "VOUCHER",
      };

      switch (paymentOneBaseCode) {
        case PAYMENT_TYPE_ENUM.Money:
          paymentMethodName = ["DINHEIRO", "FIDELIDADE"].includes(
            paymentMethodName
          )
            ? paymentMethodName
            : "DINHEIRO";
          paymentMethodNameRenamed = paymentMethodName;
          routeNameTo = PagesEnum.PAYMENT_CASH_METHOD;
          break;
        case PAYMENT_TYPE_ENUM.DebitTef:
        case PAYMENT_TYPE_ENUM.CreditTef:
        case PAYMENT_TYPE_ENUM.VoucherTef:
          paymentMethodNameRenamed = tefNames[paymentOneBaseCode] ?? "CREDITO";
          paymentMethodName = paymentMethodNameRenamed;
          routeNameTo = PagesEnum.PAYMENT_WITH_CARD_METHOD;
          break;
        case PAYMENT_TYPE_ENUM.Debit:
        case PAYMENT_TYPE_ENUM.Credit:
        case PAYMENT_TYPE_ENUM.Voucher:
          paymentMethodNameRenamed = tefNames[paymentOneBaseCode] ?? "CREDITO";
          paymentMethodName = paymentMethodNameRenamed;
          routeNameTo = PagesEnum.PAYMENT_POS_METHOD;
          break;
        case PAYMENT_TYPE_ENUM.Others:
        case PAYMENT_TYPE_ENUM.QRCode:
          routeNameTo = PagesEnum.PAYMENT_POS_METHOD;
          break;
        default:
          routeNameTo = routeNameTo;
      }

      if (paymentMethodNameRenamed) {
        sessionStorage.setItem(
          PAYMENT_METHOD_KEY_NAME,
          paymentMethodNameRenamed
        );
      }
    }

    navigate(routeNameTo, {
      replace: true,
      state: {
        PAYMENT_METHOD: paymentMethodName,
        totalPriceToPayNow,
        totalChangeMoney,
        ...(location?.state?.data && { data: location.state.data }),
      },
    });
  };

  const handleSubmitPayment = useCallback(
    async (e) => {
      e.preventDefault();

      const data = new FormData(e.currentTarget);
      const totalPriceToPayNow = escCashOnlyNumbersHelper(
        data.get("priceToPayNow") ?? 0
      );
      setIsLoadingValidate(true);

      goToPayment(totalPriceToPayNow);
    },
    [totalPriceWDiscount, totalChangeMoney, location]
  );

  useEffect(() => {
    if (!paymentMethodName) {
      goToPaymentMethods();
    } else {
      setStarting(false);
    }
  }, [location]);

  useEffect(() => {
    if (inputRef && keyboardActions?.value) {
      inputRef.current.value = maskMoneyHelper(keyboardActions.value);

      const pagandoX = Number(escMaskMoneyHelper(keyboardActions.value));
      const amountPaidAndTotalPrice =
        (totalPriceWDiscount ?? 0) - getTotalAlreadyPaid();
      const restantePrice = amountPaidAndTotalPrice - pagandoX;
      setRestante(restantePrice < 0 ? 0 : restantePrice);

      let changeMoneyPrice = 0;
      if (pagandoX > amountPaidAndTotalPrice) {
        changeMoneyPrice = pagandoX - amountPaidAndTotalPrice;
      }

      setTotalChangeMoney(changeMoneyPrice);
    }
  }, [keyboardActions?.value, totalPriceWDiscount, inputRef]);

  useEffect(() => {
    if (
      typeof keyboardActions?.value !== "undefined" &&
      inputRef?.current.value.length === 0
    ) {
      const amountPaidAndTotalPrice =
        (totalPriceWDiscount ?? 0) - getTotalAlreadyPaid();
      const currentPrice = maskMoneyHelper(amountPaidAndTotalPrice.toFixed(2));

      inputRef.current.value = currentPrice;
      keyboardActions.set(currentPrice);
    }
  }, [keyboardActions?.value, inputRef, totalPriceWDiscount, location]);

  if (starting) {
    return <></>;
  }

  return (
    <Container className={classNames({ isSmartPos })}>
      <HeaderPaymentMethod
        onClickBackButton={goToPaymentMethods}
        disabledBackButton={isLoadingValidate}
      >
        Informe o valor
      </HeaderPaymentMethod>
      <div className="content">
        <form onSubmit={handleSubmitPayment} className="form__container">
          <div className="inputs__group input__container_value">
            <input
              type="text"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              role="presentation"
              readOnly
              // onFocus={(e) => {
              //   e.currentTarget.removeAttribute("readOnly");
              // }}
              onChange={(e) => {
                e.target.value = maskMoneyHelper(e.target.value);

                if (keyboardActions?.set) {
                  keyboardActions.set(e.target.value);
                }

                return e;
              }}
              ref={inputRef}
              name="priceToPayNow"
              id="priceToPayNow"
              placeholder="Informe o valor"
            />
            <button
              onClick={handleResetValue}
              className="button button__clear"
              type="button"
            >
              <MdClose />
            </button>
          </div>
          <div className="keyboard__group">
            <KeyboardNumeric
              newButtons={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                {
                  className: "doubleZero",
                  value: "00",
                },
                "0",
                {
                  className: "backspace",
                  value: "←",
                },
              ]}
              setActions={setKeyboardActions}
            />
            <button
              className="button button__submit"
              type="submit"
              disabled={
                (paymentMethodName !== "DINHEIRO" &&
                  Number(totalChangeMoney.toFixed(2)) > 0) ||
                escCashOnlyNumbersHelper(keyboardActions?.value) <= 0
              }
            >
              Pronto
            </button>
          </div>
          <div className="troco__container">
            {paymentMethodName === "DINHEIRO" && (
              <>
                <div>TROCO</div>
                <div className="troco__valor">
                  {maskMoneyHelper(`R$${totalChangeMoney.toFixed(2)}`)}
                </div>
              </>
            )}
            <div>
              Restante a Receber:{" "}
              <strong>{maskMoneyHelper(`R$${restante.toFixed(2)}`)}</strong>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
