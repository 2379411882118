import React, { useCallback, useEffect, useRef, useState } from "react";

import { Container } from "./styles";
import { KeyboardNumeric } from "../../KeyboardNumeric";
import { useCart } from "../../../hook/cartHook";
import { ItemProduct } from "../../Products/Item";
import { MdClose } from "react-icons/md";
import { formatDecimal } from "../../../helpers/maskMoneyHelper";
import { useDialog } from "../../../hook/dialogHook";

export const ModalProductFractional = ({
  handleClose,
  productFractionalData,
}) => {
  const inputRef = useRef(null);
  const itemRef = useRef(null);
  const [keyboardActions, setKeyboardActions] = useState();
  const {
    setQuantityToCart,
    addProductToCart,
    selectedPosition
  } = useCart();
  const { showAlert } = useDialog();
  const { productResult, dataProductResult, cb } = productFractionalData;

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const data = new FormData(e.currentTarget);

      try {
        const totalInputValue = data.get("total").replace(",", ".");
        const totalQuantity = Number(totalInputValue);

        if (isNaN(totalQuantity) || totalQuantity <= 0) {
          showAlert({
            message: "O valor deve ser maior que zero e válido.",
          });
          return;
        }

        if (cb) {
          cb(totalQuantity);
        } else {
          addProductToCart({
            ...productResult,
            categoryId: dataProductResult.categoryId,
            categoryIsFidelity: dataProductResult?.categoryIsFidelity ?? false,
            pointPrice: dataProductResult?.pointPrice,
            quantityToCart: totalQuantity,
            tablePosition: selectedPosition ?? null,
          });
        }

        setQuantityToCart("reload");

        handleClose();
      } catch (error) {
        showAlert({
          message:
            "Não foi possível inserir a quantidade; ocorreu um erro inesperado.",
        });
        console.error(error);
      }
    },
    [productFractionalData]
  );

  useEffect(() => {
    if (inputRef && keyboardActions?.value) {
      const value = formatDecimal(keyboardActions.value?.replace(/^0+/, ""));
      inputRef.current.value = value;
      keyboardActions.set(value);
    }
  }, [keyboardActions?.value, inputRef]);

  return (
    <Container>
      <div className="content">
        <header className="header">
          <div className="title">Informe a Quantidade</div>
          <button onClick={handleClose} className="button__close">
            <MdClose size="1.5rem" />
          </button>
        </header>
        <form onSubmit={handleSubmit} className="form__container">
          <div className="info__text__container">
            <KeyboardNumeric
              newButtons={[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                {
                  className: "backspace",
                  value: "←",
                },
                "0",
                {
                  className: "close",
                  onClick: () => { },
                },
              ]}
              setActions={setKeyboardActions}
            />
          </div>
          <div className="input-quantity">
            <input
              type="text"
              value={keyboardActions?.value}
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              role="presentation"
              readOnly
              autoFocus
              inputMode="numeric"
              onFocus={(e) => {
                e.currentTarget.focus();
                e.currentTarget.removeAttribute("readOnly");
              }}
              onBlur={(e) => e.currentTarget.focus()}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/^0+/, "");

                if (keyboardActions?.set) {
                  keyboardActions.set(e.target.value);
                }

                return e;
              }}
              ref={inputRef}
              name="total"
              id="total"
              placeholder="0,000"
            />
          </div>
          <div className="product-detail">
            {productResult?.productCode && (
              <ItemProduct
                ref={itemRef}
                forceLayoutDefault
                data={{ ...productResult, code: productResult.productCode }}
              >
                {
                  productResult.allDescriptions.length ?
                    productResult.allDescriptions.descriptionPDV :
                    productResult.description
                }
              </ItemProduct>
            )}
            {productResult === "empty" && (
              <div className="empty__product">Produto não encontrado</div>
            )}
          </div>
          <div className="button-content">
            <button
              className="button button__submit button__cancel"
              onClick={handleClose}
            >
              Cancelar
            </button>
            <button
              className="button button__submit"
              disabled={keyboardActions?.value.length === 0}
              type="submit"
            >
              Pronto
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
