import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { PagesEnum } from "../../../constants/pagesEnum";
import { useCashdesk } from "../../../hook/cashdeskHook";
import Button from "../../Button";
import { EachSectionContainer, MenuSection, ToggleMenu } from "./styles";
import { useWrapperRouters } from "../../../hook/wrapperRoutersHook";
import { useAuth } from "../../../hook/authHook";
import { useCart } from "../../../hook/cartHook";
import {
  MdAddCircle,
  MdClose,
  MdDesktopWindows,
  MdExpandMore,
  MdLaunch,
  MdLocalShipping,
  MdPrint,
  MdReceipt,
  MdRemoveCircle,
  MdSettings,
  MdToday,
  MdRefresh,
  MdMinimize,
  MdTableRestaurant,
  MdLockOpen,
  MdRoomService,
} from "react-icons/md";
import { WiCloudRefresh } from "react-icons/wi";
import { FaClipboardList } from "react-icons/fa";
import { useDialog } from "../../../hook/dialogHook";
import { useLoading } from "../../../hook/loadingHook";
import {
  clearLocalPayments,
  getLocalPayments,
  hasLocalPayments,
} from "../../../helpers/multiPaymentsHelper";
import { useMigration } from "../../../hook/migrationHook";
import { getSystemParamsHelper } from "../../../helpers/getSystemParamsHelper";
import { openDrawer } from "../../../helpers/openDrawer";
import { getTableSelectedHelper } from "../../../helpers/getTableSelectedHelper";
import { isKiosk, isSmartPos } from "../../../../aaone/configuration";
import { undoTEFPayments } from "../../../helpers/paymentHelper";
import { currencyMask } from "../../../helpers/masks";

const MenuSections = () => {
  const systemParams = getSystemParamsHelper();
  const tableSelected = getTableSelectedHelper();

  const onCashierReductionOpenWithdrawingScreen =
    systemParams?.onCashierReductionOpenWithdrawingScreen || false;

  const { itemsInCart, clearCart, setIsCartVisible } = useCart();
  const {
    cashdesk,
    setShowCashierClosingDay,
    listReducedCashdesk,
    getLastCashdesk,
    getListCashDesk,
  } = useCashdesk();

  const { setIsOpenMenu, setVisibleSidebarCart, getInfoTef } =
    useWrapperRouters();
  const navigate = useNavigate();
  const location = useLocation();
  const migration = useMigration();

  const { profile, userAuthData } = useAuth();
  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();

  const [openedMenu, setOpenedMenu] = useState(`radio0`);
  const [visibleCashOpen, setVisibleCashOpen] = useState(false);
  const [visibleCashClose, setVisibleCashClose] = useState(false);
  const [visibleCashReduce, setVisibleCashReduce] = useState(false);
  const [visibleCashDayClose, setVisibleCashDayClose] = useState(false);
  const [visibleCashWithdraw, setVisibleCashWithdraw] = useState(false);
  const [visibleCashSupply, setVisibleCashSupply] = useState(false);
  const [visibleArmouredCar, setVisibleArmouredCar] = useState(false);

  useEffect(() => {
    // Função para exibir os itens quando o caixa estiver fechado
    const setCashClosed = () => {
      // Exibição da Abertura de Caixa
      setVisibleCashOpen(true);

      // Exibição da Encerramento do dia
      setVisibleCashDayClose(true);
    };

    // Função para exibir os itens quando o caixa estiver aberto
    const setCashOpened = () => {
      // Exibição do Fechamento de Caixa
      setVisibleCashClose(true);

      // Exibição da Redução de Caixa
      setVisibleCashReduce(true);

      // Exibição da Sangria
      setVisibleCashWithdraw(true);

      // Exibição do Suprimento
      setVisibleCashSupply(true);

      // Exibição do Carro-Forte
      setVisibleArmouredCar(true);
    };

    if (!cashdesk || cashdesk?.closingDate || cashdesk?.reducingDate) {
      setCashClosed();
    } else if (!cashdesk?.closingDate || !cashdesk?.reducingDate) {
      setCashOpened();
    }

    return () => {
      setVisibleCashOpen(false);
      setVisibleCashDayClose(false);
      setVisibleCashClose(false);
      setVisibleCashReduce(false);
      setVisibleCashWithdraw(false);
      setVisibleCashSupply(false);
      setVisibleArmouredCar(false);
    };
  }, [cashdesk]);

  const handleLink = async (item) => {
    if (
      item?.isPreventClose === false ||
      typeof item?.isPreventClose === "undefined"
    ) {
      setIsOpenMenu(false);
      setIsCartVisible(false);
    }

    if (item.onClick) {
      await item.onClick();
    }
    if (item.needAuth && !profile?.isManager) {
      navigate(PagesEnum.NEED_AUTH, {
        state: { redirectUrl: item.url, ...item.params, action: item.action },
      });
      return;
    }

    if (item.url) {
      navigate(item.url);
    }
  };

  const hasItemsInCart = itemsInCart.length > 0 || tableSelected >= 0;
  const isPaymentPage = location.pathname.startsWith("/payment-");
  const cancelOperation = useCallback(() => {
    if (isPaymentPage) {
      showAlert({
        message:
          "A ação de cancelar venda não é permitida durante o pagamento de uma venda!",
      });
      return;
    }
    if (userAuthData.isManager) {
      // Desfaz os pagamentos TEF
      const allPayments = getLocalPayments();

      undoTEFPayments(() => {
        if (allPayments.length) {
          showAlert({
            message: (
              <>
                Os pagamentos abaixo foram cancelados.
                {allPayments.length ? (
                  <>
                    {allPayments
                      .filter((payment) => payment.details.controlCode)
                      .map((payment) => (
                        <>
                          <br />
                          NSU: {payment.details.controlCode} | Valor:{" "}
                          {currencyMask(payment.details.amountPaid)}
                        </>
                      ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ),
          });
        }

        setShowLoading(false);
      });

      clearCart();
      sessionStorage.removeItem("tableSelected");
      sessionStorage.removeItem("customersSelected");
    }
    navigate(PagesEnum.HOME);
    return;
  }, [isPaymentPage, clearCart]);

  const changeModule = useCallback(() => {
    if (userAuthData.isManager) {
      clearCart();
    }
    sessionStorage.removeItem("tableSelected");
    sessionStorage.removeItem("customersSelected");
    navigate(PagesEnum.HOME);
    return;
  }, [clearCart]);

  const handleGenerateMigration = () => {
    setIsOpenMenu(false);
    migration.generateMigration();
    clearCart();
  };

  const sections = [
    {
      title: "Operacional",
      items: hasItemsInCart
        ? [
            {
              text: "Cancelar operação",
              icon: <MdSettings className="material-icons icon" />,
              onClick: cancelOperation,
              action: "CANCEL_OPERATION",
              visible: true,
              needAuth: true,
              url: !tableSelected ? PagesEnum.HOME : PagesEnum.SALLER_TABLE,
            },
          ]
        : [
            {
              text: "Abrir Caixa",
              icon: <MdLaunch className="material-icons icon" />,
              onClick: async () => {
                setShowLoading();
                await getLastCashdesk()
                  .then(() => {
                    setShowLoading(false);
                  })
                  .catch((err) => {
                    setShowLoading(false);
                    console.error(err);
                  });
              },
              needAuth: true,
              visible: visibleCashOpen,
              url: PagesEnum.CASH_OPEN,
            },
            {
              text: "Fechar Caixa",
              icon: <MdDesktopWindows className="material-icons icon" />,
              onClick: async () => {
                setShowLoading();
                await getListCashDesk()
                  .then(() => {
                    setShowLoading(false);
                  })
                  .catch((err) => {
                    setShowLoading(false);
                    console.error(err);
                  });
              },
              // visible:  (chashierIsOpen || chashierIsReduce) && !order.hasActived,
              needAuth: true,
              visible: visibleCashClose || listReducedCashdesk.length,
              url: onCashierReductionOpenWithdrawingScreen
                ? PagesEnum.CASH_CLOSE_WITHDRAW
                : PagesEnum.CASH_CLOSE,
            },
            {
              text: "Redução de Caixa",
              icon: <MdDesktopWindows className="material-icons icon" />,
              onClick: () => {
                // opentAuthorizationWindow(3);
              },
              //needAuth: true,
              visible: visibleCashReduce,
              url: onCashierReductionOpenWithdrawingScreen
                ? PagesEnum.CASH_CLOSE_REDUCE
                : PagesEnum.CASH_REDUCE,
            },
            {
              text: "Encerramento do Dia",
              icon: <MdToday className="material-icons icon" />,
              // onClick: () => opentAuthorizationWindow(6),
              //needAuth: true,
              url: PagesEnum.HOME,
              params: { showCashierClosingDay: true },
              onClick: () => setShowCashierClosingDay(true),
              visible: visibleCashDayClose,
            },
            {
              text: "Sangria",
              icon: <MdRemoveCircle className="material-icons icon" />,
              onClick: () => {
                // opentAuthorizationWindow(4);
              },
              needAuth: true,
              url: PagesEnum.CASH_WITHDRAW,
              visible: visibleCashWithdraw,
            },
            {
              text: "Suprimento",
              icon: <MdAddCircle className="material-icons icon" />,
              onClick: () => {
                // opentAuthorizationWindow(5);
              },
              needAuth: true,
              url: PagesEnum.CASH_SUPPLY,
              visible: visibleCashSupply,
              // active: menu.cashSupplyShow,
            },
            {
              text: "Carro Forte",
              icon: <MdLocalShipping className="material-icons icon" />,
              needAuth: true,
              url: PagesEnum.CASH_ARMOURED_CAR,
              visible: visibleArmouredCar,
            },
            {
              text: "Reimpressão e Cancelamento TEF",
              icon: <MdPrint className="material-icons icon" />,
              needAuth: true,
              url: PagesEnum.MONITOR_TEF,
              visible: window.desktopApp || window.Android,
            },
            {
              text: "Monitor de Notas",
              icon: <MdReceipt className="material-icons icon" />,
              needAuth: true,
              url: PagesEnum.MONITOR_NOTES,
              visible: true,
            },
            {
              text: "Devolução de Vendas",
              icon: <MdReceipt className="material-icons icon" />,
              needAuth: true,
              url: PagesEnum.SALES_RETURN,
              visible: true,
            },
            {
              text: "Abrir Gaveta",
              icon: <MdLockOpen className="material-icons icon" />,
              needAuth: true,
              url: PagesEnum.HOME,
              onClick: () => {
                window.sessionStorage.setItem("draweropen", true);
                if (userAuthData.isManager) {
                  //Abre a gaveta
                  openDrawer();
                } else {
                  navigate(PagesEnum.NEED_AUTH, {
                    state: {
                      redirectUrl: PagesEnum.HOME,
                    },
                  });
                }
              },
              visible: isSmartPos ? false : window.desktopApp || window.Android,
            },
          ],
    },
    {
      title: "Relatórios",
      items: [
        {
          text: "Fechamento de Caixa",
          icon: <MdReceipt className="material-icons icon" />,
          url: PagesEnum.REPORT_CASHIER_CLOSING,
          visible: !isSmartPos,
          needAuth: true,
        },
        {
          text: "Vendas por Hora",
          icon: <MdReceipt className="material-icons icon" />,
          url: PagesEnum.SALES_HOURLY,
          visible: !isSmartPos,
          needAuth: true,
        },
        {
          text: "Vendas Diário",
          icon: <MdReceipt className="material-icons icon" />,
          url: PagesEnum.SALES_DAILY,
          visible: true,
          needAuth: true,
        },
        {
          text: "Itens Cancelados",
          icon: <MdReceipt className="material-icons icon" />,
          url: PagesEnum.CANCELED_ITEMS,
          visible: true,
          needAuth: true,
        },
      ],
    },
    {
      title: "Configurações",
      items: [
        {
          text: "Aparência",
          icon: <MdSettings className="material-icons icon" />,
          url: PagesEnum.SETTINGS,
          visible: !isSmartPos,
        },
        {
          text: "Impressora",
          icon: <MdPrint className="material-icons icon" />,
          url: PagesEnum.PRINTER_SETTINGS,
          visible: !!window?.Android,
        },
        {
          text: "MIDe",
          icon: <MdSettings className="material-icons icon" />,
          url: PagesEnum.MIDE_SETTINGS,
          visible: true,
        },
        {
          text: "TEF",
          icon: <MdSettings className="material-icons icon" />,
          url: PagesEnum.TEF_SETTINGS,
          needAuth: true,
          onClick: getInfoTef,
          visible: !!window?.Android,
        },
        {
          text: "Série e Numeração NFC-e",
          icon: <MdSettings className="material-icons icon" />,
          url: PagesEnum.SERIES_NFCE_CONFIG,
          visible: true,
        },
        {
          text: "Produto Indisponível",
          icon: <MdSettings className="material-icons icon" />,
          needAuth: true,
          url: PagesEnum.PRODUCTS_UNAVAILABLE,
          visible: true,
        },
        {
          text: "Salvar Logs",
          icon: <FaClipboardList className="material-icons icon" />,
          url: PagesEnum.SAVE_LOGS,
          visible: !!window?.Android || isSmartPos,
        },
      ],
    },
    {
      title: "Sistema",
      items: [
        {
          text: "Trocar Usuário",
          icon: <MdRefresh className="material-icons icon" />,
          url: PagesEnum.LOGIN,
          visible: true,
        },
        {
          text: "Atualizar cardápio e configurações",
          icon: <WiCloudRefresh className="material-icons icon" />,
          isPreventClose: true,
          onClick: handleGenerateMigration,
          visible: true,
        },
        {
          text: "Minimizar Aplicação",
          icon: <MdMinimize className="material-icons icon" />,
          onClick: () => {
            window.desktopApp.minimize();
          },
          visible: window.desktopApp,
        },
        {
          text: "Fechar Aplicação",
          icon: <MdClose className="material-icons icon" />,
          onClick: () => {
            window.desktopApp.close();
          },
          visible: window.desktopApp,
        },
      ],
    },
  ];

  useEffect(() => {
    if (hasLocalPayments() && !hasItemsInCart) {
      clearLocalPayments();
      navigate(PagesEnum.HOME);
    }
  }, [location, hasItemsInCart]);

  if (!isPaymentPage && hasLocalPayments()) {
    setVisibleSidebarCart(true);

    return <Navigate to={PagesEnum.PAYMENT_METHODS} replace />;
  }

  return (
    <>
      {!hasItemsInCart && !isKiosk && (
        <div className="content-type-sale">
          <div
            className="type-of-sale balcao"
            onClick={() => {
              navigate(PagesEnum.HOME);
              setIsOpenMenu(false);
            }}
          >
            Venda Balcão
            <MdRoomService size="1.5rem" />
          </div>
          <div
            className="type-of-sale"
            onClick={() => {
              if (!isKiosk) {
                navigate(PagesEnum.SALLER_TABLE);
                setIsOpenMenu(false);
              }
            }}
          >
            Venda Mesa
            <MdTableRestaurant size="1.5rem" />
          </div>
        </div>
      )}
      {sections.map((section, sectionIndex) => {
        const currentIDSection = `radio${sectionIndex}`;

        return (
          <MenuSection key={`menu-section-${String(sectionIndex)}`}>
            <ToggleMenu
              type="checkbox"
              name="menu"
              id={currentIDSection}
              checked={currentIDSection === openedMenu}
              onChange={() => setOpenedMenu(currentIDSection)}
            />
            <label className="title" htmlFor={currentIDSection}>
              <span>{section.title}</span>
              <MdExpandMore className="material-icons" />
            </label>

            {section.items
              .filter((item) => item.visible)
              .map((item, itemSectionIndex) => (
                <EachSectionContainer key={`menu-item-${itemSectionIndex}`}>
                  <Button
                    type="link"
                    onClick={() => {
                      handleLink(item);
                    }}
                  >
                    {item.icon}
                    {item.text}
                  </Button>
                </EachSectionContainer>
              ))}
          </MenuSection>
        );
      })}
    </>
  );
};

export default MenuSections;
