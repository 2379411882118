import styled from "styled-components";

const ModalWrapper = styled.section`
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -60%);

  .modal {
    text-align: center;
    padding: 1em;
    border-radius: 1.25em;
    box-sizing: border-box;
    background-color: var(--color-background-modal);

    .suggestion {
      h1 {
        font-size: 3.4em;
        color: var(--color-title-category);
        margin-bottom: 0;
      }
      h2 {
        margin-top: 0;
        color: var(--color-subtitle);
      }
    }

    .subtotal {
      font-size: 2.625rem;
      margin-bottom: 0;
      color: var(--color-text-flavor-list);

      strong {
        color: var(--color-title-category);
      }
    }

    .image-sugesstion,
    img {
      line-height: 245px;
      height: 330px;
      width: 6200px;
      margin: 2em auto;
      border-radius: 20px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .no-image,
    img {
      line-height: 245px;
      height: 330px;
      width: 620px;
      margin: 2em auto;
      border-radius: 20px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .title {
      color: var(--color-text-flavor-list);
      font-size: 2.5rem;
      font-weight: 100;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .subtitle {
      color: var(--color-title-category);
      font-size: 30px;
      margin-top: 0;
    }

    .description {
      color: var(--color-text-flavor-list);
      word-break: break-word;
      font-size: 1.5rem;
      text-align: left;
    }

    .buttons-container {
      display: flex;

      button {
        outline: none;
        color: var(--color-title-category);
        flex-grow: 1;
        flex-shrink: 0;
        margin: 1em 0.4em;
      }
    }

    .button-count {
      outline: none;
      color: var(--color-background-progress-bar);
      font-size: 5rem;
      width: auto;
      padding: 0.2em 1.1em;
      margin: 70px 10px;
      position: relative;
      top: -20px;
      background-color: var(--color-background-cart);
      border-radius: 20px;
    }

    .button-accelerators {
      color: var(--color-background-cart);
      background: #ffe4e1;
      font-size: 2rem;
      height: 60px;
      width: -webkit-fill-available;
      padding: 0.2em 1.1em;
      position: relative;
      top: -30px;
      border: 2px solid var(--color-background-cart);
      border-radius: 30px;
      margin: 0 10px;
      outline: none;
    }

    .counter {
      color: var(--color-text-flavor-list);
      border-bottom: 3px solid #f3f1f1;

      .quantity {
        display: inline-block;
        font-size: 125px;
        font-weight: 700;
        min-width: 180px;
      }
    }

    .accelerators {
      display: flex;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;

export const NivelProductWrapper = styled.section`
  background-color: var(--color-background-modal);

  height: 65%;
  width: 95%;

  transform: translate(-50%, 0);
  position: absolute;

  z-index: 3;
  left: 50%;
  top: 8%;

  border-radius: 1.25em;
  padding: 2rem 0 2px;
  overflow: hidden;

  .step-title {
    padding: 0 3rem;
  }

  .modal {
    height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .button-container {
      margin: 2rem;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const ContentFigure = styled.div`
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  margin-bottom: 1rem;
  overflow: auto;
  flex: 1;

  position: relative;
  vertical-align: middle;
  align-items: flex-start;
  align-content: flex-start;

  &::-webkit-scrollbar-track {
    background-color: var(--color-background-light);
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.8);
  }

  .figure-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default ModalWrapper;
