import React, { useState, useEffect, useCallback } from "react";
import { showFullValue } from "../../../configuration";
import { currency } from "../../utils/currency";

import FlavorSelectListWrapper from "./style";

const FlavorSelectList = ({
  listProducts,
  maxQuantity,
  onChangeValue,
  required,
}) => {
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [items, setItems] = useState([]);
  const [proceed, setProceed] = useState(false);
  const [startAutoIncrement, setStartAutoIncrement] = useState(false);

  useEffect(() => {
    const items = listProducts.map((product) => ({
      ...product,
      qtd: product.qtd || 0,
    }));
    setItems(items);

    const quantity = items
      .filter((product) => product.qtd > 0)
      .reduce((acc, cur) => (acc = acc + cur.qtd), 0);

    setSelectedQuantity(quantity);
  }, [listProducts]);

  useEffect(() => {
    setProceed(selectedQuantity === maxQuantity);
  }, [selectedQuantity, maxQuantity]);

  useEffect(() => {
    onChangeValue(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuantity]);

  useEffect(() => {
    onChangeValue(proceed ? items : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceed]);

  const sumItem = useCallback(
    (index) => {
      if (selectedQuantity >= maxQuantity) return;

      const obj = items;
      obj[index]["qtd"] = items[index].qtd + 1;

      setItems(obj);
      setSelectedQuantity(selectedQuantity + 1);
      setProceed(selectedQuantity === maxQuantity - 1);
    },
    [items, maxQuantity, selectedQuantity]
  );

  const subItem = useCallback(
    (index) => {
      const obj = items;

      if (obj[index].qtd - 1 === -1) {
        return;
      } else {
        obj[index]["qtd"] = obj[index].qtd > 0 ? obj[index].qtd - 1 : 0;
      }

      setItems(obj);
      setSelectedQuantity(selectedQuantity > 0 ? selectedQuantity - 1 : 0);
      setProceed(selectedQuantity - 1 === maxQuantity);
    },
    [items, maxQuantity, selectedQuantity]
  );

  useEffect(() => {
    if (items && items.length === 1 && required && !startAutoIncrement) {
      setStartAutoIncrement(true);

      for (let i = 0; i < maxQuantity; i++) {
        if (i < maxQuantity) {
          sumItem(0);
        }
      }
    }
  }, [items, maxQuantity, required, sumItem, startAutoIncrement]);

  return (
    <>
      <div className="flavor-select-quantity">
        <li className="flavor-select-list-info">
          <strong>Escolha {maxQuantity} itens</strong>
          <div className={!proceed ? "no-proceed" : "proceed"}>
            <strong>{selectedQuantity}</strong> de{" "}
            <strong>{maxQuantity}</strong>
            {!proceed ? (
              <span className="required">
                {required ? "Obrigatório" : "Opcional"}
              </span>
            ) : (
              <span className="no-required">✔</span>
            )}
          </div>
        </li>
      </div>
      <FlavorSelectListWrapper className="background-flavor-select-list flavor-select-list">
        {items.map((item, index) => {
          if (item.vit_numlanctoprincipal) return;

          return (
            <li key={`${item.id}-${index}`}>
              {item.image !== null ? (
                <img
                  className="flavor-select-list-image"
                  src={item.image}
                  alt={item.name}
                />
              ) : (
                <div className="no-image-combo" />
              )}

              <div className="flavor-select-list-item">
                <p className="flavor-select-list-item-name">
                  {item.description}{" "}
                  {showFullValue && +item["mixValue"] > 0
                    ? currency(item["mixValue"])
                    : ""}
                </p>
              </div>

              <div className="flavor-select-list-action">
                <button type="button" onClick={() => subItem(index)}>
                  -
                </button>

                <span className="quantity">{item.qtd}</span>

                <button type="button" onClick={() => sumItem(index)}>
                  +
                </button>
              </div>
            </li>
          );
        })}
      </FlavorSelectListWrapper>
    </>
  );
};

export default React.memo(FlavorSelectList);
