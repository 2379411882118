import React, { useState, useMemo, useCallback } from "react";

import { SelectorWrapper, SelectorList, SelectorListItem } from "./style";

import CloseButton from "../close-button";
import classNames from "classnames";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { isSmartPos } from "../../../aaone/configuration";

const Selector = ({
  items,
  selectText,
  onSelect = () => {},
  isLogin,
  openTop,
  defaultSelected,
}) => {
  const [visible, toggleOptionsVisibility] = useState(false);
  const [selected, selectItem] = useState(
    items.filter((item) => item.id === defaultSelected)?.[0] ?? {
      code: "",
      name: "",
    }
  );
  const [list, setList] = useState([]);
  const [textModel, setText] = useState("");
  const [hasScroll, setScroll] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  /**
   * Envia o usuário da Linx para a ultima posição
   */
  const sendLinxUserToLastIndex = (items) => {
    const linxUserIndex = items.findIndex((item) => item.personCode === 99999);
    const linxUser = items[linxUserIndex];

    if (linxUser) {
      items.splice(linxUserIndex, 1);
      items.push(linxUser);
    }

    return items;
  };

  const changeSelectedItemAndCloseOptionList = useCallback(
    (item) => {
      selectItem(item);
      toggleOptionsVisibility(false);
      onSelect(item);
    },
    [onSelect]
  );

  const scroll = (isTop) => {
    const scrollElement = document.querySelector(`#selector`);
    const top = isTop
      ? scrollElement.scrollTop - 30
      : scrollElement.scrollTop + 30;

    scrollElement.scrollTo(0, top);
  };

  const onChangeInput = (event) => {
    let input = event.target.value;

    setText(input);
  };

  /**
   * Cria e filtra a lista de usuários
   */
  const UsersList = useMemo(() => {
    const _list = sendLinxUserToLastIndex(items).filter((item) => {
      if (isLogin && textModel !== "") {
        return (
          item.login.toLowerCase().includes(textModel) ||
          item.personCode.toString().includes(textModel)
        );
      }
      return item;
    });
    const hasScroll = _list.length > 3;

    setScroll(hasScroll);
    setList(_list);

    return _list.map((item, index) => (
      <SelectorListItem
        onClick={() => changeSelectedItemAndCloseOptionList(item)}
        key={item.personCode || `${item.name}_${index}`}
        className={classNames({
          hasScroll: hasScroll,
          smartPos: isSmartPos,
        })}
      >
        <span className={item.personCode ? "code" : ""}>{item.personCode}</span>
        {item.login}
        {item.active && <span className="status">Ativo</span>}
      </SelectorListItem>
    ));
  }, [changeSelectedItemAndCloseOptionList, isLogin, items, textModel]);

  return (
    <>
      <SelectorWrapper className="angle-down">
        <div
          className="selected-item"
          onClick={() => toggleOptionsVisibility(true)}
        >
          {selected?.personCode} {selected?.login}
        </div>
        {visible && hasScroll && !keyboardVisible && !isSmartPos && (
          <button
            type="button"
            className={classNames(
              {
                "btn_scroll--open_top": openTop,
              },
              "btn_scroll btn_scroll--top"
            )}
            onClick={() => scroll(true)}
          >
            <MdExpandLess size="1.5rem" />
          </button>
        )}
        <SelectorList
          id="selector"
          className={openTop ? "selector_scroll--top" : ""}
          visible={visible}
          text={selectText}
        >
          <CloseButton onClick={() => toggleOptionsVisibility(false)} />
          <div>
            {isLogin && (
              <SelectorListItem
                key={"input"}
                className={classNames({
                  hasScroll: hasScroll,
                  smartPos: isSmartPos,
                })}
                style={{
                  borderColor:
                    !!textModel && list.length === 0 ? "#da482c" : "#EBEBEB",
                }}
              >
                <input
                  data-use-touch-keyboard
                  className="input-filter"
                  type="text"
                  value={textModel}
                  onChange={(e) => onChangeInput(e)}
                  placeholder={"Digite o código ou nome para filtrar..."}
                />
              </SelectorListItem>
            )}
            {UsersList}
            <div
              className="backdrop"
              onClick={() => toggleOptionsVisibility(false)}
            />
          </div>
        </SelectorList>
        {visible && hasScroll && !keyboardVisible && !isSmartPos && (
          <button
            type="button"
            className={classNames(
              {
                "btn_scroll--open_bottom": openTop,
              },
              "btn_scroll btn_scroll--bottom"
            )}
            onClick={() => scroll(false)}
          >
            <MdExpandMore size="1.5rem" />
          </button>
        )}
      </SelectorWrapper>
    </>
  );
};

export default Selector;
