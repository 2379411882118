import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { useCart } from "../../../hook/cartHook";
import { useLocation, useNavigate } from "react-router-dom";
import { Actions, Container, Observation, ProductContainer } from "./styles";
import { useAuth } from "../../../hook/authHook";
import {
  MdDeleteForever,
  MdOutlineAssignment,
  MdOutlineMoreVert,
  MdRefresh,
} from "react-icons/md";
import { PagesEnum } from "../../../constants/pagesEnum";
import { currencyMask } from "../../../helpers/masks";
import { useDialog } from "../../../hook/dialogHook";
import Button from "../../Button";
import { KeyboardProvider } from "../../../contexts/KeyboardContext";
import Decimal from "decimal.js";

export const ItemInCart = ({
  item,
  index,
  totalItemsInCart,
  handleGoToStep,
  currentStep,
  contentCartRef,
}) => {
  const {
    currentProductStep,
    setCurrentProductStep,
    removeProductFromCart,
    setCurrentActions,
    setQuantityToCart,
    duplicateProductInCart,
    redirectCategoryProduct,
  } = useCart();
  const navigate = useNavigate();
  const { userAuthData } = useAuth();
  const [step, setStep] = useState({});
  const [showAction, setShowAction] = useState(false);
  const [actionMenuIsOnTop, setActionMenuIsOnTop] = useState(false);
  const [showObservation, setShowObservation] = useState(false);
  const [productPage, setProductPage] = useState(1);
  const observationRef = useRef();
  const location = useLocation();
  const { showAlert } = useDialog();

  const isPaymentPage = location.pathname.startsWith("/payment-");
  const paymentAlertMessage = (typeMessage = "messageRemove") => {
    const messages = {
      messageRemove:
        "A ação de remover um item não é permitida durante o pagamento de uma venda!",
      messageDuplicateProduct:
        "A ação de duplicar um item não é permitida durante o pagamento de uma venda!",
    };
    showAlert({
      message: messages[typeMessage],
    });
  };

  const changeStepsAndProduct = useCallback(
    (sale) => {
      setStep(sale.getStepsItem(item));
      setCurrentProductStep(sale.getCurrentSaleStep()?.products);
    },
    [item.ClassSale]
  );

  const nextStep = useCallback(() => {
    if (item.ClassSaleStep !== null) {
      const doneStepsItemSelected = item.ClassSale.readySale();

      if (!doneStepsItemSelected) {
        item.ClassSale.goToNextStep(item);
        changeStepsAndProduct(item.ClassSale);

        if (item.ClassSale.getCurrentSaleStep()?.products) {
          selectProductWithStepWithOnlyOneItem(
            item.ClassSale.getCurrentSaleStep()?.products
          );
        }
      } else {
        redirectCategoryProduct(item);
      }
    }
  }, [changeStepsAndProduct, item, item.ClassSale]);

  const backStep = useCallback(() => {
    if (item.ClassSaleStep !== null) {
      item.ClassSaleStep.backStep();
      changeStepsAndProduct(item.ClassSale);
    }
  }, [changeStepsAndProduct, item.ClassSaleStep]);

  const goToStep = useCallback(
    (sequence) => {
      item.ClassSale.goToStep(item, sequence);
      changeStepsAndProduct(item.ClassSale);

      selectProductWithStepWithOnlyOneItem(
        item.ClassSaleStep.getCurrentStep()?.products
      );
    },
    [changeStepsAndProduct, handleGoToStep, item]
  );

  const getCurrentStep = () => currentStep();

  const deselectProductStep = (sequence, product) => {
    if (isPaymentPage) {
      paymentAlertMessage();
      return;
    }

    if (item.ClassSaleStep !== null) {
      item.ClassSaleStep.deselectProductStep(sequence, product);
      changeStepsAndProduct(item.ClassSale);
    }
  };

  const handleDuplicateProductInCart = (product) => {
    if (isPaymentPage) {
      paymentAlertMessage("messageDuplicateProduct");
      return;
    }

    duplicateProductInCart(product);
    redirectCategoryProduct(product);
  };

  const setObservationParent = (observation) => {
    item.ClassSaleStep.setObservation(observation);
    changeStepsAndProduct(item.ClassSale);
  };

  const setObservationProductStep = (product, observation) => {
    product.orientedSaleClass.setObservation(product, observation);
  };

  const nextProduct = (product, amount = 1) => {
    if (product?.value === 0 && !product?.noSalesEffect) {
      // Quando for pela modal é só data
      showAlert({
        message: "Não é permitido registrar produto com valor zero. (R$ 0.00)",
      });
      return;
    }

    const ClassSaleStep =
      product.orientedSaleStepClass.getCurrentStep().classSale;
    const ClassOrientedSale = product.orientedSaleClass;
    ClassOrientedSale.next(product.productCode);

    if (ClassOrientedSale.getCurrentProducts().length) {
      setCurrentProductStep(ClassOrientedSale.getCurrentProducts());
      setProductPage(productPage + 1);
    } else {
      if (product?.value !== 0 || product?.noSalesEffect) {
        const currentStep = product.orientedSaleStepClass.getCurrentStep();
        if (
          currentStep?.quantity === 1 &&
          currentStep?.quantitySelected === 1
        ) {
          const [firstProduct] = currentStep.selected;
          item.ClassSaleStep.deselectProductStep(
            currentStep.sequence,
            firstProduct
          );
        }
        const calculateRemainingAmount =
          currentStep?.quantity - currentStep?.quantitySelected;

        if (
          item?.ClassSaleStep?.getCurrentStep()?.products.length === 1 &&
          item?.ClassSaleStep?.getCurrentStep().required
        ) {
          amount = calculateRemainingAmount;
        }

        ClassSaleStep.selectedProductCurrentStep(product, amount);
        setCurrentProductStep(ClassOrientedSale.getCurrentProducts());
        setQuantityToCart("reload");
        if (
          product?.composition === false &&
          currentStep?.quantity === currentStep?.quantitySelected
        ) {
          nextStep();
        }
      } else {
        showAlert({
          message:
            "Não é permitido registrar produto com valor zero. (R$ 0.00)",
        });
      }
    }
  };

  const selectProductWithStepWithOnlyOneItem = useCallback(
    (products) => {
      if (
        !item.ClassSaleStep.checkDoneSteps() &&
        products?.length === 1 &&
        item?.ClassSale?.getCurrentSaleStep().required
      ) {
        let product = products[0];
        const currentStep = product.orientedSaleStepClass.getCurrentStep();

        let amount = 1;
        if (currentStep && !currentStep.classSale) {
          const calculateRemainingAmount =
            currentStep?.quantity - currentStep?.quantitySelected;

          if (calculateRemainingAmount > 0) {
            amount = calculateRemainingAmount;
          }
        }

        nextProduct(product, amount);
      }
    },
    [item, step.steps]
  );

  useEffect(() => {
    let products = item.ClassSaleStep.getCurrentStep()?.products;
    if (productPage !== 1) {
      products = currentProductStep;
    }

    selectProductWithStepWithOnlyOneItem(products);
  }, [step.steps, productPage]);

  useEffect(() => {
    const products = item.ClassSaleStep.getCurrentStep()?.products;
    selectProductWithStepWithOnlyOneItem(products);
  }, []);

  const deselectProduct = (orientedSaleClass, product) => {
    orientedSaleClass.deselectProduct(product);
    item.ClassSaleStep.selectedProductCurrentStep(product);
    setCurrentProductStep(orientedSaleClass.getCurrentProducts());
  };

  const checkAndRemoveProductFromCart = useCallback(
    (product, isFidelity) => {
      if (isPaymentPage) {
        paymentAlertMessage();
        return;
      }
      if (userAuthData?.isManager) {
        removeProductFromCart(product, isFidelity);
      } else if (!userAuthData?.isManager) {
        navigate(PagesEnum.NEED_AUTH, {
          state: {
            isFidelity,
            redirectUrl: "/home",
            action: "removeItem",
            ItemRemoveSelect: index,
          },
        });
        return;
      }
    },
    [location, paymentAlertMessage, showAlert]
  );

  const backProduct = (orientedSaleClass) => {
    orientedSaleClass.back();
    setCurrentProductStep(orientedSaleClass.getCurrentProducts());
  };

  const listProduct = useCallback(
    (currentProductStep) => {
      const products = [];
      currentProductStep.selected.forEach((product, index) => {
        products.push(
          <ListProduct
            item={item}
            key={`sub__${index}__${product.productCode}`}
            product={product}
            currentProductStep={currentProductStep}
            deselectProductStep={deselectProductStep}
            setObservationProductStep={setObservationProductStep}
          />
        );
      });

      return products;
    },
    [deselectProductStep, item.ClassSaleStep]
  );

  const actions = {
    nextStep,
    backStep,
    goToStep,
    changeStepsAndProduct,
    getCurrentStep,
    deselectProductStep,
    nextProduct,
    deselectProduct,
    backProduct,
  };

  const isSingleProduct = !(step?.steps?.length > 0);

  const SubProducts = useCallback(() => {
    return (
      !isSingleProduct && (
        <>
          {step.steps.map((currentProductStep, index) => (
            <Fragment key={String(`subproduct__${index}-index`)}>
              <Fragment>
                {currentProductStep.quantitySelected <
                  currentProductStep.quantity && (
                  <ProductContainer
                    className={classNames(
                      {
                        "is-current": !!currentProductStep.current,
                      },
                      "select-product"
                    )}
                    onClick={() => {
                      window.oldCurrentProductStepContainer =
                        new Date().getTime();
                      goToStep(currentProductStep.sequence);
                    }}
                    role="button"
                  >
                    <div className="product-description">
                      <div>{currentProductStep.description}</div>
                      <div className="product-info">
                        {currentProductStep.quantity -
                          currentProductStep.quantitySelected}
                        x
                      </div>
                      <div
                        className={classNames(
                          {
                            required: currentProductStep.required,
                            optional: !currentProductStep.required,
                          },
                          "product-requirement"
                        )}
                      />
                    </div>
                  </ProductContainer>
                )}
              </Fragment>
              <div>
                {currentProductStep.composition === false &&
                  !!currentProductStep.selected.length &&
                  listProduct(currentProductStep)}
              </div>
            </Fragment>
          ))}
        </>
      )
    );
  }, [goToStep, isSingleProduct, listProduct, step.steps]);

  useEffect(() => {
    setCurrentActions(actions);
  }, [step]);

  useEffect(() => {
    changeStepsAndProduct(item.ClassSale);
  }, [item.ClassSale]);

  useEffect(() => {
    if (item.automaticSwitchToCurrentItem) {
      goToStep(1);
    }
  }, [item.automaticSwitchToCurrentItem]);

  useEffect(() => {
    if (contentCartRef?.current && showAction) {
      const elementRect = contentCartRef?.current?.getBoundingClientRect();
      const actionWrapperElement = document
        .querySelector(".action-wrapper")
        ?.getBoundingClientRect();

      //NOTE - Verificar se o elemento .action-wrapper está visível no conteúdo do carrinho (contentCartRef)
      const isElementFullyVisible =
        actionWrapperElement.bottom <= elementRect.bottom;

      setTimeout(() => {
        if (contentCartRef.current && !isElementFullyVisible) {
          contentCartRef.current.scrollTop =
            contentCartRef.current?.scrollHeight;
        }
      }, 200);
    }
  }, [contentCartRef, showAction]);

  return (
    <>
      <Container className={classNames({ single__product: isSingleProduct })}>
        <ProductContainer>
          <div className="product-description">
            <div>{step.description}</div>
            <div className="product-info">
              <span>
                {step?.pointPrice ? (
                  `Resgate fidelidade - ${step.pointPrice * item.quantity}pts`
                ) : (
                  <>
                    {step.fractionalQuantity ? (
                      <>{new Decimal(item.quantity).toFixed(3)} KG</>
                    ) : (
                      <>{item.quantity} UN</>
                    )}{" "}
                    x {currencyMask(step.value)}
                  </>
                )}
              </span>
              <div className="product-value">
                {step?.pointPrice
                  ? `${step?.pointPrice * item.quantity}pts`
                  : currencyMask(step.value * item.quantity)}
              </div>
            </div>
            {step.observation !== "" && (
              <div>
                {"> "}OBS: {step.observation}
              </div>
            )}
          </div>

          <div
            role="button"
            onClick={(el) => {
              setShowAction(true);

              const cartItems = document.querySelector(".productsCart");
              const maxHeight = cartItems.getBoundingClientRect().height;

              const iconPosition = el.target.getBoundingClientRect();

              if (iconPosition.y + iconPosition.height + 100 + 32 < maxHeight)
                return;

              setActionMenuIsOnTop(true);
            }}
            className="button__remove"
          >
            <MdOutlineMoreVert />
          </div>
        </ProductContainer>

        {showObservation && (
          <KeyboardProvider>
            <Observation>
              <div
                className={classNames(
                  { bottom: actionMenuIsOnTop },
                  "observation-wrapper"
                )}
              >
                <span>{step.description}</span>
                <textarea
                  data-use-touch-keyboard
                  ref={observationRef}
                  defaultValue={step.observation}
                  cols="60"
                  rows="10"
                />

                <div className="action">
                  <Button
                    className="button"
                    onClick={() => {
                      setShowObservation(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className="button btn-primary"
                    onClick={() => {
                      setShowObservation(false);
                      setObservationParent(observationRef.current.value);
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </Observation>
          </KeyboardProvider>
        )}

        {showAction && (
          <Actions>
            <div
              className={classNames(
                { bottom: actionMenuIsOnTop },
                "action-wrapper"
              )}
            >
              {!item.ClassSaleStep.getSteps().categoryIsFidelity &&
                !item.ClassSaleStep.getSteps()?.fractionalQuantity && (
                  <div
                    className="action"
                    role="button"
                    onClick={() => handleDuplicateProductInCart(item)}
                  >
                    <MdRefresh className="isPurple" />
                    <span>Repetir</span>
                  </div>
                )}
              <div
                className="action"
                role="button"
                onClick={() => {
                  setShowAction(false);
                  setShowObservation(true);
                }}
              >
                <MdOutlineAssignment className="isPurple" />
                <span>Observação</span>
              </div>
              <div
                className="action"
                role="button"
                onClick={() =>
                  checkAndRemoveProductFromCart(
                    item,
                    (step?.pointPrice && totalItemsInCart === 1) || false
                  )
                }
              >
                <MdDeleteForever className="isRed" />
                <span>Remover</span>
              </div>
            </div>
            <div onClick={() => setShowAction(false)} className="overlay" />
          </Actions>
        )}
        <SubProducts />
      </Container>
    </>
  );
};

const ListProduct = ({
  item,
  product,
  deselectProductStep,
  currentProductStep,
  setObservationProductStep,
}) => {
  const listProduct = useCallback(
    (step) => {
      const products = [];
      step.selected.forEach((product, index) => {
        products.push(
          <ListProduct
            item={item}
            key={`sub__${index}__${product.productCode}`}
            product={product}
            currentProductStep={step}
            deselectProductStep={deselectProductStep}
            setObservationProductStep={setObservationProductStep}
          />
        );
      });

      return products;
    },
    [deselectProductStep, item.ClassSaleStep]
  );

  const { Sale, setCurrentProductStep } = useCart();

  const goToStep = useCallback(
    (sequence, product) => {
      Sale.goToStep(item, sequence);
      setCurrentProductStep(product.getCurrentStep()?.products);
    },
    [item]
  );

  const [showAction, setShowAction] = useState(false);
  const [actionMenuIsOnTop, setActionMenuIsOnTop] = useState(false);
  const [showObservation, setShowObservation] = useState(false);
  const observationRef = useRef();

  return (
    <>
      <ProductContainer className="children-product">
        <div className="product-description">
          <div>{product.description}</div>

          <div className="product-info">
            {product.amount} UNs x {currencyMask(product.value)}
            <div className="product-value">
              {currencyMask(product.value * product.amount)}
            </div>
          </div>
          {product.observation !== "" && (
            <div>
              {"> "}OBS: {product.observation}
            </div>
          )}
        </div>
        <div
          role="button"
          onClick={(el) => {
            setShowAction(true);
            const cartItems = document.querySelector(".productsCart");
            const maxHeight = cartItems.getBoundingClientRect().height;

            const iconPosition = el.target.getBoundingClientRect();

            if (iconPosition.y + iconPosition.height + 100 + 32 < maxHeight)
              return;

            setActionMenuIsOnTop(true);
          }}
          className="button__remove"
        >
          <MdOutlineMoreVert />
        </div>
        {showAction && (
          <Actions>
            <div
              className={classNames(
                { bottom: actionMenuIsOnTop },
                "action-wrapper"
              )}
            >
              <div
                className="action"
                role="button"
                onClick={() => {
                  setShowAction(false);
                  setShowObservation(true);
                }}
              >
                <MdOutlineAssignment className="isPurple" />
                <span>Observação</span>
              </div>
              <div
                className="action"
                role="button"
                onClick={() => {
                  setShowAction(false);

                  if (product.amount === 1) {
                    setObservationProductStep(product, "");
                  }
                  deselectProductStep(currentProductStep.sequence, product);
                }}
              >
                <MdDeleteForever className="isRed" />
                <span>Remover</span>
              </div>
            </div>
            <div onClick={() => setShowAction(false)} className="overlay" />
          </Actions>
        )}
      </ProductContainer>
      {product.hasOrientedSale && (
        <div style={{ marginTop: "11px", marginLeft: 0, width: "100%" }}>
          {product.ClassSaleStep.getSteps().steps.map((step, indexStep) => {
            return (
              <>
                <Fragment key={String(`subproduct-step${indexStep}-index`)}>
                  {step.quantitySelected < step.quantity && (
                    <ProductContainer
                      className={classNames(
                        {
                          "is-current": !!step.current,
                        },
                        "select-product"
                      )}
                      onClick={() => {
                        window.oldCurrentProductStepContainer =
                          new Date().getTime();
                        goToStep(step.sequence, product.ClassSaleStep);
                      }}
                      role="button"
                    >
                      <div className="product-description">
                        <div>{step.description}</div>
                        <div className="product-info">
                          {step.quantity - step.quantitySelected}x
                        </div>
                        <div
                          className={classNames(
                            {
                              required: step.required,
                              optional: !step.required,
                            },
                            "product-requirement"
                          )}
                        />
                      </div>
                    </ProductContainer>
                  )}
                </Fragment>
                <div>
                  {step.composition === false &&
                    !!step.selected.length &&
                    listProduct(step)}
                </div>
              </>
            );
          })}
        </div>
      )}
      {showObservation && (
        <KeyboardProvider>
          <Observation>
            <div
              className={classNames(
                { bottom: actionMenuIsOnTop },
                "observation-wrapper"
              )}
            >
              <span>{product.description}</span>
              <textarea
                data-use-touch-keyboard
                defaultValue={product.observation}
                ref={observationRef}
                cols="60"
                rows="10"
              />

              <div className="action">
                <Button
                  className="button"
                  onClick={() => {
                    setShowObservation(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  className="button btn-primary"
                  onClick={() => {
                    setShowObservation(false);
                    setObservationProductStep(
                      product,
                      observationRef.current.value
                    );
                  }}
                >
                  Salvar
                </Button>
              </div>
            </div>
          </Observation>
        </KeyboardProvider>
      )}
    </>
  );
};
