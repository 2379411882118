import React, { useState } from "react";
import ReportCashierClosing from "../../../components/reports/cashier-closing/report-cashier-closing";
import { listCashdeskByPeriodService } from "../../../../services/cashdeskService";
import { formatDateHelper } from "../../../helpers/formatDateHelper";
import { printCloseReport } from "../../../helpers/printer/closeReport";
import { getCloseCashDeskValues } from "../../../../services/cashdeskService";
import { printerActionService } from "../../../../services/actions/printerActionService";
import { useLoading } from "../../../hook/loadingHook";
import { endOfDay, startOfDay } from "date-fns";
import { getCashDeskClosingPrinter } from "../../../../services/printerService";
import { printDefault } from "../../../helpers/printer/printDefault";

const ReportCashierClosingContainer = (props) => {
  const { setShowLoading } = useLoading();
  const [pos, setPos] = useState([]);
  const [reportContent, setReportContent] = useState("");

  const handleClose = (msg, payload) => {
    setReportContent("");
    setPos([]);
    if (!payload?.frontParams || !payload.frontParams.backgroundProcess) {
      props.reportCashierClosingShow(false);
      props.onCartShow();
    }
  };

  const handleViewReport = async (cashdesk) => {
    try {
      setShowLoading();

      getCloseCashDeskValues(cashdesk.id).then((res) => {
        getCashDeskClosingPrinter({
          closeCashdeskValues: res,
          cashdesk,
          getHTML: true,
        }).then((data) => {
          setReportContent(data);

          setShowLoading(false);
        });
      });


    } catch (err) {
      console.error(err);
    }
  };

  const printCashierClosing = async (cashdesk) => {
    try {
      if (cashdesk) {
        setShowLoading();
        getCloseCashDeskValues(cashdesk.id).then((res) => {
          getCashDeskClosingPrinter({
            closeCashdeskValues: res,
            cashdesk,
          }).then((data) => {
            if (window.desktopApp) {
              printerActionService({
                body: data,
              });
            } else if (window.Android) {
              console.log(`printDefault | {}`);
              printDefault(data);
            }

            setShowLoading(false);
          });
        });
      } else {
        if (window.desktopApp && reportContent) {
          printerActionService({
            body: reportContent,
          });
        }
      }
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  };

  const handleCloseComponent = () => {
    setReportContent("");
    setPos([]);
  };

  const handleChange = async (param) => {
    try {
      setShowLoading();

      const date = formatDateHelper(new Date(param), "yyyy/MM/dd");

      const cashDesks = await listCashdeskByPeriodService({
        initialDate: date,
        finalDate: date,
      });

      const cashierClosing = cashDesks
        .filter(
          (cashdesk) =>
            cashdesk.closingDate !== null &&
            startOfDay(new Date(cashdesk.openingDate)) >=
              startOfDay(new Date(param))
        )
        .map((x) => {
          return {
            ...x,
            openingDateFormatted: formatDateHelper(
              new Date(x.openingDate),
              "dd/MM/yyyy HH:mm"
            ),
            closingDateFormatted: formatDateHelper(
              new Date(x.closingDate),
              "dd/MM/yyyy HH:mm"
            ),
          };
        });

      setPos(cashierClosing);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <ReportCashierClosing
      handleClose={handleClose}
      onChangeDate={handleChange}
      posList={pos}
      printCashier={printCashierClosing}
      handleViewReport={handleViewReport}
      reportContent={reportContent}
      handleCloseComponent={handleCloseComponent}
      handleCloseReport={() => setReportContent("")}
    />
  );
};

export default ReportCashierClosingContainer;
