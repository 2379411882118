import React from "react";
import { ProductLevelWrapper } from "./styles";

export const ProductLevel = React.memo((props) => {
  const levelName = [
    "Primário",
    "Secundário",
    "Terciário",
    "Quaternário",
    "Quinário",
    "Senário",
    "Setenário",
    "Octonário",
    "Nonário",
    "Decenário",
  ];

  return (
    <ProductLevelWrapper
      className={String(levelName[props.value.length - 1]).toLocaleLowerCase()}
    >
      {levelName[props.value.length - 1]}
    </ProductLevelWrapper>
  );
});
