import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

const CashierOpeningWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .keyboard-cashier-opening {
    border: none;
  }

  .simple-keyboard.hg-theme-default.myThemeNumeric {
    border: none;
    width: 270px;
  }

  .card-cashier-opening {
    flex: 1;
    margin-left: 8px;
  }

  button {
    padding: 10px !important;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    flex-direction: column;

    .keyboard-cashier-opening {
      display: none;
    }
  `)}
`;

const CashierOpeningHeader = styled.h3`
  color: #414141;
  font-family: Dosis, sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  opacity: 0.8;
  margin: 0;
`;

const CashierOpeningBody = styled.div`
  margin: 15px 0;

  .grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;
    grid-column-gap: 10px;
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .grid {
      display: flex;
      flex-direction: column;
    }
  `)}
`;

const CashierOpeningFormRow = styled.div`
  margin-bottom: 20px;
  line-height: 2;

  @media only screen and (${(props) => props.theme?.minimumResolution?.media}) {
    margin-bottom: 14px;
  }

  input {
    width: 100%;
    border: 1px solid #c8cbd0;
    border-radius: 4px;
    height: 50px;
  }

  .error {
    color: #fe2e2e;
    height: 25px;
  }
`;

const CashierOpeningFooter = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
  justify-content: end;
  margin-top: 40px;

  .btn {
    font-family: Roboto, sans-serif;
    font-weight: 500;
  }
`;

export {
  CashierOpeningWrapper,
  CashierOpeningHeader,
  CashierOpeningBody,
  CashierOpeningFormRow,
  CashierOpeningFooter,
};
