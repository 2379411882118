export const htmlToTextHelper = (html) => {
  let text = html.replace(/<style([\s\S]*?)<\/style>/gi, "");

  text = text.replace(/<.*?>/g, "");
  text = text.replace(/<br\s*\/?>/gi, "\n");

  const decoder = new DOMParser().parseFromString(text, "text/html");

  text = decoder.documentElement.textContent;
  text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  text = String(text).toUpperCase();

  return text;
};
