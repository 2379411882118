import styled from "styled-components";
import { isSmartPos } from "../../../../aaone/configuration";

export const Container = styled.div`
  width: 100%;

  &.isSmartPos {
    height: 100%;

    > div {
      align-items: center;
      &,
      > #card {
        height: 100%;

        .title {
          font-size: 1rem !important;
        }

        .button {
          width: 97%;
          background-color: rgb(46, 0, 55);
          border-radius: 4px;
          border: none;
          box-shadow: 0px 3px 6px #00000033;
          color: white;
          font-size: 14px !important;
          padding: 7px;
          margin-top: 5px;
          outline: none;
          text-align: center;
        }
      }
    }
  }
`;

export const PanelStatusPdvWrapper = styled.div`
  margin: ${isSmartPos ? "1.25rem 0" : "40px 10px"};
  height: calc(100vh - 400px);

  .scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    height: 60%;
  }

  .table tbody > tr > td,
  .table thead tr td {
    padding-left: 10px;
    box-sizing: border-box;
  }

  .list-format {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* min-width: 50px; */
    max-width: 100px;
    font-size: 12px;
  }
`;
