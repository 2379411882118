const theme = {
  palette: {
    primary: [
      "#4482FF", // 0: Default
      "#3A78F5", // 1: Darken 4%
      "#3775F2", // 2: Darken 5%
      "rgba(68, 130, 255, 0.2)", // 3: Fade 20%
      "#4C8AFF", // 4: Lighten 3%
      "rgba(68, 130, 255, 0.75)", // 5: Fade 75%
      "#6AA8FF", // 6: Lighten 15%
      "#63A1FF", // 7: Lighten 12%
      "#3F7DFA", // 8: Darken 2%
      "#3369e7", // 9: Algolia color
      "#5896FF", // 10: Lighten 8%
      "#2b69e6", // 11:
      "#236cfe", // 12: darken 10%
      "#4d88ff", // 13: Lighten 5%
      "var(--highlight-color)", // 14 Roxo A Linx
    ],

    secondary: [
      "#2d3446", // 0: DarkBlue
      "#f1f3f6", // 1: LightBluish
      "#788195", // 2: LightBlue
      "#E4E6E9", // 3: LightBluish Darken 5%
      "#364d79", // 4:
      "#202739", // 5: DarkBlue Darken 5%
      "#f5f6f8", // 6: LighterBluish
      "#e9ebf1", // 7: DarkBluish
      "#F6F8FB", // 8: LighterBluish Lighten 2%
      "#E9EBEE", // 9: LighterBluish Darken 3%
      "#1a1a1a", // 10: Sidebar submenu select
    ],

    color: [
      "#FEAC01", // 0: Orange
      "var(--highlight-color)", // 1: Purple
      "#F75D81", // 2: Pink
      "#7ED321", // 3: LimeGreen
      "#39435f", // 4: BlueShade
      "#FFCA28", // 5: Yellow
      "#F2BD1B", // 6: Yellow Darken 5%
      "#3b5998", // 7: Facebook
      "#344e86", // 8: Facebook Darken 5%
      "#dd4b39", // 9: Google Plus
      "#d73925", // 10: Google Plus Darken 5%
      "#e14615", // 11: Auth0
      "#ca3f13", // 12: Auth0
      "#e0364c", // 13: themeColor--AlizarinCrimson
    ],

    warning: [
      "#ffbf00", // 0: Warning
    ],

    success: [
      "#00b16a", // 0: Success
    ],

    error: [
      "#f64744", // 0: Error
      "#EC3D3A", // 1: Darken 4%
      "#FF5B58", // 2: Lighten 8%
    ],

    grayscale: {
      active: "#F5F5F5",
      white: "#FFFFFF",
      ultraLigth: "#F0F1F2",
      light: "#D0D3D9",
      medium: "#B2B5BF",
      dark: "#8B9099",
      ultraDark: "#4A4B4D",
      black: "#000000",
    },

    text: [
      "#323332", // 0: Heading
      "#595959", // 1: HeadingLight
      "#979797", // 2: Text
      "#797979", // 3: TextDark
      "#6a6c6a", // 4: Heading Lighten 22%
    ],

    border: [
      "#BDBDBD", // 0: Border
      "#d8d8d8", // 1: BorderDark
      "#ebebeb", // 2: BorderLight
      "#d3d3d3", // 3:
      "#DBDBDB", // 4: Border Button Active
      "rgba(228, 228, 228, 0.65)", // 5:
    ],

    calendar: [
      "#905", // 0:
      "#690", // 1:
      "#a67f59", // 2:
      "#07a", // 3:
      "#dd4a68", // 4:
      "#e90", // 5:
    ],
  },

  fonts: {
    pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
  },

  text: {
    uppercase: "uppercase",
    color: "#BDC2C9",
    size: "1em",
  },

  textPrimary: {
    size: "22px",
  },

  panel: {
    padding: "20px",
  },

  panelSize: {
    centralized: "0 auto",
  },

  minimumResolution: {
    media: "max-width : 1024px",
  },

  // test
  colors: {
    purple: "#2e0037",
    purpleDisabled: "rgba(46, 0, 55, 0.4)",
    primary: "#411e5a",
    secondary: "#411e5a",
    tertiary: "#F1F3F6",
    success: "#66BB6A",
    error: "#da482c",
    warning: "#ff890d",
    info: "#411e5a",
    light: "white",
    dark: "#414141",
  },
  background: {
    primary: "#F1F3F6",
    secondary: "white",
    tertiary: "#411e5a",
    success: "#66BB6A",
    error: "#da482c",
    warning: "#ff890d",
  },
  fontFamily: {
    primary: "Dosis",
    secondary: "'Roboto', sans-serif",
    tertiary: "'Roboto', sans-serif",
  },
  fontSize: {
    small: "0.8em",
    medium: "1.2em",
    large: "3em",
  },
};

export default theme;
