import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useLayoutAa } from "../../../contexts/layout";

import BrasilFlag from "../../../assets/brasil-flag.svg";
import EuaFlag from "../../../assets/eua-flag.svg";

const Flag = ({ image, isSelected, ...props }) => (
  <img
    alt="flag"
    src={image}
    className={isSelected ? "flag selected" : "flag"}
    {...props}
  />
);

export const I18n = () => {
  const { changeLanguage } = useLayoutAa();
  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  return (
    <div className="flags-container">
      <Flag
        image={BrasilFlag}
        isSelected={selectedLanguage === "pt" || !selectedLanguage}
        onClick={() => changeLanguage("pt")}
      />
      <Flag
        image={EuaFlag}
        isSelected={selectedLanguage === "en"}
        onClick={() => changeLanguage("en")}
      />
    </div>
  );
};
