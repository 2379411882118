import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SUM_ITEM,
  SUB_ITEM,
  CHECKOUT,
  TOGGLE_CART,
  CART_SEQ,
} from "../constants/cart";

export const addToCart = (item) => {
  return {
    type: ADD_TO_CART,
    item,
  };
};

export const removeFromCart = (index) => {
  return {
    type: REMOVE_FROM_CART,
    index,
  };
};

export const sum = (index) => {
  return {
    type: SUM_ITEM,
    index,
  };
};

export const sub = (index) => {
  return {
    type: SUB_ITEM,
    index,
  };
};

export const checkout = () => {
  return {
    type: CHECKOUT,
  };
};

export const toggleCart = (toggle) => {
  return {
    type: TOGGLE_CART,
    toggle,
  };
};

export const cartSequence = (index) => {
  return {
    type: CART_SEQ,
    index,
  };
};
