import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Content, Card, Button } from "../../shared/components/modules";
import logoBobs from "../../assets/logobobs.png";
import { withdrawn } from "../../../services/withdrawnService";
import { useViewDispatch } from "../../../context";
import { showFidelityPage } from "../../configuration";
import { useLayoutAa } from "../../contexts/layout";

const WithdrawnContainer = (props) => {
  const { t } = useTranslation();
  const viewDispatch = useViewDispatch();
  const [title, setTitle] = useState("");
  const [locations, setLocations] = useState([]);
  const {
    getTranslateName,
    getScreenTitleBySlug,
    getScreenImageBySlug,
    getFieldScreenBySlug,
    getScreenIsActiveBySlug,
    isLayout,
    isLayoutAAOne,
  } = useLayoutAa();

  const pageTitle = getTranslateName(t, "withdrawn.page_title") || getScreenTitleBySlug("OPCAODECOMER") || title;
  const isShowFidelityPage =
    getScreenIsActiveBySlug("FIDELIDADE_DIGITE_CPF") ?? showFidelityPage;

  const cardTitleList = {
    L: getTranslateName(t, "withdrawn.method_L") || getFieldScreenBySlug(0, "OPCAODECOMER")?.description,
    V: getTranslateName(t, "withdrawn.method_V") || getFieldScreenBySlug(1, "OPCAODECOMER")?.description,
  };

  const cardImageList = {
    L: "https://linxfood.blob.core.windows.net/images/comeraqui.png",//isLayoutAAOne ? getScreenImageBySlug("OPCAODECOMER", "COMERAQUI") : null,
    V: "https://linxfood.blob.core.windows.net/images/levar.png"//isLayoutAAOne ? getScreenImageBySlug("OPCAODECOMER", "VIAGEM") : null,
  }

  const buttonBackText = getTranslateName(t, "buttons.back") || "Voltar";

  useEffect(() => {
    if (props.modalTimer) {
      props.modalTimer(true);
    }
    withdrawn().then(response => {
      if (!!response.locationOrders.length) {
        setTitle(response?.title ?? "O seu pedido é:");
        setLocations(response.locationOrders);
      }
    });
  }, [props]);

  return (
    <>
      <Content
        bgColor="background-primary"
        title={
          isLayout("BOBS") ? (
            <img className="logobobs" src={logoBobs} />
          ) : (
            pageTitle
          )
        }
        subtitle={false}
        contentChildren={true}
        className="full"
      >
        <section className="withdrawn-cards">
          {locations.map((item) => {
            let cardTitle = item.description;
            let cardImage = item.image;

            if (cardTitleList[item.typeCode]) {
              cardTitle = cardTitleList[item.typeCode];
            }

            if(cardImageList[item.typeCode]){
              cardImage = cardImageList[item.typeCode];
            }

            return (
              <Card
                className="withdraw-cards"
                key={item.description}
                cardDisplay="horizontal withdraw-images"
                cardTitle={cardTitle || item.description}
                imgURL={cardImage}
                eventClick={() => {
                  window.localStorage.setItem("typeCode", item.typeCode);
                  if (isShowFidelityPage) {
                    viewDispatch("step.fidelity");
                  } else {
                    viewDispatch("step.home");
                  }
                }}
              />
            );
          })}
        </section>

        {!isLayout("RAGAZZO") && (
          <footer
            className="footer template-columns-1"
            style={{ width: "75%", height: "128px" }}
          >
            <Button
              text={buttonBackText}
              buttonUIType="outline-primary"
              buttonUI={"normal"}
              handleClick={() => viewDispatch("step.banner")}
            />
          </footer>
        )}
      </Content>
    </>
  );
};

export default WithdrawnContainer;
