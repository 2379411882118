import React, { useEffect, useRef } from "react";
import {
  AgGrid,
  AgGridActions,
  DirectionalArrowsContainer,
  DirectionalArrow,
} from "./styled";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../helpers/ag-grid-locale-pt-br";
import { currencyMask } from "../../helpers/masks";
import { formatDateHelper } from "../../helpers/formatDateHelper";

const TableList = (props) => {
  const ROW_HEIGHT = 65;
  const agGridRef = useRef();

  const { listWithdraw, handleReprint, handleCancel } = props;

  const columns = [
    { title: "Data / Hora", propName: "creationDate" },
    { title: "Observação", propName: "description" },
    { title: "Identificação do Carro-Forte", propName: "numberEnvelop" },
    { title: "Cancelado", propName: "armoredCarCanceled" },
    { title: "Controle", propName: "referenceSequence" },
    { title: "Ações", propName: "action" },
  ];

  useEffect(() => {
    agGridRef.current.api.setRowData([]);
    agGridRef.current.api.setRowData(listWithdraw);
    agGridRef.current.api.sizeColumnsToFit();
  });

  const scrollGrid = (direction) => {
    if (agGridRef.current.api.getDisplayedRowCount() === 0) return;

    let nodeIndex = 0;
    const lastRowIndex = agGridRef.current.api.getDisplayedRowCount() - 1;
    const displayedRows =
      Math.floor(
        agGridRef.current.api?.["gridBodyCon"].eBodyViewport.clientHeight /
        ROW_HEIGHT
      ) - 1;

    if (direction === "top") {
      nodeIndex = agGridRef.current.api.getFirstDisplayedRow() - displayedRows;
      if (nodeIndex < 0) nodeIndex = 0;
    }

    if (direction === "bottom") {
      nodeIndex = agGridRef.current.api.getLastDisplayedRow() + displayedRows;
      if (nodeIndex > lastRowIndex) nodeIndex = lastRowIndex;
    }

    agGridRef.current.api.ensureIndexVisible(nodeIndex);
  };

  return (
    <>
      <AgGrid>
        <div className="ag-theme-balham monitor">
          <AgGridReact
            ref={agGridRef}
            localeText={agGridLocalePtBr}
            headerHeight={40}
            rowBuffer={0}
            immutableData={true}
            rowHeight={ROW_HEIGHT}
            getRowNodeId={(data) => data.referenceSequence}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            onRowDataUpdated={(params) => {
              params.api.sizeColumnsToFit();
            }}
            frameworkComponents={{
              Actions: (props) => Actions(props, handleReprint, handleCancel),
              AmountCell,
              ReversedCell,
              dateHour,
            }}
          >
            {columns.map((column) => {
              const cellStyle = {
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: "15px",
                wordBreak: "break-word",
              };

              const additionalProps = {
                ...(column.propName === "creationDate" && {
                  cellRenderer: "dateHour",
                }),
                ...(column.propName === "description" && {
                  wrapText: true,
                }),
                ...(column.propName === "armoredCarCanceled" && {
                  cellRenderer: "ReversedCell",
                  width: 120,
                }),
                ...(column.propName === "referenceSequence" && {
                  width: 120,
                }),
                ...(column.propName === "action" && {
                  width: 300,
                  cellRenderer: "Actions",
                }),
              };

              return (
                <AgGridColumn
                  key={column.propName}
                  colId={column.colId}
                  cellStyle={cellStyle}
                  field={column.propName}
                  {...additionalProps}
                  headerName={column.title}
                />
              );
            })}
          </AgGridReact>
        </div>
        <DirectionalArrowsContainer>
          <DirectionalArrow
            className="top"
            onClick={() => scrollGrid("top")}
          >
            <span className="material-icons">keyboard_arrow_up</span>
          </DirectionalArrow>

          <DirectionalArrow
            className="bottom"
            onClick={() => scrollGrid("bottom")}
          >
            <span className="material-icons">keyboard_arrow_down</span>
          </DirectionalArrow>
        </DirectionalArrowsContainer>
      </AgGrid>
    </>
  );
};

const AmountCell = (row) => <span>{currencyMask(row.value)}</span>;

const ReversedCell = (row) => <span>{row.value ? "Sim" : "Não"}</span>;

const dateHour = (row) => <span>{formatDateHelper(new Date(row.value), "dd/MM/yyyy HH:mm")}</span>;

const Actions = (row, handleReprint, handleCancel) => (
  <AgGridActions>
    <button
      className="btn-card-list"
      disabled={row.data.armoredCarCanceled}
      onClick={() => handleCancel(row.data.id)}
    >
      <i className="material-icons">cancel</i>
      <span>Cancelar</span>
    </button>
    <button
      className="btn-card-list"
      onClick={() => handleReprint(row.data.id)}
    >
      <i className="material-icons">print</i>
      <span>Imprimir</span>
    </button>
  </AgGridActions>
);

export default TableList;
