/**
 * Remove keys in session storage
 * @param {string[]|string} names
 */

export const removeSessionsStorageHelper = (names) => {
  const list = Array.isArray(names) ? names : [names];

  list.forEach((keyName) => {
    sessionStorage.removeItem(keyName);
  });
};
