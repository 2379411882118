import { isPDVOne } from "../aaone/configuration";
import { PAYMENTS_METHOD_KEY_NAME } from "../pdv-one/constants/keyNamesSessionStorage";
import { apiBff, apiProducts } from "./api";

export const getPaymentsMethodBffService = async () => {
  try {
    const { data } = await apiBff.post("/process", {
      step: "saleFlow.select_location",
    });

    if (!data?.payload && data?.payload?.[0].length === 0) {
      return null;
    }

    data.payload[0].forEach((element) => {
      switch (element.transactionType) {
        case "DINHEIRO":
          element.image =
            "https://linxfood.blob.core.windows.net/images/dinheiro.png";
          break;
        case "CREDITO":
          element.image =
            "https://linxfood.blob.core.windows.net/images/credito.png";
          break;
        case "DEBITO":
          element.image =
            "https://linxfood.blob.core.windows.net/images/debito.png";
          break;
        case "VOUCHER":
          element.image =
            "https://linxfood.blob.core.windows.net/images/voucher.png";
          break;
        case "PEDE PRONTO":
          element.image =
            "https://linxfood.blob.core.windows.net/images/qrcode.png";
          break;
        case "OUTROS":
          element.image =
            "https://linxfood.blob.core.windows.net/images/outros.png";
          break;
        case "QR LINX":
          element.image =
            "https://linxfood.blob.core.windows.net/images/qrcode.png";
          break;
        default:
          element.image =
            "https://linxfood.blob.core.windows.net/images/dinheiro.png";
      }
    });

    return data.payload[0];
  } catch (error) {
    return null;
  }
};

export const getPaymentsPosMethodService = async () => {
  try {
    const { data } = await apiProducts.get(
      "/api/payments-group-by-base-one-code"
    );

    if (!data?.payments && data?.payments?.[0].length === 0) {
      return null;
    }

    sessionStorage.setItem(
      PAYMENTS_METHOD_KEY_NAME,
      JSON.stringify(data.payments)
    );

    const payments = data.payments.map((payment) => {
      let newObj = {
        ...payment,
        description: String(payment.description).toUpperCase(),
      };

      switch (newObj.description) {
        case "DINHEIRO":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/dinheiro.png";
          break;
        case "CREDITO":
        case "[TEF] CRÉDITO":
        case "[POS] CRÉDITO":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/credito.png";
          break;
        case "DEBITO":
        case "[TEF] DÉBITO":
        case "[POS] DÉBITO":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/debito.png";
          break;
        case "VOUCHER":
        case "[TEF] VOUCHER":
        case "[POS] VOUCHER":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/voucher.png";
          break;
        case "PEDE PRONTO":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/qrcode.png";
        case "OUTROS":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/outros.png";
          break;
        case "QR LINX":
        case "QRCODE":
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/qrcode.png";
          break;
        default:
          newObj.image =
            "https://linxfood.blob.core.windows.net/images/dinheiro.png";
      }

      return newObj;
    });

    return payments;
  } catch (error) {
    return null;
  }
};

export const getPaymentsMethodService = async () => {
  if (isPDVOne) {
    return getPaymentsPosMethodService();
  } else {
    return getPaymentsMethodBffService();
  }
};
