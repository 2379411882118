import styled, { keyframes } from "styled-components";

export const infoKeyFrame = keyframes`
  from {
    background-color: #C72200;
  }

  to {
    background-color: #7C2529;
  }
`;

export const InfoWarn = styled.div`
  background-color: #ffb000;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0;
  left: 0;
  top: 0;

  padding: 0.75rem 1rem;
  width: 100%;

  animation: ${infoKeyFrame} 1.5s linear infinite;
  text-transform: uppercase;
  text-align: center;

  font-family: "Arial", "Inter", sans-serif;
  font-weight: 700;
  font-size: 2rem;
`;

export const Main = styled.div`
  transition: background-color 0.25s ease-out;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;

  position: fixed;
  z-index: 99999;

  margin: 0;
  right: 0;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &.hide {
    background-color: transparent;
    top: -100%;
  }

  &.isSmartPos {
    display: none !important;
  }
`;

export const Container = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: #c4cad9;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 1rem;

  grid-gap: 1rem;
  gap: 1rem;

  max-width: 670px;
  width: 100%;

  &,
  * {
    font-family: "Arial", "Inter", sans-serif;
  }

  span {
    font-style: normal;
    font-weight: 700;

    line-height: 2.5rem;
    font-size: 2.5rem;
  }

  .linx {
    width: 50px;
    height: 29px;

    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  }

  .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem 0px;
    grid-gap: 0.625rem 0.625rem;
    gap: 0.625rem;

    width: 100%;
  }

  .footer {
    text-align: center;
    font-size: 1.5rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0.25rem 0px;
    margin: 0;

    list-style: none;

    grid-gap: 0.5rem 0.5rem;
    gap: 0.5rem;

    width: 100%;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;

      isolation: isolate;
      color: #000000;

      font-style: normal;
      font-size: 2rem;
      line-height: 2.25rem;

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      .label {
        display: flex;
        align-items: flex-end;
        font-weight: 700;

        flex: none;
        order: 0;
        flex-grow: 1;
        z-index: 1;
      }

      .value {
        flex: none;
        order: 1;
        flex-grow: 0;
        z-index: 0;
      }
    }
  }
`;
