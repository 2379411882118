import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../Button";
import { Card } from "../../Card";
import { Period } from "../../period";
import {
  Filters,
  ReportCashierClosingWrapper,
  TableContainer,
  ActionsButtons,
  DirectionalArrow,
  DirectionalArrowsContainer,
  ModalContent,
} from "./style";
import { Modal } from "./style";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import { ReportPrint, ReportView } from "./cell-renderers";
import { useWrapperRouters } from "../../../hook/wrapperRoutersHook";
import { useNavigate } from "react-router-dom";
import { PagesEnum } from "../../../constants/pagesEnum";
import { formatDateHelper } from "../../../helpers/formatDateHelper";
import { isSmartPos } from "../../../../aaone/configuration";
import { ReportCashierCloseSmartPos } from "./report-smartpos";
import classNames from "classnames";

const ReportCashierClosing = (props) => {
  const {
    onChangeDate,
    posList,
    printCashier,
    reportContent,
    handleViewReport,
    handleCloseReport,
  } = props;
  const { setVisibleSidebarCart } = useWrapperRouters();
  const navigate = useNavigate();
  const [initialDate, setInitialDate] = useState(new Date());
  const [cashdesk, setCashdesk] = useState(new Date());
  const modalRef = useRef(null);

  const [filters, setFilters] = useState({
    initialDate: new Date(),
  });

  const handlePrintClick = (params) => {
    printCashier(params);
  };

  useEffect(() => {
    setVisibleSidebarCart(false);
  });

  useEffect(() => {
    setFilters({ initialDate: new Date() });
  }, []);

  const handleClose = () => {
    setVisibleSidebarCart();
    navigate(PagesEnum.HOME);
  };

  const handleViewClick = (cashdesk) => {
    setCashdesk(cashdesk);
    cashdesk = {
      ...cashdesk,
      htmlRequest: true,
    };
    handleViewReport(cashdesk);
  };

  const columns = [
    { title: "PDV", propName: "posId" },
    { title: "Nº Abertura", propName: "openingCount" },
    { title: "Operador", propName: "user" },
    { title: "Data Abertura", propName: "openingDateFormatted" },
    { title: "Data Fechamento", propName: "closingDateFormatted" },
    { title: "Ações", propName: "actions" },
  ];

  const periodRef = useRef();

  const handleScroll = useCallback(
    (scroll) => {
      let scrollElement = document.getElementById("reportContent");

      scroll === "down"
        ? scrollElement.scrollBy({ top: 100, behavior: "smooth" })
        : scrollElement.scrollBy({ top: -100, behavior: "smooth" });
    },
    [modalRef.current]
  );

  const handleCancelReport = () => {
    setCashdesk();
    handleCloseReport();
  };

  const handleSearch = () => {
    onChangeDate(initialDate);
  };

  const changeDate = (params) => {
    setInitialDate(params);
  };

  return (
    <Card
      title={"Relatório - Fechamento de Caixa"}
      handleClose={handleClose}
      closeEnable
      fullHeight
      className=""
      id="cardList"
    >
      <ReportCashierClosingWrapper>
        <Filters
          className={classNames({
            isSmartPos,
          })}
        >
          <div className="periodSelect">
            <Period
              ref={periodRef}
              object={filters}
              fieldStart="initialDate"
              showFieldEnd={false}
              labelStart="Data de abertura do caixa"
              initialValueStart={formatDateHelper(new Date(), "MM/dd/yyyy")}
              changeDate={changeDate}
            />
          </div>
          <Button className="btn-pronto" onClick={handleSearch}>
            <div>
              <i className="material-icons">search</i>
              Pesquisar
            </div>
          </Button>
        </Filters>

        {isSmartPos ? (
          <>
            {posList.map((item, index) => (
              <ReportCashierCloseSmartPos
                key={index}
                item={item}
                print={() => handlePrintClick(item)}
              />
            ))}
          </>
        ) : (
          <TableContainer>
            <AgGridReact
              className="ag-theme-balham monitor"
              rowData={posList}
              localeText={agGridLocalePtBr}
              getRowNodeId={(data) => data.id}
              rowSelection="multiple"
              rowHeight={60}
              headerHeight={40}
              suppressContextMenu
              suppressRowClickSelection
              rowBuffer={0}
              frameworkComponents={{
                ReportPrint,
                ReportView,
              }}
            >
              {columns.map((column) => {
                const cellStyle = {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                  lineHeight: "15px",
                  wordBreak: "break-word",
                };

                const additionalProps = {
                  suppressMenu: true,

                  ...(column.propName === "posId" && {
                    flex: 0.6,
                    wrapText: true,
                  }),

                  ...(column.propName === "openingCount" && {
                    flex: 0.8,
                  }),

                  ...(column.propName === "user" && {
                    flex: 0.8,
                    wrapText: true,
                  }),

                  ...(column.propName === "openingDate" && {
                    flex: 0.7,
                  }),

                  ...(column.propName === "actions" && {
                    width: 200,
                    propName: "",
                    cellRenderer: "ReportPrint",
                    cellRendererParams: {
                      onClickPrinter: (data) => {
                        handlePrintClick(data);
                      },
                      onClickView: (data) => {
                        handleViewClick(data);
                      },
                    },
                  }),
                };

                return (
                  <AgGridColumn
                    key={column.propName}
                    cellStyle={cellStyle}
                    field={column.propName}
                    {...additionalProps}
                    headerName={column.title}
                    floatingFilter={column.showFilter}
                    filter={column.typeFilter}
                  />
                );
              })}
            </AgGridReact>
          </TableContainer>
        )}
        <Modal ref={modalRef} className="modal" isOpen={reportContent !== ""}>
          <ModalContent>
            <DirectionalArrowsContainer>
              <DirectionalArrow
                className="top"
                onClick={() => handleScroll("up")}
              >
                <span className="material-icons">keyboard_arrow_up</span>
              </DirectionalArrow>

              <DirectionalArrow
                className="bottom"
                onClick={() => handleScroll("down")}
              >
                <span className="material-icons">keyboard_arrow_down</span>
              </DirectionalArrow>
            </DirectionalArrowsContainer>
            <div
              id="reportContent"
              style={{
                display: "flex",
                maxHeight: "90%",
                overflowY: "hidden",
              }}
            >
              <div
                style={{
                  maxWidth: "300px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: reportContent }}
              />
            </div>
            <ActionsButtons>
              <Button
                id="btnImprimir"
                className="btn-purple"
                type="button"
                onClick={() => handlePrintClick(cashdesk)}
              >
                Imprimir
              </Button>
              <Button
                id="btnCancelar"
                className="btn-purple"
                type="button"
                onClick={() => handleCancelReport()}
              >
                Fechar
              </Button>
            </ActionsButtons>
          </ModalContent>
        </Modal>
      </ReportCashierClosingWrapper>
    </Card>
  );
};

export default ReportCashierClosing;
