import { getTerminalID } from "../../../aaone/configuration";
import { printerActionService } from "../../../services/actions/printerActionService";
import { formatDateHelper } from "../formatDateHelper";
import { getSystemParamsHelper } from "../getSystemParamsHelper";
import { currencyMask } from "../masks";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const printQRcodeCancelCoupon = (qrcodeResult) => {
  const systemParams = getSystemParamsHelper();
  const documentNumber = systemParams?.storeCNPJ;

  var coupons = [];

  if (qrcodeResult) {
    var dhEvent = formatDateHelper(new Date(), 'dd/MM/yyyy HH:mm');
    var coupons = [
      `Comprovante de cancelamento (VIA ESTAB.)<br>
      NSU: ${qrcodeResult.refund_id}<br>
      Método de pagamento: QR LINX<br>
      Terminal: ${getTerminalID()}<br>
      CNPJ da loja: ${documentNumber}<br>
      Data e hora: ${dhEvent} <br>
      Modulo: QR Linx<br>
      Valor: ${currencyMask(qrcodeResult.value)}<br>
      <br><br><br>
      `,

      `Comprovante de cancelamento (VIA CLIENTE.)<br>
      NSU: ${qrcodeResult.refund_id}<br>
      Método de pagamento: QR LINX<br>
      Terminal: ${getTerminalID()}<br>
      CNPJ da loja: ${documentNumber}<br>
      Data e hora: ${dhEvent} <br>
      Modulo: QR Linx<br>
      Valor: ${currencyMask(qrcodeResult.value)}<br>
      <br><br><br>
      `
    ];
  }

  coupons.forEach(content => {
    if (window.desktopApp) {
      printerActionService({
        body: content,
      });
    } else if (isMobile) {
      printDefault(content.replace(new RegExp(/\**<br>/, 'gm'), '\n'));
    }
  })

}
