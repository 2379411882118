import classNames from "classnames";
import React, { useMemo } from "react";

import { useCart } from "../../../hook/cartHook";
import { useCategoriesNavigate } from "../../../hook/categoriesHook";

import { Container, LoadContainer } from "./styles";
import { getImageURl } from "../../../helpers/getImageURl";
import { isSmartPos } from "../../../../aaone/configuration";

export const SkeletonItemCategory = () => (
  <LoadContainer className="skeleton">
    <div className="skeleton skeleton__text"></div>
  </LoadContainer>
);

export const ItemCategory = ({ data, children }) => {
  const { currentCategoryId, handleChangeCategory } = useCategoriesNavigate();
  const { currentProductStep, clearCurrentProduct } = useCart();

  const bgColorDefault = "#ffeee9";
  const backgroundColorCategory = data?.backGroundColor ?? bgColorDefault;
  const fontColorCategory = data.fontColorDescription;

  const isItemActive = useMemo(
    () => currentCategoryId === data?.id && currentProductStep?.length <= 0,
    [currentCategoryId, currentProductStep]
  );

  const truncateDescription = (text) => {
    if (text.length > 38) {
      return text.slice(0, 38) + "...";
    }
    return text;
  };

  const handleClickChangeCategory = () => {
    clearCurrentProduct();
    const { id, isFidelity = false } = data;

    handleChangeCategory({ categoryId: id, isFidelity });
  };

  return (
    <Container
      className={classNames({ isItemActive })}
      style={{
        backgroundColor: backgroundColorCategory,
        backgroundImage: data?.image
          ? `url('${getImageURl(data.image)}')`
          : undefined,
      }}
      role="button"
      onClick={handleClickChangeCategory}
    >
      <div
        className={classNames("description", {
          isSmartPos,
        })}
        style={{ background: `#ffffff`, color: `#411e5a` }}
      >
        {truncateDescription(children)}
      </div>
    </Container>
  );
};
