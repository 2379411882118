import { apiCashdesk } from "./api";
import { getRetailerId, getTerminalID } from "../aaone/configuration";
import { getSystemParamsHelper } from "../pdv-one/helpers/getSystemParamsHelper";
import { checkTokenIsValidService } from "./checkTokenIsValidService";

export const getLastCashdeskService = async () => {
  try {
    const { data } = await apiCashdesk.get(
      `/CashDesk/${getRetailerId()}/${getTerminalID()}/last`
    );

    if (data.errors) {
      console.log("Error in getLastCashdesk ::", data);

      return null;
    }

    return data;
  } catch (error) {
    console.log("Error in getLastCashdesk ::", error);
    return Promise.reject(`Error in getLastCashdesk :: ${error}`);
  }
};

/**
 * @param {*} params
{
  "posId": "1",
  "retailerId": "38ae45ed-3bcc-8770-fd1c-b1cdabc920bf",
  "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "userReferenceId": 0,
  "user": "string",
  "responsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "responsableReferenceId": 0,
  "openDateOpeningDay": "2023-04-13T15:35:12.181Z",
  "paymentType": {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "string"
  },
  "responsable": "string",
  "openingAmount": 0,
  "description": "string",
  "retailerReferenceId": 0,
  "isDeliveryCash": true,
  "createUser": {
    "referenceId": 0,
    "name": "string"
  },
  "requestingUser": {
    "referenceId": 0,
    "name": "string"
  },
  "serverReferenceId": 0,
  "storeReferenceId": 0,
  "franchisorReferenceId": 0
}
 */
export const cashdeskOpenService = async (params) => {
  try {
    const systemParams = getSystemParamsHelper();

    const { storeID, storeFranchiseID, storeServerID } = systemParams;

    const { data } = await apiCashdesk.post("/OpenCashDesk", {
      ...params,
      retailerId: getRetailerId(),
      serverReferenceId: storeServerID,
      storeReferenceId: storeID,
      franchisorReferenceId: storeFranchiseID,
    });

    return data;
  } catch (error) {
    console.log("Error in cashdeskOpenService :: ", error);
    return Promise.reject(`Error in cashdeskOpenService ::  ${error}`);
  }
};

/**
 * Suprimento de caixa
 * @param {*} params
{
  "posId": "1",
  "retailerId": "38ae45ed-3bcc-8770-fd1c-b1cdabc920bf",
  "cashdeskId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "responsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "amount": 100,
  "description": "Suprimento teste",
  "numberEnvelop": "string",
  "paymentType": {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "string"
  },
  "createUser": {
    "referenceId": 0,
    "name": "string"
  },
  "requestingUser": {
    "referenceId": 0,
    "name": "string"
  }
}
 */

export const cashSupplyService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/Movement/Supply", params);

    return data;
  } catch (error) {
    console.log("Error in cashSupply :: ", error);
    return Promise.reject(`Error in cashSupply ::  ${error}`);
  }
};

/**
 * Listagem de Suprimento e Sangria
 * @param {*} params
{
  "retailerId": "38ae45ed-3bcc-8770-fd1c-b1cdabc920bf",
  "movementType": 2, (Sangria = 2 e Suprimento = 3)
  "initialDate": "2023-04-17",
  "finalDate": "2023-04-17"
}
 */

export const listMovementsService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/Movement/Filter", params);

    return data;
  } catch (error) {
    console.log("Error in movements :: ", error);
    return Promise.reject(`Error in movements ::  ${error}`);
  }
};

/**
 * Estorno de Suprimento e Sangria
 * @param {*} params
{
  "posId": "1",
  "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "movementId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}
 */

export const chargebackService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/Movement/Chargeback", params);

    return data;
  } catch (error) {
    console.log("Error in chargeback :: ", error);
    return Promise.reject(`Error in chargeback ::  ${error}`);
  }
};

/**
 * Sangria de caixa
 * @param {*} params
{
  "posId": "1",
  "retailerId": "38ae45ed-3bcc-8770-fd1c-b1cdabc920bf",
  "cashdeskId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "responsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "amount": 100,
  "description": "Sangria teste",
  "numberEnvelop": "123456",
  "paymentType": {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "string"
  },
  "createUser": {
    "referenceId": 0,
    "name": "string"
  },
  "requestingUser": {
    "referenceId": 0,
    "name": "string"
  }
}
 */

export const cashWithdrawService = async (params) => {
  try {
    const isValid = await checkTokenIsValidService();

    if (isValid) {
      const { data } = await apiCashdesk.post("/Movement/Withdraw", params);

      return data;
    } else {
      throw new Error("Token invalid!");
    }
  } catch (error) {
    console.log("Error in withdraw :: ", error);
    return Promise.reject(`Error in withdraw ::  ${error}`);
  }
};

/**
 * Carro Forte
 * @param {*} params
{
  "posId": "1",
  "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "cashdeskId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "responsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "amount": 100,
  "description": "Teste Carro Forte",
  "numberEnvelop": "123456",
  "operation": 1,
  "expenseType": "string",
  "requestingUser": {
    "referenceId": 0,
    "name": "string"
  },
  "createUser": {
    "referenceId": 0,
    "name": "string"
  }
}
 */

export const armoredCarService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/ArmoredCar/Withdraw", params);

    return data;
  } catch (error) {
    console.log("Error in armoredCar :: ", error);
    return Promise.reject(`Error in armoredCar ::  ${error}`);
  }
};

/**
 * Carro Forte - Listagem
 * @param {*} params
{
  "posId": "1",
  "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "armoredCarID": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}
 */

export const armoredCarWithdrawListService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/ArmoredCar/WithdrawList", params);

    return data;
  } catch (error) {
    console.log("Error in armoredCarWithdrawList :: ", error);
    return Promise.reject(`Error in armoredCarWithdrawList ::  ${error}`);
  }
};

/**
 * Carro Forte - Cancelamento
 * @param {*} params
{
  "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "armoredCarId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}
 */

export const armoredCarWithdrawCancelService = async (params) => {
  try {
    const { data } = await apiCashdesk.post(
      "/ArmoredCar/WithdrawCancel",
      params
    );

    return data;
  } catch (error) {
    console.log("Error in armoredCarWithdrawCancel :: ", error);
    return Promise.reject(`Error in armoredCarWithdrawCancel ::  ${error}`);
  }
};

/**
 * @param {*} params
  {
    "posId": "string",
    "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "cashdeskId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "responsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "amount": 0,
    "description": "string",
    "numberEnvelop": "string",
    "closingUserId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "closingResponsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "grossSales": 0,
    "closingValues": [
      {
        "amount": 0,
        "paymentType": {
          "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "name": "string"
        },
        "isReadOnly": true,
        "systemAmount": 0,
        "brands": [
          {
            "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "name": "string",
            "referenceId": 0,
            "systemAmount": 0,
            "amount": 0,
            "paymentTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
          }
        ]
      }
    ],
    "paymentType": {
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "name": "string"
    },
    "autoWithdraw": true,
    "isBlindCashDesk": true
  }
 */
export const cashdeskCloseService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/CloseCashDesk", params);

    return data;
  } catch (error) {
    console.log("Error in cashdeskCloseService :: ", error);
    return Promise.reject(`Error in cashdeskCloseService ::  ${error}`);
  }
};

/**
 *
 * @param {*} params
 {
  "posId": "string",
  "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  }
 */
export const cashdeskCloseDayService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/Day/closing", params);

    return data;
  } catch (error) {
    console.log("Error in cashdeskCloseDayService :: ", error);
    return Promise.reject(`Error in cashdeskCloseDayService ::  ${error}`);
  }
};

/**
 *
 * @param {*} params
  {
    "posId": "string",
    "retailerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "cashdeskId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "responsableId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "amount": 0,
    "description": "string",
    "numberEnvelop": "string",
    "user": "string",
    "responsable": "string",
    "isBlindCashDesk": true
  }
 */
export const cashdeskReduceService = async (params) => {
  try {
    const { data } = await apiCashdesk.post("/Movement/Reduce", params);

    return data;
  } catch (error) {
    console.log("Error in cashdeskReduceService :: ", error);
    return Promise.reject(`Error in cashdeskReduceService ::  ${error}`);
  }
};

export const listCashdeskByPeriodService = async (params) => {
  try {
    const { data } = await apiCashdesk.get("/CashDesk/ListCashDeskByPeriod?", {
      params: { ...params, retailerId: getRetailerId() },
    });

    return data;
  } catch (error) {
    console.log("Error in listCashdeskByPeriodService :: ", error);
    return Promise.reject(`Error in listCashdeskByPeriodService ::  ${error}`);
  }
};

/**
 *
 * @param {NUUID} cashDeskId
 */
export const getCashdeskById = async (cashDeskId) => {
  try {
    const { data } = await apiCashdesk.get(`/CashDesk/${cashDeskId}`);

    return data;
  } catch (error) {
    console.log("Error in cashdeskReduceService :: ", error);
    return Promise.reject(`Error in cashdeskReduceService ::  ${error}`);
  }
};

/**
 *
 * @param {NUUID} cashDeskId
 */
export const getCashdeskOpenDate = async () => {
  try {
    const { data } = await apiCashdesk.get(
      `/CashDeskOpenDate/${getRetailerId()}`
    );

    return data;
  } catch (error) {
    console.log("Error in getCashdeskOpenDateService :: ", error);
    return Promise.reject(`Error in getCashdeskOpenDateService ::  ${error}`);
  }
};

/**
 * Retorna os calculos para realizar um fechamento de caixa
 *
 * @param {NUUID} cashDeskId
 * @param {Boolean} isClosingCashDesk - indica se o caixa está fechando (TRUE) ou reimprimindo relatório (FALSE)
 */
export const getCloseCashDeskValues = async (
  cashDeskId,
  isClosingCashDesk = false
) => {
  try {
    const { data } = await apiCashdesk.get(
      `/CashDesk/closeCashDeskValues/${cashDeskId}`,
      {
        params: { isClosingCashDesk },
      }
    );

    return data;
  } catch (error) {
    console.log("Error in getCloseCashDeskValuesService :: ", error);
    return Promise.reject(
      `Error in getCloseCashDeskValuesService ::  ${error}`
    );
  }
};

/**
 * Retorna se o operador pode continuar vendendo ou notificado/obrigado a fazer uma sangria
 *
 * @param {*} cashDeskId
 */
export const getCanSaleStatus = async (cashDeskId) => {
  try {
    const { data } = await apiCashdesk.get(`/CashDesk/cansale/`, {
      params: {
        cashDeskId,
      },
    });

    return data;
  } catch (error) {
    console.log("Error in getCanSaleStatus :: ", error);
    return Promise.reject(`Error in getCanSaleStatus ::  ${error}`);
  }
};
