import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.5rem 1rem;

  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 2;

  .menu-bottom,
  .logo {
    margin-right: 1rem;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .version {
        margin-left: 0.5rem;
        margin-top: 0.5rem;

        font-size: 0.7rem;
        font-weight: bold;

        span {
          background: ${({ theme }) => theme.colors.purple};
          padding: 0.1rem 0.5rem 0.2rem;
          border-radius: 6px;
          color: #fff;
        }
      }
    }
  }

  .fa-bell {
    font-size: 20px;
    width: 30px;
    height: 100%;
  }

  .notificationCounter {
    background-color: #c00;
    color: #fff;
    position: relative;
    top: -11px;
    right: 22px;
    display: inline-block;
    padding: 0 3px;
    min-width: 8px;
    max-width: 18px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 22px;
    text-align: center;
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 11px;
    font-weight: 400;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  .notification {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    .goal-statistics {
      margin-top: 3px;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    right: 0;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;

    .notification-content {
      display: flex;

      svg {
        width: 60px;
        height: 60px;
        color: red;
        cursor: pointer;
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .textModel {
    margin-right: 25px;
  }

  .spaceOccupation {
    margin-right: 25px;
  }

  .timerNew {
    font-size: 25px;
    margin-right: 25px;
    color: #411e5a;
  }

  .textNotification {
    display: block !important;
    padding: 12px 16px !important;
  }

  .logo-wrapper {
    display: flex;
    flex-direction: row;
  }

  .details-container-main,
  .details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    flex: 1;
  }

  .details-container {
    span {
      font-size: 14px;
      font-weight: 400;
      padding: 0 0.3rem;
      text-transform: uppercase;

      strong {
        font-weight: 700;
        margin-right: 0.5rem;
      }
    }
  }

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    display: grid;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas: "logo notification details";

    .logo-wrapper {
      grid-area: logo;
    }

    .details-container-main {
      display: flex;
      flex-direction: row;
      grid-area: details;

      .details-container {
        flex-wrap: initial;
        flex: auto;
      }

      .details-container .name,
      .details-container .data-container-cashdesk,
      .notification .timerNew,
      .goal-statistics {
        display: none;
      }
    }
  `)}

  &.isSmartPos {
    .table__container_details {
      display: none;
    }
  }
`;
