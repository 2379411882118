import styled from 'styled-components';


const CloseButtonWrapper = styled.button`
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  color: #ccc;
  width: 25px;
  height: 25px;
  font-size: 24px;
  line-height: 1;
  padding: 0;
`
export { CloseButtonWrapper }