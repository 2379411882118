import React, { forwardRef } from "react";
import SliderSlick from "react-slick";

export const Slider = React.memo(
  forwardRef(({ children, ...props }, ref) => {
    return (
      <SliderSlick {...props} ref={ref}>
        {children}
      </SliderSlick>
    );
  })
);
