import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import { getPaymentsMethodService } from "../../../services/getPaymentsMethodService";
import { LoadingDots } from "../../components/LoadingDots";

import { PagesEnum } from "../../constants/pagesEnum";

import { Container } from "./styles";
import { removeSessionsStorageHelper } from "../../helpers/removeSessionsStorageHelper";
import { HeaderPaymentMethod } from "../../components/Header/paymentMethod";
import {
  MANUAL_DISCOUNTS,
  PAYMENT_METHOD_KEY_NAME,
} from "../../constants/keyNamesSessionStorage";
import { useCart } from "../../hook/cartHook";
import {
  cancelOperation,
  verifyRedeemValue,
} from "../../../services/verifyRedeemValueService";
import { reshopCalculateValues } from "../../../services/paymentService";
import { useAuth } from "../../hook/authHook";
import { cancelAllNsuInMobile } from "../../../services/tefMobile";
import { LoadingWhiteWithText } from "../../components/LoadingWhiteWithText";
import { useDialog } from "../../hook/dialogHook";
import { undoTEFPayments } from "../../helpers/paymentHelper";
import { useLoading } from "../../hook/loadingHook";
import { getLocalPayments } from "../../helpers/multiPaymentsHelper";
import { currencyMask } from "../../helpers/masks";
import { PAYMENT_TYPE_ENUM } from "../../constants/paymentTypeEnum";
import { getTefConfigHelper } from "../../helpers/getTefConfigHelper";

export const PaymentMethodsPage = () => {
  let countPayment = 0;

  const { reshop } = useAuth();
  const { showAlert } = useDialog();
  const {
    clearCart,
    nsuValue,
    paymentSuccess,
    itemsInCart,
    handleSetManualDiscount,
  } = useCart();
  const { setShowLoading } = useLoading();

  const navigate = useNavigate();
  const location = useLocation();

  const [methods, setMethods] = useState([]);
  const [isLoadingValidate, setIsLoadingValidate] = useState(true);

  const handleBackToHome = useCallback(() => {
    handleSetManualDiscount(0);
    sessionStorage.removeItem(MANUAL_DISCOUNTS);
    navigate(PagesEnum.HOME);
  }, []);

  const handleCancelOperation = useCallback(async () => {
    try {
      setShowLoading(true, "Aguarde, verificando e desfazendo pagamento(s).");

      // Desfaz os pagamentos TEF
      const allPayments = getLocalPayments();

      undoTEFPayments(() => {
        if (allPayments.length) {
          showAlert({
            message: (
              <>
                Os pagamentos abaixo foram cancelados.
                {allPayments.length ? (
                  <>
                    {allPayments
                      .filter((payment) => payment.details.controlCode)
                      .map((payment) => (
                        <>
                          <br />
                          NSU: {payment.details.controlCode} | Valor:{" "}
                          {currencyMask(payment.details.amountPaid)}
                        </>
                      ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ),
          });
        }

        setShowLoading(false);
      });

      clearCart();

      // Cancela NSU do Reshop
      const nsu = nsuValue();

      // cancelAllNsuInMobile();

      if (nsu) {
        cancelOperation(nsuValue())
          .then(() => {
            navigate(PagesEnum.HOME);
          })
          .catch(() => {
            navigate(PagesEnum.HOME);
          });
      } else {
        navigate(PagesEnum.HOME);
      }
    } catch (error) {
      console.error(error);
    }
  }, [clearCart]);

  const paymentFake = async () => {
    navigate(PagesEnum.PAYMENT_HOW_MUCH_WILL_YOU_PAY, {
      replace: true,
    });
  };

  const paymentCash = async () => {
    navigate(PagesEnum.PAYMENT_HOW_MUCH_WILL_YOU_PAY, {
      replace: true,
    });
  };

  const paymentOther =
    (data, PAYMENT_METHOD = "OUTROS") =>
    async () => {
      navigate(PagesEnum.PAYMENT_HOW_MUCH_WILL_YOU_PAY, {
        replace: true,
        state: {
          PAYMENT_METHOD,
          isPos: true,
          ...(data && { data }),
        },
      });
    };

  const paymentQrCode =
    (PAYMENT_METHOD, isPos = false, data) =>
    async () => {
      navigate(PagesEnum.PAYMENT_HOW_MUCH_WILL_YOU_PAY, {
        replace: true,
        state: {
          PAYMENT_METHOD,
          isPos,
          ...(data && { data }),
        },
      });
    };

  const paymentPinPad =
    (PAYMENT_METHOD, isPos = false, data) =>
    async () => {
      const { tefStatus } = getTefConfigHelper();

      const goToPage = window.Android ? tefStatus || isPos : true;

      if (goToPage) {
        navigate(PagesEnum.PAYMENT_HOW_MUCH_WILL_YOU_PAY, {
          replace: true,
          state: {
            PAYMENT_METHOD,
            isPos,
            ...(data && { data }),
          },
        });
      } else {
        showAlert({
          message:
            "O TEF foi não ativado.\nVá em Configurações/TEF para configurar o TEF.",
        });
      }
    };

  const startTransaction = (transactionType, data) => {
    setIsLoadingValidate(true);

    countPayment = countPayment + 1;

    window.localStorage.removeItem("returnPayment");

    localStorage.setItem("contadorPayment", String(countPayment));

    const transactionDesc = String(data?.description ?? "").toUpperCase();

    const isTef = /\[TEF\]/.test(transactionDesc);
    const hasMultiplePosPayment = data?.isPos
      ? true
      : data.payments?.length > 1;

    const isPos =
      transactionDesc === "OUTROS" ? true : Boolean(hasMultiplePosPayment);
    const isQrCode = Boolean(data?.isQrCode ?? false);
    const isQrLinx = Boolean(data?.isQrLinx ?? false);

    const CREDITO = isTef
      ? paymentPinPad("CREDITO", false, data)
      : isPos
      ? paymentPinPad("CREDITO", true, data)
      : paymentPinPad("CREDITO", false, data);

    const DEBITO = isTef
      ? paymentPinPad("DEBITO", false, data)
      : isPos
      ? paymentPinPad("DEBITO", true, data)
      : paymentPinPad("DEBITO", false, data);

    const VOUCHER = isTef
      ? paymentPinPad("VOUCHER", false, data)
      : isPos
      ? paymentPinPad("VOUCHER", true, data)
      : paymentPinPad("VOUCHER", false, data);

    const QRLINX = paymentQrCode("QRLINX", isPos, isPos ? data : undefined);

    const paymentMethods = {
      TESTEQA: paymentFake,
      DINHEIRO: paymentCash,
      FIDELIDADE: paymentCash,
      QRLINX: QRLINX,
      ["QR LINX"]: QRLINX,
      [`CRÉDITO`]: CREDITO,
      CREDITO,
      [`DÉBITO`]: DEBITO,
      DEBITO,
      VOUCHER,
      OUTROS: paymentOther(data),
      QRCODE: paymentOther(data, "QRCODE"),
      PIX: paymentQrCode("PIX"), //TODO - Precisa trabalhar melhor
    };

    let methodName = transactionType.replace(/\[TEF\]|\[POS\]/g, "").trim();

    if (isQrLinx && data?.payments?.length <= 1) {
      methodName = "QRLINX";
    } else if (isQrCode) {
      methodName = "QRCODE";
    }

    if (data.paymentOneBaseCode === PAYMENT_TYPE_ENUM.Money) {
      sessionStorage.setItem(PAYMENT_METHOD_KEY_NAME, "DINHEIRO");

      return paymentCash();
    }

    if (Object.keys(paymentMethods).find((name) => name === methodName)) {
      const executeMethod = paymentMethods[methodName];
      sessionStorage.setItem(PAYMENT_METHOD_KEY_NAME, methodName);

      return executeMethod();
    } else {
      showAlert({
        message: "Finalizador não detectado!",
      });

      return;
    }
  };

  const everyItensReshop = itemsInCart.every(
    (item) => item?.ClassSaleStep.getSteps().categoryIsFidelity === true
  );

  useEffect(() => {
    if (itemsInCart?.length > 0) {
      const { clientValue, reshopValue } = reshopCalculateValues(itemsInCart);

      localStorage.setItem("clientValue", JSON.stringify(clientValue));
      localStorage.setItem("reshopValue", JSON.stringify(reshopValue));

      const sendRehopValue = parseFloat(reshopValue?.toFixed(2));

      if (everyItensReshop) {
        setIsLoadingValidate(true);
      }

      if (everyItensReshop && !window.sendingToFinishPayment) {
        window.sendingToFinishPayment = true;
        setIsLoadingValidate(true);
        navigate(PagesEnum.PAYMENT_CASH_METHOD, {
          replace: true,
          state: {
            PAYMENT_METHOD: "FIDELIDADE",
            totalPriceToPayNow: sendRehopValue,
            totalChangeMoney: 0,
            isAllItensFidelity: true,
          },
        });
      } else if (
        !window.sendingToFinishPayment ||
        (!window.sendingToFinishPayment && !everyItensReshop)
      ) {
        verifyRedeemValue(itemsInCart)
          .then(() => {
            window.sendingToFinishPayment = false;
            setIsLoadingValidate(false);
          })
          .catch(() => {
            window.sendingToFinishPayment = false;
            setIsLoadingValidate(false);
          });
      }
    }
  }, [location, isLoadingValidate, reshop, itemsInCart, everyItensReshop]);

  useEffect(() => {
    removeSessionsStorageHelper([
      PAYMENT_METHOD_KEY_NAME,
      "@AAOne:messageTef",
      "@AAOne:modalWarnPayment",
    ]);

    getPaymentsMethodService().then(async (listMethods) => {
      if (listMethods) {
        if (localStorage.getItem("testqa") === "yes") {
          listMethods.unshift({
            image: "",
            transactionType: "TESTEQA",
          });
        }

        setMethods(listMethods);
      }
    });
  }, [location]);

  return (
    <Container>
      <div className="content__methods">
        <HeaderPaymentMethod
          onClickBackButton={handleBackToHome}
          disabledBackButton={isLoadingValidate}
        >
          Qual a forma de pagamento?
        </HeaderPaymentMethod>
        {isLoadingValidate ? (
          <>
            {everyItensReshop ? (
              <LoadingWhiteWithText>Finalizando...</LoadingWhiteWithText>
            ) : (
              <div className="loading__container">
                <LoadingDots />
              </div>
            )}
          </>
        ) : (
          <div className="content__container">
            <div className="methods__wrapper">
              {methods?.length > 0 && (
                <ul className="methods__list">
                  {methods.map((method, index) => {
                    const isTestQa = method.transactionType === "TESTEQA";

                    const methodName = String(
                      method?.description ?? ""
                    ).toUpperCase();

                    return (
                      <li
                        key={String(`${methodName}-${index}`)}
                        className={classNames({ isTestQa })}
                        onClick={() => {
                          startTransaction(method.transactionType, method);
                        }}
                      >
                        <div className="icon">
                          {!isTestQa ? (
                            <img src={method?.image} alt={methodName} />
                          ) : (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              version="1.1"
                              viewBox="0 0 16 16"
                              height="2rem"
                              width="2rem"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M16 9v-1h-3.020c-0.092-1.136-0.497-2.172-1.12-3.004h2.53l1.095-4.379-0.97-0.243-0.905 3.621h-2.729c-0.014-0.011-0.028-0.021-0.042-0.032 0.105-0.305 0.162-0.632 0.162-0.972 0-1.653-1.343-2.992-3-2.992s-3 1.34-3 2.992c0 0.34 0.057 0.667 0.162 0.972-0.014 0.011-0.028 0.021-0.042 0.032h-2.729l-0.905-3.621-0.97 0.243 1.095 4.379h2.53c-0.623 0.832-1.028 1.868-1.12 3.004h-3.020v1h3.021c0.059 0.713 0.242 1.388 0.526 1.996h-1.937l-1.095 4.379 0.97 0.243 0.905-3.621h1.756c0.917 1.219 2.303 1.996 3.854 1.996s2.937-0.777 3.854-1.996h1.756l0.905 3.621 0.97-0.243-1.095-4.379h-1.937c0.283-0.608 0.466-1.283 0.526-1.996h3.021z"></path>
                            </svg>
                          )}
                        </div>
                        <span>{methodName}</span>
                      </li>
                    );
                  })}
                </ul>
              )}

              <div className="buttons__actions">
                <button
                  onClick={handleBackToHome}
                  role="button"
                  className="button"
                >
                  Continuar comprando
                </button>
                {paymentSuccess && paymentSuccess?.length > 0 && (
                  <button
                    role="button"
                    onClick={handleCancelOperation}
                    className="button button__primary"
                  >
                    Cancelar venda
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
