import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Cart } from "../../shared/components/modules";
import { toggleCart } from "../../actions/cart";

const CartContainer = (props) => {
  let [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const isVisibleCart = useSelector((state) => state.cart.toggle);

  function showCart(value) {
    const _isVisible = value ? !isVisible : false;
    setIsVisible(_isVisible);
    dispatch(toggleCart(_isVisible));

    value
      ? setIsVisible((isVisible = !isVisible))
      : setIsVisible((isVisible = false));
  }

  return (
    <Cart expand={isVisibleCart} showCart={showCart} modal={props.statusModal} />
  );
};

export default CartContainer;
