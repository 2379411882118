import styled from "styled-components";

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 2px solid rgba(128, 128, 128, 0.27);
  width: 420px;
  height: 320px;
  margin-left: 20px;
  border-radius: 5px;
  overflow-y: scroll;
  max-height: 320px;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 0 20px;
  border-bottom: 2px solid #e6e6e6;
  min-height: 70px;

  &:hover {
    background: #f9f9f9;
  }
`;

export const RadioInput = styled.input`
  margin-right: 8px;
  width: 50px;
  height: 20px;
`;

export const RadioLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #2e0037;
  cursor: pointer;
  padding-bottom: 20px;
  width: 100%;
`;

export const DirectionalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 160px;
  flex-direction: column;
  position: absolute;
  right: 20px;
`;

export const DirectionalArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 145px;
  border-radius: 5px;
  padding: 0;
  background-color: #fff;
  font-size: 2em;
  color: #2e0037;
  border: 2px solid #2e0037;
  z-index: 3;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 440px;
  padding-top: 20px;
`;

export const ButtonContent = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  margin-right: 20px;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 0.5rem 0.5rem;
  width: 100%;

  text-transform: uppercase;
  color: #fff;

  width: 150px;
  height: 50px;

  cursor: pointer;

  &.button__white {
    background-color: #fff;
    border: 2px solid #2e0037;
    color: #2e0037;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 10px;

  &.send {
    background-color: #2e0037;
    color: #fff;
  }
}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(206, 206, 206, 0.47);

  &.status {
    border: 0;
    font-size: 1.5rem;
    justify-content: center;
  }

  .title {
    color: #2e0037;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0 1rem;
  }

  .button__close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
  }

  strong {
    font-size: 2rem;
    color: #2e0037;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: 550px;

  background-color: #fff;
  min-width: 530px;
  width: 530px;

  position: relative;

  border-radius: 0.5rem;
  margin: 0 auto;
`;

export const HeaderContent = styled.div`
  position: sticky;
  bottom: 0;
  padding: 10px;

  &.title {
    width: 100%;
  }
`;

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;

  transition: all 0.5s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  z-index: 100;

  padding: 1rem;
`;
