import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper, AnchorWrapper } from './style';

export const Button = forwardRef((props, ref) => {
  const handleClickAction = (e) => {
    if (props.handleClick) {
      props.handleClick(e);
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  const handleKeyAction = (e) => {
    if (e.charCode === 32) { // space
      handleClickAction();
    } else if (props.onKeyPress) {
      props.onKeyPress();
    }
  };

  const Component = props.type === 'link' ? AnchorWrapper : ButtonWrapper;

  return (
    <Component
      tabIndex="0"
      innerRef={ref}
      {...props}
      onClick={handleClickAction}
      onKeyPress={handleKeyAction}
    >
      {props.icon && (<i className="material-icons">{props.icon}</i>)}
      {props.children}
    </Component>
  );
});

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object]),
  type: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  children: null,
  type: 'button',
  icon: null,
  className: '',
  handleClick: null,
  onClick: null,
  onKeyPress: null,
};

export default Button;
