import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../shared/components/modules";

import { process } from "../../../services/processService";
import { getProductsAll } from "../../../services/productService";
import { loadCategoryProducts } from "../../../services/catalogService";

import { showFidelityPage } from "../../configuration";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { frontConfig } from "../../../services/frontConfigService";
import configuration, {
  getShowOptionToEat,
  isDisabledStartButton,
} from "../../configuration";

import { useLayoutAa } from "../../contexts/layout";
import { DEFAULT_LOCAL_TEXTS } from "../../constants/layout";
import { I18n } from "../../shared/components/I18n";
import { useViewDispatch } from "../../../context";
import { TOKEN_RESHOP_AUTH_KEY_NAME } from "../../../pdv-one/constants/keyNamesSessionStorage";
import { apiProducts } from "../../../services/api";

const BannerContainer = (props) => {
  const viewDispatch = useViewDispatch();
  const { t } = useTranslation();

  const {
    isTranslationActive,
    changeLanguage,
    getTranslateName,
    getFieldScreenBySlug,
    getScreenIsActiveBySlug,
    getScreenBySlug,
    isLayoutAAOne,
    disableAccessibilityColor,
    setStatusActiveAccessibilityLabel,
  } = useLayoutAa();

  const [banners, setBanners] = useState([]);
  const [productsResgate, setProductsResgate] = useState([]);
  const startButtonText =
    getTranslateName(t, "start_button") ||
    getFieldScreenBySlug(0, "APRESENTACAO")?.description ||
    DEFAULT_LOCAL_TEXTS.start_button;
  const isActiveStartButton =
    getScreenIsActiveBySlug("APRESENTACAO") ?? !isDisabledStartButton;
  const isShowFidelityPage =
    getScreenIsActiveBySlug("FIDELIDADE_DIGITE_CPF") ?? showFidelityPage;

  const clearInfosInMemory = useCallback((param) => {
    param.forEach((item) => {
      window.localStorage.removeItem(item);
      window.sessionStorage.removeItem(item);
    });
  }, []);

  const activeResetModal = useCallback(() => {
    props.modalTimer(true);
  }, [props]);

  useEffect(() => {
    if (props.modalTimer) {
      props.modalTimer(false);
    }

    frontConfig();

    apiProducts.get("/api/banners/rest-screen").then((resp) => {
      if (!!resp?.data?.banners) {
        setBanners(resp.data.banners);
      }
    });

    // process().then((data) => {
    //   if (!!data?.payload.length) {
    //     const banners = data.payload[0].banners;
    //     setBanners(banners);
    //   }
    // });

    loadCategoryProducts(39).then((res) => {
      let productsResgate = [];

      res.products.forEach((item) =>
        productsResgate.push({
          description: item.product.description,
          image: item.product.image,
          catalogImage: item.product.catalogImage,
          featuredImage: item.product.featuredImage,
          productCode: item.product.productCode,
          value: item.product.value,
          id: item._id,
        })
      );

      setProductsResgate(productsResgate);
    });

    loadCategoryProducts(36).then((res) => {
      let productsResgate = [];

      res.products.forEach((item) =>
        productsResgate.push({
          description: item.product.description,
          image: item.product.image,
          catalogImage: item.product.catalogImage,
          featuredImage: item.product.featuredImage,
          productCode: item.product.productCode,
          value: item.product.value,
          id: item._id,
        })
      );

      setProductsResgate(productsResgate);
    });

    getProductsAll();

    clearInfosInMemory([
      "cpf",
      "reshopCampanhas",
      "descontoReshop",
      "paymentTypeForReshop",
      "name",
      "nome",
      "saldoAtual",
      "cpfDocument",
      "phoneNumber",
      "clientValue",
      "PontosCreditados",
      "reshopValue",
      "NsuReshop",
      "mensagemCampanha",
      "nomeCampanha",
      "NotEnoughPoints",
      "IdTransacao",
      "ValorPonto",
      "paymentTransition",
      "getPointsTotal",
      "numeroOperacao",
      "saleControl",
      "payments",
      "ContingenciaReshop",
      TOKEN_RESHOP_AUTH_KEY_NAME,
      "prismaNumber",
      "msg",
      "messageTef",
      "messageTefWarnings",
      "typeCode",
      "storageContent",
      "carIdentification",
      "contador",
      "contadorPayment",
      "returnPayment",
      "prismakey",
      "tellkey",
      "QuantityCombo",
      "selectedTotalCombo",
      "adultConsumption",
      "paymentsucces",
      "msgStone",
      "promoCode",
      "paymentResponseStone",
      "itensReshopForDiscount",
      "NsuForConsult",
      "pagerNumber",
    ]);

    if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.tef.undo", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearInfosInMemory]);

  const nextStep = useCallback(() => {
    setStatusActiveAccessibilityLabel(false);
    const optionToEatRetaguarda = getScreenBySlug("OPCAODECOMER");

    const showOptionToEat =
      getShowOptionToEat() || optionToEatRetaguarda?.optionToEatPage || false;

    if (
      localStorage.getItem("styleAA") === "DRIVE" ||
      showOptionToEat === "V" ||
      showOptionToEat === "L"
    ) {
      window.localStorage.setItem("typeCode", showOptionToEat);
      if (!isLayoutAAOne || (isLayoutAAOne && isShowFidelityPage)) {
        viewDispatch("step.fidelity");
      } else {
        viewDispatch("step.home");
      }
    } else {
      viewDispatch("step.withdrawn");
    }
  }, [viewDispatch]);

  window.localStorage.setItem(
    "productsResgate",
    JSON.stringify(productsResgate)
  );

  window.localStorage.setItem("pdvCode", configuration.terminalid);
  window.localStorage.setItem("endPoint", configuration.endPoint);

  const slide = banners.map((url) => {
    return (
      <Slide index={banners[url]} key={url._id}>
        <img className="banner-tablet" src={url.image} alt={url.description} />
      </Slide>
    );
  });

  useEffect(() => {
    changeLanguage("pt");
    setStatusActiveAccessibilityLabel(true);
    disableAccessibilityColor();
  }, [setStatusActiveAccessibilityLabel, disableAccessibilityColor]);

  return (
    <>
      {isTranslationActive && <I18n className="flags-language" />}
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={banners.length}
        isPlaying={true}
        className="fade"
        interval="5000"
        onClick={() => {
          nextStep();
          activeResetModal();
        }}
      >
        <Slider>{slide}</Slider>

        {isActiveStartButton && (
          <Button
            className="bobsTouch"
            text={startButtonText}
            buttonUIType="primary"
            buttonUI="highlight"
            handleClick={() => nextStep()}
          />
        )}
      </CarouselProvider>
    </>
  );
};

export default React.memo(BannerContainer);
