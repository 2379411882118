import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "./styles";
import { HeaderPaymentMethod } from "../../../components/Header/paymentMethod";
import { KeyboardProvider } from "../../../contexts/KeyboardContext";
import { PagesEnum } from "../../../constants/pagesEnum";
import { CPF_RESHOP_KEY_NAME } from "../../../constants/keyNamesSessionStorage";
import { useCart } from "../../../hook/cartHook";
import { getCouponService } from "../../../../services/reshop/getCouponService";
import { Loading3Quarters } from "../../../components/Loading3Quarters";
import { useDialog } from "../../../hook/dialogHook";
import { loadProductsFidelity } from "../../../../services/catalogService";
import FidelitySelectProducts from "../../../components/fidelity/selectProducts";

export const FidelityCouponPage = () => {
  const navigate = useNavigate();
  const { showAlert } = useDialog();

  const { addCouponToCart } = useCart();

  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, [navigate]);

  const handleOnSubmit = useCallback(
    async (e) => {
      setIsSendingForm(true);

      e.preventDefault();

      const data = new FormData(e.target);
      const couponValue = data.get("coupon");

      if (!isSendingForm && couponValue && couponValue?.trim().length > 0) {
        const cpfFidelity = sessionStorage.getItem(CPF_RESHOP_KEY_NAME) || 0;

        try {
          const redeemedCoupon = await getCouponService(
            couponValue,
            cpfFidelity
          );

          if (redeemedCoupon) {
            addCouponToCart(couponValue, redeemedCoupon);

            if (redeemedCoupon?.Campanhas.length > 0) {
              const couponRule = redeemedCoupon?.Campanhas[0].ProdutosRegra.map(
                (rule) => {
                  if (rule.CodigoProduto) {
                    return [
                      {
                        ProductCode: rule.CodigoProduto,
                        sku: "",
                        points: 0,
                      },
                    ];
                  } else {
                    const products = [];

                    rule.ListaProdutos.forEach((ProductCode) =>
                      products.push({ ProductCode, sku: "", points: 0 })
                    );

                    return products;
                  }
                }
              );

              let productsPromisse = [];

              await couponRule.forEach((rule, i) => {
                productsPromisse.push(
                  loadProductsFidelity(undefined, rule).then((res) => {
                    return {
                      quantity:
                        redeemedCoupon?.Campanhas[0].ProdutosRegra[i]?.Qtde ??
                        1,
                      products: res.products,
                      selected: [],
                    };
                  })
                );
              });

              Promise.all(productsPromisse).then((res) => {
                const rules = [];

                res.forEach((rule) => {
                  rules.push(rule);
                });

                setSelectedCampaign({
                  campaignName: redeemedCoupon?.Campanhas[0].NomeCampanha,
                  promoCode: couponValue,
                  rules,
                });
              });
            } else {
              navigate(PagesEnum.HOME, {
                state: { coupon: couponValue },
                replace: true,
              });
            }
          } else {
            showAlert({
              title: "Ocorreu um erro!",
              message: "Erro desconhecido",
            });
          }
        } catch (error) {
          showAlert({
            title: "Ocorreu um erro!",
            message: error?.message ?? "Erro desconhecido",
          });
        }
      } else {
        showAlert({
          title: "Ocorreu um erro!",
          message: "Cupom está vazio!",
        });
      }

      setIsSendingForm(false);
    },
    [isSendingForm]
  );

  return (
    <KeyboardProvider>
      {(selectedCampaign?.rules?.length ?? 0) === 0 ? (
        <Container>
          <div className="content__wrapper">
            <HeaderPaymentMethod onClickBackButton={handleBackToHome}>
              Resgatar cupom reshop
            </HeaderPaymentMethod>
            <form onSubmit={handleOnSubmit} className="form__container">
              <input
                data-use-touch-keyboard
                type="text"
                name="coupon"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                role="presentation"
                placeholder="Insira o código do cupom"
                onChange={(e) => {
                  setIsDisabledButton(e?.currentTarget.value.length === 0);

                  e.currentTarget.value = e?.currentTarget.value.toUpperCase();

                  return e;
                }}
              />
              <button
                className="button button__submit"
                disabled={isDisabledButton || isSendingForm ? "disabled" : ""}
                type="submit"
              >
                {isSendingForm ? (
                  <span>
                    <Loading3Quarters /> Buscando...
                  </span>
                ) : (
                  "Buscar cupom"
                )}
              </button>
            </form>
          </div>
        </Container>
      ) : (
        <FidelitySelectProducts
          breadcrumbName={"Promocode"}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
        />
      )}
    </KeyboardProvider>
  );
};
