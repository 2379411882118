import { AgGridColumn } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { agGridLocalePtBr } from "../../../helpers/ag-grid-locale-pt-br";
import Button from "../../Button";
import { ModalBody, ModalHeader } from "./style";
import { currencyMask } from "../../../helpers/masks";
import { formatDateHelper } from "../../../helpers/formatDateHelper";
import classNames from "classnames";
import { isSmartPos } from "../../../../aaone/configuration";
import { CardContentSmartPos } from "../../CardContentSmartPos";
import { DetailNoteSmartPos } from "./smartPosDetail";

const customStyle = {
  content: {
    overflow: "hidden",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.75)",
    display: "block",
  },
};

const DISCOUNT_TYPES = {
  MANUAL: 1,
  RESHOP: 2,
  RESHOP_CAMPANHA: 3,
  FOURALL: 4,
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  width: 100,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
};

export const NoteDetails = (props) => {
  const { showModal, noteDetails, setOpenNoteDetails } = props;
  const [historyModal, setHistoryModal] = useState(false);

  if (!noteDetails) return null;

  const line1details = [
    { title: "Nº", propName: "receiptNumber" },
    { title: "Série", propName: "receiptSeries" },
    { title: "Status", propName: "status" },
    { title: "PDV", propName: "posId" },
    { title: "Data Atendimento", propName: "dtAtendimento" },
    { title: "Data Autorização", propName: "dtAutorizacao" },
    { title: "Protocolo", propName: "protocolo" },
  ];

  const line2details = [
    { title: "Chave acesso", propName: "keyaccess" },
    { title: "Motivo", propName: "motivoDetalhes" },
  ];

  const getSizes = (detail) => {
    switch (detail.propName) {
      case "receiptNumber":
        return { flex: "1 0 1%" };

      case "receiptSeries":
        return { flex: "1 0 1%" };

      case "status":
        return { flex: "1 0 1%" };

      case "posId":
        return { flex: "1 0 2%" };

      case "dtAtendimento":
        return { flex: "1 0 10%" };

      case "dtAutorizacao":
        return { flex: "1 0 10%" };

      case "protocolo":
        return { flex: "1 0 15%" };

      // 2ª linha
      case "keyaccess":
        return { flex: "1 0 39%" };

      case "motivoDetalhes":
        return { flex: "1 0 60%" };

      default:
        break;
    }
  };

  // const reduceDiscount = (previousValue, currentDiscount) =>
  //   previousValue + currentDiscount.totalDiscountValue;

  return (
    <>
      <Modal
        isOpen={showModal}
        closeOnOverlayClick={true}
        center
        style={customStyle}
        ariaHideApp={false}
      >
        <ModalHeader className={classNames({ isSmartPos })}>
          <span>DETALHES DA NOTA</span>

          <div>
            {!isSmartPos && (
              <Button
                className="btn-purple"
                onClick={() => setHistoryModal(true)}
                style={{ marginRight: "1.125rem" }}
              >
                Histórico
              </Button>
            )}
            <Button
              className="btn-purple"
              onClick={() => setOpenNoteDetails(null)}
            >
              Fechar
            </Button>
          </div>
        </ModalHeader>

        <ModalBody className={classNames({ isSmartPos })}>
          {isSmartPos ? (
            <DetailNoteSmartPos data={noteDetails} />
          ) : (
            <>
              <div className="note-details">
                <div className="row">
                  {line1details.map((detail) => (
                    <div
                      className="detail"
                      style={getSizes(detail)}
                      key={detail.propName}
                    >
                      <p>{detail.title}</p>
                      <span
                        style={
                          {
                            //...(detail.propName === "status" && getNoteStatusColor(noteDetails["status"])),
                          }
                        }
                      >
                        {noteDetails[detail.propName]}
                      </span>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {line2details.map((detail) => {
                    return (
                      <div
                        className="detail"
                        style={getSizes(detail)}
                        key={detail.propName}
                      >
                        <p>{detail.title}</p>
                        <span>{noteDetails[detail.propName]}</span>
                        <div>
                          {noteDetails.danfeUrlFiscalFlow && (
                            <img
                              src={`${noteDetails.danfeUrlFiscalFlow.substring(
                                0,
                                noteDetails.danfeUrlFiscalFlow.length - 4
                              )}.png`}
                              alt="Não foi possível carregar a imagem!"
                              className="img-note"
                              onError={(e) => {
                                e.target.style.display = "none";
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {noteDetails.items && (
                <>
                  <div
                    className="ag-theme-balham pdv"
                    style={{ width: "calc(100% - 2.5rem)", overflow: "auto" }}
                  >
                    <AgGridReact
                      rowData={noteDetails.items}
                      localeText={agGridLocalePtBr}
                      getRowNodeId={(data) => data.productId}
                      suppressContextMenu
                      defaultColDef={defaultColDef}
                      rowBuffer={0}
                      suppressHorizontalScroll
                      domLayout="autoHeight"
                      maxHeight="350px"
                    >
                      <AgGridColumn
                        suppressMenu
                        lockPosition
                        field="sequentialId"
                        headerName="Item"
                        flex={0.17}
                      />
                      <AgGridColumn
                        suppressMenu
                        lockPosition
                        field="product.referenceId"
                        headerName="Cód. Produto"
                        flex={0.3}
                      />
                      <AgGridColumn
                        suppressMenu
                        lockPosition
                        field="product.name"
                        headerName="Produto"
                        flex={0.9}
                      />
                      <AgGridColumn
                        suppressMenu
                        lockPosition
                        field="quantity"
                        headerName="Quantidade"
                        flex={0.25}
                      />
                      <AgGridColumn
                        suppressMenu
                        lockPosition
                        field="product.price"
                        headerName="Valor Unitário"
                        flex={0.25}
                        valueFormatter={({ value }) => {
                          return currencyMask(value);
                        }}
                      />
                      <AgGridColumn
                        suppressMenu
                        lockPosition
                        field="subTotal"
                        headerName="Valor Total"
                        flex={0.25}
                        valueFormatter={({ value }) => {
                          return currencyMask(value);
                        }}
                      />
                    </AgGridReact>
                  </div>
                  <div className="payment-details">
                    <div>
                      <div className="payment-total">
                        <div className="subtotal">
                          <div className="label">Subtotal</div>
                          <div className="value">
                            {typeof noteDetails.total === "number"
                              ? currencyMask(noteDetails.total)
                              : noteDetails.total}
                          </div>
                        </div>

                        <div className="discount">
                          <div className="label">Desconto</div>
                          <div className="value">
                            {currencyMask(noteDetails.discount)}
                          </div>
                        </div>

                        {noteDetails.appliedDiscount.map((discount, index) => {
                          switch (discount.type) {
                            // case DISCOUNT_TYPES.MANUAL:
                            //   return (
                            //     <div key={index} className="discount">
                            //       <div className="label">Desconto</div>
                            //       <div className="value">{currencyMask({ value: discount.totalDiscountValue })}</div>
                            //     </div>
                            //   );

                            case DISCOUNT_TYPES.RESHOP:
                              return (
                                <div key={index} className="discount">
                                  <div className="label">Desconto Reshop</div>
                                  <div className="value">
                                    {" "}
                                    {currencyMask({
                                      value: discount.totalDiscountValue,
                                    })}
                                  </div>
                                </div>
                              );

                            case DISCOUNT_TYPES.RESHOP_CAMPANHA:
                              return (
                                <div key={index} className="discount">
                                  <div className="label">Desconto Campanha</div>
                                  <div className="value">
                                    {" "}
                                    {currencyMask({
                                      value: discount.totalDiscountValue,
                                    })}
                                  </div>
                                </div>
                              );

                            case DISCOUNT_TYPES.FOURALL:
                              return (
                                <div key={index} className="discount">
                                  <div className="label">Desconto 4All</div>
                                  <div className="value">
                                    {" "}
                                    {currencyMask({
                                      value: discount.totalDiscountValue,
                                    })}
                                  </div>
                                </div>
                              );
                            default:
                              return <React.Fragment key={index} />;
                          }
                        })}

                        {noteDetails?.deliveryInfo && (
                          <div className="subtotal">
                            <div className="label">Taxa de entrega</div>
                            <div className="value">
                              {currencyMask(
                                noteDetails.deliveryInfo.deliveryTax || 0
                              )}
                            </div>
                          </div>
                        )}

                        {/* <div className="discount">
                  <div className="label">Desconto Reshop</div>
                  <div className="value">{currencyMask({ value: noteDetails.appliedDiscount.filter(discountItem => discountItem.type === 2).reduce(reduceDiscount, 0) })}</div>
                </div> */}

                        {/* <div className="discount">
                  <div className="label">Desconto Campanha</div>
                  <div className="value">{currencyMask({ value: noteDetails.appliedDiscount.filter(discountItem => discountItem.type === 3).reduce(reduceDiscount, 0) })}</div>
                </div> */}

                        <div className="total">
                          <div className="label">Total</div>
                          {/* //NOTE - Segundo Jose Riberto o totalDiscount é o total e subTotal é o .total */}
                          <div className="value">
                            {typeof noteDetails.totalDiscount === "number"
                              ? currencyMask(noteDetails.totalDiscount)
                              : noteDetails.totalDiscount}
                          </div>
                        </div>
                      </div>
                      <div className="payment-forms">
                        {noteDetails.payments.map((payment) => (
                          <div className="payment" key={payment.id}>
                            <div className="label">
                              {payment.brand?.brandName
                                ? `${payment.brand?.brandName ?? ""}`
                                : payment.paymentType.name}
                            </div>
                            <div className="value">
                              {currencyMask(payment.amount)}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={historyModal}
        closeOnOverlayClick={true}
        center
        style={customStyle}
        ariaHideApp={false}
      >
        <ModalHeader>
          <span>HISTÓRICO DA NOTA</span>
          <Button className="btn-purple" onClick={() => setHistoryModal(false)}>
            Fechar
          </Button>
        </ModalHeader>
        <ModalBody>
          <div
            className="ag-theme-balham pdv"
            style={{ width: "calc(100% - 2.5rem)", overflow: "auto" }}
          >
            <AgGridReact
              rowData={noteDetails?.fiscalDocument?.history || []}
              localeText={agGridLocalePtBr}
              getRowNodeId={(data) => data.productId}
              suppressContextMenu
              defaultColDef={defaultColDef}
              rowBuffer={0}
              suppressHorizontalScroll
              domLayout="autoHeight"
              maxHeight="350px"
            >
              <AgGridColumn
                suppressMenu
                lockPosition
                field="accessKey"
                headerName="Chave de Acesso"
                flex={0.24}
              />
              <AgGridColumn
                suppressMenu
                lockPosition
                field="attemptedDate"
                headerName="Data de tentativa"
                valueFormatter={(params) =>
                  formatDateHelper(
                    new Date(params.value),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                }
                flex={0.15}
              />
              <AgGridColumn
                suppressMenu
                lockPosition
                field="reason"
                headerName="Motivo"
                flex={0.42}
              />
            </AgGridReact>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
