import React, { useCallback, useEffect, useRef, useState } from "react";

import { Container } from "./styles";
import { escOnlyNumbersHelper } from "../../../helpers/escOnlyNumbersHelper";
import { KeyboardNumeric } from "../../KeyboardNumeric";
import { useCart } from "../../../hook/cartHook";

export const ModalQuantityProduct = ({ handleClose }) => {
  const inputRef = useRef(null);
  const [keyboardActions, setKeyboardActions] = useState();
  const { setQuantityToCart } = useCart();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    try {
      const totalQuantity = Number(
        escOnlyNumbersHelper(data.get("total") ?? "1")
      );

      if (totalQuantity <= 0) {
        alert("Valor tem que ser maior que zero");
        return;
      }

      setQuantityToCart(totalQuantity);

      const qtdEle = document.querySelector(
        `.item__button.item__num.item__quantity__${totalQuantity}`
      );

      if (qtdEle) {
        qtdEle.click();
      }

      handleClose();
    } catch (error) {
      alert("Não foi possível inserir quantidade, ocorreu um erro inesperado");
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (keyboardActions?.value && inputRef?.current) {
      const value = escOnlyNumbersHelper(keyboardActions.value);

      inputRef.current.value = value;
      keyboardActions.set(value);
    }
  }, [keyboardActions?.value, inputRef]);

  return (
    <Container>
      <div className="content">
        <form onSubmit={handleSubmit} className="form__container">
          <input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            role="presentation"
            readOnly
            autoFocus
            onFocus={(e) => {
              e.currentTarget.focus();
              e.currentTarget.removeAttribute("readOnly");
            }}
            onBlur={(e) => e.currentTarget.focus()}
            onChange={(e) => {
              e.target.value = escOnlyNumbersHelper(e.target.value);

              if (keyboardActions?.set) {
                keyboardActions.set(e.target.value);
              }

              return e;
            }}
            ref={inputRef}
            name="total"
            id="total"
            placeholder="Quantidade"
          />
          <KeyboardNumeric
            newButtons={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              {
                className: "backspace",
                value: "←",
              },
              "0",
              {
                className: "close",
                value: "❌",
                onClick: handleClose,
              },
            ]}
            setActions={setKeyboardActions}
          />
          <button
            className="button button__submit"
            disabled={keyboardActions?.value.length === 0}
            type="submit"
          >
            Aplicar
          </button>
        </form>
      </div>
    </Container>
  );
};
