import styled from "styled-components";

export const CustomCheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  .custom-checkbox {
    width: 25px;
    height: 25px;
  }

  .title {
    margin-left: 8px;
  }
`;

// button {
//   background: #ececec;
//     border: 1px solid darkgrey;
//     border-radius: 8px;
// }
