import { getDiscountsReshopService } from "./getDiscountsReshopService";

export const getActiveCampaignsReshopService = () => {
  const discountsReshop = getDiscountsReshopService();

  try {
    if (discountsReshop?.CampanhasAtivadas) {
      const { CampanhasAtivadas } = discountsReshop;

      return CampanhasAtivadas;
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const getActiveCampaignsDiscountsInItemService = () => {
  const activeCampaigns = getActiveCampaignsReshopService();

  if (activeCampaigns) {
    const campaigns = [];
    const campaignsWithDiscountsInItem = activeCampaigns.filter(
      (campaign) => campaign.DescontoNosItens
    );

    campaignsWithDiscountsInItem.forEach((campaign) => {
      const itemsCampaign = campaign.Itens;

      itemsCampaign.forEach((currentItem) => {
        const serializeCampaign = {
          code: campaign.Id,
          name: campaign.NomeCampanha,
          description: campaign.DescricaoCampanha,
          discount: campaign.ValorDescontoSubtotal,
          productCode: currentItem.Promocode,
          itemReshopIndex: null,
        };

        campaigns.push(serializeCampaign);
      });
    });

    return campaigns;
  }

  return [];
};

/**
 * ID is `CodigoProduto`
 */
export const getReshopActiveCampaignsByIdService = (productId) => {
  const activeCampaigns = getActiveCampaignsReshopService();

  if (activeCampaigns?.length > 0) {
    const campaigns = [];

    activeCampaigns.forEach((campaign) => {
      const itemsCampaign = campaign.Itens.filter(
        (itemInCampaign) => itemInCampaign.CodigoProduto === productId
      );

      itemsCampaign.forEach(() => {
        const serializeCampaign = {
          code: campaign.Id,
          name: campaign.NomeCampanha,
          description: campaign.DescricaoCampanha,
          discount: campaign.ValorDescontoSubtotal,
          productCode: productId,
          itemReshopIndex: null,
        };

        campaigns.push(serializeCampaign);
      });
    });

    return campaigns;
  }

  return [];
};
