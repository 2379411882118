import styled, { css } from "styled-components";
import { mediaQueryHelper } from "../../helpers/mediaQueryHelper";

export const Container = styled.main`
  background: radial-gradient(circle, #411e5a 0%, #2e0037 100%);

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  min-height: 100vh;
  height: 100%;
  flex: 1;

  .content-wrapper {
    max-width: 780px;
    width: 100%;

    padding: 2rem 1rem;
    margin: 0 auto;
  }

  .header-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    flex: 1;

    h1 {
      font-size: 3rem;
      color: #fff;
    }

    h2 {
      font-size: 1.5rem;
      color: #fff;
    }
  }

  .login-container {
    background-color: rgba(255, 255, 255, 0.2);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-radius: 1rem;
    margin-top: 1.5rem;
    padding: 2rem;
    font-weight: 700;

    input,
    select,
    button {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.5rem;
      padding: 0.5rem;

      width: 100%;

      border-radius: 1rem !important;

      background-color: #fff !important;
      border: 0;
    }

    button {
      font-family: "Dosis", sans-serif !important;
    }

    .inputs-container {
      padding: 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 1.25rem;
      margin-left: 0;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      flex: 1;

      margin-bottom: 1rem;

      label {
        font-size: 2rem;

        text-align: start;
        display: block;
        width: 100%;

        margin-bottom: 0.5rem;
        color: #fff;
      }

      input {
        -webkit-text-security: disc;
        text-security: disc;
      }
    }

    button[type="submit"],
    .button__submit {
      border-radius: 1.5rem;
      margin-top: 1rem;

      height: 54px;
      width: 100%;

      .loading-dots-container {
        margin-top: 1rem;

        .dots {
          width: 1.5rem;

          div {
            height: 1rem;
            width: 1rem;
          }
        }
      }
    }
  }

  h4.alerta-cash-desk {
    background: rgba(255, 0, 0, 0.36);
    padding: 0.3rem;
    color: white;
    border-radius: 0.7rem;
    text-transform: uppercase;
  }

  .keyboard-numeric {
    margin-top: 2rem;
    margin-left: 2rem;
    padding: 1rem;

    min-width: 300px;

    button {
      font-size: 1.5rem !important;

      &.button-clear-item {
        font-size: 0.85rem !important;
      }
    }
  }

  ${mediaQueryHelper("vertical").lessThan(css`
    .login-container {
      flex-direction: column;
      padding: 2rem 2rem 2rem 0;

      input,
      select,
      button {
        text-transform: uppercase;
        font-size: 3rem;
        border-radius: 1.5rem;
      }

      .inputs-container {
        padding: 1rem 1rem 2rem;
        margin-left: 2rem;
      }

      .form-group {
        label {
          font-size: 2.5rem;
        }
      }
    }

    .keyboard-numeric {
      margin-left: 2rem;

      .button {
        background-color: #fff !important;
        padding: 1rem 0;
      }
    }
  `)}

  ${mediaQueryHelper("t2Vertical").lessThan(css`
    .header-container {
      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.5rem;
      }
    }

    .login-container {
      padding: 1rem;

      input,
      select,
      button {
        font-size: 1.25rem;
      }

      label {
        font-size: 1.5rem;
      }

      .inputs-container {
        margin-left: 0;

        label {
          font-size: 1.25rem;
        }
      }
    }

    .keyboard-numeric {
      grid-gap: 0.5rem 0.5rem;
      gap: 0.5rem 0.5rem;
      margin: 0;
    }

    button[type="submit"],
    .button__submit,
    .keyboard-numeric .button {
      font-size: 1rem !important;
      padding: 0.5rem !important;
      height: auto !important;

      &.button-clear-item {
        font-size: 0.75rem !important;
    }
  `)}
`;
