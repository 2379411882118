import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
  } from 'react';
  export default forwardRef((props, ref) => {
    const { parentFilterInstance, inputName} = props;
  
  const [currentValue, setCurrentValue] = useState("");
  const inputRef = useRef(null);
  
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            onParentModelChanged(parentModel) {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    inputRef.current.value = '';
                    setCurrentValue('');
                } else {
                    inputRef.current.value = parentModel.filter + '';
                    setCurrentValue(parentModel.filter);
                }
            }
  
        }
    });
  
    const onInputBoxChanged = (input) => {
        if (input.target.value === "") {
        // clear the filter
            parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }
  
        
        setCurrentValue(input.target.value);
        parentFilterInstance(instance => {
            instance.onFloatingFilterChanged('contains', input.target.value);
        });
    }
  
    return (
        <div className='keyboardInput'>
            <input data-use-touch-keyboard className='ag-input-field-input ag-text-field-input' ref={inputRef} name={inputName} value={currentValue} type="text" min="0" onInput={onInputBoxChanged}/>
        </div>

    );
  });
  