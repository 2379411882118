import React from "react";
import { render } from "react-dom";
import PrintTemplate from "../../components/print-template";
import { formatDateHelper } from "../formatDateHelper";
import { currencyMask } from "../masks";
import { printerActionService } from "../../../services/actions/printerActionService";
import { lineToPrintHelper } from "../lineToPrintHelper";
import { printDefault } from "./printDefault";

const isMobile = !!window.Android;

export const handlePrintCoupon = (cashdesk) => {
  var html = document.createElement("html");

  render(
    <PrintTemplate>
      <>
        {!isMobile
          ? "--------------------------------------------------------"
          : lineToPrintHelper}
        <br />
        <div style={{ textAlign: "center" }}>
          <b>ABERTURA DE CAIXA</b>
        </div>
        {!isMobile
          ? "--------------------------------------------------------"
          : lineToPrintHelper}
        <br />
        PDV: {cashdesk.posId}
        {isMobile ? "\n" : ""}
        <br />
        <table>
          <tbody>
            <tr>
              <td className="shrink">Data Abertura:{isMobile ? " " : ""}</td>
              <td className="expand">
                {formatDateHelper(
                  new Date(cashdesk.openingDate),
                  "dd/MM/yyyy HH:mm:ss"
                )}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Abertura:{isMobile ? " " : ""}</td>
              <td className="expand">
                {cashdesk.openingCount}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Responsável:{isMobile ? " " : ""}</td>
              <td className="expand">
                {cashdesk.responsable}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Operador:{isMobile ? " " : ""}</td>
              <td className="expand">
                {cashdesk.user}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
            <tr>
              <td className="shrink">Fundo Inicial:{isMobile ? " " : ""}</td>
              <td className="expand">
                {currencyMask(cashdesk.movements[0].amount)}
                {isMobile ? "\n" : ""}
              </td>
            </tr>
          </tbody>
        </table>
        {!isMobile ? "==================================" : lineToPrintHelper}
        {isMobile ? "\n\n\n\n\n\n" : ""}
        <br />
      </>
    </PrintTemplate>,
    html
  );

  if (window.desktopApp) {
    printerActionService({
      body: html.outerHTML,
    });
  } else if (isMobile) {
    console.log(`printDefault | {}`);
    printDefault(html.outerHTML);
  }
};
