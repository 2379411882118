import styled, { keyframes } from "styled-components";

const slideAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideAnimationReverse = keyframes`
  from {
    box-shadow: 100vw 0 0 rgba(0, 0, 0, 0.3);
    transform: translateX(0);
  }
  to {
    box-shadow: -100vw 0 0 rgba(0, 0, 0, 0.3);
    transform: translateX(-100%);
  }
`;

export const ContainerSmartPos = styled.div`
  position: absolute;
  width: 100%;
  background: transparent;
  z-index: 1;

  display: flex;
  flex: 1;

  top: var(--menu-height, 0);
  transform: translateX(-100%);
  animation-iteration-count: 1;

  &.isHide {
    animation: ${slideAnimationReverse} 1s ease-in-out !important;
  }

  &.isCartVisible {
    animation: ${slideAnimation} 1s ease-in-out !important;
    box-shadow: 100vw 0 0 rgba(0, 0, 0, 0.3);
    transform: translateX(0);
  }

  .productsCart {
    position: relative;
    width: 359px;
    height: 100%;
  }
`;

export const Container = styled.aside`
  border-right: 1px solid #eee;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;

  position: relative;

  .loading__text {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    > span {
      margin-left: 0.5rem;
    }
  }

  .input-position {
    width: 55px;
    margin-left: 20px;
    margin-top: 3px;
    font-size: 15px;
    border-radius: 5px;
  }

  .font-table {
    font-size: 22px;
  }

  .font-tableNumber {
    margin-left: 15px;
  }

  .tableStyle {
    font-size: 32px;
    margin-top: 14px;
    align-self: center;
  }

  .container-sidebar {
    display: flex;
    flex-direction: column;

    padding: 0;
    grid-gap: 1rem 1rem;
    gap: 1rem 1rem;

    max-height: -webkit-fill-available;

    height: 100%;
    width: 100%;
    flex: 1;
    position: relative;
  }

  .reshop-wrapper {
    width: 100%;
    padding: 1rem;
  }

  .reshop-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;

    grid-gap: 1rem 1rem;
    gap: 1rem 1rem;

    width: 100%;

    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #411e5a;

    border-radius: 0.5rem;
    align-self: stretch;
    flex-grow: 0;
    flex: none;
    order: 0;

    .icon {
      margin-right: 0.5rem;
    }

    .details {
      border-right: 2px solid rgba(0, 0, 0, 0.3);

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      flex: 1;
      padding: 0;

      grid-gap: 0.25rem 0.25rem;
      gap: 0.25rem 0.25rem;

      margin-right: 0.25rem;

      text-transform: uppercase;

      .label {
        font-weight: 700;
        font-size: 1rem;
        overflow: hidden !important;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .value {
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .resgatar {
      font-weight: 700;
      font-size: 1rem;
    }
  }

  .descontos,
  .payments {
    display: flex;
    flex-direction: column;

    padding: 0.5rem 1rem;
    width: 100%;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      strong {
        font-weight: 700;
      }

      .price-with-button {
        display: flex;
        align-items: center;

        .btn-action-cart {
          margin-left: 1rem;
          color: red;
          background: transparent !important;
        }
      }
    }
  }

  .button__container-footer {
    width: 100%;
    display: flex;
  }

  .button__payment {
    background-color: rgba(46, 0, 55, 0.4);
    color: #fff;

    opacity: 1 !important;
    border-radius: 0 !important;
    width: 100%;

    transition: background-color 0.2s ease-in-out;

    cursor: pointer;

    &.button__active {
      background-color: rgba(46, 0, 55, 1);
    }
  }

  &.isSidebarRight {
    border-left: 1px solid #eee;
    border-right: 0;
  }

  .btn-action-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
`;

export const ContentCart = styled.div`
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  height: 100%;
  flex: 1;

  .hide {
    display: none !important;
  }

  &.content__space,
  .content__space {
    width: 100%;
    height: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;

    &.no-space {
      padding: 0 !important;
    }
  }

  .pdvcart-summary {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-right: 55px;
    margin-left: 0.5rem;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(46, 0, 55, 0.4);
    border-radius: 20px;
    border: 0;
  }
`;
