import React, { useState, useEffect } from "react";
import { SwitchWrapper, ToggleSwitch } from "./styles";
import { productsUnavailableEnum } from "../../constants/productsUnavailableEnum";
import { setUnavailable } from "../../../services/productService";
import { useLoading } from "../../hook/loadingHook";
import { isSmartPos } from "../../../aaone/configuration";

export const DropdownSet = (props) => {
  const [available, setAvailable] = useState();
  const { setShowLoading } = useLoading();

  useEffect(() => {
    const available = props.data.unavailableIn?.[0].type?.find(
      (unavailable) =>
        unavailable === productsUnavailableEnum[props.colDef.unavailableAt]
    );

    setAvailable(!available);
  }, [props]);

  const updateStatus = (where = []) => {
    try {
      setShowLoading();
      const returnsLevelOrientedSale = (item) => {
        return Object.keys(item).find((position) => {
          if (position.includes("nivel")) return position;
          else return undefined;
        });
      };

      const getNivelInItem = (item) => {
        const level = returnsLevelOrientedSale(item);

        if (typeof level !== "undefined") {
          return item[level]?.length > 0 ? item[level] : null;
        }

        return null;
      };

      const filterItems = (acc, curr) => {
        acc = [
          ...acc,
          {
            productCode: curr.productCode,
            type: where,
          },
        ];

        const level = getNivelInItem(curr);

        if (level) {
          let subItems = level.reduce(
            (_acc, _curr) => filterItems(_acc, _curr),
            []
          );

          acc = [...acc, ...subItems];
        }

        return acc;
      };

      const items = [props?.node?.data ?? props?.data].reduce(
        (acc, curr) => filterItems(acc, curr),
        []
      );

      setUnavailable({ items }).then(() => {
        if (props?.node?.allLeafChildren) {
          props.node.allLeafChildren.forEach((rowNode) => {
            rowNode.setData({
              ...rowNode.data,
              unavailableIn: [
                {
                  type: where,
                },
              ],
            });
            setShowLoading(false);
          });
        } else {
          setAvailable(false);
          setShowLoading(false);
        }

        // useCatalog.clearCatalog();
      });
    } catch (err) {
      console.log(err);
      setShowLoading(false);
    }
  };

  const handleUnavailable = () => {
    const types = props.data.unavailableIn?.[0].type;
    types.push(productsUnavailableEnum[props.colDef.unavailableAt]);

    updateStatus(types);
  };

  const handleAvailable = () => {
    const typeIndex = props.data.unavailableIn?.[0].type?.indexOf(
      productsUnavailableEnum[props.colDef.unavailableAt]
    );
    const where = props.data.unavailableIn?.[0].type;
    where.splice(typeIndex, 1);

    if (typeIndex >= 0) {
      updateStatus(where);
    }
  };

  return (
    <SwitchWrapper>
      <ToggleSwitch>
        <input
          type="checkbox"
          checked={available}
          onClick={() => (available ? handleUnavailable() : handleAvailable())}
        />
        <span className="slider round" />
      </ToggleSwitch>
    </SwitchWrapper>
  );
};
