import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input';

import { CashReduceWrapper, CashReduceHead, CashReduceBody, CashReduceFooter, InputGroup, ConfirmFormActions } from './style';
import Card from '../Card';
import Button from '../Button';

class CashReduce extends Component {
  submitHandler = event => {
    event.preventDefault();

    this.props.handleClick();
  };

  componentDidMount() {
    this.props.onCartHide();
  }

  render() {
    const {
      title,
      handleClose,
      openingAmount,
      amount,
      responsable,
      user,
      reduceDate,
      openingCount,
      posId,
      reduceTime,
      isBlindCashReduce
    } = this.props;

    return (
      <CashReduceWrapper>
        <Card title={title} handleClose={handleClose} closeEnable>

          <form onSubmit={e => this.submitHandler(e)}>
            <CashReduceHead>
              <InputGroup>
                <label>PDV</label>
                <input value={posId} readOnly disabled />
              </InputGroup>

              <InputGroup>
                <label>Data</label>
                <input value={reduceDate} readOnly disabled />
              </InputGroup>

              <InputGroup>
                <label>Hora</label>
                <input value={reduceTime} readOnly disabled />
              </InputGroup>

              <InputGroup>
                <label>Abertura</label>
                <input value={openingCount} readOnly disabled />
              </InputGroup>
            </CashReduceHead>

            <CashReduceBody>
              <InputGroup>
                <label>Responsável</label>
                <input value={responsable} readOnly disabled />
              </InputGroup>

              <InputGroup>
                <label>Operador</label>
                <input value={user} readOnly disabled />
              </InputGroup>
            </CashReduceBody>

            <CashReduceFooter>
              <InputGroup>
                <label>Fundo inicial de caixa</label>
                <CurrencyInput
                  value={openingAmount}
                  name=""
                  decimalSeparator=','
                  thousandSeparator='.'
                  readOnly
                  disabled
                />
              </InputGroup>

              { !isBlindCashReduce &&
                <InputGroup>
                  <label>Vendas brutas</label>
                  <CurrencyInput
                    value={amount}
                    name=""
                    decimalSeparator=','
                    thousandSeparator='.'
                    readOnly
                    disabled
                  />
                </InputGroup>
              }
            </CashReduceFooter>

            <ConfirmFormActions>
              <Button
                id="btn-confirm"
                className="btn-large btn-purple btn-custom"
                type="submit"
              >
                Confirmar
              </Button>
            </ConfirmFormActions>
          </form>
        </Card>
      </CashReduceWrapper>
    );
  }
}

export default CashReduce;
