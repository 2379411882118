import {
  getFiscalFlowEndpointConfig,
  getRetailerId,
  getSerieNFCe,
  getTerminalID,
} from "../aaone/configuration";
import { apiDegustOneOrder } from "./api";

/**
 * @param {*} params
{
  "retailerId": "812334e4-179a-41ab-a2b4-888ffad3457c",
  "createdAt": "yyyy-MM-dd"
}
 */

export const getHourlyReportService = async (req) => {
  try {
    const { data } = await apiDegustOneOrder.get(
      `api/Order/HourlyReport?retailerId=${req.retailerId}&createdAt=${req.createdAt}`
    );

    return data;
  } catch (error) {
    console.log("Error in getHourlyReportService ::", error);
    return Promise.reject(`Error in getHourlyReportService :: ${error}`);
  }
};

/**
 * @param {*} params
{
  "retailerId": "812334e4-179a-41ab-a2b4-888ffad3457c",
  "startDate": "yyyy-MM-dd"
  "endDate": "yyyy-MM-dd"
}
 */

export const getSaleByDayReportService = async (req) => {
  try {
    const { data } = await apiDegustOneOrder.post(
      `api/Order/SaleByDayReport?retailerId=${req.retailerId}&startDate=${req.startDate}&endDate=${req.endDate}`
    );

    return data;
  } catch (error) {
    console.log("Error in getSaleByDayReportService ::", error);
    return Promise.reject(`Error in getSaleByDayReportService :: ${error}`);
  }
};

/**
 * @param {*} params
{
  "retailerId": "812334e4-179a-41ab-a2b4-888ffad3457c",
  "startDate": "yyyy-MM-dd"
  "endDate": "yyyy-MM-dd"
}
 */

export const getFinishedOrders = async (req) => {
  try {
    const params = new URLSearchParams();
    params.set("RetailerId", req.retailerId);
    params.set("StartDate", req.startDate);
    params.set("EndDate", req.endDate);

    const url = `api/Order/finished?${params.toString()}`;

    const { data } = await apiDegustOneOrder.get(url);

    return data;
  } catch (error) {
    console.log("Error in getFinishedOrders ::", error);
    return Promise.reject(`Error in getFinishedOrders :: ${error}`);
  }
};

/**
 * @param {*} params
{
  "retailerId": "812334e4-179a-41ab-a2b4-888ffad3457c",
  "startDate": "yyyy-MM-dd"
  "endDate": "yyyy-MM-dd"
}
 */

export const getFinishedTefOrders = async (req) => {
  try {
    const params = new URLSearchParams();
    params.set("RetailerId", req.retailerId);
    params.set("StartDate", req.startDate);
    params.set("EndDate", req.endDate);

    const url = `api/Order/finished/tef?${params.toString()}`;

    const { data } = await apiDegustOneOrder.get(url);

    return data;
  } catch (error) {
    console.log("Error in getFinishedTefOrders ::", error);
    return Promise.reject(`Error in getFinishedTefOrders :: ${error}`);
  }
};

export const getListMonitorNotes = async (req) => {
  try {
    const params = new URLSearchParams();
    params.set("RetailerId", req.retailerId);
    params.set("StartDate", req.startDate);
    params.set("EndDate", req.endDate);
    params.set("Status", req.status);
    params.set("Type", req.type);

    const url = `api/Order/monitorNotes?${params.toString()}`;

    const { data } = await apiDegustOneOrder.get(url);

    return data;
  } catch (error) {
    console.log("Error in getListMonitorNotes ::", error);
    return Promise.reject(`Error in getListMonitorNotes :: ${error}`);
  }
};

/**
 * Cancela uma Nota e retorna os pagamentos e o status da mesma
 *
 * @typedef {object} jsonRes
 * @property {object} order
 * @property {string} order.id
 * @property {string} order.posId
 * @property {number} order.totalDiscount
 * @property {number} order.fiscalNumber
 * @property {object} order.fiscalCanceledDocument
 * @property {string} order.fiscalCanceledDocument.accessKey
 * @property {object[]} order.payments
 * @property {string} order.payments.id
 * @property {number} order.payments.amount
 * @property {string} order.payments.controlCode
 * @property {object} order.payments.paymentType
 * @property {number} order.payments.paymentType.enumValue
 * @property {boolean} order.autorizacaoPeloFront
 * @property {null} order.fiscalFlowBody
 * @property {string} order.createdAt
 * @property {string} message
 *
 * @typedef {object} jsonReq
 * @property {string} RetailerId
 * @property {string} FiscalFlowUrl
 * @property {string} OrderId
 * @property {number} CodigoUsuario
 * @property {string} PosId
 * @property {string} Justificativa
 * @property {number} FiscalType
 *
 * @param {...jsonReq} params
 * @returns {jsonRes}
 */
export const cancelOrderService = async (params) => {
  try {
    const url = `api/Order/cancelOrder`;

    const { data } = await apiDegustOneOrder.post(url, params);

    return data;
  } catch (error) {
    console.log("Error in cancelOrderService ::", error);
    return Promise.reject(
      error.response.data.message ?? `Error in cancelOrderService :: ${error}`
    );
  }
};

export const inutilizarNumeracaoService = async (params) => {
  try {
    const url = `api/Order/inutilizarNumeracao`;

    const { data } = await apiDegustOneOrder.post(url, params);

    return data;
  } catch (error) {
    console.log("Error in inutilizarNumeracaoService ::", error);
    return Promise.reject(`Error in inutilizarNumeracaoService :: ${error}`);
  }
};

export const consultarUltimaNota = async (retailerId, serie) => {
  try {
    const url = `api/ReceiptSequence/consultarUltimaNota`;

    const { data } = await apiDegustOneOrder.get(url, {
      params: { retailerId, serie },
    });

    return data;
  } catch (error) {
    console.log("Error in consultarUltimaNota ::", JSON.stringify(error));
    return Promise.reject(
      error.response.data ?? `Error in consultarUltimaNota :: ${error}`
    );
  }
};

/**
 *
 * @param {*} params
 * @param {*} params.orderId
 * @param {*} params.danfeJpg
 * @param {*} params.danfe56mm
 * @param {*} params.dateAndTime
 */
export const reauthorizeOrder = async (params) => {
  try {
    const { data } = await apiDegustOneOrder.post(`api/order/reauthorizeOrder`, { params });

    return data;
  } catch (error) {
    console.log("Error in reauthorizeOrder ::", error);
    return Promise.reject(`Error in reauthorizeOrder :: ${error}`);
  }
};

/**
 *
 * @param {*} params
 * @param {*} params.orderId
 */
export const calculateTaxOrderService = async (params) => {
  try {
    const url = `api/order/calculateTax`;

    const { data } = await apiDegustOneOrder.get(url, { params });

    return data;
  } catch (error) {
    console.log("Error in calculateTaxOrderService ::", error);
    return Promise.reject(`Error in calculateTaxOrderService :: ${error}`);
  }
};

/**
 *
 */
export const setFiscalDocumentOrderService = async (params) => {
  try {
    const url = `/api/Order/setFiscalDocument`;

    const { data } = await apiDegustOneOrder.post(url, params);

    return data;
  } catch (error) {
    console.log("Error in setFiscalDocumentOrderService ::", error);
    return Promise.reject(`Error in setFiscalDocumentOrderService :: ${error}`);
  }
};

/**
 *
 */
export const setUnusedCouponOrderService = async (params) => {
  try {
    const url = `/api/Order/tef/unusedCoupon`;

    const { data } = await apiDegustOneOrder.post(url, {
      ...params,
      retailerId: getRetailerId(),
      posId: getTerminalID().toString(),
    });

    return data;
  } catch (error) {
    console.log("Error in setUnusedCouponOrderService ::", error);
    return Promise.reject(`Error in setUnusedCouponOrderService :: ${error}`);
  }
};

/**
 *
 * @param {*} params
 * @param {Number} params.receiptSeries
 */
export const getCurrentReceiptNumberFromDB = async () => {
  try {
    const url = `/api/ReceiptSequence/${getSerieNFCe()}`;

    const { data } = await apiDegustOneOrder.get(url, {
      params: {
        retailerId: getRetailerId(),
        posId: getTerminalID().toString(),
      },
    });

    return data;
  } catch (error) {
    console.log("Error in getCurrentReceiptNumberFromDB ::", error);
    return Promise.reject(`Error in getCurrentReceiptNumberFromDB :: ${error}`);
  }
};

/**
 *
 * @param {*} params
 * @param {Number} params.receiptSeries
 * @param {Number} params.receiptNumber
 */
export const updateCurrentReceiptNumber = async (params) => {
  try {
    const url = `/api/ReceiptSequence`;

    const { data } = await apiDegustOneOrder.put(url, {
      retailerId: getRetailerId(),
      posId: getTerminalID().toString(),
      ...params,
    });

    return data;
  } catch (error) {
    console.log("Error in updateCurrentReceiptNumber ::", error);
    return Promise.reject(`Error in updateCurrentReceiptNumber :: ${error}`);
  }
};

export const getListSalesReturn = async (req) => {
  try {
    const params = new URLSearchParams();
    params.set("RetailerId", req.retailerId);
    params.set("PosId", req.posId);

    const url = `/api/Order/notesAuthorizedOutsideCancellationPeriod?${params.toString()}`;

    const { data } = await apiDegustOneOrder.get(url);

    return data;
  } catch (error) {
    console.log("Error in getListSalesReturn ::", error);
    return Promise.reject(`Error in getListSalesReturn :: ${error}`);
  }
};

export const salesReturn = async (params) => {
  try {
    const url = `/api/Order/devollutionCancellation`;

    const { data } = await apiDegustOneOrder.post(url, {
      retailerId: getRetailerId(),
      posId: getTerminalID().toString(),
      ...params,
    });

    return data;
  } catch (error) {
    console.log("Error in salesReturn ::", error);
    return Promise.reject(`Error in salesReturn :: ${error}`);
  }
};

/**
 *
 * @typedef {object} jsonReq
 * @property {string} retailerId
 * @property {string} orderId
 * @property {string} paymentId
 * @property {string} authorizationCodeCancel
 * @property {string} controlCodeCancel
 * @property {string} cancellerManagerName
 * @property {number} cancellerManagerReferenceId
 *
 * @param {...jsonReq} params
 * @returns order
 */
export const updatePaymentCancel = async (params) => {
  try {
    const url = `/api/Order/updatePaymentCancel`;

    const { data } = await apiDegustOneOrder.post(url, {
      retailerId: getRetailerId(),
      ...params,
    });

    return data;
  } catch (error) {
    console.log("Error in salesReturn ::", error);
    return Promise.reject(`Error in salesReturn :: ${error}`);
  }
};

/** Registra o cancelamento de item no banco
 *
 * @param {Object} params
 * @param {String} params.retailerId
 * @param {String} params.posId
 * @param {Number} params.sellerReferenceId
 * @param {String} params.sellerName
 * @param {Number} params.managerReferenceId
 * @param {String} params.managerName
 * @param {Number} params.sequenceNumber
 * @param {String} params.productName
 * @param {Number} params.productPrice
 * @param {Number} params.productReferenceId
 * @param {Number} params.quantity
 * @param {Number} params.subTotal
 * @returns
 */
export const registryCanceledItem = async (params) => {
  try {
    const url = `/api/Order/registryCanceledItem`;

    const { data } = await apiDegustOneOrder.post(url, {
      retailerId: getRetailerId(),
      posId: getTerminalID().toString(),
      ...params,
    });

    return data;
  } catch (error) {
    console.log("Error in registryCanceledItem ::", error);
    return Promise.reject(`Error in registryCanceledItem :: ${error}`);
  }
};

export const getListCanceledItems = async (req) => {
  try {
    const params = {
      retailerId: getRetailerId(),
      PosId: String(getTerminalID()),
      InitialDate: req.initialDate,
      FinalDate: req.finalDate,
    };

    const { data } = await apiDegustOneOrder.get(
      "/api/Order/listCanceledItem",
      { params }
    );

    return data;
  } catch (error) {
    console.log("Error in getListCanceledItems ::", error);
    return Promise.reject(`Error in getListCanceledItems :: ${error}`);
  }
};

/**
 *
 * @param {*} params -
 * @param {GUID} params.cashdeskId
 * @param {GUID} params.retailerId
 * @param {GUID} params.cashdeskOpenDateId
 *
 * @returns
 */
export const getTicketSummaries = async (params) => {
  try {
    const { data } = await apiDegustOneOrder.get("/api/Order/TicketSummaries", {
      params: {
        retailerId: getRetailerId(),
        ...params,
      },
    });

    return data;
  } catch (error) {
    console.log("Error in getListCanceledItems ::", error);
    return Promise.reject(`Error in getListCanceledItems :: ${error}`);
  }
};
