import React from "react";
import { Button } from "../Button";
import CloseButton from "../close-button";

import {
  DialogBackground,
  DialogBox,
  DialogHeader,
  DialogBody,
  DialogIcon,
  DialogMessage,
  DialogFooter,
} from "./style";

const Dialog = ({
  dialogTitle,
  messageTitle,
  message,
  messageIcon,
  onOkButtonClick,
  okButtonText = "Ok",
  cancelButtonText = "Cancelar",
  customActions = [],
  onCloseButtonClick,
}) => (
  <DialogBackground>
    <DialogBox>
      <DialogHeader>
        {dialogTitle}
        <CloseButton onClick={onCloseButtonClick} />
      </DialogHeader>

      <DialogBody>
        <DialogIcon image={messageIcon} />
        <DialogMessage>
          <h3 className="dialog-title">{messageTitle}</h3>
          <p className="dialog-message"> {message}</p>
        </DialogMessage>
      </DialogBody>

      <DialogFooter>
        <Button
          onClick={onCloseButtonClick}
          type={"link"}
          className="btn btn-text"
        >
          {cancelButtonText}
        </Button>
        {customActions.map((action) => (
          <Button
            onClick={() => {
              onCloseButtonClick();
              action.onClick();
            }}
            className="btn btn-primary"
          >
            {action.text}
          </Button>
        ))}
        <Button onClick={onOkButtonClick} className="btn btn-primary">
          {okButtonText}
        </Button>
      </DialogFooter>
    </DialogBox>
  </DialogBackground>
);

export default Dialog;
