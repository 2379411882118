import React from "react";

import ModalWrapper from "./style";
import Button from "../button";

const ModalPayments = ({
  mTitle,
  mSubtitle,
  mTextBtnPrimary,
  mTextBtnSecondary,
  mBtnPrimarClassName,
  mBtnSecondaryClassName,
  mClassName,
  onPrimaryClick,
}) => {
  return (
    <ModalWrapper>
      <div className={`modal ${mClassName}`}>
        <h1 className="textWarningTablet title">{mTitle}</h1>

        <h2 className="subtitle">{mSubtitle}</h2>

        {mTextBtnSecondary !== false && (
          <Button
            text={mTextBtnSecondary}
            buttonUIType={mBtnSecondaryClassName}
            buttonUI="highlight"
            handleClick={() => {}}
          />
        )}
        {mTextBtnPrimary !== false && (
          <Button
            text={mTextBtnPrimary}
            buttonUIType={mBtnPrimarClassName}
            buttonUI="highlight"
            handleClick={() => {
              onPrimaryClick();
            }}
          />
        )}
      </div>
    </ModalWrapper>
  );
};

export default React.memo(ModalPayments);
