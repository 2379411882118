import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { Container, DetailsVersion, WarnMessageText } from "./styles";
import { HeaderPaymentMethod } from "../../components/Header/paymentMethod";
import { PagesEnum } from "../../constants/pagesEnum";
import { useLocation, useNavigate } from "react-router-dom";
import { useWrapperRouters } from "../../hook/wrapperRoutersHook";
import { KeyboardProvider } from "../../contexts/KeyboardContext";
import { useDialog } from "../../hook/dialogHook";
import { useLoading } from "../../hook/loadingHook";
import { getSystemParamsHelper } from "../../helpers/getSystemParamsHelper";
import { TEF_CONFIG } from "../../constants/keyNamesSessionStorage";
import {
  getTefConfigHelper,
  setTEFActivationStatus,
  setTEFPrintViaStatus,
} from "../../helpers/getTefConfigHelper";
import { Switch } from "../../components/Switch";
import { getTerminalID, isSmartPos } from "../../../aaone/configuration";
import { camelCaseToPascalCaseWithSpacesHelper } from "../../helpers/camelCaseToPascalCaseWithSpacesHelper";

const TP_VALUES_CONSTANT = {
  1: "PRODUCAO",
  2: "HOMOLOGACAO",
};

export const TefSettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputDocumentNumberRef = useRef();
  const inputTokenRef = useRef();
  const inputEnviromentRef = useRef();
  // const inputViaEstablishment = useRef();

  const { showAlert } = useDialog();
  const { setShowLoading } = useLoading();
  const { setVisibleSidebarCart, updateTablesTef, infoTef, getInfoTef } =
    useWrapperRouters();

  const environmentDefault =
    TP_VALUES_CONSTANT[window?.mobileConfig?.TP_AMBIENTE ?? 1] ??
    TP_VALUES_CONSTANT[1];

  const [selectedOption, setSelectedOption] = useState(environmentDefault);
  const [documentNumber, setDocumentNumber] = useState("");
  const [token, setToken] = useState("");
  const [printVia, setPrintVia] = useState(true);

  const systemParams = getSystemParamsHelper();

  const handleChangeEnviroment = (value) => {
    setSelectedOption(value);
  };

  const handleBackToHome = useCallback(() => {
    navigate(PagesEnum.HOME);
  }, []);

  const handleActivationTef = (e) => {
    try {
      e.preventDefault();

      const data = {
        cnpj: documentNumber,
        token: token,
        ambiente: selectedOption,
      };

      if (window.Android) {
        if (data.token === "" || data.cnpj.length !== 14) {
          showAlert({
            message: (
              <>
                Verifique se os campos abaixo foram preenchidos corretamente:
                {data.token === "" ? <><br/>Token</> : ""}
                {data.cnpj.length !== 14 ? <><br/>CNPJ</> : ""}
              </>
            ),
          });

          return;
        }

        setShowLoading(true, "Ativando TEF, aguarde!");

        const verifyAuthenticateDTEF = () => {
          const result = window?.returnAuthenticateDTEF;

          if (result) {
            if (result.authenticateDTEF) {
              showAlert({
                message:
                  "TEF ativado com sucesso, a carga de tabelas será realizada na próxima venda.",
              });

              console.log(
                `TEF ativado com sucesso. -> ${JSON.stringify(data)}`
              );
            } else {
              showAlert({
                message: (
                  <WarnMessageText>
                    Ocorreu um erro ao ativar o TEF
                    <br />
                    {result.resultCodeDTEF} - {result.errorMessageDTEF}
                  </WarnMessageText>
                ),
              });

              console.log(
                `Ocorreu um erro ao ativar o TEF. -> ${JSON.stringify(data)}`
              );
            }

            setTEFActivationStatus(
              window.returnAuthenticateDTEF.authenticateDTEF
            );

            setShowLoading(false);
            window.returnAuthenticateDTEF = undefined;
          } else {
            setTimeout(() => {
              verifyAuthenticateDTEF();
            }, 1000);
          }
        };

        window.Android.initTEFConfiguration(
          data.cnpj,
          data.token,
          data.ambiente
        );

        verifyAuthenticateDTEF();

        localStorage.setItem(TEF_CONFIG, JSON.stringify(data));
      }
    } catch (err) {
      showAlert({
        message: "Não foi possível concluir a ativação do TEF",
      });
      setShowLoading(false);

      if (window.Android) {
        console.log(`error on handleActivationTef: ${JSON.stringify(err)}`);
      } else if (window.desktopApp) {
        console.log("error on handleActivationTef", err);
      }
    }
  };

  const handleUpdateTablesTef = (e) => {
    try {
      e.preventDefault();

      if (window.Android) {
        updateTablesTef();
      }
    } catch (err) {
      if (window.Android) {
        console.log(`error on handleUpdateTablesTef: ${JSON.stringify(err)}`);
      } else if (window.desktopApp) {
        console.log("error on handleUpdateTablesTef", err);
      }
    }
  };

  const handleOnChangeToken = (value) => {
    setToken(value);
  };

  // Ativa e desativa a impressão da via do estabelecimento
  const handleOnChangeVia = () => {
    try {
      if (window.Android) {
        if (window.Android.disableEstablishmentReceipt) {
          if (printVia) {
            window.Android.disableEstablishmentReceipt("S");
          } else {
            window.Android.disableEstablishmentReceipt("N");
          }
        }

        setPrintVia(!printVia);
        setTEFPrintViaStatus(!printVia);
      }
    } catch (error) {
      console.log("error on handleOnChangeVia => " + JSON.stringify(error));
    }
  };

  const isYesOrNo = (boolValue) => (boolValue ? "Sim" : "Não");

  const handleOpenMoreInfoModal = useCallback(() => {
    showAlert({
      title: "Detalhes",
      message: (
        <DetailsVersion className="modal">
          {Object.keys(infoTef).map((infoKey, index) => {
            const value =
              typeof infoTef[infoKey] !== "boolean"
                ? infoTef[infoKey]
                : isYesOrNo(infoTef[infoKey]);

            if (!value) {
              return <></>;
            }

            return (
              <span key={`${infoKey}-${index}`}>
                <strong>
                  {camelCaseToPascalCaseWithSpacesHelper(infoKey)}:
                </strong>{" "}
                {typeof infoTef[infoKey] !== "boolean"
                  ? infoTef[infoKey]
                  : isYesOrNo(infoTef[infoKey])}
              </span>
            );
          })}
          <span>
            <strong>Número do PDV:</strong> {getTerminalID()}
          </span>
        </DetailsVersion>
      ),
    });
  }, [infoTef]);

  useEffect(() => {
    try {
      const data = getTefConfigHelper();

      if (data.cnpj) {
        setDocumentNumber(data.cnpj);
      } else {
        setDocumentNumber(systemParams?.storeCNPJ);
      }

      if (data.token && inputTokenRef.current) {
        handleOnChangeToken(data.token);
      }

      if (data.ambiente && inputEnviromentRef.current) {
        handleChangeEnviroment(data.ambiente);
      }

      if (data.printVia !== undefined) {
        handleOnChangeVia(data.printVia);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    getInfoTef();
    setVisibleSidebarCart(false);
  }, [location]);

  return (
    <KeyboardProvider>
      <Container className={classNames({ isSmartPos })}>
        <HeaderPaymentMethod onClickBackButton={handleBackToHome}>
          Configurações TEF
        </HeaderPaymentMethod>
        <div className="content__wrapper">
          <form className={classNames({ isSmartPos }, "form__container")}>
            <div className="input__group">
              <label className="title">CNPJ da Loja</label>
              <input
                data-use-touch-keyboard
                name="cnpjLojaTef"
                type="text"
                ref={inputDocumentNumberRef}
                defaultValue={documentNumber}
                onChange={(e) => setDocumentNumber(e.currentTarget.value)}
              />
            </div>
            <div className="input__group">
              <label className="title">Token</label>
              <input
                data-use-touch-keyboard
                name="tokenTef"
                type="text"
                ref={inputTokenRef}
                onChange={(e) => handleOnChangeToken(e.currentTarget.value)}
                value={token}
              />
            </div>
            <div className="input__group">
              <div className="flex-row">
                <div className="input__group">
                  <label className="title">
                    Imprimir via do estabelecimento
                  </label>
                  <Switch active={printVia} onChange={handleOnChangeVia} />
                </div>
                <div className="input__group">
                  <label className="title">Ambiente</label>
                  {isSmartPos ? (
                    <input
                      readOnly
                      disabled
                      value={environmentDefault}
                      name="ambienteTef"
                    />
                  ) : (
                    <select
                      data-use-touch-keyboard
                      name="ambienteTef"
                      ref={inputEnviromentRef}
                      value={selectedOption}
                      onChange={(e) => handleChangeEnviroment(e.target.value)}
                    >
                      <option value="PRODUCAO">PRODUÇÃO</option>
                      <option value="HOMOLOGACAO">HOMOLOGAÇÃO</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
            <div className="buttons__container">
              <button
                className="button button__submit"
                onClick={handleActivationTef}
              >
                Ativar TEF
              </button>
              {!isSmartPos && (
                <button
                  className="button button__submit"
                  onClick={handleUpdateTablesTef}
                >
                  Atualização de tabelas
                </button>
              )}
            </div>
            <DetailsVersion>
              <li>
                <strong>Versão SDK:</strong> {infoTef?.sdkVersion}
              </li>
              <li
                role="button"
                onClick={handleOpenMoreInfoModal}
                className="more__info"
              >
                Mais informações
              </li>
            </DetailsVersion>
          </form>
        </div>
      </Container>
    </KeyboardProvider>
  );
};
