import React from "react";
import BoxWrapper from "./style";

const BoxWallet = ({ image, description }) => (
  <BoxWrapper>
    <figure style={{ borderColor: "transparent" }}>
      <img src={image} alt={description} />
    </figure>
  </BoxWrapper>
);

export default React.memo(BoxWallet);
