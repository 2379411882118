import React, { forwardRef, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { currency } from "../../../../aaone/shared/utils/currency";
import { getProductWithAllPossibilities } from "../../../../services/catalogService";
import { useCart } from "../../../hook/cartHook";

import { Container } from "./styles";
import { useCashdesk } from "../../../hook/cashdeskHook";
import { productsUnavailableEnum } from "../../../constants/productsUnavailableEnum";
import { useAuth } from "../../../hook/authHook";
import { useDialog } from "../../../hook/dialogHook";
import { useLoading } from "../../../hook/loadingHook";
import { PagesEnum } from "../../../constants/pagesEnum";
import { getSystemParamsHelper } from "../../../helpers/getSystemParamsHelper";
import { LoadingDots } from "../../LoadingDots";
import { LayoutItemDefault } from "./layout/default";
import { format } from "date-fns";
import { verifyRestriction } from "../../../helpers/productsHelper";

export const SkeletonItemProduct = () => (
  <Container className="skeleton">
    <div
      className={classNames({
        picture: true,
        skeleton: true,
      })}
    >
      <div className="code skeleton">
        <div className="skeleton">
          <div className="skeleton__text"></div>
        </div>
      </div>
    </div>
    <div className="title">
      <div className="skeleton">
        <div className="skeleton__text"></div>
      </div>
      <div className="skeleton">
        <div className="skeleton__text"></div>
      </div>
    </div>
  </Container>
);

window.preventItemProductDoubleClick = false;

export const ItemProduct = forwardRef(
  (
    {
      data,
      allProducts,
      setProductList,
      thumbnailContent,
      forceLayoutDefault = false,
      children,
      ...rest
    },
    ref
  ) => {
    const systemParams = getSystemParamsHelper();
    const onCashierReductionOpenWithdrawingScreen =
      systemParams?.onCashierReductionOpenWithdrawingScreen || false;

    const {
      isOpenModalProductByCode,
      handleOpenModalFidelityData,
      handleCloseModalAddProductByCode,
      handleOpenModalProductFractional,
      quantityToCart,
      addProductToCart,
      setQuantityToCart,
      totalCartInPoints,
      selectedPosition,
      itemsInCart,
      setSaleStartHour,
    } = useCart();
    const { userAuthData, reshop } = useAuth();
    const navigate = useNavigate();
    const { setShowLoading } = useLoading();
    const [showProductLoading, setShowProductLoading] = useState(false);

    const {
      cashdesk,
      isCashdeskOpen,
      isCashdeskOnCurrentDay,
      getLastCashdesk,
      showAlertWithdrawCanSale,
    } = useCashdesk();
    const { setOpenDialog, showAlert, setOpenLoginModal } = useDialog();

    const dialogCashdeskClosedSettings = {
      title: "Abertura do Caixa",
      subTitle: "O caixa não está aberto",
      message: "Deseja realizar a abertura do caixa neste momento?",
      icon: "pdv-one/aberturadecaixa.png",
      onConfirmText: "Abrir o caixa",
      onConfirmClick: async () => {
        setShowLoading();
        await getLastCashdesk();
        setShowLoading(false);
        navigate(PagesEnum.CASH_OPEN);
      },
    };

    const dialogCashdeskCurrentDaySettings = {
      title: "Fechamento de Caixa",
      subTitle: "O caixa está aberto",
      message:
        "Para continuar vendendo, realize o fechamento do caixa e encerramento do dia anterior.",
      icon: "pdv-one/aberturadecaixa.png",
      onConfirmText: "Fechar o caixa",
      onConfirmClick: () => {
        navigate(
          onCashierReductionOpenWithdrawingScreen
            ? PagesEnum.CASH_CLOSE_WITHDRAW
            : PagesEnum.CASH_CLOSE
        );
      },
    };

    const dialogProductUnavailable = {
      title: "Produto Indisponível",
      subTitle: "Para ativá-lo, acesse a página e disponibilize o produto.",
      onConfirmText: "Acessar página",
      onConfirmClick: () => {
        navigate(PagesEnum.PRODUCTS_UNAVAILABLE);
      },
    };

    const dialogNotOpenedUser = {
      title: "Operador diferente da abertura de caixa",
      subTitle:
        "Foi identificado que o operador logado não é o responsável pela abertura.",
      message:
        "Para continuar vendendo, realize o fechamento ou redução do caixa.",
      onConfirmText: "Fechar o caixa",
      onConfirmClick: () => {
        if (userAuthData.isManager) {
          navigate(
            onCashierReductionOpenWithdrawingScreen
              ? PagesEnum.CASH_CLOSE_WITHDRAW
              : PagesEnum.CASH_CLOSE
          );
        } else {
          navigate(PagesEnum.NEED_AUTH, {
            state: {
              redirectUrl: PagesEnum.CASH_CLOSE_WITHDRAW,
              redirectBackOnCancel: false,
            },
          });
        }
      },
      customActions: [
        {
          text: "Reduzir o caixa",
          onClick: () => {
            if (userAuthData.isManager) {
              navigate(
                onCashierReductionOpenWithdrawingScreen
                  ? PagesEnum.CASH_CLOSE_REDUCE
                  : PagesEnum.CASH_REDUCE
              );
            } else {
              navigate(PagesEnum.NEED_AUTH, {
                state: {
                  redirectUrl: PagesEnum.CASH_CLOSE_REDUCE,
                  redirectBackOnCancel: false,
                },
              });
            }
          },
        },
      ],
    };

    const handleProduct = (product, data, quantityToCart) => {
      if (product.level?.length && setProductList) {
        const products = product.level.map((listProduct) => ({
          product: listProduct,
          isLevel: true,
          backProducts: allProducts,
        }));
        setProductList(products);
      } else if (
        (data?.product?.value === 0 && !data?.product?.noSalesEffect) ||
        (data?.value === 0 && !data?.noSalesEffect)
      ) {
        showAlert({
          message:
            "Não é permitido registrar produto com valor zero. (R$ 0.00)",
        });

        window.preventItemProductDoubleClick = false;
      } else {
        addProductToCart({
          ...product,
          categoryId: data.categoryId,
          categoryIsFidelity: data?.categoryIsFidelity ?? false,
          pointPrice: data?.pointPrice,
          quantityToCart,
          tablePosition: selectedPosition ?? null,
        });
      }
    };

    const productFractional =
      data?.product?.fractionalQuantity ?? data?.fractionalQuantity;

    const handleAddProduct = useCallback(
      async ({ bypassRestriction = false } = {}) => {
        if (data?.code) {
          try {
            let blockSales = false;
            let validateSale = true;
            let startHour = null;

            setSaleStartHour((val) => {
              startHour = val;
              return val;
            });

            if (!startHour) {
              startHour = new Date();
              setSaleStartHour(startHour);
            }

            const weekDay = format(startHour, "eeee").toLowerCase();

            const tableSelectedInHome = JSON.parse(
              sessionStorage.getItem("tableSelected")
            );
            if (isCashdeskOpen) {
              blockSales = await showAlertWithdrawCanSale(() => {
                navigate(PagesEnum.CASH_WITHDRAW);
              });
            }

            /**
             *  Se a loja for 24h e estiver com uma venda em andamento,
             *  não trava o carrinho.
             */
            try {
              if (
                itemsInCart.length > 0 &&
                systemParams.store24Hours &&
                !isCashdeskOnCurrentDay &&
                !tableSelectedInHome
              ) {
                validateSale = false;
              }
            } catch (e) {
              if (window.Android) {
                console.log(
                  "Error on Item handleAddProduct => " + JSON.stringify(e)
                );
              } else {
                console.error("Error on Item handleAddProduct => " + e);
              }
            }

            if (validateSale) {
              if (!isCashdeskOpen && !tableSelectedInHome) {
                setOpenDialog(dialogCashdeskClosedSettings);

                return;
              } else if (!isCashdeskOnCurrentDay && !tableSelectedInHome) {
                setOpenDialog(dialogCashdeskCurrentDaySettings);

                return;
              } else if (
                cashdesk &&
                cashdesk.user !== userAuthData.login &&
                !userAuthData.isManager
              ) {
                setOpenDialog(dialogNotOpenedUser);

                return;
              } else if (blockSales) {
                console.log(
                  "Venda bloqueada por Limite/Notificação de Sangria"
                );

                return;
              }
            }

            if (
              data?.product?.unavailableIn?.[0].type.find(
                (where) => where === productsUnavailableEnum.BALCAO
              ) ||
              data?.unavailableIn?.[0].type.find(
                (where) => where === productsUnavailableEnum.BALCAO
              )
            ) {
              setOpenDialog(dialogProductUnavailable);
              window.preventItemProductDoubleClick = false;

              return;
            }

            setShowProductLoading(true);
            const { product } = await getProductWithAllPossibilities(data.code);

            if (!bypassRestriction) {
              let hasRestriction = product?.restriction
                ? !product?.restriction[weekDay]
                    .reduce((acc, curr) => {
                      const restriction = verifyRestriction({
                        restriction: curr,
                        startHour,
                      });

                      acc.push(restriction);

                      return acc;
                    }, [])
                    .includes(false)
                : false;

              if (hasRestriction) {
                showAlert({
                  handleConfirm: () => {
                    if (isOpenModalProductByCode) {
                      handleCloseModalAddProductByCode();
                    }

                    if (userAuthData?.isManager) {
                      handleAddProduct({ bypassRestriction: true });
                    } else {
                      setOpenLoginModal({
                        handleSubmit: () => {
                          handleAddProduct({ bypassRestriction: true });
                        },
                      });
                    }
                  },
                  handleCancel: () => {},
                  message: (
                    <>
                      Este produto (
                      {data?.product?.productCode || data?.productCode}) possui
                      restrição de horário.
                      <br /> Deseja prosseguir com o lançamento?"
                    </>
                  ),
                  onConfirmText: "Sim",
                  cancelButtonText: "Não",
                });

                setShowProductLoading(false);
                window.preventItemProductDoubleClick = false;

                return;
              }
            }

            setShowProductLoading(false);

            if (isOpenModalProductByCode) {
              handleCloseModalAddProductByCode();
            }

            if (productFractional) {
              handleOpenModalProductFractional({ product, data });
              return;
            }

            if (data?.pointPrice) {
              const finalPriceInPoint =
                data?.pointPrice * quantityToCart + totalCartInPoints;
              const balance = reshop?.data?.SaldoMonetario ?? 0;

              if (finalPriceInPoint > balance) {
                setOpenDialog({
                  title: "Cliente reshop",
                  subTitle: "Saldo insuficiente!",
                  message:
                    "Não será possível adicionar esse produto ao carrinho!",
                  onConfirmText: "Conferir Saldo",
                  onCloseText: "Ok!",
                  onConfirmClick: () => {
                    handleOpenModalFidelityData();
                  },
                });
                return;
              }
            }
            setQuantityToCart("reload");
            handleProduct(product, data, quantityToCart);
          } catch (err) {
            console.error(err);
          }
        }

        window.preventItemProductDoubleClick = false;
      },
      [
        data,
        reshop,
        totalCartInPoints,
        isCashdeskOpen,
        isOpenModalProductByCode,
        setQuantityToCart,
        quantityToCart,
        handleCloseModalAddProductByCode,
        handleOpenModalProductFractional,
        addProductToCart,
        productFractional,
      ]
    );

    const noHasImage = data?.product?.image
      ? !data.product.image
      : Boolean(!data?.image ?? false);

    const classes = classNames(
      { unavailable: data.unavailable },
      rest.className
    );

    useEffect(() => {
      return () => {
        window.preventItemProductDoubleClick = false;
      };
    }, []);

    const code = String(data?.code ?? data?.productCode);
    const handleOnClick = rest?.onClick ?? handleAddProduct;

    const propsSettings = {
      ...rest,
      handleOnClick: () => {
        if (rest?.onClick) {
          if (!window.preventItemProductDoubleClick) {
            window.preventItemProductDoubleClick = true;
          } else {
            return;
          }
        }

        handleOnClick();

        setTimeout(() => {
          window.preventItemProductDoubleClick = false;
        }, 300);
      },
      classes,
      noHasImage,
      data,
      code,
      thumbnailContent,
      productFractional,
      children,
    };

    if (showProductLoading) {
      return (
        <Container
          ref={ref}
          role="button"
          className={classNames("loading-container", rest.className)}
        >
          <div className="loading-dots-product">
            <LoadingDots />
          </div>
          <div className="title loading">{children}</div>
          <div className="price loading">
            {data?.pointPrice
              ? `${data.pointPrice}pts`
              : currency(
                  data?.product?.displayValue ??
                    data?.displayValue ??
                    data?.value
                )}
          </div>
        </Container>
      );
    }

    return <LayoutItemDefault {...propsSettings} code={code} />;
  }
);
