import { css } from "styled-components";
import { getProductsAll, getThemeSettings } from "../../configuration";
import { isValidHttpUrl } from "../../shared/utils/isValidHttpUrl";

export const dynamicTheme = css`
  ${getThemeSettings?.fonts &&
  [...getThemeSettings.fonts].map((currentFont) => {
    let formatFont = "truetype";

    const hasFontUrl = currentFont?.url
      ? isValidHttpUrl(currentFont.url)
      : false;

    if (currentFont?.format) {
      switch (currentFont.format) {
        case "ttf":
          formatFont = "truetype";
          break;
        case "woff2":
          formatFont = "woff2";
          break;
        case "woff":
          formatFont = "woff";
          break;
        default:
          formatFont = "truetype";
      }
    }

    return css`
      @font-face {
        font-family: "${currentFont.name}";
        src: local("${currentFont.name}")
          ${hasFontUrl &&
          `, url("${getProductsAll()}/api/font?url=${
            currentFont.url
          }") format("${formatFont}")`};
        font-weight: normal;
      }
    `;
  })}

  :root {
    ${getThemeSettings?.fontMainName &&
    css`
      --font-family-main: "${getThemeSettings.fontMainName}" !important;
    `}

    ${getThemeSettings?.colors?.primary &&
    css`
      --color-background-primary: ${getThemeSettings.colors.primary} !important;
      --color-background-cart: ${getThemeSettings.colors.primary} !important;
      --color-title-category: ${getThemeSettings.colors.primary} !important;
      --color-progress: ${getThemeSettings.colors.primary} !important;
      --color-background-card-primary: ${getThemeSettings.colors
        .primary} !important;
      --color-background-card-secondary: ${getThemeSettings.colors
        .primary} !important;
      --color-additem: ${getThemeSettings.colors.primary} !important;
      --color-trash: ${getThemeSettings.colors.primary} !important;
    `}

    ${getThemeSettings?.colors?.light &&
    css`
      --color-background-light: ${getThemeSettings.colors.light} !important;
      --color-background: ${getThemeSettings.colors.light} !important;
      --color-background-progress-bar: ${getThemeSettings.colors
        .light} !important;
      --color-keyboard-background: ${getThemeSettings.colors.light} !important;
      --color-button-resgate: ${getThemeSettings.colors.light} !important;
      --color-button-banner: ${getThemeSettings.colors.light} !important;
      --color-title-points: ${getThemeSettings.colors.light} !important;
      --color-box-points: ${getThemeSettings.colors.light} !important;
    `}

    ${getThemeSettings?.colors?.secondary &&
    css`
      --color-fidelity: ${getThemeSettings.colors.secondary} !important;
      --color-background-keyboard: ${getThemeSettings.colors
        .secondary} !important;
      --color--background-banner: ${getThemeSettings.colors
        .secondary} !important;
      --color-button-secondary: ${getThemeSettings.colors.secondary} !important;
    `}

    ${getThemeSettings?.colors?.title &&
    css`
      --color-title-secondary: ${getThemeSettings.colors.title} !important;
    `}

    ${getThemeSettings?.colors?.category &&
    css`
      --color-text-category: ${getThemeSettings.colors.category} !important;
    `}

    ${getThemeSettings?.colors?.titleProgress &&
    css`
      --color-title-progress: ${getThemeSettings.colors
        .titleProgress} !important;
    `}

    ${getThemeSettings?.colors?.textFlavorList &&
    css`
      --color-title-modal: ${getThemeSettings.colors.textFlavorList} !important;
    `}
  }
`;
