export const PAYMENT_TYPE_ENUM = {
  Money: 1,
  Debit: 2, // DEBITO POS
  Credit: 3, // CREDITO POS
  CreditTef: 4,
  DebitTef: 5,
  CreditSale: 6,
  AgreementSale: 7, // Convenio
  Voucher: 8, //VOUCHER POS
  VoucherTef: 9,
  PrivateLabel: 10,
  PrivateLabelTef: 11,
  QRCode: 12,
  Others: 15,
};
