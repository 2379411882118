import {  apiProducts } from "./api";
import { getTerminalID } from "../aaone/configuration";

export const getSystemParametersService = async () => {
  try {
    const { data } = await apiProducts.get("/api/systemParameters", {
      headers: {
        terminalid: getTerminalID(),
      },
    });

    return data;
  } catch (error) {
    return null;
  }
}
